import { css } from "styled-components";

import { DEFAULT_CARD_WIDTH } from "../constants";

export const cardGridStyle = css`
  display: grid;
  padding: 30px;
  grid-gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(${DEFAULT_CARD_WIDTH}, 1fr));

  > * {
    margin: auto;
  }

  @media screen and (max-width: 650px) {
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;
