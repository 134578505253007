import { State } from "app/domain/common";
import { CostLine } from "app/domain/costItem";
import { Requirement, RequirementType } from "app/domain/requirement";
import { UniformatLevel1 } from "app/domain/uniformat";
import ErrorsMapper, { ErrorsDTO } from "app/service/common/errorsMapper";
import CostLineMapper, { CostLineDTO } from "app/service/costItem/costLineMapper";

export type RequirementDTO = {
  id: string;
  uniformat1: string;
  uniformat2: string;
  uniformat3: string;
  uniformat4: string;
  state: string;
  requirement_category: string;
  to_verify: boolean;
  is_verified: boolean;
  total_cost: number;
  reference?: string;
  name?: string;
  category?: string;
  cause?: string;
  description?: string;
  asset_id?: string;
  component_id?: string;
  rooms?: string[];
  localisation?: string;
  quantity?: number;
  priority?: string;
  year_planned?: number;
  complexity?: number;
  height?: number;
  removal?: number;
  activity?: string;
  comment?: string;
  auditor?: string;
  audit_date?: string;
  patrimony_factor?: number;
  problematics?: string[];
  items?: string[];
  action_description?: string;
  action_comment?: string;
  action_name?: string;
  action_name_suffix?: string;
  date_finished?: string;
  update?: number;
  update_status?: number;
  reason?: number;
  created_at?: Date;
  created_hour?: Date;
  cost_lines?: CostLineDTO[];
  cost_lines_attributes?: CostLine[];
  customer_reference?: string;
  errors?: ErrorsDTO;
  comments_count?: number;
};

class RequirementMapper {
  static fromDTO(requirementDTO: RequirementDTO): Requirement;
  static fromDTO(requirementDTOs: RequirementDTO[]): Requirement[];
  static fromDTO(requirementDTOs: RequirementDTO | RequirementDTO[]): any {
    if (!Array.isArray(requirementDTOs)) {
      return new Requirement({
        id: requirementDTOs.id,
        uniformat: {
          level1: requirementDTOs.uniformat1 as UniformatLevel1,
          level2: requirementDTOs.uniformat2,
          level3: requirementDTOs.uniformat3,
          level4: requirementDTOs.uniformat4
        },
        state: State.fromString(requirementDTOs.state),
        type: RequirementType.fromString(requirementDTOs.requirement_category),
        toVerify: requirementDTOs.to_verify,
        isVerified: requirementDTOs.is_verified,
        totalCost: requirementDTOs.total_cost,
        errors: requirementDTOs.errors ? ErrorsMapper.fromDTO(requirementDTOs.errors) : [],
        reference: requirementDTOs.reference,
        name: requirementDTOs.name,
        category: requirementDTOs.category,
        cause: requirementDTOs.cause,
        description: requirementDTOs.description,
        assetId: requirementDTOs.asset_id,
        component_id: requirementDTOs.component_id,
        locals: requirementDTOs.rooms,
        localisation: requirementDTOs.localisation,
        localsArea: requirementDTOs.quantity,
        priority: requirementDTOs.priority,
        yearPlanned: requirementDTOs.year_planned,
        complexity: requirementDTOs.complexity,
        height: requirementDTOs.height,
        removal: requirementDTOs.removal,
        activity: requirementDTOs.activity,
        comment: requirementDTOs.comment,
        auditor: requirementDTOs.auditor,
        auditDate: requirementDTOs.audit_date,
        problematics: requirementDTOs.problematics,
        items: requirementDTOs.items,
        actionDescription: requirementDTOs.action_description,
        action_comment: requirementDTOs.action_comment,
        actionName: requirementDTOs.action_name,
        actionNameSuffix: requirementDTOs.action_name_suffix,
        date_finished: requirementDTOs.date_finished,
        patrimony_factor: requirementDTOs.patrimony_factor,
        update: requirementDTOs.update_status,
        reason: requirementDTOs.reason,
        created_hour: requirementDTOs.created_at,
        costLines: requirementDTOs.cost_lines ? CostLineMapper.fromDTO(requirementDTOs.cost_lines as CostLineDTO[]) : [],
        customerReference: requirementDTOs.customer_reference,
        commentsCount: requirementDTOs.comments_count ? requirementDTOs.comments_count : 0
      });
    }
    return requirementDTOs.map((requirementDTO: RequirementDTO): Requirement => this.fromDTO(requirementDTO));
  }

  static toDTO(requirement: Requirement): RequirementDTO;
  static toDTO(requirements: Requirement[]): RequirementDTO[];
  static toDTO(requirements: Requirement | Requirement[]): any {
    if (!Array.isArray(requirements)) {
      return {
        id: requirements.id,
        uniformat1: requirements.uniformat.level1 ? requirements.uniformat.level1 : "",
        uniformat2: requirements.uniformat.level2,
        uniformat3: requirements.uniformat.level3,
        uniformat4: requirements.uniformat.level4,
        state: State.toString(requirements.state),
        requirement_category: RequirementType.toString(requirements.type),
        to_verify: requirements.toVerify,
        is_verified: requirements.isVerified,
        reference: requirements.reference,
        name: requirements.name,
        category: requirements.category,
        cause: requirements.cause,
        description: requirements.description,
        asset_id: requirements.assetId,
        component_id: requirements.component_id,
        rooms: requirements.locals,
        localisation: requirements.localisation,
        quantity: requirements.localsArea,
        priority: requirements.priority,
        year_planned: requirements.yearPlanned,
        complexity: requirements.complexity,
        height: requirements.height,
        removal: requirements.removal,
        activity: requirements.activity,
        comment: requirements.comment,
        auditor: requirements.auditor,
        audit_date: requirements.auditDate,
        problematics: requirements.problematics,
        items: requirements.items,
        action_description: requirements.actionDescription,
        action_comment: requirements.action_comment,
        action_name: requirements.actionName,
        action_name_suffix: requirements.actionNameSuffix,
        date_finished: requirements.date_finished,
        patrimony_factor: requirements.patrimony_factor,
        update_status: requirements.update,
        reason: requirements.reason,
        cost_lines_attributes: requirements.costLines ? CostLineMapper.toDTO(requirements.costLines as CostLine[]) : undefined
      };
    }
    return requirements.map((requirement: Requirement): RequirementDTO => this.toDTO(requirement));
  }
}

export default RequirementMapper;
