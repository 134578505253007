/* eslint-disable react-hooks/exhaustive-deps */

import { Comment } from "app/domain/comment";
import { EntityCategory } from "app/domain/entityCategory";
import FieldNameFactory from "app/service/comment/field/fieldNameFactory";
import NotificationService from "app/service/notification/notificationService";
import { addCommentAction, getCommentsAction } from "app/store/comment/commentActions";
import { getComments, isFetching } from "app/store/comment/commentSelectors";
import { State } from "app/store/state";
import CollapsableCommentThread from "app/view/comment/collapsableCommentThread";
import CommentCreator from "app/view/comment/commentCreator";
import { Toolbar } from "app/view/common/toolbar";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useEffect, useState } from "react";
import { useAsync } from "react-async";
import { useDispatch, useSelector } from "react-redux";
import { Comment as UIComment, Container } from "semantic-ui-react";
import { AddButton } from "ui/button/actions";

interface CommentsListingProps extends ComponentProps {
  parentId: string;
  parentType: EntityCategory;
  fetchFieldValue: (fieldName: string) => string;
}

const fieldNameFactory = new FieldNameFactory();

const CommentsListing: FC<CommentsListingProps> = ({ parentId, parentType, fetchFieldValue, className }: CommentsListingProps) => {
  const [isAddingNewComment, setIsAddingNewComment] = useState(false);
  const [hasStartedLoading, setHasStartedLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [DROPDOWN_OPTIONS] = useState(
    fieldNameFactory.getCommentableFieldNames(parentType).map((field) => {
      return { key: field, text: field, value: field };
    })
  );

  const dispatch = useDispatch();
  const isLoading = useSelector((state: State): boolean => isFetching(state));
  const comments = useSelector((state: State): Comment[] => getComments(state));

  const readNotifications = ([commentIds, replyIds]: any): Promise<void> => {
    return NotificationService.readNotifications(commentIds, replyIds);
  };

  const { run: runReadNotifications } = useAsync({
    deferFn: readNotifications
  });

  useEffect((): void => {
    const commentIds: number[] = [];
    const replyIds: number[] = [];

    comments.forEach((comment) => {
      commentIds.push(comment.id as number);
      comment.replies.forEach((reply) => replyIds.push(reply.id as number));
    });

    runReadNotifications(commentIds, replyIds);
  }, [comments]);

  useEffect((): void => {
    dispatch(getCommentsAction(parentType, parentId));
  }, [dispatch, parentType, parentId]);

  useEffect((): void => {
    if (hasStartedLoading && !isLoading) {
      setHasLoaded(true);
    }

    setHasStartedLoading(isLoading);
  }, [isLoading]);

  const showNewComment = (): void => {
    setIsAddingNewComment(true);
  };

  const hideNewComment = (): void => {
    setIsAddingNewComment(false);
  };

  const addComment = (newComment: Comment): void => {
    hideNewComment();
    dispatch(addCommentAction(newComment));
  };

  const actions = [<AddButton onClick={showNewComment} label="Ajouter" key={0} />];

  return (
    <Container className={className}>
      {!isAddingNewComment && <Toolbar actions={actions} />}
      {isAddingNewComment && <CommentCreator parentId={parentId} parentType={parentType} onCreate={addComment} onCancel={hideNewComment} DROPDOWN_OPTIONS={DROPDOWN_OPTIONS} />}

      {hasLoaded && (
        <UIComment.Group>
          {comments.map((comment: Comment) => (
            <CollapsableCommentThread key={comment.id} comment={comment} fieldValue={fetchFieldValue(comment.fieldName)} DROPDOWN_OPTIONS={DROPDOWN_OPTIONS} />
          ))}
        </UIComment.Group>
      )}
    </Container>
  );
};

export default CommentsListing;
