import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

import PageHeader from "./pageHeader";

const pageStyle = css`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;

  .pageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

interface PageProps extends ComponentProps {
  showBreadcrumb: boolean;
  title: JSX.Element;
  children: (JSX.Element | null) | (JSX.Element | null)[];
}

const Page: FC<PageProps> = ({ showBreadcrumb, title, children, className }: PageProps) => {
  return (
    <div className={className}>
      <PageHeader showBreadcrumb={showBreadcrumb} title={title} />
      <div className="pageContent">{children}</div>
    </div>
  );
};

export default createStyledComponent(Page, pageStyle);
