/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */

import { Filter } from "app/domain/filter";
import usePrevious from "app/view/common/hook/usePrevious";
import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import { get, isEmpty, isEqual } from "lodash";
import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import { HeaderGroup } from "react-table";
import { css } from "styled-components";

import TableBody from "./tableBody";
import TableFooterRow from "./tableRow/footer/tableFooterRow";
import TableHeaderRow from "./tableRow/header/tableHeaderRow";
import { Column, Row, RowData } from "./types";
import { TableRowSelectionProps } from "./useTable";

const getColumnWidths = (columns: Column[]): number[] =>
  columns
    .filter((column: Column) => typeof column !== "boolean")
    .filter((column: Column) => !column.hidden)
    .map((column: Column) => (column.style && column.style.width ? column.style.width : 1));

const getGridTemplate = (columns: Column[], rowSelectionProps?: TableRowSelectionProps): string => {
  const columnWidths = getColumnWidths(columns);
  let gridTemplate = "";

  if (rowSelectionProps) {
    gridTemplate = "min-content";
  }

  columnWidths.forEach((columnWidth: number) => {
    gridTemplate = `${gridTemplate} minmax(30px, ${columnWidth}fr)`;
  });

  return gridTemplate;
};

const tableStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
  border-radius: 0.28571429rem;
  user-select: none;
  border-spacing: 0;
  flex: 1;

  .row {
    display: grid;
    grid-template-columns: ${(props: StyleProps): string => getGridTemplate(props.filteredColumns, props.rowSelectionProps)};
  }

  .cell {
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-left: 1px solid rgba(34, 36, 38, 0.1);
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    transition: background 0.1s ease, color 0.1s ease;

    :first-child {
      border-left: none;
    }
  }
`;

interface TableProps extends ComponentProps {
  headerGroups: HeaderGroup[];
  rows: Row[];
  data: RowData[];
  filteredColumns: any[];
  prepareRow: (row: Row) => void;
  filters?: Filter[];
  onFilter?: (filter: Filter) => void;
  allColumns: any[];
  rowSelectionProps?: TableRowSelectionProps;
}

const Table: FC<TableProps> = ({ headerGroups, rows, data, filters, onFilter, filteredColumns, prepareRow, allColumns, rowSelectionProps, className }: TableProps) => {
  const previousFilters = usePrevious(filters);
  const previousRows = usePrevious(rows);

  const getRowDataOnClick = useCallback(() => {
    return filteredColumns.map((column: Column) => column.onClick);
  }, [filteredColumns]);

  const getRowDataAlignments = useCallback(() => {
    return filteredColumns.map((column: Column) => (column.style && column.style.alignment ? column.style.alignment : undefined));
  }, [filteredColumns]);

  const rowsWithColumnStyle = useMemo(
    () =>
      rows.map(
        (row: Row) =>
          ({
            ...row,
            alignments: getRowDataAlignments(),
            onClick: getRowDataOnClick()
          } as Row)
      ),
    [rows, getRowDataAlignments, getRowDataOnClick]
  );

  useEffect(() => {
    if (!isEqual(previousFilters, filters) || !isEqual(previousRows, rows)) {
      allColumns.forEach((column: any) => {
        const currentColumnFilters = filters ? filters.filter((filter: Filter) => filter.field === column.field).map((filter: Filter) => filter.values) : [];

        column.setFilter(currentColumnFilters.length > 0 ? currentColumnFilters[0] : undefined);
      });
    }
  }, [previousRows, rows, previousFilters, filters, allColumns]);

  return (
    <div className={className}>
      <TableHeaderRow headerGroup={headerGroups[0]} rows={data} filters={filters} onFilter={onFilter} rowSelectionProps={rowSelectionProps} />
      <TableBody rows={rowsWithColumnStyle} prepareRow={prepareRow} rowSelectionProps={rowSelectionProps} />
      <TableFooterRow footerGroup={headerGroups[0]} rowSelectionProps={rowSelectionProps} />
    </div>
  );
};

export default createStyledComponent(memo(Table), tableStyle);
