import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import { get, isUndefined } from "lodash";
import React, { FC, memo, useRef } from "react";
import compare from "react-fast-compare";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { css } from "styled-components";
import { colors } from "ui/colors";
import { useContainerVisibleDimensions } from "ui/hook";

import TableBodyRow from "./tableRow/body/tableBodyRow";
import { Row } from "./types";
import { TableRowSelectionProps } from "./useTable";

const tableBodyStyle = css`
  height: 100%;

  .list {
    position: absolute;
    height: 100% !important;
    width: 100% !important;

    .ReactVirtualized__Grid {
      outline: none;
      max-height: 100%;
      min-height: 100%;
    }
  }
`;

interface TableBodyProps extends ComponentProps {
  rows: Row[];
  prepareRow: (row: Row) => void;
  rowSelectionProps?: TableRowSelectionProps;
}

const isEqual = (prevProps: TableBodyProps, nextProps: TableBodyProps): boolean => {
  const areRowsEqual = compare(prevProps.rows, nextProps.rows);
  const isPrepareRowEqualFiltersEqual = compare(prevProps.prepareRow.toString(), nextProps.prepareRow.toString());
  const isRowSelectionPropsEqual = compare(prevProps.rowSelectionProps, nextProps.rowSelectionProps);
  const isClassNameEqual = compare(prevProps.className, nextProps.className);

  return areRowsEqual && isPrepareRowEqualFiltersEqual && isRowSelectionPropsEqual && isClassNameEqual;
};

const TableBody: FC<TableBodyProps> = ({ rows, prepareRow, rowSelectionProps, className }: TableBodyProps) => {
  const tableBodyContainerReference = useRef(null);
  const tableBodyContainerVisibleDimensions = useContainerVisibleDimensions(tableBodyContainerReference);

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 50
  });

  const renderRow = ({ index, key, parent, style }: any): JSX.Element => (
    <CellMeasurer cache={cache} parent={parent} rowIndex={index} columnIndex={0} key={key}>
      <TableBodyRow
        row={rows[index]}
        style={{
          ...style,
          ...(!isUndefined(get(rows[index], ["original", "isRead"])) && !get(rows[index], ["original", "isRead"])
            ? { backgroundColor: colors.COLOR_WHEN_READ, fontWeight: "bold" }
            : {})
        }}
        prepareRow={prepareRow}
        rowSelectionProps={rowSelectionProps}
      />
    </CellMeasurer>
  );

  const VirtualizedList = ({ width }: any): JSX.Element => (
    <List
      width={width}
      height={tableBodyContainerVisibleDimensions.height}
      deferredMeasurementCache={cache}
      rowHeight={cache.rowHeight}
      rowRenderer={renderRow}
      rowCount={rows.length}
      overscanRowCount={30}
    />
  );

  return (
    <div className={className} ref={tableBodyContainerReference}>
      <AutoSizer className="list">{VirtualizedList}</AutoSizer>
    </div>
  );
};

export default createStyledComponent(memo(TableBody), tableBodyStyle);
