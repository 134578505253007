/* eslint-disable no-param-reassign */

import { EntityCategory } from "app/domain/entityCategory";
import Photo, { PhotosFolder } from "app/domain/photo";
import api, { NEW_API_VERSION, QueryParams } from "app/service/common/api";

import FileConverter from "../../utils/fileConverter";
import PhotoMapper, { PhotoDTO } from "./photoMapper";
import PhotosFolderMapper, { PhotosFolderDTO } from "./photosFolderMapper";

const getQueryParam = (entityId: string, entityCategory: EntityCategory): QueryParams => {
  if (entityCategory === EntityCategory.ASSET) {
    return {
      asset_id: entityId
    };
  }
  if (entityCategory === EntityCategory.SYSTEM) {
    return {
      system_id: entityId
    };
  }
  if (entityCategory === EntityCategory.REQUIREMENT) {
    return {
      requirement_id: entityId
    };
  }
  return { site_id: entityId };
};

class PhotoService {
  static getPhotos = async (entityId: string, entityCategory: EntityCategory): Promise<Photo[]> => {
    const uploadedPhotosDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/photos`,
        queryParams: getQueryParam(entityId, entityCategory)
      })
    ).data as PhotoDTO[];

    return PhotoMapper.fromDTO(uploadedPhotosDTO);
  };

  static addPhoto = async (photo: Photo, entityId: string, entityCategory: EntityCategory): Promise<Photo[]> => {
    photo.src = await FileConverter.toDataURL(photo.file as File);
    const photoDTO = PhotoMapper.toDTO(photo, entityId, entityCategory);

    const uploadedPhotosDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/photos`,
        body: [photoDTO]
      })
    ).data as PhotoDTO[];

    return PhotoMapper.fromDTO(uploadedPhotosDTO);
  };

  static deletePhoto = async (photoId: string): Promise<void> => {
    await api.delete({
      path: `${NEW_API_VERSION}/photos`,
      id: photoId
    });
  };

  static getFolderStatus = async (entityId: string, entityCategory: EntityCategory): Promise<PhotosFolder> => {
    const photosFolder = (
      await api.get({
        path: `${NEW_API_VERSION}/photos/folder_status`,
        queryParams: getQueryParam(entityId, entityCategory)
      })
    ).data as PhotosFolderDTO;

    return PhotosFolderMapper.fromDTO(photosFolder);
  };

  static startFolderCreation = async (entityId: string, entityCategory: EntityCategory): Promise<PhotosFolder> => {
    const photosFolder = (
      await api.get({
        path: `${NEW_API_VERSION}/photos/photo_archive`,
        queryParams: getQueryParam(entityId, entityCategory)
      })
    ).data as PhotosFolderDTO;

    return PhotosFolderMapper.fromDTO(photosFolder);
  };
}

export default PhotoService;
