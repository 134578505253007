import { Site } from "app/domain/site";

import { Action, PayloadAction } from "../types";

export enum SiteActionTypes {
  GET_SITE = "GET_SITE",
  GET_SITE_REQUEST = "GET_SITE_REQUEST",
  GET_SITE_SUCCESS = "GET_SITE_SUCCESS",
  GET_SITE_ERROR = "GET_SITE_ERROR",
  GET_SITES = "GET_SITES",
  GET_SITES_REQUEST = "GET_SITES_REQUEST",
  GET_SITES_SUCCESS = "GET_SITES_SUCCESS",
  GET_SITES_ERROR = "GET_SITES_ERROR",
  UPDATE_SITE = "UPDATE_SITE",
  UPDATE_SITE_REQUEST = "UPDATE_SITE_REQUEST",
  UPDATE_SITE_SUCCESS = "UPDATE_SITE_SUCCESS",
  UPDATE_SITE_ERROR = "UPDATE_SITE_ERROR",
  GENERATE_SITE_REPORT = "GENERATE_SITE_REPORT",
  GENERATE_SITE_REPORT_REQUEST = "GENERATE_SITE_REPORT_REQUEST",
  GENERATE_SITE_REPORT_SUCCESS = "GENERATE_SITE_REPORT_SUCCESS",
  GENERATE_SITE_REPORT_ERROR = "GENERATE_SITE_REPORT_ERROR",
  RESET_SITE = "RESET_SITE"
}

export type GetSiteAction = PayloadAction<
  SiteActionTypes.GET_SITE,
  {
    siteId: string;
  }
>;

export type GetSiteRequestAction = PayloadAction<
  SiteActionTypes.GET_SITE_REQUEST,
  {
    siteId: string;
  }
>;

export type GetSiteSuccessAction = PayloadAction<
  SiteActionTypes.GET_SITE_SUCCESS,
  {
    site: Site;
  }
>;

export type GetSiteErrorAction = PayloadAction<SiteActionTypes.GET_SITE_ERROR, { errorMessage: string }>;

export type GetSitesAction = PayloadAction<
  SiteActionTypes.GET_SITES,
  {
    projectId?: string;
  }
>;

export type GetSitesRequestAction = PayloadAction<
  SiteActionTypes.GET_SITES_REQUEST,
  {
    projectId?: string;
  }
>;

export type GetSitesSuccessAction = PayloadAction<
  SiteActionTypes.GET_SITES_SUCCESS,
  {
    sites: Site[];
  }
>;

export type GetSitesErrorAction = PayloadAction<SiteActionTypes.GET_SITES_ERROR, { errorMessage: string }>;

export type UpdateSiteAction = PayloadAction<
  SiteActionTypes.UPDATE_SITE,
  {
    site: Site;
  }
>;

export type UpdateSiteRequestAction = PayloadAction<
  SiteActionTypes.UPDATE_SITE_REQUEST,
  {
    site: Site;
  }
>;

export type UpdateSiteSuccessAction = PayloadAction<
  SiteActionTypes.UPDATE_SITE_SUCCESS,
  {
    site: Site;
  }
>;

export type UpdateSiteErrorAction = PayloadAction<SiteActionTypes.UPDATE_SITE_ERROR, { errorMessage: string }>;

export type GenerateSiteReportAction = PayloadAction<
  SiteActionTypes.GENERATE_SITE_REPORT,
  {
    siteId: string;
  }
>;

export type GenerateSiteReportRequestAction = PayloadAction<
  SiteActionTypes.GENERATE_SITE_REPORT_REQUEST,
  {
    siteId: string;
  }
>;

export type GenerateSiteReportSuccessAction = Action<SiteActionTypes.GENERATE_SITE_REPORT_SUCCESS>;

export type GenerateSiteReportErrorAction = PayloadAction<SiteActionTypes.GENERATE_SITE_REPORT_ERROR, { errorMessage: string }>;

export type ResetAction = Action<SiteActionTypes.RESET_SITE>;
