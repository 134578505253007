/* eslint-disable react-hooks/exhaustive-deps */

import { KeyboardArrowDown } from "@mui/icons-material";
import { Avatar, Button, ButtonGroup, ClickAwayListener, Grow, List, ListItemAvatar, ListItemButton, ListItemText, Paper, Popper } from "@mui/material";
import { Cable } from "actioncable";
import { EntityCategory } from "app/domain/entityCategory";
import Notification from "app/domain/notification";
import { NotifiableType } from "app/domain/notification/notification";
import { useRouter } from "app/routing/routerProvider";
import NotificationService from "app/service/notification/notificationService";
import QaErrorService from "app/service/qaError/qaErrorService";
import { getUserId } from "app/utils/getUserId";
import { ComponentProps } from "common/style/createStyledComponent";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useAsync } from "react-async";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { colors } from "ui/colors";
import { IconType, NotificationIcon } from "ui/icon";

interface NotificationBarProps extends ComponentProps {
  cable: Cable;
}

const StyleList = styled(List)`
  margin-bottom: 12px;
`;

const StyledPopper: any = styled(Popper)`
  display: flex;
  left: auto !important;
  top: 52px !important;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  .MuiButtonGroup-grouped {
    min-width: 10px;
    border: 1px solid ${colors.BLUE} !important;
  }
  .MuiButton-outlinedSizeSmall {
    padding: 0;
  }
`;

const StyledDiv = styled.div<{ backgroundColor?: string }>`
  flex-direction: column;
  display: flex;
  background-color: ${(props): string => props.backgroundColor || colors.SWISS_COFFEE};
  padding: 15px;
`;

const NotificationBar: FC<NotificationBarProps> = ({ cable }) => {
  const { goTo } = useRouter();
  const [nbNotification, setNbNotifications] = useState(0);
  const [nbQaErrors, setNbQaErrors] = useState(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const nbNotificationToDisplay = 5;
  const intl = useIntl();

  const fetchCommentsCount = (): Promise<number> => {
    return NotificationService.getNotificationsCount();
  };

  const handleSuccess = (commentsCount: number): void => {
    setNbNotifications(commentsCount);
  };

  const fetchQaErrorsCount = (): Promise<number> => {
    return QaErrorService.getQaErrorsCount();
  };

  const handleFetchQaErrorsSuccess = (qaErrorsCount: number): void => {
    setNbQaErrors(qaErrorsCount);
  };

  const fetchNotification = (): Promise<Notification[]> => {
    return NotificationService.getNotifications(nbNotificationToDisplay);
  };

  const handlefetchNotificationSuccess = (fetchedNotifications: Notification[]): void => {
    setNotifications(fetchedNotifications);
  };

  const goToCommentsPage = (): void => {
    goTo(`/comments`);
  };

  const goToQaErrorsPage = (): void => {
    goTo(`/userQaErrors`);
  };

  const { run: runGetCommentsCount } = useAsync({
    deferFn: fetchCommentsCount,
    onResolve: handleSuccess
  });

  const { run: runFetchQaErrorsCount } = useAsync({
    deferFn: fetchQaErrorsCount,
    onResolve: handleFetchQaErrorsSuccess
  });

  const { run: runFetchNotification } = useAsync({
    deferFn: fetchNotification,
    onResolve: handlefetchNotificationSuccess
  });

  useEffect(() => {
    runGetCommentsCount();
    runFetchQaErrorsCount();
    const interval = setInterval(() => {
      runGetCommentsCount();
      runFetchQaErrorsCount();
    }, 300000);

    return (): any => clearInterval(interval);
  }, []);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = (): void => {
    runFetchNotification();
    setOpen((prevOpen) => !prevOpen);
  };

  const userId = getUserId();

  useEffect(() => {
    const channel = { channel: "NotificationChannel", userId };
    if (
      !_.get(cable, ["subscriptions", "subscriptions"])
        .map((ele: { identifier: string }) => ele.identifier)
        .includes(JSON.stringify(channel))
    )
      cable.subscriptions.create(channel, {
        received: (data) => {
          if (_.get(data, ["data", "count"]) !== nbNotification && _.get(data, ["data", "notif"])) {
            toast.info(
              intl.formatMessage(
                { id: _.get(data, ["data", "notif", "type"]) === "reply" ? "notification.reply" : "notification.comment" },
                { name: _.get(data, ["data", "notif", "creator"]) }
              ),
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000
              }
            );
            toast.clearWaitingQueue();
          }
          setNbNotifications(_.get(data, ["data", "count"]) as number);
        }
      });
  }, []);

  const getUrl = (clickedNotification: Notification): string => {
    let parentUrl = `/projects/${clickedNotification.projectId}/sites/${clickedNotification.siteId}/assets/${clickedNotification.assetId}`;
    if (clickedNotification.parentType === EntityCategory.SYSTEM) {
      parentUrl = parentUrl.concat(`/components/${clickedNotification.parentId}`);
    } else if (clickedNotification.parentType === EntityCategory.REQUIREMENT) {
      parentUrl = parentUrl.concat(`/requirements/${clickedNotification.parentId}`);
    }
    return parentUrl;
  };

  const handleClickAway = (): void => {
    setOpen(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <StyledButtonGroup>
        <NotificationIcon quantity={nbNotification} type={IconType.COMMENT} onClick={goToCommentsPage} />
        <Button color="primary" size="small" aria-controls="split-button-menu" aria-haspopup="menu" onClick={handleToggle}>
          <KeyboardArrowDown />
        </Button>
      </StyledButtonGroup>

      <StyledPopper open={open} anchorEl={anchorRef.current} transition disablePortal placement="bottom-end">
        {({ TransitionProps }: any): any => (
          <Grow {...TransitionProps}>
            <Paper square>
              <ClickAwayListener onClickAway={handleClickAway}>
                <StyleList>
                  {notifications.map((notification) => (
                    <ListItemButton
                      className="list-items"
                      style={!notification.isRead ? { backgroundColor: colors.COLOR_WHEN_READ } : {}}
                      key={notification.id}
                      component={Link}
                      to={getUrl(notification)}
                    >
                      <ListItemAvatar>
                        <Avatar alt="Profile Picture" src={undefined} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={notification.message}
                        secondary={
                          <StyledDiv>
                            {notification.comment}
                            {notification.notifiableType === NotifiableType.reply && <StyledDiv backgroundColor={colors.SILVER}>{notification.reply}</StyledDiv>}
                          </StyledDiv>
                        }
                      />
                    </ListItemButton>
                  ))}
                </StyleList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
      <NotificationIcon quantity={nbQaErrors} type={IconType.TASKS} onClick={goToQaErrorsPage} />
    </div>
  );
};

export default NotificationBar;
