/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */

import { Filter, FilterType } from "app/domain/filter";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import { isEqual as isLodashEqual } from "lodash";
import React, { FC, memo, useEffect, useMemo } from "react";
import compare from "react-fast-compare";
import { Row as ReactTableRow, useFilters, useSortBy, useTable } from "react-table";
import { css } from "styled-components";
import QuickFilterPanel from "ui/table/quickFilterPanel/quickFilterPanel";

import usePrevious from "../../app/view/common/hook/usePrevious";
import FiltersContainer from "./quickFilterPanel/filtersContainer";
import Table from "./table";
import { Column, RowData } from "./types";
import { TableRowSelectionProps } from "./useTable";
import { selectRows, sortRows } from "./utils";

const tableContainerStyle = css`
  && {
    display: flex;
    flex-direction: column;
    height: 100%;

    .quickFilters {
      display: flex;
    }

    > *:not(:last-child) {
      margin-bottom: 1em;
    }
  }
`;

interface TableContainerProps extends ComponentProps {
  columns: (Column | boolean)[];
  data: RowData[];
  onDataChange: (rowData: RowData[]) => void;
  initialQuickFilters?: Filter[];
  filters?: Filter[];
  onResetFilters?: () => void;
  onFilter?: (filter: Filter) => void;
  onToggleFilter?: (filter: Filter) => void;
  onDeleteFilter?: (filterLabel: string) => void;
  rowSelectionProps?: TableRowSelectionProps;
  isReadList?: boolean[];
}

const isEqual = (prevProps: TableContainerProps, nextProps: TableContainerProps): boolean => {
  const isDataEqual = compare(prevProps.data, nextProps.data);
  const areColumnsEqual = compare(prevProps.columns, nextProps.columns);
  const areInitialQuickFiltersEqual = compare(prevProps.initialQuickFilters, nextProps.initialQuickFilters);
  const areFiltersEqual = compare(prevProps.filters, nextProps.filters);
  const isOnResetFiltersEqual = compare(
    prevProps.onResetFilters ? prevProps.onResetFilters.toString() : undefined,
    nextProps.onResetFilters ? nextProps.onResetFilters.toString() : undefined
  );
  const isOnFilterEqual = compare(prevProps.onFilter ? prevProps.onFilter.toString() : undefined, nextProps.onFilter ? nextProps.onFilter.toString() : undefined);
  const isOnToggleFilterEqual = compare(
    prevProps.onToggleFilter ? prevProps.onToggleFilter.toString() : undefined,
    nextProps.onToggleFilter ? nextProps.onToggleFilter.toString() : undefined
  );
  const isOnDeleteFilterEqual = compare(
    prevProps.onDeleteFilter ? prevProps.onDeleteFilter.toString() : undefined,
    nextProps.onDeleteFilter ? nextProps.onDeleteFilter.toString() : undefined
  );
  const isRowSelectionPropsEqual = compare(prevProps.rowSelectionProps, nextProps.rowSelectionProps);
  const isClassNameEqual = compare(prevProps.className, nextProps.className);

  return (
    isDataEqual &&
    areColumnsEqual &&
    areFiltersEqual &&
    isOnResetFiltersEqual &&
    areInitialQuickFiltersEqual &&
    isOnFilterEqual &&
    isOnToggleFilterEqual &&
    isOnDeleteFilterEqual &&
    isRowSelectionPropsEqual &&
    isClassNameEqual
  );
};

const TableContainer: FC<TableContainerProps> = ({
  data,
  onDataChange,
  columns,
  initialQuickFilters,
  filters,
  onToggleFilter,
  onDeleteFilter,
  onResetFilters,
  onFilter,
  rowSelectionProps,
  className
}: TableContainerProps) => {
  const defaultColumn = useMemo(
    () => ({
      filter: selectRows
    }),
    []
  ) as any;

  const filteredColumns = useMemo(
    () =>
      (columns.filter((column: boolean | Column) => typeof column !== "boolean") as Column[]).map((column: Column) => ({
        ...column,
        accessor: `${column.field}.accessor`,
        select: column.select !== undefined ? column.select : true,
        sort: column.sort !== undefined ? column.sort : true,
        sortType: sortRows,
        Cell: (props: any): JSX.Element => {
          const { row } = props;
          return <span>{row.original[column.field].value}</span>;
        }
      })) as any[],
    [columns]
  ) as any[];

  const hiddenColumns = useMemo(
    () =>
      (columns.filter((column: boolean | Column) => typeof column !== "boolean") as Column[])
        .filter((column: Column) => column.hidden)
        .map((column: Column) => `${column.field}.accessor`) as string[],
    [columns]
  ) as string[];

  const { headerGroups, prepareRow, rows, allColumns } = useTable(
    {
      columns: filteredColumns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns
      }
    },
    useFilters,
    useSortBy
  ) as any;
  const previousRows = usePrevious(rows);

  useEffect(() => {
    if (!isLodashEqual(previousRows, rows)) {
      const currentRowData = rows.map((row: ReactTableRow) => row.original);
      onDataChange(currentRowData);
    }
  }, [previousRows, rows, onDataChange]);

  return (
    <div className={className}>
      {filters && onFilter && onDeleteFilter && onResetFilters && onToggleFilter && (
        <div className="quickFilters">
          {initialQuickFilters && (
            <QuickFilterPanel
              quickFilters={initialQuickFilters || []}
              selectedQuickFilters={filters ? filters.filter((filter: Filter) => filter.type === FilterType.QUICK_FILTER) : []}
              onQuickFilterSelect={onToggleFilter}
            />
          )}
          <FiltersContainer filters={filters} onDelete={onDeleteFilter} onReset={onResetFilters} />
        </div>
      )}
      <Table
        headerGroups={headerGroups}
        rows={rows}
        data={data}
        filteredColumns={filteredColumns}
        prepareRow={prepareRow}
        filters={filters}
        onFilter={onFilter}
        allColumns={allColumns}
        rowSelectionProps={rowSelectionProps}
      />
    </div>
  );
};

export default createStyledComponent(memo(TableContainer, isEqual), tableContainerStyle);
