import { RowNode } from "ag-grid-community";

export class TableUtility {
  static comparatorWithoutFillerCharacters(valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode, isDescending: boolean): number {
    const reg = /[\d.]/g;
    const matchA = valueA.match(reg);
    const matchB = valueB.match(reg);
    if (matchA && matchB) {
      const A = parseFloat(matchA.join(""));
      const B = parseFloat(matchB.join(""));
      if (A === B) return 0;
      return A > B ? 1 : -1;
    }
    return 0;
  }
}
