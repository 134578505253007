import AuditProgress from "app/domain/asset/auditProgress";

export type AuditProgressState = {
  allAuditProgresses: AuditProgress[];
  auditProgress: AuditProgress | undefined;
  isFetching: boolean;
  isUpdating: boolean;
  errorMessage: string;
};

export const initialAuditProgressState: AuditProgressState = {
  allAuditProgresses: [],
  auditProgress: undefined,
  isFetching: false,
  isUpdating: false,
  errorMessage: ""
};
