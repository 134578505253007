/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { TreeItem, TreeView } from "@mui/lab";
import { Box, Button, Grid, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import rsmeansService from "app/service/rsmeans/rsmeansService";
import { moneyFormatter } from "app/utils/formatter";
import { ComponentProps } from "common/style/createStyledComponent";
import { cloneDeep, get, isEmpty } from "lodash";
import React, { FC, memo, useEffect, useState } from "react";
import { useAsync } from "react-async";
import { Modal } from "ui/modal";

interface Props extends ComponentProps {
  isOpen: boolean;
  setIsOpen: Function;
  systemId: string;
  addCostLine: Function;
}

interface IData {
  catalogs: any[];
  laborType: any[];
  location: any[];
  measurements: any[];
  release: any[];
}

interface RenderTree {
  id: string;
  description: string;
  level?: number;
  children?: readonly RenderTree[];
}

// eslint-disable-next-line no-empty-pattern
const CostDataContainer: FC<Props> = ({ isOpen, setIsOpen, systemId, addCostLine }: Props) => {
  const [data, setData] = useState<IData>({} as IData);
  const [catalog, setCatalog] = useState("");
  const [laborType, setLaborType] = useState("");
  const [location, setLocation] = useState("");
  const [measurements, setmMasurements] = useState("");
  const [release, setRelease] = useState("");
  const [search, setSearch] = useState("");
  const [costline, setCostline] = useState([]);
  const [division, setDivision] = useState<RenderTree>({
    id: "root",
    description: "Division"
  });
  let divisionId = "";
  let divisionNode: RenderTree;
  const [selectedCostline, setSelectedCostline] = useState({});
  const [isActive, setIsActive] = useState(false);

  const resetState = () => {
    setCatalog("");
    setSearch("");
    setCostline([]);
    setSelectedCostline({});
    setDivision({
      id: "root",
      description: "Division"
    });
  };

  const getCatalogs = async (): Promise<any> => {
    const _catalogs = await rsmeansService.getRsmeansCatalogs({});
    const _laborType = await rsmeansService.getRsmeansLaborType();
    const _location = await rsmeansService.getRsmeansLocation();
    const _measurements = await rsmeansService.getRsmeansMeasurements();
    const _release = await rsmeansService.getRsmeansRelease();
    setmMasurements(_measurements?.find((i: any) => i.is_default)?.value);
    setLaborType(_laborType?.find((i: any) => i.is_default)?.value);
    setLocation(_location?.find((i: any) => i.is_default)?.value);
    setRelease(_release?.find((i: any) => i.is_default)?.value);
    return { catalogs: _catalogs, laborType: _laborType, location: _location, measurements: _measurements, release: _release };
  };

  const setRsmeansCostline = async () => {
    setIsActive(true);
    const _costline = await rsmeansService.setCostline({
      quantity: 0,
      system_id: systemId,
      costline: {
        code: get(selectedCostline, "id"),
        description: get(selectedCostline, "description"),
        unit_of_measure: get(selectedCostline, "unitOfMeasure"),
        unit_cost: get(selectedCostline, ["baseCosts", "totalCost"]),
        rsmeans_catalog: get(selectedCostline, ["catalog", "id"])
      }
    });
    return _costline;
  };

  const handleSuccessSetCostline = (costLine: any): void => {
    addCostLine(costLine);
    setSelectedCostline({});
    setIsActive(false);
  };

  const { run: fetchSetCostline } = useAsync({
    deferFn: setRsmeansCostline,
    onResolve: handleSuccessSetCostline
  });

  const getRsmeansUnitDivision = async (params: string[]) => {
    setIsActive(true);
    const [catId] = params;
    const _unitDivision = await rsmeansService.getRsmeansUnitDivision(catId);
    return _unitDivision;
  };

  const handleSuccessUnitDivision = (_data: any): void => {
    setDivision({ ...division, children: get(_data, "items") });
    setIsActive(false);
  };

  const { run: fetchUnitDivision } = useAsync({
    deferFn: getRsmeansUnitDivision,
    onResolve: handleSuccessUnitDivision
  });

  const getRsmeansUnitDivisionChildren = async (params: string[]) => {
    setIsActive(true);
    const [catId, id] = params;
    const _unitDivision = await rsmeansService.getRsmeansUnitDivisionChildren(catId, id);
    return _unitDivision;
  };

  const handleSuccessUnitDivisionChildren = (_data: any): void => {
    divisionNode.children = _data.items;
    const tree = cloneDeep(division);
    setDivision(tree);
    setIsActive(false);
  };

  const { run: fetchUnitDivisionChildren } = useAsync({
    deferFn: getRsmeansUnitDivisionChildren,
    onResolve: handleSuccessUnitDivisionChildren
  });

  const handleSuccess = (_data: IData): void => {
    setData(_data);
    setIsActive(false);
  };

  const { run } = useAsync({
    deferFn: getCatalogs,
    onResolve: handleSuccess
  });

  useEffect(() => {
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchRsmeansUnitCostline = async (params: any[]) => {
    setIsActive(true);
    const { catId, divisionCode, searchTerm } = params[0];
    const _costline = await rsmeansService.searchRsmeansUnitCostline({ catId, divisionCode, searchTerm });
    return _costline;
  };
  const handleSuccessUnitCostline = (_data: any): void => {
    setCostline(get(_data, ["unitLines", "items"], get(_data, "items", [])));
    setIsActive(false);
  };

  const { run: fetchSearchRsmeansUnitCostline } = useAsync({
    deferFn: searchRsmeansUnitCostline,
    onResolve: handleSuccessUnitCostline
  });

  const renderTree = (nodes: RenderTree) => {
    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={nodes.id === "root" ? data?.catalogs?.find((c) => c.rsmeans_id === catalog)?.name : `${nodes.id} - ${nodes.description}`}
        onClick={() => {
          if (isEmpty(nodes.children)) {
            if (nodes.level && nodes.level === 4) {
              fetchSearchRsmeansUnitCostline({ catId: catalog, divisionCode: nodes.id });
            } else {
              divisionId = nodes.id;
              fetchUnitDivisionChildren(catalog, nodes.id);
              divisionNode = nodes;
            }
          }
        }}
      >
        {nodes.children ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    );
  };

  return (
    <Modal
      title="RSMeans CostLines"
      isOpen={isOpen}
      content={
        <Box sx={{ height: (t) => t.spacing(90), flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item height={75}>
              <FormControl variant="standard" sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-simple-select-standard-label">Catalog</InputLabel>
                <Select
                  labelId="demo-simple-sitem xs={8}elect-standard-label"
                  id="demo-simple-select-standard"
                  value={catalog}
                  onChange={(event: SelectChangeEvent) => {
                    setCatalog(event.target.value as string);
                    if (event.target.value) {
                      fetchUnitDivision(event.target.value as string);
                    } else {
                      resetState();
                    }
                  }}
                  label="Catalog"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {!isEmpty(data) &&
                    data?.catalogs.map(({ name, rsmeans_id, id, rsmeans_type }: { name: string; rsmeans_id: string; id: string; rsmeans_type: string }) => (
                      <MenuItem key={id} value={rsmeans_id}>
                        {rsmeans_type.toUpperCase()} - {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item height={75}>
              <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
                <InputLabel id="demo-simple-select-standard-label">Unit</InputLabel>
                <Select
                  labelId="demo-simple-sitem xs={8}elect-standard-label"
                  id="demo-simple-select-standard"
                  value={measurements}
                  disabled
                  onChange={(event: SelectChangeEvent) => {
                    setmMasurements(event.target.value as string);
                  }}
                  label="Unit"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {!isEmpty(data) &&
                    data?.measurements.map(({ name, value, id }: { name: string; value: string; id: string }) => (
                      <MenuItem key={id} value={value}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item height={75}>
              <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
                <InputLabel id="demo-simple-select-standard-label">Labor</InputLabel>
                <Select
                  labelId="demo-simple-sitem xs={8}elect-standard-label"
                  id="demo-simple-select-standard"
                  value={laborType}
                  disabled
                  onChange={(event: SelectChangeEvent) => {
                    setLaborType(event.target.value as string);
                  }}
                  label="Labor"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {!isEmpty(data) &&
                    data?.laborType.map(({ name, value, id }: { name: string; value: string; id: string }) => (
                      <MenuItem key={id} value={value}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item height={75}>
              <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
                <InputLabel id="demo-simple-select-standard-label">Location</InputLabel>
                <Select
                  labelId="demo-simple-sitem xs={8}elect-standard-label"
                  id="demo-simple-select-standard"
                  value={location}
                  disabled
                  onChange={(event: SelectChangeEvent) => {
                    setLocation(event.target.value as string);
                  }}
                  label="Location"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {!isEmpty(data) &&
                    data?.location.map(({ name, value, id }: { name: string; value: string; id: string }) => (
                      <MenuItem key={id} value={value}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item height={75}>
              <FormControl variant="standard" sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-simple-select-standard-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-sitem xs={8}elect-standard-label"
                  id="demo-simple-select-standard"
                  value={release}
                  disabled
                  onChange={(event: SelectChangeEvent) => {
                    setRelease(event.target.value as string);
                  }}
                  label="Year"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {!isEmpty(data) &&
                    data?.release.map(({ name, value, id }: { name: string; value: string; id: string }) => (
                      <MenuItem key={id} value={value}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} height={75}>
              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="search">Search</InputLabel>
                <Input
                  id="search"
                  value={search}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(event.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} height={75} display="flex" alignContent="center" alignItems="center" justifyContent="center">
              <Button
                variant="contained"
                onClick={() => {
                  fetchSearchRsmeansUnitCostline({ catId: catalog, searchTerm: search } as any);
                }}
                disabled={isEmpty(search) || isEmpty(catalog)}
              >
                <SearchIcon sx={{ margin: "5px", mr: 2 }} /> search
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 5, height: 470 }}>
            <Grid item xs={3}>
              {!isEmpty(division.children) ? (
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={["root"]}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    flexGrow: 1,
                    maxWidth: 400,
                    height: 470,
                    overflowY: "scroll",
                    border: "1px solid rgba(224,224,224,1)",
                    borderRadius: "4px",
                    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
                  }}
                >
                  {renderTree(division)}
                </TreeView>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 470,
                    border: "1px solid rgba(224,224,224,1)",
                    borderRadius: "4px",
                    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
                  }}
                >
                  <Typography variant="h5" gutterBottom component="div" sx={{ rotate: "40deg" }}>
                    Veuillez sélectionner un catalog
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid item xs={9}>
              <TableContainer component={Paper} sx={{ height: 470 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Unité</TableCell>
                      <TableCell>Coût</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {costline.map((row) => (
                      <TableRow
                        key={get(row, "name")}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 }, backgroundColor: get(selectedCostline, "id") === get(row, "id") ? "#ebfcff" : "#fff" }}
                        onClick={() => {
                          setSelectedCostline(row);
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {get(row, "id")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {get(row, "description")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {get(row, "unitOfMeasure")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {moneyFormatter.format(get(row, ["baseCosts", "totalCost"]) as number)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row-reverse", my: 4 }}>
            <Button
              onClick={() => {
                fetchSetCostline();
                setIsOpen(false);
                resetState();
              }}
              variant="contained"
            >
              insérer et fermer
            </Button>
            <Button
              onClick={() => {
                fetchSetCostline();
              }}
              sx={{ mx: 2 }}
              variant="contained"
            >
              insérer
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsOpen(false);
                resetState();
              }}
            >
              fermer
            </Button>
          </Box>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isActive}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      }
      actions={[]}
      data-test-id="modal"
    />
  );
};

export default memo(CostDataContainer);
