/* eslint-disable no-param-reassign  */

import { Requirement } from "app/domain/requirement";
import { RequirementProblematicOption, RequirementsOptions } from "app/domain/requirement/options";
import RequirementService from "app/service/requirement/requirementService";
import { notify } from "app/view/common/toast/Toast";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  addRequirementErrorAction,
  addRequirementOptionErrorAction,
  addRequirementOptionRequestAction,
  addRequirementOptionSuccessAction,
  addRequirementRequestAction,
  addRequirementSuccessAction,
  deleteRequirementErrorAction,
  deleteRequirementRequestAction,
  deleteRequirementSuccessAction,
  generateRequirementCopiesErrorAction,
  generateRequirementCopiesRequestAction,
  generateRequirementCopiesSuccessAction,
  getRequirementErrorAction,
  getRequirementOptionsErrorAction,
  getRequirementOptionsRequestAction,
  getRequirementOptionsSuccessAction,
  getRequirementRequestAction,
  getRequirementsErrorAction,
  getRequirementsRequestAction,
  getRequirementsSuccessAction,
  getRequirementSuccessAction,
  updateRequirementErrorAction,
  updateRequirementRequestAction,
  updateRequirementSuccessAction
} from "./actions";
import {
  AddRequirementAction,
  AddRequirementOptionAction,
  AddRequirementOptionRequestAction,
  AddRequirementRequestAction,
  DeleteRequirementAction,
  DeleteRequirementRequestAction,
  GenerateRequirementCopiesAction,
  GenerateRequirementCopiesRequestAction,
  GetRequirementAction,
  GetRequirementRequestAction,
  GetRequirementsAction,
  GetRequirementsRequestAction,
  RequirementActionTypes,
  UpdateRequirementAction,
  UpdateRequirementRequestAction
} from "./types";

export const GENERATE_REQUIREMENT_COPIES_ERROR = "Une erreur s'est produite lors de la génération des copies de l'exigence. Veuillez réessayer.";
export const GET_REQUIREMENT_ERROR = "Une erreur s'est produite lors de la récupération de l'exigence. Veuillez réessayer.";
export const GET_REQUIREMENTS_ERROR = "Une erreur s'est produite lors de la récupération des exigences. Veuillez réessayer.";
export const GET_REQUIREMENT_OPTIONS_ERROR = "Une erreur s'est produite lors de la récupération des listes d'options des exigences. Veuillez réessayer.";
export const ADD_REQUIREMENT_ERROR = "Une erreur s'est produite lors de la création de l'exigence. Veuillez réessayer.";
export const UPDATE_REQUIREMENT_ERROR = "Une erreur s'est produite lors de la mise à jour de l' exigence. Veuillez réessayer.";
export const DELETE_REQUIREMENT_ERROR = "Une erreur s'est produite lors de la suppression de l'exigence. Veuillez réessayer.";
export const ADD_REQUIREMENT_OPTION_ERROR = "Une erreur s'est produite lors de la création de l'option. Veuillez réessayer.";

export function* getRequirement(action: GetRequirementAction): Generator {
  yield put(getRequirementRequestAction(action.payload.requirementId));
}

export function* getRequirementRequest(action: GetRequirementRequestAction): Generator {
  try {
    const requirement = yield call(RequirementService.getRequirement, action.payload.requirementId);
    yield put(getRequirementSuccessAction(requirement as Requirement));
  } catch (error) {
    yield put(getRequirementErrorAction(GET_REQUIREMENT_ERROR));
  }
}

export function* getRequirements(action: GetRequirementsAction): Generator {
  yield put(getRequirementsRequestAction(action.payload.assetId));
}

export function* getRequirementsRequest(action: GetRequirementsRequestAction): Generator {
  try {
    const requirements = yield call(RequirementService.getRequirements, action.payload.assetId);
    yield put(getRequirementsSuccessAction(requirements as Requirement[]));
  } catch (error) {
    yield put(getRequirementsErrorAction(GET_REQUIREMENTS_ERROR));
  }
}

export function* getRequirementOptions(): Generator {
  yield put(getRequirementOptionsRequestAction());
}

export function* getRequirementOptionsRequest(): Generator {
  try {
    const requirementOptions = yield call(RequirementService.getRequirementOptions);
    yield put(getRequirementOptionsSuccessAction(requirementOptions as RequirementsOptions));
  } catch (error) {
    yield put(getRequirementOptionsErrorAction(GET_REQUIREMENT_OPTIONS_ERROR));
  }
}

export function* addRequirement(action: AddRequirementAction): Generator {
  yield put(addRequirementRequestAction(action.payload.requirement));
}

export function* addRequirementRequest(action: AddRequirementRequestAction): Generator {
  try {
    const newRequirement = yield call(RequirementService.createRequirement, action.payload.requirement);

    yield put(addRequirementSuccessAction(newRequirement as Requirement));
    notify("L'exigence a été créée avec succès");
  } catch (error) {
    yield put(addRequirementErrorAction(ADD_REQUIREMENT_ERROR));
  }
}

export function* updateRequirement(action: UpdateRequirementAction): Generator {
  yield put(updateRequirementRequestAction(action.payload.requirement));
}

export function* updateRequirementRequest(action: UpdateRequirementRequestAction): Generator {
  try {
    const updatedRequirement = yield call(RequirementService.updateRequirement, action.payload.requirement);

    yield put(updateRequirementSuccessAction(updatedRequirement as Requirement));
    notify("L'exigence a été modifiée avec succès");
  } catch (error) {
    yield put(updateRequirementErrorAction(UPDATE_REQUIREMENT_ERROR));
  }
}

export function* deleteRequirement(action: DeleteRequirementAction): Generator {
  yield put(deleteRequirementRequestAction(action.payload.requirementId));
}

export function* deleteRequirementRequest(action: DeleteRequirementRequestAction): Generator {
  try {
    yield call(RequirementService.deleteRequirement, action.payload.requirementId);

    yield put(deleteRequirementSuccessAction(action.payload.requirementId));
  } catch (error) {
    yield put(deleteRequirementErrorAction(DELETE_REQUIREMENT_ERROR));
  }
}

export function* generateRequirementCopies(action: GenerateRequirementCopiesAction): Generator {
  yield put(generateRequirementCopiesRequestAction(action.payload.requirementId, action.payload.requirementCopies, action.payload.parentId));
}

export function* generateRequirementCopiesRequest(action: GenerateRequirementCopiesRequestAction): Generator {
  try {
    const copies = yield call(RequirementService.createRequirementCopies, action.payload.requirementId, action.payload.requirementCopies);

    yield put(generateRequirementCopiesSuccessAction(copies as Requirement[], action.payload.parentId));
    notify("Les copies d'exigence ont été créées avec succès");
  } catch (error) {
    yield put(generateRequirementCopiesErrorAction(GENERATE_REQUIREMENT_COPIES_ERROR));
  }
}

export function* addRequirementOption(action: AddRequirementOptionAction): Generator {
  yield put(addRequirementOptionRequestAction(action.payload.option));
}

export function* addRequirementOptionRequest(action: AddRequirementOptionRequestAction): Generator {
  try {
    if (action.payload.option instanceof RequirementProblematicOption) {
      const newRequirementProblematicOption = yield call(RequirementService.addRequirementProblematicOption, action.payload.option);

      yield put(addRequirementOptionSuccessAction(newRequirementProblematicOption as RequirementProblematicOption));
    } else {
      const newRequirementItemOption = yield call(RequirementService.addRequirementItemOption, action.payload.option);
      yield put(addRequirementOptionSuccessAction(newRequirementItemOption as RequirementProblematicOption));
    }
  } catch (error) {
    yield put(addRequirementOptionErrorAction(ADD_REQUIREMENT_OPTION_ERROR));
  }
}

function* requirementSaga(): Generator {
  yield takeLatest(RequirementActionTypes.GET_REQUIREMENT, getRequirement);
  yield takeLatest(RequirementActionTypes.GET_REQUIREMENT_REQUEST, getRequirementRequest);
  yield takeLatest(RequirementActionTypes.GET_REQUIREMENTS, getRequirements);
  yield takeLatest(RequirementActionTypes.GET_REQUIREMENTS_REQUEST, getRequirementsRequest);
  yield takeLatest(RequirementActionTypes.GET_REQUIREMENT_OPTIONS, getRequirementOptions);
  yield takeLatest(RequirementActionTypes.GET_REQUIREMENT_OPTIONS_REQUEST, getRequirementOptionsRequest);
  yield takeLatest(RequirementActionTypes.ADD_REQUIREMENT, addRequirement);
  yield takeLatest(RequirementActionTypes.ADD_REQUIREMENT_REQUEST, addRequirementRequest);
  yield takeLatest(RequirementActionTypes.UPDATE_REQUIREMENT, updateRequirement);
  yield takeLatest(RequirementActionTypes.UPDATE_REQUIREMENT_REQUEST, updateRequirementRequest);

  yield takeLatest(RequirementActionTypes.DELETE_REQUIREMENT, deleteRequirement);
  yield takeLatest(RequirementActionTypes.DELETE_REQUIREMENT_REQUEST, deleteRequirementRequest);
  yield takeLatest(RequirementActionTypes.GENERATE_REQUIREMENT_COPIES, generateRequirementCopies);
  yield takeLatest(RequirementActionTypes.GENERATE_REQUIREMENT_COPIES_REQUEST, generateRequirementCopiesRequest);
  yield takeLatest(RequirementActionTypes.ADD_REQUIREMENT_OPTION, addRequirementOption);
  yield takeLatest(RequirementActionTypes.ADD_REQUIREMENT_OPTION_REQUEST, addRequirementOptionRequest);
}

export default requirementSaga;
