import { initialSearchState, SearchState } from "./state";
import { GetSearchResultsErrorAction, GetSearchResultsSuccessAction, SearchAction, SearchActionTypes } from "./types";

const getSearchResults = (state: SearchState): SearchState => ({
  ...state,
  errorMessage: ""
});

const getSearchResultsRequest = (state: SearchState): SearchState => ({
  ...state,
  isFetching: true
});

const getSearchResultsSuccess = (state: SearchState, action: GetSearchResultsSuccessAction): SearchState => ({
  ...state,
  isFetching: false,
  searchResults: action.payload.searchResults
});

const getSearchResultsError = (state: SearchState, action: GetSearchResultsErrorAction): SearchState => ({
  ...state,
  isFetching: false,
  errorMessage: action.payload
});

const reset = (state: SearchState): SearchState => ({
  ...state,
  isFetching: false,
  errorMessage: ""
});

const searchReducer = (state = initialSearchState, action: SearchAction): SearchState => {
  switch (action.type) {
    case SearchActionTypes.GET_SEARCH_RESULTS:
      return getSearchResults(state);
    case SearchActionTypes.GET_SEARCH_RESULTS_REQUEST:
      return getSearchResultsRequest(state);
    case SearchActionTypes.GET_SEARCH_RESULTS_SUCCESS:
      return getSearchResultsSuccess(state, action);
    case SearchActionTypes.GET_SEARCH_RESULTS_ERROR:
      return getSearchResultsError(state, action);
    case SearchActionTypes.RESET_SEARCH:
      return reset(state);
    default:
      return state;
  }
};

export default searchReducer;
