import { css } from "styled-components";
import { colors } from "ui/colors";

// TODO: move form css into Form component
export const formStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  h3 > span {
    color: ${colors.ACCENT_BLUE};
    font-weight: bold;
    font-size: 1.6em;
    margin-right: 6px;
  }

  label.required::after,
  div.required > label::after {
    content: "*";
    color: red;
    margin-left: 3px;
  }

  input[type="date"]::-webkit-inner-spin-button {
    appearance: none;
    display: none;
  }

  .date-field {
    margin-left: 8px;
  }

  .date-field input {
    padding: 9.5px 14px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
  }

  .date-field input:focus {
    border-color: #85b7d9;
    box-shadow: none;
    outline: 1px #85b7d9;
  }

  .label {
    font-weight: bold;
  }

  .unabled-textarea {
    background-color: #e8e8e8;
  }

  .informations > *:not(:last-child) {
    margin-bottom: 1em;
  }
`;
