import { notify } from "app/view/common/toast/Toast";
import { EventChannel, eventChannel } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";

import { updateConnectionStatusAction } from "./actions";
import { ConnectionStatus } from "./types";

export const OFFLINE_STATUS_MESSAGE = "Vous êtes hors ligne";
export const ONLINE_STATUS_MESSAGE = "Vous êtes de retour en ligne!";

// TODO: find a way to test the connection channel
function createConnectionChannel(): EventChannel<ConnectionStatus> {
  return eventChannel((emit) => {
    const onlineHandler = (): void => {
      emit(ConnectionStatus.ONLINE);
    };

    const offlineHandler = (): void => {
      emit(ConnectionStatus.OFFLINE);
    };

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return (): void => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  });
}

// TODO: the notifications and messages are temporary (we will find a better way to display the connection status)
export function* handleConnectionChange(status: ConnectionStatus): Generator {
  if (status === ConnectionStatus.OFFLINE) {
    notify(OFFLINE_STATUS_MESSAGE);
  } else if (status === ConnectionStatus.ONLINE) {
    notify(ONLINE_STATUS_MESSAGE);
  }

  yield put(updateConnectionStatusAction(status));
}

function* connectionSaga(): Generator {
  const connectionChannel = yield createConnectionChannel();

  yield takeLatest(connectionChannel as EventChannel<ConnectionStatus>, handleConnectionChange);
}

export default connectionSaga;
