import Notification from "app/domain/notification";
import { NotifiableType } from "app/domain/notification/notification";
import _ from "lodash";

export type NotificationDTO = {
  id: number;
  is_read: boolean;
  notifiable_type: NotifiableType;
  reply?: string;
  comment: string;
  site_id: string;
  asset_id: string;
  parent_id: string;
  created_by: string;
  project_id: string;
  parent_type: string;
  created_at: string;
};

class NotificationMapper {
  static fromDTO(notificationDTO: NotificationDTO): Notification;
  static fromDTO(notificationDTO: NotificationDTO[]): Notification[];
  static fromDTO(notificationDTO: NotificationDTO | NotificationDTO[]): Notification[] | Notification {
    if (!Array.isArray(notificationDTO)) {
      return new Notification({
        id: _.get(notificationDTO, "id"),
        isRead: _.get(notificationDTO, "is_read"),
        notifiableType: _.get(notificationDTO, "notifiable_type"),
        comment: _.get(notificationDTO, ["comment"]),
        reply: _.get(notificationDTO, ["reply"]),
        siteId: _.get(notificationDTO, ["site_id"]),
        assetId: _.get(notificationDTO, ["asset_id"]),
        parentId: _.get(notificationDTO, ["parent_id"]),
        createdBy: _.get(notificationDTO, ["created_by"]),
        projectId: _.get(notificationDTO, ["project_id"]),
        parentType: _.get(notificationDTO, ["parent_type"]),
        createdAt: new Date(_.get(notificationDTO, "created_at"))
      });
    }
    return notificationDTO.map((notification: NotificationDTO): Notification => this.fromDTO(notification));
  }
}

export default NotificationMapper;
