import { Avatar, IconButton, Menu as MenuUI } from "@mui/material";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import Avatars from "../../app/view/comment/avatars";
import { style, styleModifiers } from "./style";

const StyledMenu = styled(MenuUI)`
  & .MuiPaper-root {
    border-radius: 6px;
    margin-top: 8px;
    min-width: 180px;
    box-shadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px";
    & .MuiMenu-list {
      padding: 4px 0;
      display: flex;
      flex-direction: column;
    }
    & .MuiMenuItem-root {
      padding: 4px;
      justify-content: flex-start;
    }
  }
`;

interface MenuProps extends ComponentProps {
  label: string;
  children: JSX.Element[] | JSX.Element;
  closeMenu?: boolean;
}

const Menu: FC<MenuProps> = ({ label, children, closeMenu }: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (closeMenu) {
      setIsOpen(false);
    }
  }, [closeMenu]);

  const handleClick = useCallback(
    (event) => {
      setIsOpen(!isOpen);
      setAnchorEl(event.currentTarget);
    },
    [isOpen]
  );
  const handleClose = useCallback(() => {
    setIsOpen(false);
    setAnchorEl(null);
  }, []);

  return (
    <div>
      <IconButton onClick={handleClick} size="small" data-test-id="IconButton">
        <Avatar alt="David's dog" src={Avatars.Default} style={{ backgroundColor: "blue" }} />
      </IconButton>
      <StyledMenu
        id="menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={isOpen}
        onClose={handleClose}
      >
        {children}
      </StyledMenu>
    </div>
  );
};

export default createStyledComponent(Menu, style, styleModifiers);
