import { Uniformat } from "app/domain/uniformat";

import SystemModelAction from "./systemModelAction";

interface SystemModelProps {
  id: string;
  uniformat: Uniformat;
  name: string;
  actions: SystemModelAction[];
}

class SystemModel {
  id: string;
  uniformat: Uniformat;
  name: string;
  actions: SystemModelAction[];

  constructor(systemModelProps: SystemModelProps) {
    this.id = systemModelProps.id;
    this.uniformat = systemModelProps.uniformat;
    this.name = systemModelProps.name;
    this.actions = systemModelProps.actions;
  }
}

export default SystemModel;
