/* eslint-disable jsx-a11y/label-has-associated-control */

import { Asset } from "app/domain/asset";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Form as SemanticForm, TextArea as SemanticTextArea } from "semantic-ui-react";

import { AssetFields } from "../../../useAsset";

interface additionalInformationProps extends ComponentProps {
  asset: Asset | undefined;
  onUpdate: (fields: AssetFields) => void;
}

const AdditionalInformation: FC<additionalInformationProps> = ({ asset, onUpdate }: additionalInformationProps) => {
  const handleChange = (event: any): void => {
    event.preventDefault();

    onUpdate({ [event.target.name]: event.target.value });
  };

  return (
    <>
      <div>
        <label htmlFor="notes">Commentaires (pour le client)</label>
      </div>
      <SemanticForm.Group unstackable widths="2">
        <SemanticTextArea
          required
          size="large"
          placeholder="Commentaires (pour le client)"
          name="client_comments"
          value={asset && asset.client_comments ? asset.client_comments : ""}
          onChange={handleChange}
        />
      </SemanticForm.Group>
    </>
  );
};

export default AdditionalInformation;
