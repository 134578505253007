import { Cable } from "actioncable";
import { EntityCategory } from "app/domain/entityCategory";
import { Params, useRouter } from "app/routing/routerProvider";
import { getAssetsAction } from "app/store/asset/actions";
import { useLoader } from "app/view/common/loader/loaderProvider";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { css } from "styled-components";
import { Loader } from "ui/loader";

import SiteForm from "./siteForm";

const modifySiteContainerStyle = css`
  position: relative;
  flex: 1;
`;

type ModifySiteContainerProps = ComponentProps & { cable: Cable };

const ModifySiteContainer: FC<ModifySiteContainerProps> = ({ className, cable }: ModifySiteContainerProps) => {
  const { getParam } = useRouter();
  const { isActive } = useLoader();

  const siteId = getParam(Params.SITE);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssetsAction(EntityCategory.SITE, siteId));
  }, [dispatch, siteId]);

  return (
    <div className={className}>
      <Loader isActive={isActive} />
      <SiteForm cable={cable} />
    </div>
  );
};

export default createStyledComponent(ModifySiteContainer, modifySiteContainerStyle);
