import { Local } from "app/domain/local";

export type LocalDTO = {
  id: string;
  number?: string;
  description?: string;
  area?: number;
  floor_number?: string;
  room_number?: string;
  asset_id?: string;
};

class LocalMapper {
  static fromDTO(localDTO: LocalDTO): Local;
  static fromDTO(localDTOs: LocalDTO[]): Local[];
  static fromDTO(localDTOs: LocalDTO | LocalDTO[]): any {
    if (!Array.isArray(localDTOs)) {
      return new Local({
        id: localDTOs.id,
        number: localDTOs.room_number,
        description: localDTOs.description,
        area: localDTOs.area,
        assetId: localDTOs.asset_id,
        floorNumber: localDTOs.floor_number
      });
    }
    return localDTOs.map((localDTO: LocalDTO): Local => this.fromDTO(localDTO));
  }

  static toDTO(local: Local): LocalDTO;
  static toDTO(locals: Local[]): LocalDTO[];
  static toDTO(locals: Local | Local[]): any {
    if (!Array.isArray(locals)) {
      return {
        id: locals.id,
        local_number: locals.number,
        local_use: locals.description,
        area: locals.area,
        asset_id: locals.assetId,
        floor_number: locals.floorNumber
      };
    }
    return locals.map((local: Local): LocalDTO => this.toDTO(local));
  }
}

export default LocalMapper;
