import api from "app/service/rsmeans/rsmeansApi";

import CostItemMapper, { CostItemDTO } from "../costItem/costItemMapper";

export type catalogsParams = { measurement?: string; release?: string; cost_data_format?: string; location?: string; labor_type?: string };

class rsmeansService {
  static getRsmeansCatalogs = async ({ measurement, release, cost_data_format, location, labor_type }: catalogsParams): Promise<any> => {
    const catalogs = await api.getRsmeansCatalog({ measurement, release, cost_data_format, location, labor_type });
    return catalogs;
  };

  static getRsmeansLaborType = async (): Promise<any> => {
    const laborType = await api.getRsmeansLaborType();
    return laborType;
  };

  static getRsmeansLocation = async (): Promise<any> => {
    const location = await api.getRsmeansLocation();
    return location;
  };

  static getRsmeansMeasurements = async (): Promise<any> => {
    const measurements = await api.getRsmeansMeasurements();
    return measurements;
  };

  static getRsmeansRelease = async (): Promise<any> => {
    const release = await api.getRsmeansRelease();
    return release;
  };

  static getRsmeansUnitDivision = async (catId: string): Promise<any> => {
    const unitDivision = await api.getUnitDivision(catId);
    return unitDivision;
  };

  static getRsmeansUnitDivisionChildren = async (catId: string, id: string): Promise<any> => {
    const unitDivisionChildren = await api.getUnitDivisionChildren(catId, id);
    return unitDivisionChildren;
  };

  static searchRsmeansUnitCostline = async ({ catId, searchTerm, divisionCode }: { catId: string; searchTerm?: string; divisionCode?: string }): Promise<any> => {
    const costline = await api.searchUnitCostline({ catId, searchTerm, divisionCode });
    return costline;
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  static setCostline = async (data: {}): Promise<any> => {
    const costline = await api.setCostline(data);
    return CostItemMapper.fromDTO(costline.data as CostItemDTO);
  };
}

export default rsmeansService;
