import { UniformatLevel1 } from "./uniformatLevel1";

interface UniformatProps {
  level1?: UniformatLevel1;
  level2: string;
  level3: string;
  level4: string;
}

class Uniformat {
  level1?: UniformatLevel1;
  level2: string;
  level3: string;
  level4: string;

  constructor(uniformatProps: UniformatProps) {
    this.level1 = uniformatProps.level1;
    this.level2 = uniformatProps.level2;
    this.level3 = uniformatProps.level3;
    this.level4 = uniformatProps.level4;
  }
}

export default Uniformat;
