import System from "app/domain/system/system";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import compare from "react-fast-compare";
import { css } from "styled-components";
import { ErrorMessage, useErrorMessage } from "ui/errorMessage";

import SystemCopyRow from "./systemCopyRow/systemCopyRow";
import { SystemCopyFields } from "./useSystemCopyTable";

const systemCopyTableStyle = css`
  overflow: auto;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  flex: 1;
  width: fit-content;

  .systemCopyTable {
    height: fit-content;
    width: fit-content;
    padding: 20px;

    > *:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

interface SystemCopyTableProps extends ComponentProps {
  systemCopies: System[];
  onUpdate: (systemCopyFields: SystemCopyFields, index: number) => void;
}

const isEqual = (prevProps: SystemCopyTableProps, nextProps: SystemCopyTableProps): boolean => compare(prevProps.systemCopies, nextProps.systemCopies);

const SystemCopyTable: FC<SystemCopyTableProps> = ({ systemCopies, onUpdate, className }: SystemCopyTableProps) => {
  const { errorMessage: currentErrorMessage, onUpdate: onErrorMessageUpdate, onDismiss } = useErrorMessage();

  return (
    <>
      <ErrorMessage message={currentErrorMessage} onDismiss={onDismiss} data-test-id="errorMessage" />
      <div className={className}>
        <div className="systemCopyTable" data-test-id="systemCopyTable">
          {systemCopies.map((systemCopy: System, index: number) => (
            <SystemCopyRow system={systemCopy} onUpdate={onUpdate} onError={onErrorMessageUpdate} index={index} key={systemCopy.id} />
          ))}
        </div>
      </div>
    </>
  );
};

export default createStyledComponent(memo(SystemCopyTable, isEqual), systemCopyTableStyle);
