import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";
import { EmptyState } from "ui/emptyState";
import { IconType } from "ui/icon";

const DROPZONE_EMPTY_STATE_TITLE = "Choisissez des images";
const DROPZONE_EMPTY_STATE_DESCRIPTION = "Faites glisser les images ou cliquez sur les boutons ci-dessous";

export const dropzoneStyle = css`
  .dzu-dropzone {
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

export interface DropzoneProps extends ComponentProps {
  previews: React.ReactNode[] | null;
  dropzoneProps: {
    ref: React.RefObject<HTMLDivElement>;
    className: string;
    style?: React.CSSProperties;
    onDragEnter: (event: React.DragEvent<HTMLElement>) => void;
    onDragOver: (event: React.DragEvent<HTMLElement>) => void;
    onDragLeave: (event: React.DragEvent<HTMLElement>) => void;
    onDrop: (event: React.DragEvent<HTMLElement>) => void;
  };
}

const Dropzone: FC<DropzoneProps> = ({ className, previews, dropzoneProps }: DropzoneProps) => (
  <div className={className}>
    <div {...dropzoneProps} data-test-id="dropzone">
      {(previews as React.ReactNode[]).length === 0 ? (
        <EmptyState iconType={IconType.IMAGE} title={DROPZONE_EMPTY_STATE_TITLE} description={DROPZONE_EMPTY_STATE_DESCRIPTION} />
      ) : (
        previews
      )}
    </div>
  </div>
);

export default createStyledComponent(Dropzone, dropzoneStyle);
