import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

const formFieldGroupStyle = css`
  display: flex;
  flex-direction: ${(props: StyleProps): string => (props.row ? "row" : "column")};
  margin-left: 10px;
  flex-wrap: wrap;

  .field {
    margin-left: ${(props: StyleProps): string => (props.row ? "8px" : "0")};
    display: ${(props: StyleProps): string => (props.row ? "inline-block" : "block")};
    padding: 5px;

    label {
      display: ${(props: StyleProps): string => (props.row ? "block" : "inline-block")};
      width: ${(props: StyleProps): string => (props.row ? "auto" : `${props.percentWidth || 20}%`)};
      text-align: ${(props: StyleProps): string => (props.row ? "left" : "right")};
      margin-right: ${(props: StyleProps): string => (props.row ? "0" : "8px;")};
    }

    textarea {
      min-height: 90px;
      width: ${(props: StyleProps): string => (props.row ? "auto" : `${80 - props.percentWidth || 54}%`)};
    }

    &.big {
      width: 100%;
      padding: 10px;

      div.input {
        width: 100%;
      }
    }

    div.transparent {
      padding: 6px 14px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      margin-bottom: -30px;

      & > input {
        pointer-events: none;
      }
    }
  }
`;

interface FormFieldGroupProps extends ComponentProps {
  children: any;
  row?: boolean;
  percentWidth?: number;
}

const FormFieldGroup: FC<FormFieldGroupProps> = ({ children, className }: FormFieldGroupProps) => <div className={className}>{children}</div>;

export default createStyledComponent(FormFieldGroup, formFieldGroupStyle);
