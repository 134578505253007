import { SelectChangeEvent } from "@mui/material/Select";
import { Comment } from "app/domain/comment";
import CommentThread from "app/view/comment/commentThread";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, MouseEventHandler } from "react";
import { Accordion, Header } from "semantic-ui-react";
import Select from "ui/Select";

interface CollapsableCommentThreadProps extends ComponentProps {
  comment: Comment;
  fieldValue: string;
  DROPDOWN_OPTIONS: { key: string; text: string; value: string }[];
}

const CollapsableCommentThread: FC<CollapsableCommentThreadProps> = ({ comment, fieldValue, DROPDOWN_OPTIONS }: CollapsableCommentThreadProps) => {
  const [isActive, setIsActive] = React.useState(!comment.resolvedBy);
  const [isEditing, setIsEditing] = React.useState(false);
  const [fieldName, setFieldName] = React.useState(comment.fieldName);

  const toggleIsActive: MouseEventHandler<HTMLDivElement> = () => {
    setIsActive(!isActive);
  };

  const selectFieldName = (event: SelectChangeEvent): void => {
    const selectedFieldName = event.target.value as string;
    setFieldName(selectedFieldName);
    event.stopPropagation();
  };

  const commentPreview = <div>{comment.text}</div>;
  const title = (
    <div>
      <Header size="small">
        {isEditing ? <Select options={DROPDOWN_OPTIONS} onChange={selectFieldName} value={fieldName} name="Nom du champ" /> : `${comment.fieldName}: ${fieldValue}`}
      </Header>
      {!isActive && commentPreview}
    </div>
  );

  const collapseThread = (): void => {
    setIsActive(false);
  };

  const commentThread = <CommentThread comment={comment} onResolve={collapseThread} isEditing={isEditing} setIsEditing={setIsEditing} fieldName={fieldName} />;

  return (
    <Accordion styled>
      <Accordion.Title content={title} active={isActive} onClick={toggleIsActive} />
      <Accordion.Content content={commentThread} active={isActive} />
    </Accordion>
  );
};
export default CollapsableCommentThread;
