import { createAction } from "@reduxjs/toolkit";
import { ColumnState } from "ag-grid-community";
import { Filter } from "app/domain/filter";
import AgGridCustomFilterState from "app/view/gridTemplates/agGridCustomFilterState";

import { FilterActionTypes } from "./types";

export const setFiltersAction = createAction(FilterActionTypes.SET_FILTERS, (table: string, filters: Filter[]) => ({
  payload: {
    table,
    filters
  }
}));

export const setFiltersActionV2 = createAction(FilterActionTypes.SET_FILTERS_V2, (table: string, columnState: ColumnState[]) => ({
  payload: {
    table,
    columnState
  }
}));

export const setAgGridCustomFilterStateAction = createAction(FilterActionTypes.SET_AG_GRID_CUSTOM_FILTER_STATE, (table: string, model: any) => ({
  payload: {
    table,
    model
  }
}));

export const resetAgGridCustomFilterStateAction = createAction(FilterActionTypes.RESET_AG_GRID_CUSTOM_FILTER_STATE, (table: string) => ({
  payload: {
    table
  }
}));

export const deleteAgGridCustomFilterStateAction = createAction(FilterActionTypes.RESET_AG_GRID_CUSTOM_FILTER_STATE, (table: string, deletedColumn: string) => ({
  payload: {
    table,
    deletedColumn
  }
}));
