import {
  HeaderGroup as ReactTableHeaderGroup,
  Row as ReactTableRow,
  UseFiltersColumnProps as ReactTableFilteredColumn,
  UseSortByColumnProps as ReactTableSortedColumn
} from "react-table";

export enum ColumnAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right"
}

export type ColumnStyle = {
  alignment?: ColumnAlignment;
  width?: number;
};

export interface Column {
  Header: string;
  field: string;
  select?: boolean;
  sort?: boolean;
  hidden?: boolean;
  style?: ColumnStyle;
  total?: string;
  onClick?: (rowData: RowData) => void;
}

export interface Row extends ReactTableRow {
  alignments: ColumnAlignment[];
  colors: string[];
  onClick: ((rowData: RowData) => void | undefined)[];
}

export type RowData = {
  [key: string]: any;
  id: string;
};

export type TableHeaderColumn = ReactTableHeaderGroup<any> &
  ReactTableFilteredColumn<any> &
  ReactTableSortedColumn<any> & {
    field: string;
    select: boolean;
    sort: boolean;
  };
