/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */

import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css, SimpleInterpolation } from "styled-components";
import { Button, IconButton } from "ui/button";
import * as constants from "ui/button/constants";
import { Icon, IconType } from "ui/icon";

import ProgressBar from "./progressBar";

const downloadButtonStyle = css`
  width: fit-content;
  transition: width 0.5s;
  border-radius: ${constants.DEFAULT_BUTTON_BORDER_RADIUS};

  ${Button as unknown as SimpleInterpolation} {
    border-radius: inherit;
  }

  .progressBarContainer {
    display: flex;
    border-radius: inherit;

    ${ProgressBar as unknown as SimpleInterpolation} {
      border-radius: inherit;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    ${IconButton as unknown as SimpleInterpolation} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

export interface DownloadButtonProps extends ComponentProps {
  label: string;
  downloadLabel: string;
  onDownloadStart: () => void;
  onDownloadStop: () => void;
  progress: number;
  isDownloading: boolean;
}

const DownloadButton: FC<DownloadButtonProps> = ({ label, downloadLabel, onDownloadStart, onDownloadStop, progress, isDownloading, className }: DownloadButtonProps) => (
  <div className={className}>
    {isDownloading ? (
      <div className="progressBarContainer">
        <ProgressBar title={downloadLabel} progress={progress} data-test-id="progressBar" />
        <IconButton onClick={onDownloadStop} data-test-id="cancelDownloadButton">
          <Icon type={IconType.CANCEL} />
        </IconButton>
      </div>
    ) : (
      <Button title={label} iconType={IconType.DOWNLOAD} onClick={onDownloadStart} data-test-id="downloadButton" />
    )}
  </div>
);

export default createStyledComponent(DownloadButton, downloadButtonStyle);
