import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { IconType } from "ui/icon";

import HeaderButton from "./headerButton";

const NAVIGATE = "Naviguer";

interface MenuButtonProps extends ComponentProps {
  onClick: () => void;
}

const MenuButton: FC<MenuButtonProps> = ({ onClick }: MenuButtonProps) => <HeaderButton onClick={onClick} label={NAVIGATE} icon={IconType.SITE_MAP} />;

export default MenuButton;
