/* stylelint-disable value-keyword-case */

import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Form as SemanticForm } from "semantic-ui-react";
import { css } from "styled-components";
import * as constants from "ui/form/controls/constants";
import { formControlStyle } from "ui/form/controls/style";

const textAreaStyle = css`
  ${formControlStyle};

  && textarea {
    flex: 1;
    resize: none;
    outline: none;
    border: ${constants.DEFAULT_FORM_CONTROL_BORDER};
    border-radius: ${constants.DEFAULT_FORM_CONTROL_BORDER_RADIUS};
    transition: border-color 0.1s ease;
    padding: 0.78571429em 1em;
    color: ${constants.DEFAULT_FORM_CONTROL_TEXT_COLOR};
    font-size: 1em;
    line-height: 1.2857;
  }

  && textarea:hover {
    border-color: ${constants.DEFAULT_FORM_CONTROL_HOVER_COLOR};
  }

  && textarea:focus {
    border-color: ${constants.DEFAULT_FORM_CONTROL_FOCUS_COLOR};
  }
`;

interface TextAreaProps extends ComponentProps {
  text: string;
  onInput: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  label?: string;
  readOnly?: boolean;
}

const TextArea: FC<TextAreaProps> = ({ text, onInput, label, readOnly, className }: TextAreaProps) => (
  <SemanticForm.TextArea className={className} label={label} readOnly={readOnly} value={text} onInput={onInput} data-test-id="textArea" />
);

export default createStyledComponent(TextArea, textAreaStyle);
