import { UserComment } from "app/domain/comment/index";

export type UserCommentDTO = {
  comment_id: number;
  parent_category: string;
  project_id: string;
  site_id: string;
  asset_id: string;
  parent_id: string;
  site_name: string;
  asset_number: string;
  parent_name: string;
  message: string;
  author: string;
  is_resolved: boolean;
  is_read: boolean;
  created_at: string;
};

class UserCommentMapper {
  static fromDTO(userCommentDTO: UserCommentDTO): UserComment;
  static fromDTO(userCommentDTOs: UserCommentDTO[]): UserComment[];
  static fromDTO(userCommentDTOs: UserCommentDTO | UserCommentDTO[]): any {
    if (!Array.isArray(userCommentDTOs)) {
      return new UserComment({
        commentId: userCommentDTOs.comment_id,
        parentCategory: userCommentDTOs.parent_category,
        projectId: userCommentDTOs.project_id,
        siteId: userCommentDTOs.site_id,
        assetId: userCommentDTOs.asset_id,
        parentId: userCommentDTOs.parent_id,
        siteName: userCommentDTOs.site_name,
        assetNumber: userCommentDTOs.asset_number,
        parentName: userCommentDTOs.parent_name,
        message: userCommentDTOs.message,
        author: userCommentDTOs.author,
        isResolved: userCommentDTOs.is_resolved,
        isRead: userCommentDTOs.is_read,
        createdAt: userCommentDTOs.created_at
      });
    }
    return userCommentDTOs.map((userCommentDTO: UserCommentDTO): UserComment => this.fromDTO(userCommentDTO));
  }
}

export default UserCommentMapper;
