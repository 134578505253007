import { createAction } from "@reduxjs/toolkit";
import { EntityCategory } from "app/domain/entityCategory";
import Photo, { PhotosFolder } from "app/domain/photo";

import { PhotoActionTypes } from "./types";

export const addPhotosAction = createAction(PhotoActionTypes.ADD_PHOTOS, (photoFiles: File[], entityId: string, entityCategory: EntityCategory) => ({
  payload: {
    entityId,
    photoFiles,
    entityCategory
  }
}));

export const addPhotosRequestAction = createAction(PhotoActionTypes.ADD_PHOTOS_REQUEST, (photoFiles: File[], entityId: string, entityCategory: EntityCategory) => ({
  payload: {
    entityId,
    photoFiles,
    entityCategory
  }
}));

export const addPhotosSuccessAction = createAction(PhotoActionTypes.ADD_PHOTOS_SUCCESS, (photos: Photo[], entityId: string) => ({
  payload: {
    photos,
    entityId
  }
}));

export const addPhotosErrorAction = createAction(PhotoActionTypes.ADD_PHOTOS_ERROR, (errorMessage: string, entityId: string) => ({
  payload: {
    errorMessage,
    entityId
  }
}));

export const deletePhotosAction = createAction(PhotoActionTypes.DELETE_PHOTOS, (photoIds: string[], entityId: string) => ({
  payload: {
    photoIds,
    entityId
  }
}));

export const deletePhotosRequestAction = createAction(PhotoActionTypes.DELETE_PHOTOS_REQUEST, (photoIds: string[], entityId: string) => ({
  payload: {
    photoIds,
    entityId
  }
}));

export const deletePhotoSuccessAction = createAction(PhotoActionTypes.DELETE_PHOTO_SUCCESS, (photoId: string, entityId: string) => ({
  payload: {
    photoId,
    entityId
  }
}));

export const deletePhotosErrorAction = createAction(PhotoActionTypes.DELETE_PHOTOS_ERROR, (errorMessage: string, entityId: string) => ({
  payload: {
    errorMessage,
    entityId
  }
}));

export const getPhotosRequestAction = createAction(PhotoActionTypes.GET_PHOTOS_REQUEST, (entityId: string, entityCategory: EntityCategory) => ({
  payload: {
    entityId,
    entityCategory
  }
}));

export const getPhotosAction = createAction(PhotoActionTypes.GET_PHOTOS, (entityId: string, entityCategory: EntityCategory) => ({
  payload: {
    entityId,
    entityCategory
  }
}));

export const getPhotosSuccessAction = createAction(PhotoActionTypes.GET_PHOTOS_SUCCESS, (photos: Photo[], entityId: string) => ({
  payload: {
    photos,
    entityId
  }
}));

export const getPhotosErrorAction = createAction(PhotoActionTypes.GET_PHOTOS_ERROR, (errorMessage: string, entityId: string) => ({
  payload: {
    errorMessage,
    entityId
  }
}));

export const getPhotosFolderRequestAction = createAction(PhotoActionTypes.GET_PHOTOS_FOLDER_REQUEST, (entityId: string, entityCategory: EntityCategory) => ({
  payload: {
    entityId,
    entityCategory
  }
}));

export const startDownloadingPhotosFolderAction = createAction(PhotoActionTypes.START_DOWNLOADING_PHOTOS_FOLDER_ACTION, (entityId: string, entityCategory: EntityCategory) => ({
  payload: {
    entityId,
    entityCategory
  }
}));

export const setPhotosFolderAction = createAction(PhotoActionTypes.SET_PHOTOS_FOLDER_ACTION, (photosFolder: PhotosFolder, entityId: string, entityCategory: EntityCategory) => ({
  payload: {
    photosFolder,
    entityId,
    entityCategory
  }
}));

export const getPhotosFolderErrorAction = createAction(PhotoActionTypes.GET_PHOTOS_FOLDER_ERROR, (errorMessage: string, entityId: string) => ({
  payload: {
    errorMessage,
    entityId
  }
}));

export const stopDownloadingPhotosFolderAction = createAction(PhotoActionTypes.STOP_DOWNLOADING_PHOTOS_FOLDER_ACTION, (entityId: string) => ({
  payload: {
    entityId
  }
}));

export const resetAction = createAction(PhotoActionTypes.RESET_PHOTO);
