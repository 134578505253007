/* eslint-disable no-nested-ternary, react-hooks/exhaustive-deps */

import { System, SystemModel, SystemModelAction } from "app/domain/system";
import { Uniformat } from "app/domain/uniformat";
import SystemService from "app/service/system/systemService";
import useDebounce from "app/utils/useDebounce";
import { useUniformat } from "app/view/uniformat/index";
import UniformatSection from "app/view/uniformat/uniformatSection";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect } from "react";
import { useAsync } from "react-async";
import { css } from "styled-components";
import { TextField, useTextField } from "ui/form/controls/textField/index";

const searchTableControlStyle = css`
  .input {
    width: 50%;
  }
`;

interface SearchSystemModelControlProps extends ComponentProps {
  projectId: string;
  system: System | undefined;
  isModalOpen: boolean;
  onUpdateModels: (updatedModels: SystemModel[]) => void;
  onUpdateActions: (updatedActions: SystemModelAction[]) => void;
  onUpdateIsLoadingSystemModels: (isLoadingSystemModels: boolean) => void;
  onReset: () => void;
  onErrorMessageUpdate: (errorMessage: string) => void;
}

const SearchSystemModelControl: FC<SearchSystemModelControlProps> = ({
  projectId,
  system,
  isModalOpen,
  onUpdateModels,
  onUpdateActions,
  onUpdateIsLoadingSystemModels,
  onReset,
  onErrorMessageUpdate,
  className
}: SearchSystemModelControlProps) => {
  const { text: searchSystemName, onInput: onSearchNameChange } = useTextField("");
  const { debouncedValue: debouncedSearchName } = useDebounce(searchSystemName);
  const { uniformat: currentUniformat, lastUniformat, onUpdate: onUpdateCurrentUniformat } = useUniformat(system && system.uniformat);

  const fetchModels = ([uniformat, name]: string[]): Promise<SystemModel[]> => SystemService.getSystemModels(projectId, uniformat, name);

  const handleFetchModelsError = (): void => {
    onErrorMessageUpdate(`Une erreur s'est produite lors de la récupération des noms des systèmes. Veuillez réessayer`);
    onUpdateIsLoadingSystemModels(false);
  };

  const handleSuccessFetchModels = (systemModelFetched: SystemModel[]): void => {
    onUpdateModels(systemModelFetched);
    onUpdateIsLoadingSystemModels(false);
  };

  const { run: runSystemModels } = useAsync({
    deferFn: fetchModels,
    onResolve: handleSuccessFetchModels,
    onReject: handleFetchModelsError
  });

  const handleSearchNameChange = (newSearchName: string): void => {
    onSearchNameChange(newSearchName);
    onReset();
  };

  useEffect(() => {
    runSystemModels(lastUniformat, debouncedSearchName);
    onUpdateIsLoadingSystemModels(true);
    onUpdateActions([]);
  }, [currentUniformat, debouncedSearchName, lastUniformat]);

  useEffect(() => {
    if (isModalOpen && system && system.systemModelId) {
      onUpdateCurrentUniformat(system.uniformat);

      runSystemModels(system.uniformat.level4 ? system.uniformat.level4 : system.uniformat.level3);
    }
  }, [isModalOpen]);

  const onUpdateUniformat = (uniformat: Uniformat): void => {
    const selectedUniformat = uniformat.level4
      ? uniformat.level4
      : uniformat.level3
      ? uniformat.level3
      : uniformat.level2
      ? uniformat.level2
      : uniformat.level1
      ? uniformat.level1
      : "";
    onUpdateCurrentUniformat(uniformat);
    runSystemModels(selectedUniformat, searchSystemName);
    onUpdateIsLoadingSystemModels(true);
    onReset();
    onUpdateActions([]);
  };

  return (
    <div className={className}>
      <UniformatSection projectId={projectId} uniformat={currentUniformat} onUpdate={onUpdateUniformat} onUpdateError={onErrorMessageUpdate} data-test-id="uniformatSection" />
      <TextField label="Nom du système recherché" text={searchSystemName} onInput={handleSearchNameChange} data-test-id="searchNameTextField" />
    </div>
  );
};

export default createStyledComponent(SearchSystemModelControl, searchTableControlStyle);
