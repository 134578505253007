import GenderedWord from "app/locales/genderedWord";
import { Form, Gender } from "app/locales/types";

class FrenchWord extends GenderedWord {
  private readonly vowels = ["a", "e", "i", "o", "u", "y"];

  formatWithDefinedArticle(): string {
    if (this.form === Form.S) {
      if (this.vowels.includes(this.word.charAt(0))) {
        return `l'${this.word}`;
      }
      if (this.gender === Gender.F) {
        return `la ${this.word}`;
      }
      return `le ${this.word}`;
    }
    return `les ${this.word}`;
  }

  formatWithContractedDefinedArticle(): string {
    if (this.form === Form.S) {
      if (this.vowels.includes(this.word.charAt(0))) {
        return `à l'${this.word}`;
      }
      if (this.gender === Gender.F) {
        return `à la ${this.word}`;
      }
      return `au ${this.word}`;
    }
    return `aux ${this.word}`;
  }
}

export default FrenchWord;
