export enum DictionaryCode {
  FR = "fr",
  EN = "en"
}

export type Dictionary<T> = {
  [index: string]: T;
};

export enum Gender {
  M = "m",
  F = "f"
}

export enum Form {
  S = "s",
  P = "p"
}
