import { Requirement } from "app/domain/requirement";
import { Params, useRouter } from "app/routing/routerProvider";
import { getRequirementsAction } from "app/store/requirement/actions";
import { getCurrentRequirements } from "app/store/requirement/selectors";
import { State } from "app/store/state";
import { Toolbar } from "app/view/common/toolbar";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { AddButton } from "ui/button/actions";

import RequirementTable from "./table/requirementTable";

const ASSET_REQUIREMENT_URL = "/newrequirement";
const ADD = "Ajouter";

const requirementListingStyle = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

type RequirementListingProps = ComponentProps;

const RequirementListing: FC<RequirementListingProps> = ({ className }: RequirementListingProps) => {
  const dispatch = useDispatch();
  const { getParam, goTo } = useRouter();

  const projectId = getParam(Params.PROJECT);
  const siteId = getParam(Params.SITE);
  const assetId = getParam(Params.ASSET);
  const systemId = getParam(Params.SYSTEM);

  useEffect(() => {
    dispatch(getRequirementsAction(assetId));
  }, [assetId, dispatch]);

  const requirements = useSelector((state: State): Requirement[] => getCurrentRequirements(state, systemId));

  const redirectToRequirementCreationPage = (): void => {
    goTo(ASSET_REQUIREMENT_URL, {
      siteId,
      projectId,
      assetId
    });
  };

  const actions = [systemId === undefined && <AddButton onClick={redirectToRequirementCreationPage} label={ADD} key={0} />];

  return (
    <div className={className}>
      <Toolbar actions={actions} />
      <RequirementTable requirements={requirements} />
    </div>
  );
};

export default createStyledComponent(memo(RequirementListing), requirementListingStyle);
