import { Project } from "app/domain/project";

export type ProjectState = {
  isFetching: boolean;
  errorMessage: string;
  projects: Project[];
  currentProject: Project | undefined;
};

export const initialProjectState: ProjectState = {
  isFetching: false,
  errorMessage: "",
  projects: [],
  currentProject: undefined
};
