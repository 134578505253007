import { Site } from "app/domain/site";

export type SiteState = {
  isLoading: boolean;
  errorMessage: string;
  sites: Site[];
  currentSite: Site | undefined;
};

export const initialSiteState: SiteState = {
  isLoading: false,
  errorMessage: "",
  sites: [],
  currentSite: undefined
};
