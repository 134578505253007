/* eslint-disable no-param-reassign  */

import { System } from "app/domain/system";
import ElementsListAPI from "app/service/ElementsListsAPI";
import SystemService from "app/service/system/systemService";
import { notify } from "app/view/common/toast/Toast";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  addSystemErrorAction,
  addSystemRequestAction,
  addSystemSuccessAction,
  deleteSystemErrorAction,
  deleteSystemRequestAction,
  deleteSystemSuccessAction,
  generateSystemCopiesErrorAction,
  generateSystemCopiesRequestAction,
  generateSystemCopiesSuccessAction,
  generateSystemCopiesToDestinationsErrorAction,
  generateSystemCopiesToDestinationsSuccessAction,
  generateSystemLabelsErrorAction,
  generateSystemLabelsRequestAction,
  generateSystemLabelsSuccessAction,
  getSystemErrorAction,
  getSystemOptionsErrorAction,
  getSystemOptionsRequestAction,
  getSystemOptionsSuccessAction,
  getSystemRequestAction,
  getSystemsErrorAction,
  getSystemsRequestAction,
  getSystemsSuccessAction,
  getSystemSuccessAction,
  updateSystemErrorAction,
  updateSystemRequestAction,
  updateSystemSuccessAction
} from "./actions";
import {
  AddSystemAction,
  AddSystemRequestAction,
  DeleteSystemAction,
  DeleteSystemRequestAction,
  GenerateSystemCopiesAction,
  GenerateSystemCopiesRequestAction,
  GenerateSystemCopiesToDestinationRequestAction,
  GenerateSystemLabelsAction,
  GenerateSystemLabelsRequestAction,
  GetSystemAction,
  GetSystemRequestAction,
  GetSystemsAction,
  GetSystemsRequestAction,
  SystemActionTypes,
  UpdateSystemAction,
  UpdateSystemRequestAction
} from "./types";

export const GENERATE_SYSTEM_LABELS_ERROR = "Une erreur s'est produite lors de la génération des codes QR. Veuillez réessayer.";
export const GENERATE_SYSTEM_COPIES_ERROR = "Une erreur s'est produite lors de la génération des copies du système. Veuillez réessayer.";
export const GET_SYSTEM_ERROR = "Une erreur s'est produite lors de la récupération du système. Veuillez réessayer.";
export const GET_SYSTEMS_ERROR = "Une erreur s'est produite lors de la récupération des systèmes. Veuillez réessayer.";
export const GET_SYSTEM_OPTIONS_ERROR = "Une erreur s'est produite lors de la récupération des listes d'options des systèmes. Veuillez réessayer.";
export const ADD_SYSTEM_ERROR = "Une erreur s'est produite lors de la création du système. Veuillez réessayer.";
export const UPDATE_SYSTEM_ERROR = "Une erreur s'est produite lors de la mise à jour du système. Veuillez réessayer.";
export const DELETE_SYSTEM_ERROR = "Une erreur s'est produite lors de la suppression du système. Veuillez réessayer.";

export function* generateSystemLabel(systemId: string): Generator {
  try {
    const systemLabel = yield call(SystemService.createLabel, systemId);

    yield call(SystemService.printSystemLabel, systemLabel);
    const updatedSystem = yield call(SystemService.generateQrCode, systemId);

    yield put(generateSystemLabelsSuccessAction(updatedSystem));
  } catch (error) {
    yield put(generateSystemLabelsErrorAction(GENERATE_SYSTEM_LABELS_ERROR));
  }
}

export function* generateSystemLabelsRequest(action: GenerateSystemLabelsRequestAction): Generator {
  try {
    yield all(action.payload.systemIds.map((systemId: string) => generateSystemLabel(systemId)));
  } catch (error) {
    yield put(generateSystemLabelsErrorAction(GENERATE_SYSTEM_LABELS_ERROR));
  }
}

export function* generateSystemLabels(action: GenerateSystemLabelsAction): Generator {
  yield put(generateSystemLabelsRequestAction(action.payload.systemIds));
}

export function* getSystem(action: GetSystemAction): Generator {
  yield put(getSystemRequestAction(action.payload.systemId));
}

export function* getSystemRequest(action: GetSystemRequestAction): Generator {
  try {
    const system = yield call(SystemService.getSystem, action.payload.systemId);
    yield put(getSystemSuccessAction(system as System));
  } catch (error) {
    yield put(getSystemErrorAction(GET_SYSTEM_ERROR));
  }
}

export function* getSystems(action: GetSystemsAction): Generator {
  yield put(getSystemsRequestAction(action.payload.parentType, action.payload.parentId));
}

export function* getSystemsRequest(action: GetSystemsRequestAction): Generator {
  try {
    const systems = yield call(SystemService.getSystems, action.payload.parentType, action.payload.parentId);
    yield put(getSystemsSuccessAction(systems as System[]));
  } catch (error) {
    yield put(getSystemsErrorAction(GET_SYSTEMS_ERROR));
  }
}

export function* getSystemOptions(): Generator {
  yield put(getSystemOptionsRequestAction());
}

export function* getSystemOptionsRequest(): Generator {
  try {
    const options = yield call(ElementsListAPI.getComponentElementsLists);
    yield put(getSystemOptionsSuccessAction(options));
  } catch (error) {
    yield put(getSystemOptionsErrorAction(GET_SYSTEM_OPTIONS_ERROR));
  }
}

export function* addSystem(action: AddSystemAction): Generator {
  yield put(addSystemRequestAction(action.payload.system, action.payload.isClosing));
}

export function* addSystemRequest(action: AddSystemRequestAction): Generator {
  try {
    const newSystem = yield call(SystemService.createSystem, action.payload.system);

    yield put(addSystemSuccessAction(newSystem as System, action.payload.isClosing));
    notify("Le système a été créé avec succès");
  } catch (error) {
    yield put(addSystemErrorAction(ADD_SYSTEM_ERROR));
  }
}

export function* updateSystem(action: UpdateSystemAction): Generator {
  yield put(updateSystemRequestAction(action.payload.system, action.payload.isClosing));
}

export function* updateSystemRequest(action: UpdateSystemRequestAction): Generator {
  try {
    const updatedSystem = yield call(SystemService.updateSystem, action.payload.system);

    yield put(updateSystemSuccessAction(updatedSystem as System, action.payload.isClosing));
    notify("Le système a été modifié avec succès");
  } catch (error) {
    yield put(updateSystemErrorAction(UPDATE_SYSTEM_ERROR));
  }
}

export function* deleteSystem(action: DeleteSystemAction): Generator {
  yield put(deleteSystemRequestAction(action.payload.systemId));
}

export function* deleteSystemRequest(action: DeleteSystemRequestAction): Generator {
  try {
    yield call(SystemService.deleteSystem, action.payload.systemId);

    yield put(deleteSystemSuccessAction(action.payload.systemId));
    notify("Le système a été supprimé avec succès");
  } catch (error) {
    yield put(deleteSystemErrorAction(DELETE_SYSTEM_ERROR));
  }
}

export function* generateSystemCopies(action: GenerateSystemCopiesAction): Generator {
  yield put(generateSystemCopiesRequestAction(action.payload.systemId, action.payload.systemCopies, action.payload.parentId));
}

export function* generateSystemCopiesRequest(action: GenerateSystemCopiesRequestAction): Generator {
  try {
    const copies = yield call(SystemService.createSystemCopies, action.payload.systemId, action.payload.systemCopies);

    yield put(generateSystemCopiesSuccessAction(copies as System[], action.payload.parentId));
    notify("Les copies de système ont été créées avec succès");
  } catch (error) {
    yield put(generateSystemCopiesErrorAction(GENERATE_SYSTEM_COPIES_ERROR));
  }
}

export function* generateSystemCopiesToDestinationsRequest(action: GenerateSystemCopiesToDestinationRequestAction): Generator {
  try {
    yield call(
      SystemService.createSystemCopiesToDestination,
      action.payload.isCopyingIdentification,
      action.payload.isCopyingQuantity,
      action.payload.systemToCopyIds,
      action.payload.destinationAssetIds
    );

    yield put(generateSystemCopiesToDestinationsSuccessAction());
    notify("Les copies de système ont été créées avec succès");
  } catch (error) {
    yield put(generateSystemCopiesToDestinationsErrorAction());
    notify("Une erreur est survenue lors de la copie.");
  }
}

function* systemSaga(): Generator {
  yield takeLatest(SystemActionTypes.GET_SYSTEM, getSystem);
  yield takeLatest(SystemActionTypes.GET_SYSTEM_REQUEST, getSystemRequest);
  yield takeLatest(SystemActionTypes.GET_SYSTEMS, getSystems);
  yield takeLatest(SystemActionTypes.GET_SYSTEMS_REQUEST, getSystemsRequest);
  yield takeLatest(SystemActionTypes.GET_SYSTEM_OPTIONS, getSystemOptions);
  yield takeLatest(SystemActionTypes.GET_SYSTEM_OPTIONS_REQUEST, getSystemOptionsRequest);
  yield takeLatest(SystemActionTypes.ADD_SYSTEM, addSystem);
  yield takeLatest(SystemActionTypes.ADD_SYSTEM_REQUEST, addSystemRequest);
  yield takeLatest(SystemActionTypes.UPDATE_SYSTEM, updateSystem);
  yield takeLatest(SystemActionTypes.UPDATE_SYSTEM_REQUEST, updateSystemRequest);
  yield takeLatest(SystemActionTypes.DELETE_SYSTEM, deleteSystem);
  yield takeLatest(SystemActionTypes.DELETE_SYSTEM_REQUEST, deleteSystemRequest);
  yield takeEvery(SystemActionTypes.GENERATE_SYSTEM_LABELS, generateSystemLabels);
  yield takeEvery(SystemActionTypes.GENERATE_SYSTEM_LABELS_REQUEST, generateSystemLabelsRequest);
  yield takeLatest(SystemActionTypes.GENERATE_SYSTEM_COPIES, generateSystemCopies);
  yield takeLatest(SystemActionTypes.GENERATE_SYSTEM_COPIES_REQUEST, generateSystemCopiesRequest);
  yield takeLatest(SystemActionTypes.GENERATE_SYSTEM_COPIES_TO_DESTINATIONS_REQUEST, generateSystemCopiesToDestinationsRequest);
}

export default systemSaga;
