import { CostLine } from "app/domain/costItem/index";
import { System, SystemModel, SystemModelAction } from "app/domain/system";
import { SystemFields } from "app/view/system/form/systemForm/useSystem";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { css } from "styled-components";
import { Button } from "ui/button/index";
import { Modal } from "ui/modal/index";

import SystemModelSection from "./systemModelSection";

const SUBMIT = "Appliquer";

const systemModelModalStyle = css`
  .modal-container {
    height: 650px;
    width: 85vw;
    max-width: 85vw;
    min-width: 85vw;
  }
`;

interface SystemModelModalProps extends ComponentProps {
  projectId: string;
  system: System | undefined;
  currentModel: SystemModel;
  currentAction: SystemModelAction;
  isModalOpen: boolean;
  onUpdate: (fields: SystemFields) => void;
  onUpdateModel: (model: SystemModel) => void;
  onUpdateAction: (action: SystemModelAction) => void;
  onCostLinesChange: (costLine: CostLine[]) => void;
  onClose: () => void;
  onReset: () => void;
  withModel: () => void;
}

const SystemModelModal: FC<SystemModelModalProps> = ({
  projectId,
  system,
  currentModel,
  currentAction,
  isModalOpen,
  onUpdate,
  onUpdateModel,
  onUpdateAction,
  onCostLinesChange,
  onClose,
  onReset,
  className,
  withModel
}: SystemModelModalProps) => {
  const handleClose = (): void => {
    onClose();
  };

  const onSubmitSystemModel = (): void => {
    onUpdate({
      systemModelId: currentModel.id,
      uniformat: currentModel.uniformat,
      name: currentModel.name,
      custom_name: currentModel.name,
      custom_name_concat: currentModel.name,
      systemModelActionId: currentAction.id,
      actionType: currentAction.actionType,
      renewalPercentage: currentAction.renewalPercentage,
      unitCost: currentAction.unitCost,
      unitOfMeasure: currentAction.unitOfMeasure,
      usefulLife: currentAction.lifetime,
      description: currentAction.description,
      comment: currentAction.comment,
      model_number: currentAction.modelNumber,
      unitOfMeasureFactor: currentAction.factor
    });
    withModel();
    onCostLinesChange(currentAction.costLines);
    onClose();
  };

  return (
    <Modal
      title="Appliquer les informations du système"
      isOpen={isModalOpen}
      className={className}
      content={
        <SystemModelSection
          projectId={projectId}
          system={system}
          currentModel={currentModel}
          currentAction={currentAction}
          isModalOpen={isModalOpen}
          onUpdate={onUpdate}
          onUpdateModel={onUpdateModel}
          onUpdateAction={onUpdateAction}
          onCostLinesChange={onCostLinesChange}
          onReset={onReset}
          data-test-id="systemModelSection"
        />
      }
      actions={[<Button title={SUBMIT} onClick={onSubmitSystemModel} key={0} disabled={!currentAction.id || !currentModel.id} data-test-id="submitSystemModelActionButton" />]}
      onClose={handleClose}
      data-test-id="modal"
    />
  );
};

export default createStyledComponent(memo(SystemModelModal), systemModelModalStyle);
