/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";

import {
  changeUserAccountPasswordAction,
  changeUserAccountPasswordErrorAction,
  changeUserAccountPasswordRequestAction,
  changeUserAccountPasswordSuccessAction,
  loginUserAction,
  loginUserErrorAction,
  loginUserRequestAction,
  loginUserSuccessAction,
  logoutUserAction,
  logoutUserErrorAction,
  logoutUserRequestAction,
  logoutUserSuccessAction
} from "./actions";
import { initialLoginState, LoginState } from "./state";
import { ChangeUserAccountPasswordErrorAction, ChangeUserAccountPasswordSuccessAction, LoginUserErrorAction, LoginUserSuccessAction } from "./types";

const loginUser = (state: LoginState): void => {
  state.errorMessage = "";
};

const loginUserRequest = (state: LoginState): void => {
  state.isLoading = true;
};

const loginUserSuccess = (state: LoginState, action: LoginUserSuccessAction): void => {
  state.isLoading = false;
  state.isLoggedIn = true;
  state.currentUser = action.payload.user;
};

const loginUserError = (state: LoginState, action: LoginUserErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const logoutUser = (state: LoginState): void => {
  state.errorMessage = "";
};

const logoutUserRequest = (state: LoginState): void => {
  state.isLoading = true;
};

const logoutUserSuccess = (state: LoginState): void => {
  state.isLoading = false;
  state.isLoggedIn = false;
  state.currentUser = undefined;
};

const logoutUserError = (state: LoginState, action: LoginUserErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const changeUserPasswordAccount = (state: LoginState): void => {
  state.errorMessage = "";
};

const changeUserPasswordAccountRequest = (state: LoginState): void => {
  state.isLoading = true;
};

const changeUserPasswordAccountSuccess = (state: LoginState, action: ChangeUserAccountPasswordSuccessAction): void => {
  state.isLoading = false;
  state.passwordWasChanged = true;
  state.currentUser = action.payload.user;
};

const changeUserPasswordAccountError = (state: LoginState, action: ChangeUserAccountPasswordErrorAction): void => {
  state.isLoading = false;
  state.passwordWasChanged = false;
  state.errorMessage = action.payload.errorMessage;
};

const loginReducer = createReducer(initialLoginState, {
  [loginUserAction.type]: (state) => loginUser(state),
  [loginUserRequestAction.type]: (state) => loginUserRequest(state),
  [loginUserSuccessAction.type]: (state, action) => loginUserSuccess(state, action),
  [loginUserErrorAction.type]: (state, action) => loginUserError(state, action),
  [logoutUserAction.type]: (state) => logoutUser(state),
  [logoutUserRequestAction.type]: (state) => logoutUserRequest(state),
  [logoutUserSuccessAction.type]: (state) => logoutUserSuccess(state),
  [logoutUserErrorAction.type]: (state, action) => logoutUserError(state, action),
  [changeUserAccountPasswordAction.type]: (state) => changeUserPasswordAccount(state),
  [changeUserAccountPasswordSuccessAction.type]: (state, action) => changeUserPasswordAccountSuccess(state, action),
  [changeUserAccountPasswordRequestAction.type]: (state) => changeUserPasswordAccountRequest(state),
  [changeUserAccountPasswordErrorAction.type]: (state, action) => changeUserPasswordAccountError(state, action)
});

export default loginReducer;
