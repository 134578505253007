import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useCallback } from "react";
import * as reactDeviceDetect from "react-device-detect";
import { IInputProps } from "react-dropzone-uploader";
import { Button, FileUploadButton } from "ui/button";
import { useWebcam, Webcam } from "ui/webcam";

const ADD_IMAGES = "Ajouter des images";
const TAKE_PHOTO = "Prendre une photo";

interface ImageUploadButtonProps extends ComponentProps, IInputProps {}

const ImageUploadButton: FC<ImageUploadButtonProps> = ({ getFilesFromEvent, onFiles }: ImageUploadButtonProps) => {
  const { openWebcam, closeWebcam, isWebcamOpen } = useWebcam();

  const handleSubmit = useCallback(
    (photoFile: File) => {
      onFiles([photoFile]);
    },
    [onFiles]
  );

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const chosenFiles = await getFilesFromEvent(event);
      onFiles(chosenFiles);
    },
    [getFilesFromEvent, onFiles]
  );

  const isBrowser = (): boolean => reactDeviceDetect.isBrowser && !(reactDeviceDetect.isIOS && reactDeviceDetect.isSafari);

  // TODO: add missing tests
  return (
    <>
      {isBrowser() && (
        <>
          <Button modifiers="outlined" title={TAKE_PHOTO} onClick={openWebcam} data-test-id="takePhotoButton" />
          <Webcam isOpen={isWebcamOpen} onSubmit={handleSubmit} onClose={closeWebcam} data-test-id="webcam" />
        </>
      )}
      <FileUploadButton modifiers="outlined" onChange={handleChange} title={ADD_IMAGES} data-test-id="uploadImageButton" />
    </>
  );
};

export default ImageUploadButton;
