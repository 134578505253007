import Option from "app/domain/common/option";

interface UniformatOptionProps extends Option {
  level: string;
}

class UniformatOption extends Option {
  level: string;

  constructor(uniformatOptionProps: UniformatOptionProps) {
    super({
      id: uniformatOptionProps.id,
      text: uniformatOptionProps.text,
      value: uniformatOptionProps.value
    });
    this.level = uniformatOptionProps.level;
  }
}

export default UniformatOption;
