import { Dictionary } from "../types";

const RequirementType: Dictionary<string> = {
  excluded: "Excluded",
  active: "Active",
  deleted: "Deleted",
  done: "Done",
  replacedBySystem: "Replaced by system",
  library: "Requirement library"
};

export default RequirementType;
