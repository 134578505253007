export enum FieldNames {
  ACTION_COMMENT = "Commentaire de l'action",
  ACTION_DESCRIPTION = "Description de l'action",
  ACTION_NAME = "Nom de l'action",
  ACTION_NAME_SUFFIX = "Détails de l'action",
  ACTION_TYPE = "Type d'action",
  ACTIVITY = "Activité",
  AGE_RANGE = "Catégorie d'âge",
  AREA = "Superficie",
  AUDIT_DATE = "Date de l'audit",
  AUDITOR_BUILDING_ENVELOPE = "Auditeur enveloppe extérieure",
  AUDITOR_ELECTRICAL = "Auditeur électricité",
  AUDITOR_INTERIOR = "Auditeur intérieur",
  AUDITOR_MECHANICAL = "Auditeur mécanique",
  COMPLEXITY = "Complexité",
  COMMENT = "Commentaire",
  COMPONENT_ID = "Id du composant",
  CONDITION_GRADING = "Cote d'état (avant réparations)",
  CONDITION_GRADING_AFTER_REPAIR = "Cote d'état (après réparations)",
  CUSTOM_NAME = "Nom modifié",
  DESCRIPTION = "Description",
  ESTIMATED_CONDITION_GRADING = "Cote d'état estimée",
  ESTIMATED_LIFETIME = "Durée de vie (estimée)",
  HEIGHT = "Hauteur",
  IDENTIFICATION = "Identification",
  INSTALLATION_YEAR = "Année d'installation",
  LOCALISATION = "Localisation",
  NOTE = "Notes internes",
  NAME = "Nom",
  PATRIMONY_FACTOR = "Facteur patrimonial",
  PRIORITY = "Priorité",
  PUBLIC_NOTES = "Note pour le client",
  QUANTITY = "Quantité",
  REMOVAL = "Enlèvement",
  REPLACEMENT_VALUE = "Valeur de remplacement",
  TOTAL_COST = "Prix total",
  YEAR_CONSTRUCTED = "Année de construction",
  N_A = "N/A "
}
