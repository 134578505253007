import { System } from "app/domain/system";
import { UniformatLevel1 } from "app/domain/uniformat";
import { sort } from "fast-sort/dist/sort";
import { createSelector } from "reselect";

import { State } from "../state";

export const getSystems = createSelector([(state: State): System[] => state.systems.systems], (systems: System[]) => {
  return systems;
});

const sortSystems = (systems: System[]): System[] =>
  sort(systems).by([
    { asc: (system: System): string => system.uniformat.level3 as string },
    { asc: (system: System): string => system.uniformat.level4 || "" },
    { asc: (system: System): string => system.custom_name as string },
    { asc: (system: System): number => system.estimated_lifetime as number }
  ]);

export const getMechanicalSystems = createSelector([getSystems], (systems: System[]) => {
  return sortSystems(systems.filter((system: System) => system.uniformat.level1 === UniformatLevel1.D));
});

export const getArchitecturalSystems = createSelector([getSystems], (systems: System[]) => {
  return sortSystems(systems.filter((system: System) => system.uniformat.level1 !== UniformatLevel1.D));
});

const filterById = (_: State, systemId: string): string => systemId;

export const getSystem = createSelector([getSystems, filterById], (systems: System[], systemId: string) => systems.find((system) => system.id === systemId));

export const getCurrentSystem = createSelector([(state: State): System | undefined => state.systems.currentSystem], (currentSystem: System | undefined) => currentSystem);

export const getErrorMessage = createSelector([(state: State): string => state.systems.errorMessage], (errorMessage: string) => errorMessage);

export const isLoading = createSelector([(state: State): boolean => state.systems.isLoading], (isSystemLoading: boolean) => isSystemLoading);

export const isLoadingOptions = createSelector([(state: State): boolean => state.systems.isFetchingOptions], (isFetchingOptions: boolean) => isFetchingOptions);

export const shouldRedirectToSystems = createSelector([(state: State): boolean => state.systems.shouldRedirectToComponentsPage], (shouldRedirect: boolean) => shouldRedirect);

export const getNewSystemId = createSelector([(state: State): string => state.systems.newSystemId], (newSystemId: string) => newSystemId);

export const shouldRedirectToModifySystem = createSelector(
  [(state: State): boolean => state.systems.shouldRedirectToModifySystemPage],
  (shouldRedirect: boolean) => shouldRedirect
);

// TODO remove any and create options lists object + test
export const getOptionsLists = createSelector([(state: State): any => state.systems.optionsLists], (optionsLists: any) => optionsLists);

export const getFilteredSystems = createSelector([(state: State): System[] => state.systems.filteredSystems], (filteredSystems: System[]) => filteredSystems);
