import dot from "dot-object";
import { FieldError, UseFormMethods } from "react-hook-form";

export type FormControlValidationSchema = {
  [key: string]: { value: any; message: string };
};

class FormContext {
  private readonly contextValues: UseFormMethods;

  constructor(contextValues: UseFormMethods) {
    this.contextValues = contextValues;
  }

  getContextValues = (): any => this.contextValues;

  getControl = (): any => this.contextValues.control;

  getValues = (): any => {
    dot.keepArray = true;

    return dot.dot(
      Object.fromEntries(
        Object.entries(dot.dot(this.contextValues.getValues())).map(([key, value]) => {
          if (Array.isArray(value)) {
            return [key, { ...value }];
          }
          return [key, value];
        })
      )
    );
  };

  getValue = (fieldName: string): any => this.contextValues.getValues()[fieldName];

  setValue = (fieldName: string, fieldValue: unknown): void => {
    this.contextValues.setValue(fieldName, fieldValue);
  };

  isErrored = (fieldName: string): boolean => !!this.contextValues.errors[fieldName];

  getErrorMessage = (fieldName: string): string => {
    let errorMessage = "";
    if (this.contextValues.errors[fieldName]) {
      const fieldError = this.contextValues.errors[fieldName] as FieldError;
      errorMessage = fieldError.message as string;
    }
    return errorMessage;
  };

  equals = (obj: FormContext): boolean => this.contextValues === obj.contextValues;
}

export default FormContext;
