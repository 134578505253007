import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { css, SimpleInterpolation } from "styled-components";
import { IconButton } from "ui/button";
import { colors } from "ui/colors";
import { Icon, IconType } from "ui/icon";

const rowSelectionButtonStyle = css`
  &&&&& ${Icon as unknown as SimpleInterpolation} {
    height: 100%;
    width: 100%;

    i {
      font-size: 1em;
    }
  }
`;

interface RowSelectionButtonProps extends ComponentProps {
  isRowSelected: boolean;
  onRowSelect: () => void;
  indeterminate?: boolean;
}

const RowSelectionButton: FC<RowSelectionButtonProps> = ({ isRowSelected, onRowSelect, indeterminate, className }: RowSelectionButtonProps) => {
  const getIcon = (): JSX.Element => {
    if (indeterminate) {
      return <Icon type={IconType.MINUS} data-test-id="icon" />;
    }

    return isRowSelected ? <Icon type={IconType.CHECK} data-test-id="icon" /> : <div />;
  };

  return (
    <IconButton onClick={onRowSelect} modifiers={[!isRowSelected && "outlined"]} className={className} color={colors.ACCENT_BLUE} data-test-id="iconButton">
      {getIcon()}
    </IconButton>
  );
};

export default createStyledComponent(memo(RowSelectionButton), rowSelectionButtonStyle);
