import { System } from "app/domain/system";
import { FieldNames } from "app/service/comment/field/fieldNames";

class SystemFieldValueFetcher {
  getFieldValue(system: System, fieldName: string): string {
    switch (fieldName) {
      case FieldNames.TOTAL_COST: {
        return String(system.totalCost);
      }
      case FieldNames.HEIGHT: {
        return system.height != null ? String(system.height) : "";
      }
      case FieldNames.INSTALLATION_YEAR: {
        return system.installationYear != null ? String(system.installationYear) : "";
      }
      case FieldNames.PUBLIC_NOTES: {
        return system.public_notes != null ? String(system.public_notes) : "";
      }
      case FieldNames.CONDITION_GRADING: {
        return system.conditionGrading != null ? String(system.conditionGrading) : "";
      }
      case FieldNames.CONDITION_GRADING_AFTER_REPAIR: {
        return system.conditionGradingAfterRepair != null ? String(system.conditionGradingAfterRepair) : "";
      }
      case FieldNames.AGE_RANGE: {
        return system.ageRange != null ? String(system.ageRange) : "";
      }
      case FieldNames.ACTION_TYPE: {
        return system.actionType != null ? String(system.actionType) : "";
      }
      case FieldNames.ESTIMATED_LIFETIME: {
        return system.estimated_lifetime != null ? String(system.estimated_lifetime) : "";
      }
      case FieldNames.PATRIMONY_FACTOR: {
        return system.patrimony_factor != null ? String(system.patrimony_factor) : "";
      }
      case FieldNames.COMPLEXITY: {
        return system.complexity != null ? String(system.complexity) : "";
      }
      case FieldNames.REMOVAL: {
        return system.removal != null ? String(system.removal) : "";
      }
      case FieldNames.NOTE: {
        return system.note != null ? String(system.note) : "";
      }
      case FieldNames.QUANTITY: {
        return system.quantity != null ? String(system.quantity) : "";
      }
      case FieldNames.NAME: {
        return system.name != null ? String(system.name) : "";
      }
      case FieldNames.CUSTOM_NAME: {
        return system.custom_name != null ? String(system.custom_name) : "";
      }
      case FieldNames.IDENTIFICATION: {
        return system.identification != null ? String(system.identification) : "";
      }
      case FieldNames.DESCRIPTION: {
        return system.description != null ? String(system.description) : "";
      }
      case FieldNames.LOCALISATION: {
        return system.localisation != null ? String(system.localisation) : "";
      }
      default: {
        return "";
      }
    }
  }
}

export default SystemFieldValueFetcher;
