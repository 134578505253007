import { CostLine } from "app/domain/costItem";
import Local from "app/domain/local/local";
import { System } from "app/domain/system";
import IdGenerator from "app/utils/idGenerator";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface SystemCopyFields {
  assetId?: string;
  custom_name?: string;
  identification?: string;
  localisation?: string;
  quantity?: number;
  serialNumber?: string;
  locals?: Local[];
}

type UseSystemCopyHook = {
  copies: System[];
  onAdd: (quantity: number) => void;
  onUpdate: (systemCopyFields: SystemCopyFields, index: number) => void;
  onDelete: (quantity: number) => void;
  onReset: () => void;
};

const useSystemCopyTable = (systemTemplate: System): UseSystemCopyHook => {
  const [systemCopies, setSystemCopies] = useState([] as System[]);

  useEffect(() => {
    setSystemCopies([
      {
        ...systemTemplate,
        id: IdGenerator.generate(),
        costLines: systemTemplate.costLines
          ? systemTemplate.costLines.map((line: CostLine) => ({
              ...line,
              id: undefined
            }))
          : []
      }
    ]);
  }, [systemTemplate]);

  const onAdd = useCallback(
    (quantity: number) => {
      const copies = [];

      for (let i = 0; i < quantity; i += 1) {
        const copy = {
          ...systemTemplate,
          id: IdGenerator.generate(),
          costLines: systemTemplate.costLines
            ? systemTemplate.costLines.map((line: CostLine) => ({
                ...line,
                id: undefined
              }))
            : []
        };
        copies.push(copy);
      }
      setSystemCopies([...systemCopies, ...copies]);
    },
    [systemCopies, systemTemplate]
  );

  const onUpdate = useCallback(
    (systemCopyFields: SystemCopyFields, index: number) => {
      const copies = [...systemCopies];
      copies[index] = { ...copies[index], ...systemCopyFields };
      setSystemCopies(copies);
    },
    [systemCopies]
  );

  // TODO change this function to be able to delete one specific copy
  const onDelete = useCallback(
    (quantity: number) => {
      if (quantity <= systemCopies.length) {
        const copies = [...systemCopies];
        for (let i = 0; i < quantity; i += 1) {
          copies.pop();
        }
        setSystemCopies(copies);
      }
    },
    [systemCopies]
  );

  const onReset = useCallback(() => {
    setSystemCopies([{ ...systemTemplate, id: IdGenerator.generate() }]);
  }, [systemTemplate]);

  return useMemo(
    () => ({
      copies: systemCopies,
      onAdd,
      onUpdate,
      onDelete,
      onReset
    }),
    [systemCopies, onAdd, onUpdate, onDelete, onReset]
  );
};

export default useSystemCopyTable;
