import AuditProgress from "app/domain/asset/auditProgress";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Form as SemanticForm, Table as SemanticTable } from "semantic-ui-react";
import Checkbox from "ui/form/controls/checkbox/checkbox";

interface ElectromecanicProps extends ComponentProps {
  auditProgress: AuditProgress | undefined;
  onUpdate: (fieldName: string, fieldValue: any) => void;
}

const ElectromechanicalMonitoring: FC<ElectromecanicProps> = ({ auditProgress, onUpdate }: ElectromecanicProps) => {
  const [checkboxes, setCheckboxes] = useState({
    mech_preparing_meetings_date_chk: (auditProgress && auditProgress.mech_preparing_meetings_date !== undefined && auditProgress.mech_preparing_meetings_date !== null) || false,
    mech_audit_50_percent_date_chk: (auditProgress && auditProgress.mech_audit_50_percent_date !== undefined && auditProgress.mech_audit_50_percent_date !== null) || false,
    mech_audit_100_percent_date_chk: (auditProgress && auditProgress.mech_audit_100_percent_date !== undefined && auditProgress.mech_audit_100_percent_date !== null) || false,
    mech_estimation_50_percent_date_chk:
      (auditProgress && auditProgress.mech_estimation_50_percent_date !== undefined && auditProgress.mech_estimation_50_percent_date !== null) || false,
    mech_estimation_100_percent_date_chk:
      (auditProgress && auditProgress.mech_estimation_100_percent_date !== undefined && auditProgress.mech_estimation_100_percent_date !== null) || false,
    mech_quality_control_date_chk: (auditProgress && auditProgress.mech_quality_control_date !== undefined && auditProgress.mech_quality_control_date !== null) || false,
    mech_quality_autocontrol_date_chk: (auditProgress && auditProgress.mech_quality_autocontrol_date !== undefined && auditProgress.mech_quality_autocontrol_date !== null) || false
  });

  useEffect(() => {
    setCheckboxes({
      mech_preparing_meetings_date_chk: (auditProgress && auditProgress.mech_preparing_meetings_date !== undefined && auditProgress.mech_preparing_meetings_date !== null) || false,
      mech_audit_50_percent_date_chk: (auditProgress && auditProgress.mech_audit_50_percent_date !== undefined && auditProgress.mech_audit_50_percent_date !== null) || false,
      mech_audit_100_percent_date_chk: (auditProgress && auditProgress.mech_audit_100_percent_date !== undefined && auditProgress.mech_audit_100_percent_date !== null) || false,
      mech_estimation_50_percent_date_chk:
        (auditProgress && auditProgress.mech_estimation_50_percent_date !== undefined && auditProgress.mech_estimation_50_percent_date !== null) || false,
      mech_estimation_100_percent_date_chk:
        (auditProgress && auditProgress.mech_estimation_100_percent_date !== undefined && auditProgress.mech_estimation_100_percent_date !== null) || false,
      mech_quality_control_date_chk: (auditProgress && auditProgress.mech_quality_control_date !== undefined && auditProgress.mech_quality_control_date !== null) || false,
      mech_quality_autocontrol_date_chk:
        (auditProgress && auditProgress.mech_quality_autocontrol_date !== undefined && auditProgress.mech_quality_autocontrol_date !== null) || false
    });
  }, [auditProgress]);

  const convertDate = (assessment_date: string | undefined | null): Date | null => {
    if (assessment_date !== undefined && assessment_date !== null && assessment_date !== "") {
      const parts = assessment_date.split("-");
      return new Date(parts[0] as unknown as number, (parts[1] as unknown as number) - 1, parts[2] as unknown as number);
    }
    return null;
  };

  const minTwoDigits = (n: number): string => (n < 10 ? "0" : "") + n;

  const handleDateChange = (name: string, date: any): void => {
    if (date !== null) {
      const dateString = `${(date as Date).getUTCFullYear().toString()}-${minTwoDigits((date as Date).getUTCMonth() + 1).toString()}-${minTwoDigits(
        (date as Date).getUTCDate().toString() as unknown as number
      )}`;

      onUpdate(name, dateString);
    }
  };

  const handleChange = (event: any, value: any): void => {
    onUpdate(value.name, value.value);
  };

  const manageFollowUpCheckbox = (event: React.FormEvent<HTMLInputElement>): void => {
    const date = new Date().toISOString().slice(0, 10);

    const { mech_audit_50_percent_date, mech_estimation_50_percent_date } = auditProgress as AuditProgress;

    if (event.currentTarget.name === "mech_preparing_meetings_date_chk") {
      onUpdate("mech_preparing_meetings_date", event.currentTarget.checked ? date : null);
    } else if (event.currentTarget.name === "mech_audit_50_percent_date_chk") {
      onUpdate("mech_audit_50_percent_date", event.currentTarget.checked ? date : null);
    } else if (event.currentTarget.name === "mech_audit_100_percent_date_chk") {
      onUpdate("mech_audit_100_percent_date", event.currentTarget.checked ? date : null);

      if (!mech_audit_50_percent_date && event.currentTarget.checked) {
        onUpdate("mech_audit_50_percent_date", event.currentTarget.checked ? date : null);
      }
    } else if (event.currentTarget.name === "mech_estimation_50_percent_date_chk") {
      onUpdate("mech_estimation_50_percent_date", event.currentTarget.checked ? date : null);
    }
    if (event.currentTarget.name === "mech_estimation_100_percent_date_chk") {
      onUpdate("mech_estimation_100_percent_date", event.currentTarget.checked ? date : null);

      if (!mech_estimation_50_percent_date && event.currentTarget.checked) {
        onUpdate("mech_estimation_50_percent_date", event.currentTarget.checked ? date : null);
      }
    } else if (event.currentTarget.name === "mech_quality_control_date_chk") {
      onUpdate("mech_quality_control_date", event.currentTarget.checked ? date : null);
    } else if (event.currentTarget.name === "mech_quality_autocontrol_date_chk") {
      onUpdate("mech_quality_autocontrol_date", event.currentTarget.checked ? date : null);
    }
  };

  const setNbHoursList = (): any => {
    const listOptions = [];
    for (let i = 1; i < 41; i += 1) {
      listOptions.push({ key: i / 2, text: i / 2, value: i / 2 });
    }
    return listOptions;
  };

  return (
    <>
      <h2>Suivi Électromécanique</h2>
      <SemanticTable celled structured>
        <SemanticTable.Header>
          <SemanticTable.Row>
            <SemanticTable.HeaderCell>Principales étapes</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>Étapes de suivi</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>% d&apos;avancement</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>Endroit de réalisation</SemanticTable.HeaderCell>
          </SemanticTable.Row>
        </SemanticTable.Header>
        <SemanticTable.Body>
          <SemanticTable.Row>
            <SemanticTable.Cell>
              Préparation rencontres et déplacement
              <div>
                <SemanticForm.Input
                  disabled
                  inline
                  label="Nombre d'heures planifiées (h) :"
                  name="mech_planned_nb_hours_preparing"
                  value={auditProgress && auditProgress.mech_planned_nb_hours_preparing}
                />
              </div>
            </SemanticTable.Cell>
            <SemanticTable.Cell>
              Préparation rencontres et transport
              <div>
                <Checkbox label="Terminé le " name="mech_preparing_meetings_date_chk" isChecked={checkboxes.mech_preparing_meetings_date_chk} onClick={manageFollowUpCheckbox} />
                <DatePicker
                  name="mech_preparing_meetings_date"
                  disabled={!checkboxes.mech_preparing_meetings_date_chk}
                  onChange={(date): void => handleDateChange("mech_preparing_meetings_date", date)}
                  selected={convertDate(auditProgress && auditProgress.mech_preparing_meetings_date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </SemanticTable.Cell>
            <SemanticTable.Cell>15%</SemanticTable.Cell>
            <SemanticTable.Cell>Variable</SemanticTable.Cell>
          </SemanticTable.Row>
          <SemanticTable.Row>
            <SemanticTable.Cell rowSpan="2">
              Audit
              <div>
                <SemanticForm.Input
                  disabled
                  inline
                  label="Nombre d'heures planifiées (h) :"
                  name="mech_planned_nb_hours_audit"
                  value={auditProgress && auditProgress.mech_planned_nb_hours_audit}
                />
              </div>
              <div>
                <SemanticForm.Dropdown
                  search
                  label="Nombre d'heures nécéssaires"
                  clearable
                  placeholder="Nombre d'heures nécéssaires"
                  name="mech_audit_total_hours"
                  value={auditProgress && auditProgress.mech_audit_total_hours}
                  onChange={handleChange}
                  selection
                  options={setNbHoursList()}
                />
              </div>
            </SemanticTable.Cell>
            <SemanticTable.Cell>
              Audit réalisé à 50%
              <div>
                <Checkbox label="Terminé le " name="mech_audit_50_percent_date_chk" isChecked={checkboxes.mech_audit_50_percent_date_chk} onClick={manageFollowUpCheckbox} />
                <DatePicker
                  name="mech_audit_50_percent_date"
                  disabled={!checkboxes.mech_audit_50_percent_date_chk}
                  onChange={(date): void => handleDateChange("mech_audit_50_percent_date", date)}
                  selected={convertDate(auditProgress && auditProgress.mech_audit_50_percent_date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </SemanticTable.Cell>
            <SemanticTable.Cell>20%</SemanticTable.Cell>
            <SemanticTable.Cell>Terrain</SemanticTable.Cell>
          </SemanticTable.Row>
          <SemanticTable.Row>
            <SemanticTable.Cell>
              Audit réalisé à 100%
              <div>
                <Checkbox label="Terminé le " name="mech_audit_100_percent_date_chk" isChecked={checkboxes.mech_audit_100_percent_date_chk} onClick={manageFollowUpCheckbox} />
                <DatePicker
                  name="mech_audit_100_percent_date"
                  disabled={!checkboxes.mech_audit_100_percent_date_chk}
                  onChange={(date): void => handleDateChange("mech_audit_100_percent_date", date)}
                  selected={convertDate(auditProgress && auditProgress.mech_audit_100_percent_date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </SemanticTable.Cell>
            <SemanticTable.Cell>20%</SemanticTable.Cell>
            <SemanticTable.Cell>Terrain</SemanticTable.Cell>
          </SemanticTable.Row>
          <SemanticTable.Row>
            <SemanticTable.Cell rowSpan="2">
              Saisie et estimation
              <div>
                <SemanticForm.Input
                  disabled
                  inline
                  label="Nombre d'heures planifiées (h) :"
                  name="mech_planned_nb_hours_estimation"
                  value={auditProgress && auditProgress.mech_planned_nb_hours_estimation}
                />
              </div>
            </SemanticTable.Cell>
            <SemanticTable.Cell>
              Saisie et estimation réalisées à 50%
              <div>
                <Checkbox
                  label="Terminé le "
                  name="mech_estimation_50_percent_date_chk"
                  isChecked={checkboxes.mech_estimation_50_percent_date_chk}
                  onClick={manageFollowUpCheckbox}
                />
                <DatePicker
                  name="mech_estimation_50_percent_date"
                  disabled={!checkboxes.mech_estimation_50_percent_date_chk}
                  onChange={(date): void => handleDateChange("mech_estimation_50_percent_date", date)}
                  selected={convertDate(auditProgress && auditProgress.mech_estimation_50_percent_date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </SemanticTable.Cell>
            <SemanticTable.Cell>15%</SemanticTable.Cell>
            <SemanticTable.Cell>Terrain ou bureau</SemanticTable.Cell>
          </SemanticTable.Row>
          <SemanticTable.Row>
            <SemanticTable.Cell>
              Saisie et estimation réalisées à 100%
              <div>
                <Checkbox
                  label="Terminé le "
                  name="mech_estimation_100_percent_date_chk"
                  isChecked={checkboxes.mech_estimation_100_percent_date_chk}
                  onClick={manageFollowUpCheckbox}
                />
                <DatePicker
                  name="mech_estimation_100_percent_date"
                  disabled={!checkboxes.mech_estimation_100_percent_date_chk}
                  onChange={(date): void => handleDateChange("mech_estimation_100_percent_date", date)}
                  selected={convertDate(auditProgress && auditProgress.mech_estimation_100_percent_date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </SemanticTable.Cell>
            <SemanticTable.Cell>20%</SemanticTable.Cell>
            <SemanticTable.Cell>Terrain ou bureau</SemanticTable.Cell>
          </SemanticTable.Row>
          <SemanticTable.Row>
            <SemanticTable.Cell rowSpan="2">
              Contrôle qualité
              <div>
                <SemanticForm.Input
                  disabled
                  inline
                  label="Nombre d'heures planifiées (h) :"
                  name="mech_planned_nb_hours_quality_control"
                  value={auditProgress && auditProgress.mech_planned_nb_hours_quality_control}
                />
              </div>
            </SemanticTable.Cell>
            <SemanticTable.Cell>
              Autocontrôle qualité complété
              <div>
                <Checkbox label="Terminé le " name="mech_quality_autocontrol_date_chk" isChecked={checkboxes.mech_quality_autocontrol_date_chk} onClick={manageFollowUpCheckbox} />
                <DatePicker
                  name="mech_quality_autocontrol_date"
                  disabled={!checkboxes.mech_quality_autocontrol_date_chk}
                  onChange={(date): void => handleDateChange("mech_quality_autocontrol_date", date)}
                  selected={convertDate(auditProgress && auditProgress.mech_quality_autocontrol_date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </SemanticTable.Cell>
            <SemanticTable.Cell>5%</SemanticTable.Cell>
            <SemanticTable.Cell>Bureau</SemanticTable.Cell>
          </SemanticTable.Row>
          <SemanticTable.Row>
            <SemanticTable.Cell>
              Contrôle qualité complété
              <div>
                <Checkbox label="Terminé le " name="mech_quality_control_date_chk" isChecked={checkboxes.mech_quality_control_date_chk} onClick={manageFollowUpCheckbox} />
                <DatePicker
                  name="mech_quality_control_date"
                  disabled={!checkboxes.mech_quality_control_date_chk}
                  onChange={(date): void => handleDateChange("mech_quality_control_date", date)}
                  selected={convertDate(auditProgress && auditProgress.mech_quality_control_date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </SemanticTable.Cell>
            <SemanticTable.Cell>5%</SemanticTable.Cell>
            <SemanticTable.Cell>Bureau</SemanticTable.Cell>
          </SemanticTable.Row>
        </SemanticTable.Body>
      </SemanticTable>
    </>
  );
};

export default ElectromechanicalMonitoring;
