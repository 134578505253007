import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";
import { ButtonGroup } from "ui/button";

export const actionsStyle = css`
  display: flex;
  float: right;
  margin-left: auto;

  > * {
    white-space: nowrap;
  }
`;

// TODO: verify that children is an array of ActionButton/Button
interface ActionsProps extends ComponentProps {
  children: JSX.Element | Array<JSX.Element | boolean>;
}

const Actions: FC<ActionsProps> = ({ children, className }: ActionsProps) => {
  const getActions = (): JSX.Element | JSX.Element[] => {
    if (Array.isArray(children)) {
      return children.filter((action) => typeof action !== "boolean") as JSX.Element[];
    }
    return children;
  };

  return (
    <ButtonGroup className={className} modifiers="outlined">
      {getActions()}
    </ButtonGroup>
  );
};

export default createStyledComponent(Actions, actionsStyle);
