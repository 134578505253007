import { useMemo } from "react";

type AllValuesSelectorHook = {
  isChecked: boolean;
  isIndeterminate: boolean;
};

const useAllValuesSelector = (initialValues: Record<string, boolean>): AllValuesSelectorHook => {
  const values = useMemo(() => Object.keys(initialValues), [initialValues]);

  const selectedValues = useMemo(
    () =>
      Object.entries(initialValues)
        .filter(([_, isSelected]) => isSelected)
        .map(([value, _]) => value),
    [initialValues]
  );

  const isChecked = useMemo(() => selectedValues.length === values.length && selectedValues.length !== 0 && values.length !== 0, [selectedValues, values]);

  const isIndeterminate = useMemo(() => selectedValues.length > 0 && selectedValues.length < values.length, [selectedValues, values]);

  return useMemo(
    () => ({
      isChecked,
      isIndeterminate
    }),
    [isChecked, isIndeterminate]
  );
};

export default useAllValuesSelector;
