import { RefObject, useCallback, useEffect, useState } from "react";

type Axis = {
  windowProperty: string;
  start: string;
  end: string;
};

const axes = {
  x: {
    windowProperty: "innerWidth",
    start: "left",
    end: "right"
  },

  y: {
    windowProperty: "innerHeight",
    start: "top",
    end: "bottom"
  }
};

interface ContainerVisibleDimensionsHook {
  width: number;
  height: number;
}

const useContainerVisibleDimensions = (containerReference: RefObject<Element>): ContainerVisibleDimensionsHook => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const getVisibleAxisDimensions = useCallback(
    (axis: Axis) => {
      const containerDimensions = JSON.parse(JSON.stringify((containerReference.current as Element).getBoundingClientRect()));
      const windowProperty = axis.windowProperty as keyof typeof window as number;

      const visibleStart = containerDimensions[axis.start] < 0 ? 0 : containerDimensions[axis.start];

      const visibleEnd = containerDimensions[axis.end] > window[windowProperty] ? window[windowProperty] : containerDimensions[axis.end];

      return visibleEnd - visibleStart;
    },
    [containerReference]
  );

  useEffect(() => {
    const handleDimensionsChange = (): void => {
      setWidth(getVisibleAxisDimensions(axes.x));
      setHeight(getVisibleAxisDimensions(axes.y));
    };

    if (containerReference.current) {
      setWidth(getVisibleAxisDimensions(axes.x));
      setHeight(getVisibleAxisDimensions(axes.y));
    }

    window.addEventListener("resize", handleDimensionsChange);
    window.addEventListener("scroll", handleDimensionsChange);

    return (): void => {
      window.removeEventListener("resize", handleDimensionsChange);
      window.removeEventListener("scroll", handleDimensionsChange);
    };
  }, [setWidth, setHeight, containerReference, getVisibleAxisDimensions]);

  return {
    width,
    height
  };
};

export default useContainerVisibleDimensions;
