import Photo from "app/domain/photo";
import { isLoading as isLoadingSelector, sortPhotosByDate } from "app/store/photo";
import { State } from "app/store/state";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useState } from "react";
import { useSelector } from "react-redux";
import { css } from "styled-components";
import { SelectableCardGrid } from "ui/card";
import { Loader } from "ui/loader";

import Carousel from "./carousel";
import PhotoCard from "./photoCard";

export const photoGridStyle = css`
  flex: 1;
  position: relative;
`;

interface PhotoGridProps extends ComponentProps {
  onSelect: (cardKey: string) => void;
  assetId: string;
  entityId?: string;
}

const PhotoGrid: FC<PhotoGridProps> = ({ onSelect, assetId, entityId, className }: PhotoGridProps) => {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleClose = (): void => {
    setIsCarouselOpen(false);
  };

  const photos = useSelector((state: State): Photo[] => sortPhotosByDate(state, entityId || assetId));

  const isLoading = useSelector((state: State): boolean => isLoadingSelector(state, entityId || assetId));

  const onOpenImage = (imageIndex: number): void => {
    setIsCarouselOpen(true);
    setCurrentImageIndex(imageIndex);
  };

  const onPreviousPhoto = (): void => {
    const previousImageIndex = currentImageIndex - 1;
    if (previousImageIndex >= 0) {
      setCurrentImageIndex(previousImageIndex);
    }
  };

  const onNextPhoto = (): void => {
    const nextImageIndex = currentImageIndex + 1;
    if (nextImageIndex <= photos.length) {
      setCurrentImageIndex(nextImageIndex);
    }
  };

  return (
    <div className={className}>
      <Loader isActive={isLoading} data-test-id="loader" />
      <Carousel
        currentImageIndex={currentImageIndex}
        isOpen={isCarouselOpen}
        handleClose={handleClose}
        images={photos}
        onPreviousPhoto={onPreviousPhoto}
        onNextPhoto={onNextPhoto}
      />
      <SelectableCardGrid onSelect={onSelect} onOpenImage={onOpenImage} data-test-id="selectableCardGrid">
        {photos.map((photo) => (
          <PhotoCard key={photo.id} photo={photo} />
        ))}
      </SelectableCardGrid>
    </div>
  );
};

export default createStyledComponent(memo(PhotoGrid), photoGridStyle);
