import { CostLine } from "app/domain/costItem";

interface SystemModelActionProps {
  id: string;
  systemModelId: string;
  actionType: string;
  modelNumber: string;
  renewalPercentage: number;
  description: string;
  unitCost: number;
  unitOfMeasure: string;
  lifetime: number;
  comment: string;
  factor: number;
  costLines: CostLine[];
}

class SystemModelAction {
  id: string;
  systemModelId: string;
  actionType: string;
  modelNumber: string;
  renewalPercentage: number;
  description: string;
  unitCost: number;
  unitOfMeasure: string;
  lifetime: number;
  comment: string;
  factor: number;
  costLines: CostLine[];

  constructor(systemModelActionProps: SystemModelActionProps) {
    this.id = systemModelActionProps.id;
    this.systemModelId = systemModelActionProps.systemModelId;
    this.actionType = systemModelActionProps.actionType;
    this.modelNumber = systemModelActionProps.modelNumber;
    this.renewalPercentage = systemModelActionProps.renewalPercentage;
    this.description = systemModelActionProps.description;
    this.unitCost = systemModelActionProps.unitCost;
    this.unitOfMeasure = systemModelActionProps.unitOfMeasure;
    this.lifetime = systemModelActionProps.lifetime;
    this.comment = systemModelActionProps.comment;
    this.factor = systemModelActionProps.factor;
    this.costLines = systemModelActionProps.costLines;
  }
}

export default SystemModelAction;
