import Manufacturer from "app/domain/manufacturer/manufacturer";

export type ManufacturerDTO = {
  id: number;
  text: string;
  is_validated: boolean;
};

class ManufacturerMapper {
  static fromDTO(manufacturerDTO: ManufacturerDTO): Manufacturer;
  static fromDTO(manufacturerDTOs: ManufacturerDTO[]): Manufacturer[];
  static fromDTO(manufacturerDTOs: ManufacturerDTO | ManufacturerDTO[]): any {
    if (!Array.isArray(manufacturerDTOs)) {
      return new Manufacturer({
        id: manufacturerDTOs.id,
        text: manufacturerDTOs.text,
        isValidated: manufacturerDTOs.is_validated
      });
    }
    return manufacturerDTOs.map((manufacturerDTO: ManufacturerDTO): Manufacturer => this.fromDTO(manufacturerDTO));
  }

  static toDTO(manufacturer: Manufacturer): ManufacturerDTO;
  static toDTO(manufacturers: Manufacturer[]): ManufacturerDTO[];
  static toDTO(manufacturers: Manufacturer | Manufacturer[]): any {
    if (!Array.isArray(manufacturers)) {
      return {
        id: manufacturers.id,
        text: manufacturers.text,
        is_validated: manufacturers.isValidated
      };
    }
    return manufacturers.map((manufacturer: Manufacturer): ManufacturerDTO => this.toDTO(manufacturer));
  }
}

export default ManufacturerMapper;
