/* stylelint-disable value-keyword-case */

import Asset from "app/domain/asset/asset";
import { AuditType } from "app/domain/audit/auditType";
import { Error } from "app/domain/common";
import { EntityCategory } from "app/domain/entityCategory";
import { useI18n } from "app/locales";
import { useRouter } from "app/routing/routerProvider";
import AssetFieldValueFetcher from "app/service/comment/field/assetFieldValueFetcher";
import { getCurrentAsset } from "app/store/asset/selectors";
import CommentsListing from "app/view/comment/listing/commentsListing";
import { formStyle } from "app/view/common/form";
import { Page } from "app/view/common/page";
import * as constants from "app/view/constant";
import PhotoListing from "app/view/photo/listing/photoListing";
import { RequirementListing } from "app/view/requirement/listing";
import RoomListing from "app/view/room/roomListing";
import { SystemListing } from "app/view/system/listing";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Label } from "semantic-ui-react";
import { css } from "styled-components";
import { ErrorMessage } from "ui/errorMessage";
import { Tab, Tabs, useTabs } from "ui/tab";

import { AssetBasicInformationForm } from "./assetBasicInformation";
import AssetDescriptionForm from "./assetDescriptionForm";
import { AssetInputInformationForm } from "./assetInputInformation";

export const assetFormStyle = css`
  &&& {
    ${formStyle};
    display: flex;
    flex-direction: column;
    flex: 1;

    .assetError {
      margin: 1em;
    }

    > *:not(:last-child) {
      margin-bottom: 1em;
    }
  }
`;

interface AssetFormProps extends ComponentProps {
  onSave: (asset: Asset) => void;
  errorMessage: string;
  onReset: () => void;
  onUpdate: (errorMessage: string) => void;
}

const AssetForm: FC<AssetFormProps> = ({ onSave, errorMessage, onReset, onUpdate, className }: AssetFormProps) => {
  const { getAttributeLabel, dictionaryCode } = useI18n();

  const { getStateParam } = useRouter();
  const tabIndex = getStateParam("tabIndex");
  const { activeTabIndex, onTabChange } = useTabs(tabIndex as number);

  const currentAsset = useSelector(getCurrentAsset);

  useEffect(() => {
    if (currentAsset && currentAsset.errors && currentAsset.errors.length > 0) {
      let formattedError = "";

      currentAsset.errors.forEach((error: Error) => {
        if (typeof error.format === "function") {
          formattedError = `${formattedError}${error.format(dictionaryCode)}\n`;
        }
      });

      onUpdate(formattedError);
    }
  }, [dictionaryCode, onUpdate, currentAsset]);

  const [commentTabLabel, setCommentTabLabel] = useState(<div>Commentaires</div>);
  useEffect((): void => {
    const commentCount =
      currentAsset && currentAsset.commentsCount && currentAsset.commentsCount > 0 ? (
        <Label circular color="red" size="tiny">
          {currentAsset.commentsCount}
        </Label>
      ) : null;
    setCommentTabLabel(<div>Commentaires {commentCount}</div>);
  }, [currentAsset, currentAsset?.commentsCount]);

  const assetFieldValueFetcher = new AssetFieldValueFetcher();
  const fetchAssetFieldValue = (fieldName: string): string => {
    return currentAsset ? assetFieldValueFetcher.getFieldValue(currentAsset, fieldName) : "";
  };

  return (
    <Page
      showBreadcrumb
      title={
        <>
          <span>Actif:</span> {currentAsset ? `${currentAsset.asset_number} - ${currentAsset.name}` : ""}
        </>
      }
    >
      <div className={className}>
        <ErrorMessage message={errorMessage} onDismiss={onReset} className="assetError" />
        <Tabs activeTabIndex={activeTabIndex || 0} onTabChange={onTabChange}>
          <Tab label={constants.BASIC_INFORMATION}>
            <AssetBasicInformationForm asset={currentAsset} onSave={onSave} />
          </Tab>
          <Tab label={constants.INPUT_INFORMATION}>
            <AssetInputInformationForm />
          </Tab>
          <Tab label={getAttributeLabel("description")}>
            <AssetDescriptionForm />
          </Tab>
          <Tab label={constants.MECHANICAL_SYSTEMS}>
            <SystemListing parentType={EntityCategory.ASSET} auditType={AuditType.MECHANICAL} />
          </Tab>
          <Tab label={constants.ARCHITECTURAL_SYSTEMS}>
            <SystemListing parentType={EntityCategory.ASSET} auditType={AuditType.ARCHITECTURAL} />
          </Tab>
          <Tab label={constants.REQUIREMENTS}>
            <RequirementListing />
          </Tab>
          <Tab label={constants.ROOMS}>
            <RoomListing assetId={currentAsset && currentAsset.id} />
          </Tab>
          <Tab label={constants.PHOTOS}>
            <PhotoListing entityId={currentAsset && currentAsset.id ? currentAsset.id : ""} entityCategory={EntityCategory.ASSET} />
          </Tab>
          {!!currentAsset && !!currentAsset.id && (
            <Tab label={commentTabLabel}>
              <CommentsListing parentId={currentAsset.id} parentType={EntityCategory.ASSET} fetchFieldValue={fetchAssetFieldValue} />
            </Tab>
          )}
        </Tabs>
      </div>
    </Page>
  );
};

export default createStyledComponent(AssetForm, assetFormStyle);
