import { Action, PayloadAction } from "../types";

export enum LoginActionTypes {
  LOGIN_USER = "LOGIN_USER",
  LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS",
  LOGIN_USER_ERROR = "LOGIN_USER_ERROR",
  LOGOUT_USER = "LOGOUT_USER",
  LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST",
  LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR",
  CHANGE_USER_ACCOUNT_PASSWORD = "CHANGE_USER_ACCOUNT_PASSWORD",
  CHANGE_USER_ACCOUNT_PASSWORD_REQUEST = "CHANGE_USER_ACCOUNT_PASSWORD_REQUEST",
  CHANGE_USER_ACCOUNT_PASSWORD_SUCCESS = "CHANGE_USER_ACCOUNT_PASSWORD_SUCCESS",
  CHANGE_USER_ACCOUNT_PASSWORD_ERROR = "CHANGE_USER_ACCOUNT_PASSWORD_ERROR"
}

// TODO: create type for User
export type LoginUserAction = PayloadAction<
  LoginActionTypes.LOGIN_USER,
  {
    user: any;
  }
>;

export type LoginUserRequestAction = PayloadAction<
  LoginActionTypes.LOGIN_USER_REQUEST,
  {
    user: any;
  }
>;

export type LoginUserSuccessAction = PayloadAction<
  LoginActionTypes.LOGIN_USER_SUCCESS,
  {
    user: any;
  }
>;

export type LoginUserErrorAction = PayloadAction<LoginActionTypes.LOGIN_USER_ERROR, { errorMessage: string }>;

export type LogoutUserAction = Action<LoginActionTypes.LOGOUT_USER>;

export type LogoutUserRequestAction = Action<LoginActionTypes.LOGOUT_USER_REQUEST>;

export type LogoutUserSuccessAction = Action<LoginActionTypes.LOGOUT_USER_SUCCESS>;

export type LogoutUserErrorAction = PayloadAction<LoginActionTypes.LOGOUT_USER_ERROR, { errorMessage: string }>;

export type ChangeUserAccountPasswordAction = PayloadAction<
  LoginActionTypes.CHANGE_USER_ACCOUNT_PASSWORD,
  {
    password: string;
  }
>;

export type ChangeUserAccountPasswordRequestAction = PayloadAction<
  LoginActionTypes.CHANGE_USER_ACCOUNT_PASSWORD_REQUEST,
  {
    password: string;
  }
>;

// TODO; verify is user is needed
export type ChangeUserAccountPasswordSuccessAction = PayloadAction<
  LoginActionTypes.CHANGE_USER_ACCOUNT_PASSWORD_SUCCESS,
  {
    user: any;
  }
>;

export type ChangeUserAccountPasswordErrorAction = PayloadAction<LoginActionTypes.CHANGE_USER_ACCOUNT_PASSWORD_ERROR, { errorMessage: string }>;
