import thunk from "redux-thunk";
import reducers from "./reducers";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import sagas from "./sagas";
import LogRocket from "logrocket";
import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleware();
const middlewareList = [thunk, sagaMiddleware];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [...middlewareList, LogRocket.reduxMiddleware()],
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
