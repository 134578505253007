/* eslint-disable no-nested-ternary */

import { SystemModel } from "app/domain/system";
import { useI18n } from "app/locales";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useState } from "react";
import { Container, Table } from "semantic-ui-react";
import { css } from "styled-components";
import Loader from "ui/loader/loader";

const resultTableStyle = css`
  margin: 10px 0 0 0;
  height: 280px;
  position: relative;
  overflow-y: scroll;
  .loaderContainer {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
  }
`;

interface SystemModelResultsTableProps extends ComponentProps {
  selectedSystemModelId: string | undefined;
  systemModels: SystemModel[];
  isLoadingSystemModels: boolean;
  onRowClick: (systemModelId: string) => void;
}

const SystemModelResultsTable: FC<SystemModelResultsTableProps> = ({
  selectedSystemModelId,
  systemModels,
  isLoadingSystemModels,
  onRowClick,

  className
}: SystemModelResultsTableProps) => {
  const { getAttributeLabel } = useI18n();

  const [activeSystemModelId, setActiveSystemModelId] = useState(selectedSystemModelId || "");

  const handleRowClick = (systemModelId: string): void => {
    onRowClick(systemModelId);
    setActiveSystemModelId(systemModelId);
  };

  return (
    <Container className={className}>
      <div className="loaderContainer">
        <Loader className="loader" isActive={isLoadingSystemModels} height={100} />
      </div>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Uniformat</Table.HeaderCell>
            <Table.HeaderCell>{getAttributeLabel("name")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body data-test-id="rows">
          {systemModels.length === 0 ? (
            <Table.Row>
              <Table.Cell>Aucun résultats</Table.Cell>
            </Table.Row>
          ) : (
            systemModels.map((sys: SystemModel) => (
              <Table.Row key={sys.id} active={activeSystemModelId === sys.id} onClick={(): void => handleRowClick(sys.id)} data-test-id="systemModelRow">
                <Table.Cell>{sys.uniformat.level4 ? sys.uniformat.level4 : sys.uniformat.level3}</Table.Cell>
                <Table.Cell>{sys.name}</Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </Container>
  );
};

export default createStyledComponent(memo(SystemModelResultsTable), resultTableStyle);
