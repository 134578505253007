import { PhotosFolder, PhotosFolderDownloadStatus } from "app/domain/photo";

export type PhotosFolderDTO = {
  id: string;
  location: string;
  progress: number;
  status: PhotosFolderDownloadStatus;
};

class PhotosFolderMapper {
  static fromDTO(photosFolderDTO: PhotosFolderDTO): PhotosFolder {
    return new PhotosFolder(photosFolderDTO.id, photosFolderDTO.location, photosFolderDTO.progress, photosFolderDTO.status);
  }
}

export default PhotosFolderMapper;
