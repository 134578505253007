export const MODAL_TYPE_SYSTEM = "system";
export const MODAL_TYPE_REQUIREMENT = "requirement";

export const INDEX_MECHANIC_COMPONENTS_TAB = 3;
export const INDEX_ARCHITECTURE_COMPONENTS_TAB = 4;
export const INDEX_REQUIREMENTS_TAB = 5;

export const BASIC_INFORMATION = "Informations de base";
export const INPUT_INFORMATION = "Informations de saisie";

export const ONGOING_AUDITS = "Audits en cours";
export const FINISHED_AUDITS = "Audits terminés";

export const MECHANICAL_SYSTEMS = "Mécanique / Systèmes";
export const ARCHITECTURAL_SYSTEMS = "Architecture / Systèmes";
export const REQUIREMENTS = "Exigences";
export const ROOMS = "Locaux";

export const PHOTOS = "Photos";
