import { RefObject, useCallback, useMemo, useState } from "react";

const IMAGE_FORMAT = "image/jpeg";

type WebcamStreamHook = {
  isStreaming: boolean;
  isStreamVisible: boolean;
  openStream: () => void;
  playStream: () => void;
  pauseStream: () => void;
  closeStream: () => void;
  getScreenShot: () => string;
};

const useWebcamStream = (webcamStreamReference: RefObject<HTMLVideoElement>): WebcamStreamHook => {
  const [isStreaming, setStreamingState] = useState(false);
  const [isStreamVisible, setStreamVisibility] = useState(false);

  const playStream = useCallback(() => {
    const stream = webcamStreamReference.current as unknown as HTMLVideoElement;

    stream.play();

    setStreamingState(true);
    setStreamVisibility(true);
  }, [webcamStreamReference]);

  const openStream = useCallback(() => {
    const stream = webcamStreamReference.current as unknown as HTMLVideoElement;

    stream.onloadedmetadata = playStream;
  }, [webcamStreamReference, playStream]);

  const pauseStream = useCallback(() => {
    const stream = webcamStreamReference.current as unknown as HTMLVideoElement;

    stream.pause();

    setStreamingState(false);
    setStreamVisibility(true);
  }, [webcamStreamReference]);

  const closeStream = useCallback(() => {
    setStreamingState(false);
    setStreamVisibility(false);
  }, []);

  const getScreenShot = useCallback(() => {
    const stream = webcamStreamReference.current as unknown as HTMLVideoElement;

    const canvas = document.createElement("canvas");
    canvas.width = stream.videoWidth;
    canvas.height = stream.videoHeight;
    const context = canvas.getContext("2d");

    if (context) {
      context.imageSmoothingEnabled = true;
      context.drawImage(stream, 0, 0, canvas.width, canvas.height);
    }

    return canvas.toDataURL(IMAGE_FORMAT, 1);
  }, [webcamStreamReference]);

  return useMemo(
    () => ({
      isStreaming,
      isStreamVisible,
      openStream,
      playStream,
      pauseStream,
      closeStream,
      getScreenShot
    }),
    [isStreaming, isStreamVisible, openStream, playStream, pauseStream, closeStream, getScreenShot]
  );
};

export default useWebcamStream;
