import Typography from "@mui/material/Typography";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import styled from "styled-components";

export const StyledTypography = styled(Typography)`
  &.MuiTypography-body1 {
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Luminari", sans-serif;
  }
`;

interface InputLabelProps extends ComponentProps {
  label: string;
  text: string | number;
}

const InputLabel: FC<InputLabelProps> = ({ label, text }: InputLabelProps) => {
  return (
    <div>
      <div>
        <StyledTypography gutterBottom>{label}</StyledTypography>
      </div>
      <div>{text}</div>
    </div>
  );
};

export default InputLabel;
