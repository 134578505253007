import { System } from "app/domain/system";
import { Params, useRouter } from "app/routing/routerProvider";
import { getAssetsMinimalInfoAction } from "app/store/asset/actions";
import { getLocalsAction } from "app/store/local/actions";
import { getUserName } from "app/store/login/selectors";
import { deleteSystemAction, getSystemOptionsAction, updateSystemAction } from "app/store/system/actions";
import { getCurrentSystem, shouldRedirectToSystems as shouldRedirectToSystemsSelector } from "app/store/system/selectors";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { useLoader } from "app/view/common/loader/loaderProvider";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { Loader } from "ui/loader";

import { SystemForm } from "./systemForm";

const modifySystemContainerStyle = css`
  position: relative;
  flex: 1;
`;

type ModifySystemContainerProps = ComponentProps;

const ModifySystemContainer: FC<ModifySystemContainerProps> = ({ className }: ModifySystemContainerProps) => {
  const [modifyAttempt, setModifyAttempt] = useState(false);
  const { getParam } = useRouter();
  const { isActive } = useLoader();
  const { errorMessage, onDismiss, onUpdate } = useErrorMessage();

  const currentSystem = useSelector(getCurrentSystem);
  const currentUser = useSelector(getUserName);

  const shouldRedirectToSystems = useSelector(shouldRedirectToSystemsSelector);

  const dispatch = useDispatch();

  const projectId = getParam(Params.PROJECT);
  const siteId = getParam(Params.SITE);
  const assetId = getParam(Params.ASSET);
  const systemId = getParam(Params.SYSTEM);

  useEffect(() => {
    dispatch(getSystemOptionsAction());
    dispatch(getAssetsMinimalInfoAction(siteId));
    dispatch(getLocalsAction(assetId));

    return (): void => {
      onDismiss();
    };
  }, [dispatch, onDismiss, projectId, siteId, assetId]);

  const saveSystem = useCallback(
    (system: System, isClosing: boolean) => {
      const updatedSystem = {
        ...system,
        id: systemId,
        site_id: undefined,
        floor_id: undefined,
        quantity: system.quantity ? system.quantity : 0,
        updated_by: currentUser
      };

      dispatch(updateSystemAction(updatedSystem, isClosing));
      setModifyAttempt(true);
    },
    [dispatch, currentUser, systemId]
  );

  const deleteSystem = useCallback(() => {
    dispatch(deleteSystemAction(systemId));
  }, [dispatch, systemId]);

  const verifyModifyAttempt = (): void => {
    if (errorMessage === "" && modifyAttempt) {
      setModifyAttempt(false);
    }
  };

  return (
    <div className={className}>
      {verifyModifyAttempt()}
      <Loader isActive={isActive} />
      <SystemForm
        system={currentSystem}
        onConfirm={saveSystem}
        onDelete={deleteSystem}
        projectId={projectId}
        siteId={siteId}
        assetId={assetId}
        systemId={systemId}
        isElementInCreationMode={false}
        errorMessage={errorMessage}
        onReset={onDismiss}
        onUpdate={onUpdate}
        shouldRedirectToComponentsPage={shouldRedirectToSystems}
      />
    </div>
  );
};

export default createStyledComponent(memo(ModifySystemContainer), modifySystemContainerStyle);
