/* eslint-disable no-nested-ternary, react-hooks/exhaustive-deps */

import Requirement from "app/domain/requirement/requirement";
import { SystemFields } from "app/view/system/form/systemForm/useSystem";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useState } from "react";
import { Segment as SemanticSegment } from "semantic-ui-react";
import { css } from "styled-components";
import { Button } from "ui/button";
import { TextField, useTextField } from "ui/form/controls/textField";
import { IconType } from "ui/icon";

import RequirementUniformatModal from "./requirementUniformatModal";

const requirementUniformatSectionStyle = css`
  padding: 1em 0;
  width: 100%;
  .section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

interface RequirementUniformatSectionProps extends ComponentProps {
  projectId: string;
  requirement: Requirement | undefined;
  isElementInCreationMode: boolean;
  handleUniformat3Reset: (uniformat3: string) => void;
  onUpdate: (fields: SystemFields) => void;
}

const RequirementUniformatSection: FC<RequirementUniformatSectionProps> = ({
  isElementInCreationMode,
  projectId,
  requirement,
  onUpdate,
  handleUniformat3Reset,
  className
}: RequirementUniformatSectionProps) => {
  const { text: uniformat, onInput: onUniformatChange } = useTextField(
    requirement && requirement.uniformat && requirement.uniformat.level4
      ? requirement.uniformat.level4
      : requirement && requirement.uniformat && requirement.uniformat.level3
      ? requirement.uniformat.level3
      : requirement && requirement.uniformat && requirement.uniformat.level2
      ? requirement.uniformat.level2
      : requirement && requirement.uniformat && requirement.uniformat.level1
      ? requirement.uniformat.level1
      : ""
  );

  const [isUniformatModalOpen, setIsUniformatModalOpen] = useState(!!isElementInCreationMode);

  const onOpenRequirementUniformatModal = (): void => {
    setIsUniformatModalOpen(true);
  };

  const onCloseUniformatModal = (): void => {
    setIsUniformatModalOpen(false);
  };

  return (
    <div className={className}>
      <SemanticSegment className="section">
        <TextField label="Uniformat" readOnly text={uniformat} onInput={onUniformatChange} data-test-id="uniformatTextField" />
        <div>
          <Button title="Sélectionner" modifiers="outlined" iconType={IconType.EDIT_OUTLINE} onClick={onOpenRequirementUniformatModal} data-test-id="button" />
        </div>
      </SemanticSegment>
      <RequirementUniformatModal
        projectId={projectId}
        isModalOpen={isUniformatModalOpen}
        handleUniformat3Reset={handleUniformat3Reset}
        uniformat={requirement && requirement.uniformat}
        onUpdate={onUpdate}
        onClose={onCloseUniformatModal}
        data-test-id="uniformatModal"
      />
    </div>
  );
};

export default createStyledComponent(RequirementUniformatSection, requirementUniformatSectionStyle);
