import { UniformatOption } from "app/domain/uniformat/options";

export type UniformatOptionDTO = {
  id: number;
  level: string;
  text: string;
  value: string;
};

class UniformatOptionMapper {
  static fromDTO(uniformatOptionDTOs: UniformatOptionDTO[]): UniformatOption[] {
    return uniformatOptionDTOs.map(
      (uniformatOptionDTO: UniformatOptionDTO): UniformatOption =>
        new UniformatOption({
          id: uniformatOptionDTO.id,
          level: uniformatOptionDTO.level,
          text: uniformatOptionDTO.text,
          value: uniformatOptionDTO.value
        })
    );
  }
}

export default UniformatOptionMapper;
