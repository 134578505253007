/* eslint-disable react-hooks/exhaustive-deps */

import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useEffect } from "react";
import FormControl, { BaseFormControlProps } from "ui/form/formControl";
import { useFormContext } from "ui/form/useForm";

import Dropdown, { DropdownItem } from "./dropdown";
import useDropdown from "./useDropdown";

interface BaseDropdownProps extends ComponentProps {
  children: JSX.Element[] | JSX.Element;
  value?: string;
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
}

interface ControlledDropdownProps extends BaseDropdownProps {
  onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownItem) => void;
}

interface UncontrolledDropdownProps extends BaseDropdownProps, BaseFormControlProps {}

type DropdownProps = ControlledDropdownProps | UncontrolledDropdownProps;

function isControlled(props: DropdownProps): props is ControlledDropdownProps {
  return "onChange" in props;
}

const DropdownControl: FC<DropdownProps> = (props: DropdownProps) => {
  const { value, children, label, placeholder, isLoading, isDisabled, className } = props;

  const formContext = useFormContext();

  const { value: currentValue, onChange } = useDropdown(value);

  useEffect(() => {
    if (!isControlled(props)) {
      const { name } = props;

      if (formContext) {
        formContext.setValue(name, value);
      }
    }
  }, [value]);

  const handleChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownItem): void => {
    event.preventDefault();

    onChange(event, data);

    if (formContext) {
      formContext.setValue((props as UncontrolledDropdownProps).name, data.value);
    }
  };

  return isControlled(props) ? (
    <Dropdown
      placeholder={placeholder}
      label={label}
      onChange={(props as ControlledDropdownProps).onChange}
      value={value}
      className={className}
      isLoading={isLoading || false}
      isDisabled={isDisabled || false}
      data-test-id="dropdown"
    >
      {children}
    </Dropdown>
  ) : (
    <FormControl name={(props as UncontrolledDropdownProps).name} rules={(props as UncontrolledDropdownProps).rules} className={className}>
      <>
        <Dropdown
          placeholder={placeholder}
          label={label}
          onChange={handleChange}
          value={currentValue}
          isLoading={isLoading || false}
          isDisabled={isDisabled || false}
          className={className}
        >
          {children}
        </Dropdown>
      </>
    </FormControl>
  );
};

export default DropdownControl;
