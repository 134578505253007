import { useRouter } from "app/routing/routerProvider";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Button } from "ui/button";
import { IconType } from "ui/icon";

interface NavigationButtonProps extends ComponentProps {
  label: string;
  iconType: IconType;
  itemId: string;
  tabIndex?: number;
}

const NavigationButton: FC<NavigationButtonProps> = ({ label, itemId, iconType, tabIndex }: NavigationButtonProps) => {
  const { goTo, location } = useRouter();

  const baseUrl = location.substring(0, location.lastIndexOf("/"));

  return <Button modifiers="outlined" title={label} onClick={(): void => goTo(`${baseUrl}/${itemId}`, { tabIndex })} iconType={iconType} data-test-id="button" />;
};

export default NavigationButton;
