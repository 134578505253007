import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo, useCallback } from "react";
import Dropzone, { IFileWithMeta } from "react-dropzone-uploader";

import ImagePreview from "./imagePreview";
import { ImageStatus } from "./imageStatus";
import ImageUploadButton from "./imageUploadButton";
import { Layout } from "./layout";

interface ImageUploaderProps extends ComponentProps {
  onChange: (file: File, imageStatus: ImageStatus) => void;
}

const ImageUploader: FC<ImageUploaderProps> = ({ onChange }: ImageUploaderProps) => {
  const handleChangeStatus = useCallback(
    (fileWithMeta: IFileWithMeta) => {
      onChange(fileWithMeta.file, fileWithMeta.meta.status as ImageStatus);
    },
    [onChange]
  );

  return <Dropzone onChangeStatus={handleChangeStatus} InputComponent={ImageUploadButton} LayoutComponent={Layout} PreviewComponent={ImagePreview} accept="image/*" />;
};

export default memo(ImageUploader);
