import System from "app/domain/system/system";
import { useI18n } from "app/locales";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect } from "react";
import compare from "react-fast-compare";
import { css, SimpleInterpolation } from "styled-components";
import TextField from "ui/form/controls/textField/textField";
import useTextField from "ui/form/controls/textField/useTextField";

import { SystemCopyTable } from "./systemCopyTable";
import { SystemCopyFields } from "./systemCopyTable/useSystemCopyTable";

const systemCopyModalContentStyle = css`
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 600px;

  ${TextField as unknown as SimpleInterpolation} {
    width: 200px;
  }

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

interface SystemCopyModalContentProps extends ComponentProps {
  systemCopies: System[];
  onUpdate: (systemCopyFields: SystemCopyFields, index: number) => void;
  onDelete: (quantity: number) => void;
  onAdd: (quantity: number) => void;
}

const isEqual = (prevProps: SystemCopyModalContentProps, nextProps: SystemCopyModalContentProps): boolean => compare(prevProps.systemCopies, nextProps.systemCopies);

const SystemCopyModalContent: FC<SystemCopyModalContentProps> = ({ systemCopies, onUpdate, onDelete, onAdd, className }: SystemCopyModalContentProps) => {
  const { getAttributeLabel } = useI18n();

  const { text: systemCopiesNumber, onInput: onSystemCopiesNumberInput } = useTextField("1");

  useEffect(() => {
    if (systemCopiesNumber !== "") {
      const quantity = Number(systemCopiesNumber) - systemCopies.length;
      if (quantity < 0) {
        onDelete(Math.abs(quantity));
      } else {
        onAdd(quantity);
      }
    }
  }, [systemCopiesNumber, systemCopies.length, onDelete, onAdd]);

  return (
    <div className={className}>
      <TextField label={getAttributeLabel("quantity")} text={systemCopiesNumber} number onInput={onSystemCopiesNumberInput} data-test-id="nbOfCopiesTextField" />
      <SystemCopyTable systemCopies={systemCopies} onUpdate={onUpdate} />
    </div>
  );
};

export default createStyledComponent(memo(SystemCopyModalContent, isEqual), systemCopyModalContentStyle);
