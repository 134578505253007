import { createAction } from "@reduxjs/toolkit";
import { Site } from "app/domain/site";

import { SiteActionTypes } from "./types";

export const getSiteAction = createAction(SiteActionTypes.GET_SITE, (siteId: string) => ({
  payload: {
    siteId
  }
}));

export const getSiteRequestAction = createAction(SiteActionTypes.GET_SITE_REQUEST, (siteId: string) => ({
  payload: {
    siteId
  }
}));

export const getSiteSuccessAction = createAction(SiteActionTypes.GET_SITE_SUCCESS, (site: Site) => ({
  payload: {
    site
  }
}));

export const getSiteErrorAction = createAction(SiteActionTypes.GET_SITE_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const getSitesAction = createAction(SiteActionTypes.GET_SITES, (projectId?: string) => ({
  payload: {
    projectId
  }
}));

export const getSitesRequestAction = createAction(SiteActionTypes.GET_SITES_REQUEST, (projectId?: string) => ({
  payload: {
    projectId
  }
}));

export const getSitesSuccessAction = createAction(SiteActionTypes.GET_SITES_SUCCESS, (sites: Site[]) => ({
  payload: {
    sites
  }
}));

export const getSitesErrorAction = createAction(SiteActionTypes.GET_SITES_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const updateSiteAction = createAction(SiteActionTypes.UPDATE_SITE, (site: Site) => ({
  payload: {
    site
  }
}));

export const updateSiteRequestAction = createAction(SiteActionTypes.UPDATE_SITE_REQUEST, (site: Site) => ({
  payload: {
    site
  }
}));

export const updateSiteSuccessAction = createAction(SiteActionTypes.UPDATE_SITE_SUCCESS, (site: Site) => ({
  payload: {
    site
  }
}));

export const updateSiteErrorAction = createAction(SiteActionTypes.UPDATE_SITE_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const generateSiteReportAction = createAction(SiteActionTypes.GENERATE_SITE_REPORT, (siteId: string) => ({
  payload: {
    siteId
  }
}));

export const generateSiteReportRequestAction = createAction(SiteActionTypes.GENERATE_SITE_REPORT_REQUEST, (siteId: string) => ({
  payload: {
    siteId
  }
}));

export const generateSiteReportSuccessAction = createAction(SiteActionTypes.GENERATE_SITE_REPORT_SUCCESS);

export const generateSiteReportErrorAction = createAction(SiteActionTypes.GENERATE_SITE_REPORT_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const resetAction = createAction(SiteActionTypes.RESET_SITE);
