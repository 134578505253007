import { Grid } from "@mui/material";
import { System } from "app/domain/system";
import { useI18n } from "app/locales";
import { getOptionsLists } from "app/store/system/selectors";
import { formatPercentage, moneyFormatter } from "app/utils/formatter";
import { Separator } from "app/view/common/form";
import InfoBubble from "app/view/system/form/systemForm/formSections/InfoBubble";
import { SystemFields } from "app/view/system/form/systemForm/useSystem";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Form as SemanticForm } from "semantic-ui-react";
import InputLabel from "ui/inputLabel/inputLabel";
import Label from "ui/label/label";

interface MonetaryInformationProps extends ComponentProps {
  system: System | undefined;
  projectId?: string | undefined;
  onUpdate: (fields: SystemFields) => void;
  onNavigationBlock: () => void;
  systemWithoutModel?: boolean;
}

const calculateIndirectCosts = (projectId: string | undefined): number => (projectId === "PL-01347" ? 1.0 : 1.45);

const MonetaryInformation = ({ system, projectId, onUpdate, onNavigationBlock, systemWithoutModel }: MonetaryInformationProps): JSX.Element => {
  const { getAttributeLabel } = useI18n();

  const listsChoices = useSelector(getOptionsLists);

  const costAdjustmentFactor = useMemo(() => {
    const complexity = system && system.complexity ? system.complexity : 0;

    return complexity + 1;
  }, [system]);

  const totalCost = useMemo(() => {
    const quantity = system && system.quantity ? system.quantity : 0;
    const unitCost = system && system.unitCost ? system.unitCost : 0;

    return quantity * unitCost;
  }, [system]);

  const replacementCost = useMemo(() => {
    return totalCost * costAdjustmentFactor * calculateIndirectCosts(projectId);
  }, [totalCost, costAdjustmentFactor, projectId]);

  const totalPercentage = useMemo(() => {
    const renewalPercentage = system && system.renewalPercentage ? system.renewalPercentage : 0;
    const removal = system && system.removal ? system.removal : 0;
    const patrimonyFactor = system && system.patrimony_factor ? system.patrimony_factor : 0;

    return renewalPercentage + removal + patrimonyFactor;
  }, [system]);

  const renewalCost = useMemo(() => {
    return replacementCost * totalPercentage;
  }, [replacementCost, totalPercentage]);

  const handleRenewalPercentageValue = (event: any): void => {
    onUpdate({ renewalPercentage: event.target.value });
  };

  const handleSelectChange = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({
      [data.name]: data.value === "" ? null : data.value
    });

    onNavigationBlock();
  };

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
        <Grid container item xs={6} spacing={1}>
          <Grid item xs={4} alignContent="end">
            <Label label="Ajustement - Complexité" />
          </Grid>
          <Grid item xs={4}>
            <SemanticForm.Dropdown
              aria-label="complexityDropDown"
              clearable
              name="complexity"
              value={system && system.complexity}
              onChange={handleSelectChange}
              selection
              fluid
              options={listsChoices ? listsChoices.list_complexity_adjustment_factor_options : []}
              data-testid="complexityDropDown"
            />
          </Grid>
        </Grid>
        <Grid container item xs={6} spacing={1}>
          <Grid item xs={6} alignContent="end">
            <Label data-testid="totalCostField" label="Frais indirects" />
          </Grid>
          <Grid item xs={1}>
            <InputLabel data-testid="indirectCostsField" key="indirectCostsField" label="" text={calculateIndirectCosts(projectId)} />
          </Grid>
        </Grid>
        <Grid container item xs={6} spacing={1}>
          <Grid item xs={6} alignContent="end">
            <Label label="Facteur d'ajustement total" />
          </Grid>
          <Grid item xs={1}>
            <InputLabel
              arial-label="costAdjustmentFactorField"
              data-testid="costAdjustmentFactorField"
              key="costAdjustmentFactorField"
              label=""
              text={costAdjustmentFactor.toFixed(2)}
            />
          </Grid>
        </Grid>

        <Grid container item xs={6} spacing={1}>
          <Grid item xs={6} alignContent="end">
            <Label label="Coût de remplacement" />
          </Grid>
          <Grid item xs={1}>
            <InputLabel data-testid="replacementCostField" key="replacementCostField" label="" text={moneyFormatter.format(replacementCost)} />
          </Grid>
        </Grid>
      </Grid>

      <Separator />
      <Grid container spacing={2}>
        <Grid container item spacing={3}>
          <Grid container item xs={6} spacing={1}>
            <Grid item xs={4} alignContent="end">
              <Label label="Ajustement - Enlèvement" />
            </Grid>
            <Grid item xs={4}>
              <SemanticForm.Dropdown
                aria-label="removalDropDown"
                clearable
                fluid
                name="removal"
                value={system && system.removal}
                onChange={handleSelectChange}
                selection
                options={listsChoices ? listsChoices.list_removal_options : []}
                data-testid="removalDropDown"
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid container item xs={6} spacing={1}>
            <Grid item xs={4} alignContent="end">
              <Label label="Facteur patrimonial" />
            </Grid>
            <Grid item xs={4}>
              <SemanticForm.Dropdown
                aria-label="patrimony_factor"
                clearable
                name="patrimony_factor"
                value={system && system.patrimony_factor}
                onChange={handleSelectChange}
                selection
                fluid
                options={listsChoices ? listsChoices.list_patrimony_factor_options : []}
                data-testid="patrimonyFactorDropDown"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid container item xs={6} spacing={1}>
            <Grid item xs={4} alignContent="end">
              <Label label={getAttributeLabel("renewal_percentage")} />
            </Grid>
            <Grid item xs={4}>
              <SemanticForm.Input
                disabled={!systemWithoutModel && !(system?.model_number === "Custom")}
                // eslint-disable-next-line no-nested-ternary
                value={system?.model_number === "Custom" ? (system && system.renewalPercentage ? system.renewalPercentage : "") : formatPercentage(system?.renewalPercentage)}
                readOnly={!systemWithoutModel && !(system?.model_number === "Custom")}
                fluid
                data-testid="renewalPercentageField"
                onChange={handleRenewalPercentageValue}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid container item xs={6} spacing={1}>
            <Grid item xs={4} alignContent="end">
              <InfoBubble label="Pourcentage de renouvellement total" infoText="Pourcentage de renouvellement du système + Facteur d'amiante + Facteur patrimonial" />
            </Grid>
            <Grid item xs={4}>
              <SemanticForm.Input disabled value={formatPercentage(totalPercentage)} readOnly fluid data-testid="totalPercentageField" />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid container item xs={6} spacing={1}>
            <Grid item xs={4} alignContent="end">
              <InfoBubble label="Coût de renouvellement" infoText="Coût de remplacement x Pourcentage de renouvellement total" />
            </Grid>
            <Grid item xs={4}>
              <SemanticForm.Input disabled value={moneyFormatter.format(renewalCost)} readOnly fluid data-testid="renewalCostField" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MonetaryInformation;
