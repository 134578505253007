import React, { memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { listAllNames } from "app/store/sideMenu/sideMenuActions";
import { withRouter } from "react-router";
import "./sideMenu.css";
import { ListItemButton, ListItemText, Collapse } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import Styled from "styled-components";
import { colors } from "ui/colors";
import arrow from "./left-arrow.svg";
import MenuList from "./menuList";
import { isLoggedIn } from "app/store/login/selectors";
import env from "@beam-australia/react-env";

const NavOption = Styled.div`
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    transition: background-color 0.2s;

    &>p {
        margin-left: 5px;
        line-height: 28px;
        font-family: 'Arial';
        font-size: 15px;
    }
    
    &>img {
        width: 25px;
        height: 25px;
        opacity: 0.8;
    }
    
    &:hover {
        background-color: ${colors.ACCENT_BLUE};
        cursor: pointer;
    }
`;

const MenuContainer = Styled.div`
    position: absolute;
    top: 0;
    overflow: auto;
    width: 190px;
    
    &>div:not(:first-child) {
        background-color: ${colors.SECONDARY_BLACK};
        transition: background-color 0.4s ease;
        
        &:hover {
            background-color: ${colors.ACCENT_BLUE};
        }
        
        &> li {
            background-color: ${colors.SECONDARY_BLACK};
        }
    }    
`;

const CloseButton = Styled(NavOption)`
    height: 52px;
    background-color: ${colors.SECONDARY_BLACK};
    border-top: 1px solid ${colors.ACCENT_GREY};
    border-bottom: 1px solid ${colors.ACCENT_GREY};
    &>img {
        margin-left: 40px;
        height: 22px;
        width: 22px;
        height: 100%;
    }
`;

class Menu2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAssetId: null,
    };
  }

  UNSAFE_componentWillMount() {
    if (localStorage.getItem("token")) this.props.actions.listAllNames();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      let projectId =
        window.location.href.split("/").length > 5
          ? window.location.href.split("/")[4]
          : null;
      this.setState({ projectId });
    }
  }

  selectAsset = (assetId) => {
    this.setState({ activeAssetId: assetId });
  };

  renderSiteOption(site) {
    const id = this.state[site.id];
    const { activeAssetId } = this.state;
    const items = site.assets.map((asset) => ({
      id: asset.id,
      label: asset.asset_number,
      url: asset.url,
    }));
    return (
      <div>
        <ListItemButton
          onClick={() => this.setState({ [site.id]: id ? null : site.id })}
        >
          <ListItemText primary={site.name} />
          {id ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse
          style={{ listStyle: "none" }}
          component="li"
          timeout="auto"
          unmountOnExit
          in={id}
        >
          <ListItemButton
            style={{ color: "white" }}
            onClick={() => this.props.history.push(site.url)}
          >
            <ListItemText primary="Consulter ce site" />
          </ListItemButton>
          <MenuList
            items={items}
            selectedItemId={activeAssetId}
            onSelectItem={this.selectAsset}
          />
        </Collapse>
      </div>
    );
  }

  listSites = (data) => {
    let sites = this.state.projectId
      ? data.sites
          .filter((x) => x.project_id === this.state.projectId)
          .map((x) => this.renderSiteOption(x))
      : data.sites.map((x) => this.renderSiteOption(x));
    return sites;
  };

  render() {
    const { data } = this.props;
    if (!data) return <div>Loading...</div>;
    return (
      <MenuContainer
        style={{
          maxHeight: `calc(100% - ${
            env("API_HOST").includes("staging") ||
            env("API_HOST").includes("local.unifika.ca")
              ? "75px"
              : "50px"
          })`,
        }}
      >
        <CloseButton onClick={() => this.props.onClose()}>
          <img src={arrow} alt="arrow-icon" />
          <p style={{ margin: "auto 0" }}>Fermer</p>
        </CloseButton>
        {this.listSites(data)}
      </MenuContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.minimalInfo.data,
    isFetching: state.minimalInfo.isFetching,
    error: state.minimalInfo.error,
    isLoggedIn: isLoggedIn(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ listAllNames }, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(Menu2))
);
