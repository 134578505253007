interface SystemMinimalInfoProps {
  id: string;
  reference: string;
  customNameConcat: string;
}

class SystemMinimalInfo {
  id: string;
  reference: string;
  customNameConcat: string;

  constructor(systemMinimalInfoProps: SystemMinimalInfoProps) {
    this.id = systemMinimalInfoProps.id;
    this.reference = systemMinimalInfoProps.reference;
    this.customNameConcat = systemMinimalInfoProps.customNameConcat;
  }
}

export default SystemMinimalInfo;
