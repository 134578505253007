/* eslint-disable jsx-a11y/media-has-caption */

import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { forwardRef, memo, RefObject, useEffect, useRef } from "react";
import { css } from "styled-components";

export const webcamStreamStyle = css`
  video {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .background {
    video {
      object-fit: cover;
      transform: scale(1.5);
      filter: blur(25px);
      opacity: 0.5;
    }
  }

  .background::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: white;
  }
`;

const videoConstraints = {
  video: {
    width: { ideal: 4096 },
    height: { ideal: 2160 }
  },
  audio: false,
  facingMode: "environment"
};

interface WebcamStreamProps extends ComponentProps {
  onUserMedia: () => void;
  onUserMediaError: () => void;
}

const WebcamStream = forwardRef(({ onUserMedia, onUserMediaError, className }: WebcamStreamProps, ref): JSX.Element => {
  const backgroundStreamReference = useRef(null);

  useEffect(() => {
    const foregroundStream = (ref as RefObject<HTMLVideoElement>).current;
    const backgroundStream = (backgroundStreamReference as RefObject<HTMLVideoElement>).current;

    const { mediaDevices } = navigator as Navigator;

    if (mediaDevices) {
      mediaDevices
        .getUserMedia(videoConstraints)
        .then((videoStream: MediaStream) => {
          (foregroundStream as HTMLVideoElement).srcObject = videoStream;
          (backgroundStream as HTMLVideoElement).srcObject = videoStream;

          onUserMedia();
        })
        .catch(() => {
          onUserMediaError();
        });
    }

    return (): void => {
      ((foregroundStream as HTMLVideoElement).srcObject as MediaStream).getVideoTracks().map((track) => track.stop());
    };
  }, [ref, backgroundStreamReference, onUserMedia, onUserMediaError]);

  return (
    <div className={className}>
      <div className="background">
        <video ref={backgroundStreamReference} autoPlay playsInline />
      </div>
      <video ref={ref as RefObject<HTMLVideoElement>} autoPlay playsInline data-test-id="webcamFeed" />
    </div>
  );
});

WebcamStream.displayName = "WebcamStream";

export default createStyledComponent(memo(WebcamStream), webcamStreamStyle);
