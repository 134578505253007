import { Dictionary, Gender } from "app/locales/types";
import { capitalize } from "app/utils/formatter";

import Attributes from "./attributes";

const ComparisonOperators: Record<string, any> = {
  greaterThan: {
    [Gender.M]: "doit être plus grand",
    [Gender.F]: "doit être plus grande"
  },
  greaterThanOrEquals: {
    [Gender.M]: "doit être plus grand ou égal",
    [Gender.F]: "doit être plus grande ou égale"
  },
  lessThanOrEquals: {
    [Gender.M]: "doit être plus petit ou égal",
    [Gender.F]: "doit être plus petite ou égale"
  },
  equals: {
    [Gender.M]: "doit être égal",
    [Gender.F]: "doit être égale"
  },
  notEquals: {
    [Gender.M]: "ne doit pas être égal",
    [Gender.F]: "ne doit pas être égale"
  },
  shouldNotContain: {
    [Gender.M]: "ne doit pas contenir",
    [Gender.F]: "ne doit pas contenir"
  }
};

const formatComparisonError = (firstValue: string, secondValue: string, comparisonOperator: any): string => {
  const formattedFirstValue = Attributes[firstValue] ? Attributes[firstValue].formatWithDefinedArticle() : firstValue;
  const formattedSecondValue = Attributes[secondValue]
    ? Attributes[secondValue].formatWithContractedDefinedArticle()
    : `${comparisonOperator === ComparisonOperators.greaterThan ? "que" : "à"} ${secondValue}`;
  const formattedOperator = Attributes[firstValue] && Attributes[firstValue].gender ? comparisonOperator[Attributes[firstValue].gender] : comparisonOperator[Gender.M];

  return `- ${formattedFirstValue} ${formattedOperator} ${formattedSecondValue}.`;
};

const formatParam = (param: any): string => {
  return capitalize(Attributes[param] ? Attributes[param].word : param) as string;
};

const formatParamsError = (params: string[], lastDelimiter: string): string => {
  let formattedError = "";
  if (!params) return formattedError;
  const firstParam = params[0];
  const middleParams = params.slice(1, -1);
  const lastParam = params[params.length - 1];

  formattedError = `${formatParam(firstParam)}`;

  if (params.length > 1) {
    middleParams.forEach((param: string): void => {
      formattedError = `${formattedError}, ${formatParam(param)}`;
    });

    formattedError = `${formattedError} ${lastDelimiter} ${formatParam(lastParam)}`;
  }

  return formattedError;
};

const Errors: Dictionary<any> = {
  comparisonError: (firstValue: string, secondValue: string, comparisonOperator: string): string =>
    formatComparisonError(firstValue, secondValue, ComparisonOperators[comparisonOperator]),
  requiredParamsError: (params: string[]): string => `- Les paramètres suivants sont requis: ${formatParamsError(params, "et")}.`,
  requiredParamsBetweenError: (params: string[]): string => `- Au moins un des paramètres parmi les suivants est requis: ${formatParamsError(params, "ou")}.`
};

export default Errors;
