import { Dictionaries, DictionaryCode } from "app/locales";
import { capitalize } from "app/utils/formatter";

import Error from "./error";

interface RequiredParamsBetweenErrorProps {
  params: string[];
}

class RequiredParamsBetweenError implements Error {
  params: string[];

  constructor(requiredParamBetweenProps: RequiredParamsBetweenErrorProps) {
    this.params = requiredParamBetweenProps.params;
  }

  format(dictionaryCode: DictionaryCode): string {
    return capitalize(Dictionaries[dictionaryCode].errors.requiredParamsBetweenError(this.params)) as string;
  }
}

export default RequiredParamsBetweenError;
