import { Project, ProjectProps } from "app/domain/projectSites";
import { QaError } from "app/domain/qaError";
import api, { NEW_API_VERSION } from "app/service/common/api";

import QaErrorMapper, { QaErrorDTO } from "./qaErrorMapper";

class QaErrorService {
  static getQaErrors = async ({ siteId, bypassed }: { siteId?: string; bypassed?: boolean } = {}): Promise<QaError[]> => {
    const path = {
      path: `${NEW_API_VERSION}/qa_errors`
    };
    if (siteId) {
      Object.assign(path, { queryParams: { site_id: siteId } });
    }
    if (bypassed) {
      Object.assign(path, { queryParams: { bypassed } });
    }

    const qaErrorsDTO = (await api.get(path)).data as QaErrorDTO[];

    return QaErrorMapper.fromDTO(qaErrorsDTO);
  };

  static getQaErrorsIgnored = async (): Promise<QaError[]> => {
    const path = {
      path: `${NEW_API_VERSION}/qa_errors`
    };

    const qaErrorsDTO = (await api.get(path)).data as QaErrorDTO[];

    return QaErrorMapper.fromDTO(qaErrorsDTO);
  };

  static getQaErrorsCount = async (): Promise<number> => {
    return (
      await api.get({
        path: `${NEW_API_VERSION}/qa_errors/count`
      })
    ).data as number;
  };

  static getProjectSites = async (): Promise<Project[]> => {
    const path = {
      path: `${NEW_API_VERSION}/projects?sites=true`
    };
    const projectSitesDTO = (await api.get(path)).data;

    return projectSitesDTO.map((project: ProjectProps) => new Project({ id: project.id, description: project.description, sites: project.sites }));
  };

  static runSitesQaRules = async (site_ids: string[], uniformat1: string[]): Promise<unknown> => {
    return api.post({
      path: `${NEW_API_VERSION}/sites/qa_rules`,
      body: { site_ids, uniformat1 }
    });
  };

  static bypassQaErrors = async (error_ids: string[], comment: string): Promise<QaError[]> => {
    return api.post({
      path: `${NEW_API_VERSION}/qa_errors/by_pass`,
      body: { error_ids, comment }
    }) as unknown as Promise<QaError[]>;
  };

  static keepQaErrors = async (error_ids: string[]): Promise<QaError[]> => {
    return api.post({
      path: `${NEW_API_VERSION}/qa_errors/keep_error`,
      body: { error_ids }
    }) as unknown as Promise<QaError[]>;
  };
}

export default QaErrorService;
