import { Dictionaries, DictionaryCode } from "app/locales";
import { capitalize } from "app/utils/formatter";

import { ComparisonOperator } from "./comparisonOperator";
import Error from "./error";

interface ComparisonErrorProps {
  firstValue: string;
  secondValue: string;
  operator: ComparisonOperator;
}

class ComparisonError implements Error {
  firstValue: string;
  secondValue: string;
  operator: ComparisonOperator;

  constructor(comparisonErrorProps: ComparisonErrorProps) {
    this.firstValue = comparisonErrorProps.firstValue;
    this.secondValue = comparisonErrorProps.secondValue;
    this.operator = comparisonErrorProps.operator;
  }

  format(dictionaryCode: DictionaryCode): string {
    return capitalize(Dictionaries[dictionaryCode].errors.comparisonError(this.firstValue, this.secondValue, this.operator)) as string;
  }
}

export default ComparisonError;
