import { createAction } from "@reduxjs/toolkit";

import { ReportActionTypes } from "./reportTypes";

export const generateTemplateReportAction = createAction(ReportActionTypes.GENERATE_TEMPLATE_REPORT);

export const generateTemplateReportRequestAction = createAction(ReportActionTypes.GENERATE_TEMPLATE_REPORT_REQUEST);

export const generateTemplateReportSuccessAction = createAction(ReportActionTypes.GENERATE_TEMPLATE_REPORT_SUCCESS);

export const generateTemplateReportErrorAction = createAction(ReportActionTypes.GENERATE_TEMPLATE_REPORT_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const importDataAction = createAction(ReportActionTypes.IMPORT_DATA, (report: File) => ({
  payload: { report }
}));

export const importDataRequestAction = createAction(ReportActionTypes.IMPORT_DATA_REQUEST, (report: File) => ({
  payload: { report }
}));

export const importDataSuccessAction = createAction(ReportActionTypes.IMPORT_DATA_SUCCESS);

export const importDataErrorAction = createAction(ReportActionTypes.IMPORT_DATA_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));
