/* eslint-disable react-hooks/exhaustive-deps */

import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useEffect } from "react";
import FormControl, { BaseFormControlProps } from "ui/form/formControl";
import { useFormContext } from "ui/form/useForm";

import TextArea from "./textArea";
import useTextArea from "./useTextArea";

interface BaseTextAreaProps extends ComponentProps {
  text: string;
  label?: string;
  readOnly?: boolean;
}

interface ControlledTextAreaProps extends BaseTextAreaProps {
  onInput: (event: React.FormEvent<HTMLTextAreaElement>) => void;
}

interface UncontrolledTextAreaProps extends BaseTextAreaProps, BaseFormControlProps {}

type TextAreaProps = ControlledTextAreaProps | UncontrolledTextAreaProps;

function isControlled(props: TextAreaProps): props is ControlledTextAreaProps {
  return "onInput" in props;
}

const TextAreaControl: FC<TextAreaProps> = (props: TextAreaProps) => {
  const { text, label, readOnly, className } = props;

  const formContext = useFormContext();

  const { text: currentText, onInput } = useTextArea(text);

  useEffect(() => {
    if (!isControlled(props)) {
      const { name } = props;

      if (formContext) {
        formContext.setValue(name, text);
      }
    }
  }, [text]);

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>): void => {
    event.preventDefault();
    const newText = event.currentTarget.value;

    onInput(event);

    if (formContext) {
      formContext.setValue((props as UncontrolledTextAreaProps).name, newText);
    }
  };

  return isControlled(props) ? (
    <TextArea label={label} readOnly={readOnly} text={text} onInput={(props as ControlledTextAreaProps).onInput} data-test-id="textArea" />
  ) : (
    <FormControl name={(props as UncontrolledTextAreaProps).name} rules={(props as UncontrolledTextAreaProps).rules} className={className}>
      <TextArea label={label} readOnly={readOnly} text={currentText} onInput={handleInput} />
    </FormControl>
  );
};

export default TextAreaControl;
