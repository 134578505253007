import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Button } from "ui/button";
import { IconType } from "ui/icon";

export interface DeleteButtonProps extends ComponentProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

const DeleteButton: FC<DeleteButtonProps> = ({ label, onClick, disabled }: DeleteButtonProps) => (
  <Button title={label} iconType={IconType.TRASH} onClick={onClick} disabled={disabled} data-test-id="button" />
);

export default DeleteButton;
