/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";

import { setSelectedValuesAction } from "./actions";
import { initialSelectedValueState, SelectedValueState } from "./state";
import { SetSelectedValueAction } from "./types";

const setSelectedValues = (state: SelectedValueState, action: SetSelectedValueAction): void => {
  state.selectedValues = action.payload.selectedValues;
};

const selectedValueReducer = createReducer(initialSelectedValueState, {
  [setSelectedValuesAction.type]: (state, action) => setSelectedValues(state, action)
});

export default selectedValueReducer;
