/* eslint-disable no-restricted-syntax */

import "ag-grid-community/dist/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/dist/styles/ag-theme-alpine.css"; // Optional theme CSS

import { ColumnApi, ColumnState, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Asset } from "app/domain/asset";
import { useI18n } from "app/locales";
import { deleteAgGridCustomFilterStateAction, resetAgGridCustomFilterStateAction, setAgGridCustomFilterStateAction, setFiltersActionV2 } from "app/store/filter/actions";
import { getAgGridCustomFilterState, getFiltersV2 } from "app/store/filter/selectors";
import { State } from "app/store/state";
import { formatArea, formatNumber, moneyFormatter } from "app/utils/formatter";
import ClickableCell from "app/view/gridTemplates/clickableCell";
import CustomTableFilter from "app/view/gridTemplates/customTableFilter";
import EditButtonCell from "app/view/gridTemplates/editButtonCell";
import AgGridFilterContainer from "app/view/gridTemplates/filterStateUI/agGridFilterContainer";
import { TableUtility } from "app/view/gridTemplates/tableUtility";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuditStatus } from "../../../domain/audit/auditStatus";
import { getAssets } from "../../../store/asset/selectors";

interface AssetTableProps extends ComponentProps {
  projectId: string;
  siteId?: string;
  auditStatus?: AuditStatus;
  displayDrawer?: (id: string) => void;
}

const AssetTable: FC<AssetTableProps> = ({ projectId, auditStatus, displayDrawer }: AssetTableProps) => {
  const currentTable = `AUDIT_${auditStatus}`;
  const dispatch = useDispatch();
  const { getAttributeLabel } = useI18n();
  const gridRef = useRef<any>(null); // For accessing Grid's API
  const [activeFilterColumn, setActiveFilterColumn] = useState([] as string[]);
  const assets = useSelector((state: State): Asset[] => getAssets(state));

  const savedColumnState = useSelector((state: State): ColumnState[] => getFiltersV2(state, currentTable));
  const savedFilterState = useSelector((state: State): any => getAgGridCustomFilterState(state, currentTable));

  const SITE_NAME = "siteName";
  const NUMBER = "number";
  const NAME = "name";

  const displayActiveFilters = (): void => {
    const tmp = [];
    const filterModel = (gridRef.current.api as GridApi).getFilterModel();
    for (const prop in filterModel) {
      if (Object.prototype.hasOwnProperty.call(filterModel, prop)) {
        try {
          if (filterModel[prop].selectedValues.length !== 0) {
            tmp.push(prop);
          }
        } catch (e) {
          if (filterModel[prop].filter !== "") {
            tmp.push(prop);
          }
        }
      }
    }
    setActiveFilterColumn(tmp);
  };

  const restoreGridState = (): void => {
    if (savedFilterState === null) return;
    if (projectId === savedFilterState.projectId) {
      for (const prop in savedFilterState.filterModal) {
        if (Object.prototype.hasOwnProperty.call(savedFilterState.filterModal, prop)) {
          activeFilterColumn.push(prop);
        }
      }
      setActiveFilterColumn(activeFilterColumn);

      (gridRef.current.columnApi as ColumnApi).applyColumnState({ state: savedColumnState });
      (gridRef.current.api as GridApi).setFilterModel(savedFilterState.filterModal);
    }
  };

  const saveGridState = (): void => {
    dispatch(setAgGridCustomFilterStateAction(currentTable, { filterModal: (gridRef.current.api as GridApi).getFilterModel(), projectId }));
    dispatch(setFiltersActionV2(currentTable, (gridRef.current.columnApi as ColumnApi).getColumnState()));
    displayActiveFilters();
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Site",
        headerTooltip: "Site",
        field: SITE_NAME,
        filter: CustomTableFilter,
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 100
      },
      {
        headerName: "Numéro",
        headerTooltip: "Numéro",
        field: NUMBER,
        filter: CustomTableFilter,
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 100,
        cellRenderer: "ClickableCell"
      },
      {
        headerTooltip: "Éditer",
        resizable: true,
        flex: 1,
        minWidth: 100,
        cellRenderer: "EditButtonCell",
        cellRendererParams: { displayDrawer }
      },
      {
        headerName: getAttributeLabel("name"),
        headerTooltip: getAttributeLabel("name"),
        field: NAME,
        filter: CustomTableFilter,
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 200
      },
      {
        headerName: "Superficie totale",
        headerTooltip: "Superficie totale",
        field: "area",
        filter: true,
        sortable: true,
        comparator: TableUtility.comparatorWithoutFillerCharacters,
        resizable: true,
        flex: 1,
        minWidth: 100
      },
      {
        headerName: "Superficie à auditer",
        headerTooltip: "Superficie à auditer",
        field: "areaToInspect",
        filter: true,
        sortable: true,
        comparator: TableUtility.comparatorWithoutFillerCharacters,
        resizable: true,
        flex: 1,
        minWidth: 100
      },
      {
        headerName: "IVP",
        headerTooltip: "IVP",
        field: "ivp",
        filter: true,
        sortable: true,
        comparator: TableUtility.comparatorWithoutFillerCharacters,
        resizable: true,
        flex: 1,
        minWidth: 100
      },
      {
        headerName: "Cote d'état",
        headerTooltip: "Cote d'état",
        field: "calculatedConditionGrading",
        filter: true,
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 100
      },
      {
        headerName: "Ancien IVP",
        headerTooltip: "Ancien IVP",
        field: "pastIVP",
        filter: true,
        sortable: true,
        comparator: TableUtility.comparatorWithoutFillerCharacters,
        resizable: true,
        flex: 1,
        minWidth: 100
      },
      {
        headerName: "Ancien IE",
        headerTooltip: "Ancien IE",
        field: "pastRI",
        filter: true,
        sortable: true,
        comparator: TableUtility.comparatorWithoutFillerCharacters,
        resizable: true,
        flex: 1,
        minWidth: 100
      },
      {
        headerName: "Valeur des exigences",
        headerTooltip: "Valeur des exigences",
        field: "sumRequirementsPrices",
        filter: true,
        sortable: true,
        comparator: TableUtility.comparatorWithoutFillerCharacters,
        resizable: true,
        flex: 1,
        minWidth: 100
      },
      {
        headerName: "Avancement architecture",
        headerTooltip: "Avancement achitecture",
        field: "architecturalProgress",
        filter: true,
        sortable: true,
        comparator: TableUtility.comparatorWithoutFillerCharacters,
        resizable: true,
        flex: 1,
        minWidth: 100
      },
      {
        headerName: "Avancement mécanique",
        headerTooltip: "Avancement mécanique",
        field: "mechanicalProgress",
        filter: true,
        sortable: true,
        comparator: TableUtility.comparatorWithoutFillerCharacters,
        resizable: true,
        flex: 1,
        minWidth: 100
      },
      {
        // This column is hidden
        headerName: "Audits terminés",
        field: "auditDone",
        filter: true,
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 100,
        hide: true
      }
    ],
    [getAttributeLabel, displayDrawer]
  );

  const rowData = useMemo(
    () =>
      assets.map((asset: Asset) => ({
        projectId,
        id: asset.id,
        siteId: asset.site_id,
        siteName: asset.siteName,
        name: asset.name,
        number: asset.asset_number,
        area: formatArea(asset.area),
        areaToInspect: formatArea(asset.areaToInspect),
        ivp: formatNumber(asset.ivp, 3),
        calculatedConditionGrading: asset.calculatedConditionGrading,
        pastIVP: formatNumber(asset.pastIVP, 3),
        pastRI: formatNumber(asset.pastRI, 3),
        sumRequirementsPrices: moneyFormatter.format(asset.sum_requirements_prices as number),
        architecturalProgress: asset.architectural_progress ? `${asset.architectural_progress} %` : undefined,
        mechanicalProgress: asset.architectural_progress ? `${asset.mechanical_progress} %` : undefined,
        auditDone: asset.is_audit_done ? "1" : "0"
      })),
    [assets, projectId]
  );

  const deleteAllActiveFilters = (): void => {
    dispatch(resetAgGridCustomFilterStateAction(currentTable));
    setActiveFilterColumn([]);
    (gridRef.current.api as GridApi).setFilterModel(null);
    (gridRef.current.api as GridApi).destroyFilter(SITE_NAME);
    (gridRef.current.api as GridApi).destroyFilter(NUMBER);
    (gridRef.current.api as GridApi).destroyFilter(NAME);
  };

  const deleteActiveFilter = (filterDeleted: string): void => {
    dispatch(deleteAgGridCustomFilterStateAction(currentTable, filterDeleted));
    activeFilterColumn.splice(activeFilterColumn.indexOf(filterDeleted), 1);
    setActiveFilterColumn(activeFilterColumn);
    (gridRef.current.api as GridApi).destroyFilter(filterDeleted);
  };

  return (
    <>
      <div style={{ paddingBottom: "15px" }}>
        <AgGridFilterContainer filters={activeFilterColumn} onDelete={deleteActiveFilter} onReset={deleteAllActiveFilters} />
      </div>
      <div className="ag-theme-alpine" style={{ height: "100%" }}>
        <AgGridReact
          onGridReady={restoreGridState}
          onSortChanged={saveGridState}
          onFilterChanged={saveGridState}
          onColumnResized={saveGridState}
          onColumnMoved={saveGridState}
          rowData={rowData}
          columnDefs={columnDefs}
          animateRows
          pagination
          suppressRowClickSelection
          suppressDragLeaveHidesColumns
          ref={gridRef}
          components={{ ClickableCell, EditButtonCell }}
        />
      </div>
    </>
  );
};

export default AssetTable;
