/* eslint-disable */
import CircularProgress from "@mui/material/CircularProgress";
import React, { FC, useState } from "react";
import styled from "styled-components";

import LazyTreeView from "./lazyTreeView";

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 45%;
`;

const TreeViewStyle: FC<{ checked: string[]; setChecked: Function; getData: Function; defaultData: Function; isSingleCheck?: boolean }> = (props) => {
  const [areDataLoading, setAreDataLoading] = useState(false);

  return (
    <div style={{ height: "525px", overflow: "auto" }}>
      <div style={{ display: areDataLoading ? "block" : "none", minHeight: "525px", position: "relative", width: "750px" }}>
        <StyledCircularProgress />
      </div>
      <div style={{ display: areDataLoading ? "none" : "block", width: "750px" }}>
        <LazyTreeView
          checked={props.checked}
          setChecked={props.setChecked}
          getData={props.getData}
          defaultData={props.defaultData}
          setAreDataLoading={setAreDataLoading}
          isSingleCheck={props.isSingleCheck}
        />
      </div>
    </div>
  );
};

export default TreeViewStyle;
