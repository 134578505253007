import { useCallback, useMemo, useState } from "react";

type ModalHook = {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

const useModal = (): ModalHook => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return useMemo(() => ({ isModalOpen, openModal, closeModal }), [isModalOpen, openModal, closeModal]);
};

export default useModal;
