/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, RefObject } from "react";
import { css } from "styled-components";
import { colors } from "ui/colors";

const formSectionNavigationLinkStyle = css`
  list-style-type: none;
  display: block;
  padding: 10px;
  background-color: ${colors.ACCENT_GREY};
  transition: background-color 0.2s ease;

  &:hover {
    cursor: pointer;
    background-color: ${colors.PRIMARY_WHITE};
  }
`;

interface FormSectionNavigationLinkProps extends ComponentProps {
  reference: RefObject<any>;
  title: string;
}

const FormSectionNavigationLink: FC<FormSectionNavigationLinkProps> = ({ reference, title, className }: FormSectionNavigationLinkProps) => {
  const handleClick = (): void => {
    window.scrollTo(0, reference.current.offsetTop - 40);
  };

  return (
    <li onClick={handleClick} className={className}>
      {title}
    </li>
  );
};

export default createStyledComponent(FormSectionNavigationLink, formSectionNavigationLinkStyle);
