import { useState } from "react";

type TabsHook = {
  activeTabIndex: number;
  onTabChange: (newTabIndex: number) => void;
};

const useTabs = (initialActiveTabIndex?: number): TabsHook => {
  const [activeTabIndex, setActiveTabIndex] = useState(initialActiveTabIndex || 0);

  const onTabChange = (newTabIndex: number): void => {
    setActiveTabIndex(newTabIndex);
  };

  return { activeTabIndex, onTabChange };
};

export default useTabs;
