/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import Notification from "app/domain/notification";
import api, { NEW_API_VERSION } from "app/service/common/api";

import NotificationMapper, { NotificationDTO } from "./notificationMapper";

export default class NotificationService {
  static getNotifications = async (countLimit: number): Promise<Notification[]> => {
    const notificationDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/notifications`,
        queryParams: { count_limit: countLimit.toString() }
      })
    ).data as NotificationDTO[];

    return NotificationMapper.fromDTO(notificationDTOs);
  };

  static getNotificationsCount = async (): Promise<number> => {
    return (
      await api.get({
        path: `${NEW_API_VERSION}/notifications/count`
      })
    ).data as number;
  };

  static readNotifications = async (commentIds: number[], replyIds: number[]): Promise<void> => {
    await api.post({
      path: `${NEW_API_VERSION}/notifications/read`,
      body: { comments_ids: commentIds, replies_ids: replyIds }
    });
  };
}
