/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { statusOptions } from "app/data/formOptions";
import { System } from "app/domain/system";
import { FormFieldGroup } from "app/view/common/form";
import RenewalInformation from "app/view/system/form/systemForm/formSections/renewalInformation";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { forwardRef, RefObject } from "react";
import { Form as SemanticForm } from "semantic-ui-react";

import { SystemFields } from "../useSystem";
import ConditionGradingSection from "./conditionGradingSection";

interface SystemLifeInformationProps extends ComponentProps {
  system: System | undefined;
  onUpdate: (fields: SystemFields) => void;
  onNavigationBlock: () => void;
  projectId: string;
  systemWithoutModel?: boolean;
}

const SystemLifeInformation = forwardRef((props: SystemLifeInformationProps, ref): JSX.Element => {
  const { system, onUpdate, projectId, onNavigationBlock, systemWithoutModel } = props;

  const updateConditionGradingInfo = (values: Record<string, unknown>): void => {
    Object.entries(values).forEach(([key, value]) => {
      onUpdate({ [key]: value });
    });
  };

  const handleStatusChange = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({ [data.name]: data.value });
    onNavigationBlock();
  };

  const hasEnabledAdditionalInformation = (project: string): boolean => {
    const authorizedProjects = ["PL-0002", "PL-0003", "PL-0004", "PL-01296", "PL-01364", "PL-01396", "PL-01431", "PL-01432", "PL-01445", "PL-01446", "PL-23137"];
    return authorizedProjects.includes(project);
  };

  return (
    <div>
      <h3 ref={ref as RefObject<any>}>
        <span>2.</span> Informations de vie du système
      </h3>
      <ConditionGradingSection system={system} onUpdate={updateConditionGradingInfo} systemWithoutModel={systemWithoutModel} />
      {hasEnabledAdditionalInformation(projectId) && (
        <FormFieldGroup>
          <SemanticForm.Dropdown selection search clearable label="Statut" onChange={handleStatusChange} name="status" value={system && system.status} options={statusOptions} />
          <RenewalInformation system={system} onUpdate={onUpdate} onNavigationBlock={onNavigationBlock} />
        </FormFieldGroup>
      )}
    </div>
  );
});

SystemLifeInformation.displayName = "SystemLifeInformation";

export default SystemLifeInformation;
