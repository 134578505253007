import { createAction } from "@reduxjs/toolkit";

import { LoginActionTypes } from "./types";

export const loginUserAction = createAction(LoginActionTypes.LOGIN_USER, (user: any) => ({
  payload: {
    user
  }
}));

export const loginUserRequestAction = createAction(LoginActionTypes.LOGIN_USER_REQUEST, (user: any) => ({
  payload: {
    user
  }
}));

export const loginUserSuccessAction = createAction(LoginActionTypes.LOGIN_USER_SUCCESS, (user: any) => ({
  payload: {
    user
  }
}));

export const loginUserErrorAction = createAction(LoginActionTypes.LOGIN_USER_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const logoutUserAction = createAction(LoginActionTypes.LOGOUT_USER);

export const logoutUserRequestAction = createAction(LoginActionTypes.LOGOUT_USER_REQUEST);

export const logoutUserSuccessAction = createAction(LoginActionTypes.LOGOUT_USER_SUCCESS);

export const logoutUserErrorAction = createAction(LoginActionTypes.LOGOUT_USER_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const changeUserAccountPasswordAction = createAction(LoginActionTypes.CHANGE_USER_ACCOUNT_PASSWORD, (password: string) => ({
  payload: {
    password
  }
}));

export const changeUserAccountPasswordRequestAction = createAction(LoginActionTypes.CHANGE_USER_ACCOUNT_PASSWORD_REQUEST, (password: string) => ({
  payload: {
    password
  }
}));

export const changeUserAccountPasswordSuccessAction = createAction(LoginActionTypes.CHANGE_USER_ACCOUNT_PASSWORD_SUCCESS, (user: any) => ({
  payload: {
    user
  }
}));

export const changeUserAccountPasswordErrorAction = createAction(LoginActionTypes.CHANGE_USER_ACCOUNT_PASSWORD_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));
