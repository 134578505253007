class SearchItem {
  text: string;
  location: string;

  constructor(text: string, location: string) {
    this.text = text;
    this.location = location;
  }
}

export default SearchItem;
