import { Local } from "app/domain/local";
import LocalService from "app/service/local/localService";
import { setShouldRedirectToSystemsPageAction } from "app/store/system/actions";
import { call, put, takeLatest } from "redux-saga/effects";

import { getLocalsErrorAction, getLocalsRequestAction, getLocalsSuccessAction, getRoomErrorAction, getRoomRequestAction, getRoomSuccessAction } from "./actions";
import { GetLocalsAction, GetLocalsRequestAction, GetRoomAction, GetRoomRequestAction, LocalActionTypes } from "./types";

export const GET_LOCALS_ERROR = "Une erreur s'est produite lors de la récupération des locaux. Veuillez réessayer.";
export const GET_ROOM_ERROR = "Une erreur s'est produite lors de la récupération du local. Veuillez réessayer.";

export function* getLocals(action: GetLocalsAction): Generator {
  yield put(getLocalsRequestAction(action.payload.assetId));
}

export function* getLocalsRequest(action: GetLocalsRequestAction): Generator {
  try {
    const locals = yield call(LocalService.getLocals, action.payload.assetId);
    yield put(getLocalsSuccessAction(locals as Local[]));
    yield put(setShouldRedirectToSystemsPageAction(false));
  } catch (error) {
    yield put(getLocalsErrorAction(GET_LOCALS_ERROR));
  }
}

export function* getRoom(action: GetRoomAction): Generator {
  yield put(getRoomRequestAction(action.payload.roomId));
}

export function* getRoomRequest(action: GetRoomRequestAction): Generator {
  try {
    const room = yield call(LocalService.getRoom, action.payload.roomId);
    yield put(getRoomSuccessAction(room as Local));
  } catch (error) {
    yield put(getRoomErrorAction(GET_ROOM_ERROR));
  }
}

function* localSaga(): Generator {
  yield takeLatest(LocalActionTypes.GET_LOCALS, getLocals);
  yield takeLatest(LocalActionTypes.GET_LOCALS_REQUEST, getLocalsRequest);
  yield takeLatest(LocalActionTypes.GET_ROOM, getRoom);
  yield takeLatest(LocalActionTypes.GET_ROOM_REQUEST, getRoomRequest);
}

export default localSaga;
