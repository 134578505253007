import SystemModelAction from "app/domain/system/systemModelAction";
import { useI18n } from "app/locales";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Radio } from "semantic-ui-react";
import { css } from "styled-components";

const actionOptionsSectionStyle = css`
  padding: 10px 0 0 0;

  .title {
    padding: 10px 0 10px 0;
    font-weight: bold;
  }
`;

interface ActionOptionsSectionProps extends ComponentProps {
  currentAction: SystemModelAction;
  actionOptions: SystemModelAction[];
  onUpdateAction: (action: SystemModelAction) => void;
}

const ActionOptionsSection: FC<ActionOptionsSectionProps> = ({ currentAction, actionOptions, onUpdateAction, className }: ActionOptionsSectionProps) => {
  const { getAttributeLabel } = useI18n();

  const handleActionChange = (event: any, data: any, action: SystemModelAction): void => {
    onUpdateAction(action);
  };

  return (
    <div className={className}>
      <div className="title">{getAttributeLabel("action_type")}</div>
      <div data-test-id="radioButtonsSection">
        {actionOptions.map((action) => (
          <Radio
            key={action.id}
            label={action.actionType}
            value={action.id}
            checked={action.id === currentAction.id}
            onClick={(e, data): void => handleActionChange(e, data, action)}
            data-test-id="radioButton"
          />
        ))}
      </div>
    </div>
  );
};

export default createStyledComponent(ActionOptionsSection, actionOptionsSectionStyle);
