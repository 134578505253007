import SearchItem from "app/domain/search/searchItem";
import api from "app/service/common/api";

import { NEW_API_VERSION } from "../common/api";
import SearchMapper, { SearchItemDTO } from "./searchMapper";

class SearchService {
  static search = async (searchItem: SearchItem): Promise<SearchItem[]> => {
    const searchItemsDTO = SearchMapper.toDTO(searchItem);
    const searchResultsDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/search`,
        body: searchItemsDTO
      })
    ).data as SearchItemDTO[];

    return SearchMapper.fromDTO(searchResultsDTO);
  };
}

export default SearchService;
