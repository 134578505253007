import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

export const footerStyle = css`
  display: flex;
  margin: 20px 0;

  > * {
    flex: 1;
  }
`;

export interface FooterProps extends ComponentProps {
  children: React.ReactNode;
}

const Footer: FC<FooterProps> = ({ children, className }: FooterProps) => (
  <div data-test-id="footer" className={className}>
    {children}
  </div>
);

export default createStyledComponent(Footer, footerStyle);
