/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { isEmpty } from "lodash";

import api, { Options } from "../api";
import { NEW_API_VERSION } from "../common/api";

class RsmeansAPI {
  getRsmeansCatalog = async ({ measurement, release, cost_data_format, location, labor_type }: any) => {
    const url = `${NEW_API_VERSION}/rsmeans/catalogs`;
    const params = new URLSearchParams();
    if (measurement) params.append("measurement", measurement);
    if (release) params.append("release", release);
    if (cost_data_format) params.append("cost_data_format", cost_data_format);
    if (location) params.append("location", location);
    if (labor_type) params.append("labor_type", labor_type);
    if (!isEmpty(params.toString())) url.concat(`?${params.toString()}`);

    const { data } = await api.get(url);
    return data;
  };

  getRsmeansMeasurements = async () => {
    const url = `${NEW_API_VERSION}/rsmeans/measurement`;
    const { data } = await api.get(url);
    return data;
  };

  getRsmeansLaborType = async () => {
    const url = `${NEW_API_VERSION}/rsmeans/labor_type`;
    const { data } = await api.get(url);
    return data;
  };

  getRsmeansLocation = async () => {
    const url = `${NEW_API_VERSION}/rsmeans/location`;
    const { data } = await api.get(url);
    return data;
  };

  getRsmeansRelease = async () => {
    const url = `${NEW_API_VERSION}/rsmeans/release`;
    const { data } = await api.get(url);
    return data;
  };

  getUnitDivision = async (catId: string) => {
    const url = `${NEW_API_VERSION}/rsmeans/unit_division?cat_id=${catId}`;
    const { data } = await api.get(url);
    return data;
  };

  getUnitDivisionChildren = async (catId: string, id: string) => {
    const url = `${NEW_API_VERSION}/rsmeans/unit_division_children?cat_id=${catId}&id=${id}`;
    const { data } = await api.get(url);
    return data;
  };

  searchUnitCostline = async ({ catId, searchTerm, divisionCode }: { catId: string; searchTerm?: string; divisionCode?: string }) => {
    let url = `${NEW_API_VERSION}/rsmeans/unit_costline_search`;
    const params = new URLSearchParams();

    if (catId) params.append("cat_id", catId);
    if (searchTerm) params.append("search_term", searchTerm);
    if (divisionCode) params.append("division_code", divisionCode);
    url = url.concat(`?${params.toString()}`);
    const { data } = await api.get(url);
    return data;
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  setCostline = async (_data: {}) => {
    return api.post(`${NEW_API_VERSION}/rsmeans/set_costline`, _data);
  };
}
export default new RsmeansAPI();
