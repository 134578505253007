import * as React from "react";
import Menu2 from "./MenuOptions";
import "../../App.css";
import Styled from "styled-components";
import { colors } from "ui/colors";
import env from "@beam-australia/react-env";

const ContentContainer = Styled.div`
    width: ${(props) => (props.pushed ? "calc(100% - 190px)" : "100%")};
    margin-left: ${(props) => (props.pushed ? "190px" : "0")};
    transition: margin-left 0.5s ease, width 0.5s ease;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const MenuContainer = Styled.div`
    position: fixed;
    width: 190px;
    left: ${(props) => (props.open ? "0" : "-190px")};
    height: 100%;
    background-color: ${colors.SECONDARY_BLACK};
    transition: left 0.5s ease;
    color: ${colors.PRIMARY_WHITE};
    z-index: 1000;
`;

class SideMenuTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuVisible: false,
    };
  }

  componentDidMount() {
    const el = document.querySelector(".side_menu");
    if (el) {
      this.setState({ top: el.offsetTop, height: el.offsetHeight });
    }
  }

  handleMenuhide = () => {
    this.props.onClose();
  };

  render() {
    const { menuIsOpen } = this.props;
    return (
      <div
        style={{
          position: "absolute",
          flex: "1",
          top:
            env("API_HOST").includes("staging") ||
            env("API_HOST").includes("local.unifika.ca")
              ? "75px"
              : "50px",
          left: "0",
          right: "0",
          bottom: "0",
        }}
      >
        <MenuContainer className="side_menu" open={menuIsOpen}>
          <Menu2 open={menuIsOpen} onClose={this.handleMenuhide} />
        </MenuContainer>

        <ContentContainer pushed={menuIsOpen}>
          {this.props.children}
        </ContentContainer>
      </div>
    );
  }
}
export default SideMenuTemplate;
