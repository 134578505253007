import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeUserAccountPasswordAction } from "app/store/login/actions";
import AccountFormComponent from "./accountFormComponent";
import { getUserEmail } from "app/store/login/selectors";

class UpdateAccountContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      isLoggedIn: localStorage.getItem("token"),
    };
  }

  changePassword = (password) => {
    this.props.actions.changeUserAccountPasswordAction(password);
  };

  render() {
    const { email, passwordIsChanged } = this.props;

    return (
      <AccountFormComponent
        changePassword={this.changePassword}
        email={email}
        passwordIsChanged={passwordIsChanged}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    email: getUserEmail(state),
    passwordIsChanged: state.login.passwordWasChanged,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ changeUserAccountPasswordAction }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateAccountContainer);
