import { System } from "app/domain/system";
import { useI18n } from "app/locales";
import { getOptionsLists } from "app/store/system/selectors";
import { FormFieldGroup } from "app/view/common/form";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { forwardRef, RefObject } from "react";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { Form as SemanticForm } from "semantic-ui-react";

import { SystemFields } from "../useSystem";

interface AuditInformationProps extends ComponentProps {
  system: System | undefined;
  onUpdate: (fields: SystemFields) => void;
  onNavigationBlock: () => void;
}

const AuditInformation = forwardRef(({ system, onUpdate, onNavigationBlock }: AuditInformationProps, ref): JSX.Element => {
  const { getAttributeLabel } = useI18n();

  const listsChoices = useSelector(getOptionsLists);

  const handleChange = (event: any): void => {
    event.preventDefault();
    onUpdate({ [event.target.name]: event.target.value });
    onNavigationBlock();
  };

  const handleSelectChange = (event: any, data: any): void => {
    event.preventDefault();
    onUpdate({ [data.name]: data.value });
    onNavigationBlock();
  };

  const convertDate = (date: string | undefined | null): Date | null => {
    if (date !== undefined && date !== null && date !== "") {
      const parts = date.split("-");
      return new Date(parts[0] as unknown as number, (parts[1] as unknown as number) - 1, parts[2] as unknown as number);
    }
    return null;
  };

  const minTwoDigits = (n: number): string => (n < 10 ? "0" : "") + n;

  const handleDateChange = (name: string, date: any): void => {
    if (date !== null) {
      const dateString = `${(date as Date).getUTCFullYear().toString()}-${minTwoDigits((date as Date).getUTCMonth() + 1).toString()}-${minTwoDigits(
        (date as Date).getUTCDate().toString() as unknown as number
      )}`;

      onUpdate({ [name]: dateString });
      onNavigationBlock();
    }
  };

  return (
    <div>
      <h3 ref={ref as RefObject<any>}>
        <span>3.</span> Informations sur l&apos;audit
      </h3>
      <FormFieldGroup row>
        <SemanticForm.Dropdown
          required
          label={getAttributeLabel("auditor")}
          clearable
          placeholder={getAttributeLabel("auditor")}
          name="auditor"
          value={system && system.auditor}
          onChange={handleSelectChange}
          selection
          options={listsChoices ? listsChoices.list_auditor_options : []}
        />
        <SemanticForm.Input
          disabled
          readOnly
          key="updated_by"
          label="Modifié par"
          placeholder="Modifié par"
          name="updated_by"
          value={system && system.updated_by ? system.updated_by : ""}
          onChange={handleChange}
        />
        <SemanticForm.Field>
          <div className="date-field">
            <div className="required">{getAttributeLabel("audit_date")}</div>
            <ReactDatePicker
              name="auditDate"
              onChange={(date): void => handleDateChange("auditDate", date)}
              selected={convertDate(system && system.auditDate)}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </SemanticForm.Field>
        <SemanticForm.Input
          key="created_hour"
          label="Date et heure de saisie"
          placeholder="Heure de saisie"
          name="created_hour"
          value={system && system.created_hour ? new Date(system.created_hour).toLocaleString() : ""}
          readOnly
          disabled
        />
      </FormFieldGroup>
    </div>
  );
});

AuditInformation.displayName = "AuditInformation";

export default AuditInformation;
