import { Dictionaries, DictionaryCode } from "app/locales";
import { capitalize } from "app/utils/formatter";

import Error from "./error";

interface RequiredParamsErrorProps {
  params: string[];
}

class RequiredParamsError implements Error {
  params: string[];

  constructor(requiredParamsProps: RequiredParamsErrorProps) {
    this.params = requiredParamsProps.params;
  }

  format(dictionaryCode: DictionaryCode): string {
    return capitalize(Dictionaries[dictionaryCode].errors.requiredParamsError(this.params)) as string;
  }
}

export default RequiredParamsError;
