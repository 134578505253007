import { Params, useRouter } from "app/routing/routerProvider";
import { getCurrentAsset } from "app/store/asset/selectors";
import { getCurrentProject } from "app/store/project/selectors";
import { getCurrentRequirement } from "app/store/requirement/selectors";
import { getCurrentSite } from "app/store/site/selectors";
import { getCurrentSystem } from "app/store/system/selectors";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { css } from "styled-components";
import { colors } from "ui/colors";

import { BreadcrumbLink, Breadcrumbs } from "./breadcrumbs";

const pageHeaderStyle = css`
  .navigation {
    display: flex;
  }

  .pageTitle {
    font-size: 2em;
    margin-top: 14px;

    span {
      color: ${colors.ACCENT_BLUE};
      font-weight: bold;
    }
  }
`;

interface PageHeaderProps extends ComponentProps {
  showBreadcrumb: boolean;
  title: JSX.Element;
}

const PageHeader: FC<PageHeaderProps> = ({ showBreadcrumb, title, className }: PageHeaderProps) => {
  const { getParam } = useRouter();

  const projectId = getParam(Params.PROJECT);
  const siteId = getParam(Params.SITE);
  const assetId = getParam(Params.ASSET);
  const systemId = getParam(Params.SYSTEM);
  const requirementId = getParam(Params.REQUIREMENT);

  const currentProject = useSelector(getCurrentProject);
  const currentSite = useSelector(getCurrentSite);
  const currentAsset = useSelector(getCurrentAsset);
  const currentSystem = useSelector(getCurrentSystem);
  const currentRequirement = useSelector(getCurrentRequirement);

  return (
    <div className={className}>
      <div className="navigation">
        {showBreadcrumb && (
          <Breadcrumbs>
            {projectId && currentProject && <BreadcrumbLink label={currentProject.id} url={`/projects/${projectId}`} />}
            {projectId && siteId && currentSite && <BreadcrumbLink label={`${currentSite.id} - ${currentSite.name}`} url={`/projects/${projectId}/sites/${siteId}`} />}
            {projectId && siteId && assetId && currentAsset && (
              <BreadcrumbLink label={`${currentAsset.asset_number} - ${currentAsset.name}`} url={`/projects/${projectId}/sites/${siteId}/assets/${assetId}`} />
            )}
            {projectId && siteId && assetId && systemId && currentSystem && (
              <BreadcrumbLink label={currentSystem.reference as string} url={`/projects/${projectId}/sites/${siteId}/assets/${assetId}/components/${systemId}`} />
            )}
            {projectId && siteId && assetId && requirementId && currentRequirement && (
              <BreadcrumbLink label={currentRequirement.reference as string} url={`/projects/${projectId}/sites/${siteId}/assets/${assetId}/requirements/${requirementId}`} />
            )}
          </Breadcrumbs>
        )}
      </div>
      <h2 className="pageTitle">{title}</h2>
    </div>
  );
};

export default createStyledComponent(PageHeader, pageHeaderStyle);
