import LoginAPI from "app/service/LoginAPI";
import { getCostItemsAction } from "app/store/costItem/actions";
import jwtDecode from "jwt-decode";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  changeUserAccountPasswordErrorAction,
  changeUserAccountPasswordRequestAction,
  changeUserAccountPasswordSuccessAction,
  loginUserErrorAction,
  loginUserRequestAction,
  loginUserSuccessAction,
  logoutUserRequestAction,
  logoutUserSuccessAction
} from "./actions";
import { getUserEmail } from "./selectors";
import { ChangeUserAccountPasswordAction, ChangeUserAccountPasswordRequestAction, LoginActionTypes, LoginUserAction, LoginUserRequestAction } from "./types";

export const LOGIN_USER_ERROR = "Une erreur s'est produite lors de la connexion. Veuillez réessayer.";
export const LOGOUT_USER_ERROR = "Une erreur s'est produite lors de la déconnexion. Veuillez réessayer.";
export const CHANGE_USER_ACCOUNT_PASSWORD_ERROR = "Une erreur s'est produite lors du changement de mot de passe. Veuillez réessayer.";

export function* loginUser(action: LoginUserAction): Generator {
  yield put(loginUserRequestAction(action.payload.user));
}

export function* loginUserRequest(action: LoginUserRequestAction): Generator {
  try {
    const connectedUser = yield call(LoginAPI.login, action.payload.user);
    localStorage.setItem("token", (connectedUser as any).token);
    localStorage.setItem("token-exp", (connectedUser as any).expires);

    yield put(getCostItemsAction());
    yield put(loginUserSuccessAction(jwtDecode((connectedUser as any).token)));
  } catch (error) {
    yield put(loginUserErrorAction(LOGIN_USER_ERROR));
  }
}

export function* logoutUser(): Generator {
  yield put(logoutUserRequestAction());
}

export function* logoutUserRequest(): Generator {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("token-exp");
    yield put(logoutUserSuccessAction());
  } catch (error) {
    yield put(loginUserErrorAction(LOGOUT_USER_ERROR));
  }
}

export function* changeUserAccountPassword(action: ChangeUserAccountPasswordAction): Generator {
  yield put(changeUserAccountPasswordRequestAction(action.payload.password));
}

export function* changeUserAccountPasswordRequest(action: ChangeUserAccountPasswordRequestAction): Generator {
  try {
    const userEmail = yield select(getUserEmail);
    const updatedUser = yield call(LoginAPI.changePassword, userEmail, action.payload.password);
    yield put(changeUserAccountPasswordSuccessAction(updatedUser));
  } catch (error) {
    yield put(changeUserAccountPasswordErrorAction(CHANGE_USER_ACCOUNT_PASSWORD_ERROR));
  }
}

function* loginSaga(): Generator {
  yield takeLatest(LoginActionTypes.LOGIN_USER, loginUser);
  yield takeLatest(LoginActionTypes.LOGIN_USER_REQUEST, loginUserRequest);
  yield takeLatest(LoginActionTypes.LOGOUT_USER, logoutUser);
  yield takeLatest(LoginActionTypes.LOGOUT_USER_REQUEST, logoutUserRequest);
  yield takeLatest(LoginActionTypes.CHANGE_USER_ACCOUNT_PASSWORD, changeUserAccountPassword);
  yield takeLatest(LoginActionTypes.CHANGE_USER_ACCOUNT_PASSWORD_REQUEST, changeUserAccountPasswordRequest);
}

export default loginSaga;
