import { Link as MaterialLink } from "@mui/material";
import { useRouter } from "app/routing/routerProvider";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import styled from "styled-components";

interface BreadcrumbLinkProps extends ComponentProps {
  label: string;
  url: string;
}

const StyledLink = styled(MaterialLink)`
  &.MuiTypography-root {
    text-decoration: none;
  }
`;

const BreadcrumbLink: FC<BreadcrumbLinkProps> = ({ label, url }: BreadcrumbLinkProps) => {
  const { goTo } = useRouter();

  return (
    <StyledLink color="inherit" onClick={(): void => goTo(url)} key={label}>
      {label}
    </StyledLink>
  );
};

export default BreadcrumbLink;
