import { Requirement } from "app/domain/requirement";
import { RequirementsOptions } from "app/domain/requirement/options";

export type RequirementState = {
  isLoading: boolean;
  isFetchingOptions: boolean;
  errorMessage: string;
  requirements: Requirement[];
  filteredRequirements: Requirement[];
  currentRequirement: Requirement | undefined;
  shouldRedirectToRequirementsPage: boolean;
  options: RequirementsOptions;
};

export const initialRequirementState: RequirementState = {
  isLoading: false,
  isFetchingOptions: false,
  errorMessage: "",
  requirements: [],
  filteredRequirements: [],
  currentRequirement: undefined,
  shouldRedirectToRequirementsPage: false,
  options: {
    activityOptions: [],
    categoryOptions: [],
    complexityOptions: [],
    heightOptions: [],
    localisationOptions: [],
    patrimonyFactorOptions: [],
    priorityOptions: [],
    problematicOptions: [],
    removalOptions: [],
    requirementItemOptions: [],
    auditorOptions: []
  }
};
