import axios, { AxiosRequestConfig } from "axios";

import apiSettings from "./apiSettings";

axios.defaults.baseURL = apiSettings.host;
axios.defaults.headers.common = apiSettings.headers;

export const NEW_API_VERSION = "/v2";

type Options = {
  baseURL?: string;
  headers?: Record<string, unknown>;
};

export type QueryParams = {
  [key: string]: string;
};

export type GetRequest = {
  path: string;
  queryParams?: QueryParams;
  responseType?: "arraybuffer" | "blob" | "document" | "json" | "text" | "stream" | undefined;
};

type PostRequest<T> = {
  path: string;
  queryParams?: QueryParams;
  body?: T;
  options?: Options;
  file?: File;
  responseType?: "arraybuffer" | "blob" | "document" | "json" | "text" | "stream" | undefined;
};

type PatchRequest<T> = {
  path: string;
  id: string;
  body?: T;
};

type PutRequest<T> = {
  path: string;
  id: any;
  body: T;
};

type DeleteRequest = {
  path: string;
  id: string;
};

export interface Response {
  data: any;
  headers?: any;
}

class Api {
  static get = async (request: GetRequest): Promise<Response> =>
    axios.get(request.path, {
      headers: {
        Authorization: localStorage.getItem("token") as string,
        "Content-Type": "application/json"
      },
      params: request.queryParams,
      responseType: request.responseType
    });

  static post = async <RequestType>(request: PostRequest<RequestType>): Promise<Response> => {
    const requestOptions = request.options
      ? request.options
      : {
          headers: {
            Authorization: localStorage.getItem("token"),
            contentType: request.file ? "multipart/form-data" : "application/json"
          },
          params: request.queryParams,
          responseType: request.responseType
        };
    if (request.file) {
      const formData = new FormData();
      formData.append("file", request.file);
      return axios.post(request.path, formData, requestOptions as AxiosRequestConfig);
    }

    return axios.post(request.path, request.body, requestOptions as AxiosRequestConfig);
  };

  static put = async <RequestType>(request: PutRequest<RequestType>): Promise<Response> =>
    axios.put(`${request.path}/${request.id}`, request.body, {
      headers: {
        Authorization: localStorage.getItem("token") as string,
        "Content-Type": "application/json"
      }
    });

  static patch = async <RequestType>(request: PatchRequest<RequestType>): Promise<Response> =>
    axios.patch(`${request.path}/${request.id}`, request.body, {
      headers: {
        Authorization: localStorage.getItem("token") as string,
        "Content-Type": "application/json"
      }
    });

  static delete = async (request: DeleteRequest): Promise<Response> =>
    axios.delete(`${request.path}/${request.id}`, {
      headers: {
        Authorization: localStorage.getItem("token") as string,
        "Content-Type": "application/json"
      }
    });
}

export default Api;
