import { UniformatOption } from "app/domain/uniformat/options";

import api, { NEW_API_VERSION } from "../common/api";
import UniformatOptionMapper, { UniformatOptionDTO } from "./uniformatOptionMapper";

class UniformatService {
  static getUniformatOptions = async (projectId: string, uniformatParent?: string): Promise<UniformatOption[]> => {
    const queryParams = { project_id: projectId };
    if (uniformatParent) {
      Object.assign(queryParams, { uniformat: uniformatParent });
    }
    const uniformatOptionDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/uniformats`,
        queryParams
      })
    ).data as UniformatOptionDTO[];
    return UniformatOptionMapper.fromDTO(uniformatOptionDTOs);
  };
}

export default UniformatService;
