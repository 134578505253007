import { Asset, AssetMinimalInfo } from "app/domain/asset";

export type AssetState = {
  isFetching: boolean;
  isFetchingOptions: boolean;
  isUpdating: boolean;
  errorMessage: string;
  assets: Asset[];
  assetsMinimalInfo: AssetMinimalInfo[];
  currentAsset: Asset | undefined;
  optionsLists: any;
};

export const initialAssetState: AssetState = {
  isFetching: false,
  isFetchingOptions: false,
  isUpdating: false,
  errorMessage: "",
  assets: [],
  assetsMinimalInfo: [],
  currentAsset: undefined,
  optionsLists: {}
};
