import { System } from "app/domain/system";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Checkbox, CheckboxGroup } from "ui/form/controls/checkbox";

import { SystemFields } from "./useSystem";

interface SystemFormStateProps extends ComponentProps {
  system: System | undefined;
  onUpdate: (fields: SystemFields) => void;
  onNavigationBlock: () => void;
}

const SystemFormState: FC<SystemFormStateProps> = ({ system, onUpdate, onNavigationBlock }: SystemFormStateProps) => {
  const handleCheckChange = (event: any): void => {
    onUpdate({ [event.currentTarget.name]: event.currentTarget.checked });
    onNavigationBlock();
  };

  return (
    <CheckboxGroup>
      <Checkbox label="À vérifier" name="toVerify" isChecked={(system && system.toVerify) || false} onClick={handleCheckChange} />
      <Checkbox label="Contrôle qualité fait" name="isVerified" isChecked={(system && system.isVerified) || false} onClick={handleCheckChange} />
      <Checkbox label="Code QR généré" name="has_qr_code_been_generated" isChecked={(system && system.has_qr_code_been_generated) || false} onClick={handleCheckChange} />
    </CheckboxGroup>
  );
};

export default SystemFormState;
