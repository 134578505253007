import { Form, Gender } from "./types";

class GenderedWord {
  word: string;
  gender: Gender;
  form: Form;

  constructor(word: string, gender: Gender, form: Form) {
    this.word = word;
    this.gender = gender;
    this.form = form;
  }
}

export default GenderedWord;
