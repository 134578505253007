import { FilterType } from "./filterType";

interface FilterProps {
  field: string;
  label: string;
  type: FilterType;
  values: string[];
}

class Filter {
  field: string;
  label: string;
  type: FilterType;
  values: string[];

  constructor(filterProps: FilterProps) {
    this.field = filterProps.field;
    this.label = filterProps.label;
    this.type = filterProps.type;
    this.values = filterProps.values;
  }
}

export default Filter;
