/* eslint-disable react-hooks/exhaustive-deps  */

import Manufacturer from "app/domain/manufacturer/manufacturer";
import { System } from "app/domain/system/index";
import ManufacturerService from "app/service/manufacturer/manufacturerService";
import { SystemFields } from "app/view/system/form/systemForm/useSystem";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useEffect, useState } from "react";
import { useAsync } from "react-async";
import { Form as SemanticForm } from "semantic-ui-react";

interface ManufacturerDropdownProps extends ComponentProps {
  system: System | undefined;
  onUpdate: (fields: SystemFields) => void;
}

const ManufacturerDropdown: FC<ManufacturerDropdownProps> = ({ system, onUpdate }: ManufacturerDropdownProps) => {
  const [manufacturers, setManufacturers] = useState([] as Manufacturer[]);
  const handleManufacturerChange = (event: any, data: any): void => {
    event.preventDefault();
    onUpdate({ manufacturerId: data.value });
  };

  const createManufacturer = ([manufacturer]: string[]): Promise<Manufacturer> => {
    return ManufacturerService.createManufacturer(new Manufacturer({ text: manufacturer, isValidated: false }));
  };

  const getManufacturers = (): Promise<Manufacturer[]> => {
    return ManufacturerService.getManufacturers();
  };

  const handleGetSuccess = (fetchedManufacturers: Manufacturer[]): void => {
    setManufacturers(fetchedManufacturers);
  };

  const { run: runFetch } = useAsync({
    deferFn: getManufacturers,
    onResolve: handleGetSuccess
  });

  const handleCreateSuccess = (manufacturer: Manufacturer): void => {
    onUpdate({ manufacturerId: manufacturer.id });
    runFetch();
  };

  const { run: runCreate } = useAsync({
    deferFn: createManufacturer,
    onResolve: handleCreateSuccess
  });

  const addManufacturer = (event: any, data: any): void => {
    runCreate(data.value);
  };

  useEffect(() => {
    runFetch();
  }, []);

  return (
    <SemanticForm.Dropdown
      search
      label="Marque"
      clearable
      name="manufacturerId"
      value={system && system.manufacturerId}
      onChange={handleManufacturerChange}
      selection
      allowAdditions
      onAddItem={addManufacturer}
      options={manufacturers.map((manufacturer: Manufacturer) => ({
        value: manufacturer.id,
        text: manufacturer.text
      }))}
      data-test-id="manufacturerDropdown"
    />
  );
};

export default ManufacturerDropdown;
