import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

import { colors } from "../colors";
import { Icon, IconType } from "../icon";

const defaultColor = colors.ACCENT_BLUE_DARK;

const emptyStateStyle = css`
  height: fit-content;
  width: fit-content;
  margin: auto;
  text-align: center;
  padding: 20px;

  .image {
    margin: auto;
    width: 100px;
    height: 100px;

    > * {
      margin: auto;
      width: inherit;
      height: inherit;

      i {
        font-size: 8em;
      }
    }
  }

  .title {
    margin: 20px 0;
    font-size: 1.3em;
    color: ${defaultColor};
  }
`;

interface EmptyStateProps extends ComponentProps {
  iconType: IconType;
  title: string;
  description: string;
}

const EmptyState: FC<EmptyStateProps> = ({ iconType, title, description, className }: EmptyStateProps) => (
  <div className={className}>
    <div className="image">
      <Icon color={defaultColor} type={iconType} />
    </div>
    <div className="title">{title}</div>
    <div className="description">{description}</div>
  </div>
);

export default createStyledComponent(EmptyState, emptyStateStyle);
