/* eslint-disable @typescript-eslint/no-namespace, import/export */

import { Dictionaries, DictionaryCode } from "app/locales";

export enum State {
  IN_PROGRESS = "inProgress",
  COMPLETED = "completed",
  VERIFIED = "qaDone"
}

export namespace State {
  export const values = [State.IN_PROGRESS, State.COMPLETED, State.VERIFIED] as State[];

  export const format = (dictionaryCode: DictionaryCode, state: State): string => {
    return Dictionaries[dictionaryCode].state[state];
  };

  export const toString = (state: State): string => {
    if (state === State.IN_PROGRESS) {
      return "in_progress";
    }
    if (state === State.COMPLETED) {
      return "completed";
    }
    if (state === State.VERIFIED) {
      return "qaDone";
    }
    return "";
  };

  export const fromString = (state: string): State => {
    if (state === "in_progress") {
      return State.IN_PROGRESS;
    }
    if (state === "completed") {
      return State.COMPLETED;
    }
    if (state === "qa_done") {
      return State.VERIFIED;
    }

    throw new RangeError(
      `Illegal argument passed to fromString(): ${state} does not 
      correspond to any instance of the enum State`
    );
  };
}
