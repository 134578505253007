import Photo, { PhotosFolder } from "app/domain/photo";

export enum EntityPhotosStatus {
  FETCHING = "FETCHING",
  UPLOADING = "UPLOADING",
  DELETING = "DELETING"
}

export enum PhotosFolderPollingStatus {
  STARTED = "STARTED",
  STOPPED = "STOPPED"
}

export interface Photos {
  [entityId: string]: {
    photos: Photo[];
    status: EntityPhotosStatus | undefined;
  };
}

export interface PhotosFolders {
  [entityId: string]: {
    photosFolder: PhotosFolder;
    pollingStatus: PhotosFolderPollingStatus;
  };
}

export type PhotoState = {
  photos: Photos;
  photosFolders: PhotosFolders;
  errorMessage: string;
};

export const initialPhotoState: PhotoState = {
  photos: {},
  photosFolders: {},
  errorMessage: ""
};
