/* eslint-disable no-param-reassign  */

import { Comment } from "app/domain/comment";
import CommentService from "app/service/comment/commentService";
import { notify } from "app/view/common/toast/Toast";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  addCommentErrorAction,
  addCommentRequestAction,
  addCommentSuccessAction,
  getCommentsErrorAction,
  getCommentsRequestAction,
  getCommentsSuccessAction,
  updateCommentErrorAction,
  updateCommentRequestAction,
  updateCommentSuccessAction
} from "./commentActions";
import {
  AddCommentAction,
  AddCommentRequestAction,
  CommentActionTypes,
  GetCommentsAction,
  GetCommentsRequestAction,
  UpdateCommentAction,
  UpdateCommentRequestAction
} from "./commentTypes";

export const GET_COMMENTS_ERROR = "Une erreur s'est produite lors de la récupération des commentaires. Veuillez réessayer.";

export const ADD_COMMENT_ERROR = "Une erreur s'est produite lors de la création du commentaire. Veuillez réessayer.";
export const UPDATE_COMMENT_ERROR = "Une erreur s'est produite lors de la mise à jour du commentaire. Veuillez réessayer.";

export function* getComments(action: GetCommentsAction): Generator {
  yield put(getCommentsRequestAction(action.payload.parentType, action.payload.parentId));
}

export function* getCommentsRequest(action: GetCommentsRequestAction): Generator {
  try {
    const comments = yield call(CommentService.getComments, action.payload.parentType, action.payload.parentId);
    yield put(getCommentsSuccessAction(comments as Comment[]));
  } catch (error) {
    yield put(getCommentsErrorAction(GET_COMMENTS_ERROR));
  }
}

export function* addComment(action: AddCommentAction): Generator {
  yield put(addCommentRequestAction(action.payload.comment));
}

export function* addCommentRequest(action: AddCommentRequestAction): Generator {
  try {
    const newComment = yield call(CommentService.createComment, action.payload.comment);

    yield put(addCommentSuccessAction(newComment as Comment));
    notify("Le système a été créé avec succès");
  } catch (error) {
    yield put(addCommentErrorAction(ADD_COMMENT_ERROR));
  }
}

export function* updateComment(action: UpdateCommentAction): Generator {
  yield put(updateCommentRequestAction(action.payload.comment));
}

export function* updateCommentRequest(action: UpdateCommentRequestAction): Generator {
  try {
    const updatedComment = yield call(CommentService.updateComment, action.payload.comment);

    yield put(updateCommentSuccessAction(updatedComment as Comment));
  } catch (error) {
    yield put(updateCommentErrorAction(UPDATE_COMMENT_ERROR));
  }
}

function* commentSaga(): Generator {
  yield takeLatest(CommentActionTypes.GET_COMMENTS, getComments);
  yield takeLatest(CommentActionTypes.GET_COMMENTS_REQUEST, getCommentsRequest);
  yield takeLatest(CommentActionTypes.ADD_COMMENT, addComment);
  yield takeLatest(CommentActionTypes.ADD_COMMENT_REQUEST, addCommentRequest);
  yield takeLatest(CommentActionTypes.UPDATE_COMMENT, updateComment);
  yield takeLatest(CommentActionTypes.UPDATE_COMMENT_REQUEST, updateCommentRequest);
}

export default commentSaga;
