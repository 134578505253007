/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useMemo, useState } from "react";

type DebounceHook = {
  debouncedValue: string;
};

const useDebounce = (value: string): DebounceHook => {
  const [currentDebouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return useMemo(
    () => ({
      debouncedValue: currentDebouncedValue
    }),
    [currentDebouncedValue]
  );
};

export default useDebounce;
