import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import compare from "react-fast-compare";
import { css } from "styled-components";
import { TableHeaderColumn } from "ui/table/types";

const tableFooterRowCellStyle = css`
  &&&&& {
    position: relative;
    background: #f9fafb;
    font-weight: 700;
    border-top: 1px solid rgba(34, 36, 38, 0.1);
    border-bottom: none;
    border-left: none;
    text-align: ${(props: StyleProps): string => (props.column.style && props.column.style.alignment) || "left"};
    white-space: nowrap;
  }
`;

interface TableFooterCellProps extends ComponentProps {
  column: TableHeaderColumn;
}

const isEqual = (prevProps: TableFooterCellProps, nextProps: TableFooterCellProps): boolean => compare((prevProps.column as any).Footer, (nextProps.column as any).Footer);

const TableFooterRowCell: FC<TableFooterCellProps> = ({ column, className }: TableFooterCellProps) => (
  <div {...column.getFooterProps()} className={`${className} cell`}>
    {column.render("Footer")}
  </div>
);

export default createStyledComponent(memo(TableFooterRowCell, isEqual), tableFooterRowCellStyle);
