/* eslint-disable no-nested-ternary, react-hooks/exhaustive-deps, no-unneeded-ternary  */
/* stylelint-disable value-keyword-case */

import env from "@beam-australia/react-env";
import { AuditType } from "app/domain/audit/auditType";
import { Error } from "app/domain/common";
import { CostLine } from "app/domain/costItem";
import { EntityCategory } from "app/domain/entityCategory";
import { System } from "app/domain/system";
import { UniformatLevel1 } from "app/domain/uniformat";
import { useI18n } from "app/locales";
import { useRouter } from "app/routing/routerProvider";
import SystemFieldValueFetcher from "app/service/comment/field/systemFieldValueFetcher";
import { getAssetsMinimalInfo } from "app/store/asset/selectors";
import { generateSystemLabelsAction } from "app/store/system/actions";
import { getFilteredSystems } from "app/store/system/selectors";
import { capitalize } from "app/utils/formatter";
import CommentsListing from "app/view/comment/listing/commentsListing";
import { FormOverview, formStyle } from "app/view/common/form";
import usePrevious from "app/view/common/hook/usePrevious";
import DeleteModal from "app/view/common/modal/deleteModal";
import { Page } from "app/view/common/page";
import { Toolbar } from "app/view/common/toolbar";
import { INDEX_ARCHITECTURE_COMPONENTS_TAB, INDEX_MECHANIC_COMPONENTS_TAB, MODAL_TYPE_SYSTEM } from "app/view/constant";
import useCostLineTable from "app/view/costItem/costLinesTable/useCostLineTable";
import PhotoListing from "app/view/photo/listing/photoListing";
import { SystemCopyModal } from "app/view/system/form/systemCopy/systemCopyModal";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import { isEqual } from "lodash";
import React, { createRef, FC, memo, RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, Redirect } from "react-router";
import { Label, Sticky } from "semantic-ui-react";
import { css } from "styled-components";
import { Button } from "ui/button";
import { SaveButton } from "ui/button/actions";
import { ErrorMessage } from "ui/errorMessage";
import { IconType } from "ui/icon";
import useModal from "ui/modal/useModal";
import { Tab, Tabs } from "ui/tab";

import SystemChildren from "../SystemChildren";
import { AuditInformation, CostLinesInformation, SystemInformation, SystemLifeInformation } from "./formSections";
import SystemFormState from "./systemFormState";
import useSystem from "./useSystem";

const systemFormStyle = css`
  ${formStyle};
  width: 80%;
  margin-top: 14px;

  .systemError {
    margin: 1em;
  }

  .actionsBar {
    background: white;
  }
`;

interface SystemFormProps extends ComponentProps {
  onConfirm: (system: System, isClosing: boolean) => void;
  projectId: string;
  siteId: string;
  assetId: string;
  isElementInCreationMode: boolean;
  onReset: () => void;
  onUpdate: (errorMessage: string) => void;
  errorMessage: string;
  shouldRedirectToComponentsPage: boolean;
  shouldRedirectToModifySystem?: boolean;
  systemId?: string;
  onDelete?: (data: any) => void;
  system?: System;
  auditDate?: string;
  installationYear?: string;
  newSystemId?: string;
}

const SystemForm: FC<SystemFormProps> = ({
  system,
  onConfirm,
  projectId,
  siteId,
  assetId,
  isElementInCreationMode,
  onReset,
  onUpdate,
  errorMessage,
  shouldRedirectToComponentsPage,
  shouldRedirectToModifySystem,
  systemId,
  onDelete,
  auditDate,
  installationYear,
  newSystemId,
  className
}: SystemFormProps) => {
  const { dictionaryCode } = useI18n();
  const { goTo, getStateParam } = useRouter();

  const dispatch = useDispatch();

  // TODO temporary fix, to be removed in next release
  const offset = env("API_HOST").includes("staging") || env("API_HOST").includes("local.unifika.ca") ? 75 : 50;

  const auditType = getStateParam("auditType");
  const tabIndex = getStateParam("tabIndex");
  const parentType = getStateParam("parentType");
  const parentId = getStateParam("parentId");

  const assetsMinimalInfo = useSelector(getAssetsMinimalInfo);
  const filteredSystems = useSelector(getFilteredSystems);

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [systemWithoutModel, setSytemWithoutModel] = useState(false);

  const { isModalOpen, openModal, closeModal } = useModal();

  const { currentSystem, onUpdate: onSystemUpdate } = useSystem(system as System, auditDate, installationYear);

  const typeSystemIndex = currentSystem && currentSystem.uniformat && currentSystem.uniformat.level1 === UniformatLevel1.D ? 3 : 4; // TODO remove in PR issue #285

  const {
    onAdd: onAddCostLine,
    onUpdate: onUpdateCostLine,
    onDelete: onDeleteCostLine,
    onReset: onResetCostLineTable,
    selectedCostLines,
    costLinesTotalCost
  } = useCostLineTable(!isElementInCreationMode && system && system.costLines ? system.costLines : []);

  const previousSystemCostLines = usePrevious(selectedCostLines);
  const refSysInfo = createRef();
  const refLifeInfo = createRef();
  const refAuditInfo = createRef();
  const refCostLines = createRef();
  const context = createRef() as RefObject<HTMLDivElement>;

  const systemCustomName = useMemo((): string => {
    if (currentSystem) {
      const identification = currentSystem.identification && currentSystem.isIdentificationInName ? ` - ${capitalize(currentSystem.identification)}` : "";

      const localisation = currentSystem.localisation && currentSystem.isLocalisationInName ? ` - ${capitalize(currentSystem.localisation)}` : "";

      const systemInstallationYear = currentSystem.installationYear && currentSystem.isInstallationYearInName ? ` - ${capitalize(currentSystem.installationYear.toString())}` : "";

      let formattedLocals = "";

      if (currentSystem.isLocalNumberInName && currentSystem.locals) {
        if (currentSystem.locals.length > 0 && currentSystem.locals.length <= 6) {
          for (let i = 0; i < currentSystem.locals.length - 1; i += 1) {
            formattedLocals += `${currentSystem.locals[i].number} - `;
          }
          formattedLocals += currentSystem.locals[currentSystem.locals.length - 1].number;
          formattedLocals = ` - ${formattedLocals}`;
        }
      }

      return (currentSystem.custom_name || "") + identification + formattedLocals + localisation + systemInstallationYear;
    }
    return "";
  }, [currentSystem]);

  const systemUnitCost = useMemo((): number => {
    if (currentSystem && selectedCostLines.length !== 0) {
      const factor = currentSystem.unitOfMeasureFactor ? currentSystem.unitOfMeasureFactor : 1;

      return costLinesTotalCost / factor;
    }
    return 0;
  }, [selectedCostLines, costLinesTotalCost, currentSystem]);
  useEffect((): void => {
    onReset();
    if (!isElementInCreationMode) {
      if (currentSystem && currentSystem.errors && currentSystem.errors.length > 0) {
        let formattedError = "";

        currentSystem.errors.forEach((error: Error) => {
          if (typeof error.format === "function") {
            formattedError = `${formattedError}${error.format(dictionaryCode)}\n`;
          }
        });

        onUpdate(formattedError);
      }
    }

    onSystemUpdate({
      custom_name_concat: systemCustomName,
      unitCost: systemUnitCost
    });

    if (!isEqual(previousSystemCostLines, selectedCostLines)) {
      onResetCostLineTable(selectedCostLines);
      onSystemUpdate({
        costLines: selectedCostLines
      });
    }
  }, [
    onSystemUpdate,
    systemCustomName,
    systemUnitCost,
    previousSystemCostLines,
    selectedCostLines,
    onResetCostLineTable,
    isElementInCreationMode,
    dictionaryCode,
    onUpdate,
    currentSystem.errors,
    onReset
  ]);

  const [commentTabLabel, setCommentTabLabel] = useState(<div>Commentaires</div>);
  useEffect((): void => {
    const commentCount =
      currentSystem && currentSystem.commentsCount > 0 ? (
        <Label circular color="red" size="tiny">
          {currentSystem.commentsCount}
        </Label>
      ) : null;
    setCommentTabLabel(<div>Commentaires {commentCount}</div>);
  }, [currentSystem, currentSystem?.commentsCount]);

  const getPercentageDone = (): number => {
    const fieldsToWrite = [
      "uniformat",
      "custom_name",
      "actionType",
      "description",
      "unitOfMeasure",
      "unitOfMeasureFactor",
      "quantity",
      "unitCost",
      "renewalPercentage",
      "installationYear",
      "usefulLife",
      "estimated_lifetime",
      "lifetime",
      "ageRange",
      "conditionGrading",
      "auditor",
      "auditDate"
    ];

    let fieldsSum = 0;

    if (currentSystem) {
      fieldsToWrite.forEach((field: string) => {
        if (currentSystem[field] !== null && currentSystem[field] !== "" && currentSystem[field] !== undefined) {
          fieldsSum += 1;
        }
      });

      if (!currentSystem.installationYear) {
        fieldsSum += 1;
      }
    }

    return (fieldsSum / fieldsToWrite.length) * 100;
  };

  const confirmSave = useCallback(() => {
    onConfirm(currentSystem, false);
    setShouldBlockNavigation(false);
  }, [currentSystem, onConfirm]);

  const confirmSaveQuit = useCallback(() => {
    onConfirm(currentSystem, true);
    setShouldBlockNavigation(false);
  }, [currentSystem, onConfirm]);

  const handleDelete = (): void => {
    if (onDelete) {
      onDelete(systemId);
    }
  };

  const handleNavigationBlock = (): void => {
    setShouldBlockNavigation(true);
  };

  const handleActionReset = (): void => {
    onSystemUpdate({
      systemModelActionId: "",
      total_percentage: 0,
      renewalPercentage: 0,
      unitCost: 0,
      unitOfMeasure: "",
      lifetime: 0,
      description: "",
      comment: "",
      model_number: "",
      unitOfMeasureFactor: 1,
      actionType: ""
    });

    onResetCostLineTable([]);
  };

  const handleSystemReset = (): void => {
    onSystemUpdate({
      name: "",
      custom_name: "",
      custom_name_concat: "",
      systemModelId: ""
    });

    onResetCostLineTable([]);
    handleActionReset();
  };

  const createLabel = (): void => {
    if (currentSystem) {
      dispatch(generateSystemLabelsAction([currentSystem.id]));
    }
  };

  const onCostLinesChange = (costLines: CostLine[]): void => {
    const newSystemCostLines = costLines.map(
      (costLine: CostLine) =>
        new CostLine({
          costItemId: costLine.costItemId,
          quantity: costLine.quantity as number,
          code: costLine.code,
          unitCost: costLine.unitCost,
          description: costLine.description,
          className: costLine.className,
          unitOfMeasure: costLine.unitOfMeasure
        })
    );
    onResetCostLineTable(newSystemCostLines);
  };

  const systemTabIndex = auditType === AuditType.MECHANICAL ? INDEX_MECHANIC_COMPONENTS_TAB : auditType === AuditType.ARCHITECTURAL ? INDEX_ARCHITECTURE_COMPONENTS_TAB : "";

  const previousTabIndex = auditType ? systemTabIndex : tabIndex ? tabIndex : typeSystemIndex;

  const systemFieldValueFetcher = new SystemFieldValueFetcher();
  const fetchSystemValue = (fieldName: string): string => {
    return systemFieldValueFetcher.getFieldValue(currentSystem, fieldName);
  };

  const exit = useCallback(() => {
    if (parentType === EntityCategory.ROOM && parentId) {
      goTo(`/projects/${projectId}/sites/${siteId}/assets/${assetId}/rooms/${parentId}`, {
        tabIndex: auditType === AuditType.MECHANICAL ? 0 : 1
      });
    } else {
      goTo(`/projects/${projectId}/sites/${siteId}/assets/${assetId}`, {
        tabIndex: previousTabIndex
      });
    }
  }, [goTo, projectId, siteId, assetId, tabIndex, auditType]);

  const toWithoutModel = (): void => {
    setSytemWithoutModel(true);
    onSystemUpdate({ ...system, model_number: "Custom" });
  };

  const withModel = (): void => {
    setSytemWithoutModel(false);
  };
  const actions = [
    <SaveButton onClick={confirmSave} label="Enregistrer" key={0} />,
    <SaveButton onClick={confirmSaveQuit} label="Enregistrer et fermer" key={1} />,
    <Button onClick={toWithoutModel} title="Système sans modèle" disabled={systemWithoutModel || currentSystem.model_number === "Custom"} iconType={IconType.WRENCH} key={6} />,
    <Button onClick={exit} title="Fermer" iconType={IconType.CANCEL} key={2} />,
    !isElementInCreationMode && <Button onClick={openModal} title="" iconType={IconType.COPY} key={3} />,
    !isElementInCreationMode && currentSystem && currentSystem.uniformat && currentSystem.uniformat.level1 === UniformatLevel1.D && (
      <Button onClick={createLabel} title="" iconType={IconType.QR_CODE} key={4} />
    ),
    !isElementInCreationMode && <DeleteModal itemType={MODAL_TYPE_SYSTEM} onDelete={handleDelete} key={5} />
  ];

  return shouldRedirectToComponentsPage && parentType === EntityCategory.ROOM ? (
    <Redirect
      to={{
        pathname: `/projects/${projectId}/sites/${siteId}/assets/${assetId}/rooms/${parentId}`,
        state: { tabIndex: auditType === AuditType.MECHANICAL ? 0 : 1 }
      }}
    />
  ) : shouldRedirectToComponentsPage ? (
    <Redirect
      to={{
        pathname: `/projects/${projectId}/sites/${siteId}/assets/${assetId}`,
        state: { tabIndex: systemTabIndex ? systemTabIndex : previousTabIndex }
      }}
    />
  ) : shouldRedirectToModifySystem && newSystemId ? (
    <Redirect
      to={{
        pathname: `/projects/${projectId}/sites/${siteId}/assets/${assetId}/components/${newSystemId}`,
        state: { auditType, tabIndex: 6, parentType, parentId }
      }}
    />
  ) : (
    <div>
      <Page
        showBreadcrumb={!isElementInCreationMode}
        title={
          isElementInCreationMode ? (
            <>
              <span>Créer</span> un nouveau système
            </>
          ) : (
            <>
              <span>Système:</span> {system && system.reference ? system.reference : ""}{" "}
            </>
          )
        }
      >
        <Prompt message="Vous avez des changements non-enregistrés. Quitter quand même ?" when={shouldBlockNavigation} />
        <SystemCopyModal system={currentSystem} isOpen={isModalOpen} onClose={closeModal} />
        <div className={className} ref={context}>
          <>
            <ErrorMessage message={errorMessage} onDismiss={onReset} className="systemError" />
            <Tabs>
              <Tab label="Informations" className="informations">
                <Sticky context={context} offset={offset}>
                  <div className="actionsBar">
                    <Toolbar actions={actions} />
                  </div>
                </Sticky>
                <SystemFormState system={currentSystem} onUpdate={onSystemUpdate} onNavigationBlock={handleNavigationBlock} />
                <SystemInformation
                  systemWithoutModel={systemWithoutModel}
                  withModel={withModel}
                  system={currentSystem}
                  assetsMinimalInfo={assetsMinimalInfo}
                  onUpdate={onSystemUpdate}
                  onNavigationBlock={handleNavigationBlock}
                  isElementInCreationMode={isElementInCreationMode}
                  onSystemReset={handleSystemReset}
                  onActionReset={handleActionReset}
                  projectId={projectId}
                  siteId={siteId}
                  assetId={assetId}
                  ref={refSysInfo}
                  onCostLinesChange={onCostLinesChange}
                  calculations={currentSystem.calculations}
                />
                <SystemLifeInformation
                  system={currentSystem}
                  onUpdate={onSystemUpdate}
                  onNavigationBlock={handleNavigationBlock}
                  projectId={projectId}
                  ref={refLifeInfo}
                  systemWithoutModel={systemWithoutModel}
                />
                <AuditInformation system={currentSystem} onUpdate={onSystemUpdate} onNavigationBlock={handleNavigationBlock} ref={refAuditInfo} />
                <CostLinesInformation
                  onAdd={onAddCostLine}
                  onUpdate={onUpdateCostLine}
                  onDelete={onDeleteCostLine}
                  selectedCostLines={selectedCostLines}
                  ref={refCostLines}
                  systemId={systemId as string}
                />
              </Tab>
              <Tab label="Systèmes liés">
                <SystemChildren siteId={siteId} systemId={systemId as string} />
              </Tab>
              {!isElementInCreationMode && (
                <Tab label="Photos">
                  <PhotoListing entityId={systemId || ""} entityCategory={EntityCategory.SYSTEM} />
                </Tab>
              )}
              {!isElementInCreationMode && !!currentSystem && (
                <Tab label={commentTabLabel}>
                  <CommentsListing parentId={currentSystem.id} parentType={EntityCategory.SYSTEM} fetchFieldValue={fetchSystemValue} />
                </Tab>
              )}
            </Tabs>
          </>
          <FormOverview
            itemId={systemId}
            items={filteredSystems}
            percentage={getPercentageDone()}
            tabIndex={tabIndex}
            formSectionNavigationLinks={[
              {
                reference: refSysInfo,
                title: " 1. Informations du système"
              },
              {
                reference: refLifeInfo,
                title: " 2. Informations de vie du système"
              },
              { reference: refAuditInfo, title: "3. Informations sur l'audit" },
              {
                reference: refCostLines,
                title: "4. Informations des lignes de coût"
              }
            ]}
          />
        </div>
      </Page>
    </div>
  );
};

export default createStyledComponent(memo(SystemForm), systemFormStyle);
