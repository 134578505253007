/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */

import SearchItem from "app/domain/search/searchItem";
import { useRouter } from "app/routing/routerProvider";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useCallback } from "react";
import { css } from "styled-components";
import { colors } from "ui/colors";

const searchResultStyle = css`
  padding: 6px 10px;

  a {
    color: ${colors.PRIMARY_WHITE};
  }

  &:hover {
    background-color: ${colors.ACCENT_BLUE};
  }
`;

interface SearchResultProps extends ComponentProps {
  item: SearchItem;
  onClick: () => void;
}

const SearchResult: FC<SearchResultProps> = ({ item, onClick, className }: SearchResultProps) => {
  const { goTo } = useRouter();

  const handleClick = useCallback(() => {
    onClick();
    goTo(item.location);
  }, [onClick, goTo, item]);

  return (
    <li className={className}>
      <a onClick={handleClick} data-test-id="searchResultLink">
        {item.text}
      </a>
    </li>
  );
};

export default createStyledComponent(SearchResult, searchResultStyle);
