interface SiteCommentProps {
  commentId: number;
  parentCategory: string;
  projectId: string;
  siteId: string;
  assetId: string;
  parentId: string;
  assetNumber: string;
  parentName: string;
  message: string;
  author: string;
  isResolved: boolean;
  createdAt: string;
}

class SiteComment {
  commentId: number;
  parentCategory: string;
  projectId: string;
  siteId: string;
  assetId: string;
  parentId: string;
  assetNumber: string;
  parentName: string;
  message: string;
  author: string;
  isResolved: boolean;
  createdAt: string;

  constructor(props: SiteCommentProps) {
    this.commentId = props.commentId;
    this.parentCategory = props.parentCategory;
    this.projectId = props.projectId;
    this.siteId = props.siteId;
    this.assetId = props.assetId;
    this.parentId = props.parentId;
    this.assetNumber = props.assetNumber;
    this.parentName = props.parentName;
    this.message = props.message;
    this.author = props.author;
    this.isResolved = props.isResolved;
    this.createdAt = props.createdAt;
  }
}

export default SiteComment;
