import AuditProgress from "app/domain/asset/auditProgress";
import { State } from "app/store/state";
import { createSelector } from "reselect";

export const getAuditProgress = createSelector(
  [(state: State): AuditProgress | undefined => state.auditProgress.auditProgress],
  (auditProgress: AuditProgress | undefined) => auditProgress
);

export const isLoading = createSelector(
  [(state: State): boolean => state.auditProgress.isFetching, (state: State): boolean => state.auditProgress.isUpdating],
  (isFetchingAuditProgress: boolean, isUpdatingAuditProgress: boolean) => isFetchingAuditProgress || isUpdatingAuditProgress
);

export const getErrorMessage = createSelector([(state: State): string => state.auditProgress.errorMessage], (errorMessage) => errorMessage);
