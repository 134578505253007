import { Asset } from "app/domain/asset";
import { useI18n } from "app/locales";
import { getAssetOptions } from "app/store/asset/selectors";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { Form as SemanticForm } from "semantic-ui-react";

import { AssetFields } from "../../../useAsset";

interface AuditInformationProps extends ComponentProps {
  asset: Asset | undefined;
  onUpdate: (fields: AssetFields) => void;
}

const AuditInformation: FC<AuditInformationProps> = ({ asset, onUpdate }: AuditInformationProps) => {
  const { getAttributeLabel } = useI18n();

  const assetOptions = useSelector(getAssetOptions);

  const handleSelectChange = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({ [data.name]: data.value });
  };

  const handleChange = (event: any): void => {
    event.preventDefault();

    onUpdate({ [event.target.name]: event.target.value });
  };

  const convertDate = (date: string | undefined | null): Date | null => {
    if (date !== undefined && date !== null && date !== "") {
      const parts = date.split("-");
      return new Date(parts[0] as unknown as number, (parts[1] as unknown as number) - 1, parts[2] as unknown as number);
    }
    return null;
  };

  const minTwoDigits = (n: number): string => (n < 10 ? "0" : "") + n;

  const handleDateChange = (name: string, date: any): void => {
    if (date !== null) {
      const dateString = `${(date as Date).getUTCFullYear().toString()}-${minTwoDigits((date as Date).getUTCMonth() + 1).toString()}-${minTwoDigits(
        (date as Date).getUTCDate().toString() as unknown as number
      )}`;

      onUpdate({ [name]: dateString });
    }
  };

  return (
    <>
      <div className="date-field">
        <div>Date du dernier audit</div>
        <ReactDatePicker
          disabled
          name="assessment_date"
          onChange={(date): void => handleDateChange("assessment_date", date)}
          selected={convertDate(asset && asset.assessment_date)}
          dateFormat="dd/MM/yyyy"
        />
      </div>
      <SemanticForm.Group unstackable widths={4}>
        <SemanticForm.Input
          disabled
          label="Année des données existantes"
          placeholder="Année des données existantes"
          name="year_of_data"
          value={asset && asset.year_of_data ? asset.year_of_data : ""}
          onChange={handleChange}
        />
      </SemanticForm.Group>
      <div className="date-field">
        <div>{getAttributeLabel("audit_date")}</div>
        <ReactDatePicker name="auditDate" onChange={(date): void => handleDateChange("auditDate", date)} selected={convertDate(asset && asset.auditDate)} dateFormat="dd/MM/yyyy" />
      </div>
      <SemanticForm.Group unstackable widths={3}>
        <SemanticForm.Dropdown
          label={getAttributeLabel("auditor_interior")}
          clearable
          placeholder={getAttributeLabel("auditor_interior")}
          name="auditorInterior"
          value={asset && asset.auditorInterior}
          onChange={handleSelectChange}
          fluid
          selection
          options={assetOptions && assetOptions.list_auditor_options ? assetOptions.list_auditor_options : []}
        />
        <SemanticForm.Dropdown
          label={getAttributeLabel("auditor_mechanical")}
          clearable
          placeholder={getAttributeLabel("auditor_mechanical")}
          name="auditorMechanical"
          value={asset && asset.auditorMechanical}
          onChange={handleSelectChange}
          selection
          options={assetOptions && assetOptions.list_auditor_options ? assetOptions.list_auditor_options : []}
        />
      </SemanticForm.Group>
      <SemanticForm.Group unstackable widths={3}>
        <SemanticForm.Dropdown
          label={getAttributeLabel("auditor_building_envelope")}
          clearable
          placeholder={getAttributeLabel("auditor_building_envelope")}
          name="auditorBuildingEnvelope"
          value={asset && asset.auditorBuildingEnvelope}
          onChange={handleSelectChange}
          selection
          options={assetOptions && assetOptions.list_auditor_options ? assetOptions.list_auditor_options : []}
        />
        <SemanticForm.Dropdown
          label={getAttributeLabel("auditor_electrical")}
          clearable
          placeholder={getAttributeLabel("auditor_electrical")}
          name="auditorElectrical"
          value={asset && asset.auditorElectrical}
          onChange={handleSelectChange}
          selection
          options={assetOptions && assetOptions.list_auditor_options ? assetOptions.list_auditor_options : []}
        />
      </SemanticForm.Group>
    </>
  );
};

export default AuditInformation;
