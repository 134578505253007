import { ComparisonError, ComparisonOperator } from "app/domain/common";

export interface ComparisonErrorDTO {
  operator: string;
  params: {
    first_param: string;
    second_param: string;
  };
}

class ComparisonErrorMapper {
  static fromDTO(comparisonErrorDTOs: ComparisonErrorDTO[]): ComparisonError[] {
    return comparisonErrorDTOs.map(
      (comparisonErrorDTO: ComparisonErrorDTO) =>
        new ComparisonError({
          firstValue: comparisonErrorDTO.params.first_param,
          secondValue: comparisonErrorDTO.params.second_param,
          operator: ComparisonOperator.fromString(comparisonErrorDTO.operator)
        })
    );
  }
}

export default ComparisonErrorMapper;
