/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";
import { Project } from "app/domain/project";

import { getProjectsAction, getProjectsErrorAction, getProjectsRequestAction, getProjectsSuccessAction, resetAction, setCurrentProjectAction } from "./actions";
import { initialProjectState, ProjectState } from "./state";
import { GetProjectsErrorAction, GetProjectsSuccessAction, SetCurrentProjectAction } from "./types";

const getProjects = (state: ProjectState): void => {
  state.errorMessage = "";
};

const getProjectsRequest = (state: ProjectState): void => {
  state.isFetching = true;
};

const getProjectsSuccess = (state: ProjectState, action: GetProjectsSuccessAction): void => {
  state.isFetching = false;
  state.projects = action.payload.projects;
};

const getProjectsError = (state: ProjectState, action: GetProjectsErrorAction): void => {
  state.isFetching = false;
  state.errorMessage = action.payload.errorMessage;
};

const setCurrentProject = (state: ProjectState, action: SetCurrentProjectAction): void => {
  state.currentProject = state.projects.find((project: Project) => project.id === action.payload.projectId);
};

const reset = (state: ProjectState): void => {
  state.errorMessage = "";
};

const projectReducer = createReducer(initialProjectState, {
  [getProjectsAction.type]: (state) => getProjects(state),
  [getProjectsRequestAction.type]: (state) => getProjectsRequest(state),
  [getProjectsSuccessAction.type]: (state, action) => getProjectsSuccess(state, action),
  [getProjectsErrorAction.type]: (state, action) => getProjectsError(state, action),
  [setCurrentProjectAction.type]: (state, action) => setCurrentProject(state, action),
  [resetAction.type]: (state) => reset(state)
});

export default projectReducer;
