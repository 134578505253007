import { createAction } from "@reduxjs/toolkit";
import Local from "app/domain/local/local";

import { LocalActionTypes } from "./types";

export const getLocalsAction = createAction(LocalActionTypes.GET_LOCALS, (assetId: string) => ({
  payload: {
    assetId
  }
}));

export const getLocalsRequestAction = createAction(LocalActionTypes.GET_LOCALS_REQUEST, (assetId: string) => ({
  payload: {
    assetId
  }
}));

export const getLocalsSuccessAction = createAction(LocalActionTypes.GET_LOCALS_SUCCESS, (locals: Local[]) => ({
  payload: {
    locals
  }
}));

export const getLocalsErrorAction = createAction(LocalActionTypes.GET_LOCALS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const getRoomAction = createAction(LocalActionTypes.GET_ROOM, (roomId: string) => ({
  payload: {
    roomId
  }
}));

export const getRoomRequestAction = createAction(LocalActionTypes.GET_ROOM_REQUEST, (roomId: string) => ({
  payload: {
    roomId
  }
}));

export const getRoomSuccessAction = createAction(LocalActionTypes.GET_ROOM_SUCCESS, (room: Local) => ({
  payload: {
    room
  }
}));

export const getRoomErrorAction = createAction(LocalActionTypes.GET_ROOM_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const resetAction = createAction(LocalActionTypes.RESET_LOCAL);
