import { EntityCategory } from "app/domain/entityCategory";
import { FieldNames } from "app/service/comment/field/fieldNames";

const SYSTEM_FIELDS = [
  FieldNames.TOTAL_COST,
  FieldNames.HEIGHT,
  FieldNames.INSTALLATION_YEAR,
  FieldNames.PUBLIC_NOTES,
  FieldNames.CONDITION_GRADING,
  FieldNames.CONDITION_GRADING_AFTER_REPAIR,
  FieldNames.AGE_RANGE,
  FieldNames.ACTION_TYPE,
  FieldNames.ESTIMATED_LIFETIME,
  FieldNames.PATRIMONY_FACTOR,
  FieldNames.COMPLEXITY,
  FieldNames.REMOVAL,
  FieldNames.NOTE,
  FieldNames.QUANTITY,
  FieldNames.NAME,
  FieldNames.CUSTOM_NAME,
  FieldNames.IDENTIFICATION,
  FieldNames.DESCRIPTION,
  FieldNames.LOCALISATION,
  FieldNames.N_A
];

const REQUIREMENT_FIELDS = [
  FieldNames.TOTAL_COST,
  FieldNames.NAME,
  FieldNames.DESCRIPTION,
  FieldNames.COMPONENT_ID,
  FieldNames.LOCALISATION,
  FieldNames.PRIORITY,
  FieldNames.COMPLEXITY,
  FieldNames.HEIGHT,
  FieldNames.REMOVAL,
  FieldNames.ACTIVITY,
  FieldNames.COMMENT,
  FieldNames.PATRIMONY_FACTOR,
  FieldNames.ACTION_DESCRIPTION,
  FieldNames.ACTION_COMMENT,
  FieldNames.ACTION_NAME,
  FieldNames.ACTION_NAME_SUFFIX,
  FieldNames.N_A
];

const ASSET_FIELDS = [
  FieldNames.YEAR_CONSTRUCTED,
  FieldNames.AREA,
  FieldNames.AUDIT_DATE,
  FieldNames.AUDITOR_BUILDING_ENVELOPE,
  FieldNames.AUDITOR_INTERIOR,
  FieldNames.AUDITOR_MECHANICAL,
  FieldNames.AUDITOR_ELECTRICAL,
  FieldNames.REPLACEMENT_VALUE,
  FieldNames.DESCRIPTION,
  FieldNames.ESTIMATED_CONDITION_GRADING,
  FieldNames.N_A
];

export default class FieldNameFactory {
  getCommentableFieldNames(parentType: EntityCategory): string[] {
    switch (parentType) {
      case EntityCategory.SYSTEM:
        return SYSTEM_FIELDS.sort();

      case EntityCategory.REQUIREMENT:
        return REQUIREMENT_FIELDS.sort();

      case EntityCategory.ASSET:
        return ASSET_FIELDS.sort();

      default:
        return [];
    }
  }
}
