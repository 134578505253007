import { RefObject, useCallback, useMemo } from "react";
import { useForm as useReactHookForm, useFormContext as useReactHookFormContext } from "react-hook-form";

import FormContext from "./formContext";

type FormHook = {
  onFormSubmit: () => void;
  formContext: FormContext;
};

export const useForm = (formReference: RefObject<Element>): FormHook => {
  const reactHookForm = useReactHookForm({ mode: "onChange" });

  const onFormSubmit = useCallback(() => {
    (formReference.current as HTMLFormElement).dispatchEvent(new Event("submit"));
  }, [formReference]);

  return useMemo(
    () => ({
      onFormSubmit,
      formContext: new FormContext(reactHookForm)
    }),
    [onFormSubmit, reactHookForm]
  );
};

export const useFormContext = (): FormContext | undefined => {
  const reactHookFormContext = useReactHookFormContext();

  return useMemo(() => (reactHookFormContext !== null ? new FormContext(reactHookFormContext) : undefined), [reactHookFormContext]);
};
