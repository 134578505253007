import { Asset } from "app/domain/asset";
import { EntityCategory } from "app/domain/entityCategory";
import { Params, useRouter } from "app/routing/routerProvider";
import AssetService from "app/service/asset/assetService";
import { getAssetsAction, updateAssetAction } from "app/store/asset/actions";
import { notify } from "app/view/common/toast/Toast";
import EditAssetDrawer from "app/view/site/form/editAssetDrawer";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { css } from "styled-components";

import { AuditStatus } from "../../../domain/audit/auditStatus";
import AssetTable from "./assetTable";

const assetListingStyle = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

interface AssetListingProps extends ComponentProps {
  auditStatus: AuditStatus;
}

const AssetListing: FC<AssetListingProps> = ({ auditStatus, className }: AssetListingProps) => {
  const dispatch = useDispatch();
  const { getParam } = useRouter();

  const projectId = getParam(Params.PROJECT);

  useEffect(() => {
    dispatch(getAssetsAction(EntityCategory.PROJECT, projectId, auditStatus));
  }, [projectId, auditStatus, dispatch]);

  const [visibleEditAssetDrawer, setVisibleEditAssetDrawer] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({} as Asset);

  const displayEditAssetDrawer = (selectedAssetId: string): void => {
    AssetService.getAsset(selectedAssetId).then((res: Asset) => {
      setSelectedAsset(res);
      setVisibleEditAssetDrawer(true);
    });
  };

  const onSubmitValidEdit = (asset: Asset): void => {
    dispatch(updateAssetAction(asset, EntityCategory.PROJECT, projectId, auditStatus));
    setVisibleEditAssetDrawer(false);
  };

  const onAbortEdit = (): void => {
    setVisibleEditAssetDrawer(false);
    notify("Édition annulée.");
  };

  return (
    <div className={className} key={auditStatus}>
      <AssetTable projectId={projectId} auditStatus={auditStatus} displayDrawer={displayEditAssetDrawer} />
      <EditAssetDrawer visible={visibleEditAssetDrawer} onSubmitValid={onSubmitValidEdit} onAbort={onAbortEdit} asset={selectedAsset} />
    </div>
  );
};

export default createStyledComponent(memo(AssetListing), assetListingStyle);
