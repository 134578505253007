import { combineReducers } from "redux";
import siteReducer from "./site/reducer";
import assetReducer from "./asset/reducer";
import sideMenuReducer from "./sideMenu/sideMenuReducer";
import localReducer from "./local/reducer";
import systemReducer from "./system/reducer";
import systemModelReducer from "./system/systemModel/reducer";
import loginReducer from "./login/reducer";
import requirementReducer from "./requirement/reducer";
import costItemReducer from "./costItem/reducer";
import auditProgressReducer from "./auditProgress/reducer";
import projectReducer from "./project/reducer";
import photoReducer from "./photo/reducer";
import connectionReducer from "./connection/reducer";
import searchReducer from "./search/reducer";
import filterReducer from "./filter/reducer";
import selectedValueReducer from "./filter/selectedValue/reducer";
import commentReducer from "./comment/commentReducer";
import reportReducer from "./report/reportReducer";

const rootReducer = combineReducers({
  sites: siteReducer,
  assets: assetReducer,
  minimalInfo: sideMenuReducer,
  locals: localReducer,
  login: loginReducer,
  systems: systemReducer,
  systemModels: systemModelReducer,
  requirements: requirementReducer,
  costItems: costItemReducer,
  auditProgress: auditProgressReducer,
  projects: projectReducer,
  photo: photoReducer,
  search: searchReducer,
  connection: connectionReducer,
  filter: filterReducer,
  selectedValues: selectedValueReducer,
  comments: commentReducer,
  report: reportReducer,
});

export default rootReducer;
