import { SystemLabel } from "app/domain/system";

export type SystemLabelDTO = {
  barcode: string;
  labelTitle: string;
  labelText: string;
};

export type PrinterStatusDTO = {
  printerName: string;
  printerIsOnline: boolean;
  printerMediaName: string;
  printSuccess: boolean;
  printMessage: string;
};

class SystemLabelMapper {
  static toDTO(systemLabel: SystemLabel): SystemLabelDTO {
    return {
      labelTitle: systemLabel.title,
      labelText: systemLabel.description,
      barcode: systemLabel.barcode
    };
  }
}

export default SystemLabelMapper;
