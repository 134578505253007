/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";

import { getCostItemsAction, getCostItemsErrorAction, getCostItemsRequestAction, getCostItemsSuccessAction, resetAction } from "./actions";
import { CostItemState, initialCostItemState } from "./state";
import { GetCostItemsErrorAction, GetCostItemsSuccessAction } from "./types";

const getCostItems = (state: CostItemState): void => {
  state.errorMessage = "";
};

const getCostItemsRequest = (state: CostItemState): void => {
  state.isLoading = true;
};

const getCostItemsSuccess = (state: CostItemState, action: GetCostItemsSuccessAction): void => {
  state.isLoading = false;
  state.costItems = action.payload.costItems;
};

const getCostItemsError = (state: CostItemState, action: GetCostItemsErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const reset = (state: CostItemState): void => {
  state.errorMessage = "";
};

const costItemReducer = createReducer(initialCostItemState, {
  [getCostItemsAction.type]: (state) => getCostItems(state),
  [getCostItemsRequestAction.type]: (state) => getCostItemsRequest(state),
  [getCostItemsSuccessAction.type]: (state, action) => getCostItemsSuccess(state, action),
  [getCostItemsErrorAction.type]: (state, action) => getCostItemsError(state, action),
  [resetAction.type]: (state) => reset(state)
});

export default costItemReducer;
