/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";

import { getSystemModelsAction, getSystemModelsErrorAction, getSystemModelsRequestAction, getSystemModelsSuccessAction, resetAction } from "./actions";
import { initialSystemModelState, SystemModelState } from "./state";
import { GetSystemModelsErrorAction, GetSystemModelsSuccessAction } from "./types";

const getSystemModels = (state: SystemModelState): void => {
  state.errorMessage = "";
};

const getSystemModelsRequest = (state: SystemModelState): void => {
  state.isFetching = true;
};

const getSystemModelsSuccess = (state: SystemModelState, action: GetSystemModelsSuccessAction): void => {
  state.systemModels = action.payload.systemModels;
  state.isFetching = false;
};

const getSystemModelsError = (state: SystemModelState, action: GetSystemModelsErrorAction): void => {
  state.errorMessage = action.payload.errorMessage;
  state.isFetching = false;
};

const reset = (state: SystemModelState): void => {
  state.systemModels = [];
  state.errorMessage = "";
};

const systemModelReducer = createReducer(initialSystemModelState, {
  [getSystemModelsAction.type]: (state) => getSystemModels(state),
  [getSystemModelsRequestAction.type]: (state) => getSystemModelsRequest(state),
  [getSystemModelsSuccessAction.type]: (state, action) => getSystemModelsSuccess(state, action),
  [getSystemModelsErrorAction.type]: (state, action) => getSystemModelsError(state, action),
  [resetAction.type]: (state) => reset(state)
});

export default systemModelReducer;
