/* eslint-disable react-hooks/exhaustive-deps */

import { Requirement } from "app/domain/requirement";
import { SystemMinimalInfo } from "app/domain/system";
import SystemService from "app/service/system/systemService";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect, useState } from "react";
import { useAsync } from "react-async";
import { Form } from "semantic-ui-react";
import styled from "styled-components";

interface AvailableSystemsDropdownProps extends ComponentProps {
  assetId: string;
  requirement: Requirement | undefined;
  onUpdate: (fields: any) => void;
  onUpdateErrorMessage: (errorMessage: string) => void;
}

const StyledDropdown = styled.div`
  .ui.search.selection.dropdown {
    width: 600px;
  }
`;

const AvailableSystemsDropdown: FC<AvailableSystemsDropdownProps> = ({ assetId, requirement, onUpdate, onUpdateErrorMessage }: AvailableSystemsDropdownProps) => {
  const [availableSystemOptions, setAvailableSystemOptions] = useState([] as any[]);

  const fetchAvailableSystems = (): Promise<SystemMinimalInfo[]> => {
    return SystemService.getSystemsMinimalInfo(assetId);
  };

  const handleSuccess = (systems: SystemMinimalInfo[]): void => {
    const systemOptions = systems.map((system: SystemMinimalInfo) => ({
      key: system.id,
      text: system.customNameConcat ? system.customNameConcat : "",
      value: system.id,
      description: system.reference ? system.reference : ""
    }));
    const options = [{ key: "-", text: "Aucun système associé", value: "-", description: "" }].concat(systemOptions);
    setAvailableSystemOptions(options);
  };

  const handleError = (): void => {
    onUpdateErrorMessage(`Une erreur s'est produite lors de la récupération des systèmes liés à l'actif. Veuillez réessayer`);
  };

  const { run } = useAsync({
    deferFn: fetchAvailableSystems,
    onResolve: handleSuccess,
    onReject: handleError
  });

  useEffect(() => {
    run();
  }, []);

  const handleChange = (event: React.SyntheticEvent<HTMLElement>, data: any): void => {
    event.preventDefault();
    onUpdate({ component_id: data.value });
  };

  return (
    <div>
      <StyledDropdown>
        <Form.Dropdown
          label="Système associé"
          clearable
          search
          placeholder="Système associé"
          name="component_id"
          value={requirement && requirement.component_id}
          onChange={handleChange}
          selection
          options={availableSystemOptions}
          data-test-id="availableSystemDropdown"
        />
      </StyledDropdown>
    </div>
  );
};

export default memo(AvailableSystemsDropdown);
