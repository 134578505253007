/* stylelint-disable value-keyword-case */

import { AuditType } from "app/domain/audit/auditType";
import { EntityCategory } from "app/domain/entityCategory";
import { useRouter } from "app/routing/routerProvider";
import { getCurrentRoom } from "app/store/local/selectors";
import { formStyle } from "app/view/common/form";
import { Page } from "app/view/common/page";
import { Toolbar } from "app/view/common/toolbar";
import * as constants from "app/view/constant";
import { SystemListing } from "app/view/system/listing";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { css } from "styled-components";
import { Button } from "ui/button";
import { ErrorMessage } from "ui/errorMessage";
import { IconType } from "ui/icon";
import { Tab, Tabs, useTabs } from "ui/tab";

export const roomFormStyle = css`
  &&& {
    ${formStyle};
    display: flex;
    flex-direction: column;
    flex: 1;

    .roomError {
      margin: 1em;
    }

    > *:not(:last-child) {
      margin-bottom: 1em;
    }
  }
`;

interface RoomFormProps extends ComponentProps {
  projectId: string;
  siteId: string;
  assetId: string;
  errorMessage: string;
  onReset: () => void;
  onUpdateError: (errorMessage: string) => void;
}

const RoomForm: FC<RoomFormProps> = ({ projectId, siteId, assetId, errorMessage, onReset, onUpdateError, className }: RoomFormProps) => {
  const { goTo, getStateParam } = useRouter();
  const tabIndex = getStateParam("tabIndex");
  const { activeTabIndex, onTabChange } = useTabs(tabIndex as number);
  const currentRoom = useSelector(getCurrentRoom);

  const exit = (): void => {
    goTo(`/projects/${projectId}/sites/${siteId}/assets/${assetId}`, {
      tabIndex: 6
    });
  };

  const actions = [<Button onClick={exit} title="Fermer" iconType={IconType.CANCEL} key={0} data-test-id="exitButton" />];

  return (
    <Page
      showBreadcrumb
      title={
        <>
          <span>Local:</span> {currentRoom ? `${currentRoom.number} - ${currentRoom.description}` : ""}
        </>
      }
    >
      <div className={className}>
        <ErrorMessage message={errorMessage} onDismiss={onReset} className="roomError" />
        <Toolbar actions={actions} />
        <Tabs activeTabIndex={activeTabIndex || 0} onTabChange={onTabChange}>
          <Tab label={constants.MECHANICAL_SYSTEMS}>
            <SystemListing parentType={EntityCategory.ROOM} auditType={AuditType.MECHANICAL} roomId={currentRoom && currentRoom.id} />
          </Tab>
          <Tab label={constants.ARCHITECTURAL_SYSTEMS}>
            <SystemListing parentType={EntityCategory.ROOM} auditType={AuditType.ARCHITECTURAL} roomId={currentRoom && currentRoom.id} />
          </Tab>
        </Tabs>
      </div>
    </Page>
  );
};

export default createStyledComponent(RoomForm, roomFormStyle);
