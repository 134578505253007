import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import compare from "react-fast-compare";
import { Controller } from "react-hook-form";

import FormContext, { FormControlValidationSchema } from "./formContext";
import { useFormContext } from "./useForm";

export interface BaseFormControlProps {
  name: string;
  rules?: FormControlValidationSchema;
}

interface FormControlProps extends ComponentProps, BaseFormControlProps {
  children: JSX.Element;
}

const getPropsValues = (props: Record<string, any>): any[] =>
  Object.keys(props).map((prop: any) => {
    if (typeof props[prop] === "function") {
      return props[prop].toString();
    }
    return props[prop];
  });

const isEqual = (prevProps: FormControlProps, nextProps: FormControlProps): boolean => {
  const prevPropsValues = getPropsValues(prevProps.children.props);
  const nextPropsValues = getPropsValues(nextProps.children.props);

  for (let index = 0; index < prevPropsValues.length; index += 1) {
    if (!compare(prevPropsValues[index], nextPropsValues[index])) {
      return false;
    }
  }
  return true;
};

const FormControl: FC<FormControlProps> = ({ children, name, rules, className }: FormControlProps) => {
  const formContext = useFormContext();

  return (
    <Controller
      className={className}
      key={name}
      as={children}
      name={name}
      control={(formContext as FormContext).getControl()}
      rules={rules}
      defaultValue=""
      data-test-id="formControl"
    />
  );
};

export default memo(FormControl, isEqual);
