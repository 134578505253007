import { Requirement } from "app/domain/requirement";
import { useI18n } from "app/locales";
import { getRequirementOptions } from "app/store/requirement/selectors";
import { FormFieldGroup } from "app/view/common/form";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { forwardRef, RefObject } from "react";
import { useSelector } from "react-redux";
import { Form as SemanticForm } from "semantic-ui-react";

import { RequirementFields } from "../useRequirement";

interface TimelineInformationProps extends ComponentProps {
  requirement: Requirement | undefined;
  onUpdate: (fields: RequirementFields) => void;
  onNavigationBlock: () => void;
}

const TimelineInformation = forwardRef(({ requirement, onUpdate, onNavigationBlock }: TimelineInformationProps, ref): JSX.Element => {
  const { getAttributeLabel } = useI18n();

  const requirementOptions = useSelector(getRequirementOptions);

  const handlePriorityChange = (event: any, data: any): void => {
    event.preventDefault();

    const yearPlanned = new Date().getFullYear();
    let yearValue;

    if (data.value === 0) {
      yearValue = yearPlanned;
    } else if (data.value === 1) {
      yearValue = yearPlanned + 1;
    } else if (data.value === 3) {
      yearValue = yearPlanned + 3;
    } else if (data.value === 4) {
      yearValue = yearPlanned + 6;
    } else if (data.value === "") {
      yearValue = 0;
    }

    onUpdate({
      [data.name]: data.value === "" ? null : data.value,
      yearPlanned: yearValue
    });
    onNavigationBlock();
  };

  return (
    <div>
      <h3 ref={ref as RefObject<any>}>
        <span>2.</span> Informations temporelles
      </h3>
      <FormFieldGroup>
        <SemanticForm.Dropdown
          required
          label={getAttributeLabel("priority")}
          clearable
          placeholder={getAttributeLabel("priority")}
          name="priority"
          value={requirement && requirement.priority}
          onChange={handlePriorityChange}
          selection
          options={requirementOptions ? requirementOptions.priorityOptions : []}
        />
        <SemanticForm.Input
          label={getAttributeLabel("year_planned")}
          placeholder={getAttributeLabel("year_planned")}
          name="yearPlanned"
          value={requirement && requirement.yearPlanned}
          type="number"
        />
      </FormFieldGroup>
    </div>
  );
});

TimelineInformation.displayName = "TimelineInformation";

export default TimelineInformation;
