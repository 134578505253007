import { EntityCategory } from "app/domain/entityCategory";
import { Params, useRouter } from "app/routing/routerProvider";
import {
  deletePhotosAction,
  getPhotosAction,
  getPhotosFolderDownloadProgress,
  getPhotosFolderPollingStatus,
  startDownloadingPhotosFolderAction,
  stopDownloadingPhotosFolderAction
} from "app/store/photo";
import { PhotosFolderPollingStatus } from "app/store/photo/state";
import { State } from "app/store/state";
import { Toolbar } from "app/view/common/toolbar";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { AddButton, DeleteButton, DownloadButton } from "ui/button/actions";
import { useSelectableCardGrid } from "ui/card";
import { useModal } from "ui/modal";

import AddPhotosModal from "./addPhotosModal";
import PhotoGrid from "./photoGrid";

const ADD = "Ajouter";
const DELETE = "Supprimer";
const DOWNLOAD = "Télécharger";
const DOWNLOAD_IN_PROGRESS = "Préparation du zip en cours...";

interface PhotoListingProps extends ComponentProps {
  entityId: string;
  entityCategory: EntityCategory;
}

export const photoListingStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;

  .imageUploader {
    height: 250px;
    margin-top: 15px;
  }

  > *:not(:last-child) {
    margin-bottom: 1em;
  }
`;

const PhotoListing: FC<PhotoListingProps> = ({ entityId, entityCategory, className }: PhotoListingProps) => {
  const { getParam } = useRouter();
  const { isModalOpen, openModal, closeModal } = useModal();
  const { onSelect, onReset, selectedCardKeys } = useSelectableCardGrid();

  const dispatch = useDispatch();

  const projectId = getParam(Params.PROJECT);
  const siteId = getParam(Params.SITE);
  const assetId = getParam(Params.ASSET);

  const photosFolderDownloadProgress = useSelector((state: State): number => getPhotosFolderDownloadProgress(state, assetId));

  const photosFolderPollingStatus = useSelector((state: State): PhotosFolderPollingStatus => getPhotosFolderPollingStatus(state, assetId));

  useEffect(() => {
    dispatch(getPhotosAction(entityId, entityCategory));
  }, [dispatch, entityId, entityCategory]);

  const deletePhotos = (): void => {
    dispatch(deletePhotosAction(selectedCardKeys, entityId));
    onReset();
  };

  const handleDownloadStart = (): void => {
    dispatch(startDownloadingPhotosFolderAction(entityId, entityCategory));
  };

  const handleDownloadCancel = (): void => {
    dispatch(stopDownloadingPhotosFolderAction(assetId));
  };

  const actions = [
    <AddButton onClick={openModal} label={ADD} key={0} />,
    selectedCardKeys.length !== 0 && <DeleteButton onClick={deletePhotos} label={DELETE} key={1} />,
    entityCategory === EntityCategory.ASSET && (
      <DownloadButton
        label={DOWNLOAD}
        downloadLabel={DOWNLOAD_IN_PROGRESS}
        onDownloadStart={handleDownloadStart}
        onDownloadStop={handleDownloadCancel}
        progress={photosFolderDownloadProgress}
        isDownloading={photosFolderPollingStatus === PhotosFolderPollingStatus.STARTED}
        key={2}
      />
    )
  ];

  return (
    <div className={className}>
      <Toolbar actions={actions} />
      <AddPhotosModal isOpen={isModalOpen} onClose={closeModal} projectId={projectId} siteId={siteId} assetId={assetId} entityId={entityId} entityCategory={entityCategory} />
      <PhotoGrid onSelect={onSelect} assetId={assetId} entityId={entityId} />
    </div>
  );
};

export default createStyledComponent(memo(PhotoListing), photoListingStyle);
