/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import CircularProgress from "@mui/material/CircularProgress";
import { Project } from "app/domain/projectSites/index";
import QaErrorService from "app/service/qaError/qaErrorService";
import React, { FC, useEffect, useState } from "react";
import { useAsync } from "react-async";
import CheckboxTree from "react-checkbox-tree";
import styled from "styled-components";

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 45%;
`;

// eslint-disable-next-line @typescript-eslint/ban-types
const ProjectsSitesTreeView: FC<{ checked: string[]; setChecked: Function }> = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [expanded, setExpanded] = useState<string[]>([]);
  const [areDataLoading, setAreDataLoading] = useState(false);

  const [nodes, setNodes] = useState<Project[]>([]);

  const fetchProjectSites = (): Promise<Project[]> => {
    setAreDataLoading(true);
    return QaErrorService.getProjectSites();
  };

  const handleSuccess = (fetchedProjectSites: Project[]): void => {
    setNodes(fetchedProjectSites);
    setAreDataLoading(false);
  };

  const { run } = useAsync({
    deferFn: fetchProjectSites,
    onResolve: handleSuccess
  });

  useEffect(() => {
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerCheck = (siteIds: string[]): void => {
    props.setChecked(siteIds);
  };

  const handlerExpand = (projectIds: string[]): void => {
    setExpanded(projectIds);
  };

  return (
    <div style={{ minHeight: "350px", maxHeight: "350px", overflow: "auto" }}>
      <div style={{ display: areDataLoading ? "block" : "none", minHeight: "350px", position: "relative", width: "500px" }}>
        <StyledCircularProgress />
      </div>
      <div style={{ display: areDataLoading ? "none" : "block" }}>
        <CheckboxTree nodes={nodes} checked={props.checked} expanded={expanded} onCheck={handlerCheck} onExpand={handlerExpand} />
      </div>
    </div>
  );
};

export default ProjectsSitesTreeView;
