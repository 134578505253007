import { SystemModel } from "app/domain/system";
import { Uniformat, UniformatLevel1 } from "app/domain/uniformat";
import SystemModelActionMapper, { SystemModelActionDTO } from "app/service/system/systemModelActionMapper";

export type SystemModelDTO = {
  id: string;
  uniformat1: string;
  uniformat2: string;
  uniformat3: string;
  uniformat4: string;
  name: string;
  system_model_actions: SystemModelActionDTO[];
};

class SystemModelMapper {
  static fromSystemModelDTO(systemModelDTO: SystemModelDTO): SystemModel;
  static fromSystemModelDTO(systemModelDTOs: SystemModelDTO[]): SystemModel[];
  static fromSystemModelDTO(systemModelDTOs: SystemModelDTO | SystemModelDTO[]): SystemModel | SystemModel[] {
    if (!Array.isArray(systemModelDTOs)) {
      return new SystemModel({
        id: systemModelDTOs.id,
        uniformat: new Uniformat({
          level1: systemModelDTOs.uniformat1 as UniformatLevel1,
          level2: systemModelDTOs.uniformat2,
          level3: systemModelDTOs.uniformat3,
          level4: systemModelDTOs.uniformat4
        }),
        name: systemModelDTOs.name,
        actions: SystemModelActionMapper.fromSystemModelActionDTO(systemModelDTOs.system_model_actions)
      });
    }
    return systemModelDTOs.map((systemModelDTO: SystemModelDTO): SystemModel => this.fromSystemModelDTO(systemModelDTO));
  }
}

export default SystemModelMapper;
