import React, { useEffect, useState } from "react";

type TextAreaHook = {
  text: string;
  onInput: (event: React.FormEvent<HTMLTextAreaElement>) => void;
};

const useTextArea = (text: string): TextAreaHook => {
  const [currentText, setCurrentText] = useState(text);

  useEffect(() => {
    setCurrentText(text);
  }, [text]);

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>): void => {
    event.preventDefault();

    const newText = event.currentTarget.value;
    setCurrentText(newText);
  };

  return { text: currentText, onInput: handleInput };
};

export default useTextArea;
