import AuditProgress from "app/domain/asset/auditProgress";
import { Action, PayloadAction } from "app/store/types";

export enum AuditProgressActionTypes {
  GET_AUDIT_PROGRESS = "GET_AUDIT_PROGRESS",
  GET_AUDIT_PROGRESS_REQUEST = "GET_AUDIT_PROGRESS_REQUEST",
  GET_AUDIT_PROGRESS_SUCCESS = "GET_AUDIT_PROGRESS_SUCCESS",
  GET_AUDIT_PROGRESS_ERROR = "GET_AUDIT_PROGRESS_ERROR",
  GET_ALL_AUDIT_PROGRESSES = "GET_ALL_AUDIT_PROGRESSES",
  GET_ALL_AUDIT_PROGRESSES_REQUEST = "GET_ALL_AUDIT_PROGRESSES_REQUEST",
  GET_ALL_AUDIT_PROGRESSES_SUCCESS = "GET_ALL_AUDIT_PROGRESSES_SUCCESS",
  GET_ALL_AUDIT_PROGRESSES_ERROR = "GET_ALL_AUDIT_PROGRESSES_ERROR",
  UPDATE_AUDIT_PROGRESS = "UPDATE_AUDIT_PROGRESS",
  UPDATE_AUDIT_PROGRESS_REQUEST = "UPDATE_AUDIT_PROGRESS_REQUEST",
  UPDATE_AUDIT_PROGRESS_SUCCESS = "UPDATE_AUDIT_PROGRESS_SUCCESS",
  UPDATE_AUDIT_PROGRESS_ERROR = "UPDATE_AUDIT_PROGRESS_ERROR",
  RESET_AUDIT_PROGRESS = "RESET_AUDIT_PROGRESS"
}

export type GetAuditProgressAction = PayloadAction<
  AuditProgressActionTypes.GET_AUDIT_PROGRESS,
  {
    assetId: string;
  }
>;

export type GetAuditProgressRequestAction = PayloadAction<
  AuditProgressActionTypes.GET_AUDIT_PROGRESS_REQUEST,
  {
    assetId: string;
  }
>;

export type GetAuditProgressSuccessAction = PayloadAction<
  AuditProgressActionTypes.GET_AUDIT_PROGRESS_SUCCESS,
  {
    auditProgress: AuditProgress;
  }
>;

export type GetAuditProgressErrorAction = PayloadAction<AuditProgressActionTypes.GET_AUDIT_PROGRESS_ERROR, { errorMessage: string }>;

export type GetAllAuditProgressesAction = Action<AuditProgressActionTypes.GET_ALL_AUDIT_PROGRESSES>;

export type GetAllAuditProgressesRequestAction = Action<AuditProgressActionTypes.GET_ALL_AUDIT_PROGRESSES_REQUEST>;

export type GetAllAuditProgressesSuccessAction = PayloadAction<
  AuditProgressActionTypes.GET_ALL_AUDIT_PROGRESSES_SUCCESS,
  {
    auditProgresses: AuditProgress[];
  }
>;

export type GetAllAuditProgressesErrorAction = PayloadAction<AuditProgressActionTypes.GET_ALL_AUDIT_PROGRESSES_ERROR, { errorMessage: string }>;

export type UpdateAuditProgressAction = PayloadAction<
  AuditProgressActionTypes.UPDATE_AUDIT_PROGRESS,
  {
    auditProgress: AuditProgress;
  }
>;

export type UpdateAuditProgressRequestAction = PayloadAction<
  AuditProgressActionTypes.UPDATE_AUDIT_PROGRESS_REQUEST,
  {
    auditProgress: AuditProgress;
  }
>;

export type UpdateAuditProgressSuccessAction = PayloadAction<
  AuditProgressActionTypes.UPDATE_AUDIT_PROGRESS_SUCCESS,
  {
    auditProgress: AuditProgress;
  }
>;

export type UpdateAuditProgressErrorAction = PayloadAction<AuditProgressActionTypes.UPDATE_AUDIT_PROGRESS_ERROR, { errorMessage: string }>;

export type ResetAction = Action<AuditProgressActionTypes.RESET_AUDIT_PROGRESS>;
