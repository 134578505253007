import React from "react";
import "../../popUp/popUp.css";
import { Form, Button } from "semantic-ui-react";
import { Requirement } from "app/domain/requirement";
import { generateRequirementCopiesAction } from "app/store/requirement/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IdGenerator from "app/utils/idGenerator";
import { CostLine } from "app/domain/costItem";
const TO_VERIFY_IS_TRUE = 1;

class CopyRequirementForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nbCopy: 0,
      listDuplicatedRequirements: [],
    };
  }

  generateListDuplicatedRequirements(nbCopy, values) {
    let list = this.state.listDuplicatedRequirements
      ? this.state.listDuplicatedRequirements
      : [];
    if (nbCopy < 0) {
      for (var i = 0; i < Math.abs(nbCopy); i++) {
        list.pop();
      }
    }

    const copyCostLines = values.costLines
      ? values.costLines.map(
          (costLine) =>
            new CostLine({
              costItemId: costLine.costItemId,
              quantity: costLine.quantity,
            })
        )
      : [];

    for (var i = 0; i < nbCopy; i++) {
      const requirement = new Requirement({
        ...values,
        id: IdGenerator.generate(),
        toVerify: TO_VERIFY_IS_TRUE,
        costLines: copyCostLines,
        locals: [],
      });
      list.push(requirement);
    }
    return list;
  }
  handleComponentChange = (event, data, index) => {
    let updatedListDuplicatedRequirements = this.state
      .listDuplicatedRequirements
      ? this.state.listDuplicatedRequirements
      : [];
    updatedListDuplicatedRequirements[index][data.name] = data.value;
    this.setState({
      listDuplicatedRequirements: updatedListDuplicatedRequirements,
    });
  };

  handleAssetChange = (event, data, index) => {
    let updatedListDuplicatedRequirements = this.state
      .listDuplicatedRequirements
      ? this.state.listDuplicatedRequirements
      : [];
    updatedListDuplicatedRequirements[index][data.name] = data.value;
    this.setState({
      listDuplicatedRequirements: updatedListDuplicatedRequirements,
    });
  };

  findComponentsAssociatedToAsset = (value) => {
    let componentsList = this.props.components
      ? this.props.components
          .filter((x) => String(x.assetId) === String(value))
          .map((x) => {
            return { text: x.custom_name, value: x.id, description: x.id };
          })
      : [];
    return componentsList;
  };

  nbCopyChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
    let nbExistingCopy = this.state.listDuplicatedRequirements
      ? this.state.listDuplicatedRequirements.length
      : 0;
    let nbCopy = event.target.value - nbExistingCopy;
    if (event.target.value > 0) {
      this.setState({
        listDuplicatedRequirements: this.generateListDuplicatedRequirements(
          nbCopy,
          this.props.fields
        ),
      });
    } else if (event.target.value <= 0) {
      this.setState({ listDuplicatedRequirements: [] });
    }
  };

  handleChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  closePopUp = () => {
    this.props.handleClose();
    this.setState({
      nbCopy: 0,
      listDuplicatedRequirements: [],
    });
  };

  handleFieldChange = (event, data, index) => {
    let updatedListDuplicatedRequirements = this.state
      .listDuplicatedRequirements
      ? this.state.listDuplicatedRequirements
      : [];
    updatedListDuplicatedRequirements[index][data.name] = data.value;
    this.setState({
      listDuplicatedRequirements: updatedListDuplicatedRequirements,
    });
  };

  generateCopy = () => {
    if (this.state.listDuplicatedRequirements) {
      this.props.actions.generateRequirementCopiesAction(
        this.props.requirementToBeCopiedId,
        this.state.listDuplicatedRequirements,
        this.props.assetId
      );
    }
    this.closePopUp();
    this.props.redirectToRequirementsPage();
  };

  render() {
    const showHideClassName = this.props.show
      ? "copyComponentForm"
      : "copyComponentForm display-none";
    const listDuplicatedRequirements = this.state.listDuplicatedRequirements
      ? this.state.listDuplicatedRequirements
      : [];
    const nbCopy = this.state.nbCopy;

    return (
      <div className={showHideClassName}>
        <div>
          <Form>
            <Form.Group>
              <Form.Input
                label="Nombre de copies"
                type="number"
                name="nbCopy"
                value={nbCopy}
                onChange={this.nbCopyChange}
              />
            </Form.Group>
            {listDuplicatedRequirements.map((requirement, index) => (
              <Form.Group key={index}>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  label="Actif parent :" // TODO: transform component in FC in order to use I18n translations
                  onChange={(e, data) => this.handleAssetChange(e, data, index)}
                  name="assetId"
                  value={requirement.assetId}
                  options={
                    this.props.listSiteAssets
                      ? this.props.listSiteAssets.map((x) => {
                          return {
                            key: "asset-" + x.id,
                            text: x.id,
                            value: x.id,
                            description: x.name,
                          };
                        })
                      : []
                  }
                />
                <Form.Dropdown
                  width={5}
                  required
                  label="Système associé"
                  selection
                  clearable
                  name="component_id"
                  value={requirement.component_id}
                  onChange={(e, data) =>
                    this.handleComponentChange(e, data, index)
                  }
                  placeholder="Système associé"
                  options={this.findComponentsAssociatedToAsset(
                    requirement.assetId
                  )}
                />
                <Form.Input
                  width={5}
                  label="Nom" // TODO: transform component in FC in order to use I18n translations
                  name="name"
                  value={requirement.name}
                  onChange={(e, data) => this.handleFieldChange(e, data, index)}
                />
                <Form.Input
                  width={5}
                  label="Nom de l'action" // TODO: transform component in FC in order to use I18n translations
                  name="actionName"
                  value={requirement.actionName}
                  onChange={(e, data) => this.handleFieldChange(e, data, index)}
                />
              </Form.Group>
            ))}
          </Form>
        </div>
        <div>
          <Button right onClick={(e) => this.generateCopy()}>
            Générer
          </Button>
          <Button right onClick={(e) => this.closePopUp()}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.requirements.isLoading,
    error: state.requirements.errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ generateRequirementCopiesAction }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyRequirementForm);
