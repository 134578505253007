import { SystemModel } from "app/domain/system";
import SystemService from "app/service/system/systemService";
import { call, put, takeLatest } from "redux-saga/effects";

import { getSystemModelsErrorAction, getSystemModelsRequestAction, getSystemModelsSuccessAction } from "./actions";
import { SystemModelActionTypes } from "./types";

export const GET_SYSTEM_MODELS_ERROR = "Une erreur s'est produite lors de la récupération des modèles de systèmes. Veuillez réessayer.";

export function* getSystemModels(): Generator {
  yield put(getSystemModelsRequestAction());
}

export function* getSystemModelsRequest(): Generator {
  try {
    const systemModels = yield call(SystemService.getSystemModels, " ", " ");
    yield put(getSystemModelsSuccessAction(systemModels as SystemModel[]));
  } catch (error) {
    yield put(getSystemModelsErrorAction(GET_SYSTEM_MODELS_ERROR));
  }
}

function* systemModelSaga(): Generator {
  yield takeLatest(SystemModelActionTypes.GET_SYSTEM_MODELS, getSystemModels);
  yield takeLatest(SystemModelActionTypes.GET_SYSTEM_MODELS_REQUEST, getSystemModelsRequest);
}

export default systemModelSaga;
