import { colors } from "ui/colors";

export const DEFAULT_FORM_CONTROL_TEXT_COLOR = "#000000de";

const DEFAULT_FORM_CONTROL_COLOR = "rgba(34, 36, 38, 0.15)";
export const DEFAULT_FORM_CONTROL_HOVER_COLOR = "rgba(34,36,38,.35)";
export const DEFAULT_FORM_CONTROL_FOCUS_COLOR = colors.ACCENT_BLUE;

export const DEFAULT_FORM_CONTROL_BORDER = `1px solid ${DEFAULT_FORM_CONTROL_COLOR}`;
export const DEFAULT_FORM_CONTROL_BORDER_RADIUS = "0.28571429rem";
