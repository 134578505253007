import { createAction } from "@reduxjs/toolkit";
import AuditProgress from "app/domain/asset/auditProgress";

import { AuditProgressActionTypes } from "./types";

export const getAuditProgressAction = createAction(AuditProgressActionTypes.GET_AUDIT_PROGRESS, (assetId: string) => ({
  payload: {
    assetId
  }
}));

export const getAuditProgressRequestAction = createAction(AuditProgressActionTypes.GET_AUDIT_PROGRESS_REQUEST, (assetId: string) => ({
  payload: {
    assetId
  }
}));
export const getAuditProgressSuccessAction = createAction(AuditProgressActionTypes.GET_AUDIT_PROGRESS_SUCCESS, (auditProgress: AuditProgress) => ({
  payload: {
    auditProgress
  }
}));

export const getAuditProgressErrorAction = createAction(AuditProgressActionTypes.GET_AUDIT_PROGRESS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const getAllAuditProgressesAction = createAction(AuditProgressActionTypes.GET_ALL_AUDIT_PROGRESSES);

export const getAllAuditProgressesRequestAction = createAction(AuditProgressActionTypes.GET_ALL_AUDIT_PROGRESSES_REQUEST);

export const getAllAuditProgressesSuccessAction = createAction(AuditProgressActionTypes.GET_ALL_AUDIT_PROGRESSES_SUCCESS, (auditProgresses: AuditProgress[]) => ({
  payload: {
    auditProgresses
  }
}));

export const getAllAuditProgressesErrorAction = createAction(AuditProgressActionTypes.GET_ALL_AUDIT_PROGRESSES_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const updateAuditProgressAction = createAction(AuditProgressActionTypes.UPDATE_AUDIT_PROGRESS, (auditProgress: AuditProgress) => ({
  payload: {
    auditProgress
  }
}));

export const updateAuditProgressRequestAction = createAction(AuditProgressActionTypes.UPDATE_AUDIT_PROGRESS_REQUEST, (auditProgress: AuditProgress) => ({
  payload: {
    auditProgress
  }
}));

export const updateAuditProgressSuccessAction = createAction(AuditProgressActionTypes.UPDATE_AUDIT_PROGRESS_SUCCESS, (auditProgress: AuditProgress) => ({
  payload: {
    auditProgress
  }
}));

export const updateAuditProgressErrorAction = createAction(AuditProgressActionTypes.UPDATE_AUDIT_PROGRESS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const resetAction = createAction(AuditProgressActionTypes.RESET_AUDIT_PROGRESS);
