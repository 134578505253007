import { AssetMinimalInfo } from "app/domain/asset";
import { Local } from "app/domain/local";
import { System } from "app/domain/system";
import { useI18n } from "app/locales";
import LocalService from "app/service/local/localService";
import { getAssetsMinimalInfo } from "app/store/asset/selectors";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { useAsync } from "react-async";
import { useSelector } from "react-redux";
import { Dropdown, DropdownItem } from "ui/form/controls/dropdown";
import { DropdownItem as DropdownItemInterface } from "ui/form/controls/dropdown/dropdown";

import { SystemCopyFields } from "../useSystemCopyTable";

interface AssetsDropdownProps extends ComponentProps {
  system: System;
  index: number;
  onUpdate: (systemCopyFields: SystemCopyFields, index: number) => void;
  onError: (errorMessage: string) => void;
  onSuccess: (locals: Local[]) => void;
}

const AssetsDropdown: FC<AssetsDropdownProps> = ({ system, index, onUpdate, onError, onSuccess }: AssetsDropdownProps) => {
  const { getAttributeLabel } = useI18n();
  const availableAssets = useSelector(getAssetsMinimalInfo);

  const fetchAssetLocals = ([assetId]: string[]): Promise<Local[]> => {
    return LocalService.getLocals(assetId);
  };

  const handleSuccess = (locals: Local[]): void => {
    onSuccess(locals);
  };

  const handleError = (): void => {
    onError(`Une erreur s'est produite lors de la récupération des locaux liés à l'actif. Veuillez réessayer`);
  };

  const { run } = useAsync({
    deferFn: fetchAssetLocals,
    onResolve: handleSuccess,
    onReject: handleError
  });

  const handleChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownItemInterface): void => {
    event.preventDefault();

    const updatedAssetId = data.value;
    const systemCopyFields = {
      assetId: updatedAssetId,
      locals: []
    };

    onUpdate(systemCopyFields, index);
    run(updatedAssetId);
  };

  return (
    <Dropdown label={getAttributeLabel("asset_id")} value={system.assetId} onChange={handleChange} placeholder={getAttributeLabel("asset_id")} data-test-id="dropdown">
      {availableAssets.map((asset: AssetMinimalInfo) => (
        <DropdownItem value={asset.id} label={`${asset.id} - ${asset.name}` as string} key={asset.id} />
      ))}
    </Dropdown>
  );
};

export default memo(AssetsDropdown);
