import Uniformat from "app/domain/uniformat/uniformat";
import { useUniformat } from "app/view/uniformat";
import UniformatSection from "app/view/uniformat/uniformatSection";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { css } from "styled-components";
import { Button } from "ui/button";
import { ErrorMessage, useErrorMessage } from "ui/errorMessage";
import { Modal } from "ui/modal";

const SUBMIT = "Appliquer";

const uniformatModalStyle = css`
  .modal-container {
    height: 400px;
    width: 85vw;
    max-width: 85vw;
    min-width: 85vw;
  }
`;

interface RequirementUniformatModalProps extends ComponentProps {
  projectId: string;
  isModalOpen: boolean;
  handleUniformat3Reset: (uniformat3: string) => void;
  onUpdate: (fields: any) => void;
  onClose: () => void;
  uniformat?: Uniformat;
}

const RequirementUniformatModal: FC<RequirementUniformatModalProps> = ({
  projectId,
  isModalOpen,
  handleUniformat3Reset,
  uniformat,
  onUpdate,
  onClose,
  className
}: RequirementUniformatModalProps) => {
  const { uniformat: currentUniformat, onUpdate: onUpdateCurrentUniformat } = useUniformat(uniformat as Uniformat);

  const { errorMessage: currentErrorMessage, onUpdate: onErrorMessageUpdate, onDismiss } = useErrorMessage();

  const handleClose = (): void => {
    onClose();
  };

  const applyUniformat = (): void => {
    onUpdate({ uniformat: currentUniformat });
    const a = currentUniformat;
    if ((!uniformat && currentUniformat.level3) || (uniformat && uniformat.level3 !== currentUniformat.level3)) {
      handleUniformat3Reset(currentUniformat.level3);
    }
    onClose();
  };

  return (
    <Modal
      title="Appliquer les informations liées à l'uniformat"
      isOpen={isModalOpen}
      className={className}
      content={
        <div>
          <ErrorMessage message={currentErrorMessage} onDismiss={onDismiss} data-test-id="errorMessage" />
          <UniformatSection
            projectId={projectId}
            uniformat={currentUniformat}
            onUpdate={onUpdateCurrentUniformat}
            onUpdateError={onErrorMessageUpdate}
            data-test-id="uniformatSection"
          />
        </div>
      }
      actions={[<Button title={SUBMIT} onClick={applyUniformat} key={0} data-test-id="applyUniformatActionButton" />]}
      onClose={handleClose}
      data-test-id="modal"
    />
  );
};

export default createStyledComponent(memo(RequirementUniformatModal), uniformatModalStyle);
