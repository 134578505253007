import "react-datepicker/dist/react-datepicker.css";
import "./App.css";

import env from "@beam-australia/react-env";
import ActionCable from "actioncable";
import { NEW_API_VERSION } from "app/service/common/api";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { css } from "styled-components";

import I18nProvider from "./locales/i18nProvider";
import LanguageProvider from "./locales/LanguageProvider";
import RouterProvider from "./routing/routerProvider";
import { Routes } from "./routing/Routes";
import ScrollToTop from "./routing/ScrollToTop";
import { persistor, store } from "./store/configureStore";
import ErrorMessageProvider from "./view/common/errorMessage/errorMessageProvider";
import { Header } from "./view/common/header";
import LoaderProvider from "./view/common/loader/loaderProvider";

const appStyle = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

type AppProps = ComponentProps;

const App: FC<AppProps> = ({ className }: AppProps) => {
  const cable = ActionCable.createConsumer(env("SOCKET_HOST"));

  moment.locale(window.navigator.language);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <RouterProvider>
            <I18nProvider>
              <LanguageProvider>
                <LoaderProvider>
                  <ErrorMessageProvider>
                    <ScrollToTop>
                      <div className={className}>
                        <Header onOpen={handleOpen} cable={cable} />
                        <Routes onClose={handleClose} menuIsOpen={isOpen} cable={cable} />
                        <ToastContainer limit={1} />
                      </div>
                    </ScrollToTop>
                  </ErrorMessageProvider>
                </LoaderProvider>
              </LanguageProvider>
            </I18nProvider>
          </RouterProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default createStyledComponent(App, appStyle);
