interface OptionProps {
  text: string;
  value: string;
  id?: number;
}

class Option {
  text: string;
  value: string;
  id?: number;

  constructor(optionProps: OptionProps) {
    this.text = optionProps.text;
    this.value = optionProps.value;
    this.id = optionProps.id;
  }
}

export default Option;
