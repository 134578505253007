import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, RefObject, useRef } from "react";
import { Card as SemanticCard, Popup } from "semantic-ui-react";
import { css } from "styled-components";
import { useContentOverflow } from "ui/hook";

import * as constants from "../constants";

export const imageCardStyle = css`
  &&& {
    margin: auto;
    overflow: hidden;
    transform: none;
    transition: none;
    width: ${constants.DEFAULT_CARD_WIDTH};
    height: ${constants.DEFAULT_CARD_HEIGHT};
    border-radius: ${constants.DEFAULT_CARD_BORDER_RADIUS};
    box-shadow: ${constants.DEFAULT_CARD_BOX_SHADOW};

    .image {
      height: 100%;
      border-radius: ${constants.DEFAULT_CARD_BORDER_RADIUS} !important;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .content {
      position: absolute;
      box-sizing: content-box;
      height: 20px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      border-top: none;
      border-radius: 0 0 ${constants.DEFAULT_CARD_BORDER_RADIUS} ${constants.DEFAULT_CARD_BORDER_RADIUS} !important;

      .description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:hover {
      transform: none;
      transition: none;
      border-radius: ${constants.DEFAULT_CARD_BORDER_RADIUS};
      box-shadow: ${constants.DEFAULT_CARD_BOX_SHADOW};
    }
  }
`;

// TODO: review description type (we need the JSX.Element to comply to the PhotoCard for the moment)
interface ImageCardProps extends ComponentProps {
  src: string;
  description: string | JSX.Element;
}

const ImageCard: FC<ImageCardProps> = ({ src, description, className }: ImageCardProps) => {
  const descriptionReference = useRef(null);
  const { isOverflowing: isDescriptionOverflowing } = useContentOverflow(descriptionReference);

  return (
    <SemanticCard className={className}>
      <div
        data-test-id="image"
        className="image"
        style={{
          backgroundImage: `url(${src})`
        }}
      />
      <Popup
        trigger={
          <SemanticCard.Content data-test-id="content">
            <div ref={descriptionReference as RefObject<HTMLDivElement>} className="description" data-test-id="description">
              {description}
            </div>
          </SemanticCard.Content>
        }
        content={description}
        position="bottom left"
        disabled={!isDescriptionOverflowing}
        data-test-id="tooltip"
      />
    </SemanticCard>
  );
};

export default createStyledComponent(memo(ImageCard), imageCardStyle);
