import Calculation from "app/domain/calculation/calculation";
import { Error, State } from "app/domain/common";
import { CostLine } from "app/domain/costItem";
import { Local } from "app/domain/local";
import { Uniformat } from "app/domain/uniformat";

interface SystemProps {
  id: string;
  uniformat: Uniformat;
  state: State;
  toVerify: boolean;
  isVerified: boolean;
  totalCost: number;
  errors: Error[];
  reference?: string;
  height?: number;
  unitOfMeasure?: string;
  unitOfMeasureFactor?: number;
  installationYear?: number;
  public_notes?: string;
  is_installation_year_estimated?: boolean;
  lifetime?: number;
  conditionGrading?: string;
  conditionGradingAfterRepair?: string;
  ageRange?: string;
  auditor?: string;
  auditDate?: string;
  created_hour?: Date;
  comment?: string;
  actionType?: string;
  unitCost?: number;
  model_number?: string;
  systemModelId?: string;
  systemModelActionId?: string;
  estimated_lifetime?: number;
  renewalPercentage?: number;
  patrimony_factor?: number;
  updated_by?: string;
  usefulLife?: number;
  complexity?: number;
  removal?: number;
  has_qr_code_been_generated?: boolean;
  locals?: Local[];
  note?: string;
  quantity?: number;
  name?: string;
  custom_name?: string;
  custom_name_concat?: string;
  identification?: string;
  description?: string;
  assetId?: string;
  localisation?: string;
  isLinkedToArea: boolean;
  numberOfPhotos: number;
  isIdentificationInName: boolean;
  isLocalNumberInName: boolean;
  isLocalisationInName: boolean;
  isInstallationYearInName: boolean;
  status?: number;
  costLines?: CostLine[];
  manufacturerId?: number;
  model?: string;
  serialNumber?: string;
  calculations?: Calculation[];
  customerReference?: string;
  commentsCount: number;
  category?: string;
  renewalDescription?: string;
  actionDescription?: string;
  update?: number;
  reason?: number;
  isAutoUsefulLifeDisabled?: boolean;
}

class System {
  id: string;
  uniformat: Uniformat;
  state: State;
  isVerified: boolean;
  toVerify: boolean;
  totalCost: number;
  errors: Error[];
  reference?: string;
  height?: number;
  unitOfMeasure?: string;
  unitOfMeasureFactor?: number;
  installationYear?: number;
  public_notes?: string;
  is_installation_year_estimated?: boolean;
  lifetime?: number;
  conditionGrading?: string;
  conditionGradingAfterRepair?: string;
  ageRange?: string;
  auditor?: string;
  auditDate?: string;
  created_hour?: Date;
  comment?: string;
  actionType?: string;
  unitCost?: number;
  model_number?: string;
  systemModelId?: string;
  systemModelActionId?: string;
  estimated_lifetime?: number;
  renewalPercentage?: number;
  patrimony_factor?: number;
  updated_by?: string;
  usefulLife?: number;
  complexity?: number;
  removal?: number;
  has_qr_code_been_generated?: boolean;
  locals?: Local[];
  note?: string;
  quantity?: number;
  name?: string;
  custom_name?: string;
  custom_name_concat?: string;
  identification?: string;
  description?: string;
  site_id?: string;
  assetId?: string;
  localisation?: string;
  isLinkedToArea: boolean;
  numberOfPhotos: number;
  isIdentificationInName: boolean;
  isLocalNumberInName: boolean;
  isLocalisationInName: boolean;
  isInstallationYearInName: boolean;
  status?: number;
  costLines?: CostLine[];
  manufacturerId?: number;
  model?: string;
  serialNumber?: string;
  calculations?: Calculation[];
  customerReference?: string;
  commentsCount: number;
  category?: string;
  renewalDescription?: string;
  actionDescription?: string;
  update?: number;
  reason?: number;
  isAutoUsefulLifeDisabled?: boolean;

  constructor(systemProps: SystemProps) {
    this.id = systemProps.id;
    this.uniformat = systemProps.uniformat;
    this.state = systemProps.state;
    this.toVerify = systemProps.toVerify;
    this.isVerified = systemProps.isVerified;
    this.totalCost = systemProps.totalCost;
    this.errors = systemProps.errors;
    this.reference = systemProps.reference;
    this.height = systemProps.height;
    this.unitOfMeasure = systemProps.unitOfMeasure;
    this.unitOfMeasureFactor = systemProps.unitOfMeasureFactor;
    this.installationYear = systemProps.installationYear;
    this.public_notes = systemProps.public_notes;
    this.is_installation_year_estimated = systemProps.is_installation_year_estimated;
    this.lifetime = systemProps.lifetime;
    this.conditionGrading = systemProps.conditionGrading;
    this.conditionGradingAfterRepair = systemProps.conditionGradingAfterRepair;
    this.ageRange = systemProps.ageRange;
    this.auditor = systemProps.auditor;
    this.auditDate = systemProps.auditDate;
    this.created_hour = systemProps.created_hour;
    this.comment = systemProps.comment;
    this.actionType = systemProps.actionType;
    this.unitCost = systemProps.unitCost;
    this.model_number = systemProps.model_number;
    this.systemModelId = systemProps.systemModelId;
    this.systemModelActionId = systemProps.systemModelActionId;
    this.estimated_lifetime = systemProps.estimated_lifetime;
    this.renewalPercentage = systemProps.renewalPercentage;
    this.patrimony_factor = systemProps.patrimony_factor;
    this.updated_by = systemProps.updated_by;
    this.usefulLife = systemProps.usefulLife;
    this.complexity = systemProps.complexity;
    this.removal = systemProps.removal;
    this.has_qr_code_been_generated = systemProps.has_qr_code_been_generated;
    this.locals = systemProps.locals;
    this.note = systemProps.note;
    this.quantity = systemProps.quantity;
    this.name = systemProps.name;
    this.custom_name = systemProps.custom_name;
    this.custom_name_concat = systemProps.custom_name_concat;
    this.identification = systemProps.identification;
    this.description = systemProps.description;
    this.assetId = systemProps.assetId;
    this.localisation = systemProps.localisation;
    this.isLinkedToArea = systemProps.isLinkedToArea;
    this.numberOfPhotos = systemProps.numberOfPhotos;
    this.isIdentificationInName = systemProps.isIdentificationInName;
    this.isLocalNumberInName = systemProps.isLocalNumberInName;
    this.isLocalisationInName = systemProps.isLocalisationInName;
    this.isInstallationYearInName = systemProps.isInstallationYearInName;
    this.status = systemProps.status;
    this.costLines = systemProps.costLines;
    this.manufacturerId = systemProps.manufacturerId;
    this.model = systemProps.model;
    this.serialNumber = systemProps.serialNumber;
    this.calculations = systemProps.calculations;
    this.customerReference = systemProps.customerReference;
    this.commentsCount = systemProps.commentsCount;
    this.category = systemProps.category;
    this.renewalDescription = systemProps.renewalDescription;
    this.actionDescription = systemProps.actionDescription;
    this.update = systemProps.update;
    this.reason = systemProps.reason;
    this.isAutoUsefulLifeDisabled = systemProps.isAutoUsefulLifeDisabled;
  }
}

export default System;
