import { CostLine } from "app/domain/costItem";
import CostDataContainer from "app/view/costItem/costDataContainer";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { forwardRef, memo, RefObject } from "react";

interface CostLinesInformationProps extends ComponentProps {
  onAdd: (newCostLine: CostLine) => void;
  onUpdate: (costLineId: number, costLinesQuantity: number) => void;
  onDelete: (costLineId: number) => void;
  selectedCostLines: CostLine[];
  systemId: string;
}

const CostLinesInformation = forwardRef(
  ({ onAdd, onUpdate, onDelete, selectedCostLines, systemId }: CostLinesInformationProps, ref): JSX.Element => (
    <div>
      <h3 ref={ref as RefObject<any>}>
        <span>4.</span> Informations des lignes de coût
      </h3>
      <CostDataContainer onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} selectedCostLines={selectedCostLines} systemId={systemId} />
    </div>
  )
);

CostLinesInformation.displayName = "CostLinesInformation";

export default memo(CostLinesInformation);
