import api from "./api";
import { NEW_API_VERSION } from "./common/api";

class ElementsListsAPI {
  async getAssetElementsLists() {
    const url = `${NEW_API_VERSION}/assets/options`;
    const { data } = await api.get(url, {}, {});
    return data;
  }

  async getComponentElementsLists() {
    const url = `${NEW_API_VERSION}/systems/options`;
    const { data } = await api.get(url, {}, {});

    return data;
  }
}

export default new ElementsListsAPI();
