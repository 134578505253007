/* eslint-disable @typescript-eslint/ban-ts-comment */
import Report from "app/domain/report/report";
import ReportService from "app/service/report/reportService";
import FileDownloader from "app/utils/fileDownloader";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  generateTemplateReportErrorAction,
  generateTemplateReportRequestAction,
  generateTemplateReportSuccessAction,
  importDataErrorAction,
  importDataRequestAction,
  importDataSuccessAction
} from "./reportActions";
import { GenerateTemplateReportAction, GenerateTemplateReportRequestAction, ImportDataAction, ImportDataRequestAction, ReportActionTypes } from "./reportTypes";

export const GENERATE_REPORT_ERROR = "Une erreur s'est produite lors de la génération du rapport excel. Veuillez réessayer.";

export const IMPORT_DATA_ERROR = "Une erreur s'est produite lors de l'importation des données. Veuillez réessayer.";

export function* generateTemplateReport(action: GenerateTemplateReportAction): Generator {
  yield put(generateTemplateReportRequestAction());
}

export function* generateTemplateReportRequest(action: GenerateTemplateReportRequestAction): Generator {
  try {
    const report = (yield call(ReportService.generateReportTemplate)) as Report;

    yield call(FileDownloader.download, report.path, report.fileName);

    yield put(generateTemplateReportSuccessAction());
  } catch (error) {
    yield put(generateTemplateReportErrorAction(GENERATE_REPORT_ERROR));
  }
}

export function* importData(action: ImportDataAction): Generator {
  yield put(importDataRequestAction(action.payload.report));
}

export function* importDataRequest(action: ImportDataRequestAction): Generator {
  try {
    yield call(ReportService.importData, action.payload.report);
  } catch (error) {
    yield put(importDataErrorAction(IMPORT_DATA_ERROR));
  }
}

function* reportSaga(): Generator {
  yield takeLatest(ReportActionTypes.GENERATE_TEMPLATE_REPORT, generateTemplateReport);
  yield takeLatest(ReportActionTypes.GENERATE_TEMPLATE_REPORT_REQUEST, generateTemplateReportRequest);
  yield takeLatest(ReportActionTypes.IMPORT_DATA, importData);

  yield takeLatest(ReportActionTypes.IMPORT_DATA_REQUEST, importDataRequest);
}

export default reportSaga;
