import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MaterialSelect, { SelectChangeEvent } from "@mui/material/Select";
import { ComponentProps } from "common/style/createStyledComponent";
import React from "react";
import Styled from "styled-components";

interface SelectProps extends ComponentProps {
  options: { key: string; value: string; text: string }[];
  value: string;
  name: string;
  helperText?: string;
  onChange: (event: SelectChangeEvent) => void;
}

const StyledFormControl = Styled(FormControl)`
  width: 200px !important;
  margin-bottom: 15px !important;
`;

const Select: React.FC<SelectProps> = ({ options, onChange, value, name, helperText }: SelectProps) => {
  return (
    <StyledFormControl>
      <InputLabel id="simple-select-standard-label0">{name}</InputLabel>
      <MaterialSelect variant="standard" value={value} label="Age" onChange={onChange}>
        {options.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </MaterialSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  );
};

export default Select;
