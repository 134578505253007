import { System } from "app/domain/system";
import { useRouter } from "app/routing/routerProvider";
import { getCurrentAsset } from "app/store/asset/selectors";
import { getLocalsAction } from "app/store/local/actions";
import { getUserName } from "app/store/login/selectors";
import { addSystemAction, getSystemOptionsAction } from "app/store/system/actions";
import {
  getNewSystemId,
  shouldRedirectToModifySystem as shouldRedirectToModifySystemSelector,
  shouldRedirectToSystems as shouldRedirectToSystemsSelector
} from "app/store/system/selectors";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { useLoader } from "app/view/common/loader/loaderProvider";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { Loader } from "ui/loader";

import { SystemForm } from "./systemForm";

const createSystemContainerStyle = css`
  position: relative;
  flex: 1;
`;

type CreateSystemContainerProps = ComponentProps;

const CreateSystemContainer: FC<CreateSystemContainerProps> = ({ className }: CreateSystemContainerProps) => {
  const [createAttempt, setCreateAttempt] = useState(false);
  const { getStateParam } = useRouter();
  const { isActive } = useLoader();
  const { errorMessage, onDismiss, onUpdate } = useErrorMessage();

  const currentAsset = useSelector(getCurrentAsset);
  const currentUser = useSelector(getUserName);
  const newSystemId = useSelector(getNewSystemId);

  const shouldRedirectToSystems = useSelector(shouldRedirectToSystemsSelector);
  const shouldRedirectToModifySystem = useSelector(shouldRedirectToModifySystemSelector);

  const dispatch = useDispatch();

  const projectId = getStateParam("projectId");
  const siteId = getStateParam("siteId");
  const assetId = getStateParam("assetId");

  useEffect(() => {
    dispatch(getSystemOptionsAction());
    dispatch(getLocalsAction(assetId));

    return (): void => {
      onDismiss();
    };
  }, [dispatch, onDismiss, assetId]);

  const saveSystem = (system: System, isClosing: boolean): void => {
    const updatedSystem = {
      ...system,
      assetId,
      site_id: undefined,
      floor_id: undefined,
      quantity: system.quantity ? system.quantity : 0,
      updated_by: currentUser
    };

    dispatch(addSystemAction(updatedSystem, isClosing));
    setCreateAttempt(true);
  };

  const verifyCreateAttempt = (): void => {
    if (errorMessage === "" && createAttempt) {
      setCreateAttempt(false);
    }
  };

  return (
    <div className={className}>
      {verifyCreateAttempt()}
      <Loader isActive={isActive} />
      <SystemForm
        onConfirm={saveSystem}
        projectId={projectId}
        siteId={siteId}
        assetId={assetId}
        isElementInCreationMode
        errorMessage={errorMessage}
        onReset={onDismiss}
        onUpdate={onUpdate}
        shouldRedirectToComponentsPage={shouldRedirectToSystems}
        shouldRedirectToModifySystem={shouldRedirectToModifySystem}
        auditDate={currentAsset && currentAsset.auditDate}
        installationYear={currentAsset?.year_constructed?.toString()}
        newSystemId={newSystemId}
      />
    </div>
  );
};

export default createStyledComponent(memo(CreateSystemContainer), createSystemContainerStyle);
