import Asset from "app/domain/asset/asset";
import { resetAction, updateAssetAction } from "app/store/asset/actions";
import { getCurrentAsset } from "app/store/asset/selectors";
import { Toolbar } from "app/view/common/toolbar";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { Button } from "ui/button";
import { Form, useForm } from "ui/form";
import { TextArea } from "ui/form/controls";

import { EntityCategory } from "../../../../domain/entityCategory";

export const assetDescriptionTextAreaName = "assetDescription";
const SAVE = "Enregistrer";

const assetDescriptionFormStyle = css`
  > *:not(:last-child) {
    margin-bottom: 1em;
  }
`;

type AssetDescriptionFormProps = ComponentProps;

const AssetDescriptionForm: FC<AssetDescriptionFormProps> = ({ className }: AssetDescriptionFormProps) => {
  const formReference = useRef(null);
  const { formContext, onFormSubmit } = useForm(formReference);

  const dispatch = useDispatch();

  const asset = useSelector(getCurrentAsset);

  const handleSubmission = (): void => {
    dispatch(resetAction());

    const assetDescription = formContext.getValue(assetDescriptionTextAreaName) as string;

    if (asset?.id) {
      dispatch(updateAssetAction({ ...asset, description: assetDescription } as Asset, EntityCategory.ASSET, asset.id));
    }
  };

  const actions = [<Button submit title={SAVE} onClick={onFormSubmit} key={0} />];

  return (
    <Form context={formContext} onSubmit={handleSubmission} ref={formReference} className={className}>
      <Toolbar actions={actions} />
      <TextArea text={(asset as Asset).description ? ((asset as Asset).description as string) : ""} name={assetDescriptionTextAreaName} />
    </Form>
  );
};

export default createStyledComponent(AssetDescriptionForm, assetDescriptionFormStyle);
