import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Photo from "app/domain/photo/photo";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo, RefObject, useEffect, useRef, useState } from "react";

interface CarouselProps extends ComponentProps {
  currentImageIndex: number;
  handleClose: () => void;
  images: Photo[];
  isOpen: boolean;
  onPreviousPhoto: () => void;
  onNextPhoto: () => void;
}

const Carousel: FC<CarouselProps> = ({ currentImageIndex, handleClose, images, isOpen, onPreviousPhoto, onNextPhoto }: CarouselProps) => {
  const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] = useState(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  useEffect(() => {
    setIsNextButtonDisabled(false);
    setIsPreviousButtonDisabled(false);
    if (currentImageIndex === images.length - 1) {
      setIsNextButtonDisabled(true);
    }
    if (currentImageIndex === 0) {
      setIsPreviousButtonDisabled(true);
    }
  }, [images, currentImageIndex]);

  return (
    <div>
      <Dialog fullScreen open={isOpen} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }} color="inherit">
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {images && images.length > 0 ? images[currentImageIndex].name : ""}
            </Typography>
            <IconButton name="previous" aria-label="previous" size="large" onClick={onPreviousPhoto} disabled={isPreviousButtonDisabled}>
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
            <IconButton name="next" aria-label="next" size="large" onClick={onNextPhoto} disabled={isNextButtonDisabled}>
              <ArrowForwardIcon fontSize="inherit" />
            </IconButton>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <img src={images && images.length > 0 ? images[currentImageIndex].src : ""} alt="someImage" />
      </Dialog>
    </div>
  );
};

export default Carousel;
