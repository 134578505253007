import SearchItem from "app/domain/search/searchItem";
import { Action, PayloadAction } from "app/store/types";

export enum SearchActionTypes {
  GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS",
  GET_SEARCH_RESULTS_REQUEST = "GET_SEARCH_RESULTS_REQUEST",
  GET_SEARCH_RESULTS_SUCCESS = "GET_SEARCH_RESULTS_SUCCESS",
  GET_SEARCH_RESULTS_ERROR = "GET_SEARCH_RESULTS_ERROR",
  RESET_SEARCH = "RESET_SEARCH"
}

export type GetSearchResultsAction = PayloadAction<
  SearchActionTypes.GET_SEARCH_RESULTS,
  {
    searchItem: SearchItem;
  }
>;

export type GetSearchResultsRequestAction = PayloadAction<
  SearchActionTypes.GET_SEARCH_RESULTS_REQUEST,
  {
    searchItem: SearchItem;
  }
>;

export type GetSearchResultsSuccessAction = PayloadAction<
  SearchActionTypes.GET_SEARCH_RESULTS_SUCCESS,
  {
    searchResults: SearchItem[];
  }
>;

export type GetSearchResultsErrorAction = PayloadAction<SearchActionTypes.GET_SEARCH_RESULTS_ERROR, string>;

export type ResetAction = Action<SearchActionTypes.RESET_SEARCH>;

export type SearchAction = GetSearchResultsAction | GetSearchResultsRequestAction | GetSearchResultsSuccessAction | GetSearchResultsErrorAction | ResetAction;
