import "antd/dist/antd.css";

import { Button, Checkbox, Col, DatePicker, Drawer, Form, Input, InputNumber, Row, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { Asset } from "app/domain/asset";
import moment from "moment";
import React, { useState } from "react";

interface NewAssetDrawerInterface {
  visible: boolean;
  asset: Asset;
  onSubmitValid: (asset: Asset) => void;
  onAbort: () => void;
}

const EditAssetDrawer: React.FC<NewAssetDrawerInterface> = ({ visible, asset, onSubmitValid, onAbort }: NewAssetDrawerInterface) => {
  const [form] = Form.useForm();
  const [isHeritage, setIsHeritage] = useState(asset.heritage);
  const MIN_REPLACEMENT_VALUE = 1.0;
  const MIN_AREA = 0;
  const MIN_YEAR = 0;
  const MAX_YEAR = new Date().getFullYear();

  const abort = (): void => {
    setIsHeritage(asset.heritage);
    onAbort();
  };

  const editedAsset = (): Asset => {
    return {
      ...asset,
      name: form.getFieldValue("name"),
      asset_number: form.getFieldValue("asset_number"),
      customerReference: form.getFieldValue("customerReference"),
      heritage: isHeritage,
      area: form.getFieldValue("area"),
      year_constructed: form.getFieldValue("year_constructed"),
      year_renovated: form.getFieldValue("year_renovated"),
      pastIVP: form.getFieldValue("pastIVP"),
      replacement_value: form.getFieldValue("replacement_value"),
      civic_number: form.getFieldValue("civic_number"),
      street_name: form.getFieldValue("street_name"),
      city: form.getFieldValue("city"),
      postal_code: form.getFieldValue("postal_code"),
      region: form.getFieldValue("region"),
      province: form.getFieldValue("province"),
      country: form.getFieldValue("country")
    };
  };

  const submitForm = (): void => {
    form.validateFields().then((value) => {
      if (!value.errorFields) {
        onSubmitValid(editedAsset());
        form.resetFields();
      }
    });
  };

  const setFormFields = (): void => {
    if (visible) {
      form.setFieldsValue(asset);
    } else {
      form.resetFields();
    }
  };

  const onHeritageChange = (e: CheckboxChangeEvent): void => {
    setIsHeritage(e.target.checked);
  };

  const getDisabledDate = (currenDate: moment.Moment): boolean => {
    return currenDate.get("year") < MIN_YEAR || currenDate.get("year") > MAX_YEAR;
  };

  return (
    <>
      <Drawer
        title="Modification d'un actif"
        width="50%"
        afterVisibleChange={setFormFields}
        onClose={abort}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={abort}>Annuler</Button>
            <Button onClick={submitForm} type="primary" htmlType="submit">
              Enregistrer
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form}>
          <h1>Informations actif</h1>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label="Nom" rules={[{ required: true, message: "Veuillez entrer le nom." }]}>
                <Input placeholder="Veuillez entrer le nom" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="asset_number" label="Numéro de l'actif" rules={[{ required: true, message: "Veuillez entrer le numéro de l'actif." }]}>
                <Input placeholder="Veuillez entrer le numéro de l'actif." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="customerReference" label="Référence client">
                <Input placeholder="Veuillez entrer la référence client." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="heritage">
                <Checkbox checked={isHeritage} onChange={onHeritageChange}>
                  Patrimonial
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="area" label="Superficie de l'actif">
                <InputNumber min={MIN_AREA} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="year_constructed" label="Année de construction">
                <DatePicker.YearPicker disabledDate={getDisabledDate} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="year_renovated" label="Année de rénovation">
                <DatePicker.YearPicker disabledDate={getDisabledDate} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="pastIVP" label="IVP avant audit">
                <InputNumber<string> min="0" max="10" step="0.000000000000001" stringMode />
              </Form.Item>
            </Col>
          </Row>

          <h1>Informations monétaires</h1>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="replacement_value" label="Valeur de remplacement">
                <InputNumber min={MIN_REPLACEMENT_VALUE} />
              </Form.Item>
            </Col>
          </Row>

          <h1>Localisation</h1>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="civic_number" label="Numéro civique">
                <Input placeholder="Veuillez entrer le numéro civique." />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="street_name" label="Rue">
                <Input placeholder="Veuillez entrer la rue." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="city" label="Ville">
                <Input placeholder="Veuillez entrer la ville." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="postal_code" label="Code postal">
                <Input placeholder="Veuillez entrer le code postal." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="region" label="Région">
                <Input placeholder="Veuillez entrer la région." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="province" label="Province">
                <Input placeholder="Veuillez entrer la province." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="country" label="Pays">
                <Input placeholder="Veuillez entrer le pays." />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default EditAssetDrawer;
