interface CostLineProps {
  costItemId: number;
  code: string;
  unitCost: number;
  description: string;
  className: string;
  unitOfMeasure: string;
  id?: number;
  quantity?: number;
  systemModelActionId?: string;
  uniformatLevel3?: string;
}

class CostLine {
  costItemId: number;
  code: string;
  unitCost: number;
  description: string;
  className: string;
  unitOfMeasure: string;
  id?: number;
  quantity?: number;
  systemModelActionId?: string;
  uniformatLevel3?: string;

  constructor(costLineProps: CostLineProps) {
    this.costItemId = costLineProps.costItemId;
    this.code = costLineProps.code;
    this.unitCost = costLineProps.unitCost;
    this.description = costLineProps.description;
    this.className = costLineProps.className;
    this.unitOfMeasure = costLineProps.unitOfMeasure;
    this.id = costLineProps.id;
    this.quantity = costLineProps.quantity;
    this.systemModelActionId = costLineProps.systemModelActionId;
    this.uniformatLevel3 = costLineProps.uniformatLevel3;
  }
}

export default CostLine;
