import { createAction } from "@reduxjs/toolkit";
import SystemModel from "app/domain/system/systemModel";

import { SystemModelActionTypes } from "./types";

export const getSystemModelsRequestAction = createAction(SystemModelActionTypes.GET_SYSTEM_MODELS_REQUEST);

export const getSystemModelsAction = createAction(SystemModelActionTypes.GET_SYSTEM_MODELS);

export const getSystemModelsSuccessAction = createAction(SystemModelActionTypes.GET_SYSTEM_MODELS_SUCCESS, (systemModels: SystemModel[]) => ({
  payload: {
    systemModels
  }
}));

export const getSystemModelsErrorAction = createAction(SystemModelActionTypes.GET_SYSTEM_MODELS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const resetAction = createAction(SystemModelActionTypes.RESET_SYSTEM_MODELS);
