/* eslint-disable array-callback-return, consistent-return, no-plusplus  */

import { Filter } from "app/domain/filter";
import { useRouter } from "app/routing/routerProvider";
import { setFiltersAction } from "app/store/filter/actions";
import { setSelectedValuesAction } from "app/store/filter/selectedValue/actions";
import { getFilters } from "app/store/filter/selectors";
import { State } from "app/store/state";
import { setFilteredSystemsAction } from "app/store/system/actions";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import isEqual from "react-fast-compare";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { useOpenNewTab } from "ui/hook";
import { Table, TableRowSelectionProps } from "ui/table/index";
import useFilters from "ui/table/quickFilterPanel/useFilters";
import { Column, RowData } from "ui/table/types";

import usePrevious from "../common/hook/usePrevious";

const roomTableStyle = css`
  && {
    @media screen and (max-width: 1200px) {
      font-size: 0.9em;
    }
  }
`;

interface RoomTableProps extends ComponentProps {
  onDataChange: (rowData: RowData[]) => void;
  currentData: RowData[];
  data: RowData[];
  rowSelectionProps: TableRowSelectionProps;
  selectedValues: string[];
}

const RoomTable: FC<RoomTableProps> = ({ onDataChange, rowSelectionProps, currentData, data, selectedValues, className }: RoomTableProps) => {
  const dispatch = useDispatch();
  const { location, goTo } = useRouter();
  const filteredRooms = useMemo(() => currentData.map((rowData: RowData) => rowData.id), [currentData]) as string[];
  const { newTab } = useOpenNewTab();
  const filters = useSelector((state: State): Filter[] => getFilters(state, "rooms"));

  const previousFilters = usePrevious(filters);
  const previousFilteredRooms = usePrevious(filteredRooms);

  const { onChange: onChangeFilter, onToggle: onToggleFilter, onDelete: onDeleteFilter, onReset: onResetFilters, filters: currentFilters } = useFilters(filters);

  const handleRowClick = useCallback((clickedRowData: any): void => goTo(`${location}/rooms/${clickedRowData.id}`, undefined, newTab), [goTo, location, newTab]);

  const roomsTotalArea = useMemo(() => {
    let total = 0;

    for (let index = 0; index < currentData.length; index += 1) {
      total += currentData[index].area.accessor;
    }
    return total;
  }, [currentData]);

  const columns = useMemo(
    () => [
      {
        Header: "Numéro de local",
        Footer: `Nombre de locaux sélectionnés: ${rowSelectionProps.selectedRowKeys.length} `,
        field: "number",
        select: true,
        sort: true,
        onClick: handleRowClick
      },
      {
        Header: "Superficie",
        Footer: `Nombre total de locaux: ${currentData.length} `,
        field: "area",
        sort: true,
        select: false
      },
      {
        Header: "Étage",
        Footer: "",
        field: "floorNumber",
        sort: true,
        select: true
      },
      {
        Header: "Description",
        Footer: `Superficie totale: ${roomsTotalArea.toFixed(2)} `,
        field: "description",
        sort: true,
        select: true
      }
    ],
    [roomsTotalArea, currentData.length, handleRowClick, rowSelectionProps.selectedRowKeys]
  ) as Column[];

  useEffect(() => {
    if (!isEqual(previousFilteredRooms, filteredRooms)) {
      dispatch(setFilteredSystemsAction(filteredRooms));
    }

    if (!isEqual(selectedValues, rowSelectionProps.selectedRowKeys)) {
      dispatch(setSelectedValuesAction(rowSelectionProps.selectedRowKeys));
    }

    if (!isEqual(previousFilters, currentFilters)) {
      dispatch(setFiltersAction("rooms", currentFilters));
    }
  }, [dispatch, previousFilters, currentFilters, currentData, previousFilteredRooms, filteredRooms, selectedValues, rowSelectionProps.selectedRowKeys]);

  return (
    <Table
      className={className}
      data={data}
      onDataChange={onDataChange}
      columns={columns}
      rowSelectionProps={rowSelectionProps}
      filters={currentFilters}
      onFilter={onChangeFilter}
      onToggleFilter={onToggleFilter}
      onDeleteFilter={onDeleteFilter}
      onResetFilters={onResetFilters}
    />
  );
};

export default createStyledComponent(memo(RoomTable), roomTableStyle);
