import env from "@beam-australia/react-env";
import { Cable } from "actioncable";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

import { LocalBanner, StagingBanner } from "./banners";
import Header from "./header";

export const headerContainerStyle = css`
  width: 100%;
  height: fit-content;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;

interface HeaderContainerProps extends ComponentProps {
  onOpen: () => void;
  cable: Cable;
}

const HeaderContainer: FC<HeaderContainerProps> = ({ onOpen, className, cable }: HeaderContainerProps) => (
  <div className={className}>
    <StagingBanner isVisible={env("API_HOST").includes("staging")} />
    <LocalBanner isVisible={env("API_HOST").includes("local.unifika.ca")} />
    <Header onOpen={onOpen} cable={cable} />
  </div>
);

export default createStyledComponent(HeaderContainer, headerContainerStyle);
