class FileConverter {
  static toDataURL = async (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (): void => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = (error): void => reject(error);
    });

  static toFile = (dataURL: string, mimeType: string, fileName?: string): Promise<File> =>
    fetch(dataURL)
      .then((response) => response.blob())
      .then((blob) => new File([blob], fileName || "", { type: mimeType }));
}

export default FileConverter;
