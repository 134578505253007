import { Asset } from "app/domain/asset";
import { formatArea } from "app/utils/formatter";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Form as SemanticForm } from "semantic-ui-react";

import { AssetFields } from "../../../useAsset";

interface SurfaceInformationProps extends ComponentProps {
  asset: Asset | undefined;
  onUpdate: (fields: AssetFields) => void;
}

const SurfaceInformation: FC<SurfaceInformationProps> = ({ asset, onUpdate }: SurfaceInformationProps) => {
  const handleChange = (event: any): void => {
    event.preventDefault();

    onUpdate({ [event.target.name]: event.target.value });
  };

  return (
    <>
      <SemanticForm.Group unstackable widths={4}>
        <SemanticForm.Input transparent label="Superficie à réaliser" name="area_to_inspect" value={asset && asset.areaToInspect ? formatArea(asset.areaToInspect) : ""} />
        <SemanticForm.Input transparent label="Superficie de base" name="surface_base" value={asset && asset.surface_base ? formatArea(asset.surface_base) : ""} />
      </SemanticForm.Group>
      <SemanticForm.Group unstackable widths={4}>
        <SemanticForm.Input transparent label="Ajustement de la superficie" name="surface_adjustment" value={asset && asset.surface_adjustment ? asset.surface_adjustment : ""} />
        <SemanticForm.Input transparent label="Dimension" name="dimension" value={asset && asset.dimension ? asset.dimension : ""} />
      </SemanticForm.Group>
      <SemanticForm.Input
        label="Précision concernant la superficie"
        name="comment_about_area"
        value={asset && asset.comment_about_area ? asset.comment_about_area : ""}
        onChange={handleChange}
      />
    </>
  );
};

export default SurfaceInformation;
