import { ComparisonError, ComparisonOperator, RequiredParamsBetweenError, RequiredParamsError } from "app/domain/common";

interface QaErrorProps {
  errorId: number;
  parentId: string;
  assetId: string;
  parentCategory: string;
  assetName: string;
  assetNumber: string;
  parentName: string;
  auditor: string;
  nbErrors: number;
  siteName: string;
  projectName: string;
  siteId: string;
  projectId: string;
  parentReference: string;
  ruleName: string;
  ruleContent: { operator: ComparisonOperator; first_param: string; second_param: string } | { params: string[] };
  errorMessage: ComparisonError | RequiredParamsBetweenError | RequiredParamsError;
  createdBy: string;
  justification?: string;
}

class QaError {
  errorId: number;
  parentId: string;
  assetId: string;
  parentCategory: string;
  assetName: string;
  assetNumber: string;
  parentName: string;
  auditor: string;
  nbErrors: number;
  siteName: string;
  projectName: string;
  siteId: string;
  projectId: string;
  parentReference: string;
  ruleName: string;
  ruleContent: { operator: ComparisonOperator; first_param: string; second_param: string } | { params: string[] };
  errorMessage: ComparisonError | RequiredParamsBetweenError | RequiredParamsError;
  createdBy: string;
  justification?: string;

  constructor(qaErrorProps: QaErrorProps) {
    this.errorId = qaErrorProps.errorId;
    this.parentId = qaErrorProps.parentId;
    this.assetId = qaErrorProps.assetId;
    this.parentCategory = qaErrorProps.parentCategory;
    this.assetName = qaErrorProps.assetName;
    this.assetNumber = qaErrorProps.assetNumber;
    this.parentName = qaErrorProps.parentName;
    this.auditor = qaErrorProps.auditor;
    this.nbErrors = qaErrorProps.nbErrors;
    this.siteName = qaErrorProps.siteName;
    this.projectName = qaErrorProps.projectName;
    this.siteId = qaErrorProps.siteId;
    this.projectId = qaErrorProps.projectId;
    this.parentReference = qaErrorProps.parentReference;
    this.ruleName = qaErrorProps.ruleName;
    this.ruleContent = qaErrorProps.ruleContent;
    this.errorMessage = qaErrorProps.errorMessage;
    this.createdBy = qaErrorProps.createdBy;
    this.justification = qaErrorProps.justification;
  }
}

export default QaError;
