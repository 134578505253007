// TODO: delete this file once every request type will be moved to app/service/common/api

import axios from "axios";
import qs from "qs";
import env from "@beam-australia/react-env";

const apiSettings = {
  host: env("API_HOST"),
  headers: {
    "Content-Type": "application/json",
  },
};

axios.defaults.baseURL = apiSettings.host;
axios.defaults.headers.common = apiSettings.headers


axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "repeat" });
};

export class Options {
  constructor({ baseURL, token, body, params, headers }) {
    this.baseURL = baseURL;
    this.token = token;
    this.body = body;
    this.params = params;
    this.headers = headers;
  }
}

class Api {
  static async get(path, options) {
    const config = await toAxiosConfig(options);
    return axios.get(path, config);
  }

  static async post(path, body) {
    return axios.post(path, body, {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
  }

  static async put(path, body) {
    return axios.put(path, body, {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
  }
}

const toAxiosConfig = async (options) => {
  const defaultHeaders = await buildDefaultHeaders();

  if (!options) {
    return {
      headers: defaultHeaders,
    };
  }
  const baseURL = options.baseURL ? { baseURL: options.baseURL } : {};
  const headers = await buildHeaders(options);

  return {
    ...baseURL,
    data: options.body,
    params: options.params,
    headers: { ...defaultHeaders, ...headers },
  };
};

const buildHeaders = async (options) => {
  options.token = localStorage.getItem("token");
  const tokenHeader = options.token ? { Authorization: options.token } : {};

  return { ...tokenHeader, ...options.headers };
};

const buildDefaultHeaders = async () => {
  return {
    Authorization: localStorage.getItem("token"),
    "Content-Type": "application/json",
  };
};

export default Api;
