import { SystemModel, SystemModelAction } from "app/domain/system";
import { useCallback, useMemo, useState } from "react";

type SystemModelHook = {
  currentModel: any;
  currentAction: any;
  onUpdateModel: (model: SystemModel) => void;
  onUpdateAction: (action: SystemModelAction) => void;
  onReset: () => void;
};

const useSystemModel = (): SystemModelHook => {
  const [currentModel, setCurrentModel] = useState({
    id: "",
    name: "",
    uniformat: {
      level2: "",
      level3: "",
      level4: ""
    },
    actions: []
  } as SystemModel);
  const [currentAction, setCurrentAction] = useState({
    id: "",
    systemModelId: "",
    actionType: "",
    modelNumber: "",
    renewalPercentage: 0,
    description: "",
    unitCost: 0,
    unitOfMeasure: "",
    lifetime: 0,
    comment: "",
    factor: 0,
    costLines: []
  } as SystemModelAction);

  const onUpdateModel = useCallback((model: SystemModel) => {
    setCurrentModel(model);
  }, []);

  const onUpdateAction = useCallback((action: SystemModelAction) => {
    setCurrentAction(action);
  }, []);

  const onReset = useCallback(() => {
    setCurrentModel({
      id: "",
      name: "",
      uniformat: {
        level2: "",
        level3: "",
        level4: ""
      },
      actions: []
    });
    setCurrentAction({
      id: "",
      systemModelId: "",
      actionType: "",
      modelNumber: "",
      renewalPercentage: 0,
      description: "",
      unitCost: 0,
      unitOfMeasure: "",
      lifetime: 0,
      comment: "",
      factor: 0,
      costLines: []
    });
  }, []);

  return useMemo(
    () => ({
      currentModel,
      currentAction,
      onUpdateModel,
      onUpdateAction,
      onReset
    }),
    [currentModel, currentAction, onUpdateModel, onUpdateAction, onReset]
  );
};

export default useSystemModel;
