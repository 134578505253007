/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */

import { Divider, MenuItem } from "@mui/material";
import { useRouter } from "app/routing/routerProvider";
import { logoutUserAction } from "app/store/login/actions";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { css } from "styled-components";
import { colors } from "ui/colors";
import { Menu } from "ui/menu";

const OPTIONS = "Options";
const UPLOAD_CUSTOMER_REFERENCES = "Mise à jour des références clients";
const LOGOUT = "Se déconnecter";

export const userProfileStyle = css`
  position: relative;

  .list {
    background-color: ${colors.SECONDARY_BLACK};
    border-left: 2px solid ${colors.ACCENT_BLUE};
    transition: opacity 0.3s ease;
    position: absolute;
    right: 0;
    width: max-content;

    ul {
      padding: 0;
      margin: 0;
      cursor: pointer;

      li {
        padding: 6px 10px;

        a {
          color: ${colors.PRIMARY_WHITE};
        }

        &:hover {
          background-color: ${colors.ACCENT_BLUE};
        }
      }
    }
  }
`;

type UserProfileProps = ComponentProps;

const UserProfile: FC<UserProfileProps> = () => {
  const { goTo } = useRouter();

  const dispatch = useDispatch();
  const [closeMenu, setCloseMenu] = useState(false);

  useEffect(() => {
    if (closeMenu) {
      setCloseMenu(false);
    }
  }, [closeMenu]);

  const redirectToImportCustomerReferencePage = (): void => {
    goTo("/importCustomerReference");
    setCloseMenu(true);
  };

  const handleLogout = useCallback(() => {
    dispatch(logoutUserAction());
    goTo("/");
  }, [dispatch, goTo]);

  return (
    <Menu label={OPTIONS} closeMenu={closeMenu} data-test-id="menu">
      <MenuItem onClick={redirectToImportCustomerReferencePage} data-test-id="importCustomerReferences">
        {UPLOAD_CUSTOMER_REFERENCES}
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogout} data-test-id="logoutLink">
        {LOGOUT}
      </MenuItem>
    </Menu>
  );
};

export default createStyledComponent(UserProfile, userProfileStyle);
