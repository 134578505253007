import { Error, State } from "app/domain/common";
import { CostLine } from "app/domain/costItem";
import { Uniformat } from "app/domain/uniformat";

import { RequirementType } from "./requirementType";

interface RequirementProps {
  id: string;
  uniformat: Uniformat;
  state: State;
  type: RequirementType;
  toVerify: boolean;
  isVerified: boolean;
  totalCost: number;
  errors: Error[];
  reference?: string;
  name?: string;
  category?: string;
  cause?: string;
  description?: string;
  assetId?: string;
  component_id?: string;
  locals?: string[];
  localisation?: string;
  localsArea?: number;
  priority?: string;
  yearPlanned?: number;
  complexity?: number;
  height?: number;
  removal?: number;
  activity?: string;
  comment?: string;
  auditor?: string;
  auditDate?: string;
  patrimony_factor?: number;
  problematics?: string[];
  items?: string[];
  actionDescription?: string;
  action_comment?: string;
  actionName?: string;
  actionNameSuffix?: string;
  date_finished?: string;
  update?: number;
  reason?: number;
  created_hour?: Date;
  costLines?: CostLine[];
  customerReference?: string;
  commentsCount?: number;
}

class Requirement {
  id: string;
  uniformat: Uniformat;
  state: State;
  type: RequirementType;
  toVerify: boolean;
  isVerified: boolean;
  totalCost: number;
  errors: Error[];
  reference?: string;
  name?: string;
  category?: string;
  cause?: string;
  description?: string;
  assetId?: string;
  component_id?: string;
  locals?: string[];
  localisation?: string;
  localsArea?: number;
  priority?: string;
  yearPlanned?: number;
  complexity?: number;
  height?: number;
  removal?: number;
  activity?: string;
  comment?: string;
  auditor?: string;
  auditDate?: string;
  patrimony_factor?: number;
  problematics?: string[];
  items?: string[];
  actionDescription?: string;
  action_comment?: string;
  actionName?: string;
  actionNameSuffix?: string;
  date_finished?: string;
  update?: number;
  reason?: number;
  created_hour?: Date;
  costLines?: CostLine[];
  customerReference?: string;
  commentsCount?: number;

  constructor(requirementProps: RequirementProps) {
    this.id = requirementProps.id;
    this.uniformat = requirementProps.uniformat;
    this.state = requirementProps.state;
    this.type = requirementProps.type;
    this.toVerify = requirementProps.toVerify;
    this.isVerified = requirementProps.isVerified;
    this.totalCost = requirementProps.totalCost;
    this.errors = requirementProps.errors;
    this.reference = requirementProps.reference;
    this.name = requirementProps.name;
    this.category = requirementProps.category;
    this.cause = requirementProps.cause;
    this.description = requirementProps.description;
    this.assetId = requirementProps.assetId;
    this.component_id = requirementProps.component_id;
    this.locals = requirementProps.locals;
    this.localisation = requirementProps.localisation;
    this.localsArea = requirementProps.localsArea;
    this.priority = requirementProps.priority;
    this.yearPlanned = requirementProps.yearPlanned;
    this.complexity = requirementProps.complexity;
    this.height = requirementProps.height;
    this.removal = requirementProps.removal;
    this.activity = requirementProps.activity;
    this.comment = requirementProps.comment;
    this.auditor = requirementProps.auditor;
    this.auditDate = requirementProps.auditDate;
    this.problematics = requirementProps.problematics;
    this.items = requirementProps.items;
    this.actionDescription = requirementProps.actionDescription;
    this.action_comment = requirementProps.action_comment;
    this.actionName = requirementProps.actionName;
    this.actionNameSuffix = requirementProps.actionNameSuffix;
    this.date_finished = requirementProps.date_finished;
    this.patrimony_factor = requirementProps.patrimony_factor;
    this.update = requirementProps.update;
    this.reason = requirementProps.reason;
    this.created_hour = requirementProps.created_hour;
    this.costLines = requirementProps.costLines;
    this.customerReference = requirementProps.customerReference;
    this.commentsCount = requirementProps.commentsCount;
  }
}

export default Requirement;
