import { Comment } from "app/domain/comment";

export type CommentState = {
  isFetching: boolean;
  errorMessage: string;
  comments: Comment[];
};

export const initialCommentState: CommentState = {
  isFetching: false,
  errorMessage: "",
  comments: []
};
