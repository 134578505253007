import api, { Options } from "../api";
import AuditProgress from "../../domain/asset/auditProgress";
import { NEW_API_VERSION } from "../common/api";

class AuditProgressAPI {
  async updateAuditProgress(auditProgress) {
    const option = new Options({ body: auditProgress });
    const url = `${NEW_API_VERSION}/audit_progress/${auditProgress.assetId}`;
    const { data } = await api.put(url, option.body);
    return AuditProgress.parse(data);
  }

  async getAuditProgress(assetId) {
    const url = `${NEW_API_VERSION}/audit_progress/${assetId}`;
    const { data } = await api.get(url, {}, {});
    return AuditProgress.parse(data);
  }

  async getAllAuditProgresses() {
    const url = "/audit-progress";
    const { data } = await api.get(url, {}, {});
    return data.map((auditProgress) => AuditProgress.parse(auditProgress));
  }
}
export default new AuditProgressAPI();
