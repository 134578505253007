import { ColumnState } from "ag-grid-community";
import { Filter } from "app/domain/filter";
import AgGridCustomFilterState from "app/view/gridTemplates/agGridCustomFilterState";

export type FilterState = {
  [tableName: string]: {
    filters: Filter[];
    columnState: ColumnState[];
    model: any;
  };
};

export const initialFilterState: FilterState = {};
