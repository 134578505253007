/* eslint-disable jsx-a11y/label-has-associated-control */

import { Site } from "app/domain/site";
import { useI18n } from "app/locales";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Form as SemanticForm, TextArea as SemanticTextArea } from "semantic-ui-react";

import { SiteFields } from "../../useSite";

interface GeneralInformationProps extends ComponentProps {
  site: Site | undefined;
  onUpdate: (fields: SiteFields) => void;
}

const GeneralInformation: FC<GeneralInformationProps> = ({ site, onUpdate }: GeneralInformationProps) => {
  const { getAttributeLabel } = useI18n();

  const handleChange = (event: any): void => {
    event.preventDefault();

    onUpdate({ [event.target.name]: event.target.value });
  };

  return (
    <>
      <SemanticForm.Group widths={4}>
        {site && site.id ? <SemanticForm.Input label="ID du site (Campus)" value={site.id} /> : null}
        <SemanticForm.Input disabled label="ID de l'organisme" value={site && site.id_organism ? site.id_organism : ""} />
      </SemanticForm.Group>
      <SemanticForm.Group widths={4}>
        <SemanticForm.Input
          disabled
          label={getAttributeLabel("name")}
          placeholder={getAttributeLabel("name")}
          name="name"
          value={site && site.name ? site.name : ""}
          onChange={handleChange}
        />
        <SemanticForm.Input
          disabled
          type="number"
          label="Valeur de remplacement"
          placeholder="Valeur de remplacement"
          name="replacement_value"
          value={site && site.replacement_value ? site.replacement_value : ""}
          onChange={handleChange}
        />
      </SemanticForm.Group>
      <SemanticForm.Group widths={4}>
        <SemanticForm.Input
          disabled
          label="Valeur des exigences"
          placeholder="Valeur des exigences"
          type="number"
          name="requirements_value"
          value={site && site.requirements_value ? site.requirements_value : ""}
          onChange={handleChange}
        />
        <SemanticForm.Input
          disabled
          type="number"
          label="Indice de vetusté physique"
          placeholder="Indice de vetusté physiquex"
          name="facility_condition_index"
          value={site && site.facility_condition_index ? site.facility_condition_index : ""}
          onChange={handleChange}
        />
      </SemanticForm.Group>
      <div>
        <label>{getAttributeLabel("description")}</label>
      </div>
      <SemanticTextArea required size="large" name="description" value={site && site.description ? site.description : ""} onChange={handleChange} />
    </>
  );
};

export default GeneralInformation;
