// TODO: review types
type Data = {
  sites: any[];
};

export type SideMenuState = Readonly<{
  isFetching: boolean;
  error: boolean;
  data: Data;
}>;

export const initialSideMenuState: SideMenuState = {
  isFetching: false,
  error: false,
  data: {
    sites: []
  }
};
