import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Button as SemanticButton, Comment as SemanticComment, SemanticCOLORS } from "semantic-ui-react";
import { style, styleModifiers } from "ui/button/style";

interface ActionButtonProps extends ComponentProps {
  content: string;
  basic?: boolean;
  color?: SemanticCOLORS;
  onClick: () => void;
  isVisible: boolean;
}

const CommentActionButton: FC<ActionButtonProps> = ({ content, basic, color, onClick, isVisible, className }: ActionButtonProps): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    onClick();
  };

  return isVisible ? (
    <SemanticComment.Action>
      <SemanticButton className={className} content={content} color={color} onClick={handleClick} basic={basic} compact circular size="mini" />
    </SemanticComment.Action>
  ) : (
    <div />
  );
};

export default createStyledComponent(CommentActionButton, style, styleModifiers);
