import { Dictionary } from "../types";
import Attributes from "./attributes";
import Errors from "./errors";
import RequirementType from "./requirementType";
import State from "./state";

const FR: Dictionary<any> = {
  state: State,
  requirementType: RequirementType,
  errors: Errors,
  attributes: Object.keys(Attributes).reduce(
    (attrs, key) => ({
      ...attrs,
      [key]: Attributes[key].word
    }),
    {}
  )
};

export default FR;
