import { Site } from "app/domain/site";
import { createSelector } from "reselect";

import { State } from "../state";

const filterById = (_: State, siteId: string | undefined): string | undefined => siteId;

export const getSites = createSelector([(state: State): Site[] => state.sites.sites], (sites: Site[]) => sites);

export const getSite = createSelector([getSites, filterById], (sites: Site[], siteId: string | undefined) => sites.find((site: Site) => site.id === siteId));

export const getCurrentSite = createSelector([(state: State): Site | undefined => state.sites.currentSite], (currentSite: Site | undefined) => currentSite);

export const getErrorMessage = createSelector([(state: State): string => state.sites.errorMessage], (errorMessage) => errorMessage);

export const isLoading = createSelector([(state: State): boolean => state.sites.isLoading], (isSiteLoading: boolean) => isSiteLoading);
