import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { ILayoutProps } from "react-dropzone-uploader";
import { css } from "styled-components";

import Dropzone from "./dropzone";
import LayoutFooter from "./footer";

export const layoutStyle = css`
  width: 500px;
  display: flex;
  flex-direction: column;
`;

export interface LayoutProps extends ComponentProps, ILayoutProps {}

const Layout: FC<LayoutProps> = ({ className, previews, dropzoneProps, input }: LayoutProps) => (
  <div className={className}>
    <Dropzone previews={previews} dropzoneProps={dropzoneProps} />
    <LayoutFooter>{input}</LayoutFooter>
  </div>
);

export default createStyledComponent(Layout, layoutStyle);
