import { capitalize } from "app/utils/formatter";
import React, { createContext, useCallback, useContext, useState } from "react";

import { EN } from "./en";
import { FR } from "./fr";
import { DictionaryCode } from "./types";

export class IllegalAttributeLabelError extends RangeError {
  constructor(attributeLabel: string) {
    super(`Illegal argument passed to getAttributeLabel(): key ${attributeLabel} does not exist in the dictionary`);
  }
}

export const Dictionaries = {
  [DictionaryCode.FR]: FR,
  [DictionaryCode.EN]: EN
};

interface I18nProviderState {
  dictionaryCode: DictionaryCode;
  setDictionaryCode: (dictionaryCode: DictionaryCode) => void;
  getAttributeLabel: (attributeName: string) => string;
}

const I18nContext = createContext({
  dictionaryCode: DictionaryCode.FR,
  setDictionaryCode: (dictionaryCode: DictionaryCode) => {},
  getAttributeLabel: (attributeName: string) => "" as string
});

export const useI18n = (): I18nProviderState => useContext(I18nContext);

const I18nProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [currentDictionaryCode, setCurrentDictionaryCode] = useState(DictionaryCode.FR);

  const setDictionaryCode = useCallback((dictionaryCode: DictionaryCode): void => {
    setCurrentDictionaryCode(dictionaryCode);
  }, []);

  const getAttributeLabel = useCallback(
    (attributeName: string): string => {
      const attribute = Dictionaries[currentDictionaryCode].attributes[attributeName];

      if (attribute) {
        return capitalize(attribute) as string;
      }

      throw new IllegalAttributeLabelError(attributeName);
    },
    [currentDictionaryCode]
  );

  return (
    <I18nContext.Provider
      value={{
        dictionaryCode: currentDictionaryCode,
        setDictionaryCode,
        getAttributeLabel
      }}
    >
      {children}
    </I18nContext.Provider>
  );
};

export default I18nProvider;
