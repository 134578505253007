import SystemModel from "app/domain/system/systemModel";

export type SystemModelState = {
  isFetching: boolean;
  systemModels: SystemModel[];
  errorMessage: string;
};

export const initialSystemModelState: SystemModelState = {
  isFetching: false,
  systemModels: [],
  errorMessage: ""
};
