import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import compare from "react-fast-compare";
import { css } from "styled-components";
import Checkbox from "ui/form/controls/checkbox/checkbox";

export const SELECT_ALL = "Sélectionner tous";
export const SELECT_ALL_RESULTS = "Sélectionner tous les résultats";

const selectAllValuesCheckboxStyle = css`
  padding: 10px 0;
  width: 100%;
`;

interface SelectAllValuesCheckboxProps extends ComponentProps {
  searchedValue: string;
  isChecked: boolean;
  isIndeterminate: boolean;
  onSelectAll: () => void;
}

const isEqual = (prevProps: SelectAllValuesCheckboxProps, nextProps: SelectAllValuesCheckboxProps): boolean => {
  const isSearchedValueEqual = compare(prevProps.searchedValue, nextProps.searchedValue);
  const isCheckedEqual = compare(prevProps.isChecked, nextProps.isChecked);
  const isIndeterminateEqual = compare(prevProps.isIndeterminate, nextProps.isIndeterminate);
  const isOnSelectAllEqual = compare(prevProps.onSelectAll.toString(), nextProps.onSelectAll.toString());

  return isSearchedValueEqual && isCheckedEqual && isIndeterminateEqual && isOnSelectAllEqual;
};

const SelectAllValuesCheckbox: FC<SelectAllValuesCheckboxProps> = ({ searchedValue, onSelectAll, isChecked, isIndeterminate, className }: SelectAllValuesCheckboxProps) => {
  return (
    <Checkbox
      isChecked={isChecked}
      indeterminate={isIndeterminate}
      label={searchedValue !== "" ? SELECT_ALL_RESULTS : SELECT_ALL}
      onClick={onSelectAll}
      className={className}
      data-test-id="checkbox"
    />
  );
};

export default createStyledComponent(SelectAllValuesCheckbox, selectAllValuesCheckboxStyle);
