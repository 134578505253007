import { Grid, TextField } from "@mui/material";
import { AssetMinimalInfo } from "app/domain/asset";
import { System } from "app/domain/system";
import { useI18n } from "app/locales";
import { getCurrentAsset } from "app/store/asset/selectors";
import { moneyFormatter } from "app/utils/formatter";
import { SystemFields } from "app/view/system/form/systemForm/useSystem";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, forwardRef, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox as SemanticCheckbox, Form as SemanticForm, Segment as SemanticSegment } from "semantic-ui-react";
import InputLabel from "ui/inputLabel/inputLabel";
import Label from "ui/label/label";

interface CostInformationProps extends ComponentProps {
  system: System | undefined;
  onUpdate: (fields: SystemFields) => void;
  onNavigationBlock: () => void;
  assetsMinimalInfo: AssetMinimalInfo[];
  isElementInCreationMode: boolean;
  systemWithoutModel?: boolean;
}

const CostInformation = forwardRef(
  ({ system, assetsMinimalInfo, onUpdate, isElementInCreationMode, onNavigationBlock, systemWithoutModel }: CostInformationProps, ref): JSX.Element => {
    const { getAttributeLabel } = useI18n();
    const currentAsset = useSelector(getCurrentAsset);

    const handleQuantityChange = (event: any): void => {
      event.preventDefault();

      onUpdate({ [event.target.name]: event.target.value.replace(",", ".") });
      onNavigationBlock();
    };
    const findAssetArea = (associatedAssetId: string): number => {
      const associatedAsset = assetsMinimalInfo.find((assetMinimalInfo: AssetMinimalInfo) => assetMinimalInfo.id === associatedAssetId);
      return associatedAsset ? associatedAsset.area : 0;
    };

    const handleIsLinkedToArea = (event: any, data: any): void => {
      if (isElementInCreationMode && currentAsset && data.checked) {
        onUpdate({ isLinkedToArea: data.checked, quantity: currentAsset.area });
      } else if (!isElementInCreationMode && data.checked) {
        onUpdate({
          isLinkedToArea: data.checked,
          quantity: findAssetArea(system && system.assetId ? system.assetId : "")
        });
      } else {
        onUpdate({ isLinkedToArea: data.checked, quantity: 0 });
      }
    };

    const handleChangeUnitFactory = (event: any): void => {
      onUpdate({ ...system, unitOfMeasureFactor: event.target.value });
    };
    const totalCost = useMemo(() => {
      const quantity = system && system.quantity ? system.quantity : 0;
      const unitCost = system && system.unitCost ? system.unitCost : 0;

      return quantity * unitCost;
    }, [system]);

    return (
      <div>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
          <Grid container item xs={6} spacing={2}>
            <Grid item xs={6} alignContent="end">
              <Label label={getAttributeLabel("unit_cost")} />
            </Grid>
            <Grid item xs={6}>
              <InputLabel data-test-id="indirectCostField" key="indirectCostField" label="" text={system && system.unitCost ? moneyFormatter.format(system.unitCost) : 0} />
            </Grid>
          </Grid>
          <Grid container item xs={6} spacing={1}>
            <Grid item xs={6} alignContent="end">
              <Label label={getAttributeLabel("unit_of_measure_factor")} />
            </Grid>
            <Grid item xs={1} alignContent="end">
              <SemanticForm.Input
                required
                key="unitOfMeasureFactor"
                transparent={!systemWithoutModel && !(system?.model_number === "Custom")}
                readOnly={!systemWithoutModel && !(system?.model_number === "Custom")}
                name="unitOfMeasureFactor"
                value={system && system.unitOfMeasureFactor ? system.unitOfMeasureFactor : ""}
                onChange={handleChangeUnitFactory}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={2} alignContent="end">
              <Label label={getAttributeLabel("quantity")} />
            </Grid>
            <Grid item xs={2} alignContent="end" alignSelf="center">
              <TextField
                data-test-id="quantityField"
                key="quantity"
                placeholder={getAttributeLabel("quantity")}
                name="quantity"
                value={system && system.quantity}
                type="number"
                onChange={handleQuantityChange}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={2} alignItems="start" alignSelf="center">
              <Label label={system && system.unitOfMeasure ? system.unitOfMeasure : ""} />
            </Grid>

            <Grid item xs={3} alignContent="end" alignSelf="center">
              <SemanticCheckbox
                label="Lier à la superficie de l'actif"
                name="isLinkedToArea"
                checked={system && system.isLinkedToArea ? system.isLinkedToArea : false}
                onChange={handleIsLinkedToArea}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={3}>
              <Label label={getAttributeLabel("total_cost")} />
            </Grid>
            <Grid item xs={3} justifyContent="flex-end">
              <InputLabel data-test-id="totalCostField" key="totalCostField" label="" text={moneyFormatter.format(totalCost)} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
);
CostInformation.displayName = "CostInformation";

export default CostInformation;
