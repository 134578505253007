import SideMenuAPI from "../../service/SideMenuAPI";

export const LIST_PARENTS_AND_CHILDREN_REQUEST =
  "asset/LIST_PARENTS_AND_CHILDREN_REQUEST";
export const LIST_PARENTS_AND_CHILDREN_SUCCESS =
  "asset/LIST_PARENTS_AND_CHILDREN_SUCCESS";
export const LIST_PARENTS_AND_CHILDREN_ERROR =
  "asset/LIST_PARENTS_AND_CHILDREN_ERROR";
export const LOAD_DATA_DONE_REQUEST = "LOAD_DATA_DONE_REQUEST";
export const LOAD_DATA_DONE_SUCCESS = "LOAD_DATA_DONE_SUCCESS";
export const LOAD_DATA_DONE_ERROR = "LOAD_DATA_DONE_ERROR";

const fetchDataDone = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_DATA_DONE_REQUEST });
      dispatch({ type: LOAD_DATA_DONE_SUCCESS });
    } catch (error) {
      dispatch({ type: LOAD_DATA_DONE_ERROR, error });
      throw error;
    }
  };
};

export const listAllNames = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: LIST_PARENTS_AND_CHILDREN_REQUEST });
      const data = await SideMenuAPI.listAllNames();
      dispatch({ type: LIST_PARENTS_AND_CHILDREN_SUCCESS, data: data });
      dispatch(fetchDataDone());
    } catch (error) {
      dispatch({ type: LIST_PARENTS_AND_CHILDREN_ERROR, error });
    }
  };
};
