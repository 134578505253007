/* eslint-disable no-unneeded-ternary */

import { useCallback, useEffect, useMemo, useState } from "react";

import useValuesSelector from "./filterPanel/filterPanelModal/useValuesSelector";
import { RowData } from "./types";

export interface TableRowSelectionProps {
  onSelect: (rowKey: string) => void;
  onSelectAll: () => void;
  onReset: () => void;
  selectedRowKeys: string[];
  visibleSelectedRowKeys: string[];
  isChecked: boolean;
  isIndeterminate: boolean;
}

type TableHook = {
  onChange: (rowData: RowData[]) => void;
  rowData: RowData[];
  rowSelectionProps: TableRowSelectionProps;
};

const useTable = (initialRowData?: RowData[], initialSelectedValues?: string[]): TableHook => {
  const [currentRowData, setCurrentRowData] = useState<RowData[]>(initialRowData || []);

  const { onSelect, onSelectAll, onReset, selectedValues, visibleSelectedValues, isChecked, isIndeterminate, onFilter } = useValuesSelector(
    initialRowData ? initialRowData.map((rowData: RowData) => rowData.id) : [],
    initialSelectedValues ? initialSelectedValues : []
  );

  useEffect(() => {
    setCurrentRowData(initialRowData || []);
  }, [initialRowData]);

  const onChange = useCallback(
    (rowData: RowData[]) => {
      setCurrentRowData([...rowData]);
      onFilter(rowData.map((filteredRowData: RowData) => filteredRowData.id));
    },
    [onFilter]
  );

  return useMemo(
    () => ({
      onChange,
      rowData: currentRowData,
      rowSelectionProps: {
        onSelect,
        onSelectAll,
        onReset,
        selectedRowKeys: selectedValues,
        visibleSelectedRowKeys: visibleSelectedValues,
        isChecked,
        isIndeterminate
      }
    }),
    [onChange, currentRowData, onSelect, onSelectAll, onReset, selectedValues, visibleSelectedValues, isChecked, isIndeterminate]
  );
};

export default useTable;
