/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import get from "lodash/get";
import React, { useState } from "react";
import { IntlProvider } from "react-intl";

import { translationMessages } from "./intl";

const messages = translationMessages;

const LanguageProvider: React.FC = (props): JSX.Element => {
  const [locale] = useState(window.navigator.language.split("-")[0]);

  return (
    <IntlProvider locale={locale} messages={get(messages, locale)}>
      {React.Children.only(props.children)}
    </IntlProvider>
  );
};

export default LanguageProvider;
