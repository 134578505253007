/* stylelint-disable value-keyword-case */

import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import React, { FC, useCallback } from "react";
import { Form as SemanticForm } from "semantic-ui-react";
import { css } from "styled-components";
import * as constants from "ui/form/controls/constants";

const textFieldStyle = css`
  display: flex;
  flex-direction: column;
  height: "fit-content";
  width: 100%;

  label {
    display: block;
    margin: "0 0 0.28571429rem 0";
    color: ${constants.DEFAULT_FORM_CONTROL_TEXT_COLOR};
    font-size: ${(props: StyleProps): string => (!props.isNotBold ? "0.92857143em" : "14px")};
    font-weight: ${(props: StyleProps): number => (!props.isNotBold ? 700 : 0)};
    text-transform: none;
  }

  &&& input {
    border: ${(props: StyleProps): string => (props.readOnly && !props.hasBorder ? "none" : "1px solid rgba(34,36,38,.15)")};
    padding: ${(props: StyleProps): string => (props.readOnly ? ".67857143em 0" : ".67857143em 1em")};
  }

  &&& input:hover {
    border-color: ${constants.DEFAULT_FORM_CONTROL_HOVER_COLOR};
  }

  &&& input:focus {
    border-color: ${constants.DEFAULT_FORM_CONTROL_FOCUS_COLOR};
  }
`;

interface TextFieldProps extends ComponentProps {
  text: string;
  onInput: (newText: string) => void;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  number?: boolean;
  isDisabled?: boolean;
  isNotBold?: boolean;
  hasBorder?: boolean;
}

const TextField: FC<TextFieldProps> = ({ label, text, onInput, placeholder, readOnly, number, className, isDisabled, isNotBold, hasBorder }: TextFieldProps) => {
  const handleClick = (event: React.FormEvent<HTMLInputElement>): void => {
    event.stopPropagation();
  };

  const handleInput = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      event.preventDefault();

      const newText = event.currentTarget.value;
      onInput(newText);
    },
    [onInput]
  );

  return (
    <SemanticForm.Input
      disabled={isDisabled}
      label={label}
      placeholder={placeholder}
      readOnly={readOnly}
      value={text}
      onChange={handleInput}
      onClick={handleClick}
      className={className}
      type={number ? "number" : "text"}
      data-test-id="textField"
    />
  );
};

export default createStyledComponent(TextField, textFieldStyle);
