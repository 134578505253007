/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Button from "@mui/material/Button";
import { CostLine } from "app/domain/costItem";
import { getCostItems } from "app/store/costItem/selectors";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { useSelector } from "react-redux";

import CostDataModal from "./costDataModal";
import { CostItemSearchBar } from "./costItemSearchBar";
import { CostLinesTable } from "./costLinesTable";
import CustomCostItem from "./customCostItem/customCostItem";

interface CostDataContainerProps extends ComponentProps {
  onAdd: (newCostLine: CostLine) => void;
  onUpdate: (costLineId: number, costLinesQuantity: number) => void;
  onDelete: (costLineId: number) => void;
  selectedCostLines: CostLine[];
  systemId: string;
}

const CostDataContainer: FC<CostDataContainerProps> = ({ onAdd, onUpdate, onDelete, selectedCostLines, systemId }: CostDataContainerProps) => {
  const allCostItems = useSelector(getCostItems);
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <Button variant="contained" onClick={() => setIsOpen(true)}>
        ajouter des ligne rsmeans
      </Button>
      <CostItemSearchBar costItems={allCostItems} onChange={onAdd} />
      <CostLinesTable costLines={selectedCostLines} onUpdateCostLine={onUpdate} onDeleteCostLine={onDelete} />
      <CustomCostItem addCustomCostLineToParent={onAdd} />
      <CostDataModal isOpen={isOpen} setIsOpen={setIsOpen} systemId={systemId} addCostLine={onAdd} />
    </div>
  );
};

export default memo(CostDataContainer);
