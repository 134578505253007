/* eslint-disable no-nested-ternary, react-hooks/exhaustive-deps */

import { System } from "app/domain/system";
import { SystemFields } from "app/view/system/form/systemForm/useSystem";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect } from "react";
import { css } from "styled-components";
import { TextField, useTextField } from "ui/form/controls/textField";

import ManufacturerDropdown from "./manufacturerDropdown";

const manufacturerSectionStyle = css`
  padding: 1em 0;
  width: 100%;
`;

interface ManufacturerSectionProps extends ComponentProps {
  system: System | undefined;
  onUpdate: (fields: SystemFields) => void;
}

const ManufacturerSection: FC<ManufacturerSectionProps> = ({ system, onUpdate, className }: ManufacturerSectionProps) => {
  const { text: serialNumber, onInput: onSerialNumberChange } = useTextField(system && system.serialNumber ? system.serialNumber : "");
  const { text: model, onInput: onModelChange } = useTextField(system && system.model ? system.model : "");

  const { text: customerReference, onInput: onCustomerReferenceChange } = useTextField(system && system.customerReference ? system.customerReference : "");

  useEffect(() => {
    onUpdate({
      serialNumber
    });
  }, [onUpdate, serialNumber]);

  useEffect(() => {
    onUpdate({
      model
    });
  }, [onUpdate, model]);

  useEffect(() => {
    onUpdate({
      customerReference
    });
  }, [onUpdate, customerReference]);

  return (
    <div className={className}>
      <ManufacturerDropdown system={system} onUpdate={onUpdate} />
      <TextField label="Modèle" text={model} onInput={onModelChange} data-test-id="modelTextField" />
      <TextField label="Numéro de série" text={serialNumber} onInput={onSerialNumberChange} data-test-id="serialNumberTextField" />
      <TextField label="Numéro de référence client" text={customerReference} onInput={onCustomerReferenceChange} data-test-id="customerReferenceTextField" />
    </div>
  );
};

export default createStyledComponent(ManufacturerSection, manufacturerSectionStyle);
