import { Reply } from "app/domain/comment";
import CommentFactory from "app/service/comment/commentFactory";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { ChangeEvent, FC } from "react";
import { Button, Form } from "semantic-ui-react";
import { TextAreaProps } from "semantic-ui-react/dist/commonjs/addons/TextArea/TextArea";

interface CommentReplyCreatorProps extends ComponentProps {
  onCreate: (reply: Reply) => void;
  onCancel: () => void;
}

const CommentReplyCreator: FC<CommentReplyCreatorProps> = ({ onCreate, onCancel, className }: CommentReplyCreatorProps) => {
  const [text, setText] = React.useState("");
  const commentFactory = new CommentFactory();

  const modifyText = (event: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps): void => {
    setText(String(data.value));
  };

  const createReply = (): void => {
    onCreate(commentFactory.createReply(text));
    setText("");
  };

  const cancelReply = (): void => {
    setText("");
    onCancel();
  };

  return (
    <Form className={className} reply>
      <Form.TextArea value={text} onChange={modifyText} />
      <Button content="Envoyer" onClick={createReply} labelPosition="left" icon="edit" primary />
      <Button content="Annuler" onClick={cancelReply} labelPosition="left" icon="trash" secondary />
    </Form>
  );
};

export default CommentReplyCreator;
