import { createAction } from "@reduxjs/toolkit";
import { Comment } from "app/domain/comment";
import { EntityCategory } from "app/domain/entityCategory";

import { CommentActionTypes } from "./commentTypes";

export const getCommentsAction = createAction(CommentActionTypes.GET_COMMENTS, (parentType: EntityCategory, parentId: string) => ({
  payload: {
    parentType,
    parentId
  }
}));

export const getCommentsRequestAction = createAction(CommentActionTypes.GET_COMMENTS_REQUEST, (parentType: EntityCategory, parentId: string) => ({
  payload: {
    parentType,
    parentId
  }
}));

export const getCommentsSuccessAction = createAction(CommentActionTypes.GET_COMMENTS_SUCCESS, (comments: Comment[]) => ({
  payload: {
    comments
  }
}));

export const getCommentsErrorAction = createAction(CommentActionTypes.GET_COMMENTS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const addCommentAction = createAction(CommentActionTypes.ADD_COMMENT, (comment: Comment) => ({
  payload: {
    comment
  }
}));

export const addCommentRequestAction = createAction(CommentActionTypes.ADD_COMMENT_REQUEST, (comment: Comment) => ({
  payload: {
    comment
  }
}));

export const addCommentSuccessAction = createAction(CommentActionTypes.ADD_COMMENT_SUCCESS, (comment: Comment) => ({
  payload: {
    comment
  }
}));

export const addCommentErrorAction = createAction(CommentActionTypes.ADD_COMMENT_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const updateCommentAction = createAction(CommentActionTypes.UPDATE_COMMENT, (comment: Comment) => ({
  payload: {
    comment
  }
}));

export const updateCommentRequestAction = createAction(CommentActionTypes.UPDATE_COMMENT_REQUEST, (comment: Comment) => ({
  payload: {
    comment
  }
}));

export const updateCommentSuccessAction = createAction(CommentActionTypes.UPDATE_COMMENT_SUCCESS, (comment: Comment) => ({
  payload: {
    comment
  }
}));

export const updateCommentErrorAction = createAction(CommentActionTypes.UPDATE_COMMENT_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const resetAction = createAction(CommentActionTypes.RESET_COMMENT);
