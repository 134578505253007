import { Site } from "app/domain/site";

export type SiteDTO = {
  id: string;
  project_id?: string;
  organism_id?: string;
  site_number?: string;
  name?: string;
  city?: string;
  province?: string;
  country?: string;
  replacement_value?: number;
  requirements_value?: number;
  postal_code?: string;
  description?: string;
  is_audit_done?: boolean;
};

class SiteMapper {
  static fromDTO(siteDTO: SiteDTO): Site;
  static fromDTO(siteDTOs: SiteDTO[]): Site[];
  static fromDTO(siteDTOs: SiteDTO | SiteDTO[]): any {
    if (!Array.isArray(siteDTOs)) {
      return new Site({
        id: siteDTOs.id,
        project_id: siteDTOs.project_id,
        id_organism: siteDTOs.organism_id,
        site_number: siteDTOs.site_number,
        name: siteDTOs.name,
        city: siteDTOs.city,
        province: siteDTOs.province,
        country: siteDTOs.country,
        replacement_value: siteDTOs.replacement_value,
        requirements_value: siteDTOs.requirements_value,
        postal_code: siteDTOs.postal_code,
        description: siteDTOs.description,
        is_audit_done: siteDTOs.is_audit_done
      });
    }
    return siteDTOs.map((systemDTO: SiteDTO): Site => this.fromDTO(systemDTO));
  }

  static toDTO(site: Site): SiteDTO {
    return {
      id: site.id,
      project_id: site.project_id,
      organism_id: site.id_organism,
      site_number: site.site_number,
      name: site.name,
      city: site.city,
      province: site.province,
      country: site.country,
      replacement_value: site.replacement_value,
      requirements_value: site.requirements_value,
      postal_code: site.postal_code,
      description: site.description,
      is_audit_done: site.is_audit_done
    };
  }
}

export default SiteMapper;
