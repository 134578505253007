/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import usePrevious from "app/view/common/hook/usePrevious";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import useAllValuesSelector from "./useAllValuesSelector";

type ValuesSelectorHook = {
  onSelect: (value: string) => void;
  onSelectAll: () => void;
  onReset: () => void;
  selectedValues: string[];
  visibleSelectedValues: string[];
  values: string[];
  isChecked: boolean;
  isIndeterminate: boolean;
  onFilter: (values: string[]) => void;
};

const useValuesSelector = (initialValues: string[], initialSelectedValues: string[], inverted?: boolean): ValuesSelectorHook => {
  const previousInitialValues = usePrevious(initialValues);
  const previousInitialSelectedValues = usePrevious(initialSelectedValues);

  const computeCurrentValues = useCallback(
    (values: string[], selectedValues: string[]) =>
      values.reduce(
        (object, value: string) =>
          Object.assign(object, {
            [value]: selectedValues.length === 0 && inverted ? false : selectedValues.includes(value)
          }),
        {}
      ),
    [inverted]
  );

  const [currentValues, setCurrentValues] = useState<Record<string, boolean>>(computeCurrentValues(initialValues, initialSelectedValues));
  const [currentFilteredValues, setCurrentFilteredValues] = useState<string[]>(initialValues);

  useEffect(() => {
    if (!isEqual(previousInitialValues, initialValues) || !isEqual(previousInitialSelectedValues, initialSelectedValues)) {
      setCurrentValues(computeCurrentValues(initialValues, initialSelectedValues));
      setCurrentFilteredValues(initialValues);
    }
  }, [computeCurrentValues, previousInitialValues, initialValues, previousInitialSelectedValues, initialSelectedValues]);

  const onReset = useCallback(() => {
    setCurrentValues(computeCurrentValues(initialValues, initialSelectedValues));
    setCurrentFilteredValues(initialValues);
  }, [computeCurrentValues, initialValues, initialSelectedValues]);

  const onFilter = useCallback(
    (values: string[]) => {
      setCurrentFilteredValues([...values]);
    },
    [setCurrentFilteredValues]
  );

  const filteredValues = useMemo(
    () =>
      Object.keys(currentValues)
        .filter((key: string) => currentFilteredValues.includes(key))
        .reduce(
          (object, key: string) => ({
            ...object,
            [key]: currentValues[key]
          }),
          {}
        ) as Record<string, boolean>,
    [currentValues, currentFilteredValues]
  );

  const { isChecked, isIndeterminate } = useAllValuesSelector(filteredValues);

  const onSelectAll = useCallback(() => {
    const newCheckedState = isIndeterminate || !isChecked;
    setCurrentValues(() => {
      return {
        ...currentValues,
        ...currentFilteredValues.reduce(
          (object, value: string) => ({
            ...(object as any),
            [value]: newCheckedState
          }),
          {}
        )
      };
    });
  }, [isChecked, isIndeterminate, currentFilteredValues]);

  const onSelect = useCallback((value: string) => {
    setCurrentValues((previousValues: Record<string, boolean>) => ({
      ...previousValues,
      [value]: !previousValues[value]
    }));
  }, []);

  const selectedValues = useMemo(
    () =>
      Object.entries(currentValues)
        .filter(([_, isSelected]) => isSelected)
        .map(([value, _]) => value),
    [currentValues]
  );

  const visibleSelectedValues = useMemo(
    () =>
      Object.entries(filteredValues)
        .filter(([_, isSelected]) => isSelected)
        .map(([value, _]) => value),
    [filteredValues]
  );

  return useMemo(
    () => ({
      onSelect,
      onSelectAll,
      onReset,
      selectedValues,
      visibleSelectedValues,
      values: currentFilteredValues,
      isChecked,
      isIndeterminate,
      onFilter
    }),
    [onSelect, onSelectAll, onReset, selectedValues, currentFilteredValues, isChecked, isIndeterminate, onFilter, visibleSelectedValues]
  );
};

export default useValuesSelector;
