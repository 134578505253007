import { List } from "@mui/material";
import { ComponentProps } from "common/style/createStyledComponent";
import * as React from "react";
import { FC } from "react";

import MenuListItem, { MenuItem } from "./menuListItem";

interface MenuListProps extends ComponentProps {
  items: MenuItem[];
  selectedItemId: string;
  onSelectItem: (itemId: string) => void;
}

const MenuList: FC<MenuListProps> = ({ items, selectedItemId, onSelectItem }: MenuListProps) => {
  const selectItem = (itemId: string): void => {
    onSelectItem(itemId);
  };

  return (
    <List>
      {items.map((item: MenuItem) => (
        <MenuListItem key={item.id} item={item} isSelected={item.id === selectedItemId} onClick={(): void => selectItem(item.id)} data-test-id={`item-${item.id}`} />
      ))}
    </List>
  );
};

export default MenuList;
