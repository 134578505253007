/* eslint-disable array-callback-return, consistent-return, no-plusplus */

import { State as RequirementState, State as SystemState } from "app/domain/common";
import { Filter, FilterType } from "app/domain/filter";
import { Requirement } from "app/domain/requirement";
import { useI18n } from "app/locales";
import { useRouter } from "app/routing/routerProvider";
import { setFiltersAction } from "app/store/filter/actions";
import { getFilters } from "app/store/filter/selectors";
import { setFilteredRequirementsAction } from "app/store/requirement/actions";
import { State } from "app/store/state";
import { moneyFormatter } from "app/utils/formatter";
import usePrevious from "app/view/common/hook/usePrevious";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import isEqual from "react-fast-compare";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { useOpenNewTab } from "ui/hook";
import { ColumnAlignment, Table, useTable } from "ui/table";
import useFilters from "ui/table/quickFilterPanel/useFilters";
import { Column, RowData } from "ui/table/types";

import { createRequirementRowData } from "../requirementData";

const requirementTableStyle = css`
  && {
    @media screen and (max-width: 1200px) {
      font-size: 0.9em;
    }
  }
`;

interface RequirementTableProps extends ComponentProps {
  requirements: Requirement[];
}

const RequirementTable: FC<RequirementTableProps> = ({ requirements, className }: RequirementTableProps) => {
  const { dictionaryCode, getAttributeLabel } = useI18n();
  const { location, goTo } = useRouter();
  const { newTab } = useOpenNewTab();

  const filters = useSelector((state: State): Filter[] => getFilters(state, "requirement"));
  const previousFilters = usePrevious(filters);

  const { onChange: onChangeFilter, onToggle: onToggleFilter, onDelete: onDeleteFilter, onReset: onResetFilters, filters: currentFilters } = useFilters(filters);

  const data = useMemo(() => requirements.map((requirement: Requirement) => createRequirementRowData(requirement, dictionaryCode)), [requirements, dictionaryCode]) as RowData[];

  const { onChange: onDataChange, rowData: currentData } = useTable(data);

  const filteredRequirements = useMemo(() => currentData.map((rowData: RowData) => rowData.id), [currentData]) as string[];
  const previousFilteredRequirements = usePrevious(filteredRequirements);

  const requirementsTotalCost = useMemo(() => {
    let totalCost = 0;
    for (let index = 0; index < currentData.length; index += 1) {
      totalCost += currentData[index].totalCost.accessor;
    }
    return totalCost;
  }, [currentData]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEqual(previousFilteredRequirements, filteredRequirements)) {
      dispatch(setFilteredRequirementsAction(filteredRequirements));
    }

    if (!isEqual(previousFilters, currentFilters)) {
      dispatch(setFiltersAction("requirement", currentFilters));
    }
  }, [dispatch, previousFilteredRequirements, filteredRequirements, previousFilters, currentFilters]);

  const handleRowClick = useCallback((clickedRowData: any): void => goTo(`${location}/requirements/${clickedRowData.id}`, undefined, newTab), [goTo, location, newTab]);

  const columns = useMemo(
    () => [
      {
        Header: getAttributeLabel("reference"),
        Footer: "",
        field: "reference",
        onClick: handleRowClick
      },
      {
        Header: getAttributeLabel("customer_reference"),
        Footer: "",
        field: "customerReference"
      },
      {
        Header: "État",
        Footer: "",
        field: "state"
      },
      {
        Header: getAttributeLabel("requirement_category"),
        Footer: "",
        field: "type",
        style: {
          width: 1.5
        }
      },
      {
        Header: "Uniformat",
        Footer: "",
        field: "uniformat"
      },
      {
        Header: "Action",
        Footer: "",
        field: "action",
        style: {
          width: 1.5
        }
      },
      {
        Header: getAttributeLabel("total_cost"),
        Footer: `Total: ${moneyFormatter.format(requirementsTotalCost)}`,
        field: "totalCost",
        style: {
          alignment: ColumnAlignment.RIGHT
        }
      },
      {
        Header: "Date",
        Footer: "",
        field: "date",
        select: false,
        style: {
          width: 0.8
        }
      },
      {
        Header: "À vérifier",
        Footer: "",
        field: "toVerify",
        hidden: true
      }
    ],
    [requirementsTotalCost, handleRowClick, getAttributeLabel]
  ) as Column[];

  const initialQuickFilters = useMemo(
    () => [
      {
        label: "À vérifier",
        type: FilterType.QUICK_FILTER,
        field: "toVerify",
        values: ["1"]
      },
      {
        label: "Contrôle qualité non fait",
        type: FilterType.QUICK_FILTER,
        field: "state",
        values: RequirementState.values
          .filter((requirementState: SystemState) => requirementState !== RequirementState.VERIFIED)
          .map((requirementState: RequirementState) => RequirementState.format(dictionaryCode, requirementState))
      },
      {
        label: "Non complété",
        type: FilterType.QUICK_FILTER,
        field: "state",
        values: RequirementState.values
          .filter((requirementState: SystemState) => requirementState !== RequirementState.COMPLETED)
          .map((requirementState: RequirementState) => RequirementState.format(dictionaryCode, requirementState))
      }
    ],
    [dictionaryCode]
  ) as Filter[];

  return (
    <Table
      className={className}
      data={data}
      onDataChange={onDataChange}
      columns={columns}
      filters={currentFilters}
      initialQuickFilters={initialQuickFilters}
      onFilter={onChangeFilter}
      onToggleFilter={onToggleFilter}
      onDeleteFilter={onDeleteFilter}
      onResetFilters={onResetFilters}
    />
  );
};

export default createStyledComponent(memo(RequirementTable), requirementTableStyle);
