import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { DataNode } from "antd/lib/tree";
import { AuditType } from "app/domain/audit/auditType";
import { EntityCategory } from "app/domain/entityCategory";
import { System } from "app/domain/system";
import { useI18n } from "app/locales";
import { Params, useRouter } from "app/routing/routerProvider";
import AssetService from "app/service/asset/assetService";
import QaErrorService from "app/service/qaError/qaErrorService";
import { generateSystemCopiesToDestinationsRequestAction, generateSystemLabelsAction, getSystemsAction } from "app/store/system/actions";
import { getArchitecturalSystems, getMechanicalSystems } from "app/store/system/selectors";
import { Toolbar } from "app/view/common/toolbar";
import TreeViewStyle from "app/view/project/treeViewStyle";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { Button } from "ui/button";
import { AddButton } from "ui/button/actions";
import { IconType } from "ui/icon";
import { Modal } from "ui/modal";
import { useTable } from "ui/table";
import { RowData } from "ui/table/types";

import { createSystemRowData } from "./systemData";
import { SystemTable } from "./table";

const ASSET_COMPONENT_URL = "/newcomponent";
const GENERATE_CODES = "Générer les codes QR";
const ADD = "Ajouter";
const COPY = "Copier vers";

const systemListingStyle = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

interface SystemListingProps extends ComponentProps {
  parentType: EntityCategory;
  auditType: AuditType;
  roomId?: string;
}

const SystemListing: FC<SystemListingProps> = ({ parentType, auditType, roomId, className }: SystemListingProps) => {
  const { dictionaryCode } = useI18n();
  const { getParam, goTo } = useRouter();

  const projectId = getParam(Params.PROJECT);
  const siteId = getParam(Params.SITE);
  const assetId = getParam(Params.ASSET);

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isCopyingQuantity, setIsCopyingQuantity] = useState(false);
  const [isCopyingIdentification, setIsCopyingIdentification] = useState(false);
  const [destinationAssetIds, setDestinationAssetIds] = useState<string[]>([]);

  const mechanicalSystems = useSelector(getMechanicalSystems);
  const architecturalSystems = useSelector(getArchitecturalSystems);
  const systems = auditType === AuditType.MECHANICAL ? mechanicalSystems : architecturalSystems;

  useEffect(() => {
    if (roomId) {
      dispatch(getSystemsAction(EntityCategory.ROOM, roomId));
    } else {
      dispatch(getSystemsAction(EntityCategory.ASSET, assetId));
    }
  }, [assetId, roomId, dispatch]);

  const data = useMemo(() => systems.map((system: System) => createSystemRowData(system, dictionaryCode)), [systems, dictionaryCode]) as RowData[];

  const { onChange: onDataChange, rowData: currentData, rowSelectionProps } = useTable(data);

  function canGenerateCodeAndCopy(): boolean {
    return rowSelectionProps.selectedRowKeys.length > 0;
  }

  const generateQrCodes = (): void => {
    dispatch(generateSystemLabelsAction(rowSelectionProps.selectedRowKeys));
    rowSelectionProps.onReset();
  };

  const displayCopyModal = (): void => {
    setIsOpen(true);
  };

  const closeModal = (): void => {
    setIsOpen(false);
    setDestinationAssetIds([]);
  };

  const sendCopyMessage = (): void => {
    if (destinationAssetIds.length > 0) {
      dispatch(
        generateSystemCopiesToDestinationsRequestAction(
          isCopyingIdentification,
          isCopyingQuantity,
          rowSelectionProps.selectedRowKeys,
          destinationAssetIds.map((key) => {
            const idHierarchy = key.split("/");
            return idHierarchy[idHierarchy.length - 1];
          })
        )
      );
      setDestinationAssetIds([]);
    }
    setIsOpen(false);
  };

  const redirectToSystemCreationPage = (): void => {
    goTo(ASSET_COMPONENT_URL, {
      auditType,
      siteId,
      projectId,
      assetId,
      selectedRoomsId: roomId ? [roomId] : [],
      parentType,
      parentId: roomId
    });
  };

  const fetchProjectSites = async (): Promise<DataNode[]> => {
    const fetchedProjectSites = await QaErrorService.getProjectSites();
    return fetchedProjectSites.map((project) => {
      return {
        title: project.label,
        key: project.value,
        checkable: false,
        children: project.children.map((site) => {
          return {
            title: site.label,
            key: `${project.value}/${site.value}`,
            checkable: false
          };
        })
      };
    });
  };

  const getSites = async (key: string): Promise<DataNode[]> => {
    const resultArr = key.split("/");
    const arr = await AssetService.getAssetsMinimalInfo(resultArr[resultArr.length - 1]);
    return arr.map((asset) => {
      return {
        title: asset.name,
        key: `${key}/${asset.id}`,
        isLeaf: true
      };
    });
  };

  const actions = [
    <Button disabled={!canGenerateCodeAndCopy()} onClick={generateQrCodes} title={GENERATE_CODES} iconType={IconType.QR_CODE} key={0} />,
    <Button disabled={!canGenerateCodeAndCopy()} onClick={displayCopyModal} title={COPY} iconType={IconType.COPY} key={1} />,
    <AddButton onClick={redirectToSystemCreationPage} label={ADD} key={2} />
  ];

  return (
    <div className={className}>
      <Toolbar actions={actions} />
      <SystemTable
        projectId={projectId}
        siteId={siteId}
        assetId={assetId}
        parentType={parentType}
        roomId={roomId}
        auditType={auditType}
        onDataChange={onDataChange}
        currentData={currentData}
        data={data}
        rowSelectionProps={rowSelectionProps}
      />
      <Modal
        isOpen={isOpen}
        padding="40px"
        content={
          <div>
            <div style={{ padding: "20px 0" }}>
              <TreeViewStyle checked={destinationAssetIds} setChecked={setDestinationAssetIds} getData={getSites} defaultData={fetchProjectSites} />
            </div>
            <div>
              <Checkbox
                onChange={(e: CheckboxChangeEvent): void => {
                  setIsCopyingQuantity(e.target.checked);
                }}
              >
                Copier la quantité
              </Checkbox>
              <Checkbox
                onChange={(e: CheckboxChangeEvent): void => {
                  setIsCopyingIdentification(e.target.checked);
                }}
              >
                Copier l&apos;identification
              </Checkbox>
            </div>
          </div>
        }
        title="Sélectionner la/les destination(s)"
        actions={[<Button title="Copier" key={0} onClick={sendCopyMessage} />]}
        onClose={closeModal}
      />
    </div>
  );
};

export default createStyledComponent(memo(SystemListing), systemListingStyle);
