import { Asset } from "app/domain/asset";
import { getAssetOptions } from "app/store/asset/selectors";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Form as SemanticForm } from "semantic-ui-react";

import { AssetFields } from "../../../useAsset";

interface AssetEvaluationProps extends ComponentProps {
  asset: Asset | undefined;
  onUpdate: (fields: AssetFields) => void;
}

const AssetEvaluation: FC<AssetEvaluationProps> = ({ asset, onUpdate }: AssetEvaluationProps) => {
  const assetOptions = useSelector(getAssetOptions);

  const conditionGradingOptions = [
    { value: "A", text: "A" },
    { value: "B", text: "B" },
    { value: "C", text: "C" },
    { value: "D", text: "D" },
    { value: "E", text: "E" }
  ];

  const handleSelectChange = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({ [data.name]: data.value });
  };

  return (
    <SemanticForm.Group unstackable widths={4}>
      <SemanticForm.Dropdown
        label="Coût de construction"
        clearable
        placeholder="Coût de construction"
        name="building_cost_category"
        value={asset && asset.building_cost_category}
        onChange={handleSelectChange}
        selection
        options={assetOptions ? assetOptions.list_building_cost_category_options : []}
      />
      <SemanticForm.Dropdown
        label="Cote d'état estimée"
        clearable
        placeholder="Cote d'état estimée"
        name="estimated_condition_grading"
        value={asset && asset.estimated_condition_grading ? asset.estimated_condition_grading : ""}
        onChange={handleSelectChange}
        selection
        options={conditionGradingOptions}
      />
    </SemanticForm.Group>
  );
};

export default AssetEvaluation;
