import { State as SystemState } from "app/domain/common";
import { Local } from "app/domain/local";
import { System } from "app/domain/system";
import { DictionaryCode } from "app/locales";
import { moneyFormatter } from "app/utils/formatter";
import { getStateColor } from "app/view/common/form/formColors";
import moment from "moment";
import React from "react";
import { TableBadge } from "ui/table";
import TableCheckIcon from "ui/table/tableCheckIcon";
import { RowData } from "ui/table/types";

const displayLocals = (system: System): string => ((system.locals as Local[]).length > 1 ? "Plusieurs locaux" : ((system.locals as Local[])[0].number as string));

export const createSystemRowData = (system: System, dictionaryCode: DictionaryCode): RowData => {
  return {
    id: system.id,
    toVerify: {
      accessor: system.toVerify ? "1" : "0",
      value: system.toVerify ? "1" : "0"
    },
    is_installation_year_estimated: {
      accessor: system.is_installation_year_estimated ? "1" : "0",
      value: system.is_installation_year_estimated ? "1" : "0"
    },
    qrCode: {
      accessor: system.has_qr_code_been_generated ? 1 : 0,
      value: system.has_qr_code_been_generated ? <TableCheckIcon /> : null
    },
    reference: {
      accessor: system.reference === "" ? null : system.reference,
      value: system.reference
    },
    state: {
      accessor: SystemState.format(dictionaryCode, system.state),
      value: <TableBadge label={SystemState.format(dictionaryCode, system.state)} color={getStateColor(system.state)} />
    },
    name: {
      accessor: system.custom_name_concat === "" ? null : system.custom_name_concat,
      value: system.custom_name_concat
    },
    lifespan: {
      accessor: system.estimated_lifetime,
      value: system.estimated_lifetime !== undefined && system.estimated_lifetime !== null ? `${system.estimated_lifetime} ans` : ""
    },
    location: {
      accessor: system.locals && system.locals.length > 0 ? displayLocals(system) : system.localisation,
      value: system.locals && system.locals.length > 0 ? displayLocals(system) : system.localisation
    },
    uniformat: {
      accessor: system.uniformat.level4 ? system.uniformat.level4 : system.uniformat.level3,
      value: system.uniformat.level4 ? system.uniformat.level4 : system.uniformat.level3
    },
    quantity: { accessor: system.quantity, value: system.quantity },
    totalCost: {
      accessor: system.totalCost,
      value: moneyFormatter.format(system.totalCost)
    },
    numberOfPhotos: {
      accessor: system.numberOfPhotos,
      value: system.numberOfPhotos
    },
    date: {
      accessor: system.created_hour ? `${new Date(system.created_hour).toISOString()}` : "",
      value: system.created_hour ? `${moment(system.created_hour).fromNow()}` : ""
    }
  };
};
