/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

export const bannerStyle = css`
  display: ${(props: StyleProps): string => (props.isVisible ? "block" : "none")};
  background-color: ${(props: StyleProps): string => props.color};
  color: white;
  flex: 1;
  line-height: 25px;
  text-align: center;
  font-weight: bolder;
  user-select: none;
  width: 100%;
  position: relative;
`;

interface BannerProps extends ComponentProps {
  isVisible: boolean;
  text: string;
  color: string;
}

const Banner: FC<BannerProps> = ({ text, className }: BannerProps) => <div className={className}>{text}</div>;

export default createStyledComponent(Banner, bannerStyle);
