/* eslint-disable jsx-a11y/label-has-associated-control */

import { Requirement } from "app/domain/requirement";
import { useI18n } from "app/locales";
import { State } from "app/store/state";
import { FormFieldGroup, Separator } from "app/view/common/form";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { forwardRef, RefObject } from "react";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { Form as SemanticForm, TextArea as SemanticTextArea } from "semantic-ui-react";

import { RequirementFields } from "../useRequirement";

interface ManagementInformationProps extends ComponentProps {
  requirement: Requirement | undefined;
  onUpdate: (fields: RequirementFields) => void;
  onNavigationBlock: () => void;
}

const ManagementInformation = forwardRef((props: ManagementInformationProps, ref): JSX.Element => {
  const { requirement, onUpdate, onNavigationBlock } = props;

  const { getAttributeLabel } = useI18n();

  const optionsLists = useSelector((state: State): any => state.requirements.options);

  const handleSelectChange = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({ [data.name]: data.value });
    onNavigationBlock();
  };

  const handleChange = (event: any): void => {
    event.preventDefault();

    onUpdate({ [event.target.name]: event.target.value });
    onNavigationBlock();
  };

  const minTwoDigits = (n: number): string => (n < 10 ? "0" : "") + n;

  const handleDateChange = (name: string, date: any): void => {
    if (date !== null) {
      const dateString = `${(date as Date).getUTCFullYear().toString()}-${minTwoDigits((date as Date).getUTCMonth() + 1).toString()}-${minTwoDigits(
        (date as Date).getUTCDate().toString() as unknown as number
      )}`;

      onUpdate({ [name]: dateString });
      onNavigationBlock();
    }
  };

  const convertDate = (date: string | undefined | null): Date | null => {
    if (date !== undefined && date !== null && date !== "") {
      const parts = date.substring(0, 10).split("-");
      return new Date(parts[0] as unknown as number, (parts[1] as unknown as number) - 1, parts[2] as unknown as number);
    }
    return null;
  };

  return (
    <div>
      <h3 ref={ref as RefObject<any>}>
        <span>5.</span> Informations de gestion
      </h3>
      <FormFieldGroup percentWidth={14}>
        <SemanticForm.Dropdown
          required
          label={getAttributeLabel("auditor")}
          clearable
          placeholder={getAttributeLabel("auditor")}
          name="auditor"
          value={requirement && requirement.auditor}
          onChange={handleSelectChange}
          selection
          options={optionsLists ? optionsLists.auditorOptions : []}
        />
      </FormFieldGroup>
      <FormFieldGroup row>
        <div className="date-field">
          <div>{getAttributeLabel("audit_date")}</div>
          <ReactDatePicker
            name="auditDate"
            onChange={(date): void => handleDateChange("auditDate", date)}
            selected={convertDate(requirement && requirement.auditDate)}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="date-field">
          <div>Date de fin</div>
          <ReactDatePicker
            name="date_finished"
            onChange={(date): void => handleDateChange("date_finished", date)}
            selected={convertDate(requirement && requirement.date_finished)}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <SemanticForm.Input
          label="Date et heure de saisie"
          placeholder="Heure de saisie"
          name="created_hour"
          value={requirement && requirement.created_hour ? new Date(requirement.created_hour).toLocaleString() : ""}
          readOnly
        />
      </FormFieldGroup>
      <Separator />
      <FormFieldGroup>
        <div className="field">
          <label htmlFor="notes">Commentaires de l&apos;exigence</label>
          <SemanticTextArea
            id="comment"
            required
            size="large"
            placeholder="Commentaires de l'exigence"
            name="comment"
            value={requirement && requirement.comment ? requirement.comment : ""}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="action_notes">Commentaires de l&apos;action</label>
          <SemanticTextArea
            id="action_notes"
            required
            size="large"
            placeholder="Commentaires de l'action"
            name="action_comment"
            value={requirement && requirement.action_comment ? requirement.action_comment : ""}
            onChange={handleChange}
          />
        </div>
      </FormFieldGroup>
    </div>
  );
});
ManagementInformation.displayName = "ManagementInformation";

export default ManagementInformation;
