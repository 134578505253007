/* eslint-disable react-hooks/exhaustive-deps */
import UserComment from "app/domain/comment/userComment";
import { EntityCategory } from "app/domain/entityCategory";
import { Filter } from "app/domain/filter";
import { useRouter } from "app/routing/routerProvider";
import CommentService from "app/service/comment/commentService";
import { setFiltersAction } from "app/store/filter/actions";
import { getFilters } from "app/store/filter/selectors";
import { State } from "app/store/state";
import usePrevious from "app/view/common/hook/usePrevious";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import moment from "moment";
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-async";
import isEqual from "react-fast-compare";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import Loader from "ui/loader/loader";
import { ColumnAlignment, Table } from "ui/table";
import useFilters from "ui/table/quickFilterPanel/useFilters";
import TableCheckIcon from "ui/table/tableCheckIcon";
import { Column, RowData } from "ui/table/types";
import useTable from "ui/table/useTable";

import { BreadcrumbLink } from "../common/page/breadcrumbs";

const commentsTableStyle = css`
  width: 90%;
  margin: 20px auto;

  .table {
    height: 70vh;
  }

  .loaderContainer {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
  }
`;

interface CommentsTableProps extends ComponentProps {
  siteId?: string;
}

const CommentsTable: FC<CommentsTableProps> = ({ siteId, className }: CommentsTableProps) => {
  const dispatch = useDispatch();
  const filters = useSelector((state: State): Filter[] => getFilters(state, "comments"));
  const previousFilters = usePrevious(filters);
  const { onChange: onChangeFilter, onToggle: onToggleFilter, onDelete: onDeleteFilter, onReset: onResetFilters, filters: currentFilters } = useFilters(filters);

  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([] as UserComment[]);
  const { goTo } = useRouter();

  const openSystemDetailsInNewTab = useCallback(
    (clickedRowData: RowData): void => {
      const baseURL = `/projects/${clickedRowData.projectId}/sites/${clickedRowData.siteId}/assets/${clickedRowData.assetId}`;
      if (clickedRowData.parentCategory.value === EntityCategory.SYSTEM) {
        window.open(`${baseURL}/components/${clickedRowData.id}`);
      } else if (clickedRowData.parentCategory.value === EntityCategory.REQUIREMENT) {
        window.open(`${baseURL}/requirements/${clickedRowData.id}`);
      } else {
        window.open(baseURL);
      }
    },
    [goTo]
  );

  const fetchComments = (): Promise<any> => {
    setIsLoading(true);
    if (siteId) {
      return CommentService.getSiteComments(siteId);
    }
    return CommentService.getUserComments();
  };

  const handleSuccess = (fetchedComments: UserComment[]): void => {
    setIsLoading(false);
    setComments(fetchedComments);
  };

  const { run } = useAsync({
    deferFn: fetchComments,
    onResolve: handleSuccess
  });

  useEffect(() => {
    run();
    if (!isEqual(previousFilters, currentFilters)) {
      dispatch(setFiltersAction("comments", currentFilters));
    }
  }, [dispatch, previousFilters, currentFilters, siteId]);

  const columns = useMemo(
    () => [
      {
        Header: "Site",
        Footer: "",
        field: "siteName",
        sort: false,
        select: true,
        hidden: !!siteId
      },
      {
        Header: "Actif",
        Footer: "",
        field: "assetNumber",
        sort: false,
        select: true,
        style: {
          width: 0.5
        }
      },

      {
        Header: "Nom de l'élément",
        Footer: "",
        field: "parentName",
        onClick: openSystemDetailsInNewTab,
        sort: false,
        select: false
      },
      {
        Header: "Message",
        Footer: "",
        field: "message",
        sort: false,
        select: false,
        style: {
          width: 2
        }
      },
      {
        Header: "Catégorie",
        Footer: "",
        field: "parentCategory",
        sort: false,
        select: true,
        style: {
          width: 0.5
        }
      },
      {
        Header: "Date",
        Footer: "",
        field: "createdAt",
        sort: true,
        select: true,
        style: {
          width: 0.6
        }
      },
      {
        Header: "Auteur",
        Footer: "",
        field: "author",
        sort: false,
        select: true,
        style: {
          width: 0.6
        }
      },
      {
        Header: "Résolu",
        Footer: "",
        field: "status",
        sort: false,
        select: true,
        style: {
          width: 0.6,
          alignment: ColumnAlignment.CENTER
        }
      }
    ],
    [openSystemDetailsInNewTab]
  ) as Column[];
  const { onChange: onDataChange } = useTable();

  const data = useMemo(
    () =>
      comments.map((comment) => ({
        id: comment.parentId,
        commentId: comment.commentId,
        isRead: comment.isRead,
        projectId: comment.projectId,
        siteId: comment.siteId,
        assetId: comment.assetId,
        siteName: {
          accessor: comment.siteName,
          value: comment.siteName
        },
        assetNumber: {
          accessor: comment.assetNumber,
          value: comment.assetNumber
        },
        parentName: {
          accessor: comment.parentName,
          value: comment.parentName
        },
        message: {
          accessor: comment.message,
          value: comment.message
        },
        createdAt: {
          accessor: `${new Date(comment.createdAt).toISOString()}`,
          value: `${moment(comment.createdAt).fromNow()}`
        },
        parentCategory: {
          accessor: comment.parentCategory,
          value: comment.parentCategory
        },
        author: {
          accessor: comment.author,
          value: comment.author
        },
        status: {
          accessor: comment.isResolved,
          value: comment.isResolved ? <TableCheckIcon /> : null
        }
      })),
    [comments]
  ) as RowData[];

  return (
    <div className={className}>
      <BreadcrumbLink label="Projets" url="/projects" /> / Commentaires
      <h1>Commentaires</h1>
      <div className="loaderContainer">
        <Loader className="loader" isActive={isLoading} height={100} data-test-id="loader" />
      </div>
      <Table
        className="table"
        data={data}
        onDataChange={onDataChange}
        columns={columns}
        filters={currentFilters}
        onFilter={onChangeFilter}
        onToggleFilter={onToggleFilter}
        onDeleteFilter={onDeleteFilter}
        onResetFilters={onResetFilters}
      />
    </div>
  );
};

export default createStyledComponent(memo(CommentsTable), commentsTableStyle);
