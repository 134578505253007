import { ColumnState } from "ag-grid-community";
import { Filter } from "app/domain/filter";
import AgGridCustomFilterState from "app/view/gridTemplates/agGridCustomFilterState";

import { PayloadAction } from "../types";

export enum FilterActionTypes {
  SET_FILTERS = "SET_FILTERS",
  SET_FILTERS_V2 = "SET_FILTERS_V2",
  SET_AG_GRID_CUSTOM_FILTER_STATE = "SET_AG_GRID_CUSTOM_FILTER_STATE",
  RESET_AG_GRID_CUSTOM_FILTER_STATE = "RESET_AG_GRID_CUSTOM_FILTER_STATE",
  DELETE_AG_GRID_CUSTOM_FILTER_STATE = "DELETE_AG_GRID_CUSTOM_FILTER_STATE"
}

export type SetFiltersAction = PayloadAction<
  FilterActionTypes.SET_FILTERS,
  {
    table: string;
    filters: Filter[];
  }
>;

export type SetFiltersActionV2 = PayloadAction<
  FilterActionTypes.SET_FILTERS_V2,
  {
    table: string;
    columnState: ColumnState[];
  }
>;

export type SetAgGridCustomFilterStateAction = PayloadAction<
  FilterActionTypes.SET_AG_GRID_CUSTOM_FILTER_STATE,
  {
    table: string;
    model: any;
  }
>;

export type ResetAgGridCustomFilterStateAction = PayloadAction<
  FilterActionTypes.RESET_AG_GRID_CUSTOM_FILTER_STATE,
  {
    table: string;
  }
>;

export type DeleteAgGridCustomFilterStateAction = PayloadAction<
  FilterActionTypes.DELETE_AG_GRID_CUSTOM_FILTER_STATE,
  {
    table: string;
    deletedColumn: string;
  }
>;
