/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";

import {
  getLocalsAction,
  getLocalsErrorAction,
  getLocalsRequestAction,
  getLocalsSuccessAction,
  getRoomAction,
  getRoomErrorAction,
  getRoomRequestAction,
  getRoomSuccessAction,
  resetAction
} from "./actions";
import { initialLocalState, LocalState } from "./state";
import { GetLocalsErrorAction, GetLocalsSuccessAction, GetRoomErrorAction, GetRoomSuccessAction } from "./types";

const getLocals = (state: LocalState): void => {
  state.errorMessage = "";
};

const getLocalsRequest = (state: LocalState): void => {
  state.isFetching = true;
};

const getLocalsSuccess = (state: LocalState, action: GetLocalsSuccessAction): void => {
  state.isFetching = false;
  state.locals = action.payload.locals;
};

const getLocalsError = (state: LocalState, action: GetLocalsErrorAction): void => {
  state.isFetching = false;
  state.errorMessage = action.payload.errorMessage;
};

const getRoom = (state: LocalState): void => {
  state.errorMessage = "";
};

const getRoomRequest = (state: LocalState): void => {
  state.isFetching = true;
};

const getRoomSuccess = (state: LocalState, action: GetRoomSuccessAction): void => {
  state.isFetching = false;
  state.currentRoom = action.payload.room;
};

const getRoomError = (state: LocalState, action: GetRoomErrorAction): void => {
  state.isFetching = false;
  state.errorMessage = action.payload.errorMessage;
};

const reset = (state: LocalState): void => {
  state.errorMessage = "";
};

const localReducer = createReducer(initialLocalState, {
  [getLocalsAction.type]: (state) => getLocals(state),
  [getLocalsRequestAction.type]: (state) => getLocalsRequest(state),
  [getLocalsSuccessAction.type]: (state, action) => getLocalsSuccess(state, action),
  [getLocalsErrorAction.type]: (state, action) => getLocalsError(state, action),
  [getRoomAction.type]: (state) => getRoom(state),
  [getRoomRequestAction.type]: (state) => getRoomRequest(state),
  [getRoomSuccessAction.type]: (state, action) => getRoomSuccess(state, action),
  [getRoomErrorAction.type]: (state, action) => getRoomError(state, action),
  [resetAction.type]: (state) => reset(state)
});

export default localReducer;
