import { Requirement } from "app/domain/requirement";
import { FieldNames } from "app/service/comment/field/fieldNames";

class RequirementFieldValueFetcher {
  getFieldValue(requirement: Requirement, fieldName: string): string {
    switch (fieldName) {
      case FieldNames.TOTAL_COST: {
        return String(requirement.totalCost);
      }
      case FieldNames.NAME: {
        return requirement.name != null ? String(requirement.name) : "";
      }
      case FieldNames.DESCRIPTION: {
        return requirement.description != null ? String(requirement.description) : "";
      }
      case FieldNames.COMPONENT_ID: {
        return requirement.component_id != null ? String(requirement.component_id) : "";
      }
      case FieldNames.LOCALISATION: {
        return requirement.localisation != null ? String(requirement.localisation) : "";
      }
      case FieldNames.PRIORITY: {
        return requirement.priority != null ? String(requirement.priority) : "";
      }
      case FieldNames.COMPLEXITY: {
        return requirement.complexity != null ? String(requirement.complexity) : "";
      }
      case FieldNames.HEIGHT: {
        return requirement.height != null ? String(requirement.height) : "";
      }
      case FieldNames.REMOVAL: {
        return requirement.removal != null ? String(requirement.removal) : "";
      }
      case FieldNames.ACTIVITY: {
        return requirement.activity != null ? String(requirement.activity) : "";
      }
      case FieldNames.COMMENT: {
        return requirement.comment != null ? String(requirement.comment) : "";
      }
      case FieldNames.PATRIMONY_FACTOR: {
        return requirement.patrimony_factor != null ? String(requirement.patrimony_factor) : "";
      }
      case FieldNames.ACTION_DESCRIPTION: {
        return requirement.actionDescription != null ? String(requirement.actionDescription) : "";
      }
      case FieldNames.ACTION_COMMENT: {
        return requirement.action_comment != null ? String(requirement.action_comment) : "";
      }
      case FieldNames.ACTION_NAME: {
        return requirement.actionName != null ? String(requirement.actionName) : "";
      }
      case FieldNames.ACTION_NAME_SUFFIX: {
        return requirement.actionNameSuffix != null ? String(requirement.actionNameSuffix) : "";
      }
      default: {
        return "";
      }
    }
  }
}

export default RequirementFieldValueFetcher;
