import { PhotosFolderDownloadStatus } from "./photosFolderDownloadStatus";

export const DEFAULT_PROGRESS = 0;
export const DEFAULT_STATUS = PhotosFolderDownloadStatus.NOT_STARTED;

class PhotosFolder {
  id: string;
  url: string;
  progress: number;
  status: PhotosFolderDownloadStatus;

  constructor(id?: string, url?: string, progress?: number, status?: PhotosFolderDownloadStatus) {
    this.id = id || "";
    this.url = url || "";
    this.progress = progress || DEFAULT_PROGRESS;
    this.status = status || DEFAULT_STATUS;
  }
}

export default PhotosFolder;
