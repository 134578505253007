import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Badge } from "ui/badge";

interface TableBadgeProps extends ComponentProps {
  label: string;
  color: string;
}

const TableBadge: FC<TableBadgeProps> = ({ label, color }: TableBadgeProps) => {
  return <Badge label={label} color={color} modifiers={["outlined", "pill"]} />;
};

export default TableBadge;
