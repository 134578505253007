/* eslint-disable react-hooks/exhaustive-deps */

import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect } from "react";
import compare from "react-fast-compare";
import FormControl, { BaseFormControlProps } from "ui/form/formControl";
import { useFormContext } from "ui/form/useForm";

import Checkbox from "./checkbox";
import useCheckbox from "./useCheckbox";

interface BaseCheckboxProps extends ComponentProps {
  isChecked?: boolean;
  label?: string;
}

interface ControlledCheckboxProps extends BaseCheckboxProps {
  onClick: (event: React.FormEvent<HTMLInputElement>) => void;
  name?: string;
}

interface UncontrolledCheckboxProps extends BaseCheckboxProps, BaseFormControlProps {
  onClick?: (event: React.FormEvent<HTMLInputElement>) => void;
}

type CheckboxProps = ControlledCheckboxProps | UncontrolledCheckboxProps;

const isEqual = (prevProps: CheckboxProps, nextProps: CheckboxProps): boolean => {
  const isCheckedEqual = compare(prevProps.isChecked, nextProps.isChecked);
  const isLabelEqual = compare(prevProps.label, nextProps.label);
  const isOnClickEqual = compare(prevProps.onClick ? prevProps.onClick.toString() : undefined, nextProps.onClick ? nextProps.onClick.toString() : undefined);
  const isNameEqual = compare(prevProps.name, nextProps.name);
  const isClassNameEqual = compare(prevProps.className, nextProps.className);

  return isOnClickEqual && isCheckedEqual && isLabelEqual && isNameEqual && isClassNameEqual;
};

const CheckboxControl: FC<CheckboxProps> = (props: CheckboxProps) => {
  const { isChecked, label, name, className, onClick } = props;

  const formContext = useFormContext();

  const { isChecked: isCurrentlyChecked, onClick: onCheckboxClick } = useCheckbox(isChecked || false);

  useEffect(() => {
    if (formContext) {
      formContext.setValue(name as string, isChecked || false);
    }
  }, [isChecked]);

  const handleClick = (event: React.FormEvent<HTMLInputElement>): void => {
    onCheckboxClick(event);

    if (formContext) {
      formContext.setValue(name as string, !isCurrentlyChecked);
    }

    if (onClick) {
      onClick(event);
    }
  };

  return formContext ? (
    <FormControl name={name as string} rules={(props as UncontrolledCheckboxProps).rules} className={className}>
      <Checkbox label={label} onClick={handleClick} isChecked={isCurrentlyChecked} />
    </FormControl>
  ) : (
    <Checkbox label={label} name={name} onClick={(props as ControlledCheckboxProps).onClick} isChecked={isChecked} data-test-id="checkbox" />
  );
};

export default memo(CheckboxControl, isEqual);
