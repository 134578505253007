import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";
import { Icon, IconType } from "ui/icon";

const tableCheckIcon = css`
  margin: auto;
`;

const TableCheckIcon: FC<ComponentProps> = ({ className }: ComponentProps) => <Icon type={IconType.CHECK} modifiers="circular" className={className} />;

export default createStyledComponent(TableCheckIcon, tableCheckIcon);
