import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import compare from "react-fast-compare";
import { HeaderGroup } from "react-table";
import { css } from "styled-components";

import { TableRowSelectionProps } from "../../useTable";
import TableFooterRowCell from "./tableFooterRowCell";

const tableFooterRowCellStyle = css`
  &&&&& {
    width: 42px;
    background: #f9fafb;
    border-top: 1px solid rgba(34, 36, 38, 0.1);
    border-bottom: none;
    border-left: none;
    white-space: nowrap;
  }
`;

interface TableFooterRowProps extends ComponentProps {
  footerGroup: HeaderGroup;
  rowSelectionProps?: TableRowSelectionProps;
}

const isEqual = (prevProps: TableFooterRowProps, nextProps: TableFooterRowProps): boolean => {
  const isFooterGroupEqual = compare(prevProps.footerGroup, nextProps.footerGroup);
  const isRowSelectionPropsEqual = compare(prevProps.rowSelectionProps, nextProps.rowSelectionProps);

  return isFooterGroupEqual && isRowSelectionPropsEqual;
};

const TableFooterRow: FC<TableFooterRowProps> = ({ footerGroup, rowSelectionProps, className }: TableFooterRowProps) => (
  <div className="row" data-test-id="row">
    {rowSelectionProps && <div className={`${className} cell`} />}
    {footerGroup.headers.map((column: any) => (
      <TableFooterRowCell column={column} key={column.getFooterProps().key} />
    ))}
  </div>
);

export default createStyledComponent(memo(TableFooterRow, isEqual), tableFooterRowCellStyle);
