import React, { useEffect, useState } from "react";

import { DropdownItem } from "./dropdown";

type DropdownHook = {
  onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownItem) => void;
  value: string;
};

const useDropdown = (value?: string): DropdownHook => {
  const [currentValue, setCurrentValue] = useState(value || "");

  useEffect(() => {
    setCurrentValue(value || "");
  }, [value]);

  const handleChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownItem): void => {
    event.preventDefault();

    setCurrentValue(data.value);
  };

  return { value: currentValue, onChange: handleChange };
};

export default useDropdown;
