interface AssetMinimalInfoProps {
  id: string;
  number: string;
  name: string;
  area: number;
}

class AssetMinimalInfo {
  id: string;
  number: string;
  name: string;
  area: number;

  constructor(assetMinimalInfoProps: AssetMinimalInfoProps) {
    this.id = assetMinimalInfoProps.id;
    this.number = assetMinimalInfoProps.number;
    this.name = assetMinimalInfoProps.name;
    this.area = assetMinimalInfoProps.area;
  }
}

export default AssetMinimalInfo;
