import { useCallback, useMemo, useState } from "react";

type WebcamHook = {
  openWebcam: () => void;
  closeWebcam: () => void;
  isWebcamOpen: boolean;
};

const useWebcam = (): WebcamHook => {
  const [isWebcamOpen, setOpen] = useState(false);

  const openWebcam = useCallback(() => {
    setOpen(true);
  }, []);

  const closeWebcam = useCallback(() => {
    setOpen(false);
  }, []);

  return useMemo(
    () => ({
      openWebcam,
      closeWebcam,
      isWebcamOpen
    }),
    [openWebcam, closeWebcam, isWebcamOpen]
  );
};

export default useWebcam;
