import { CostItem } from "app/domain/costItem";

export type CostItemDTO = {
  unit_cost: number;
  description: string;
  unit_of_measure: string;
  id?: number;
  code?: string;
  class_name?: string;
};

class CostItemMapper {
  static fromDTO(costItemDTO: CostItemDTO): CostItem;
  static fromDTO(costItemDTOs: CostItemDTO[]): CostItem[];
  static fromDTO(costItemDTOs: CostItemDTO | CostItemDTO[]): any {
    if (!Array.isArray(costItemDTOs)) {
      return new CostItem({
        unitCost: costItemDTOs.unit_cost,
        description: costItemDTOs.description,
        unitOfMeasure: costItemDTOs.unit_of_measure,
        id: costItemDTOs.id,
        code: costItemDTOs.code,
        className: costItemDTOs.class_name
      });
    }
    return costItemDTOs.map((costItemDTO: CostItemDTO): CostItem => this.fromDTO(costItemDTO));
  }

  static toDTO(costItem: CostItem): CostItemDTO {
    return {
      unit_cost: costItem.unitCost,
      description: costItem.description,
      unit_of_measure: costItem.unitOfMeasure,
      id: costItem.id,
      code: costItem.code,
      class_name: costItem.className
    };
  }
}

export default CostItemMapper;
