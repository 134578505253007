import React, { FC } from "react";

import Banner from "./banner";

interface LocalBannerProps {
  isVisible: boolean;
}

const LocalBanner: FC<LocalBannerProps> = ({ isVisible }: LocalBannerProps) => <Banner isVisible={isVisible} text="LOCAL" color="green" />;

export default LocalBanner;
