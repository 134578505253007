import env from "@beam-australia/react-env";

type Headers = {
  [key: string]: string;
};

type ApiSettings = {
  host: string;
  headers: Headers;
};

const apiSettings: ApiSettings = {
  host: env("API_HOST"),
  headers: {
    Authorization: localStorage.getItem("token") as string,
    "Content-Type": "application/json"
  }
};

export default apiSettings;
