/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";

import {
  generateTemplateReportAction,
  generateTemplateReportErrorAction,
  generateTemplateReportRequestAction,
  generateTemplateReportSuccessAction,
  importDataAction,
  importDataErrorAction,
  importDataRequestAction,
  importDataSuccessAction
} from "./reportActions";
import { initialReportState, ReportState } from "./reportState";
import { GenerateTemplateReportErrorAction } from "./reportTypes";

const generateTemplateReport = (state: ReportState): void => {
  state.errorMessage = "";
};

const generateTemplateReportRequest = (state: ReportState): void => {
  state.isLoading = true;
};

const generateTemplateReportSuccess = (state: ReportState): void => {
  state.isLoading = false;
};

const generateTemplateReportError = (state: ReportState, action: GenerateTemplateReportErrorAction): void => {
  state.errorMessage = action.payload.errorMessage;
  state.isLoading = false;
};

const importData = (state: ReportState): void => {
  state.errorMessage = "";
};

const importDataRequest = (state: ReportState): void => {
  state.isLoading = true;
};

const importDataSuccess = (state: ReportState): void => {
  state.isLoading = false;
};

const importDataError = (state: ReportState, action: GenerateTemplateReportErrorAction): void => {
  state.errorMessage = action.payload.errorMessage;
  state.isLoading = false;
};

const reportReducer = createReducer(initialReportState, {
  [generateTemplateReportAction.type]: (state) => generateTemplateReport(state),
  [generateTemplateReportRequestAction.type]: (state) => generateTemplateReportRequest(state),
  [generateTemplateReportSuccessAction.type]: (state) => generateTemplateReportSuccess(state),
  [generateTemplateReportErrorAction.type]: (state, action) => generateTemplateReportError(state, action),
  [importDataAction.type]: (state) => importData(state),
  [importDataRequestAction.type]: (state) => importDataRequest(state),
  [importDataSuccessAction.type]: (state) => importDataSuccess(state),
  [importDataErrorAction.type]: (state, action) => importDataError(state, action)
});

export default reportReducer;
