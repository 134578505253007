import { Asset } from "app/domain/asset";
import { Params, useRouter } from "app/routing/routerProvider";
import { getAssetOptionsAction, updateAssetAction } from "app/store/asset/actions";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { useLoader } from "app/view/common/loader/loaderProvider";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { css } from "styled-components";
import { Loader } from "ui/loader";

import { EntityCategory } from "../../../domain/entityCategory";
import { AssetForm } from "./assetForm";

const modifyAssetContainerStyle = css`
  position: relative;
  flex: 1;
`;

type ModifyAssetContainerProps = ComponentProps;

const ModifyAssetContainer: FC<ModifyAssetContainerProps> = ({ className }: ModifyAssetContainerProps) => {
  const { getParam } = useRouter();
  const { isActive } = useLoader();
  const { errorMessage, onDismiss, onUpdate } = useErrorMessage();

  const dispatch = useDispatch();

  const siteId = getParam(Params.SITE);
  const assetId = getParam(Params.ASSET);

  useEffect(() => {
    dispatch(getAssetOptionsAction());
    return (): void => {
      onDismiss();
    };
  }, [dispatch, onDismiss, siteId, assetId]);

  const handleSave = (asset: Asset): void => {
    if (asset?.id) {
      dispatch(updateAssetAction(asset, EntityCategory.ASSET, asset.id));
    }
  };

  return (
    <div className={className}>
      <Loader isActive={isActive} />
      <AssetForm onSave={handleSave} errorMessage={errorMessage} onReset={onDismiss} onUpdate={onUpdate} />
    </div>
  );
};

export default createStyledComponent(ModifyAssetContainer, modifyAssetContainerStyle);
