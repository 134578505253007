import { Project } from "app/domain/project";
import ProjectService from "app/service/project/projectService";
import { call, put, takeLatest } from "redux-saga/effects";

import { getProjectsErrorAction, getProjectsRequestAction, getProjectsSuccessAction } from "./actions";
import { ProjectActionTypes } from "./types";

export const GET_PROJECTS_ERROR = "Une erreur s'est produite lors de la récupération des projets. Veuillez réessayer.";

export function* getProjects(): Generator {
  yield put(getProjectsRequestAction());
}

export function* getProjectsRequest(): Generator {
  try {
    const projects = yield call(ProjectService.getProjects);
    yield put(getProjectsSuccessAction(projects as Project[]));
  } catch (error) {
    yield put(getProjectsErrorAction(GET_PROJECTS_ERROR));
  }
}
function* projectSaga(): Generator {
  yield takeLatest(ProjectActionTypes.GET_PROJECTS, getProjects);
  yield takeLatest(ProjectActionTypes.GET_PROJECTS_REQUEST, getProjectsRequest);
}

export default projectSaga;
