import System from "app/domain/system/system";
import { generateSystemCopiesAction } from "app/store/system/actions";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { useDispatch } from "react-redux";
import { css } from "styled-components";
import { Button } from "ui/button";
import { Modal } from "ui/modal";

import SystemCopyModalContent from "./systemCopyModalContent";
import { useSystemCopyTable } from "./systemCopyTable";

const SUBMIT = "Soumettre";

const systemCopyModalStyle = css`
  .modal-container {
    width: 85vw;
    max-width: 85vw;
    min-width: 85vw;
  }
`;

interface SystemCopyModalProps extends ComponentProps {
  system: System;
  isOpen: boolean;
  onClose: () => void;
}

const SystemCopyModal: FC<SystemCopyModalProps> = ({ system, isOpen, onClose, className }: SystemCopyModalProps) => {
  const dispatch = useDispatch();

  const { copies: systemCopies, onAdd, onUpdate, onDelete, onReset } = useSystemCopyTable(system);

  const handleClose = (): void => {
    onReset();
    onClose();
  };

  const submitSystemCopies = (): void => {
    dispatch(generateSystemCopiesAction(system.id, systemCopies, system.assetId as string));

    handleClose();
  };

  return (
    <Modal
      title={`Copie du système ${system.reference}`}
      isOpen={isOpen}
      className={className}
      content={<SystemCopyModalContent systemCopies={systemCopies} onUpdate={onUpdate} onDelete={onDelete} onAdd={onAdd} data-test-id="systemCopyModalContent" />}
      actions={[<Button title={SUBMIT} onClick={submitSystemCopies} disabled={systemCopies.length === 0} key={0} data-test-id="submitSystemCopiesActionButton" />]}
      onClose={handleClose}
      data-test-id="modal"
    />
  );
};

export default createStyledComponent(memo(SystemCopyModal), systemCopyModalStyle);
