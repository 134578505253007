import * as React from "react";
import { Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginUserAction } from "app/store/login/actions";
import "./login.css";
import User from "../../domain/userIdentification";
import { Redirect } from "react-router";
import PreLoader from "../../view/menus/preLoader";
import logoLogin from "./login_logo.png";
import logoMain from "./logo_main_login.png";
import Styled from "styled-components";
import { Button } from "ui/button";
import { isLoggedIn, getErrorMessage } from "app/store/login/selectors";

const LoginCard = Styled.div`
    height: fit-content;
    max-width: 400px;
    width: 400px;
    border-radius: 10px;
    background-color: #ffffff9c;
    overflow: hidden;
    color: #144aa9;
    padding: 1.5rem;

    .ui.form {        
        label {
           text-align: left;
        }
    
        input {
            margin-bottom: 10px;
        }
        
        .button {
           margin: auto;
        }  
    }
`;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isLoggedIn: localStorage.getItem("token"),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      if (nextProps.isLoggedIn) this.setState({ isLoggedIn: true });
    }
  }

  listenToEnter = (e) => {
    let key = e.which || e.keyCode;
    if (key === 13) {
      // 13 is enter
      this.logUser({ preventDefault: () => null });
    }
  };

  componentDidMount() {
    window.addEventListener("keypress", this.listenToEnter, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keypress", this.listenToEnter);
  }

  handleChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  logUser = () => {
    const user = new User({
      email: this.state.email,
      password: this.state.password,
    });
    this.props.actions.loginUserAction(user);
  };

  render() {
    const { email, password, isLoggedIn } = this.state;

    if (this.props.isLoading || this.props.isMenuLoading) {
      return <PreLoader />;
    }

    if (isLoggedIn) return <Redirect to="/projects" />;

    return (
      <div className="login-background">
        <div className="logo_holder">
          <img src={logoLogin} />
          <img className="logo_main" src={logoMain} alt="logo_name" />
        </div>
        <LoginCard>
          <Form>
            <Form.Input
              id="email"
              type="email"
              size="big"
              label="Adresse courriel :"
              placeholder="Adresse courriel"
              name="email"
              value={email}
              onChange={this.handleChange}
            />
            <Form.Input
              id="password"
              type="password"
              size="big"
              placeholder="Mot de passe"
              label="Mot de passe :"
              name="password"
              value={password}
              onChange={this.handleChange}
            />
            <Button onClick={this.logUser} title="Se connecter" />
            {this.props.errorMessage !== "" ? (
              <div className="credentialsErrorMessage">
                Courriel ou mot de passe invalide.
              </div>
            ) : null}
          </Form>
        </LoginCard>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: isLoggedIn(state),
    errorMessage: getErrorMessage(state),
    isLoading: state.login.isLoading,
    isMenuLoading: state.minimalInfo.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ loginUserAction }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
