/* stylelint-disable value-keyword-case */
import { Cable } from "actioncable";
import { Asset } from "app/domain/asset";
import AssetForm from "app/domain/asset/assetForm";
import AssetFormMapper from "app/domain/asset/assetFormMapper";
import { EntityCategory } from "app/domain/entityCategory";
import { Params, useRouter } from "app/routing/routerProvider";
import { AssetService } from "app/service/asset";
import { createAssetAction, updateAssetAction } from "app/store/asset/actions";
import { getCurrentSite } from "app/store/site/selectors";
import AssetTable from "app/view/asset/listing/assetTable";
import CommentsTable from "app/view/comment/commentsTable";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { formStyle } from "app/view/common/form";
import { Page } from "app/view/common/page";
import { notify } from "app/view/common/toast/Toast";
import { Toolbar } from "app/view/common/toolbar";
import * as constants from "app/view/constant";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import AddButton from "ui/button/actions/addButton";
import { ErrorMessage } from "ui/errorMessage";
import { Tab, Tabs, useTabs } from "ui/tab";

import AssetDrawer from "./assetDrawer";
import EditAssetDrawer from "./editAssetDrawer";
import { SiteBasicInformationForm } from "./siteBasicInformation";

export const siteFormStyle = css`
  &&& {
    ${formStyle};
    display: flex;
    flex-direction: column;
    flex: 1;

    .siteError {
      margin: 1em;
    }

    > *:not(:last-child) {
      margin-bottom: 1em;
    }
  }
`;

type SiteFormProps = ComponentProps & { cable: Cable };

const SiteForm: FC<SiteFormProps> = ({ className, cable }: SiteFormProps) => {
  const { getParam } = useRouter();
  const { activeTabIndex, onTabChange } = useTabs();
  const { errorMessage, onDismiss } = useErrorMessage();
  const [isNewAssetDrawerVisible, setIsNewAssetDrawerVisible] = useState(false);
  const [isEditAssetDrawerVisible, setIsEditAssetDrawerVisible] = useState(false);

  const projectId = getParam(Params.PROJECT);
  const siteId = getParam(Params.SITE);

  const dispatch = useDispatch();

  const currentSite = useSelector(getCurrentSite);
  const [selectedAsset, setSelectedAsset] = useState({} as Asset);

  useEffect(() => {
    onDismiss();

    return (): void => {
      onDismiss();
    };
  }, [dispatch, onDismiss]);

  const displayNewAssetDrawer = (): void => {
    setIsNewAssetDrawerVisible(true);
  };

  const onSubmitValidNew = (asset: AssetForm): void => {
    dispatch(createAssetAction(AssetFormMapper.toAsset(asset, siteId), siteId));
    setIsNewAssetDrawerVisible(false);
  };

  const onAbortNew = (): void => {
    setIsNewAssetDrawerVisible(false);
    notify("Création annulée.");
  };

  const displayEditAssetDrawer = (selectedAssetId: string): void => {
    AssetService.getAsset(selectedAssetId).then((res: Asset) => {
      setSelectedAsset(res);
      setIsEditAssetDrawerVisible(true);
    });
  };

  const onSubmitValidEdit = (asset: Asset): void => {
    dispatch(updateAssetAction(asset, EntityCategory.SITE, siteId));
    setIsEditAssetDrawerVisible(false);
  };

  const onAbortEdit = (): void => {
    setIsEditAssetDrawerVisible(false);
    notify("Édition annulée.");
  };

  return (
    <Page
      showBreadcrumb
      title={
        <>
          <span>Site:</span> {currentSite ? `${currentSite.id} - ${currentSite.name}` : ""}
        </>
      }
    >
      <div className={className}>
        <ErrorMessage message={errorMessage} onDismiss={onDismiss} className="siteError" />
        <Tabs activeTabIndex={activeTabIndex || 0} onTabChange={onTabChange}>
          <Tab label={constants.BASIC_INFORMATION}>
            <SiteBasicInformationForm site={currentSite} cable={cable} />
          </Tab>
          <Tab label="Actifs">
            <Toolbar actions={<AddButton onClick={displayNewAssetDrawer} label="Ajouter" key={0} />} />
            <AssetTable projectId={projectId} siteId={siteId} displayDrawer={displayEditAssetDrawer} />
          </Tab>
          <Tab label="Commentaires">
            <CommentsTable siteId={siteId} />
          </Tab>
        </Tabs>
        <AssetDrawer isVisible={isNewAssetDrawerVisible} title="Création d'un actif" acceptButtonLabel="Créer" onAccept={onSubmitValidNew} onCancel={onAbortNew} />
        <EditAssetDrawer visible={isEditAssetDrawerVisible} onSubmitValid={onSubmitValidEdit} onAbort={onAbortEdit} asset={selectedAsset} />
      </div>
    </Page>
  );
};

export default createStyledComponent(SiteForm, siteFormStyle);
