import { Dictionary } from "../types";

const RequirementType: Dictionary<string> = {
  excluded: "Hors projet",
  active: "Active",
  deleted: "Supprimée",
  done: "Terminée",
  replacedBySystem: "Remplacée par un système",
  library: "Bibliothèque d'exigences"
};

export default RequirementType;
