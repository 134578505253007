import { createSelector } from "@reduxjs/toolkit";
import Photo, { PhotosFolder } from "app/domain/photo";
import { DEFAULT_PROGRESS, DEFAULT_STATUS } from "app/domain/photo/photosFolder";

import { State } from "../state";
import { EntityPhotosStatus, PhotosFolderPollingStatus } from "./state";

const getPhotos = (state: State, entityId: string): Photo[] => (state.photo.photos[entityId] ? state.photo.photos[entityId].photos : []);

const getPhotosFolder = (
  state: State,
  entityId: string
):
  | {
      photosFolder: PhotosFolder;
      pollingStatus: PhotosFolderPollingStatus;
    }
  | undefined => state.photo.photosFolders[entityId];

const getEntityPhotosStatus = (state: State, entityId: string): EntityPhotosStatus | undefined => state.photo.photos[entityId] && state.photo.photos[entityId].status;

export const isLoading = createSelector([getEntityPhotosStatus], (entityPhotosStatus: EntityPhotosStatus | undefined) => !!entityPhotosStatus);

const isSerialized = (photo: Photo): boolean => typeof photo.timestamp === "string";

export const sortPhotosByDate = createSelector([getPhotos], (photos: Photo[]) =>
  photos
    .slice()
    .sort((firstPhoto: Photo, secondPhoto: Photo) => {
      let firstPhotoTimestamp = firstPhoto.timestamp;
      let secondPhotoTimestamp = secondPhoto.timestamp;

      if (isSerialized(firstPhoto)) {
        firstPhotoTimestamp = new Date(firstPhotoTimestamp);
      }

      if (isSerialized(secondPhoto)) {
        secondPhotoTimestamp = new Date(secondPhotoTimestamp);
      }

      return firstPhotoTimestamp.getTime() - secondPhotoTimestamp.getTime();
    })
    .reverse()
);

export const getPhotosFolderDownloadProgress = createSelector(
  [getPhotosFolder],
  (
    photosFolder:
      | {
          photosFolder: PhotosFolder;
          pollingStatus: PhotosFolderPollingStatus;
        }
      | undefined
  ) => (photosFolder ? photosFolder.photosFolder.progress : DEFAULT_PROGRESS)
);

export const getPhotosFolderDownloadStatus = createSelector(
  [getPhotosFolder],
  (
    photosFolder:
      | {
          photosFolder: PhotosFolder;
          pollingStatus: PhotosFolderPollingStatus;
        }
      | undefined
  ) => (photosFolder ? photosFolder.photosFolder.status : DEFAULT_STATUS)
);

export const getPhotosFolderPollingStatus = createSelector(
  [getPhotosFolder],
  (
    photosFolder:
      | {
          photosFolder: PhotosFolder;
          pollingStatus: PhotosFolderPollingStatus;
        }
      | undefined
  ) => (photosFolder ? photosFolder.pollingStatus : PhotosFolderPollingStatus.STOPPED)
);

export const getErrorMessage = createSelector([(state: State): string => state.photo.errorMessage], (errorMessage) => errorMessage);

export const getPhotosFolderUrl = (state: State, entityId: string): string => (state.photo.photosFolders[entityId] ? state.photo.photosFolders[entityId].photosFolder.url : "");
