import { createSelector } from "reselect";

import { State } from "../state";

export const isLoggedIn = createSelector([(state: State): boolean => state.login.isLoggedIn], (isUserLoggedIn) => isUserLoggedIn);

// TODO: verify where sub.name comes from (not equal to username in database)
export const getUserName = createSelector([(state: State): string => (state.login.currentUser ? state.login.currentUser.sub.name : "")], (userName: string) => userName);

export const getUserEmail = createSelector([(state: State): string => (state.login.currentUser ? state.login.currentUser.sub.email : "")], (userEmail: string) => userEmail);

export const getUserCode = createSelector([(state: State): string => (state.login.currentUser ? state.login.currentUser.sub.nickname : "")], (userCode: string) => userCode);

export const getErrorMessage = createSelector([(state: State): string => state.login.errorMessage], (errorMessage: string) => errorMessage);
