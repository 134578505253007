import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { forwardRef, RefObject } from "react";
import { Button as SemanticButton, Ref } from "semantic-ui-react";

import { style, styleModifiers } from "./style";

interface IconButtonProps extends ComponentProps {
  children: JSX.Element;
  onClick: () => void;
  color?: string;
  circular?: boolean;
}

const IconButton = forwardRef(({ children, onClick, circular, className }: IconButtonProps, ref): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();

    onClick();
  };

  return (
    <Ref innerRef={ref as RefObject<HTMLElement>}>
      <SemanticButton circular={circular} onClick={handleClick} icon className={className}>
        {children}
      </SemanticButton>
    </Ref>
  );
});

IconButton.displayName = "IconButton";

export default createStyledComponent(IconButton, style, styleModifiers);
