import Local from "app/domain/local/local";

import { Action, PayloadAction } from "../types";

export enum LocalActionTypes {
  GET_LOCALS = "GET_LOCALS",
  GET_LOCALS_REQUEST = "GET_LOCALS_REQUEST",
  GET_LOCALS_SUCCESS = "GET_LOCALS_SUCCESS",
  GET_LOCALS_ERROR = "GET_LOCALS_ERROR",
  GET_ROOM = "GET_ROOM",
  GET_ROOM_REQUEST = "GET_ROOM_REQUEST",
  GET_ROOM_SUCCESS = "GET_ROOM_SUCCESS",
  GET_ROOM_ERROR = "GET_ROOM_ERROR",
  RESET_LOCAL = "RESET_LOCAL"
}

export type GetLocalsAction = PayloadAction<
  LocalActionTypes.GET_LOCALS,
  {
    assetId: string;
  }
>;

export type GetLocalsRequestAction = PayloadAction<
  LocalActionTypes.GET_LOCALS_REQUEST,
  {
    assetId: string;
  }
>;

export type GetLocalsSuccessAction = PayloadAction<
  LocalActionTypes.GET_LOCALS_SUCCESS,
  {
    locals: Local[];
  }
>;

export type GetLocalsErrorAction = PayloadAction<LocalActionTypes.GET_LOCALS_ERROR, { errorMessage: string }>;

export type GetRoomAction = PayloadAction<
  LocalActionTypes.GET_ROOM,
  {
    roomId: string;
  }
>;

export type GetRoomRequestAction = PayloadAction<
  LocalActionTypes.GET_ROOM_REQUEST,
  {
    roomId: string;
  }
>;

export type GetRoomSuccessAction = PayloadAction<
  LocalActionTypes.GET_ROOM_SUCCESS,
  {
    room: Local;
  }
>;

export type GetRoomErrorAction = PayloadAction<LocalActionTypes.GET_ROOM_ERROR, { errorMessage: string }>;

export type ResetAction = Action<LocalActionTypes.RESET_LOCAL>;
