import React from "react";
import Styled from "styled-components";
import { colors } from "ui/colors";

const InfoBubbleLabel = Styled.label`
    span {
        background-color: #DCDCDC;
        border-radius: 50%;
        display: inline-block;
        width: 20px;
        height: 20px;
        text-align: center;
        margin-left: 5px;
        font-weight: bold;
        
        &:hover {
            background-color: ${colors.ACCENT_GREY};
        }
    }
`;

class InfoBubble extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.fields,
      search_term: "",
    };
  }

  render() {
    const { label, infoText } = this.props;

    return (
      <InfoBubbleLabel>
        {label}
        <span title={infoText}>?</span>
      </InfoBubbleLabel>
    );
  }
}

export default InfoBubble;
