/* eslint-disable no-alert */

import FileConverter from "app/utils/fileConverter";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useCallback, useRef, useState } from "react";
import { css } from "styled-components";
import { useContainerVisibleDimensions } from "ui/hook";

import { Loader } from "../loader";
import { WebcamActions } from "./actions";
import WebcamStream from "./stream";
import useWebcamStream from "./useWebcamStream";

const IMAGE_FORMAT = "image/jpeg";

export const webcamStyle = css`
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  img {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

interface WebcamProps extends ComponentProps {
  isOpen: boolean;
  onSubmit: (imageFile: File) => void;
  onClose: () => void;
}

const Webcam: FC<WebcamProps> = ({ isOpen, onSubmit, onClose, className }: WebcamProps) => {
  const [imageDataURL, setImageDataURL] = useState("");
  const [isWebcamFound, setWebcamState] = useState(false);

  const webcamStreamReference = useRef(null);
  const { isStreaming, isStreamVisible, openStream, playStream, pauseStream, closeStream, getScreenShot } = useWebcamStream(webcamStreamReference);

  const webcamContainerReference = useRef(null);
  const webcamContainerDimensions = useContainerVisibleDimensions(webcamContainerReference);

  const handleCapture = useCallback(() => {
    const dataURL = getScreenShot();
    setImageDataURL(dataURL);
    pauseStream();
  }, [getScreenShot, pauseStream]);

  const handleClose = useCallback(() => {
    setWebcamState(false);
    onClose();
    closeStream();
  }, [onClose, closeStream]);

  const handleSubmit = useCallback(async () => {
    const imageFile = await FileConverter.toFile(imageDataURL, IMAGE_FORMAT);

    onSubmit(imageFile);
    handleClose();
  }, [imageDataURL, onSubmit, handleClose]);

  const handleRetry = useCallback(() => {
    playStream();
  }, [playStream]);

  const webcamFound = useCallback(() => {
    setWebcamState(true);
    openStream();
  }, [openStream]);

  const webcamNotFound = useCallback(() => {
    setWebcamState(false);
    alert("Impossible de se connecter à la caméra");
    handleClose();
  }, [handleClose]);

  return (
    <>
      <div className={className} style={{ display: isOpen && isStreamVisible ? "block" : "none" }} ref={webcamContainerReference} data-test-id="webcamContainer">
        {isOpen && <WebcamStream ref={webcamStreamReference} onUserMedia={webcamFound} onUserMediaError={webcamNotFound} data-test-id="webcamStream" />}
        <WebcamActions
          isStreaming={isStreaming}
          onCapture={handleCapture}
          onSubmit={handleSubmit}
          onRetry={handleRetry}
          onClose={handleClose}
          modifiers={webcamContainerDimensions.width >= webcamContainerDimensions.height ? "vertical" : "horizontal"}
          data-test-id="webcamActions"
        />
      </div>
      <Loader isActive={isOpen && isWebcamFound && !isStreamVisible} data-test-id="webcamLoader" />
    </>
  );
};

export default createStyledComponent(memo(Webcam), webcamStyle);
