import { Params, useRouter } from "app/routing/routerProvider";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { useLoader } from "app/view/common/loader/loaderProvider";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { css } from "styled-components";
import { Loader } from "ui/loader";

import ProjectForm from "./projectForm";

const modifyProjectContainerStyle = css`
  position: relative;
  flex: 1;
`;

type ModifyProjectContainerProps = ComponentProps;

const ModifyProjectContainer: FC<ModifyProjectContainerProps> = ({ className }: ModifyProjectContainerProps) => {
  const { getParam } = useRouter();
  const { isActive } = useLoader();
  const { errorMessage, onDismiss } = useErrorMessage();

  const dispatch = useDispatch();

  const projectId = getParam(Params.PROJECT);

  useEffect(() => {
    return (): void => {
      onDismiss();
    };
  }, [dispatch, onDismiss]);

  return (
    <div className={className}>
      <Loader isActive={isActive} />
      <ProjectForm projectId={projectId} errorMessage={errorMessage} onReset={onDismiss} />
    </div>
  );
};

export default createStyledComponent(ModifyProjectContainer, modifyProjectContainerStyle);
