/* stylelint-disable no-duplicate-selectors */

import Checkbox from "@mui/material/Checkbox";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useRef, useState } from "react";
import { css } from "styled-components";
import { useClickEnd, useClickStart, useToggle } from "ui/hook";

import * as constants from "../constants";

export const selectableCardStyle = css`
  & {
    display: flex;
    margin: auto;
    cursor: pointer;
    outline: none;
    border-radius: ${constants.DEFAULT_CARD_BORDER_RADIUS};
    box-shadow: ${constants.DEFAULT_CARD_BOX_SHADOW};
    transition: transform 0.2s;

    && .card {
      border-radius: inherit;
      box-shadow: inherit;
    }

    && .card:hover {
      border-radius: inherit;
      box-shadow: inherit;
    }
  }

  &.active {
    transform: scale(0.95);
  }

  &[data-selected="true"] {
    border-radius: ${constants.SELECTED_CARD_BORDER_RADIUS};
    box-shadow: ${constants.SELECTED_CARD_BOX_SHADOW};
  }

  &:hover[data-selected="false"] {
    border-radius: ${constants.HOVERED_CARD_BORDER_RADIUS};
    box-shadow: ${constants.HOVERED_CARD_BOX_SHADOW};
  }
`;

interface SelectableCardProps extends ComponentProps {
  children: JSX.Element | JSX.Element[];
  imageIndex: number;
  onClick: () => void;
  onOpenImage: (imageIndex: number) => void;
}

const SelectableCard: FC<SelectableCardProps> = ({ children, imageIndex, onClick, onOpenImage, className }: SelectableCardProps) => {
  const { isToggleOn: isSelected, toggle: toggleSelection } = useToggle(false);

  const cardReference = useRef(null);

  useClickStart(cardReference, () => {
    if (cardReference.current) {
      (cardReference.current as unknown as HTMLDivElement).classList.add("active");
    }
  });

  useClickEnd(cardReference, () => {
    if (cardReference.current) {
      (cardReference.current as unknown as HTMLDivElement).classList.remove("active");
    }
  });

  const selectPhoto = (): void => {
    onOpenImage(imageIndex);
  };

  const handleClick = (): void => {
    toggleSelection();
    onClick();
  };

  return (
    <div>
      <Checkbox checked={isSelected} onChange={handleClick} inputProps={{ "aria-label": "controlled" }} />
      <div
        className={className}
        onClick={selectPhoto}
        onKeyDown={(): void => {}}
        role="button"
        tabIndex={0}
        data-selected={isSelected}
        ref={cardReference}
        data-testid="photoCheckbox"
      >
        {children}
      </div>
    </div>
  );
};

export default createStyledComponent(SelectableCard, selectableCardStyle);
