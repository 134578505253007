export const colors = {
  PRIMARY_WHITE: "#FFFFFF",
  SECONDARY_BLACK: "#2D2D2D",
  ACCENT_BLUE: "#04A4CC",
  ACCENT_BLUE_DARK: "#027997",
  ACCENT_GREY: "#f0f0f0",
  ACCENT_RED: "#FF1919",
  ACCENT_GREEN: "#32CD30",
  BLUE: "rgba(63, 81, 181, 0.5)",
  SWISS_COFFEE: "#dcd3d4",
  SILVER: "#c6c6c6",
  COLOR_WHEN_READ: "rgba(0, 0, 0, 0.10)"
};
