import { Requirement } from "app/domain/requirement";
import { getUserName } from "app/store/login/selectors";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

export type RequirementFields = {
  [key: string]: any;
};

type RequirementHook = {
  currentRequirement: any;
  onUpdate: (fields: RequirementFields) => void;
};

const useRequirement = (initialRequirement: Requirement | undefined, auditDate: string | undefined): RequirementHook => {
  const userName = useSelector(getUserName);

  const [currentRequirement, setCurrentRequirement] = useState({
    ...initialRequirement,
    created_hour: initialRequirement && initialRequirement.created_hour ? initialRequirement.created_hour : new Date().toLocaleString(),
    auditDate: initialRequirement && initialRequirement.auditDate ? initialRequirement.auditDate : auditDate,
    activity: initialRequirement && initialRequirement.activity ? initialRequirement.activity : "",
    localisation: initialRequirement && initialRequirement.localisation ? initialRequirement.localisation : "",
    auditor: initialRequirement && initialRequirement.auditor ? initialRequirement.auditor : userName,
    errors: initialRequirement ? initialRequirement.errors : []
  });

  const onUpdate = useCallback((fields: RequirementFields) => {
    setCurrentRequirement((prevState) => ({
      ...prevState,
      ...fields
    }));
  }, []);

  useEffect(() => {
    if (initialRequirement) {
      onUpdate(initialRequirement);
    }

    if (auditDate) {
      onUpdate({ auditDate });
    }
  }, [initialRequirement, auditDate, onUpdate]);

  return useMemo(
    () => ({
      currentRequirement,
      onUpdate
    }),
    [currentRequirement, onUpdate]
  );
};

export default useRequirement;
