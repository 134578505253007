import SearchItem from "app/domain/search/searchItem";

export type SearchState = Readonly<{
  isFetching: boolean;
  searchResults: SearchItem[];
  errorMessage: string;
}>;

export const initialSearchState: SearchState = {
  isFetching: false,
  searchResults: [],
  errorMessage: ""
};
