import { Modal as MaterialModal } from "@mui/material";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import Button from "ui/button/button";
import { style } from "ui/modal/style";

const BUTTON_TITLE_CANCEL = "Annuler";
const BUTTON_TITLE_CLOSE = "Confirmer";

interface ModalProps extends ComponentProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  content?: JSX.Element[] | JSX.Element;
  actions?: JSX.Element[];
  padding?: string;
}

const Modal: FC<ModalProps> = ({ isOpen, title, content, actions, onClose, className, padding }: ModalProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
  };

  return (
    <MaterialModal open={isOpen} className={className} onClose={onClose} onClick={handleClick} data-test-id="modal">
      <div className="modal-container" style={{ padding: !padding ? "auto" : `${padding}` }}>
        <div data-test-id="header" className="header">
          <div data-test-id="title">{title}</div>
        </div>
        <div data-test-id="content" className="content">
          {content}
        </div>
        <div data-test-id="footer" className="footer">
          {onClose && (
            <Button
              title={actions && actions.length > 0 ? BUTTON_TITLE_CANCEL : BUTTON_TITLE_CLOSE}
              modifiers={actions && actions.length > 0 ? "outlined" : ""}
              data-test-id="cancelButton"
              color={actions && actions.length > 0 ? "#d80000" : ""}
              onClick={onClose}
            />
          )}

          {actions}
        </div>
      </div>
    </MaterialModal>
  );
};

export default createStyledComponent(Modal, style);
