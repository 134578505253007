import { Error } from "app/domain/common";
import moment from "moment";

interface AssetProps {
  id?: string;
  errors?: Error[];
  site_id?: string;
  customerReference?: string;
  asset_number?: string;
  name?: string;
  asset_use?: string[];
  year_constructed?: moment.Moment;
  year_renovated?: moment.Moment;
  area?: number;
  nb_floors_basement?: number;
  nb_floors_total?: number;
  has_underground_parking?: boolean;
  assessment_date?: string;
  year_of_data?: number;
  auditDate?: string;
  auditorBuildingEnvelope?: string;
  auditorInterior?: string;
  auditorMechanical?: string;
  auditorElectrical?: string;
  building_cost_category?: string;
  replacement_value?: number;
  is_audit_done?: boolean;
  civic_number?: string;
  street_name?: string;
  city?: string;
  postal_code?: string;
  region?: string;
  province?: string;
  country?: string;
  heritage: boolean;
  description?: string;
  areaToInspect?: number;
  surface_base?: number;
  surface_adjustment?: number;
  dimension?: number;
  estimated_condition_grading?: string;
  calculatedConditionGrading?: string;
  architectural_progress?: number;
  mechanical_progress?: number;
  ivp?: number;
  sum_requirements_prices?: number;
  pastIVP?: string;
  pastRI?: number;
  client_comments?: string;
  comment_about_area?: string;
  siteName?: string;
  commentsCount?: number;
}

class Asset {
  id?: string;
  errors?: Error[];
  site_id?: string;
  customerReference?: string;
  asset_number?: string;
  name?: string;
  asset_use?: string[];
  year_constructed?: moment.Moment;
  year_renovated?: moment.Moment;
  area?: number;
  nb_floors_basement?: number;
  nb_floors_total?: number;
  has_underground_parking?: boolean;
  assessment_date?: string;
  year_of_data?: number;
  auditDate?: string;
  auditorBuildingEnvelope?: string;
  auditorInterior?: string;
  auditorMechanical?: string;
  auditorElectrical?: string;
  building_cost_category?: string;
  replacement_value?: number;
  is_audit_done?: boolean;
  civic_number?: string;
  street_name?: string;
  city?: string;
  postal_code?: string;
  region?: string;
  province?: string;
  country?: string;
  heritage: boolean;
  description?: string;
  areaToInspect?: number;
  surface_base?: number;
  surface_adjustment?: number;
  dimension?: number;
  estimated_condition_grading?: string;
  calculatedConditionGrading?: string;
  architectural_progress?: number;
  mechanical_progress?: number;
  ivp?: number;
  sum_requirements_prices?: number;
  pastIVP?: string;
  pastRI?: number;
  client_comments?: string;
  comment_about_area?: string;
  siteName?: string;
  commentsCount?: number;

  constructor(assetProps: AssetProps) {
    this.id = assetProps.id;
    this.errors = assetProps.errors;
    this.site_id = assetProps.site_id;
    this.customerReference = assetProps.customerReference;
    this.asset_number = assetProps.asset_number;
    this.name = assetProps.name;
    this.asset_use = assetProps.asset_use;
    this.year_constructed = assetProps.year_constructed;
    this.year_renovated = assetProps.year_renovated;
    this.area = assetProps.area;
    this.nb_floors_basement = assetProps.nb_floors_basement;
    this.nb_floors_total = assetProps.nb_floors_total;
    this.has_underground_parking = assetProps.has_underground_parking;
    this.assessment_date = assetProps.assessment_date;
    this.year_of_data = assetProps.year_of_data;
    this.auditDate = assetProps.auditDate;
    this.auditorBuildingEnvelope = assetProps.auditorBuildingEnvelope;
    this.auditorInterior = assetProps.auditorInterior;
    this.auditorMechanical = assetProps.auditorMechanical;
    this.auditorElectrical = assetProps.auditorElectrical;
    this.building_cost_category = assetProps.building_cost_category;
    this.replacement_value = assetProps.replacement_value;
    this.is_audit_done = assetProps.is_audit_done;
    this.civic_number = assetProps.civic_number;
    this.street_name = assetProps.street_name;
    this.city = assetProps.city;
    this.postal_code = assetProps.postal_code;
    this.region = assetProps.region;
    this.province = assetProps.province;
    this.country = assetProps.country;
    this.heritage = assetProps.heritage;
    this.description = assetProps.description;
    this.areaToInspect = assetProps.areaToInspect;
    this.surface_base = assetProps.surface_base;
    this.surface_adjustment = assetProps.surface_adjustment;
    this.dimension = assetProps.dimension;
    this.estimated_condition_grading = assetProps.estimated_condition_grading;
    this.calculatedConditionGrading = assetProps.calculatedConditionGrading;
    this.architectural_progress = assetProps.architectural_progress;
    this.mechanical_progress = assetProps.mechanical_progress;
    this.ivp = assetProps.ivp;
    this.sum_requirements_prices = assetProps.sum_requirements_prices;
    this.pastIVP = assetProps.pastIVP;
    this.pastRI = assetProps.pastRI;
    this.client_comments = assetProps.client_comments;
    this.comment_about_area = assetProps.comment_about_area;
    this.siteName = assetProps.siteName;
    this.commentsCount = assetProps.commentsCount;
  }
}

export default Asset;
