import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { Label } from "semantic-ui-react";
import { css } from "styled-components";
import Icon from "ui/icon/icon";
import { IconType } from "ui/icon/type";

export const iconStyle = css`
  padding-left: 10px;
  padding-right: 10px;
`;

interface NotificationIconProps extends ComponentProps {
  quantity: number;
  type: IconType;
  onClick: () => void;
}

const NotificationIcon: FC<NotificationIconProps> = ({ quantity, type, onClick, className }: NotificationIconProps) => (
  <div className={className} onClick={onClick}>
    <Icon type={type} color="white" />
    {quantity !== 0 && (
      <Label circular color="red">
        {quantity}
      </Label>
    )}
  </div>
);

export default createStyledComponent(memo(NotificationIcon), iconStyle);
