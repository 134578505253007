import { SystemModelAction } from "app/domain/system";
import CostLineMapper, { CostLineDTO } from "app/service/costItem/costLineMapper";

export type SystemModelActionDTO = {
  id: string;
  system_model_id: string;
  action_type: string;
  model_number: string;
  renewal_percentage: number;
  description: string;
  unit_cost: number;
  unit_of_measure: string;
  lifetime: number;
  comment: string;
  factor: number;
  updated_date: string;
  is_deleted: boolean;
  cost_lines?: CostLineDTO[];
};

class SystemModelActionMapper {
  static fromSystemModelActionDTO(systemModelActionDTO: SystemModelActionDTO): SystemModelAction;
  static fromSystemModelActionDTO(systemModelActionDTOs: SystemModelActionDTO[]): SystemModelAction[];
  static fromSystemModelActionDTO(systemModelActionDTOs: SystemModelActionDTO | SystemModelActionDTO[]): SystemModelAction | SystemModelAction[] {
    if (!Array.isArray(systemModelActionDTOs)) {
      return new SystemModelAction({
        id: systemModelActionDTOs.id,
        systemModelId: systemModelActionDTOs.system_model_id,
        actionType: systemModelActionDTOs.action_type,
        modelNumber: systemModelActionDTOs.model_number,
        renewalPercentage: systemModelActionDTOs.renewal_percentage,
        description: systemModelActionDTOs.description,
        unitCost: systemModelActionDTOs.unit_cost,
        unitOfMeasure: systemModelActionDTOs.unit_of_measure,
        lifetime: systemModelActionDTOs.lifetime,
        comment: systemModelActionDTOs.comment,
        factor: systemModelActionDTOs.factor,
        costLines: systemModelActionDTOs.cost_lines ? CostLineMapper.fromDTO(systemModelActionDTOs.cost_lines) : []
      });
    }
    return systemModelActionDTOs.map((systemModelActionDTO: SystemModelActionDTO): SystemModelAction => this.fromSystemModelActionDTO(systemModelActionDTO));
  }
}

export default SystemModelActionMapper;
