import { RefObject, useEffect } from "react";

type Target = Element | Document;
type Ref<E extends Target> = RefObject<E>;
type Args<E extends Target> = Parameters<E["addEventListener"]>;

const useEvent = <E extends Target>(ref: Ref<E> | Document, ...[type, listener, options]: Args<E>): void => {
  useEffect(() => {
    const target = (ref as Ref<E>).current ? (ref as Ref<E>).current : document;

    if (target) {
      target.addEventListener(type, listener, options);
    }

    return (): void => {
      if (target) {
        target.removeEventListener(type, listener, options);
      }
    };
  });
};

export default useEvent;
