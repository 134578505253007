let timerId;
let callbackFn;
let autoLogOutDelayInMillisecond = 3600000;

export function startIdleService(callback) {
  callbackFn = callback;
  timerId = setTimeout(() => {
    if (typeof callback === "function") {
      callback();
    }
  }, autoLogOutDelayInMillisecond);
}

function resetIdleService() {
  clearTimeout(timerId);
  startIdleService(callbackFn);
}

export function setupTimers() {
  document.addEventListener("mousemove", resetIdleService, false);
  document.addEventListener("mousedown", resetIdleService, false);
  document.addEventListener("keypress", resetIdleService, false);
  document.addEventListener("touchmove", resetIdleService, false);
  document.addEventListener("onscroll", resetIdleService, false);
}
