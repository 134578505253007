/* stylelint-disable no-descending-specificity */

import { ComponentProps, createStyledComponent, StyleModifiers, StyleProps } from "common/style/createStyledComponent";
import { transparentize } from "polished";
import React, { FC } from "react";
import { Label as SemanticLabel } from "semantic-ui-react";
import { css, SimpleInterpolation } from "styled-components";
import { IconButton } from "ui/button";
import { Icon, IconType } from "ui/icon";

import * as constants from "./constants";

const badgeStyle = css`
  && {
    display: flex;
    width: fit-content;
    user-select: none;
    background-color: white;
    border-radius: ${constants.DEFAULT_BADGE_BORDER_RADIUS};

    .label {
      border-bottom-right-radius: ${(props: StyleProps): string => (props.onDelete ? "0" : constants.DEFAULT_BADGE_BORDER_RADIUS)};
      border-top-right-radius: ${(props: StyleProps): string => (props.onDelete ? "0" : constants.DEFAULT_BADGE_BORDER_RADIUS)};
      margin: 0;
      padding: ${constants.DEFAULT_BADGE_PADDING};
      color: white;
      background-color: ${(props: StyleProps): string => props.color || constants.DEFAULT_BADGE_COLOR};
      height: fit-content;
      width: fit-content;
    }

    ${IconButton as unknown as SimpleInterpolation} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      font-size: ${constants.DEFAULT_BADGE_FONT_SIZE};
      padding: ${constants.DEFAULT_BADGE_PADDING};
      max-height: ${constants.DEFAULT_BADGE_HEIGHT};
    }
  }
`;

export const badgeStyleModifiers: StyleModifiers = {
  outlined: (props: StyleProps) => css`
    && {
      .label {
        position: relative;
        color: ${props.color || constants.DEFAULT_BADGE_COLOR};
        background-color: ${transparentize(0.8, props.color || constants.DEFAULT_BADGE_COLOR)};

        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: -1px;
          top: 0;
          bottom: 0;
          border: 1px solid ${transparentize(0.4, props.color || constants.DEFAULT_BADGE_COLOR)};
          border-radius: inherit;
        }
      }
    }
  `,
  pill: (props: StyleProps) => css`
    && {
      border-radius: 16px;

      .label {
        border-radius: 16px ${props.onDelete ? "0" : "16px"} ${props.onDelete ? "0" : "16px"} 16px;
        padding: 0.5833em 0.833em;
      }
    }
  `
};

interface BadgeProps extends ComponentProps {
  label: string;
  onDelete?: () => void;
  color?: string;
}

const Badge: FC<BadgeProps> = ({ label, onDelete, color, className, modifiers }: BadgeProps) => (
  <div className={className}>
    <SemanticLabel className="label" data-test-id="badgeLabel">
      {label}
    </SemanticLabel>
    {onDelete && (
      <IconButton onClick={onDelete} modifiers={modifiers} color={color} data-test-id="iconButton">
        <Icon type={IconType.CANCEL} data-test-id="icon" />
      </IconButton>
    )}
  </div>
);

export default createStyledComponent(Badge, badgeStyle, badgeStyleModifiers);
