/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import { Requirement } from "app/domain/requirement";
import { System } from "app/domain/system";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";
import { colors } from "ui/colors";

import CirclePercentage from "./circlePercentage";
import FormOverviewNav, { NavigationLink } from "./formOverviewNav";
import NavigationButtons from "./navigationButtons";

const formOverviewStyle = css`
  height: 82vh;
  width: 18%;
  background-color: ${colors.ACCENT_GREY};
  position: fixed;
  right: 10px;

  svg {
    margin-left: 20%;
  }

  h2 {
    text-align: center;
    margin-top: 8px;
  }
`;

interface FormOverviewProps extends ComponentProps {
  formSectionNavigationLinks: NavigationLink[];
  percentage: number;
  itemId?: string;
  tabIndex?: number;
  items?: System[] | Requirement[];
}

const FormOverview: FC<FormOverviewProps> = ({ itemId, formSectionNavigationLinks, percentage, items, tabIndex, className }: FormOverviewProps) => {
  return (
    <div className={className}>
      <h2>Terminé à</h2>
      <CirclePercentage percentage={percentage} diameter={50} />
      {items && itemId && items[0] ? <NavigationButtons itemId={itemId} items={items} tabIndex={tabIndex} data-test-id="navigationButtons" /> : null}
      <FormOverviewNav formSectionNavigationLinks={formSectionNavigationLinks} />
    </div>
  );
};

export default createStyledComponent(FormOverview, formOverviewStyle);
