/* eslint-disable @typescript-eslint/ban-ts-comment */
import { EntityCategory } from "app/domain/entityCategory";
import Photo, { PhotoFactory, PhotosFolder, PhotosFolderDownloadStatus, PhotoStatus } from "app/domain/photo";
import PhotoService from "app/service/photo/photoAPI";
import FileDownloader from "app/utils/fileDownloader";
import { all, call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  addPhotosErrorAction,
  addPhotosRequestAction,
  addPhotosSuccessAction,
  deletePhotosErrorAction,
  deletePhotosRequestAction,
  deletePhotoSuccessAction,
  getPhotosErrorAction,
  getPhotosFolderErrorAction,
  getPhotosFolderRequestAction,
  getPhotosRequestAction,
  getPhotosSuccessAction,
  setPhotosFolderAction,
  stopDownloadingPhotosFolderAction
} from "./actions";
import { getPhotosFolderPollingStatus } from "./selectors";
import { PhotosFolderPollingStatus } from "./state";
import {
  AddPhotosAction,
  AddPhotosRequestAction,
  DeletePhotosAction,
  DeletePhotosRequestAction,
  GetPhotosAction,
  GetPhotosFolderRequestAction,
  GetPhotosRequestAction,
  PhotoActionTypes,
  StartDownloadingPhotosFolderAction
} from "./types";

export const GET_PHOTOS_ERROR = "Une erreur s'est produite lors de la récupération des photos. Veuillez réessayer.";
export const ADD_PHOTOS_ERROR = "Une erreur s'est produite lors de l'ajout des photos. Veuillez réessayer.";
export const DELETE_PHOTOS_ERROR = "Une erreur s'est produite lors de la suppression des photos. Veuillez réessayer.";
export const GET_PHOTOS_FOLDER_ERROR = "Une erreur s'est produite lors de la récupération du dossier de photos. Veuillez réessayer.";

const DELAY = 3000;

export function* addPhotos(action: AddPhotosAction): Generator {
  yield put(addPhotosRequestAction(action.payload.photoFiles, action.payload.entityId, action.payload.entityCategory));
}

function* addPhotoRequest(photo: Photo, entityId: string, entityCategory: EntityCategory): Generator {
  const uploadedPhoto = yield call(PhotoService.addPhoto, photo, entityId, entityCategory);

  yield put(addPhotosSuccessAction(uploadedPhoto as Photo[], entityId));
}

export function* addPhotosRequest(action: AddPhotosRequestAction): Generator {
  try {
    const pendingPhotos = yield call(PhotoFactory.create, action.payload.photoFiles, PhotoStatus.PENDING);

    yield all((pendingPhotos as Photo[]).map((pendingPhoto: Photo) => addPhotoRequest(pendingPhoto, action.payload.entityId, action.payload.entityCategory)));
  } catch (error) {
    yield put(addPhotosErrorAction(ADD_PHOTOS_ERROR, action.payload.entityId));
  }
}

export function* deletePhotos(action: DeletePhotosAction): Generator {
  yield put(deletePhotosRequestAction(action.payload.photoIds, action.payload.entityId));
}

function* deletePhotoRequest(photoId: string, entityId: string): Generator {
  yield call(PhotoService.deletePhoto, photoId);

  yield put(deletePhotoSuccessAction(photoId, entityId));
}

export function* deletePhotosRequest(action: DeletePhotosRequestAction): Generator {
  try {
    yield all(action.payload.photoIds.map((photoId) => deletePhotoRequest(photoId, action.payload.entityId)));
  } catch (error) {
    yield put(deletePhotosErrorAction(DELETE_PHOTOS_ERROR, action.payload.entityId));
  }
}

export function* getPhotos(action: GetPhotosAction): Generator {
  yield put(getPhotosRequestAction(action.payload.entityId, action.payload.entityCategory));
}

export function* getPhotosRequest(action: GetPhotosRequestAction): Generator {
  try {
    const photos = yield call(PhotoService.getPhotos, action.payload.entityId, action.payload.entityCategory);
    yield put(getPhotosSuccessAction(photos as Photo[], action.payload.entityId));
  } catch (error) {
    yield put(getPhotosErrorAction(GET_PHOTOS_ERROR, action.payload.entityId));
  }
}

export function* startDownloadingPhotosFolder(action: StartDownloadingPhotosFolderAction): Generator {
  yield put(getPhotosFolderRequestAction(action.payload.entityId, action.payload.entityCategory));
}

export function* pollTaskGetPhotosFolder(action: GetPhotosFolderRequestAction): Generator {
  while (true) {
    try {
      const photosFolder = yield call(PhotoService.getFolderStatus, action.payload.entityId, action.payload.entityCategory);

      yield put(setPhotosFolderAction(photosFolder as PhotosFolder, action.payload.entityId, action.payload.entityCategory));

      if ((photosFolder as PhotosFolder).status === PhotosFolderDownloadStatus.FAILED) {
        yield put(getPhotosFolderErrorAction(GET_PHOTOS_FOLDER_ERROR, action.payload.entityId));
      }

      // @ts-ignore
      const pollingStatus = yield select(getPhotosFolderPollingStatus, action.payload.entityId, action.payload.entityCategory);

      if ((photosFolder as PhotosFolder).status === PhotosFolderDownloadStatus.COMPLETED) {
        yield call(FileDownloader.download, (photosFolder as PhotosFolder).url);
      }

      if ((photosFolder as PhotosFolder).status === PhotosFolderDownloadStatus.COMPLETED || pollingStatus === PhotosFolderPollingStatus.STOPPED) {
        yield put(stopDownloadingPhotosFolderAction(action.payload.entityId));
        return false;
      }
      yield delay(DELAY);
    } catch (error) {
      yield put(getPhotosFolderErrorAction(GET_PHOTOS_FOLDER_ERROR, action.payload.entityId));
      return false;
    }
  }
}

export function* startTheCreation(action: GetPhotosFolderRequestAction): Generator {
  try {
    const photosFolder = yield call(PhotoService.startFolderCreation, action.payload.entityId, action.payload.entityCategory);

    yield put(setPhotosFolderAction(photosFolder as PhotosFolder, action.payload.entityId, action.payload.entityCategory));
    yield call(pollTaskGetPhotosFolder, action);
  } catch (error) {
    yield put(getPhotosFolderErrorAction(GET_PHOTOS_FOLDER_ERROR, action.payload.entityId));
  }
}

function* photosSaga(): Generator {
  yield takeLatest(PhotoActionTypes.ADD_PHOTOS, addPhotos);
  yield takeEvery(PhotoActionTypes.ADD_PHOTOS_REQUEST, addPhotosRequest);
  yield takeLatest(PhotoActionTypes.DELETE_PHOTOS, deletePhotos);
  yield takeLatest(PhotoActionTypes.DELETE_PHOTOS_REQUEST, deletePhotosRequest);
  yield takeLatest(PhotoActionTypes.GET_PHOTOS, getPhotos);
  yield takeLatest(PhotoActionTypes.GET_PHOTOS_REQUEST, getPhotosRequest);
  yield takeEvery(PhotoActionTypes.GET_PHOTOS_FOLDER_REQUEST, startTheCreation);
  yield takeLatest(PhotoActionTypes.START_DOWNLOADING_PHOTOS_FOLDER_ACTION, startDownloadingPhotosFolder);
}

export default photosSaga;
