import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { SystemChildren as SystemChildrenClass } from "app/domain/systemChildren";
import systemChildrenService from "app/service/systemChildren/systemChildrenService";
import _ from "lodash";
import React, { FC, useEffect } from "react";
import { useAsync } from "react-async";
// eslint-disable-next-line @typescript-eslint/ban-types
const filter = createFilterOptions<string>();

interface SystemChildrenProps {
  systemId: string;
  siteId: string;
}
interface ChildrenType {
  id: string;
  custom_name_concat: string;
  reference: string;
}
const SystemChildren: FC<SystemChildrenProps> = ({ systemId, siteId }: SystemChildrenProps) => {
  const [type, setType] = React.useState("");
  const [textValue, setTextValue] = React.useState<string>("");
  const [options, setOptions] = React.useState<string[]>([]);
  const [children, setChildren] = React.useState<ChildrenType[]>([]);
  const [searchOptions, setSearchOptions] = React.useState<ChildrenType[]>([]);
  const [searchValue, setSearchValue] = React.useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setType((event.target as HTMLInputElement).value);
  };

  const putSystemChildrens = (): Promise<SystemChildrenClass> => {
    const child_ids = _.compact(children.map((_children) => _children.id));
    const Children = _.isEqual(type, "text") ? { child_type: type, child_text_value: textValue } : { child_type: type, child_ids };

    return systemChildrenService.putSystemChildren(systemId, Children);
  };

  const onDelete = (id: string) => (): void => {
    setChildren(children.filter((_children) => !_.isEqual(_children.id, id)));
  };

  const fetchSystemChildrens = (): Promise<SystemChildrenClass> => {
    return systemChildrenService.getSystemChildren(systemId);
  };

  const handleSuccess = (fetchedSystemChildrens: SystemChildrenClass): void => {
    setType(fetchedSystemChildrens.childType);
    setTextValue(fetchedSystemChildrens.childTextValue as string);
    setOptions(fetchedSystemChildrens.textValueOptions);
    setChildren(fetchedSystemChildrens.children as ChildrenType[]);
  };

  const fetchSearch = (text: string[]): Promise<ChildrenType> => {
    return systemChildrenService.searchSystemChildren(siteId, text[0]);
  };

  const handleSuccessSearch = (fetchedOption: ChildrenType[]): void => {
    setSearchOptions(_.uniqBy(fetchedOption, "id"));
  };

  const { run } = useAsync({
    deferFn: fetchSystemChildrens,
    onResolve: handleSuccess
  });

  const { run: fetchPutSystemChildrens } = useAsync({
    deferFn: putSystemChildrens
  });

  const { run: search } = useAsync({
    deferFn: fetchSearch,
    onResolve: handleSuccessSearch
  } as any);

  useEffect(() => {
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box paddingX={3}>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={1}>
          <Button variant="outlined" size="large" onClick={fetchPutSystemChildrens}>
            <SaveIcon sx={{ marginRight: 1 }} />
            Enregistrer
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormLabel id="demo-row-radio-buttons-group-label">Enfants :</FormLabel>
              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={type} onChange={handleChange} sx={{ marginLeft: 2 }}>
                <FormControlLabel value="systems" control={<Radio />} label="Systèmes" />
                <FormControlLabel value="text" control={<Radio />} label="Texte" />
              </RadioGroup>
            </Box>
          </FormControl>
        </Grid>
        {_.isEqual(type, "text") && (
          <Grid item xs={12} marginLeft={8}>
            <Autocomplete
              value={textValue}
              onChange={(_event, newValue): any => {
                setTextValue(newValue as string);
              }}
              // eslint-disable-next-line no-shadow
              filterOptions={(options, params): any => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={options}
              getOptionLabel={(option): any => {
                return option;
              }}
              renderOption={(props, option): any => <li {...props}>{option}</li>}
              sx={{ width: 500 }}
              freeSolo
              renderInput={(params): any => <TextField {...params} label="text" />}
            />
          </Grid>
        )}
        {_.isEqual(type, "systems") && (
          <>
            <Grid item xs={12} marginLeft={8}>
              <Autocomplete
                freeSolo
                disableClearable
                options={searchOptions.map((option) => option.custom_name_concat)}
                onChange={(_event, newValue): any => {
                  setChildren((_children) => [..._children, searchOptions.find((_option) => _.isEqual(_option.custom_name_concat, newValue))] as ChildrenType[]);
                  setSearchOptions([]);
                  setSearchValue("");
                }}
                value={searchValue}
                renderInput={(params): any => (
                  <TextField
                    {...params}
                    label="Recherche par reference ou nom"
                    value={searchValue}
                    onChange={(event): any => {
                      setSearchValue(event.target.value);
                      search(event.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      type: "search"
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} marginLeft={8}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Reference</TableCell>
                      <TableCell>Nom Concat</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {children.map((row) => (
                      <TableRow key={row.reference} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row.reference}
                        </TableCell>
                        <TableCell>{row.custom_name_concat}</TableCell>
                        <TableCell>
                          <IconButton aria-label="delete" size="large" onClick={onDelete(row.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default SystemChildren;
