/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";
import { Site } from "app/domain/site";

import {
  generateSiteReportAction,
  generateSiteReportErrorAction,
  generateSiteReportRequestAction,
  generateSiteReportSuccessAction,
  getSiteAction,
  getSiteErrorAction,
  getSiteRequestAction,
  getSitesAction,
  getSitesErrorAction,
  getSitesRequestAction,
  getSitesSuccessAction,
  getSiteSuccessAction,
  resetAction,
  updateSiteAction,
  updateSiteErrorAction,
  updateSiteRequestAction,
  updateSiteSuccessAction
} from "./actions";
import { initialSiteState, SiteState } from "./state";
import {
  GenerateSiteReportErrorAction,
  GetSiteErrorAction,
  GetSitesErrorAction,
  GetSitesSuccessAction,
  GetSiteSuccessAction,
  UpdateSiteErrorAction,
  UpdateSiteSuccessAction
} from "./types";

const replaceIn = (sites: Site[], newSite: Site): Site[] => {
  const indexOfSiteToReplace = sites.findIndex((site: Site) => site.id === newSite.id);
  sites[indexOfSiteToReplace] = newSite;
  return sites;
};

const getSite = (state: SiteState): void => {
  state.errorMessage = "";
};

const getSiteRequest = (state: SiteState): void => {
  state.isLoading = true;
};

const getSiteSuccess = (state: SiteState, action: GetSiteSuccessAction): void => {
  state.isLoading = false;
  state.currentSite = action.payload.site;
};

const getSiteError = (state: SiteState, action: GetSiteErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const getSites = (state: SiteState): void => {
  state.errorMessage = "";
};

const getSitesRequest = (state: SiteState): void => {
  state.isLoading = true;
};

const getSitesSuccess = (state: SiteState, action: GetSitesSuccessAction): void => {
  state.isLoading = false;
  state.sites = action.payload.sites;
};

const getSitesError = (state: SiteState, action: GetSitesErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const updateSite = (state: SiteState): void => {
  state.errorMessage = "";
};

const updateSiteRequest = (state: SiteState): void => {
  state.isLoading = true;
};

const updateSiteSuccess = (state: SiteState, action: UpdateSiteSuccessAction): void => {
  state.isLoading = false;
  state.currentSite = action.payload.site;
  state.sites = replaceIn(state.sites, action.payload.site);
};

const updateSiteError = (state: SiteState, action: UpdateSiteErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const generateSiteReport = (state: SiteState): void => {
  state.errorMessage = "";
};

const generateSiteReportRequest = (state: SiteState): void => {
  state.isLoading = true;
};

const generateSiteReportSuccess = (state: SiteState): void => {
  state.isLoading = false;
};

const generateSiteReportError = (state: SiteState, action: GenerateSiteReportErrorAction): void => {
  state.errorMessage = action.payload.errorMessage;
  state.isLoading = false;
};

const reset = (state: SiteState): void => {
  state.errorMessage = "";
};

const siteReducer = createReducer(initialSiteState, {
  [getSiteAction.type]: (state) => getSite(state),
  [getSiteRequestAction.type]: (state) => getSiteRequest(state),
  [getSiteSuccessAction.type]: (state, action) => getSiteSuccess(state, action),
  [getSiteErrorAction.type]: (state, action) => getSiteError(state, action),
  [getSitesAction.type]: (state) => getSites(state),
  [getSitesRequestAction.type]: (state) => getSitesRequest(state),
  [getSitesSuccessAction.type]: (state, action) => getSitesSuccess(state, action),
  [getSitesErrorAction.type]: (state, action) => getSitesError(state, action),
  [updateSiteAction.type]: (state) => updateSite(state),
  [updateSiteRequestAction.type]: (state) => updateSiteRequest(state),
  [updateSiteSuccessAction.type]: (state, action) => updateSiteSuccess(state, action),
  [updateSiteErrorAction.type]: (state, action) => updateSiteError(state, action),
  [generateSiteReportAction.type]: (state) => generateSiteReport(state),
  [generateSiteReportRequestAction.type]: (state) => generateSiteReportRequest(state),
  [generateSiteReportSuccessAction.type]: (state) => generateSiteReportSuccess(state),
  [generateSiteReportErrorAction.type]: (state, action) => generateSiteReportError(state, action),
  [resetAction.type]: (state) => reset(state)
});

export default siteReducer;
