import api from "./api";
import SideMenu from "../domain/sideMenu";
import { NEW_API_VERSION } from "./common/api";

class SideMenuAPI {
  async listAllNames() {
    let url = `${NEW_API_VERSION}/all-menu-items`;
    const { data } = await api.get(url, {}, {});
    return SideMenu.parse(data);
  }
}
export default new SideMenuAPI();
