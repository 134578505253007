import { Project } from "app/domain/project";

import api, { NEW_API_VERSION } from "../common/api";
import ProjectMapper, { ProjectDTO } from "./projectMapper";

class ProjectService {
  static getProjects = async (): Promise<Project[]> => {
    const projectDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/projects`
      })
    ).data as ProjectDTO[];

    return ProjectMapper.fromDTO(projectDTOs);
  };
}

export default ProjectService;
