import { RequiredParamsBetweenError } from "app/domain/common";

export interface RequiredParamsBetweenErrorDTO {
  params: string[];
}

class RequiredParamsBetweenErrorMapper {
  static fromDTO(requiredParamsBetweenErrorDTOs: RequiredParamsBetweenErrorDTO[]): RequiredParamsBetweenError[] {
    return requiredParamsBetweenErrorDTOs.map(
      (requiredParamsBetweenErrorDTO: RequiredParamsBetweenErrorDTO) =>
        new RequiredParamsBetweenError({
          params: requiredParamsBetweenErrorDTO.params
        })
    );
  }
}

export default RequiredParamsBetweenErrorMapper;
