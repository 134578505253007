/* stylelint-disable value-keyword-case */

import { AuditStatus } from "app/domain/audit/auditStatus";
import { getCurrentProject } from "app/store/project/selectors";
import AssetListing from "app/view/asset/listing/assetListing";
import { formStyle } from "app/view/common/form";
import { Page } from "app/view/common/page";
import * as constants from "app/view/constant";
import { SiteTable } from "app/view/site/listing/table";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { css } from "styled-components";
import { ErrorMessage } from "ui/errorMessage";
import { Tab, Tabs, useTabs } from "ui/tab";

const projectFormStyle = css`
  &&& {
    ${formStyle};
    display: flex;
    flex-direction: column;
    flex: 1;

    .projectError {
      margin: 1em;
    }

    > *:not(:last-child) {
      margin-bottom: 1em;
    }
  }
`;

interface ProjectFormProps extends ComponentProps {
  projectId: string;
  onReset: () => void;
  errorMessage: string;
}

const ProjectForm: FC<ProjectFormProps> = ({ projectId, onReset, errorMessage, className }: ProjectFormProps) => {
  const { activeTabIndex, onTabChange } = useTabs();

  const currentProject = useSelector(getCurrentProject);
  return (
    <Page
      showBreadcrumb
      title={
        <>
          <span>Projet:</span> {currentProject ? currentProject.id : ""}
        </>
      }
    >
      <div className={className}>
        <ErrorMessage message={errorMessage} onDismiss={onReset} className="projectError" />
        <Tabs activeTabIndex={activeTabIndex || 0} onTabChange={onTabChange}>
          <Tab label={constants.ONGOING_AUDITS}>
            <AssetListing auditStatus={AuditStatus.ON_GOING} />
          </Tab>
          <Tab label={constants.FINISHED_AUDITS}>
            <AssetListing auditStatus={AuditStatus.DONE} />
          </Tab>
          <Tab label="Sites">
            <SiteTable projectId={projectId} />
          </Tab>
        </Tabs>
      </div>
    </Page>
  );
};

export default createStyledComponent(ProjectForm, projectFormStyle);
