/* eslint-disable jsx-a11y/label-has-associated-control, react-hooks/exhaustive-deps */

import { reasonOptions, updateOptions } from "app/data/formOptions";
import AssetMinimalInfo from "app/domain/asset/assetMinimalInfo";
import { Local } from "app/domain/local";
import { Requirement, RequirementType } from "app/domain/requirement";
import { RequirementItemOption, RequirementProblematicOption } from "app/domain/requirement/options";
import { useI18n } from "app/locales";
import { useRouter } from "app/routing/routerProvider";
import { getLocalsAction } from "app/store/local/actions";
import { getLocals } from "app/store/local/selectors";
import { addRequirementOptionAction } from "app/store/requirement/actions";
import { getRequirementOptions } from "app/store/requirement/selectors";
import { DropdownModel, FormFieldGroup, InputModel, Separator } from "app/view/common/form";
import AvailableSystemsDropdown from "app/view/requirement/form/requirementForm/formSections/availableSystemsDropdown";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { forwardRef, RefObject, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form as SemanticForm, TextArea as SemanticTextArea } from "semantic-ui-react";

import { RequirementFields } from "../useRequirement";
import RequirementUniformatSection from "./requirementUniformatSection";

const ACTIVITY_TYPE_REPLACEMENT = "Remplacer";

interface RequirementInformationProps extends ComponentProps {
  requirement: Requirement | undefined;
  assets: AssetMinimalInfo[] | undefined;
  onUpdate: (fields: RequirementFields) => void;
  isElementInCreationMode: boolean;
  onNavigationBlock: () => void;
  projectId: string;
  assetId: string;
  onSelectUniformat3: (uniformat3: string) => void;
  onUpdateErrorMessage: (errorMessage: string) => void;
}

const RequirementInformation = forwardRef((props: RequirementInformationProps, ref): JSX.Element => {
  const { requirement, assets, onUpdate, isElementInCreationMode, onNavigationBlock, projectId, assetId, onSelectUniformat3, onUpdateErrorMessage } = props;
  const { dictionaryCode, getAttributeLabel } = useI18n();
  const { getStateParam } = useRouter();
  const selectedRoomsId = getStateParam("selectedRoomsId");

  const locals = useSelector(getLocals);

  const [addingError, setAddingError] = useState(false);
  const [optionWasAdded, setOptionWasAdded] = useState(false);

  const dispatch = useDispatch();

  const requirementOptions = useSelector(getRequirementOptions);

  const findRoom = (roomId: string): Local | undefined => locals.find((room: Local) => room.id === roomId);

  const fromRoomIdsToRoomNumbers = (roomIds: string[]): string[] => {
    const roomNumbers = [];
    for (let i = 0; i < roomIds.length; i += 1) {
      const associatedRoom = findRoom(roomIds[i]);
      if (associatedRoom && associatedRoom.number) {
        roomNumbers.push(associatedRoom.number);
      }
    }
    return roomNumbers;
  };

  const findArea = (localNumber: string): number => {
    const result = locals.find((local: Local) => local.number === localNumber);
    return result ? (result.area as number) : 0;
  };

  const calculateArea = (localNumbers: string[]): number => {
    let sumArea = 0;
    localNumbers.forEach((localNumber: string) => {
      sumArea += findArea(localNumber);
    });
    return sumArea;
  };

  useEffect(() => {
    if (selectedRoomsId) {
      const selectedRoomNumbers = fromRoomIdsToRoomNumbers(selectedRoomsId);
      onUpdate({
        locals: selectedRoomNumbers,
        localsArea: calculateArea(selectedRoomNumbers).toFixed(0)
      });
    }
  }, [selectedRoomsId]);

  const handleChange = (event: any): void => {
    event.preventDefault();

    onUpdate({ [event.target.name]: event.target.value });
    onNavigationBlock();
  };

  const handleLocalsChange = (event: any, data: any): void => {
    event.preventDefault();
    onUpdate({
      locals: data.value,
      localsArea: calculateArea(data.value).toFixed(0)
    });
    onNavigationBlock();
  };

  const handleParentAssetChange = (event: any, data: any): void => {
    event.preventDefault();
    dispatch(getLocalsAction(data.value));
    onUpdate({ assetId: data.value, locals: [] });
    onNavigationBlock();
  };

  const handleSelectChange = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({
      [data.name]: data.value
    });
    onNavigationBlock();
  };

  const handleLocalisationChange = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({ localisation: data.value });
    onNavigationBlock();
  };

  const handleSelectActivity = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({ [data.name]: data.value });
    onNavigationBlock();

    if (requirement && requirement.uniformat && requirement.uniformat.level3 && data.value === ACTIVITY_TYPE_REPLACEMENT) {
      onSelectUniformat3(requirement.uniformat.level3);
    }
  };

  const handleSelectProblematic = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({ [data.name]: data.value });
    onNavigationBlock();
  };

  const mapToLocalOptions = (assetLocals: Local[]): any =>
    assetLocals.map((local) => ({
      key: local.id,
      text: local.number,
      value: local.number
    }));

  const addProblemOption = (event: any, data: any): void => {
    if (requirement && requirement.uniformat.level3) {
      const requirementProblematicOption = new RequirementProblematicOption({
        value: data.value,
        text: data.value,
        parent: requirement.uniformat.level3
      });

      dispatch(addRequirementOptionAction(requirementProblematicOption));
      setOptionWasAdded(true);
      setAddingError(false);
    } else {
      setOptionWasAdded(false);
      setAddingError(true);
    }
  };

  const filterProblematics = (problematicsList: any[]): any[] => {
    if (requirement && requirement.uniformat && requirement.uniformat.level3 && problematicsList) {
      return problematicsList.filter((problematic: any) => requirement.uniformat.level3 === problematic.parent);
    }
    return [];
  };

  const handleSelectItem = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({ [data.name]: data.value });
    onNavigationBlock();
  };

  const addItemOption = (event: any, data: any): void => {
    if (requirement && requirement.uniformat.level3 && requirement.uniformat.level4) {
      const requirementItemOption = new RequirementItemOption({
        value: data.value,
        text: data.value,
        parentLevel3: requirement.uniformat.level3,
        parentLevel4: requirement.uniformat.level4
      });

      dispatch(addRequirementOptionAction(requirementItemOption));
      setOptionWasAdded(true);
      setAddingError(false);
    } else {
      setOptionWasAdded(false);
      setAddingError(true);
    }
  };

  const filterRequirementItemOptions = (requirementItemOptions: RequirementItemOption[]): RequirementItemOption[] => {
    const filteredRequirementItemOptions = [];

    if (requirementItemOptions && requirement && requirement.uniformat && (requirement.uniformat.level3 || requirement.uniformat.level4)) {
      for (let index = 0; index < requirementItemOptions.length; index += 1) {
        if (requirement.uniformat.level3 && requirement.uniformat.level3.includes(requirementItemOptions[index].parentLevel3)) {
          if (filteredRequirementItemOptions.findIndex((requirementItemOption) => requirementItemOption.value === requirementItemOptions[index].value) === -1) {
            filteredRequirementItemOptions.push(requirementItemOptions[index]);
          }
        }
      }
    }

    return filteredRequirementItemOptions;
  };

  const handleUniformat3Reset = (uniformat3: string): void => {
    if (requirement && requirement.activity === ACTIVITY_TYPE_REPLACEMENT) {
      onSelectUniformat3(uniformat3);
    }
  };

  return (
    <div>
      <h3 ref={ref as RefObject<any>}>
        <span>1.</span> Informations sur l&apos;exigence
      </h3>
      {!isElementInCreationMode ? (
        <FormFieldGroup>
          <SemanticForm.Dropdown
            selection
            search
            label={getAttributeLabel("asset_id")}
            onChange={handleParentAssetChange}
            name="assetId"
            value={requirement && requirement.assetId}
            options={
              assets
                ? assets.map((asset: AssetMinimalInfo) => ({
                    key: `asset-${asset.id}`,
                    text: asset.number,
                    value: asset.id
                  }))
                : []
            }
          />
          <SemanticForm.Input
            label={getAttributeLabel("customer_reference")}
            readOnly
            name="customerReference"
            value={requirement && requirement.customerReference ? requirement.customerReference : ""}
          />
          <SemanticForm.Dropdown
            selection
            label={getAttributeLabel("requirement_category")}
            onChange={handleSelectChange}
            name="type"
            value={requirement && requirement.type}
            options={RequirementType.values.map((requirementType: RequirementType) => ({
              key: requirementType,
              text: RequirementType.format(dictionaryCode, requirementType),
              value: requirementType
            }))}
          />
        </FormFieldGroup>
      ) : null}
      <RequirementUniformatSection
        isElementInCreationMode={isElementInCreationMode}
        handleUniformat3Reset={handleUniformat3Reset}
        onUpdate={onUpdate}
        projectId={projectId}
        requirement={requirement}
      />
      <FormFieldGroup>
        <DropdownModel>
          <SemanticForm.Dropdown
            required
            label={getAttributeLabel("activity")}
            clearable
            placeholder={getAttributeLabel("activity")}
            name="activity"
            value={requirement && requirement.activity}
            onChange={handleSelectActivity}
            selection
            options={requirementOptions ? requirementOptions.activityOptions : []}
          />
        </DropdownModel>
      </FormFieldGroup>
      <FormFieldGroup>
        <AvailableSystemsDropdown assetId={assetId} requirement={requirement} onUpdate={onUpdate} onUpdateErrorMessage={onUpdateErrorMessage} />
        <DropdownModel>
          <SemanticForm.Dropdown
            label={getAttributeLabel("category")}
            clearable
            placeholder={getAttributeLabel("category")}
            name="category"
            value={requirement && requirement.category}
            onChange={handleSelectChange}
            selection
            options={requirementOptions ? requirementOptions.categoryOptions : []}
          />
        </DropdownModel>
        <DropdownModel>
          <SemanticForm.Dropdown
            required
            search
            label={getAttributeLabel("requirement_items")}
            multiple
            clearable
            name="items"
            value={requirement && requirement.items ? requirement.items : []}
            onChange={handleSelectItem}
            selection
            allowAdditions
            onAddItem={addItemOption}
            options={requirementOptions ? filterRequirementItemOptions(requirementOptions.requirementItemOptions) : []}
          />
        </DropdownModel>
        <DropdownModel>
          <SemanticForm.Dropdown
            required
            label={getAttributeLabel("requirement_problematics")}
            clearable
            name="problematics"
            value={requirement && requirement.problematics ? requirement.problematics : []}
            onChange={handleSelectProblematic}
            search
            selection
            multiple
            allowAdditions
            onAddItem={addProblemOption}
            options={requirementOptions ? filterProblematics(requirementOptions.problematicOptions) : []}
          />
        </DropdownModel>
        <InputModel>
          <SemanticForm.Input
            label={getAttributeLabel("name")}
            placeholder={getAttributeLabel("name")}
            name="name"
            value={requirement && requirement.name ? requirement.name : ""}
            onChange={handleChange}
          />
        </InputModel>
        <div className="field ">
          <div className="action-name-container">
            <label htmlFor="action_name">{getAttributeLabel("action_name")}</label>
            <p id="action_name" className="label-disabled">
              {requirement && requirement.actionName ? requirement.actionName : ""}
            </p>
            <InputModel className="action-name-suffix">
              <SemanticForm.Input
                label={getAttributeLabel("action_name_suffix")}
                placeholder={getAttributeLabel("action_name_suffix")}
                value={requirement && requirement.actionNameSuffix ? requirement.actionNameSuffix : ""}
                name="actionNameSuffix"
                onChange={handleChange}
              />
            </InputModel>
          </div>
        </div>
        <div className="field">
          <label className="required" htmlFor="cause">
            Pourquoi est-ce un problème?
          </label>
          <SemanticTextArea
            id="cause"
            size="large"
            placeholder="Pourquoi est-ce un problème?"
            name="cause"
            value={requirement && requirement.cause ? requirement.cause : ""}
            onChange={handleChange}
          />
        </div>
        {addingError ? <p className="error-message">Une nouvelle option doit être reliée au minimum à un uniformat de niveau 3</p> : null}
        {optionWasAdded ? (
          <p className="warning">Une option a été rajoutée à la liste. Cette option devra être approuvée et pourra être modifiée si elle est jugée comme innapropriée</p>
        ) : null}
      </FormFieldGroup>
      <Separator />
      <FormFieldGroup>
        <DropdownModel>
          <SemanticForm.Dropdown
            className="field"
            required
            search
            label={getAttributeLabel("requirement_locals")}
            multiple
            name="locals"
            value={requirement && requirement.locals ? requirement.locals : []}
            onChange={handleLocalsChange}
            selection
            options={locals ? mapToLocalOptions(locals) : []}
          />
        </DropdownModel>
        <SemanticForm.Input
          label="Superficie local(aux)"
          placeholder="Superficie"
          name="localsArea"
          onChange={handleChange}
          type="number"
          value={requirement && requirement.localsArea}
        />
        {isElementInCreationMode ? (
          <DropdownModel>
            <SemanticForm.Dropdown
              label="Choix de localisation"
              clearable
              placeholder="Options de localisation"
              name="localisation_option"
              onChange={handleLocalisationChange}
              selection
              options={requirementOptions ? requirementOptions.localisationOptions : []}
            />
          </DropdownModel>
        ) : null}
        <SemanticForm.Input
          label={getAttributeLabel("localisation")}
          placeholder={getAttributeLabel("localisation")}
          value={requirement && requirement.localisation ? requirement.localisation : ""}
          name="localisation"
          onChange={handleChange}
        />
      </FormFieldGroup>
      <Separator />
      <FormFieldGroup>
        <div className="field">
          <label className="required" htmlFor="description">
            {getAttributeLabel("description")}
          </label>
          <SemanticTextArea
            id="description"
            size="large"
            placeholder={getAttributeLabel("description")}
            name="description"
            value={requirement && requirement.description ? requirement.description : ""}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label className="required" htmlFor="action_description">
            {getAttributeLabel("action_description")}
          </label>
          <SemanticTextArea
            id="actionDescription"
            required
            size="large"
            placeholder={getAttributeLabel("action_description")}
            name="actionDescription"
            value={requirement && requirement.actionDescription ? requirement.actionDescription : ""}
            onChange={handleChange}
          />
        </div>
      </FormFieldGroup>
      {(projectId === "PL-01364" || projectId === "PL-01396" || projectId === "PL-01431" || projectId === "PL-01432" || projectId === "PL-01445") && (
        <FormFieldGroup>
          <SemanticForm.Dropdown selection search label="Cause" onChange={handleSelectChange} name="reason" value={requirement && requirement.reason} options={reasonOptions} />
          <SemanticForm.Dropdown
            selection
            search
            label="Mise à jour"
            onChange={handleSelectChange}
            name="update"
            value={requirement && requirement.update}
            options={updateOptions}
          />
        </FormFieldGroup>
      )}
    </div>
  );
});

export default RequirementInformation;
