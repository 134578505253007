import { SelectChangeEvent } from "@mui/material/Select";
import { Comment } from "app/domain/comment";
import { EntityCategory } from "app/domain/entityCategory";
import CommentFactory from "app/service/comment/commentFactory";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { ChangeEvent, FC } from "react";
import { Button, Form } from "semantic-ui-react";
import { TextAreaProps } from "semantic-ui-react/dist/commonjs/addons/TextArea/TextArea";
import Select from "ui/Select";

interface CommentCreatorProps extends ComponentProps {
  parentId: string;
  parentType: EntityCategory;
  onCreate: (comment: Comment) => void;
  onCancel: () => void;
  DROPDOWN_OPTIONS: { key: string; text: string; value: string }[];
}

const CommentCreator: FC<CommentCreatorProps> = ({ parentId, parentType, onCreate, onCancel, className, DROPDOWN_OPTIONS }: CommentCreatorProps) => {
  const commentFactory = new CommentFactory();
  const [newComment, setNewComment] = React.useState(commentFactory.createEmptyComment(parentId, parentType));
  const [fieldName, setFieldName] = React.useState("");
  const [hasSelectedFieldName, setHasSelectedFieldName] = React.useState(false);
  const [text, setText] = React.useState("");

  const persistNewComment = (): void => {
    newComment.fieldName = fieldName;
    newComment.text = text;
    onCreate(newComment);
    setNewComment(commentFactory.createEmptyComment(parentId, parentType));
  };

  const resetNewComment = (): void => {
    setNewComment(commentFactory.createEmptyComment(parentId, parentType));
    onCancel();
  };

  const selectFieldName = (event: SelectChangeEvent): void => {
    const selectedFieldName = event.target.value as string;
    setFieldName(selectedFieldName);
    setHasSelectedFieldName(!!selectedFieldName);
  };

  const modifyText = (event: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps): void => {
    setText(String(data.value));
  };

  return (
    <div className={className}>
      <Select options={DROPDOWN_OPTIONS} onChange={selectFieldName} value={fieldName} name="Nom du champ" />

      <Form reply>
        <Form.TextArea onChange={modifyText} />
        <Button content="Envoyer" onClick={persistNewComment} labelPosition="left" icon="edit" primary disabled={!hasSelectedFieldName} />
        <Button content="Annuler" onClick={resetNewComment} labelPosition="left" icon="trash" primary />
      </Form>
    </div>
  );
};

export default CommentCreator;
