import "antd/dist/antd.css";

import { Button, Checkbox, Col, DatePicker, Drawer, Form, Input, InputNumber, Row, Select, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import moment from "moment";
import React, { useState } from "react";

import AssetForm from "../../../domain/asset/assetForm";

interface AssetDrawerInterface {
  title: string;
  defaultValues?: AssetForm;
  isVisible: boolean;
  acceptButtonLabel: string;
  onAccept: (asset: AssetForm) => void;
  onCancel: () => void;
}

const AssetDrawer: React.FC<AssetDrawerInterface> = ({ title, defaultValues, isVisible, acceptButtonLabel, onAccept, onCancel }: AssetDrawerInterface) => {
  const [form] = Form.useForm();
  const [isHeritage, setIsHeritage] = useState(false);
  const MIN_REPLACEMENT_VALUE = 1.0;
  const MIN_AREA = 0;
  const MIN_YEAR = 0;
  const MAX_YEAR = new Date().getFullYear();

  const submitForm = (): void => {
    form.validateFields().then((value) => {
      if (!value.errorFields) {
        onAccept({ ...form.getFieldsValue(), heritage: isHeritage } as AssetForm);
        form.resetFields();
      }
    });
  };

  const onHeritageChange = (e: CheckboxChangeEvent): void => {
    setIsHeritage(e.target.checked);
  };

  const setFormFields = (): void => {
    if (!isVisible) {
      form.resetFields();
    } else if (defaultValues) {
      form.setFieldsValue(defaultValues);
    }
  };

  const getDisabledDate = (currenDate: moment.Moment): boolean => {
    return currenDate.get("year") < MIN_YEAR || currenDate.get("year") > MAX_YEAR;
  };

  return (
    <>
      <Drawer
        title={title}
        width="50%"
        afterVisibleChange={setFormFields}
        onClose={onCancel}
        visible={isVisible}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={onCancel}>Annuler</Button>
            <Button onClick={submitForm} type="primary" htmlType="submit">
              {acceptButtonLabel}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form}>
          <h1>Informations actif</h1>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label="Nom" rules={[{ required: true, message: "Veuillez entrer le nom." }]}>
                <Input placeholder="Veuillez entrer le nom" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="asset_number" label="Numéro de l'actif" rules={[{ required: true, message: "Veuillez entrer le numéro de l'actif." }]}>
                <Input placeholder="Veuillez entrer le numéro de l'actif." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="customerReference" label="Référence client">
                <Input placeholder="Veuillez entrer la référence client." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="heritage">
                <Checkbox checked={isHeritage} onChange={onHeritageChange}>
                  Patrimonial
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="area" label="Superficie de l'actif">
                <InputNumber min={MIN_AREA} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="year_constructed" label="Année de construction">
                <DatePicker.YearPicker disabledDate={getDisabledDate} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="year_renovated" label="Année de rénovation">
                <DatePicker.YearPicker disabledDate={getDisabledDate} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="pastIVP" label="IVP avant audit">
                <InputNumber<string> min="0" max="10" step="0.000000000000001" stringMode />
              </Form.Item>
            </Col>
          </Row>

          <h1>Informations monétaires</h1>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="replacement_value" label="Valeur de remplacement">
                <InputNumber min={MIN_REPLACEMENT_VALUE} />
              </Form.Item>
            </Col>
          </Row>

          <h1>Localisation</h1>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="civic_number" label="Numéro civique">
                <Input placeholder="Veuillez entrer le numéro civique." />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="street_name" label="Rue">
                <Input placeholder="Veuillez entrer la rue." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="city" label="Ville">
                <Input placeholder="Veuillez entrer la ville." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="postal_code" label="Code postal">
                <Input placeholder="Veuillez entrer le code postal." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="region" label="Région">
                <Input placeholder="Veuillez entrer la région." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="province" label="Province">
                <Input placeholder="Veuillez entrer la province." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="country" label="Pays">
                <Input placeholder="Veuillez entrer le pays." />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default AssetDrawer;
