/* eslint-disable @typescript-eslint/no-unused-vars */

import { useMemo, useState } from "react";
import { useTextField } from "ui/form/controls/textField";

type ValuesSearchbarHook = {
  onSearch: (searchedValue: string) => void;
  searchedValue: string;
  results: string[];
};

const useValuesSearchbar = (initialValues: string[]): ValuesSearchbarHook => {
  const { text: searchedValue, onInput: onSearch } = useTextField("");
  const [currentInitialValues] = useState(initialValues);

  const escapeRegExp = (value: string): string => value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  const results = useMemo(() => {
    return currentInitialValues.filter((key: string) => key.toLowerCase().match(escapeRegExp(searchedValue.toLowerCase())));
  }, [currentInitialValues, searchedValue]);

  return useMemo(
    () => ({
      onSearch,
      results,
      searchedValue
    }),
    [onSearch, results, searchedValue]
  );
};

export default useValuesSearchbar;
