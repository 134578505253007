import { Dictionary } from "app/locales/types";
import { capitalize } from "app/utils/formatter";

import Attributes from "./attributes";

const ComparisonOperators: Record<string, string> = {
  greaterThan: "must be greater than",
  greaterThanOrEquals: "must be greater or equal to",
  lessThanOrEquals: "must be less than or equal to",
  equals: "must be equal to",
  notEquals: "must not me equal to"
};

const formatComparisonError = (firstValue: string, secondValue: string, comparisonOperator: string): string => {
  const formattedFirstValue = Attributes[firstValue] ? Attributes[firstValue] : firstValue;
  const formattedSecondValue = Attributes[secondValue] ? Attributes[secondValue] : secondValue;

  return `the ${formattedFirstValue} ${comparisonOperator} the ${formattedSecondValue}`;
};

const formatParam = (param: string): string => {
  return capitalize(Attributes[param] ? Attributes[param] : param) as string;
};

const formatParamsError = (params: string[], lastDelimiter: string): string => {
  let formattedError = "";

  const firstParam = params[0];
  const middleParams = params.slice(1, -1);
  const lastParam = params[params.length - 1];

  formattedError = `${formatParam(firstParam)}`;

  if (params.length > 1) {
    middleParams.forEach((param: string): void => {
      formattedError = `${formattedError}, ${formatParam(param)}`;
    });

    formattedError = `${formattedError} ${lastDelimiter} ${formatParam(lastParam)}.`;
  }

  return formattedError;
};

const Errors: Dictionary<any> = {
  comparisonError: (firstValue: string, secondValue: string, comparisonOperator: string): string =>
    formatComparisonError(firstValue, secondValue, ComparisonOperators[comparisonOperator]),
  requiredParamsError: (params: string[]): string => `The following parameters are required: ${formatParamsError(params, "and")}.`,
  requiredParamsBetweenError: (params: string[]): string => `At least one of the following parameters is required: ${formatParamsError(params, "or")}.`
};

export default Errors;
