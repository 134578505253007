class AuditProgress {
  constructor({
    id,
    assetId,
    mech_quality_control_date,
    mech_quality_autocontrol_date,
    mech_estimation_50_percent_date,
    mech_estimation_100_percent_date,
    mech_audit_50_percent_date,
    mech_audit_100_percent_date,
    mech_preparing_meetings_date,
    arch_quality_control_date,
    arch_quality_autocontrol_date,
    arch_estimation_50_percent_date,
    arch_estimation_100_percent_date,
    arch_audit_50_percent_date,
    arch_audit_100_percent_date,
    arch_preparing_meetings_date,
    arch_audit_total_hours,
    mech_audit_total_hours,
    global_quality_control_date,
    mech_planned_nb_hours_preparing,
    mech_planned_nb_hours_audit,
    mech_planned_nb_hours_estimation,
    mech_planned_nb_hours_quality_control,
    arch_planned_nb_hours_preparing,
    arch_planned_nb_hours_audit,
    arch_planned_nb_hours_estimation,
    arch_planned_nb_hours_quality_control,
    global_progress_percentage,
    global_quality_control25_date,
    global_quality_control50_date,
    global_quality_control75_date,
  }) {
    this.id = id;
    this.assetId = assetId;
    this.mech_quality_control_date = mech_quality_control_date;
    this.mech_quality_autocontrol_date = mech_quality_autocontrol_date;
    this.mech_estimation_50_percent_date = mech_estimation_50_percent_date;
    this.mech_estimation_100_percent_date = mech_estimation_100_percent_date;
    this.mech_audit_50_percent_date = mech_audit_50_percent_date;
    this.mech_audit_100_percent_date = mech_audit_100_percent_date;
    this.mech_preparing_meetings_date = mech_preparing_meetings_date;
    this.arch_quality_control_date = arch_quality_control_date;
    this.arch_quality_autocontrol_date = arch_quality_autocontrol_date;
    this.arch_estimation_50_percent_date = arch_estimation_50_percent_date;
    this.arch_estimation_100_percent_date = arch_estimation_100_percent_date;
    this.arch_audit_50_percent_date = arch_audit_50_percent_date;
    this.arch_audit_100_percent_date = arch_audit_100_percent_date;
    this.arch_preparing_meetings_date = arch_preparing_meetings_date;
    this.arch_audit_total_hours = arch_audit_total_hours;
    this.mech_audit_total_hours = mech_audit_total_hours;
    this.global_quality_control_date = global_quality_control_date;
    this.mech_planned_nb_hours_preparing = mech_planned_nb_hours_preparing;
    this.mech_planned_nb_hours_audit = mech_planned_nb_hours_audit;
    this.mech_planned_nb_hours_estimation = mech_planned_nb_hours_estimation;
    this.mech_planned_nb_hours_quality_control =
      mech_planned_nb_hours_quality_control;
    this.arch_planned_nb_hours_preparing = arch_planned_nb_hours_preparing;
    this.arch_planned_nb_hours_audit = arch_planned_nb_hours_audit;
    this.arch_planned_nb_hours_estimation = arch_planned_nb_hours_estimation;
    this.arch_planned_nb_hours_quality_control =
      arch_planned_nb_hours_quality_control;
    this.global_progress_percentage = global_progress_percentage;
    this.global_quality_control25_date = global_quality_control25_date;
    this.global_quality_control50_date = global_quality_control50_date;
    this.global_quality_control75_date = global_quality_control75_date;
  }

  toJSON() {
    return {
      ...this,
      asset_id: this.assetId,
    };
  }

  static parse({
    id,
    asset_id,
    mech_quality_control_date,
    mech_quality_autocontrol_date,
    mech_estimation_50_percent_date,
    mech_estimation_100_percent_date,
    mech_audit_50_percent_date,
    mech_audit_100_percent_date,
    mech_preparing_meetings_date,
    arch_quality_control_date,
    arch_quality_autocontrol_date,
    arch_estimation_50_percent_date,
    arch_estimation_100_percent_date,
    arch_audit_50_percent_date,
    arch_audit_100_percent_date,
    arch_preparing_meetings_date,
    arch_audit_total_hours,
    mech_audit_total_hours,
    global_quality_control_date,
    mech_planned_nb_hours_preparing,
    mech_planned_nb_hours_audit,
    mech_planned_nb_hours_estimation,
    mech_planned_nb_hours_quality_control,
    arch_planned_nb_hours_preparing,
    arch_planned_nb_hours_audit,
    arch_planned_nb_hours_estimation,
    arch_planned_nb_hours_quality_control,
    global_progress_percentage,
    global_quality_control25_date,
    global_quality_control50_date,
    global_quality_control75_date,
  }) {
    return new AuditProgress({
      id,
      assetId: asset_id,
      mech_quality_control_date,
      mech_quality_autocontrol_date,
      mech_estimation_50_percent_date,
      mech_estimation_100_percent_date,
      mech_audit_50_percent_date,
      mech_audit_100_percent_date,
      mech_preparing_meetings_date,
      arch_quality_control_date,
      arch_quality_autocontrol_date,
      arch_estimation_50_percent_date,
      arch_estimation_100_percent_date,
      arch_audit_50_percent_date,
      arch_audit_100_percent_date,
      arch_preparing_meetings_date,
      arch_audit_total_hours,
      mech_audit_total_hours,
      global_quality_control_date,
      mech_planned_nb_hours_preparing,
      mech_planned_nb_hours_audit,
      mech_planned_nb_hours_estimation,
      mech_planned_nb_hours_quality_control,
      arch_planned_nb_hours_preparing,
      arch_planned_nb_hours_audit,
      arch_planned_nb_hours_estimation,
      arch_planned_nb_hours_quality_control,
      global_progress_percentage,
      global_quality_control25_date,
      global_quality_control50_date,
      global_quality_control75_date,
    });
  }
}
export default AuditProgress;
