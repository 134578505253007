import { Site } from "app/domain/site";
import SiteService, { Report } from "app/service/site/siteService";
import FileDownloader from "app/utils/fileDownloader";
import { notify } from "app/view/common/toast/Toast";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  generateSiteReportErrorAction,
  generateSiteReportRequestAction,
  generateSiteReportSuccessAction,
  getSiteErrorAction,
  getSiteRequestAction,
  getSitesErrorAction,
  getSitesRequestAction,
  getSitesSuccessAction,
  getSiteSuccessAction,
  updateSiteErrorAction,
  updateSiteRequestAction,
  updateSiteSuccessAction
} from "./actions";
import {
  GenerateSiteReportAction,
  GenerateSiteReportRequestAction,
  GetSiteAction,
  GetSiteRequestAction,
  GetSitesAction,
  GetSitesRequestAction,
  SiteActionTypes,
  UpdateSiteAction,
  UpdateSiteRequestAction
} from "./types";

export const GET_SITE_ERROR = "Une erreur s'est produite lors de la récupération du site. Veuillez réessayer.";
export const GET_SITES_ERROR = "Une erreur s'est produite lors de la récupération des sites. Veuillez réessayer.";
export const UPDATE_SITE_ERROR = "Une erreur s'est produite lors de la mise à jour du site. Veuillez réessayer.";
export const GENERATE_SITE_REPORT_ERROR = "Une erreur s'est produite lors de la génération du rapport excel. Veuillez réessayer.";

export function* getSite(action: GetSiteAction): Generator {
  yield put(getSiteRequestAction(action.payload.siteId));
}

export function* getSiteRequest(action: GetSiteRequestAction): Generator {
  try {
    const site = yield call(SiteService.getSite, action.payload.siteId);
    yield put(getSiteSuccessAction(site as Site));
  } catch (error) {
    yield put(getSiteErrorAction(GET_SITE_ERROR));
  }
}

export function* getSites(action: GetSitesAction): Generator {
  yield put(getSitesRequestAction(action.payload.projectId));
}

export function* getSitesRequest(action: GetSitesRequestAction): Generator {
  try {
    const sites = yield call(SiteService.getSites, action.payload.projectId);
    yield put(getSitesSuccessAction(sites as Site[]));
  } catch (error) {
    yield put(getSitesErrorAction(GET_SITES_ERROR));
  }
}

export function* updateSite(action: UpdateSiteAction): Generator {
  yield put(updateSiteRequestAction(action.payload.site));
}

export function* updateSiteRequest(action: UpdateSiteRequestAction): Generator {
  try {
    const updatedSite = yield call(SiteService.updateSite, action.payload.site);
    yield put(updateSiteSuccessAction(updatedSite as Site));
    notify("Le site a été modifié avec succès");
  } catch (error) {
    yield put(updateSiteErrorAction(UPDATE_SITE_ERROR));
  }
}

export function* generateSiteReport(action: GenerateSiteReportAction): Generator {
  yield put(generateSiteReportRequestAction(action.payload.siteId));
}

export function* generateSiteReportRequest(action: GenerateSiteReportRequestAction): Generator {
  try {
    const report = yield call(SiteService.generateReport, action.payload.siteId);

    yield call(FileDownloader.download, (report as Report).url as string, (report as Report).filename as string);
    yield put(generateSiteReportSuccessAction());
  } catch (error) {
    yield put(generateSiteReportErrorAction(GENERATE_SITE_REPORT_ERROR));
  }
}

function* siteSaga(): Generator {
  yield takeLatest(SiteActionTypes.GET_SITE, getSite);
  yield takeLatest(SiteActionTypes.GET_SITE_REQUEST, getSiteRequest);
  yield takeLatest(SiteActionTypes.GET_SITES, getSites);
  yield takeLatest(SiteActionTypes.GET_SITES_REQUEST, getSitesRequest);
  yield takeLatest(SiteActionTypes.UPDATE_SITE, updateSite);
  yield takeLatest(SiteActionTypes.UPDATE_SITE_REQUEST, updateSiteRequest);
  yield takeLatest(SiteActionTypes.GENERATE_SITE_REPORT, generateSiteReport);
  yield takeLatest(SiteActionTypes.GENERATE_SITE_REPORT_REQUEST, generateSiteReportRequest);
}

export default siteSaga;
