import * as React from "react";
import { withRouter } from "react-router";
import style, { keyframes } from "styled-components";

class PreLoader extends React.Component {
  render() {
    const keyFrameSlide1 = keyframes`
            0% {
                left: 0
            }
            30% {
                left: 0
            }
            45% {
                left: 24vw;
            }
            70% {
                left: 0;
            }
            100% {
                left: 0
            }
        `;

    const keyFrameSlide2 = keyframes`
            0% {
                left: 7vw;
            }
            30% {
                left: 7vw;
            }
            45% {
                left: 18vw;
            }
            70% {
                left: 7vw;
            }
            100% {
                left: 7vw;
            }
        `;

    const Row = style.div`
            width: 20vw;
            height: 4.8vw;
            margin-bottom: 0.3vw;
            position: absolute;
            
            &:nth-child(1) {
                left: 0;
                top: 0;
                animation ${keyFrameSlide1} 4s ease-in-out 0s infinite;
            }
            
            &:nth-child(5) {
                left: 0;
                bottom: 0;
                animation ${keyFrameSlide1} 4s ease-in-out 0s infinite;
            }
            
            &:nth-child(2) {
                left: calc(50% - 10vw);
                top: 5vw;
                animation ${keyFrameSlide2} 4s ease-in-out 0s infinite;
            }
            
            &:nth-child(4) {
                left: calc(50% - 10vw);
                bottom: 5vw;
                animation ${keyFrameSlide2} 4s ease-in-out 0s infinite;
            }
            
            &:nth-child(3) {
                left: 12vw;
                top: 10vw;
            }
        `;

    const keyFrameStretch = keyframes`
            0% {
                width: 6vw;
                position: absolute;
            }
            30% {
                width: 20vw;
                position: absolute;
            }
            70% {
                width: 20vw;
                position: absolute;
            }
            100% {
                width: 6vw;
                position: absolute;
            }
        `;

    const Square = style.div`
            width: 6vw;
            height: 4.8vw;
            margin: 0.3vw;
            background-color: ${(props) => props.color};
            display: inline-block;
            
            &:nth-child(1) {
                animation: ${keyFrameStretch} 4s ease-in-out 0s infinite;
            }
        `;

    const Circle = style.div`
            position: absolute;
            width: 55vw;
            height: 55vw;
            border-radius: 50%;
            background-color: rgba(255,255,255, 0.99);
            padding-top: calc(50% - 17,25vw);
            margin: auto;
            top: calc(50% - 55vw/2);
            left: calc(50% - 55vw/2);
            clip-path: polygon(0 20%, 100% 20%, 100% 80%, 0 80%);
        `;

    return (
      <Circle>
        <div>Données en chargement</div>
        <div
          style={{
            width: "24.5vw",
            height: "25.3vw",
            margin: "auto",
            position: "relative",
            top: "15.25vw",
            left: "-10vw",
          }}
        >
          <Row rowNum={1}>
            <Square color="rgb(0,162,204)"></Square>
          </Row>
          <Row rowNum={2}>
            <Square color="rgb(0,162,204)"></Square>
          </Row>
          <Row rowNum={3}>
            <Square color="rgb(0,131,172)"></Square>
          </Row>
          <Row rowNum={4}>
            <Square color="black"></Square>
          </Row>
          <Row rowNum={5}>
            <Square color="black"></Square>
          </Row>
        </div>
      </Circle>
    );
  }
}
export default withRouter(PreLoader);
