import { State } from "app/domain/common";
import { CostLine } from "app/domain/costItem";
import Local from "app/domain/local/local";
import { System } from "app/domain/system";
import { UniformatLevel1 } from "app/domain/uniformat";
import CalculationMapper, { CalculationDTO } from "app/service/calculation/calculationMapper";
import ErrorsMapper, { ErrorsDTOSystem } from "app/service/common/errorsMapper";
import CostLineMapper, { CostLineDTO } from "app/service/costItem/costLineMapper";
import LocalMapper from "app/service/local/localMapper";

export type SystemDTO = {
  id: string;
  uniformat1: string;
  uniformat2: string;
  uniformat3: string;
  uniformat4: string;
  state: string;
  to_verify: boolean;
  is_verified: boolean;
  total_cost: number;
  reference?: string;
  height?: number;
  unit_of_measure?: string;
  unit_of_measure_factor?: number;
  installation_year?: number;
  public_notes?: string;
  is_installation_year_estimated?: boolean;
  lifetime?: number;
  condition_grading?: string;
  condition_grading_after_repair?: string;
  age_range?: string;
  auditor?: string;
  audit_date?: string;
  created_at?: Date;
  comment?: string;
  action_type?: string;
  unit_cost?: number;
  model_number?: string;
  system_model_id?: string;
  system_model_action_id?: string;
  estimated_lifetime?: number;
  renewal_percentage?: number;
  patrimony_factor?: number;
  updated_by?: string;
  useful_life?: number;
  complexity?: number;
  removal?: number;
  has_qr_code_been_generated?: boolean;
  rooms?: Local[];
  room_ids?: string[];
  note?: string;
  quantity?: number;
  name?: string;
  custom_name?: string;
  custom_name_concat?: string;
  identification?: string;
  description?: string;
  asset_id?: string;
  localisation?: string;
  is_linked_to_area: boolean;
  number_of_photos: number;
  is_identification_in_name: boolean;
  is_local_number_in_name: boolean;
  is_localisation_in_name: boolean;
  is_installation_year_in_name: boolean;
  status?: number;
  cost_lines?: CostLineDTO[];
  cost_lines_attributes?: CostLine[];
  errors?: ErrorsDTOSystem;
  manufacturer_id?: number;
  model?: string;
  serial_number?: string;
  calculations_attributes?: CalculationDTO[];
  calculations?: CalculationDTO[];
  customer_reference?: string;
  comments_count?: number;
  category?: string;
  renewal_description?: string;
  action_description?: string;
  update_status?: number;
  reason?: number;
  is_auto_useful_life_disabled?: boolean;
};

class SystemMapper {
  static fromDTO(systemDTO: SystemDTO): System;
  static fromDTO(systemDTOs: SystemDTO[]): System[];
  static fromDTO(systemDTOs: SystemDTO | SystemDTO[]): any {
    if (!Array.isArray(systemDTOs)) {
      return new System({
        id: systemDTOs.id,
        uniformat: {
          level1: systemDTOs.uniformat1 as UniformatLevel1,
          level2: systemDTOs.uniformat2,
          level3: systemDTOs.uniformat3,
          level4: systemDTOs.uniformat4
        },
        state: State.fromString(systemDTOs.state),
        toVerify: systemDTOs.to_verify,
        isVerified: systemDTOs.is_verified,
        totalCost: systemDTOs.total_cost,
        errors: systemDTOs.errors ? ErrorsMapper.fromDTOSystem(systemDTOs.errors) : [],
        reference: systemDTOs.reference,
        height: systemDTOs.height,
        unitOfMeasure: systemDTOs.unit_of_measure,
        unitOfMeasureFactor: systemDTOs.unit_of_measure_factor,
        installationYear: systemDTOs.installation_year,
        public_notes: systemDTOs.public_notes,
        is_installation_year_estimated: systemDTOs.is_installation_year_estimated,
        lifetime: systemDTOs.lifetime,
        conditionGrading: systemDTOs.condition_grading,
        conditionGradingAfterRepair: systemDTOs.condition_grading_after_repair,
        ageRange: systemDTOs.age_range,
        auditor: systemDTOs.auditor,
        auditDate: systemDTOs.audit_date,
        created_hour: systemDTOs.created_at,
        comment: systemDTOs.comment,
        actionType: systemDTOs.action_type,
        unitCost: systemDTOs.unit_cost,
        model_number: systemDTOs.model_number,
        systemModelId: systemDTOs.system_model_id,
        systemModelActionId: systemDTOs.system_model_action_id,
        estimated_lifetime: systemDTOs.estimated_lifetime,
        renewalPercentage: systemDTOs.renewal_percentage,
        patrimony_factor: systemDTOs.patrimony_factor,
        updated_by: systemDTOs.updated_by,
        usefulLife: systemDTOs.useful_life,
        complexity: systemDTOs.complexity,
        removal: systemDTOs.removal,
        has_qr_code_been_generated: systemDTOs.has_qr_code_been_generated,
        locals: systemDTOs.rooms ? LocalMapper.fromDTO(systemDTOs.rooms) : [],
        note: systemDTOs.note,
        quantity: systemDTOs.quantity,
        name: systemDTOs.name,
        custom_name: systemDTOs.custom_name,
        custom_name_concat: systemDTOs.custom_name_concat,
        identification: systemDTOs.identification,
        description: systemDTOs.description,
        assetId: systemDTOs.asset_id,
        localisation: systemDTOs.localisation,
        isLinkedToArea: systemDTOs.is_linked_to_area,
        numberOfPhotos: systemDTOs.number_of_photos,
        isIdentificationInName: systemDTOs.is_identification_in_name,
        isLocalNumberInName: systemDTOs.is_local_number_in_name,
        isLocalisationInName: systemDTOs.is_localisation_in_name,
        isInstallationYearInName: systemDTOs.is_installation_year_in_name,
        status: systemDTOs.status,
        manufacturerId: systemDTOs.manufacturer_id,
        model: systemDTOs.model,
        serialNumber: systemDTOs.serial_number,
        customerReference: systemDTOs.customer_reference,
        costLines: systemDTOs.cost_lines ? CostLineMapper.fromDTO(systemDTOs.cost_lines as CostLineDTO[]) : [],
        calculations: systemDTOs.calculations ? CalculationMapper.fromDTO(systemDTOs.calculations) : [],
        commentsCount: systemDTOs.comments_count ? systemDTOs.comments_count : 0,
        category: systemDTOs.category,
        renewalDescription: systemDTOs.renewal_description,
        actionDescription: systemDTOs.action_description,
        update: systemDTOs.update_status,
        reason: systemDTOs.reason,
        isAutoUsefulLifeDisabled: systemDTOs.is_auto_useful_life_disabled
      });
    }
    return systemDTOs.map((systemDTO: SystemDTO): System => this.fromDTO(systemDTO));
  }

  static mapToLocalIds(locals: Local[]): string[] {
    return locals.map((local: Local) => local.id as string);
  }

  static toDTO(system: System): SystemDTO;
  static toDTO(systems: System[]): SystemDTO[];
  static toDTO(systems: System | System[]): any {
    if (!Array.isArray(systems)) {
      return {
        id: systems.id,
        uniformat1: systems.uniformat.level1 ? systems.uniformat.level1 : "",
        uniformat2: systems.uniformat.level2,
        uniformat3: systems.uniformat.level3,
        uniformat4: systems.uniformat.level4,
        state: State.toString(systems.state),
        to_verify: systems.toVerify,
        is_verified: systems.isVerified,
        height: systems.height,
        unit_of_measure: systems.unitOfMeasure,
        unit_of_measure_factor: systems.unitOfMeasureFactor,
        installation_year: systems.installationYear,
        public_notes: systems.public_notes,
        is_installation_year_estimated: systems.is_installation_year_estimated,
        lifetime: systems.lifetime,
        condition_grading: systems.conditionGrading,
        condition_grading_after_repair: systems.conditionGradingAfterRepair,
        age_range: systems.ageRange,
        auditor: systems.auditor,
        audit_date: systems.auditDate,
        comment: systems.comment,
        action_type: systems.actionType,
        unit_cost: systems.unitCost,
        model_number: systems.model_number,
        system_model_id: systems.systemModelId,
        system_model_action_id: systems.systemModelActionId,
        estimated_lifetime: systems.estimated_lifetime,
        renewal_percentage: systems.renewalPercentage,
        patrimony_factor: systems.patrimony_factor,
        useful_life: systems.usefulLife,
        complexity: systems.complexity,
        removal: systems.removal,
        has_qr_code_been_generated: systems.has_qr_code_been_generated,
        room_ids: systems.locals ? this.mapToLocalIds(systems.locals) : [],
        note: systems.note,
        reference: systems.reference,
        quantity: systems.quantity,
        name: systems.name,
        custom_name: systems.custom_name,
        custom_name_concat: systems.custom_name_concat,
        identification: systems.identification,
        description: systems.description,
        asset_id: systems.assetId,
        localisation: systems.localisation,
        is_linked_to_area: systems.isLinkedToArea,
        is_identification_in_name: systems.isIdentificationInName,
        is_local_number_in_name: systems.isLocalNumberInName,
        is_localisation_in_name: systems.isLocalisationInName,
        is_installation_year_in_name: systems.isInstallationYearInName,
        status: systems.status,
        manufacturer_id: systems.manufacturerId,
        model: systems.model,
        serial_number: systems.serialNumber,
        customer_reference: systems.customerReference,
        cost_lines_attributes: systems.costLines ? CostLineMapper.toDTO(systems.costLines as CostLine[]) : [],
        calculations_attributes: systems.calculations ? CalculationMapper.toDTO(systems.calculations) : [],
        category: systems.category,
        renewal_description: systems.renewalDescription,
        action_description: systems.actionDescription,
        update_status: systems.update,
        reason: systems.reason,
        is_auto_useful_life_disabled: systems.isAutoUsefulLifeDisabled
      };
    }
    return systems.map((system: System): SystemDTO => this.toDTO(system));
  }
}

export default SystemMapper;
