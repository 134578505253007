class FileDownloader {
  static download = (url: string, filename?: string): HTMLAnchorElement => {
    const downloadLink = document.createElement("a");

    downloadLink.download = filename || "";
    downloadLink.href = url;

    const clickHandler = (): void => {
      downloadLink.removeEventListener("click", clickHandler);
    };

    downloadLink.addEventListener("click", clickHandler, false);

    downloadLink.click();
    window.URL.revokeObjectURL(url);

    return downloadLink;
  };
}

export default FileDownloader;
