/* eslint-disable react-hooks/exhaustive-deps */

import { FilterType } from "app/domain/filter";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import compare from "react-fast-compare";
import { useModal } from "ui/modal";

import FilterPanelButton from "./filterPanelButton";
import { FilterPanelModal } from "./filterPanelModal";

interface FilterPanelProps extends ComponentProps {
  values: string[];
  filteredValues: string[];
  onFilter: (values: string[]) => void;
  filterType: FilterType;
}

const isEqual = (prevProps: FilterPanelProps, nextProps: FilterPanelProps): boolean => {
  const areValuesEqual = compare(prevProps.values, nextProps.values);
  const areFilteredValuesEqual = compare(prevProps.filteredValues, nextProps.filteredValues);
  return areValuesEqual && areFilteredValuesEqual;
};

const FilterPanel: FC<FilterPanelProps> = ({ values, filteredValues, onFilter, filterType }: FilterPanelProps) => {
  const { isModalOpen: isOpen, openModal: onOpen, closeModal: onClose } = useModal();

  return (
    <>
      <FilterPanelButton isActive={filteredValues.length > 0} onClick={onOpen} data-test-id="filterPanelButton" />
      <FilterPanelModal
        values={values}
        filteredValues={filteredValues}
        onFilter={onFilter}
        filterType={filterType}
        isOpen={isOpen}
        onClose={onClose}
        data-test-id="filterPanelModal"
      />
    </>
  );
};

export default memo(FilterPanel, isEqual);
