export const moneyFormatter = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD",
  minimumFractionDigits: 2,
});

export const formatPercentage = (percentageInDecimal) => {
  return Math.round(percentageInDecimal * 100).toString() + "%";
};

export const formatNumber = (number, numberOfDigits) => {
  const numberFormatter = new Intl.NumberFormat("en-CA", {
    minimumFractionDigits: numberOfDigits,
  });

  return numberFormatter.format(number);
};

export const formatArea = (area) => {
  return `${Math.round(area)} m\u00B2`;
};

export const capitalize = (word) => {
  if (typeof word === "string") {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return null;
};
