export enum IconType {
  CAMERA = "camera",
  CHECK = "check",
  COPY = "copy",
  UNDO = "undo",
  CANCEL = "cancel",
  DOWNLOAD = "download",
  IMAGE = "image outline",
  PLUS = "plus",
  TRASH = "trash alternate outline",
  EXCEL = "file excel outline",
  SAVE = "save",
  QR_CODE = "qrcode",
  ARROW_UP = "arrow up",
  ARROW_DOWN = "arrow down",
  MINUS = "minus",
  FILTER = "filter",
  ANGLE_RIGHT = "angle right",
  ANGLE_LEFT = "angle left",
  SITE_MAP = "sitemap",
  EDIT_OUTLINE = "edit outline",
  TASKS = "tasks",
  WRENCH = "wrench",
  COMMENT = "comments",
  CLOCK = "history"
}
