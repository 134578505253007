import { Site } from "app/domain/site";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Form as SemanticForm } from "semantic-ui-react";

import { SiteFields } from "../../useSite";

interface SiteInformationProps extends ComponentProps {
  site: Site | undefined;
  onUpdate: (fields: SiteFields) => void;
}

const SiteInformation: FC<SiteInformationProps> = ({ site, onUpdate }: SiteInformationProps) => {
  const handleChange = (event: any): void => {
    event.preventDefault();

    onUpdate({ [event.target.name]: event.target.value });
  };

  return (
    <>
      <SemanticForm.Group unstackable widths={4}>
        <SemanticForm.Input label="Ville" placeholder="Ville" name="city" value={site && site.city ? site.city : ""} onChange={handleChange} />
        <SemanticForm.Input label="Code postal" placeholder="Code postal" name="postal_code" value={site && site.postal_code ? site.postal_code : ""} onChange={handleChange} />
        <SemanticForm.Input label="Province" placeholder="Province" name="province" value={site && site.province ? site.province : ""} onChange={handleChange} />
        <SemanticForm.Input label="Pays" placeholder="Pays" name="country" value={site && site.country ? site.country : ""} onChange={handleChange} />
      </SemanticForm.Group>
    </>
  );
};

export default SiteInformation;
