import { type } from "@testing-library/user-event/dist/type";
import { Error } from "app/domain/common";
import _ from "lodash";

import ComparisonErrorMapper, { ComparisonErrorDTO } from "./comparisonErrorMapper";
import RequiredParamsBetweenErrorMapper, { RequiredParamsBetweenErrorDTO } from "./requiredParamsBetweenErrorMapper";
import RequiredParamsErrorMapper from "./requiredParamsErrorMapper";

export type ErrorsDTOSystem =
  | {
      rule_name: string;
      rule_content:
        | {
            operator: string;
            first_param: string;
            second_param: string;
          }
        | { params: string[] };
    }[];

export type ErrorsDTO = {
  comparison_errors: ComparisonErrorDTO[];
  required_params_errors: RequiredParamsBetweenErrorDTO[];
  required_params_between_errors: RequiredParamsBetweenErrorDTO[];
};

class ErrorsMapper {
  static fromDTOSystem(errorsDTO: ErrorsDTOSystem): Error[] {
    const errors = {
      required_params_errors: [],
      required_params_between_errors: [],
      comparison_errors: []
    };

    errorsDTO.forEach((error) => {
      switch (error.rule_name) {
        case "required_between":
          errors.required_params_between_errors.push(error.rule_content as never);
          break;
        case "required":
          errors.required_params_errors.push(error.rule_content as never);
          break;
        default:
          errors.comparison_errors.push({ operator: error.rule_name, params: error.rule_content } as never);
          break;
      }
    });

    return ([] as Error[]).concat(
      RequiredParamsErrorMapper.fromDTO(errors.required_params_errors),
      RequiredParamsBetweenErrorMapper.fromDTO(errors.required_params_between_errors),
      ComparisonErrorMapper.fromDTO(errors.comparison_errors) as any
    ) as Error[];
  }

  static fromDTO(errorsDTO: ErrorsDTO): Error[] {
    const errors = [] as Error[];

    return errors.concat(
      RequiredParamsErrorMapper.fromDTO(errorsDTO.required_params_errors),
      RequiredParamsBetweenErrorMapper.fromDTO(errorsDTO.required_params_between_errors),
      ComparisonErrorMapper.fromDTO(errorsDTO.comparison_errors)
    );
  }
}

export default ErrorsMapper;
