import { Filter, FilterType } from "app/domain/filter";
import { useCallback, useEffect, useMemo, useState } from "react";

type FiltersHook = {
  onChange: (filter: Filter) => void;
  onToggle: (filter: Filter) => void;
  onDelete: (filterLabel: string) => void;
  onReset: () => void;
  filters: Filter[];
};

const useFilters = (initialFilters?: Filter[]): FiltersHook => {
  const [currentFilters, setCurrentFilters] = useState(initialFilters || []);

  useEffect(() => {
    setCurrentFilters(initialFilters || []);
  }, [initialFilters]);

  const removeFilter = useCallback((filters: Filter[], filter: Filter) => {
    const filterIndex = filters.findIndex((previousFilter: Filter) => previousFilter.label === filter.label);

    if (filterIndex !== -1) {
      filters.splice(filterIndex, 1);
    }
  }, []);

  const removeQuickFiltersOnField = useCallback(
    (filters: Filter[], field: string) => {
      const currentQuickFiltersOnField = filters.filter((filter: Filter) => filter.field === field && filter.type === FilterType.QUICK_FILTER);

      currentQuickFiltersOnField.forEach((quickFilter: Filter) => {
        removeFilter(filters, quickFilter);
      });
    },
    [removeFilter]
  );

  const removeColumnFilterOnField = useCallback(
    (filters: Filter[], field: string) => {
      const currentColumnFilterOnField = filters.filter((filter: Filter) => filter.field === field && filter.type === FilterType.COLUMN_FILTER);

      if (currentColumnFilterOnField.length > 0) {
        removeFilter(filters, currentColumnFilterOnField[0]);
      }
    },
    [removeFilter]
  );

  const onChange = useCallback(
    (filter: Filter) => {
      setCurrentFilters((previousFilters: Filter[]) => {
        let previousFiltersCopy = [...previousFilters];

        const filterIndex = previousFiltersCopy.findIndex((previousFilter: Filter) => previousFilter.label === filter.label);

        if (filterIndex === -1) {
          removeQuickFiltersOnField(previousFiltersCopy, filter.field);

          if (filter.values.length > 0) {
            previousFiltersCopy = [...previousFiltersCopy, filter];
          }
        } else if (filter.values.length > 0) {
          previousFiltersCopy[filterIndex] = {
            ...previousFiltersCopy[filterIndex],
            values: filter.values
          };
        } else {
          previousFiltersCopy.splice(filterIndex, 1);
        }

        return [...previousFiltersCopy];
      });
    },
    [removeQuickFiltersOnField]
  );

  const onToggle = useCallback(
    (filter: Filter) => {
      setCurrentFilters((previousFilters: Filter[]) => {
        let previousFiltersCopy = [...previousFilters];

        const filterIndex = previousFiltersCopy.findIndex((previousFilter: Filter) => previousFilter.label === filter.label);

        if (filterIndex === -1) {
          removeColumnFilterOnField(previousFiltersCopy, filter.field);
          previousFiltersCopy = [...previousFiltersCopy, filter];
        } else {
          previousFiltersCopy.splice(filterIndex, 1);
        }

        return [...previousFiltersCopy];
      });
    },
    [removeColumnFilterOnField]
  );

  const onDelete = useCallback((filterLabel: string) => {
    setCurrentFilters((previousFilters: Filter[]) => {
      const previousFiltersCopy = [...previousFilters];

      const filterIndex = previousFiltersCopy.findIndex((previousFilter: Filter) => previousFilter.label === filterLabel);

      if (filterIndex !== -1) {
        previousFiltersCopy.splice(filterIndex, 1);
      }

      return [...previousFiltersCopy];
    });
  }, []);

  const onReset = useCallback(() => {
    setCurrentFilters([]);
  }, []);

  return useMemo(
    () => ({
      onChange,
      onToggle,
      onDelete,
      onReset,
      filters: currentFilters
    }),
    [onChange, onToggle, onDelete, onReset, currentFilters]
  );
};

export default useFilters;
