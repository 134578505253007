import { CostLine } from "app/domain/costItem";
import { get, isEmpty, isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import usePrevious from "../../common/hook/usePrevious";

type CostLinesTableHook = {
  onAdd: (newCostLine: CostLine) => void;
  onUpdate: (index: number, costLinesQuantity: number) => void;
  onDelete: (index: number) => void;
  onReset: (costLines: CostLine[]) => void;
  selectedCostLines: CostLine[];
  costLinesTotalCost: number;
};

const useCostLineTable = (selectedCostLines: CostLine[]): CostLinesTableHook => {
  const [currentSelectedCostLines, setCurrentSelectedCostLines] = useState(selectedCostLines);

  const previousCostLines = usePrevious(selectedCostLines);

  useEffect((): void => {
    if (!isEqual(previousCostLines, selectedCostLines)) {
      setCurrentSelectedCostLines(selectedCostLines);
    }
  }, [previousCostLines, selectedCostLines]);

  const costLinesTotalCost = useMemo(() => {
    let total = 0;

    for (let index = 0; index < currentSelectedCostLines.length; index += 1) {
      const quantity = get(currentSelectedCostLines, [index, "quantity"], 0);
      total += quantity * (get(currentSelectedCostLines, [index, "unitCost"], 0) as number);
    }
    return total;
  }, [currentSelectedCostLines]);

  const onAdd = useCallback((newCostLine: CostLine) => {
    setCurrentSelectedCostLines((previousSelectedCostLines: CostLine[]) => {
      const costLine = previousSelectedCostLines.find((line) => line.code === newCostLine.code);
      if (isEmpty(costLine)) {
        return [...previousSelectedCostLines, newCostLine];
      }
      return [...previousSelectedCostLines];
    });
  }, []);

  const onUpdate = useCallback((index: number, costLineQuantity: number) => {
    setCurrentSelectedCostLines((previousSelectedCostLines: CostLine[]) => {
      const copyPreviousSelectedCostLines = [...previousSelectedCostLines];
      const updatedCostLine = {
        ...copyPreviousSelectedCostLines[index],
        quantity: costLineQuantity
      };
      copyPreviousSelectedCostLines.splice(index, 1, updatedCostLine as CostLine);
      return [...copyPreviousSelectedCostLines];
    });
  }, []);

  const onDelete = useCallback((index: number) => {
    setCurrentSelectedCostLines((previousSelectedCostLines: CostLine[]) => {
      const copyPreviousSelectedCostLines = [...previousSelectedCostLines];
      copyPreviousSelectedCostLines.splice(index, 1);
      return [...copyPreviousSelectedCostLines];
    });
  }, []);

  const onReset = useCallback((costLines: CostLine[]) => {
    setCurrentSelectedCostLines(costLines);
  }, []);

  return useMemo(
    () => ({
      onAdd,
      onUpdate,
      onDelete,
      onReset,
      selectedCostLines: currentSelectedCostLines,
      costLinesTotalCost
    }),
    [onAdd, onUpdate, onDelete, onReset, currentSelectedCostLines, costLinesTotalCost]
  );
};

export default useCostLineTable;
