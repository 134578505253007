import { getProjectsAction } from "app/store/project/actions";
import { getProjects } from "app/store/project/selectors";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { useLoader } from "app/view/common/loader/loaderProvider";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Header as SemanticHeader } from "semantic-ui-react";
import { css, SimpleInterpolation } from "styled-components";
import { ErrorMessage } from "ui/errorMessage";
import { Loader } from "ui/loader";

import { ProjectGrid } from "./grid";

// TODO: same style as assetListing. Create a "listing" template.
export const projectListingStyle = css`
  padding: 10px;
  position: relative;
  flex: 1;

  .listingHeader {
    margin: 0;
    padding: 10px;
  }

  .button {
    margin-left: 10px;
  }

  ${ErrorMessage as unknown as SimpleInterpolation} {
    margin: 0 1em;
  }
`;

const ProjectListing: FC<ComponentProps> = ({ className }: ComponentProps) => {
  const { isActive } = useLoader();
  const { errorMessage, onDismiss } = useErrorMessage();
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);

  useEffect(() => {
    onDismiss();
    dispatch(getProjectsAction());
    return (): void => {
      onDismiss();
    };
  }, [dispatch, onDismiss]);

  return (
    <div className={className}>
      <Loader isActive={isActive} />

      <Grid>
        <Grid.Column>
          <SemanticHeader size="huge" className="listingHeader">
            Liste des projets
          </SemanticHeader>
        </Grid.Column>
      </Grid>

      <ErrorMessage message={errorMessage} onDismiss={onDismiss} />
      <ProjectGrid projects={projects} />
    </div>
  );
};

export default createStyledComponent(memo(ProjectListing), projectListingStyle);
