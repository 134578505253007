import Local from "app/domain/local/local";
import System from "app/domain/system/system";
import { useI18n } from "app/locales";
import { getLocals } from "app/store/local/selectors";
import { capitalize } from "app/utils/formatter";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { css, SimpleInterpolation } from "styled-components";
import { useTextField } from "ui/form/controls/textField";
import TextField from "ui/form/controls/textField/textField";

import { SystemCopyFields } from "../useSystemCopyTable";
import AssetsDropdown from "./assetsDropdown";
import LocalsDropdown from "./localsDropdown";

const systemCopyRowStyle = css`
  && {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    gap: 10px;
    ${TextField as unknown as SimpleInterpolation} {
      width: fit-content;
    }
  }
`;

interface SystemCopyRowProps extends ComponentProps {
  system: System;
  index: number;
  onUpdate: (systemCopyFields: SystemCopyFields, index: number) => void;
  onError: (errorMessage: string) => void;
}

const SystemCopyRow: FC<SystemCopyRowProps> = ({ system, index, onUpdate, onError, className }: SystemCopyRowProps) => {
  const { getAttributeLabel } = useI18n();

  const initialLocals = useSelector(getLocals);
  const [listedLocals, setListedLocals] = useState(initialLocals as Local[]);

  const { text: customName, onInput: onCustomNameInput } = useTextField(system.custom_name || "");
  const { text: identification, onInput: onIdentificationInput } = useTextField(system.identification || "");
  const { text: localisation, onInput: onLocalisationInput } = useTextField(system.localisation || "");
  const { text: quantity, onInput: onQuantityInput } = useTextField(system && system.quantity ? (system.quantity as number).toString() : "");

  const { text: serialNumber, onInput: onSerialNumberInput } = useTextField(system.serialNumber || "");

  useEffect(() => {
    onUpdate(
      {
        custom_name: capitalize(customName) as string,
        identification: capitalize(identification) as string,
        localisation: capitalize(localisation) as string,
        quantity: parseInt(quantity, 10),
        serialNumber
      },
      index
    );
  }, [onUpdate, customName, identification, localisation, quantity, serialNumber, index]);

  const handleSuccess = (locals: Local[]): void => {
    onError("");
    setListedLocals(locals);
  };

  const handleError = (errorMessage: string): void => {
    onError(errorMessage);
    setListedLocals([]);
  };

  return (
    <div className={className}>
      <AssetsDropdown system={system} index={index} onUpdate={onUpdate} onError={handleError} onSuccess={handleSuccess} data-test-id="assetsDropdown" />
      <TextField label={getAttributeLabel("custom_name")} text={customName} onInput={onCustomNameInput} data-test-id="customNameInput" />
      <TextField label={getAttributeLabel("identification")} text={identification} onInput={onIdentificationInput} data-test-id="identificationInput" />
      <LocalsDropdown system={system} locals={listedLocals} index={index} onUpdate={onUpdate} data-test-id="localsDropdown" />
      <TextField label={getAttributeLabel("localisation")} text={localisation} onInput={onLocalisationInput} data-test-id="localisationInput" />
      <TextField label={getAttributeLabel("quantity")} number text={quantity} onInput={onQuantityInput} data-test-id="quantityInput" />
      {system && system.assetId && system.assetId.includes("979000") ? (
        <TextField label="No série" text={serialNumber} onInput={onSerialNumberInput} data-test-id="serialNumber" />
      ) : null}
    </div>
  );
};

export default createStyledComponent(memo(SystemCopyRow), systemCopyRowStyle);
