import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Button } from "ui/button";
import { IconType } from "ui/icon";

interface HeaderButtonProps extends ComponentProps {
  onClick: () => void;
  label: string;
  icon: IconType;
}

const HeaderButton: FC<HeaderButtonProps> = ({ onClick, label, icon }: HeaderButtonProps) => <Button title={label} iconType={icon} onClick={onClick} />;

export default HeaderButton;
