export type LoginState = {
  isLoggedIn: boolean;
  currentUser: any;
  passwordWasChanged: boolean;
  fetchDone: boolean;
  isLoading: boolean;
  errorMessage: string;
};

export const initialLoginState: LoginState = {
  isLoggedIn: false,
  currentUser: undefined,
  passwordWasChanged: false,
  fetchDone: true,
  isLoading: false,
  errorMessage: ""
};
