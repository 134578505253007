import { Project } from "app/domain/project";
import { createSelector } from "reselect";

import { State } from "../state";

export const getProjects = createSelector([(state: State): Project[] => state.projects.projects], (projects: Project[]) => projects);

export const getCurrentProject = createSelector([(state: State): Project | undefined => state.projects.currentProject], (currentProject: Project | undefined) => currentProject);

export const getErrorMessage = createSelector([(state: State): string => state.projects.errorMessage], (errorMessage) => errorMessage);

export const isLoading = createSelector([(state: State): boolean => state.projects.isFetching], (isProjectLoading: boolean) => isProjectLoading);
