import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

const dropdownContainerStyle = css`
  width: 300px;
  margin-left: 21%;
  margin-bottom: 20px;
`;

interface DropdownContainerProps extends ComponentProps {
  children: any;
}

const DropdownContainer: FC<DropdownContainerProps> = ({ children, className }: DropdownContainerProps) => <div className={className}>{children}</div>;

export default createStyledComponent(DropdownContainer, dropdownContainerStyle);
