/* stylelint-disable value-keyword-case */

import { FC, ForwardRefExoticComponent, NamedExoticComponent } from "react";
import styled, { FlattenInterpolation, FlattenSimpleInterpolation, ThemeProps } from "styled-components";
import { applyStyleModifiers, ModifiersConfig } from "styled-components-modifiers";

type Style = FlattenInterpolation<ThemeProps<any>>;

type StyleModifier = (theme: Record<string, unknown>) => FlattenSimpleInterpolation;

export type StyleModifiers = {
  [key: string]: StyleModifier;
};

export type StyleProps = any;

export interface ComponentProps {
  className?: string;
  modifiers?: string | Array<string | boolean>;
}

export const createStyledComponent = <Props>(
  component: FC<Props> | ForwardRefExoticComponent<Props> | NamedExoticComponent<Props>,
  style: Style,
  styleModifiers?: StyleModifiers
): FC<Props> =>
  styled(component)`
    ${style}
    ${styleModifiers && applyStyleModifiers(styleModifiers as ModifiersConfig)};
  ` as FC<Props>;
