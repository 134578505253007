import { Project } from "app/domain/project";
import { useRouter } from "app/routing/routerProvider";
import { setCurrentProjectAction } from "app/store/project/actions";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { useDispatch } from "react-redux";
import { Card as SemanticCard, Image as SemanticImage } from "semantic-ui-react";
import { css } from "styled-components";

const projectGridStyle = css`
  padding: 10px;
`;

interface ProjectGridProps extends ComponentProps {
  projects: Project[];
}

const ProjectGrid: FC<ProjectGridProps> = ({ projects, className }: ProjectGridProps) => {
  const { location } = useRouter();

  const dispatch = useDispatch();

  return (
    <SemanticCard.Group className={className}>
      {projects.map((project: Project) => {
        return (
          <SemanticCard href={`${location}/${project.id}`} key={project.id} onClick={(): any => dispatch(setCurrentProjectAction(project.id))}>
            <SemanticCard.Content>
              <SemanticImage floated="left" size="mini" src="https://cdn0.iconfinder.com/data/icons/real-estate-4-8/66/180-512.png" />
              <SemanticCard.Header>{project.id}</SemanticCard.Header>
              <SemanticCard.Description>{project.description}</SemanticCard.Description>
            </SemanticCard.Content>
          </SemanticCard>
        );
      })}
    </SemanticCard.Group>
  );
};

export default createStyledComponent(memo(ProjectGrid), projectGridStyle);
