import { InputBase } from "@mui/material";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useCallback } from "react";
import { css, SimpleInterpolation } from "styled-components";
import { Button } from "ui/button";

const SEARCH_BUTTON_TITLE = "Rechercher";
const SEARCH_INPUT_PLACEHOLDER = " ID à trouver";

const searchInputStyle = css`
  display: flex;
  height: 100%;

  .searchInput {
    margin-right: 5px;
    background-color: white;
    border-radius: 4px;
  }

  &&& ${Button as unknown as SimpleInterpolation} {
    height: 100%;
  }
`;

interface SearchInputProps extends ComponentProps {
  value: string;
  onChange: (newValue: string) => void;
  onSearch: () => void;
}

const SearchInput: FC<SearchInputProps> = ({ value, onSearch, onChange, className }: SearchInputProps) => {
  const handleInputChange = useCallback(
    (event) => {
      event.preventDefault();

      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <div className={className}>
      <InputBase
        placeholder={SEARCH_INPUT_PLACEHOLDER}
        value={value}
        onChange={handleInputChange}
        className="searchInput"
        data-test-id="searchInput"
        color="primary"
        inputProps={{ "aria-label": "naked" }}
      />

      <Button title={SEARCH_BUTTON_TITLE} onClick={onSearch} data-test-id="searchButton" />
    </div>
  );
};

export default createStyledComponent(SearchInput, searchInputStyle);
