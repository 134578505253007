import Report from "app/domain/report/report";
import api, { NEW_API_VERSION } from "app/service/common/api";

const extractFilename = (contentDisposition: string): string => {
  let filename = "";

  if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  return filename;
};

class ReportService {
  static generateReportTemplate = async (): Promise<Report> => {
    const reportDTO = await api.get({
      path: `${NEW_API_VERSION}/import/template`,
      responseType: "blob"
    });

    const filename = extractFilename(reportDTO.headers["content-disposition"]);
    const blob = new Blob([reportDTO.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    return { path: window.URL.createObjectURL(blob), fileName: filename };
  };

  static importData = async (file: File): Promise<void> => {
    await api.post({
      path: `${NEW_API_VERSION}/import/importing`,
      file
    });
  };

  static uploadCustomerReferences = async (file: File): Promise<void> => {
    await api.post({
      path: `${NEW_API_VERSION}/import/update_customer_ref`,
      file
    });
  };

  static exportDataForVFA = async (siteIds: string[], tableName: string): Promise<any> => {
    const dataForVFA = await api.post({
      path: `${NEW_API_VERSION}/export/vfa`,
      body: { site_ids: siteIds, table_name: tableName },
      responseType: "blob"
    });

    const filename = extractFilename(dataForVFA.headers["content-disposition"]);
    const blob = new Blob([dataForVFA.data], {
      type: "application/xlsx"
    });

    return { url: window.URL.createObjectURL(blob), fileName: filename };
  };
}
export default ReportService;
