import { MODAL_TYPE_REQUIREMENT, MODAL_TYPE_SYSTEM } from "app/view/constant";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useState } from "react";
import { Button as SemanticButton, Header, Icon, Modal as SemanticModal } from "semantic-ui-react";
import { DeleteButton } from "ui/button/actions";

interface DeleteModalProps extends ComponentProps {
  itemType: string;
  onDelete: () => void;
}

const DeleteModal: FC<DeleteModalProps> = ({ itemType, onDelete }: DeleteModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getNameAndHeader = (type: string): any => {
    switch (type) {
      case MODAL_TYPE_SYSTEM:
        return { name: "ce système", header: "du système" };
      case MODAL_TYPE_REQUIREMENT:
        return { name: "cette exigence", header: "de l'exigence" };
      default:
        return { name: null, header: null };
    }
  };

  const handleDelete = (): void => {
    onDelete();
    setIsModalOpen(false);
  };

  return (
    <SemanticModal trigger={<DeleteButton onClick={(): void => setIsModalOpen(true)} label="" />} open={isModalOpen} onClose={(): void => setIsModalOpen(false)} basic size="small">
      <Header icon="trash" content={`Supression ${getNameAndHeader(itemType).header}`} />
      <SemanticModal.Content>
        <p>{`Voulez-vous vraiment supprimer ${getNameAndHeader(itemType).name} ? Tous les éléments appartenants à ${getNameAndHeader(itemType).name} seront supprimés.`}</p>
      </SemanticModal.Content>
      <SemanticModal.Actions>
        <SemanticButton color="red" onClick={(): void => setIsModalOpen(false)}>
          <Icon name="remove" /> Annuler
        </SemanticButton>
        <SemanticButton color="green" onClick={handleDelete}>
          <Icon name="checkmark" />
          Supprimer
        </SemanticButton>
      </SemanticModal.Actions>
    </SemanticModal>
  );
};

export default DeleteModal;
