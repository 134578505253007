import api, { Options } from "./api";

class UserAPI {
  async login(user) {
    const option = new Options({ body: user.toJSON() });
    const { data } = await api.post("/login", option.body);
    return data;
  }

  async changePassword(email, newPassword) {
    const option = new Options({
      body: { user_password: newPassword, email: email },
    });
    const { data } = await api.put("/create-account", option.body);
    return data;
  }
}

export default new UserAPI();
