import { AssetMinimalInfo } from "app/domain/asset";

export type AssetMinimalInfoDTO = {
  id: string;
  asset_number: string;
  name: string;
  area: number;
};

class AssetMinimalInfoMapper {
  static fromDTO(assetMinimalInfoDTO: AssetMinimalInfoDTO): AssetMinimalInfo;
  static fromDTO(assetMinimalInfoDTOs: AssetMinimalInfoDTO[]): AssetMinimalInfo[];
  static fromDTO(assetMinimalInfoDTOs: AssetMinimalInfoDTO | AssetMinimalInfoDTO[]): any {
    if (!Array.isArray(assetMinimalInfoDTOs)) {
      return new AssetMinimalInfo({
        id: assetMinimalInfoDTOs.id,
        number: assetMinimalInfoDTOs.asset_number,
        name: assetMinimalInfoDTOs.name,
        area: assetMinimalInfoDTOs.area
      });
    }
    return assetMinimalInfoDTOs.map((assetMinimalInfoDTO: AssetMinimalInfoDTO): AssetMinimalInfo => this.fromDTO(assetMinimalInfoDTO));
  }
}

export default AssetMinimalInfoMapper;
