import { CostLine } from "app/domain/costItem";
import { useI18n } from "app/locales";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { Table as SemanticTable } from "semantic-ui-react";

import CostLinesTableRow from "./costLinesTableRow";

interface CostLinesTableProps extends ComponentProps {
  costLines: CostLine[];
  onDeleteCostLine: (costLineId: number) => void;
  onUpdateCostLine: (costLineId: number, costLineQuantity: number) => void;
}

const CostLinesTable: FC<CostLinesTableProps> = ({ costLines, onDeleteCostLine, onUpdateCostLine }: CostLinesTableProps) => {
  const { getAttributeLabel } = useI18n();

  return (
    <SemanticTable>
      <SemanticTable.Header>
        <SemanticTable.Row>
          <SemanticTable.HeaderCell />
          <SemanticTable.HeaderCell>Classe</SemanticTable.HeaderCell>
          <SemanticTable.HeaderCell>Code</SemanticTable.HeaderCell>
          <SemanticTable.HeaderCell>{getAttributeLabel("description")}</SemanticTable.HeaderCell>
          <SemanticTable.HeaderCell>{getAttributeLabel("quantity")}</SemanticTable.HeaderCell>
          <SemanticTable.HeaderCell style={{ textAlign: "center" }}>{getAttributeLabel("unit_of_measure")}</SemanticTable.HeaderCell>
          <SemanticTable.HeaderCell style={{ textAlign: "right" }}>{getAttributeLabel("unit_cost")}</SemanticTable.HeaderCell>
          <SemanticTable.HeaderCell style={{ textAlign: "right" }}>{getAttributeLabel("total_cost")}</SemanticTable.HeaderCell>
        </SemanticTable.Row>
      </SemanticTable.Header>
      <SemanticTable.Body data-test-id="costLinesTableBody">
        {costLines.map((costLine: CostLine, index: number) => (
          <CostLinesTableRow costLine={costLine} onDeleteCostLine={onDeleteCostLine} onUpdateCostLine={onUpdateCostLine} index={index} key={costLine.code} />
        ))}
      </SemanticTable.Body>
    </SemanticTable>
  );
};

export default memo(CostLinesTable);
