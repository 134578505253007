/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { EntityCategory } from "app/domain/entityCategory";
import { System, SystemLabel, SystemModel, SystemModelAction } from "app/domain/system";
import SystemMinimalInfo from "app/domain/system/systemMinimalInfo";
import api, { NEW_API_VERSION } from "app/service/common/api";

import SystemCopiesMapper from "./systemCopiesMapper";
import SystemLabelMapper, { PrinterStatusDTO } from "./systemLabelMapper";
import SystemMapper, { SystemDTO } from "./systemMapper";
import SystemMinimalInfoMapper, { SystemMinimalInfoDTO } from "./systemMinimalInfoMapper";
import SystemModelActionMapper, { SystemModelActionDTO } from "./systemModelActionMapper";
import SystemModelMapper, { SystemModelDTO } from "./systemModelMapper";

class SystemService {
  static getSystem = async (systemId: string): Promise<System> => {
    const systemDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/systems/${systemId}`
      })
    ).data as SystemDTO;
    return SystemMapper.fromDTO(systemDTO);
  };

  static getSystems = async (parentType: EntityCategory, parentId: string): Promise<System[]> => {
    let path = "";
    if (parentType === EntityCategory.ASSET) {
      path = `${NEW_API_VERSION}/assets/${parentId}/systems`;
    } else {
      path = `${NEW_API_VERSION}/systems?room_id=${parentId}`;
    }
    const systemDTOs = (
      await api.get({
        path
      })
    ).data as SystemDTO[];

    return SystemMapper.fromDTO(systemDTOs);
  };

  static createSystem = async (system: System): Promise<System> => {
    const systemDTO = SystemMapper.toDTO(system);

    const createdSystemDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/systems`,
        body: systemDTO
      })
    ).data as SystemDTO;

    return SystemMapper.fromDTO(createdSystemDTO);
  };

  static createSystemCopies = async (systemId: string, systemCopies: System[]): Promise<System[]> => {
    const systemCopiesDTO = SystemMapper.toDTO(systemCopies);

    const createdSystemCopiesDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/systems/${systemId}/copies`,
        body: systemCopiesDTO
      })
    ).data as SystemDTO[];

    return SystemMapper.fromDTO(createdSystemCopiesDTO);
  };

  static createSystemCopiesToDestination = async (
    isCopyingIdentification: boolean,
    isCopyingQuantity: boolean,
    systemToCopyIds: string[],
    destinationAssetIds: string[]
  ): Promise<void> => {
    const systemCopiesDTO = SystemCopiesMapper.toDTO(isCopyingIdentification, isCopyingQuantity, systemToCopyIds, destinationAssetIds);

    await api.post({
      path: `${NEW_API_VERSION}/systems/copies`,
      body: systemCopiesDTO
    });
  };

  static updateSystem = async (system: System): Promise<System> => {
    const systemDTO = SystemMapper.toDTO(system);

    const updatedSystemDTO = (
      await api.put({
        id: systemDTO.id,
        path: `${NEW_API_VERSION}/systems`,
        body: systemDTO
      })
    ).data as SystemDTO;

    return SystemMapper.fromDTO(updatedSystemDTO);
  };

  static getSystemModels = async (projectId: string, uniformat?: string, name?: string): Promise<SystemModel[]> => {
    const queryParams = { project_id: projectId };
    if (uniformat) {
      Object.assign(queryParams, { uniformat });
    }
    if (name) {
      Object.assign(queryParams, { name });
    }

    const systemModelDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/system_models`,
        queryParams
      })
    ).data as SystemModelDTO[];
    return SystemModelMapper.fromSystemModelDTO(systemModelDTO);
  };

  static getSystemModelActions = async (systemModelId: string): Promise<SystemModelAction[]> => {
    const systemModelActionDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/system_models/${systemModelId}/actions`
      })
    ).data as SystemModelActionDTO[];
    return SystemModelActionMapper.fromSystemModelActionDTO(systemModelActionDTOs);
  };

  static deleteSystem = async (systemId: string): Promise<void> => {
    await api.delete({
      path: `${NEW_API_VERSION}/systems`,
      id: systemId
    });
  };

  static getSystemsMinimalInfo = async (assetId: string): Promise<SystemMinimalInfo[]> => {
    const systemsMinimalInfoDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/systems`,
        queryParams: { asset_id: assetId, options: "minimal" }
      })
    ).data as SystemMinimalInfoDTO[];

    return SystemMinimalInfoMapper.fromDTO(systemsMinimalInfoDTO);
  };

  static printSystemLabel = async (systemLabel: any): Promise<void> => {
    const systemLabelDTO = SystemLabelMapper.toDTO(systemLabel);

    const printerStatusDTO = (
      await api.post({
        path: "/print_label",
        body: systemLabelDTO,
        options: {
          baseURL: "http://localhost:8085/",
          headers: {
            "Content-Type": "application/json; charset=utf-8"
          }
        }
      })
    ).data as PrinterStatusDTO;

    if (!printerStatusDTO.printerIsOnline || !printerStatusDTO.printSuccess) {
      throw new Error();
    }
  };

  static createLabel = async (systemId: string): Promise<SystemLabel> => {
    const systemLabel = (
      await api.get({
        path: `${NEW_API_VERSION}/systems/${systemId}/create_label`
      })
    ).data as SystemLabel;

    return systemLabel;
  };

  static generateQrCode = async (systemId: string): Promise<System> => {
    const systemDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/systems/${systemId}/qr_code`
      })
    ).data as SystemDTO;

    return SystemMapper.fromDTO(systemDTO);
  };
}

export default SystemService;
