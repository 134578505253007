import { PayloadAction } from "../types";

export enum ConnectionStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE"
}

export enum ConnectionActionTypes {
  UPDATE_STATUS = "UPDATE_STATUS"
}

export type UpdateConnectionStatusAction = PayloadAction<ConnectionActionTypes.UPDATE_STATUS, ConnectionStatus>;
