import React from "react";
import { Form, Message, Icon, Button } from "semantic-ui-react";
import styled from "styled-components";
import { CostLine } from "app/domain/costItem";
let debounce = require("lodash.debounce");

const SearchResultsStyle = styled.div`
  border: 1px solid lightblue;
  border-radius: 5px;
  max-height: 300px;
  overflow: auto;
  background-color: white;
  margin-bottom: 10px;
`;

const SearchOption = styled.p`
  padding: 5px;
  margin: 0;

  :hover {
    background-color: rgb(200, 200, 200) !important;
    cursor: pointer;
  }

  :nth-child(even) {
    background-color: rgb(220, 220, 220);
  }
`;

export class CostItemSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      possibleChoices: [],
      hideMessage: true,
      clearInput: false,
    };
    this.searchForRSMeanLine = this.searchForRSMeanLine.bind(this);
    this.handleChoiceSelect = this.handleChoiceSelect.bind(this);
    this.clearInput = this.clearInput.bind(this);
  }

  searchForRSMeanLine(event) {
    const { costItems } = this.props;
    const { inputValue } = this.state;
    event.preventDefault();
    if (inputValue === "") {
      this.setState({ possibleChoices: [] });
    } else if (inputValue) {
      this.setState({
        possibleChoices: costItems.filter((option) => {
          if (option.description) {
            const wordsUsed = inputValue.toLowerCase().trim().split(",");
            for (let i = 0; i < wordsUsed.length; i++) {
              let orWords = wordsUsed[i].trim().split(";");
              let wordWasFound = false;
              let j = 0;
              while (j < orWords.length && !wordWasFound) {
                wordWasFound =
                  option.description
                    .toLowerCase()
                    .indexOf(orWords[j].trim()) !== -1;
                j++;
              }
              if (!wordWasFound) return false;
            }
            return true;
          } else {
            return false;
          }
        }),
      });
    }
  }

  debounceResearch = debounce((e) => {
    this.searchForRSMeanLine(e, e.target);
  }, 300);

  addCostLine = (idToFind) => {
    if (idToFind) {
      const selectedCostItem = this.props.costItems.filter(
        (costItem) => costItem.id === idToFind
      );
      if (selectedCostItem) {
        const newCostLine = new CostLine({
          ...selectedCostItem[0],
          id: undefined,
          costItemId: selectedCostItem[0].id,
          quantity: 0,
        });
        this.props.onChange(newCostLine);
      }
    }
  };

  handleChoiceSelect(event, choice) {
    this.setState(
      {
        possibleChoices: [],
        inputValue: event.target.innerText,
      },
      this.addCostLine(choice.id)
    );
    this.setState({ inputValue: "", clearInput: true });
  }

  clearInput() {
    this.setState({ inputValue: "", clearInput: true });
  }

  render() {
    const { possibleChoices, inputValue, hideMessage } = this.state;

    return (
      <div>
        <Message hidden={hideMessage}>
          <Message.Header>Fonction de recherche</Message.Header>
          <ul>
            <li>
              La fonction recherche dans la description d'un élément de coût
            </li>
            <li>
              Si la description doit contenir plusieurs termes, séparer les
              termes par une virgule
            </li>
            <li>
              Si la description doit contenir un terme OU un autre, séparer les
              alternatives par un point-virgule
            </li>
            <li>
              La recherche ne prends pas en compte la casse ou les espaces entre
              les termes
            </li>
            <li>
              <strong>Exemple:</strong> ( circuit breaker , 3 phase, 400 a;600a
              ) retournera les descriptions contenant "circuit breaker", "3
              phase", et "400 a" ou "600a"
            </li>
          </ul>
        </Message>
        <Form.Group widths="equal">
          <Button
            circular
            onClick={(e) => {
              e.preventDefault();
              this.setState({ hideMessage: !this.state.hideMessage });
            }}
          >
            <Icon size="large" name="info circle" />
          </Button>
          <Form.Input
            style={{ width: "100%" }}
            name="inputValue"
            value={inputValue}
            placeholder="Rechercher un élément de coût à ajouter"
            onChange={(e) => {
              if (e.target.value === "") this.setState({ possibleChoices: [] });
              this.debounceResearch(e);
              this.setState({ inputValue: e.target.value });
            }}
          />
        </Form.Group>
        {possibleChoices.length > 0 ? (
          <SearchResultsStyle>
            {possibleChoices.map((choice) => (
              <SearchOption
                id={choice.id}
                className="costItemSearchChoice"
                onClick={(e) => this.handleChoiceSelect(e, choice)}
              >
                <div className={"costItemOptions"}>
                  <div style={{ flex: 7 }}>{choice.description}</div>
                  <div style={{ flex: 4 }}>{choice.code}</div>
                  <div style={{ flex: 2 }}>{choice.unitOfMeasure}</div>
                  <div style={{ flex: 1 }}>{choice.unitCost}</div>
                </div>
              </SearchOption>
            ))}
          </SearchResultsStyle>
        ) : null}
      </div>
    );
  }
}

CostItemSearchBar.defaultProps = {
  costItems: [],
  onChange: () => {},
};
