export const categoryOptions = [
  {
    value: 10,
    text: "Sécurité des personnes"
  },
  {
    value: 20,
    text: "Intégrité"
  },
  {
    value: 30,
    text: "Au-delà de la durée de vie nominale"
  },
  {
    value: 40,
    text: "Désuétude"
  },
  {
    value: 50,
    text: "Fiabilité"
  },
  {
    value: 60,
    text: "Apparence"
  },
  {
    value: 70,
    text: "Énergie"
  },
  {
    value: 80,
    text: "Entretien"
  },
  {
    value: 90,
    text: "Capacité"
  },
  {
    value: 100,
    text: "Environnemental"
  },
  {
    value: 110,
    text: "Qualité de l'air et de l'eau"
  },
  {
    value: 120,
    text: "Amiante"
  },
  {
    value: 130,
    text: "Plomb"
  },
  {
    value: 140,
    text: "BPC"
  },
  {
    value: 150,
    text: "Fonctionnalité"
  },
  {
    value: 160,
    text: "Mission"
  },
  {
    value: 170,
    text: "Modernisation"
  },
  {
    value: 180,
    text: "Adaptation"
  },
  {
    value: 190,
    text: "Divers"
  },
  {
    value: 200,
    text: "Conformité au code"
  },
  {
    value: 210,
    text: "Accessibilité"
  },
  {
    value: 220,
    text: "Opérations"
  }
];

export const updateOptions = [
  {
    value: 10,
    text: "ER - En rédaction"
  },
  {
    value: 20,
    text: "EA - En attente"
  },
  {
    value: 30,
    text: "PR - Programmé"
  },
  {
    value: 40,
    text: "TB - Transféré en BT"
  },
  {
    value: 50,
    text: "CM - Complété"
  },
  {
    value: 60,
    text: "AV - À valider"
  },
  {
    value: 70,
    text: "AR - Archivé"
  }
];

export const reasonOptions = [
  {
    value: 10,
    text: "CI - Condition d’origine inadéquate"
  },
  {
    value: 20,
    text: "EB - Évolution du besoin"
  },
  {
    value: 30,
    text: "ND - Non déterminé"
  },
  {
    value: 40,
    text: "PR - Préventif"
  },
  {
    value: 50,
    text: "SI - Sinistre ou vandalisme"
  },
  {
    value: 60,
    text: "UA - Usure anormale ou usage inapproprié"
  },
  {
    value: 70,
    text: "UN - Usure normale"
  },
  {
    value: 80,
    text: "SO  - Sans objet"
  }
];

export const statusOptions = [
  {
    value: 10,
    text: "AB - Absent"
  },
  {
    value: 20,
    text: "DX - Défectueux"
  },
  {
    value: 30,
    text: "IN - Inutile ou nuisible"
  },
  {
    value: 40,
    text: "NA - Non accessible"
  },
  {
    value: 50,
    text: "NC - Non conforme"
  },
  {
    value: 60,
    text: "NF - Non fonctionnel"
  },
  {
    value: 70,
    text: "SA - Satisfaisant"
  },
  {
    value: 80,
    text: "UC - Non constaté"
  },
  {
    value: 90,
    text: "VU - Vie utile résiduelle"
  }
];
