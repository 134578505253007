/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { CostItem, CostLine } from "app/domain/costItem";

import api, { NEW_API_VERSION } from "../common/api";
import CostItemMapper, { CostItemDTO } from "./costItemMapper";
import CostLineMapper, { CostLineDTO } from "./costLineMapper";

class CostItemService {
  static getCostItems = async (): Promise<CostItem[]> => {
    const costItemDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/cost_items`
      })
    ).data as CostItemDTO[];

    return CostItemMapper.fromDTO(costItemDTOs);
  };

  static addCostItem = async (costItem: CostItem): Promise<CostItem> => {
    const costItemDTO = CostItemMapper.toDTO(costItem);

    const newCostItemDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/cost_items`,
        body: costItemDTO
      })
    ).data as CostItemDTO;

    return CostItemMapper.fromDTO(newCostItemDTO);
  };

  static getSystemModelActionCostLines = async (systemModelActionId: string): Promise<CostLine[]> => {
    const costLineDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/system_models/cost_lines`,
        queryParams: { system_model_action_id: systemModelActionId }
      })
    ).data as CostLineDTO[];

    return CostLineMapper.fromDTO(costLineDTOs);
  };

  static getRequirementReplacementCostLines = async (uniformat3: string): Promise<CostLine[]> => {
    const costLineDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/requirements/uniformat_cost_lines`,
        queryParams: { uniformat3 }
      })
    ).data as CostLineDTO[];

    return CostLineMapper.fromDTO(costLineDTOs);
  };
}

export default CostItemService;
