import { Site } from "app/domain/site";
import { useCallback, useEffect, useMemo, useState } from "react";

export type SiteFields = {
  [key: string]: any;
};

type SiteHook = {
  currentSite: any;
  onUpdate: (fields: SiteFields) => void;
};

const useSite = (initialSite: Site | undefined): SiteHook => {
  const [currentSite, setCurrentSite] = useState({
    ...initialSite
  });

  const onUpdate = useCallback((fields: SiteFields) => {
    setCurrentSite((prevState) => ({
      ...prevState,
      ...fields
    }));
  }, []);

  useEffect(() => {
    if (initialSite) {
      onUpdate(initialSite);
    }
  }, [initialSite, onUpdate]);

  return useMemo(
    () => ({
      currentSite,
      onUpdate
    }),
    [currentSite, onUpdate]
  );
};

export default useSite;
