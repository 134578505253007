/* eslint-disable @typescript-eslint/no-unused-vars */

import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import { transparentize } from "polished";
import React, { FC } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { css } from "styled-components";
import { colors } from "ui/colors";
import Checkbox from "ui/form/controls/checkbox/checkbox";

import SelectAllValuesCheckbox from "./selectAllValuesCheckbox";

const valuesSelectorStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;

  .noResult {
    height: 150px;
    width: 250px;
    margin: auto;
  }

  .checkbox {
    padding: 10px 0;
    width: 100%;
  }

  .agGridFilter {
    height: 250px;
    width: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .ReactVirtualized__Grid {
      outline: none;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;

      .checkbox:hover {
        background-color: ${transparentize(0.8, colors.ACCENT_BLUE)};
      }
    }
  }
`;

interface AgGridValuesSelectorProps extends ComponentProps {
  values: string[];
  selectedValues: string[];
  searchedValue: string;
  onSelect: (value: string) => void;
  isChecked: boolean;
  isIndeterminate: boolean;
  onSelectAll?: () => void;
}

const AgGridValuesSelector: FC<AgGridValuesSelectorProps> = ({
  values,
  selectedValues,
  searchedValue,
  onSelect,
  isChecked,
  isIndeterminate,
  onSelectAll,
  className
}: AgGridValuesSelectorProps) => {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 50
  });

  const renderValue = ({ index, key, parent, style }: any): JSX.Element => (
    <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
      <div style={style}>
        <Checkbox isChecked={selectedValues.includes(values[index])} label={values[index]} onClick={(): void => onSelect(values[index])} className="checkbox" key={key} />
      </div>
    </CellMeasurer>
  );

  const renderValues = ({ width, height }: any): JSX.Element => (
    <List width={width} height={height} deferredMeasurementCache={cache} rowHeight={cache.rowHeight} rowRenderer={renderValue} rowCount={values.length} overscanRowCount={30} />
  );

  return (
    <div className={className}>
      {values.length > 0 ? (
        <>
          {onSelectAll && <SelectAllValuesCheckbox searchedValue={searchedValue} onSelectAll={onSelectAll} isChecked={isChecked} isIndeterminate={isIndeterminate} />}
          <div className="agGridFilter">
            <AutoSizer>{renderValues}</AutoSizer>
          </div>
        </>
      ) : (
        <span className="noResult">Aucun élément ne correspond à votre recherche</span>
      )}
    </div>
  );
};

export default createStyledComponent(AgGridValuesSelector, valuesSelectorStyle);
