/* eslint-disable react-hooks/exhaustive-deps */

import { Filter, FilterType } from "app/domain/filter";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo, useMemo } from "react";
import compare from "react-fast-compare";
import { FilterPanel } from "ui/table/filterPanel";
import { RowData, TableHeaderColumn } from "ui/table/types";
import { sortAlphanumeric } from "ui/table/utils";

interface ColumnFilterPanelProps extends ComponentProps {
  column: TableHeaderColumn;
  rows: RowData[];
  onFilter: (filter: Filter) => void;
  filters?: Filter[];
}

const isEqual = (prevProps: ColumnFilterPanelProps, nextProps: ColumnFilterPanelProps): boolean => {
  const areRowsEqual = compare(prevProps.rows, nextProps.rows);
  const areFiltersEqual = compare(prevProps.filters, nextProps.filters);

  return areRowsEqual && areFiltersEqual;
};

const ColumnFilterPanel: FC<ColumnFilterPanelProps> = ({ column, rows, onFilter, filters }: ColumnFilterPanelProps) => {
  const isRowPreviouslyFiltered = (row: RowData, currentFilters?: Filter[]): boolean => {
    if (currentFilters) {
      for (let index = 0; index < currentFilters.length; index += 1) {
        if (currentFilters[index].field !== column.field) {
          const otherRowValue = String(row[currentFilters[index].field].accessor);
          if (!currentFilters[index].values.includes(String(otherRowValue))) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const values = useMemo((): string[] => {
    const columnAccessors = rows.filter((row: RowData) => row[column.field] !== undefined).filter((row: RowData) => isRowPreviouslyFiltered(row, filters));

    const sortedColumnAccessors = columnAccessors
      .sort((firstRow: RowData, secondRow: RowData) => sortAlphanumeric(firstRow[column.field].accessor, secondRow[column.field].accessor))
      .map((row: RowData) => String(row[column.field].accessor));

    return Array.from(new Set(sortedColumnAccessors));
  }, [rows, column.field, filters]);

  const filteredValues = useMemo(() => {
    if (filters) {
      const currentFilters = filters.filter((filter: Filter) => filter.field === column.field).map((filter: Filter) => filter.values);
      return currentFilters.length > 0 ? currentFilters[0] : [];
    }
    return [];
  }, [filters, column.field]);

  const handleFilter = (filterLabels: string[]): void => {
    onFilter(
      new Filter({
        label: column.Header as string,
        field: column.field,
        type: FilterType.COLUMN_FILTER,
        values: filterLabels.length === values.length ? [] : filterLabels
      })
    );
  };

  return <FilterPanel values={values} filteredValues={filteredValues} onFilter={handleFilter} filterType={FilterType.COLUMN_FILTER} data-test-id="filterPanel" />;
};

export default memo(ColumnFilterPanel, isEqual);
