/* eslint-disable react-hooks/exhaustive-deps */

import { Grid, Input, TextField } from "@mui/material";
import { DataNode } from "antd/lib/tree";
import { CostLine } from "app/domain/costItem";
import { System } from "app/domain/system";
import UniformatOption from "app/domain/uniformat/options/uniformatOption";
import { UniformatService } from "app/service/uniformat";
import { capitalize } from "app/utils/formatter";
import IdGenerator from "app/utils/idGenerator";
import TreeViewStyle from "app/view/project/treeViewStyle";
import SystemModelLabels from "app/view/system/form/systemForm/formSections/systemInformation/systemModelModal/systemModelLabels";
import { SystemFields } from "app/view/system/form/systemForm/useSystem";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect, useState } from "react";
import { useAsync } from "react-async";
import { Segment as SemanticSegment } from "semantic-ui-react";
import { css } from "styled-components";
import { Button } from "ui/button";
import { IconType } from "ui/icon";
import InputLabel from "ui/inputLabel/inputLabel";
import { Modal } from "ui/modal/index";

import { SystemModelModal, useSystemModel } from "./systemModelModal";

const systemNameSectionStyle = css`
  padding: 1em 0;
  width: 100%;

  .systemModelSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .Input {
    padding: 20px 0 4px;
  }

  .systemNameSection {
    display: flex;
    justify-content: space-between;
  }

  .systemModelLabels {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  .uniformatLabel {
    flex: 0 1 0;
  }

  .systemModelNameLabel {
    flex: 1 0 0;
  }

  .actionLabel {
    flex: 0 1 0;
  }

  .field {
    padding: 0.5em 0;
  }
`;

interface SystemNameSectionProps extends ComponentProps {
  projectId: string;
  system: System | undefined;
  isElementInCreationMode: boolean;
  onCostLinesChange: (costLine: CostLine[]) => void;
  onUpdate: (fields: SystemFields) => void;
  systemWithoutModel?: boolean;
  withModel: () => void;
}

const SystemNameSection: FC<SystemNameSectionProps> = ({
  isElementInCreationMode,
  projectId,
  system,
  onCostLinesChange,
  onUpdate,
  className,
  systemWithoutModel,
  withModel
}: SystemNameSectionProps) => {
  const { currentModel, currentAction, onUpdateModel, onUpdateAction, onReset } = useSystemModel();
  const [addableField, setAddableField] = useState(
    (system && system.custom_name_concat ? system.custom_name_concat : "").replace(system && system.custom_name ? system.custom_name : "", "")
  );
  const [isSystemModalOpen, setIsSystemModalOpen] = useState(!!isElementInCreationMode);
  const [isUniformatmodalOpen, setIsUniformatmodalOpen] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const onOpenSystemModelModal = (): void => {
    setIsSystemModalOpen(true);
    if (system && system.systemModelId && system.systemModelActionId) {
      onUpdateModel({
        id: system.systemModelId,
        name: system && system.name ? system.name : "",
        uniformat: system.uniformat,
        actions: []
      });
      onUpdateAction({
        id: system.systemModelActionId,
        systemModelId: system.systemModelId as string,
        actionType: system.actionType as string,
        modelNumber: system.model_number as string,
        renewalPercentage: system.renewalPercentage as number,
        description: system.description as string,
        unitCost: system.unitCost as number,
        unitOfMeasure: system.unitOfMeasure as string,
        lifetime: system.usefulLife as number,
        comment: system.comment as string,
        factor: system.unitOfMeasureFactor as number,
        costLines: system.costLines ? system.costLines : []
      });
    }
  };

  const onCloseSystemModelModal = (): void => {
    setIsSystemModalOpen(false);
  };

  const handleCustomNameChange = (event: any): void => {
    event.preventDefault();
    onUpdate({ [event.target.name]: capitalize(event.target.value.replace("≤", "<=")) });
  };

  const onOpenUniformatModal = (): void => {
    setIsUniformatmodalOpen(true);
  };

  const onCloseUniformatModal = (): void => {
    setIsUniformatmodalOpen(false);
    if (selected.length > 0) {
      onUpdate({
        uniformat: {
          level1: selected[0].split("")[0],
          level2: selected[0].split("/")[0].slice(0, 3),
          level3: `${selected[0].split("/")[0].slice(0, 4)}0`,
          level4: selected[0].split("/")[0]
        }
      });
    }
  };

  const fetchUniformats = async (searchedUniformatParent?: string): Promise<DataNode[]> => {
    const uniformats = await UniformatService.getUniformatOptions(projectId, searchedUniformatParent?.split("/")[0]);
    return uniformats.map(({ text, value, level }) => ({
      key: `${value}/${IdGenerator.generate()}`,
      title: text,
      checkable: level === "4" || level === "3",
      isLeaf: level === "4"
    }));
  };

  useEffect(() => {
    const customNameConcat = system && system.custom_name_concat ? system.custom_name_concat : "";
    const customName = system && system.custom_name ? system.custom_name : "";
    setAddableField(customNameConcat.replace(customName, ""));
  }, [system]);

  return (
    <div className={className}>
      <SemanticSegment className="systemModelSection">
        <SystemModelLabels system={system} selected={selected} />
        <div>
          <Button title="Modèle" modifiers="outlined" iconType={IconType.EDIT_OUTLINE} onClick={onOpenSystemModelModal} data-test-id="button" />
        </div>
        {(systemWithoutModel || system?.model_number === "Custom") && (
          <div style={{ marginLeft: "10px" }}>
            <Button title="Uniformat" modifiers="outlined" iconType={IconType.EDIT_OUTLINE} onClick={onOpenUniformatModal} data-test-id="button" />
          </div>
        )}
      </SemanticSegment>

      <Grid container spacing={2}>
        <Grid item xs={6} alignSelf="center">
          <InputLabel label="Nom modifié" text="" />
          <TextField
            fullWidth
            size="small"
            placeholder="Nom modifié"
            name="custom_name"
            value={system && system.custom_name ? system.custom_name : ""}
            onChange={handleCustomNameChange}
            data-test-id="customName"
          />
        </Grid>
        <Grid item xs={3} alignSelf="end" margin="9px">
          <InputLabel label="" text={addableField} />
        </Grid>
      </Grid>

      <SystemModelModal
        projectId={projectId}
        isModalOpen={isSystemModalOpen}
        onClose={onCloseSystemModelModal}
        system={system}
        onUpdate={onUpdate}
        currentModel={currentModel}
        currentAction={currentAction}
        onUpdateModel={onUpdateModel}
        onUpdateAction={onUpdateAction}
        onReset={onReset}
        onCostLinesChange={onCostLinesChange}
        data-test-id="systemModelModal"
        withModel={withModel}
      />

      <Modal
        title="Changer l'uniformat"
        isOpen={isUniformatmodalOpen}
        className={className}
        padding="40px"
        content={
          <div>
            <TreeViewStyle checked={selected} setChecked={setSelected} getData={fetchUniformats} defaultData={fetchUniformats} isSingleCheck />
          </div>
        }
        onClose={onCloseUniformatModal}
        data-test-id="modal"
      />
    </div>
  );
};

export default createStyledComponent(SystemNameSection, systemNameSectionStyle);
