import { AssetMinimalInfo } from "app/domain/asset";
import Asset from "app/domain/asset/asset";
import { AssetService } from "app/service/asset";
import ElementsListAPI from "app/service/ElementsListsAPI";
import { notify } from "app/view/common/toast/Toast";
import { call, put, takeLatest } from "redux-saga/effects";

import { EntityCategory } from "../../domain/entityCategory";
import {
  createAssetErrorAction,
  createAssetRequestAction,
  createAssetSuccessAction,
  getAssetErrorAction,
  getAssetOptionsErrorAction,
  getAssetOptionsRequestAction,
  getAssetOptionsSuccessAction,
  getAssetRequestAction,
  getAssetsErrorAction,
  getAssetsMinimalInfoErrorAction,
  getAssetsMinimalInfoRequestAction,
  getAssetsMinimalInfoSuccessAction,
  getAssetsRequestAction,
  getAssetsSuccessAction,
  getAssetSuccessAction,
  updateAssetErrorAction,
  updateAssetRequestAction,
  updateAssetSuccessAction
} from "./actions";
import {
  AssetActionTypes,
  CreateAssetAction,
  CreateAssetRequestAction,
  GetAssetAction,
  GetAssetRequestAction,
  GetAssetsAction,
  GetAssetsMinimalInfoAction,
  GetAssetsMinimalInfoRequestAction,
  GetAssetsRequestAction,
  UpdateAssetAction,
  UpdateAssetRequestAction
} from "./types";

export const CREATE_ASSET_ERROR = "Une erreur s'est produite lors de la création de l'actif. Veuillez réessayer.";
export const UPDATE_ASSET_ERROR = "Une erreur s'est produite lors de la mise à jour de l'actif. Veuillez réessayer.";
export const GET_ASSET_ERROR = "Une erreur s'est produite lors de la récupération de l'actif. Veuillez réessayer.";
export const GET_ASSETS_ERROR = "Une erreur s'est produite lors de la récupération des actifs. Veuillez réessayer.";
export const GET_ASSET_OPTIONS_ERROR = "Une erreur s'est produite lors de la récupération des listes d'options des actifs. Veuillez réessayer.";
export const GET_ASSETS_MINIMAL_INFO_ERROR = "Une erreur s'est produite lors de la récupération des informations minimales des actifs. Veuillez réessayer.";

export function* getAsset(action: GetAssetAction): Generator {
  yield put(getAssetRequestAction(action.payload.assetId));
}

export function* getAssetRequest(action: GetAssetRequestAction): Generator {
  try {
    const asset = yield call(AssetService.getAsset, action.payload.assetId);
    yield put(getAssetSuccessAction(asset as Asset));
  } catch (error) {
    yield put(getAssetErrorAction(GET_ASSET_ERROR));
  }
}

export function* getAssets(action: GetAssetsAction): Generator {
  yield put(getAssetsRequestAction(action.payload.parentCategory, action.payload.parentId, action.payload.auditStatus));
}

export function* getAssetsRequest(action: GetAssetsRequestAction): Generator {
  try {
    const assets = (yield call(AssetService.getAssets, action.payload.parentCategory, action.payload.parentId, action.payload.auditStatus)) as Asset[];
    yield put(getAssetsSuccessAction(assets));
  } catch (error) {
    yield put(getAssetsErrorAction(GET_ASSETS_ERROR));
  }
}

export function* getAssetOptions(): Generator {
  yield put(getAssetOptionsRequestAction());
}

export function* getAssetOptionsRequest(): Generator {
  try {
    const options = yield call(ElementsListAPI.getAssetElementsLists);
    yield put(getAssetOptionsSuccessAction(options));
  } catch (error) {
    yield put(getAssetOptionsErrorAction(GET_ASSET_OPTIONS_ERROR));
  }
}

export function* createAsset(action: CreateAssetAction): Generator {
  yield put(createAssetRequestAction(action.payload.asset, action.payload.siteId));
}

export function* createAssetRequest(action: CreateAssetRequestAction): Generator {
  try {
    const createdAsset = (yield call(AssetService.createAsset, action.payload.asset)) as Asset;
    const assets = (yield call(AssetService.getAssets, EntityCategory.SITE, action.payload.siteId)) as Asset[];
    yield put(createAssetSuccessAction(createdAsset, assets));
    notify("L'actif a été créé avec succès");
  } catch (error) {
    yield put(createAssetErrorAction(CREATE_ASSET_ERROR));
  }
}

export function* updateAsset(action: UpdateAssetAction): Generator {
  yield put(updateAssetRequestAction(action.payload.asset, action.payload.entityType, action.payload.entityId, action.payload.auditStatus));
}

export function* updateAssetRequest(action: UpdateAssetRequestAction): Generator {
  try {
    const updatedAsset = (yield call(AssetService.updateAsset, action.payload.asset)) as Asset;
    const assets = (yield call(AssetService.getAssets, action.payload.entityType, action.payload.entityId, action.payload.auditStatus)) as Asset[];
    yield put(updateAssetSuccessAction(updatedAsset, assets));
    notify("L'actif a été modifié avec succès");
  } catch (error) {
    yield put(updateAssetErrorAction(UPDATE_ASSET_ERROR));
  }
}

export function* getAssetsMinimalInfo(action: GetAssetsMinimalInfoAction): Generator {
  yield put(getAssetsMinimalInfoRequestAction(action.payload.siteId));
}

export function* getAssetsMinimalInfoRequest(action: GetAssetsMinimalInfoRequestAction): Generator {
  try {
    const assets = yield call(AssetService.getAssetsMinimalInfo, action.payload.siteId);
    yield put(getAssetsMinimalInfoSuccessAction(assets as AssetMinimalInfo[]));
  } catch (error) {
    yield put(getAssetsMinimalInfoErrorAction(GET_ASSETS_MINIMAL_INFO_ERROR));
  }
}

function* assetSaga(): Generator {
  yield takeLatest(AssetActionTypes.GET_ASSET, getAsset);
  yield takeLatest(AssetActionTypes.GET_ASSET_REQUEST, getAssetRequest);
  yield takeLatest(AssetActionTypes.GET_ASSETS, getAssets);
  yield takeLatest(AssetActionTypes.GET_ASSETS_REQUEST, getAssetsRequest);
  yield takeLatest(AssetActionTypes.GET_ASSET_OPTIONS, getAssetOptions);
  yield takeLatest(AssetActionTypes.GET_ASSET_OPTIONS_REQUEST, getAssetOptionsRequest);
  yield takeLatest(AssetActionTypes.CREATE_ASSET, createAsset);
  yield takeLatest(AssetActionTypes.CREATE_ASSET_REQUEST, createAssetRequest);
  yield takeLatest(AssetActionTypes.UPDATE_ASSET, updateAsset);
  yield takeLatest(AssetActionTypes.UPDATE_ASSET_REQUEST, updateAssetRequest);
  yield takeLatest(AssetActionTypes.GET_ASSETS_MINIMAL_INFO, getAssetsMinimalInfo);
  yield takeLatest(AssetActionTypes.GET_ASSETS_MINIMAL_INFO_REQUEST, getAssetsMinimalInfoRequest);
}

export default assetSaga;
