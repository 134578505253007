import React from "react";
import { Switch, Route } from "react-router-dom";
import { ModifyAssetContainer } from "app/view/asset/form";
import { ModifySiteContainer } from "app/view/site/form";
import SideMenuTemplate from "../view/menu/SideMenuTemplate";
import { Redirect } from "react-router";
import LoginPage from "../view/login/LoginPage";
import {
  CreateSystemContainer,
  ModifySystemContainer,
} from "app/view/system/form";
import {
  CreateRequirementContainer,
  ModifyRequirementContainer,
} from "app/view/requirement/form";
import UpdateAccountContainer from "app/view/account/updateAccountContainer";
import { ProjectListing } from "app/view/project/listing";
import ModifyProjectContainer from "app/view/project/form/modifyProjectContainer";
import ModifyRoomContainer from "app/view/room/modifyRoomContainer";
import CommentsTable from "app/view/comment/commentsTable";
import UserQaErrorPage from "app/view/qaError/userQaErrorPage";
import ImportCustomerReference from "app/view/importData/importCustomerReference";

const PrivateRoute = ({ component: Component, propsComponent = {} , ...rest}) => (
  <Route
    {...rest}
    render={(props) =>
      IsAuthenticated() === true ? (
        <Component {...{...props, ...propsComponent}} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

const IsAuthenticated = () => {
  let token = localStorage.getItem("token");
  if (!token) return false;

  let expiration_date = localStorage.getItem("token-exp");
  return expiration_date > Date.now() / 1000;
};

export function Routes({ menuIsOpen, onClose, cable }) {
  return (
    <Switch>
      <Route exact path="/" component={LoginPage} />
      <SideMenuTemplate
        onClose={() => onClose()}
        menuIsOpen={menuIsOpen}
        children={
          <Switch>
            <PrivateRoute exact path="/projects" component={ProjectListing} />
            <PrivateRoute
              exact
              path="/projects/:projectId"
              component={ModifyProjectContainer}
            />
            <PrivateRoute
              exact
              path="/projects/:projectId/sites/:siteId"
              component={ModifySiteContainer}
              propsComponent={{cable}}
            />
            <PrivateRoute
              exact
              path="/projects/:projectId/sites/:siteId/assets/:assetId"
              component={ModifyAssetContainer}
            />
            <PrivateRoute
              exact
              path="/projects/:projectId/sites/:siteId/assets/:assetId/rooms/:roomId"
              component={ModifyRoomContainer}
            />
            <PrivateRoute
              exact
              path="/newcomponent"
              component={CreateSystemContainer}
            />
            <PrivateRoute
              exact
              path="/projects/:projectId/sites/:siteId/assets/:assetId/components/:systemId"
              component={ModifySystemContainer}
            />
            <PrivateRoute
              exact
              path="/newrequirement"
              component={CreateRequirementContainer}
            />
            <PrivateRoute
              exact
              path="/projects/:projectId/sites/:siteId/assets/:assetId/requirements/:requirementId"
              component={ModifyRequirementContainer}
            />
            <PrivateRoute
              exact
              path="/account"
              component={UpdateAccountContainer}
            />
            <PrivateRoute
              exact
              path="/comments"
              component={CommentsTable}
            />
            <PrivateRoute
              exact
              path="/userQaErrors"
              component={UserQaErrorPage}
              propsComponent={{cable}}
            />
            <PrivateRoute
              exact
              path="/importCustomerReference"
              component={ImportCustomerReference}
              propsComponent={{cable}}
            />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        }
      />
    </Switch>
  );
}
