import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import compare from "react-fast-compare";
import { css, SimpleInterpolation } from "styled-components";
import { IconButton } from "ui/button";
import { colors } from "ui/colors";
import { Icon, IconType } from "ui/icon";

const filterPanelButtonStyle = css`
  &&&&& ${Icon as unknown as SimpleInterpolation} {
    height: 100%;
    width: 100%;

    i {
      font-size: 1em;
    }
  }
`;

interface FilterPanelButtonProps extends ComponentProps {
  isActive: boolean;
  onClick: () => void;
}

const isEqual = (prevProps: FilterPanelButtonProps, nextProps: FilterPanelButtonProps): boolean => {
  const isActiveEqual = compare(prevProps.isActive, nextProps.isActive);
  const isOnClickEqual = compare(prevProps.onClick.toString(), nextProps.onClick.toString());

  return isActiveEqual && isOnClickEqual;
};

const FilterPanelButton: FC<FilterPanelButtonProps> = ({ isActive, onClick, className }: FilterPanelButtonProps) => (
  <IconButton onClick={onClick} className={className} data-test-id="iconButton" color={isActive ? colors.ACCENT_BLUE_DARK : "#9d9e9e"}>
    <Icon type={IconType.FILTER} data-test-id="icon" />
  </IconButton>
);

export default createStyledComponent(memo(FilterPanelButton, isEqual), filterPanelButtonStyle);
