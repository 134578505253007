import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import compare from "react-fast-compare";
import { css } from "styled-components";
import { TableHeaderColumn } from "ui/table/types";

const getFiltersWidth = (canFilter: boolean, canSort: boolean): string => {
  if (canFilter && canSort) {
    return "50px";
  }
  if (canFilter || canSort) {
    return "25px";
  }
  return "0px";
};

const titleStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props: StyleProps): string => (props.column.getHeaderProps().key === "header_selection" ? "100%" : `calc(100% - ${getFiltersWidth(props.filter, props.sort)})`)};
`;

interface TitleProps extends ComponentProps {
  sort: boolean;
  filter: boolean;
  column: TableHeaderColumn;
}

const isEqual = (prevProps: TitleProps, nextProps: TitleProps): boolean => {
  const isSortEqual = compare(prevProps.sort, nextProps.sort);
  const isFilterEqual = compare(prevProps.filter, nextProps.filter);
  const isHeaderEqual = compare((prevProps.column as any).Header, (nextProps.column as any).Header);

  return isSortEqual && isFilterEqual && isHeaderEqual;
};

const Title: FC<TitleProps> = ({ column, className }: TitleProps) => <div className={className}>{column.render("Header")}</div>;

export default createStyledComponent(memo(Title, isEqual), titleStyle);
