import { createAction } from "@reduxjs/toolkit";
import { Requirement } from "app/domain/requirement";
import { RequirementItemOption, RequirementsOptions } from "app/domain/requirement/options";
import RequirementProblematicOption from "app/domain/requirement/options/requirementProblematicOption";

import { RequirementActionTypes } from "./types";

export const getRequirementAction = createAction(RequirementActionTypes.GET_REQUIREMENT, (requirementId: string) => ({
  payload: {
    requirementId
  }
}));

export const getRequirementRequestAction = createAction(RequirementActionTypes.GET_REQUIREMENT_REQUEST, (requirementId: string) => ({
  payload: {
    requirementId
  }
}));

export const getRequirementSuccessAction = createAction(RequirementActionTypes.GET_REQUIREMENT_SUCCESS, (requirement: Requirement) => ({
  payload: {
    requirement
  }
}));

export const getRequirementErrorAction = createAction(RequirementActionTypes.GET_REQUIREMENT_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const getRequirementsAction = createAction(RequirementActionTypes.GET_REQUIREMENTS, (assetId: string) => ({
  payload: {
    assetId
  }
}));

export const getRequirementsRequestAction = createAction(RequirementActionTypes.GET_REQUIREMENTS_REQUEST, (assetId: string) => ({
  payload: {
    assetId
  }
}));

export const getRequirementsSuccessAction = createAction(RequirementActionTypes.GET_REQUIREMENTS_SUCCESS, (requirements: Requirement[]) => ({
  payload: {
    requirements
  }
}));

export const getRequirementsErrorAction = createAction(RequirementActionTypes.GET_REQUIREMENTS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const getRequirementOptionsAction = createAction(RequirementActionTypes.GET_REQUIREMENT_OPTIONS);

export const getRequirementOptionsRequestAction = createAction(RequirementActionTypes.GET_REQUIREMENT_OPTIONS_REQUEST);

export const getRequirementOptionsSuccessAction = createAction(RequirementActionTypes.GET_REQUIREMENT_OPTIONS_SUCCESS, (options: RequirementsOptions) => ({
  payload: {
    options
  }
}));

export const getRequirementOptionsErrorAction = createAction(RequirementActionTypes.GET_REQUIREMENT_OPTIONS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const addRequirementAction = createAction(RequirementActionTypes.ADD_REQUIREMENT, (requirement: Requirement) => ({
  payload: {
    requirement
  }
}));

export const addRequirementRequestAction = createAction(RequirementActionTypes.ADD_REQUIREMENT_REQUEST, (requirement: Requirement) => ({
  payload: {
    requirement
  }
}));

export const addRequirementSuccessAction = createAction(RequirementActionTypes.ADD_REQUIREMENT_SUCCESS, (requirement: Requirement) => ({
  payload: {
    requirement
  }
}));

export const addRequirementErrorAction = createAction(RequirementActionTypes.ADD_REQUIREMENT_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const updateRequirementAction = createAction(RequirementActionTypes.UPDATE_REQUIREMENT, (requirement: Requirement) => ({
  payload: {
    requirement
  }
}));

export const updateRequirementRequestAction = createAction(RequirementActionTypes.UPDATE_REQUIREMENT_REQUEST, (requirement: Requirement) => ({
  payload: {
    requirement
  }
}));

export const updateRequirementSuccessAction = createAction(RequirementActionTypes.UPDATE_REQUIREMENT_SUCCESS, (requirement: Requirement) => ({
  payload: {
    requirement
  }
}));

export const updateRequirementErrorAction = createAction(RequirementActionTypes.UPDATE_REQUIREMENT_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const deleteRequirementAction = createAction(RequirementActionTypes.DELETE_REQUIREMENT, (requirementId: string) => ({
  payload: {
    requirementId
  }
}));

export const deleteRequirementRequestAction = createAction(RequirementActionTypes.DELETE_REQUIREMENT_REQUEST, (requirementId: string) => ({
  payload: {
    requirementId
  }
}));

export const deleteRequirementSuccessAction = createAction(RequirementActionTypes.DELETE_REQUIREMENT_SUCCESS, (requirementId: string) => ({
  payload: {
    requirementId
  }
}));

export const deleteRequirementErrorAction = createAction(RequirementActionTypes.DELETE_REQUIREMENT_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const generateRequirementCopiesAction = createAction(
  RequirementActionTypes.GENERATE_REQUIREMENT_COPIES,
  (requirementId: string, requirementCopies: Requirement[], parentId: string) => ({
    payload: {
      requirementId,
      requirementCopies,
      parentId
    }
  })
);

export const generateRequirementCopiesRequestAction = createAction(
  RequirementActionTypes.GENERATE_REQUIREMENT_COPIES_REQUEST,
  (requirementId: string, requirementCopies: Requirement[], parentId: string) => ({
    payload: {
      requirementId,
      requirementCopies,
      parentId
    }
  })
);

export const generateRequirementCopiesSuccessAction = createAction(
  RequirementActionTypes.GENERATE_REQUIREMENT_COPIES_SUCCESS,
  (requirementCopies: Requirement[], parentId: string) => ({
    payload: {
      requirementCopies,
      parentId
    }
  })
);

export const generateRequirementCopiesErrorAction = createAction(RequirementActionTypes.GENERATE_REQUIREMENT_COPIES_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const addRequirementOptionAction = createAction(RequirementActionTypes.ADD_REQUIREMENT_OPTION, (option: RequirementItemOption | RequirementProblematicOption) => ({
  payload: {
    option
  }
}));

export const addRequirementOptionRequestAction = createAction(
  RequirementActionTypes.ADD_REQUIREMENT_OPTION_REQUEST,
  (option: RequirementItemOption | RequirementProblematicOption) => ({
    payload: {
      option
    }
  })
);

export const addRequirementOptionSuccessAction = createAction(
  RequirementActionTypes.ADD_REQUIREMENT_OPTION_SUCCESS,
  (option: RequirementItemOption | RequirementProblematicOption) => ({
    payload: {
      option
    }
  })
);

export const addRequirementOptionErrorAction = createAction(RequirementActionTypes.ADD_REQUIREMENT_OPTION_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const setFilteredRequirementsAction = createAction(RequirementActionTypes.SET_FILTERED_REQUIREMENTS, (requirementIds: string[]) => ({
  payload: {
    requirementIds
  }
}));

export const resetAction = createAction(RequirementActionTypes.RESET_REQUIREMENT);
