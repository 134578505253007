import { Breadcrumbs as MaterialBreadcrumbs } from "@mui/material";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

const breadcrumbsStyle = css`
  && {
    margin: auto 0;
    padding: 10px 0;
  }
`;

interface BreadcrumbsProps extends ComponentProps {
  children: JSX.Element[];
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ children, className }: BreadcrumbsProps) => {
  return (
    <MaterialBreadcrumbs aria-label="breadcrumb" className={className}>
      {children}
    </MaterialBreadcrumbs>
  );
};

export default createStyledComponent(Breadcrumbs, breadcrumbsStyle);
