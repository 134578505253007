import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

const dropdownModelStyle = css`
  .ui.selection.dropdown {
    min-width: 250px;
  }
`;

interface DropdownModelProps extends ComponentProps {
  children: any;
}

const DropdownModel: FC<DropdownModelProps> = ({ children, className }: DropdownModelProps) => <div className={className}>{children}</div>;

export default createStyledComponent(DropdownModel, dropdownModelStyle);
