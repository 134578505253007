import { Filter } from "app/domain/filter";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { css } from "styled-components";
import { Badge } from "ui/badge";
import { Button } from "ui/button";

const filtersContainerStyle = css`
  &&& {
    display: flex;

    .resetButton {
      max-height: 32px;
      font-size: 0.8rem;
      padding: 0.833em;
      margin-right: 8px;

      p {
        line-height: 1;
        font-size: 0.85714286rem;
      }
    }

    .filterBadgesContainer {
      display: flex;

      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
`;

interface FiltersContainerProps extends ComponentProps {
  filters: Filter[];
  onDelete: (filterLabel: string) => void;
  onReset: () => void;
}

const FiltersContainer: FC<FiltersContainerProps> = ({ filters, onDelete, onReset, className }: FiltersContainerProps) => (
  <div className={className}>
    {filters.length > 0 && <Button color="red" title="Réinitialiser les filtres" onClick={onReset} className="resetButton" data-test-id="resetButton" />}
    <div className="filterBadgesContainer" data-test-id="filterBadgesContainer">
      {filters.map((filter: Filter) => (
        <Badge label={filter.label} onDelete={(): void => onDelete(filter.label)} key={filter.label} />
      ))}
    </div>
  </div>
);

export default createStyledComponent(memo(FiltersContainer), filtersContainerStyle);
