import { CostItem } from "app/domain/costItem";

import { Action, PayloadAction } from "../types";

export enum CostItemActionTypes {
  GET_COST_ITEMS = "GET_COST_ITEMS",
  GET_COST_ITEMS_REQUEST = "GET_COST_ITEMS_REQUEST",
  GET_COST_ITEMS_SUCCESS = "GET_COST_ITEMS_SUCCESS",
  GET_COST_ITEMS_ERROR = "GET_COST_ITEMS_ERROR",
  RESET_COST_ITEM = "RESET_COST_ITEM"
}

export type GetCostItemsAction = Action<CostItemActionTypes.GET_COST_ITEMS>;

export type GetCostItemsRequestAction = Action<CostItemActionTypes.GET_COST_ITEMS_REQUEST>;

export type GetCostItemsSuccessAction = PayloadAction<
  CostItemActionTypes.GET_COST_ITEMS_SUCCESS,
  {
    costItems: CostItem[];
  }
>;

export type GetCostItemsErrorAction = PayloadAction<CostItemActionTypes.GET_COST_ITEMS_ERROR, { errorMessage: string }>;

export type ResetAction = Action<CostItemActionTypes.RESET_COST_ITEM>;
