/* eslint-disable react-hooks/exhaustive-deps */

import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { DatePicker } from "antd";
import { System } from "app/domain/system/index";
import { useI18n } from "app/locales";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import moment from "moment";
import React, { FC, useEffect, useRef } from "react";
import { Button, Form } from "semantic-ui-react";
import styled, { css } from "styled-components";
import Checkbox from "ui/form/controls/checkbox/checkbox";
import { TextField, useTextField } from "ui/form/controls/textField";

import matrix from "../../matrixConditionGrading.json";

const StyledFormControl = styled(FormControl)`
  max-width: 320px;
`;

const conditionGradingSectionStyle = css`
  .installationYearSection {
    padding: 10px 0;
    display: flex;
  }

  .isInstallationYearCheckbox {
    padding: 0 15px;
  }

  .column {
    float: left;
    width: 33.33%;
    padding-right: 10px;
  }

  .row::after {
    content: "";
    display: table;
    clear: both;
  }

  .buttonGroupLabel {
    margin-right: 15px;
  }

  .buttonGroup {
    margin-top: 15px;
  }
`;

interface ConditionGradingSectionProps extends ComponentProps {
  system: System | undefined;
  onUpdate: (fields: any) => void;
  systemWithoutModel?: boolean;
}

type InstallationYear = number | moment.Moment | undefined;

const ConditionGradingSection: FC<ConditionGradingSectionProps> = ({ system, onUpdate, className, systemWithoutModel }: ConditionGradingSectionProps) => {
  const { getAttributeLabel } = useI18n();

  const { text: lifetime, onInput: onLifetimeChange } = useTextField(system && system.lifetime !== undefined && system.lifetime !== null ? system.lifetime.toString() : "");

  const estimateResidualLife = (systemUsefulLife: number, installationYear: number): number => {
    const date = new Date();
    return systemUsefulLife - (date.getFullYear() - installationYear);
  };

  const onChange = (event: any): void => {
    event.preventDefault();
    onUpdate({
      [event.target.name]: event.target.value
    });
  };

  const findInMatrixByResidualLife = (installationYear: number, usefulLifeDuration: number): any => {
    const matrixJs = matrix.matrix;
    const date = new Date();
    const age = date.getFullYear() - installationYear;
    return matrixJs.find((x) => age >= x.age_min && age <= x.age_max && x.useful_life_duration === usefulLifeDuration);
  };

  const findInMatrixByConditionGrading = (installationYear: number, usefulLifeDuration: number, conditionGrading: string): any => {
    const matrixJs = matrix.matrix;
    const date = new Date();
    const age = date.getFullYear() - installationYear;
    return matrixJs.find((x) => age >= x.age_min && age <= x.age_max && x.useful_life_duration === usefulLifeDuration && x.conditionGrading === conditionGrading);
  };

  const findInMatrixByAgeCategory = (usefulLifeDuration: number, ageCategory: string): any => {
    const matrixJs = matrix.matrix;
    return matrixJs.find((x) => {
      return x.useful_life_duration === usefulLifeDuration && x.age_category === ageCategory;
    });
  };

  const onAgeCategoryChange = (ageCategory: string): void => {
    if (system && system.isAutoUsefulLifeDisabled) {
      onUpdate({ ageRange: ageCategory });
    } else {
      if (system && system.usefulLife && ["recent", "middle", "aged"].indexOf(ageCategory) !== -1) {
        const matrixEntry = findInMatrixByAgeCategory(system.usefulLife, ageCategory);
        if (matrixEntry) {
          const age = matrixEntry.age_max !== 99999 ? Math.round((matrixEntry.age_min + matrixEntry.age_max) / 2) : matrixEntry.age_min;
          const currentYear = new Date().getFullYear();
          const estimated_inst_year = currentYear - age;
          const calculated_lifetime = estimateResidualLife(system.usefulLife, estimated_inst_year);
          onUpdate({
            installationYear: estimated_inst_year,
            lifetime: calculated_lifetime
          });
        }
      }
      onUpdate({
        is_installation_year_estimated: true,
        ageRange: ageCategory,
        conditionGrading: "",
        conditionGradingAfterRepair: "",
        estimated_lifetime: null
      });
    }
  };

  const onConditionGradingChange = (conditionGrading: string): void => {
    if (system && system.isAutoUsefulLifeDisabled) {
      onUpdate({
        conditionGrading
      });
    }
    if (system && system.installationYear && system.usefulLife && ["A", "B", "C", "D", "E"].indexOf(conditionGrading) !== -1 && !system.isAutoUsefulLifeDisabled) {
      const matrixEntry = findInMatrixByConditionGrading(system.installationYear, system.usefulLife, conditionGrading);
      if (matrixEntry) {
        onUpdate({
          conditionGrading,
          estimated_lifetime: system.conditionGradingAfterRepair ? system.estimated_lifetime : matrixEntry.calculated_residual_life
        });
      }
    }
  };

  const onConditionGradingAfterRepairChange = (conditionGradingAfterRepair: string): void => {
    if (system && system.isAutoUsefulLifeDisabled) {
      onUpdate({
        conditionGradingAfterRepair
      });
    }
    if (system && system.installationYear && system.usefulLife && ["A", "B", "C", "D", "E"].indexOf(conditionGradingAfterRepair) !== -1 && !system.isAutoUsefulLifeDisabled) {
      const matrixEntry = findInMatrixByConditionGrading(system.installationYear, system.usefulLife, conditionGradingAfterRepair);
      if (matrixEntry) {
        onUpdate({
          estimated_lifetime: matrixEntry.calculated_residual_life,
          conditionGradingAfterRepair
        });
      }
    } else if (system && system.installationYear && system.usefulLife && system.conditionGrading && !conditionGradingAfterRepair && !system.isAutoUsefulLifeDisabled) {
      const updatedMatrixEntry = findInMatrixByConditionGrading(system.installationYear, system.usefulLife, system.conditionGrading);
      if (updatedMatrixEntry) {
        onUpdate({
          estimated_lifetime: updatedMatrixEntry.calculated_residual_life
        });
      }
      onUpdate({
        conditionGradingAfterRepair: ""
      });
    }
  };

  const findConditionGradingByLifetime = (updatedUsefulLife: number, ageCategory: string, estimatedLifetime: number): string => {
    const matrixJs = matrix.matrix;
    const result = matrixJs.find(
      (x) => x.useful_life_duration === updatedUsefulLife && x.age_category === ageCategory && x.margins[0] <= estimatedLifetime && x.margins[1] >= estimatedLifetime
    );
    return result ? result.conditionGrading : "";
  };

  const onEstimatedLifetimeChange = (event: any): void => {
    if (system && !system.isAutoUsefulLifeDisabled) {
      let foundConditionGrading = "";
      if (system && system.usefulLife && system.ageRange) {
        foundConditionGrading = findConditionGradingByLifetime(system.usefulLife, system.ageRange, event.target.value);
      }
      if (system && system.conditionGradingAfterRepair) {
        onUpdate({
          conditionGradingAfterRepair: foundConditionGrading
        });
      } else {
        onUpdate({
          conditionGrading: foundConditionGrading
        });
      }
    }
  };

  const onInstallationYearChange = (event: any): void => {
    event.preventDefault();
    if (system && !system.isAutoUsefulLifeDisabled) {
      let residualLife = null;
      if (system && system.usefulLife && event.target.value) {
        residualLife = estimateResidualLife(system.usefulLife, parseInt(event.target.value, 10));
        const matrixEntry = findInMatrixByResidualLife(parseInt(event.target.value, 10), system.usefulLife);
        if (matrixEntry) {
          onUpdate({
            ageRange: matrixEntry.age_category
          });
        }
        onUpdate({
          lifetime: residualLife,
          conditionGrading: "",
          conditionGradingAfterRepair: "",
          is_installation_year_estimated: false,
          estimated_lifetime: null
        });
      }
    }
  };

  const onUsefulLifeChange = (event: SelectChangeEvent): void => {
    event.preventDefault();
    onUpdate({
      usefulLife: parseInt(event.target.value, 10)
    });
    if (system && !system.isAutoUsefulLifeDisabled) {
      if (system && event.target.value && system.installationYear && system.conditionGrading) {
        const matrixEntry = findInMatrixByConditionGrading(system.installationYear, parseInt(event.target.value, 10), system.conditionGrading);
        if (matrixEntry) {
          onUpdate({
            lifetime: estimateResidualLife(parseInt(event.target.value, 10), system.installationYear),
            estimated_lifetime: system.conditionGradingAfterRepair ? system.estimated_lifetime : matrixEntry.calculated_residual_life
          });
        }
      } else if (system && system.installationYear && event.target.value) {
        const residualLife = estimateResidualLife(parseInt(event.target.value, 10), system.installationYear);
        const matrixEntry = findInMatrixByResidualLife(system.installationYear, parseInt(event.target.value, 10));
        if (matrixEntry) {
          onUpdate({
            ageRange: matrixEntry.age_category,
            lifetime: residualLife
          });
        }
      }
    }
  };
  const onInstallationChange = (e: any): void => {
    if (!e) return;
    onUpdate({
      installationYear: e.year()
    });
  };

  const checkInstallationYearEstimated = (e: any): void => {
    onUpdate({
      is_installation_year_estimated: e.currentTarget.checked
    });
  };

  const checkIsAutoUsefulLifeDisabled = (e: any): void => {
    onUpdate({
      isAutoUsefulLifeDisabled: e.currentTarget.checked
    });
  };

  useEffect(() => {
    if (system && !system.conditionGrading && !system.ageRange && system.usefulLife && system.installationYear) {
      const residualLife = estimateResidualLife(system.usefulLife, system.installationYear);
      const matrixEntry = findInMatrixByResidualLife(system.installationYear, system.usefulLife);
      if (matrixEntry) {
        onUpdate({
          ageRange: matrixEntry.age_category,
          lifetime: residualLife
        });
      }
    }
    // Changing date type to be in accordance with the Form.Input 'onSystemChange'
    if (system && system.installationYear && typeof system.installationYear === "string") {
      onUpdate({ installationYear: moment(system.installationYear).year() });
    }
  }, [system]);

  const getInstallationYear = (): InstallationYear => {
    // Changing date type to be in accordance with the Form.Input 'onRerender'
    if (!system) return undefined;
    if (typeof system.installationYear === "string") {
      return moment(system.installationYear);
    }
    return system.installationYear ? moment().set("year", system.installationYear) : undefined;
  };

  return (
    <div className={className}>
      <Checkbox
        className="isAutoUsefulLifeDisabled"
        onClick={checkIsAutoUsefulLifeDisabled}
        name="isAutoUsefulLifeDisabled"
        isChecked={system && system.isAutoUsefulLifeDisabled}
        label="Désactiver la matrice de cote d'état et les calculs automatiques"
      />
      <div className="installationYearSection">
        <Form.Field
          required
          name="installationYear"
          label={getAttributeLabel("installation_year")}
          control={DatePicker.YearPicker}
          value={getInstallationYear()}
          onChange={onInstallationChange}
          placeholder={getAttributeLabel("installation_year")}
          onBlur={onInstallationYearChange}
        />
        <Checkbox
          className="isInstallationYearCheckbox"
          onClick={checkInstallationYearEstimated}
          name="is_installation_year_estimated"
          isChecked={system && system.is_installation_year_estimated}
          label="L'année d'installation est estimée?"
        />
      </div>
      <div className="row">
        <div className="column">
          <StyledFormControl fullWidth>
            <InputLabel id="dvu-label">Durée de vie utile</InputLabel>
            <Select
              disabled={!systemWithoutModel && !(system?.model_number === "Custom")}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={system && system.usefulLife ? system.usefulLife.toString() : ""}
              label={getAttributeLabel("useful_life")}
              onChange={onUsefulLifeChange}
            >
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="15">15</MenuItem>
              <MenuItem value="20">20</MenuItem>
              <MenuItem value="25">25</MenuItem>
              <MenuItem value="30">30</MenuItem>
              <MenuItem value="35">35</MenuItem>
              <MenuItem value="40">40</MenuItem>
              <MenuItem value="45">45</MenuItem>
              <MenuItem value="50">50</MenuItem>
              <MenuItem value="55">55</MenuItem>
              <MenuItem value="60">60</MenuItem>
              <MenuItem value="65">65</MenuItem>
              <MenuItem value="70">70</MenuItem>
              <MenuItem value="75">75</MenuItem>
            </Select>
          </StyledFormControl>
        </div>
        <div className="column">
          <TextField label={getAttributeLabel("lifetime")} hasBorder isNotBold isDisabled readOnly text={lifetime} number onInput={onLifetimeChange} data-test-id="lifetime" />
        </div>
        <div className="column">
          <Form.Input
            fluid
            required
            label={getAttributeLabel("estimated_lifetime")}
            name="estimated_lifetime"
            value={system && system.estimated_lifetime !== undefined && system.estimated_lifetime !== null ? system.estimated_lifetime : ""}
            type="number"
            onChange={onChange}
            onBlur={onEstimatedLifetimeChange}
          />
        </div>
      </div>
      <div className="buttonGroup">
        {!system || !system.usefulLife ? <p className="warning">Remplir durée de vie utile pour pouvoir choisir la catégorie d&apos;âge</p> : null}
        <div className="required buttonGroupLabel">{getAttributeLabel("age_range")}</div>
        <Button.Group id="age_range">
          <Button
            disabled={!system || !system.usefulLife}
            name="recent_btn"
            inverted
            onClick={(): void => onAgeCategoryChange("recent")}
            color="green"
            active={system && system.ageRange === "recent"}
          >
            Récent
          </Button>
          <Button
            disabled={!system || !system.usefulLife}
            name="middle_btn"
            inverted
            onClick={(): void => onAgeCategoryChange("middle")}
            color="orange"
            active={system && system.ageRange === "middle"}
          >
            Milieu de vie
          </Button>
          <Button
            disabled={!system || !system.usefulLife}
            name="old_btn"
            inverted
            onClick={(): void => onAgeCategoryChange("aged")}
            color="red"
            active={system && system.ageRange === "aged"}
          >
            Âgé
          </Button>
        </Button.Group>
      </div>

      <div className="buttonGroup">
        {!system || !system.ageRange ? <p className="warning">Remplir la catégorie d&apos;âge pour pouvoir choisir la cote d&apos;état</p> : null}
        <div className="required buttonGroupLabel">{getAttributeLabel("condition_grading")}</div>
        <Button.Group id="coteEtat">
          <Button
            disabled={!system || !system.ageRange}
            name="state_A_btn"
            active={system && system.conditionGrading === "A"}
            onClick={(): void => onConditionGradingChange("A")}
            inverted
            color="green"
          >
            A
          </Button>
          <Button
            disabled={!system || !system.ageRange}
            name="state_B_btn"
            active={system && system.conditionGrading === "B"}
            onClick={(): void => onConditionGradingChange("B")}
            inverted
            color="olive"
          >
            B
          </Button>
          <Button
            disabled={!system || !system.ageRange}
            name="state_C_btn"
            active={system && system.conditionGrading === "C"}
            onClick={(): void => onConditionGradingChange("C")}
            inverted
            color="yellow"
          >
            C
          </Button>
          <Button
            disabled={!system || !system.ageRange}
            name="state_D_btn"
            active={system && system.conditionGrading === "D"}
            onClick={(): void => onConditionGradingChange("D")}
            inverted
            color="orange"
          >
            D
          </Button>
          <Button
            disabled={!system || !system.ageRange}
            name="state_E_btn"
            active={system && system.conditionGrading === "E"}
            onClick={(): void => onConditionGradingChange("E")}
            inverted
            color="red"
          >
            E
          </Button>
        </Button.Group>
      </div>

      <div className="buttonGroup">
        <div className="buttonGroupLabel">{getAttributeLabel("condition_grading_after_repair")}</div>
        <Button.Group id="afterRepairConditionGrading">
          <Button
            disabled={!system || !system.ageRange}
            name="state_after_A_btn"
            active={system && system.conditionGradingAfterRepair === "A"}
            onClick={(): void => onConditionGradingAfterRepairChange("A")}
            inverted
            color="green"
          >
            A
          </Button>
          <Button
            disabled={!system || !system.ageRange}
            name="state_after_B_btn"
            active={system && system.conditionGradingAfterRepair === "B"}
            onClick={(): void => onConditionGradingAfterRepairChange("B")}
            inverted
            color="olive"
          >
            B
          </Button>
          <Button
            disabled={!system || !system.ageRange}
            name="state_after_C_btn"
            active={system && system.conditionGradingAfterRepair === "C"}
            onClick={(): void => onConditionGradingAfterRepairChange("C")}
            inverted
            color="yellow"
          >
            C
          </Button>
          <Button
            disabled={!system || !system.ageRange}
            name="state_after_D_btn"
            active={system && system.conditionGradingAfterRepair === "D"}
            onClick={(): void => onConditionGradingAfterRepairChange("D")}
            inverted
            color="orange"
          >
            D
          </Button>
          <Button
            disabled={!system || !system.ageRange}
            name="state_after_E_btn"
            active={system && system.conditionGradingAfterRepair === "E"}
            onClick={(): void => onConditionGradingAfterRepairChange("E")}
            inverted
            color="red"
          >
            E
          </Button>
        </Button.Group>
        {system && system.conditionGradingAfterRepair ? (
          <Button name="erase_state_after" onClick={(): void => onConditionGradingAfterRepairChange("")} color="red">
            X
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default createStyledComponent(ConditionGradingSection, conditionGradingSectionStyle);
