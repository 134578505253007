import { Filter } from "app/domain/filter";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { HeaderGroup } from "react-table";
import { css } from "styled-components";
import { RowData } from "ui/table/types";

import { TableRowSelectionProps } from "../../useTable";
import RowSelectionButton from "../rowSelectionButton";
import TableHeaderRowCell from "./tableHeaderRowCell";

const tableHeaderRowCellStyle = css`
  &&&&& {
    background: #f9fafb;
  }
`;

interface TableHeaderRowProps extends ComponentProps {
  headerGroup: HeaderGroup;
  rows: RowData[];
  filters?: Filter[];
  onFilter?: (filter: Filter) => void;
  rowSelectionProps?: TableRowSelectionProps;
}

const TableHeaderRow: FC<TableHeaderRowProps> = ({ headerGroup, rows, filters, onFilter, rowSelectionProps, className }: TableHeaderRowProps) => (
  <div className="row" data-test-id="row">
    {rowSelectionProps && (
      <div className={`${className} cell`}>
        <RowSelectionButton isRowSelected={rowSelectionProps.isChecked} onRowSelect={rowSelectionProps.onSelectAll} indeterminate={rowSelectionProps.isIndeterminate} />
      </div>
    )}
    {headerGroup.headers.map((column: any) => (
      <TableHeaderRowCell column={column} filters={filters} rows={rows} onFilter={onFilter} key={column.getHeaderProps().key} />
    ))}
  </div>
);

export default createStyledComponent(TableHeaderRow, tableHeaderRowCellStyle);
