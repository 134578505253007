/* eslint-disable array-callback-return, consistent-return, no-plusplus  */

import { AuditType } from "app/domain/audit/auditType";
import { State as SystemState } from "app/domain/common";
import { EntityCategory } from "app/domain/entityCategory";
import { Filter, FilterType } from "app/domain/filter";
import { useI18n } from "app/locales";
import { useRouter } from "app/routing/routerProvider";
import { setFiltersAction } from "app/store/filter/actions";
import { getFilters } from "app/store/filter/selectors";
import { State } from "app/store/state";
import { setFilteredSystemsAction } from "app/store/system/actions";
import { moneyFormatter } from "app/utils/formatter";
import usePrevious from "app/view/common/hook/usePrevious";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import isEqual from "react-fast-compare";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { useOpenNewTab } from "ui/hook";
import { ColumnAlignment, Table, TableRowSelectionProps } from "ui/table";
import useFilters from "ui/table/quickFilterPanel/useFilters";
import { Column, RowData } from "ui/table/types";

const systemTableStyle = css`
  && {
    @media screen and (max-width: 1200px) {
      font-size: 0.9em;
    }
  }
`;

interface SystemTableProps extends ComponentProps {
  projectId: string;
  siteId: string;
  assetId: string;
  auditType: AuditType;
  parentType: EntityCategory;
  onDataChange: (rowData: RowData[]) => void;
  currentData: RowData[];
  data: RowData[];
  rowSelectionProps: TableRowSelectionProps;
  roomId?: string;
}

const SystemTable: FC<SystemTableProps> = ({
  projectId,
  siteId,
  assetId,
  parentType,
  roomId,
  auditType,
  onDataChange,
  currentData,
  rowSelectionProps,
  data,
  className
}: SystemTableProps) => {
  const { dictionaryCode, getAttributeLabel } = useI18n();
  const { goTo } = useRouter();
  const { newTab } = useOpenNewTab();

  const getFiltersTableName = useCallback((): string => {
    let tableName = "";
    if (parentType === EntityCategory.ROOM) {
      tableName = auditType === AuditType.MECHANICAL ? "system.mechanical" : "system.architectural";
    } else {
      tableName = auditType === AuditType.MECHANICAL ? "roomSystem.mechanical" : "roomSystem.architectural";
    }
    return tableName;
  }, [parentType, auditType]);

  const filters = useSelector((state: State): Filter[] => getFilters(state, getFiltersTableName()));
  const previousFilters = usePrevious(filters);

  const { onChange: onChangeFilter, onToggle: onToggleFilter, onDelete: onDeleteFilter, onReset: onResetFilters, filters: currentFilters } = useFilters(filters);

  const filteredSystems = useMemo(() => currentData.map((rowData: RowData) => rowData.id), [currentData]) as string[];
  const previousFilteredSystems = usePrevious(filteredSystems);

  const systemsTotalCost = useMemo(() => {
    let totalCost = 0;

    for (let index = 0; index < currentData.length; index += 1) {
      totalCost += currentData[index].totalCost.accessor;
    }
    return totalCost;
  }, [currentData]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEqual(previousFilteredSystems, filteredSystems)) {
      dispatch(setFilteredSystemsAction(filteredSystems));
    }

    if (!isEqual(previousFilters, currentFilters)) {
      dispatch(setFiltersAction(getFiltersTableName(), currentFilters));
    }
  }, [dispatch, auditType, previousFilters, currentFilters, currentData, previousFilteredSystems, filteredSystems, getFiltersTableName]);

  const handleRowClick = useCallback(
    (clickedRowData: any): void => {
      const path = `/projects/${projectId}/sites/${siteId}/assets/${assetId}/components/${clickedRowData.id}`;
      goTo(
        path,
        {
          auditType,
          parentType,
          parentId: parentType === EntityCategory.ROOM ? roomId : ""
        },
        newTab
      );
    },
    [goTo, auditType, projectId, siteId, assetId, roomId, parentType, newTab]
  );

  const columns = useMemo(
    () => [
      {
        Header: getAttributeLabel("reference"),
        Footer: "Nombre de systèmes:",
        field: "reference",
        sort: false,
        select: false,
        onClick: handleRowClick,
        style: {
          width: 1.3
        }
      },
      {
        Header: "État",
        Footer: currentData.length,
        field: "state"
      },
      {
        Header: getAttributeLabel("name"),
        Footer: "",
        field: "name",
        style: {
          width: 2.5
        }
      },
      {
        Header: "Durée de vie", // TODO: create a "short" label type for estimated_lifetime
        Footer: "",
        field: "lifespan"
      },
      {
        Header: "Emplacement",
        Footer: "",
        field: "location"
      },
      {
        Header: "Uniformat",
        Footer: "",
        field: "uniformat",
        style: {
          alignment: ColumnAlignment.CENTER
        }
      },
      {
        Header: getAttributeLabel("quantity"),
        Footer: "Total:",
        field: "quantity",
        style: {
          alignment: ColumnAlignment.CENTER
        }
      },
      {
        Header: getAttributeLabel("total_cost"),
        Footer: moneyFormatter.format(systemsTotalCost),
        field: "totalCost",
        style: {
          alignment: ColumnAlignment.RIGHT
        }
      },
      {
        Header: "Photos", // TODO: create a "short" label type for number_of_photos
        Footer: "",
        field: "numberOfPhotos",
        sort: false,
        select: true,
        style: {
          alignment: ColumnAlignment.CENTER,
          width: 0.8
        }
      },
      auditType === AuditType.MECHANICAL && {
        Header: "Code QR",
        Footer: "",
        field: "qrCode",
        style: {
          alignment: ColumnAlignment.CENTER
        }
      },
      {
        Header: "Date",
        Footer: "",
        field: "date",
        select: false,
        style: {
          width: 0.8
        }
      },
      {
        Header: "À vérifier",
        Footer: "",
        field: "toVerify",
        hidden: true
      },
      {
        Header: "Année installation estimée",
        Footer: "",
        field: "is_installation_year_estimated",
        hidden: true
      }
    ],
    [auditType, systemsTotalCost, currentData.length, handleRowClick, getAttributeLabel]
  ) as Column[];

  const initialQuickFilters = useMemo(
    () => [
      {
        label: "0 à 5 ans",
        type: FilterType.QUICK_FILTER,
        field: "lifespan",
        values: ["0", "1", "2", "3", "4", "5"]
      },
      {
        label: "À vérifier",
        type: FilterType.QUICK_FILTER,
        field: "toVerify",
        values: ["1"]
      },
      {
        label: "Année installation estimée",
        type: FilterType.QUICK_FILTER,
        field: "is_installation_year_estimated",
        values: ["1"]
      },
      {
        label: "Contrôle qualité non fait",
        type: FilterType.QUICK_FILTER,
        field: "state",
        values: SystemState.values
          .filter((systemState: SystemState) => systemState !== SystemState.VERIFIED)
          .map((systemState: SystemState) => SystemState.format(dictionaryCode, systemState))
      },
      {
        label: "Non complété",
        type: FilterType.QUICK_FILTER,
        field: "state",
        values: SystemState.values
          .filter((systemState: SystemState) => systemState !== SystemState.COMPLETED)
          .map((systemState: SystemState) => SystemState.format(dictionaryCode, systemState))
      }
    ],
    [dictionaryCode]
  ) as Filter[];

  return (
    <Table
      className={className}
      data={data}
      onDataChange={onDataChange}
      columns={columns}
      filters={currentFilters}
      initialQuickFilters={initialQuickFilters}
      onFilter={onChangeFilter}
      onToggleFilter={onToggleFilter}
      onDeleteFilter={onDeleteFilter}
      onResetFilters={onResetFilters}
      rowSelectionProps={rowSelectionProps}
    />
  );
};

export default createStyledComponent(memo(SystemTable), systemTableStyle);
