import AuditProgress from "app/domain/asset/auditProgress";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import DatePicker from "react-datepicker";
import { Button as SemanticButton } from "semantic-ui-react";
import { css } from "styled-components";

const globalQualityControlStyle = css`
  .completionPercentagePanel {
    display: flex;
    flex-direction: row;
  }
`;

interface GlobalQualityControlProps extends ComponentProps {
  auditProgress: AuditProgress | undefined;
  onUpdate: (fieldName: string, fieldValue: any) => void;
}

const GlobalQualityControl: FC<GlobalQualityControlProps> = ({ auditProgress, onUpdate, className }: GlobalQualityControlProps) => {
  const handleProgressPercentageChange = (progressPercentage: number, value: string): void => {
    const date = new Date().toISOString().slice(0, 10);

    onUpdate("global_progress_percentage", progressPercentage);
    onUpdate(value, date);
  };

  const convertDate = (assessment_date: string | undefined | null): Date | null => {
    if (assessment_date !== undefined && assessment_date !== null && assessment_date !== "") {
      const parts = assessment_date.split("-");
      return new Date(parts[0] as unknown as number, (parts[1] as unknown as number) - 1, parts[2] as unknown as number);
    }
    return null;
  };

  const minTwoDigits = (n: number): string => (n < 10 ? "0" : "") + n;

  const handleDateChange = (name: string, date: any): void => {
    if (date !== null) {
      const dateString = `${(date as Date).getUTCFullYear().toString()}-${minTwoDigits((date as Date).getUTCMonth() + 1).toString()}-${minTwoDigits(
        (date as Date).getUTCDate().toString() as unknown as number
      )}`;

      onUpdate(name, dateString);
    }
  };

  return (
    <div className={className}>
      <div className="label">Avancement du contrôle qualité global:</div>
      <div>
        <SemanticButton.Group>
          <SemanticButton
            name="globalProgressPercentage25"
            active={auditProgress && auditProgress.global_progress_percentage === 25}
            onClick={(): void => handleProgressPercentageChange(25, "global_quality_control25_date")}
            inverted
            color="orange"
          >
            25%
          </SemanticButton>
          <SemanticButton
            name="globalProgressPercentage50"
            active={auditProgress && auditProgress.global_progress_percentage === 50}
            onClick={(): void => handleProgressPercentageChange(50, "global_quality_control50_date")}
            inverted
            color="yellow"
          >
            50%
          </SemanticButton>
          <SemanticButton
            name="globalProgressPercentage75"
            active={auditProgress && auditProgress.global_progress_percentage === 75}
            onClick={(): void => handleProgressPercentageChange(75, "global_quality_control75_date")}
            inverted
            color="olive"
          >
            75%
          </SemanticButton>
          <SemanticButton
            name="globalProgressPercentage100"
            active={auditProgress && auditProgress.global_progress_percentage === 100}
            onClick={(): void => handleProgressPercentageChange(100, "global_quality_control_date")}
            inverted
            color="green"
          >
            100%
          </SemanticButton>
        </SemanticButton.Group>
      </div>
      <div className="completionPercentagePanel">
        <div>
          <div className="label">25 % complété le:</div>
          <DatePicker
            name="global_quality_control25_date"
            onChange={(date): void => handleDateChange("global_quality_control25_date", date)}
            selected={convertDate(auditProgress && auditProgress.global_quality_control25_date)}
            dateFormat="dd/MM/yyyy"
          />
          <div className="label">75 % complété le:</div>
          <DatePicker
            name="global_quality_control75_date"
            onChange={(date): void => handleDateChange("global_quality_control75_date", date)}
            selected={convertDate(auditProgress && auditProgress.global_quality_control75_date)}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div>
          <div className="label">50 % complété le:</div>
          <DatePicker
            name="global_quality_control50_date"
            onChange={(date): void => handleDateChange("global_quality_control50_date", date)}
            selected={convertDate(auditProgress && auditProgress.global_quality_control50_date)}
            dateFormat="dd/MM/yyyy"
          />
          <div className="label">100 % complété le:</div>
          <DatePicker
            name="global_quality_control_date"
            onChange={(date): void => handleDateChange("global_quality_control_date", date)}
            selected={convertDate(auditProgress && auditProgress.global_quality_control_date)}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
    </div>
  );
};

export default createStyledComponent(GlobalQualityControl, globalQualityControlStyle);
