import { ComparisonError, ComparisonOperator, RequiredParamsBetweenError, RequiredParamsError } from "app/domain/common";
import { QaError } from "app/domain/qaError";
import _ from "lodash";

export type QaErrorDTO = {
  error_id: number;
  parent_id: string;
  asset_id: string;
  parent_category: string;
  asset_name: string;
  asset_number: string;
  parent_name: string;
  auditor: string;
  nb_errors: number;
  site_name: string;
  project_name: string;
  site_id: string;
  project_id: string;
  parent_reference: string;
  rule_name: string;
  rule_content: { operator: ComparisonOperator; first_param: string; second_param: string } | { params: string[] };
  created_by: string;
  comment?: string;
};

class QaErrorMapper {
  static fromDTO(qaErrorDTO: QaErrorDTO): QaError;
  static fromDTO(qaErrorDTOs: QaErrorDTO[]): QaError[];
  static fromDTO(qaErrorDTOs: QaErrorDTO | QaErrorDTO[]): any {
    if (!Array.isArray(qaErrorDTOs)) {
      return new QaError({
        errorId: qaErrorDTOs.error_id,
        parentId: qaErrorDTOs.parent_id,
        assetId: qaErrorDTOs.asset_id,
        parentCategory: qaErrorDTOs.parent_category,
        assetName: qaErrorDTOs.asset_name,
        assetNumber: qaErrorDTOs.asset_number,
        parentName: qaErrorDTOs.parent_name,
        auditor: qaErrorDTOs.auditor,
        nbErrors: qaErrorDTOs.nb_errors,
        siteName: qaErrorDTOs.site_name,
        projectName: qaErrorDTOs.project_name,
        siteId: qaErrorDTOs.site_id,
        projectId: qaErrorDTOs.project_id,
        parentReference: qaErrorDTOs.parent_reference,
        ruleName: qaErrorDTOs.rule_name,
        ruleContent: qaErrorDTOs.rule_content,
        createdBy: qaErrorDTOs.created_by,
        justification: qaErrorDTOs.comment,
        // eslint-disable-next-line no-nested-ternary
        errorMessage: _.isEqual(qaErrorDTOs.rule_name, "required")
          ? new RequiredParamsError({
              params: _.get(qaErrorDTOs, ["rule_content", "params"])
            })
          : _.isEqual(qaErrorDTOs.rule_name, "required_between")
          ? new RequiredParamsBetweenError({
              params: _.get(qaErrorDTOs, ["rule_content", "params"])
            })
          : new ComparisonError({
              firstValue: _.get(qaErrorDTOs, ["rule_content", "first_param"]),
              secondValue: _.get(qaErrorDTOs, ["rule_content", "second_param"]),
              operator: ComparisonOperator.fromString(_.get(qaErrorDTOs, ["rule_content", "operator"]))
            })
      });
    }
    return qaErrorDTOs.map((qaErrorDTO: QaErrorDTO): QaError => this.fromDTO(qaErrorDTO));
  }
}

export default QaErrorMapper;
