import { Comment, Reply } from "app/domain/comment/index";
import { EntityCategory } from "app/domain/entityCategory";
import Avatars from "app/view/comment/avatars";

class CommentFactory {
  createEmptyComment(parentId: string, parentType: EntityCategory): Comment {
    return new Comment({
      parentId,
      parentType,
      avatar: Avatars.Default,
      fieldName: "",
      text: "",
      replies: [],
      canEdit: false,
      isRead: false
    });
  }

  createReply(text: string): Reply {
    return new Reply({
      avatar: Avatars.Default,
      text,
      canEdit: false,
      isRead: false
    });
  }
}

export default CommentFactory;
