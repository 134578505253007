/* eslint-disable react/destructuring-assignment, react/no-access-state-in-setstate */

import { Column, ColumnApi, GridApi, RowNode } from "ag-grid-community";
import React, { Component } from "react";
import AgGridValuesSelector from "ui/table/filterPanel/filterPanelModal/agGridValuesSelector";
import ValuesSearchbar from "ui/table/filterPanel/filterPanelModal/valuesSearchbar";

import AgGridCustomFilterState from "./agGridCustomFilterState";

interface FilterProp {
  api: GridApi;
  colDef: any;
  column: Column;
  columnApi: ColumnApi;
}

interface ParamsType {
  node: RowNode;
  data: any;
}

class CustomTableFilter extends Component<any, any> {
  field: string;

  constructor(props: FilterProp) {
    super(props);

    this.field = this.props.colDef.field;
    this.state = {
      searchedValue: "",
      isChecked: false,
      isIndeterminate: false,
      uniqueValues: [] as string[],
      filteredUniqueValues: [] as string[],
      selectedValues: [] as string[]
    };

    this.onSearch = this.onSearch.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
  }

  componentDidMount(): void {
    this.setUniqueValues();
  }

  onSearch(newValue: string): void {
    this.setState({ searchedValue: newValue }, () => {
      const escapeRegExp = (value: string): string => value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      this.setState({
        filteredUniqueValues: Array.from(
          (this.state.uniqueValues as string[]).filter((value: string) => value.toLowerCase().match(escapeRegExp(this.state.searchedValue.toLowerCase())))
        )
      });
    });
  }

  onChange(): void {
    (this.props.api as GridApi).onFilterChanged();
  }

  onSelect(newSelectedElement: string): void {
    const index = (this.state.selectedValues as string[]).indexOf(newSelectedElement);
    const isInsideArray = index !== -1;
    if (isInsideArray) (this.state.selectedValues as string[]).splice(index, 1);
    this.setState(
      {
        isIndeterminate: isInsideArray ? this.state.selectedValues.length > 0 : true,
        selectedValues: isInsideArray ? this.state.selectedValues : [...this.state.selectedValues, newSelectedElement]
      },
      () => {
        this.onChange();
      }
    );
  }

  onSelectAll(): void {
    this.setState({ isChecked: !this.state.isChecked, isIndeterminate: false, selectedValues: this.state.isChecked ? [] : Array.from(this.state.filteredUniqueValues) }, () => {
      this.onChange();
    });
  }

  getModel(): AgGridCustomFilterState {
    return {
      isChecked: this.state.isChecked,
      isIndeterminate: this.state.isIndeterminate,
      uniqueValues: Array.from(this.state.uniqueValues),
      selectedValues: Array.from(this.state.selectedValues)
    };
  }

  setModel(model: AgGridCustomFilterState): void {
    if (!model) return;
    this.setState({
      isChecked: model.isChecked,
      isIndeterminate: model.isIndeterminate,
      uniqueValues: Array.from(model.uniqueValues),
      filteredUniqueValues: Array.from(model.uniqueValues),
      selectedValues: Array.from(model.selectedValues)
    });
  }

  setUniqueValues(): void {
    const uniqueValues = new Set<string>();
    (this.props.api as GridApi).forEachNode((node) => {
      uniqueValues.add(node.data[this.field]);
    });
    this.setState({ uniqueValues: Array.from(uniqueValues), filteredUniqueValues: Array.from(uniqueValues) });
  }

  isFilterActive(): boolean {
    return (this.state.selectedValues as string[]).length > 0;
  }

  doesFilterPass(params: ParamsType): boolean {
    return (this.state.selectedValues as string[]).includes(params.data[this.field]);
  }

  render(): JSX.Element {
    return (
      <div>
        <ValuesSearchbar searchedValue={this.state.searchedValue} onSearch={this.onSearch} />

        <AgGridValuesSelector
          values={this.state.filteredUniqueValues}
          selectedValues={this.state.selectedValues}
          searchedValue={this.state.searchedValue}
          onSelect={this.onSelect}
          onSelectAll={this.onSelectAll}
          isChecked={this.state.isChecked}
          isIndeterminate={this.state.isIndeterminate}
          className="agGridFilter"
        />
      </div>
    );
  }
}

export default CustomTableFilter;
