import { StyleModifiers, StyleProps } from "common/style/createStyledComponent";
import { darken, transparentize } from "polished";
import { css, SimpleInterpolation } from "styled-components";
import { Icon } from "ui/icon";

import * as constants from "./constants";

const getBackgroundColor = (color?: string, disabled?: boolean): string => {
  if (disabled) {
    return transparentize(0.5, "grey");
  }
  return color || constants.DEFAULT_BUTTON_COLOR;
};

export const style = css`
  &&&& {
    opacity: 1 !important;
    display: flex;
    color: white;
    background-color: ${(props: StyleProps): string => getBackgroundColor(props.color, props.disabled)};
    height: auto;
    width: fit-content;
    line-height: ${constants.DEFAULT_BUTTON_LINE_HEIGHT};
    margin: 0;

    &:hover {
      background-color: ${(props: StyleProps): string => darken(0.08, getBackgroundColor(props.color, props.disabled))};
    }

    ${Icon as unknown as SimpleInterpolation} {
      height: ${constants.DEFAULT_BUTTON_ICON_DIMENSIONS};
      width: ${constants.DEFAULT_BUTTON_ICON_DIMENSIONS};
      margin: auto;

      .icon {
        color: white;
        font-size: ${constants.DEFAULT_BUTTON_LINE_HEIGHT};
      }
    }

    .title {
      margin: auto auto auto ${(props: StyleProps): string => (props.iconType ? "10px" : "auto")};
    }
  }
`;

export const styleModifiers: StyleModifiers = {
  outlined: (props: StyleProps) => css`
    &&&&& {
      position: relative;
      color: ${getBackgroundColor(props.color, props.disabled)};
      background-color: transparent;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 1px solid ${transparentize(0.4, getBackgroundColor(props.color, props.disabled))};
        border-radius: inherit;
      }

      &:hover {
        background-color: ${transparentize(0.8, getBackgroundColor(props.color, props.disabled))};
        border: ${transparentize(0.8, getBackgroundColor(props.color, props.disabled))};
      }

      &:hover::after {
        border: 1px solid ${getBackgroundColor(props.color, props.disabled)};
      }

      ${Icon as unknown as SimpleInterpolation} {
        .icon {
          color: ${getBackgroundColor(props.color, props.disabled)};
        }
      }
    }
  `
};
