import { createAction } from "@reduxjs/toolkit";
import { Asset, AssetMinimalInfo } from "app/domain/asset";
import { EntityCategory } from "app/domain/entityCategory";

import { AuditStatus } from "../../domain/audit/auditStatus";
import { AssetActionTypes } from "./types";

export const getAssetAction = createAction(AssetActionTypes.GET_ASSET, (assetId: string) => ({
  payload: {
    assetId
  }
}));

export const getAssetRequestAction = createAction(AssetActionTypes.GET_ASSET_REQUEST, (assetId: string) => ({
  payload: {
    assetId
  }
}));

export const getAssetSuccessAction = createAction(AssetActionTypes.GET_ASSET_SUCCESS, (asset: Asset) => ({
  payload: {
    asset
  }
}));

export const getAssetErrorAction = createAction(AssetActionTypes.GET_ASSET_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const getAssetsAction = createAction(AssetActionTypes.GET_ASSETS, (parentCategory?: EntityCategory, parentId?: string, auditStatus?: string) => ({
  payload: {
    parentCategory,
    parentId,
    auditStatus
  }
}));

export const getAssetsRequestAction = createAction(AssetActionTypes.GET_ASSETS_REQUEST, (parentCategory?: EntityCategory, parentId?: string, auditStatus?: string) => ({
  payload: {
    parentCategory,
    parentId,
    auditStatus
  }
}));

export const getAssetsSuccessAction = createAction(AssetActionTypes.GET_ASSETS_SUCCESS, (assets: Asset[]) => ({
  payload: {
    assets
  }
}));

export const getAssetsErrorAction = createAction(AssetActionTypes.GET_ASSETS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const getAssetOptionsAction = createAction(AssetActionTypes.GET_ASSET_OPTIONS);

export const getAssetOptionsRequestAction = createAction(AssetActionTypes.GET_ASSET_OPTIONS_REQUEST);

// TODO: determine options type
export const getAssetOptionsSuccessAction = createAction(AssetActionTypes.GET_ASSET_OPTIONS_SUCCESS, (options: any) => ({
  payload: {
    options
  }
}));

export const getAssetOptionsErrorAction = createAction(AssetActionTypes.GET_ASSET_OPTIONS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const createAssetAction = createAction(AssetActionTypes.CREATE_ASSET, (asset: Asset, siteId: string) => ({
  payload: {
    asset,
    siteId
  }
}));

export const createAssetRequestAction = createAction(AssetActionTypes.CREATE_ASSET_REQUEST, (asset: Asset, siteId: string) => ({
  payload: {
    asset,
    siteId
  }
}));

export const createAssetSuccessAction = createAction(AssetActionTypes.CREATE_ASSET_SUCCESS, (asset: Asset, assets: Asset[]) => ({
  payload: {
    asset,
    assets
  }
}));

export const createAssetErrorAction = createAction(AssetActionTypes.CREATE_ASSET_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const updateAssetAction = createAction(AssetActionTypes.UPDATE_ASSET, (asset: Asset, entityType: EntityCategory, entityId: string, auditStatus?: AuditStatus) => ({
  payload: {
    asset,
    entityType,
    entityId,
    auditStatus
  }
}));

export const updateAssetRequestAction = createAction(
  AssetActionTypes.UPDATE_ASSET_REQUEST,
  (asset: Asset, entityType: EntityCategory, entityId: string, auditStatus?: AuditStatus) => ({
    payload: {
      asset,
      entityType,
      entityId,
      auditStatus
    }
  })
);

export const updateAssetSuccessAction = createAction(AssetActionTypes.UPDATE_ASSET_SUCCESS, (asset: Asset, assets: Asset[]) => ({
  payload: {
    asset,
    assets
  }
}));

export const updateAssetErrorAction = createAction(AssetActionTypes.UPDATE_ASSET_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const getAssetsMinimalInfoAction = createAction(AssetActionTypes.GET_ASSETS_MINIMAL_INFO, (siteId: string) => ({
  payload: {
    siteId
  }
}));

export const getAssetsMinimalInfoRequestAction = createAction(AssetActionTypes.GET_ASSETS_MINIMAL_INFO_REQUEST, (siteId: string) => ({
  payload: {
    siteId
  }
}));

export const getAssetsMinimalInfoSuccessAction = createAction(AssetActionTypes.GET_ASSETS_MINIMAL_INFO_SUCCESS, (assetsMinimalInfo: AssetMinimalInfo[]) => ({
  payload: {
    assetsMinimalInfo
  }
}));

export const getAssetsMinimalInfoErrorAction = createAction(AssetActionTypes.GET_ASSETS_MINIMAL_INFO_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const resetAction = createAction(AssetActionTypes.RESET_ASSET);
