import { Requirement } from "app/domain/requirement";
import { System } from "app/domain/system";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useMemo } from "react";
import { css } from "styled-components";
import { IconType } from "ui/icon";

import NavigationButton from "./navigationButton";

const navigationButtonsStyle = css`
  &&&& {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;

    button {
      max-width: 130px;
      min-width: 130px;
      height: fit-content;

      :not(:last-child) {
        margin-bottom: 10px;
      }

      :last-child {
        flex-direction: row-reverse;
      }
    }
  }
`;

interface NavigationButtonsProps extends ComponentProps {
  itemId: string;
  items: System[] | Requirement[];
  tabIndex?: number;
}

const NavigationButtons: FC<NavigationButtonsProps> = ({ itemId, items, tabIndex, className }: NavigationButtonsProps) => {
  const currentItemIndex = useMemo(() => items.findIndex((item: System | Requirement) => item.id === itemId), [items, itemId]);

  const previousItemId = useMemo(() => (items[currentItemIndex - 1] ? items[currentItemIndex - 1].id : undefined), [items, currentItemIndex]);

  const nextItemId = useMemo(() => (items[currentItemIndex + 1] ? items[currentItemIndex + 1].id : undefined), [items, currentItemIndex]);

  return (
    <div className={className}>
      {previousItemId && (
        <NavigationButton label="Précédent" itemId={previousItemId} iconType={IconType.ANGLE_LEFT} tabIndex={tabIndex} data-test-id="previousItemNavigationButton" />
      )}
      {nextItemId && <NavigationButton label="Suivant" itemId={nextItemId} iconType={IconType.ANGLE_RIGHT} tabIndex={tabIndex} data-test-id="nextItemNavigationButton" />}
    </div>
  );
};

export default createStyledComponent(NavigationButtons, navigationButtonsStyle);
