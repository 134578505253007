/* eslint-disable @typescript-eslint/no-unused-vars */

import React from "react";
import { useToggle } from "ui/hook";

type CheckboxHook = {
  isChecked: boolean;
  onClick: (event: React.FormEvent<HTMLInputElement>) => void;
};

const useCheckbox = (isChecked: boolean): CheckboxHook => {
  const { isToggleOn: isCurrentlyChecked, toggle: toggleCheckedState } = useToggle(isChecked);

  const handleClick = (_: React.FormEvent<HTMLInputElement>): void => {
    toggleCheckedState();
  };

  return { isChecked: isCurrentlyChecked, onClick: handleClick };
};

export default useCheckbox;
