import { RequirementProblematicOption } from "app/domain/requirement/options";

export type RequirementProblematicOptionDTO = {
  parent: string;
  text: string;
  value: string;
  id?: number;
};

class RequirementProblematicOptionMapper {
  static fromDTO(requirementProblematicOptionDTO: RequirementProblematicOptionDTO): RequirementProblematicOption;
  static fromDTO(requirementProblematicOptionDTOs: RequirementProblematicOptionDTO[]): RequirementProblematicOption[];
  static fromDTO(requirementProblematicOptionDTOs: RequirementProblematicOptionDTO | RequirementProblematicOptionDTO[]): any {
    if (!Array.isArray(requirementProblematicOptionDTOs)) {
      return new RequirementProblematicOption({
        parent: requirementProblematicOptionDTOs.parent,
        text: requirementProblematicOptionDTOs.text,
        value: requirementProblematicOptionDTOs.value,
        id: requirementProblematicOptionDTOs.id
      });
    }
    return requirementProblematicOptionDTOs.map(
      (requirementProblematicOptionDTO: RequirementProblematicOptionDTO): RequirementProblematicOption => this.fromDTO(requirementProblematicOptionDTO)
    );
  }

  static toDTO(requirementProblematicOption: RequirementProblematicOption): RequirementProblematicOptionDTO {
    return {
      parent: requirementProblematicOption.parent,
      text: requirementProblematicOption.text,
      value: requirementProblematicOption.value,
      id: requirementProblematicOption.id
    };
  }
}

export default RequirementProblematicOptionMapper;
