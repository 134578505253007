/* stylelint-disable  value-keyword-case, no-descending-specificity */

import { ComponentProps, createStyledComponent, StyleModifiers, StyleProps } from "common/style/createStyledComponent";
import { darken, transparentize } from "polished";
import React, { FC } from "react";
import { css, SimpleInterpolation } from "styled-components";

import { Icon } from "../icon";
import Button from "./button";
import * as constants from "./constants";
import { style as buttonStyle } from "./style";

const buttonGroupStyle = css`
  display: inline-flex;
  flex-direction: row;
  font-size: 0;
  vertical-align: baseline;
  margin: 0 0.25em 0 0;

  &&& > * {
    border-radius: 0;
    flex: 1 0 auto;
    margin: 0;
  }

  &&& > *:first-child {
    border-top-left-radius: ${constants.DEFAULT_BUTTON_BORDER_RADIUS};
    border-bottom-left-radius: ${constants.DEFAULT_BUTTON_BORDER_RADIUS};
  }

  &&& > *:last-child {
    border-top-right-radius: ${constants.DEFAULT_BUTTON_BORDER_RADIUS};
    border-bottom-right-radius: ${constants.DEFAULT_BUTTON_BORDER_RADIUS};
  }

  &&&& {
    ${Button as unknown as SimpleInterpolation} {
      ${buttonStyle};
      background-color: ${(props: StyleProps): string => props.color || constants.DEFAULT_BUTTON_COLOR};

      &:hover {
        background-color: ${(props: StyleProps): string => darken(0.08, props.color || constants.DEFAULT_BUTTON_COLOR)};
      }
    }
  }
`;

export const buttonGroupStyleModifiers: StyleModifiers = {
  outlined: (props: StyleProps) => css`
    &&&& {
      ${Button as unknown as SimpleInterpolation} {
        position: relative;
        background-color: transparent;
        color: ${props.color || constants.DEFAULT_BUTTON_COLOR};

        &:disabled {
          background-color: #fff;
          color: #f0f0f1;
          ${Icon as unknown as SimpleInterpolation} {
            .icon {
              color: #f0f0f1;
            }
          }
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: -1px;
          top: 0;
          bottom: 0;
          border: 1px solid ${transparentize(0.4, props.color || constants.DEFAULT_BUTTON_COLOR)};
          border-radius: inherit;
        }

        &:hover {
          background-color: ${transparentize(0.8, props.color || constants.DEFAULT_BUTTON_COLOR)};
          border: ${transparentize(0.8, props.color || constants.DEFAULT_BUTTON_COLOR)};
        }

        &:hover::after {
          border: 1px solid ${props.color || constants.DEFAULT_BUTTON_COLOR};
        }

        ${Icon as unknown as SimpleInterpolation} {
          .icon {
            color: ${props.color || constants.DEFAULT_BUTTON_COLOR};
          }
        }
      }
    }
  `
};

interface ButtonGroupProps extends ComponentProps {
  children: JSX.Element[] | JSX.Element;
  color?: string;
}

const ButtonGroup: FC<ButtonGroupProps> = ({ children, className }: ButtonGroupProps) => <div className={className}>{children}</div>;

export default createStyledComponent(ButtonGroup, buttonGroupStyle, buttonGroupStyleModifiers);
