import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import { transparentize } from "polished";
import React, { FC, memo } from "react";
import { css } from "styled-components";
import { Icon, IconType } from "ui/icon";

const getIconColor = (isSortedDesc?: boolean): string => {
  if (isSortedDesc !== undefined) {
    return "rgba(0, 0, 0, 0.87)";
  }
  return transparentize(0.5, "rgba(0, 0, 0, 0.87)");
};

const sortFilterStyle = css`
  &&&&& {
    height: 100%;
    margin-left: auto;

    i {
      color: ${(props: StyleProps): string => getIconColor(props.isSortedDesc)};
      font-size: 1em;
      border: 0;
      left: 0;
      right: 0;
      transition: color 0.1s ease;
    }

    i::before {
      position: relative;
      top: 0;
      left: 0;
    }
  }
`;

interface SortFilterProps extends ComponentProps {
  sort: boolean;
  isSortedDesc: boolean | undefined;
}

const SortFilter: FC<SortFilterProps> = ({ sort, isSortedDesc, className }: SortFilterProps) =>
  sort ? <Icon type={isSortedDesc ? IconType.ARROW_DOWN : IconType.ARROW_UP} className={className} data-test-id="icon" /> : null;

export default createStyledComponent(memo(SortFilter), sortFilterStyle);
