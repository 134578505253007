import { css } from "styled-components";
import { colors } from "ui/colors";

const defaultColor = colors.ACCENT_BLUE_DARK;

export const style = css`
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-container {
    outline: none;
    max-height: fit-content;
    max-width: fit-content;
    overflow: auto;
    border: 2px solid ${defaultColor};
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    flex: 1;

    .header {
      font-size: 20px;
      color: ${defaultColor};
    }

    .content {
      flex: 1;
    }

    .footer {
      justify-content: flex-end;
      display: flex;

      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`;
