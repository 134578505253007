import { SystemChildren } from "app/domain/systemChildren";

import api, { NEW_API_VERSION } from "../common/api";
import SystemChildrenMapper, { SystemChildrenDTO } from "./systemChildrenMapper";

class SystemChildrenService {
  static getSystemChildren = async (systemId: string): Promise<SystemChildren> => {
    const systemChildrenDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/systems/${systemId}?children=true`
      })
    ).data as SystemChildrenDTO;

    return SystemChildrenMapper.fromDTO(systemChildrenDTO);
  };

  static putSystemChildren = async (systemId: string, body: { child_type: string; child_text_value?: string; child_ids?: string[] }): Promise<SystemChildren> => {
    return (
      await api.patch({
        path: `${NEW_API_VERSION}/systems/`,
        id: systemId,
        body
      })
    ).data;
  };

  static searchSystemChildren = async (siteId: string, text: string): Promise<{ id: string; custom_name_concat: string; reference: string }> => {
    return (
      await api.get({
        path: `${NEW_API_VERSION}/systems?search_text=${text}&site_id=${siteId}&limit=50&columns[]=id,reference,custom_name_concat`
      })
    ).data;
  };
}

export default SystemChildrenService;
