import { isLoading as isAssetLoadingSelector, isLoadingOptions as isLoadingAssetOptionsSelector } from "app/store/asset/selectors";
import { isLoading as isAuditProgressLoadingSelector } from "app/store/auditProgress/selectors";
import { isLoading as isCostItemLoadingSelector } from "app/store/costItem/selectors";
import { isFetching as isLocalLoadingSelector } from "app/store/local/selectors";
import { isLoading as isProjectLoadingSelector } from "app/store/project/selectors";
import { isLoading as isReportLoadingSelector } from "app/store/report/reportSelectors";
import { isLoading as isRequirementLoadingSelector, isLoadingOptions as isLoadingRequirementOptionsSelector } from "app/store/requirement/selectors";
import { isLoading as isSiteLoadingSelector } from "app/store/site/selectors";
import { isLoading as isSystemLoadingSelector, isLoadingOptions as isLoadingSystemOptionsSelector } from "app/store/system/selectors";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";

interface LoaderProviderState {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
}

const LoaderContext = createContext({
  isActive: false,
  setIsActive: (isActive: boolean) => {}
});

export const useLoader = (): LoaderProviderState => useContext(LoaderContext);

const LoaderProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const isAssetLoading = useSelector(isAssetLoadingSelector);
  const isLoadingAssetOptions = useSelector(isLoadingAssetOptionsSelector);
  const isAuditProgressLoading = useSelector(isAuditProgressLoadingSelector);
  const isCostItemLoading = useSelector(isCostItemLoadingSelector);
  const isLocalLoading = useSelector(isLocalLoadingSelector);
  const isProjectLoading = useSelector(isProjectLoadingSelector);
  const isRequirementLoading = useSelector(isRequirementLoadingSelector);
  const isLoadingRequirementOptions = useSelector(isLoadingRequirementOptionsSelector);
  const isSiteLoading = useSelector(isSiteLoadingSelector);
  const isSystemLoading = useSelector(isSystemLoadingSelector);
  const isLoadingSystemOptions = useSelector(isLoadingSystemOptionsSelector);
  const isReportLoading = useSelector(isReportLoadingSelector);

  const setIsActive = useCallback((isActive: boolean): void => {
    setIsLoading(isActive);
  }, []);

  const isActive = useMemo(
    () =>
      isLoading ||
      isAssetLoading ||
      isLoadingAssetOptions ||
      isAuditProgressLoading ||
      isCostItemLoading ||
      isLocalLoading ||
      isProjectLoading ||
      isRequirementLoading ||
      isSiteLoading ||
      isSystemLoading ||
      isLoadingSystemOptions ||
      isLoadingRequirementOptions ||
      isReportLoading,
    [
      isLoading,
      isAssetLoading,
      isLoadingAssetOptions,
      isAuditProgressLoading,
      isCostItemLoading,
      isLocalLoading,
      isProjectLoading,
      isRequirementLoading,
      isSiteLoading,
      isSystemLoading,
      isLoadingSystemOptions,
      isLoadingRequirementOptions,
      isReportLoading
    ]
  );

  return <LoaderContext.Provider value={{ isActive, setIsActive }}>{children}</LoaderContext.Provider>;
};

export default LoaderProvider;
