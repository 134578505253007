/* eslint-disable @typescript-eslint/no-namespace, import/export */

export enum ComparisonOperator {
  GREATER_THAN = "greaterThan",
  GREATER_THAN_OR_EQUALS = "greaterThanOrEquals",
  LESS_THAN_OR_EQUALS = "lessThanOrEquals",
  EQUALS = "equals",
  NOT_EQUALS = "notEquals",
  SHOULD_NOT_CONTAIN = "shouldNotContain"
}

export namespace ComparisonOperator {
  export const toString = (comparisonOperator: ComparisonOperator): string => {
    if (comparisonOperator === ComparisonOperator.GREATER_THAN) {
      return "greater_than";
    }
    if (comparisonOperator === ComparisonOperator.GREATER_THAN_OR_EQUALS) {
      return "greater_than_or_equals";
    }
    if (comparisonOperator === ComparisonOperator.LESS_THAN_OR_EQUALS) {
      return "less_than_or_equals";
    }
    if (comparisonOperator === ComparisonOperator.EQUALS) {
      return "equals";
    }
    if (comparisonOperator === ComparisonOperator.NOT_EQUALS) {
      return "not_equals";
    }
    if (comparisonOperator === ComparisonOperator.SHOULD_NOT_CONTAIN) {
      return "should_not_contain";
    }

    return "";
  };

  export const fromString = (comparisonOperator: string): ComparisonOperator => {
    if (comparisonOperator === "greater_than") {
      return ComparisonOperator.GREATER_THAN;
    }
    if (comparisonOperator === "greater_than_or_equals") {
      return ComparisonOperator.GREATER_THAN_OR_EQUALS;
    }
    if (comparisonOperator === "less_than_or_equals") {
      return ComparisonOperator.LESS_THAN_OR_EQUALS;
    }
    if (comparisonOperator === "equals") {
      return ComparisonOperator.EQUALS;
    }
    if (comparisonOperator === "not_equals") {
      return ComparisonOperator.NOT_EQUALS;
    }
    if (comparisonOperator === "should_not_contain") {
      return ComparisonOperator.SHOULD_NOT_CONTAIN;
    }

    throw new RangeError(
      `Illegal argument passed to fromString(): ${comparisonOperator} does not 
      correspond to any instance of the enum ComparisonOperator`
    );
  };
}
