import {
  LIST_PARENTS_AND_CHILDREN_ERROR,
  LIST_PARENTS_AND_CHILDREN_REQUEST,
  LIST_PARENTS_AND_CHILDREN_SUCCESS,
} from "./sideMenuActions";

import { initialSideMenuState } from "./state";

export default (state = initialSideMenuState, action) => {
  switch (action.type) {
    case LIST_PARENTS_AND_CHILDREN_REQUEST: {
      return { ...state, isFetching: true, error: undefined };
    }
    case LIST_PARENTS_AND_CHILDREN_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: { ...action.data },
        error: false,
      };
    }
    case LIST_PARENTS_AND_CHILDREN_ERROR: {
      return { ...state, isFetching: false, error: action.error };
    }
    default:
      return state;
  }
};
