/* eslint-disable react-hooks/exhaustive-deps */

import { Uniformat, UniformatLevel1 } from "app/domain/uniformat";
import UniformatOption from "app/domain/uniformat/options/uniformatOption";
import UniformatDropdown from "app/view/uniformat/uniformatDropdown";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect, useState } from "react";
import { css } from "styled-components";
import { colors } from "ui/colors";

const uniformatSectionStyle = css`
  padding: 1em 0;
  display: flex;
  flex-direction: column;

  .subtitle {
    size: 14px;
    color: ${colors.ACCENT_BLUE_DARK};
  }

  .dropdownSection {
    display: flex;
    flex-direction: row;
  }

  .uniformatOptions {
    padding: 0 5px 0 0;
  }
`;

interface UniformatSectionProps extends ComponentProps {
  projectId: string;
  uniformat: Uniformat;
  onUpdate: (fields: any) => void;
  onUpdateError: (errorMessage: string) => void;
}

const UniformatSection: FC<UniformatSectionProps> = ({ projectId, uniformat, onUpdate, onUpdateError, className }: UniformatSectionProps) => {
  const [dropdownLevel1Value, setDropdownLevel1Value] = useState(uniformat.level1 ? uniformat.level1 : "");
  const [dropdownLevel2Value, setDropdownLevel2Value] = useState(uniformat.level2 ? uniformat.level2 : "");
  const [dropdownLevel3Value, setDropdownLevel3Value] = useState(uniformat.level3 ? uniformat.level3 : "");
  const [dropdownLevel4Value, setDropdownLevel4Value] = useState(uniformat.level4 ? uniformat.level4 : "");

  const [dropdownLevel1Data, setDropdownLevel1Data] = useState({
    uniformatOptions: [] as UniformatOption[],
    isLoading: false,
    isDisabled: false
  });
  const [dropdownLevel2Data, setDropdownLevel2Data] = useState({
    uniformatOptions: [] as UniformatOption[],
    isLoading: false,
    isDisabled: true
  });
  const [dropdownLevel3Data, setDropdownLevel3Data] = useState({
    uniformatOptions: [] as UniformatOption[],
    isLoading: false,
    isDisabled: true
  });
  const [dropdownLevel4Data, setDropdownLevel4Data] = useState({
    uniformatOptions: [] as UniformatOption[],
    isLoading: false,
    isDisabled: true
  });

  useEffect(() => {
    if (uniformat && uniformat.level1) {
      setDropdownLevel1Value(uniformat.level1);
      setDropdownLevel2Value(uniformat.level2);
      setDropdownLevel3Value(uniformat.level3);
      setDropdownLevel4Value(uniformat.level4);
    }
  }, [uniformat]);

  const onUpdateUniformat1 = (data: string): void => {
    setDropdownLevel1Value(data);
    setDropdownLevel2Value("");
    setDropdownLevel3Value("");
    setDropdownLevel4Value("");

    setDropdownLevel2Data({
      ...dropdownLevel2Data,
      uniformatOptions: []
    });
    setDropdownLevel3Data({
      ...dropdownLevel3Data,
      uniformatOptions: [],
      isDisabled: true
    });
    setDropdownLevel4Data({
      ...dropdownLevel4Data,
      uniformatOptions: [],
      isDisabled: true
    });
    if (data) {
      onUpdate({
        level1: data as UniformatLevel1,
        level2: "",
        level3: "",
        level4: ""
      });
      setDropdownLevel2Data({ ...dropdownLevel2Data, isLoading: true });
    } else {
      setDropdownLevel2Data({ ...dropdownLevel2Data, isDisabled: true });
      onUpdate({
        level2: "",
        level3: "",
        level4: ""
      });
    }
  };

  const onUpdateUniformat2 = (data: string): void => {
    setDropdownLevel2Value(data);
    setDropdownLevel3Value("");
    setDropdownLevel4Value("");

    setDropdownLevel3Data({
      ...dropdownLevel3Data,
      uniformatOptions: []
    });
    setDropdownLevel4Data({
      ...dropdownLevel4Data,
      uniformatOptions: [],
      isDisabled: true
    });
    if (data) {
      onUpdate({
        level1: dropdownLevel1Value,
        level2: data,
        level3: "",
        level4: ""
      });
      setDropdownLevel3Data({ ...dropdownLevel3Data, isLoading: true });
    } else {
      setDropdownLevel3Data({ ...dropdownLevel3Data, isDisabled: true });
      onUpdate({
        level1: dropdownLevel1Value,
        level2: "",
        level3: "",
        level4: ""
      });
    }
  };

  const onUpdateUniformat3 = (data: string): void => {
    setDropdownLevel3Value(data);
    setDropdownLevel4Value("");
    setDropdownLevel4Data({
      ...dropdownLevel4Data,
      uniformatOptions: []
    });
    if (data) {
      onUpdate({
        level1: dropdownLevel1Value,
        level2: dropdownLevel2Value,
        level3: data,
        level4: ""
      });
      setDropdownLevel4Data({ ...dropdownLevel4Data, isLoading: true });
    } else {
      setDropdownLevel4Data({ ...dropdownLevel4Data, isDisabled: true });
      onUpdate({
        level1: dropdownLevel1Value,
        level2: dropdownLevel2Value,
        level3: "",
        level4: ""
      });
    }
  };

  const onUpdateUniformat4 = (data: string): void => {
    setDropdownLevel4Value(data);
    onUpdate({
      level1: dropdownLevel1Value,
      level2: dropdownLevel2Value,
      level3: dropdownLevel3Value,
      level4: data
    });
  };

  const onSuccessFetchingLevel1 = (uniformatOptions: UniformatOption[]): void => {
    setDropdownLevel1Data({
      uniformatOptions,
      isLoading: false,
      isDisabled: false
    });
  };

  const onSuccessFetchingLevel2 = (uniformatOptions: UniformatOption[]): void => {
    setDropdownLevel2Data({
      uniformatOptions,
      isLoading: false,
      isDisabled: false
    });
  };

  const onSuccessFetchingLevel3 = (uniformatOptions: UniformatOption[]): void => {
    setDropdownLevel3Data({
      uniformatOptions,
      isLoading: false,
      isDisabled: false
    });
  };

  const onSuccessFetchingLevel4 = (uniformatOptions: UniformatOption[]): void => {
    setDropdownLevel4Data({
      uniformatOptions,
      isLoading: false,
      isDisabled: false
    });
  };

  return (
    <div className={className}>
      <div className="subtitle">Uniformat</div>
      <div className="dropdownSection">
        <UniformatDropdown
          value={dropdownLevel1Value}
          projectId={projectId}
          label="Niveau 1"
          uniformatLevel="level1"
          uniformatParent=""
          dropdownData={dropdownLevel1Data}
          onSuccess={onSuccessFetchingLevel1}
          onUpdate={onUpdateUniformat1}
          onUpdateError={onUpdateError}
          data-test-id="dropdownLevel1"
        />
        <UniformatDropdown
          value={dropdownLevel2Value}
          projectId={projectId}
          label="Niveau 2"
          uniformatLevel="level2"
          uniformatParent={dropdownLevel1Value}
          dropdownData={dropdownLevel2Data}
          onSuccess={onSuccessFetchingLevel2}
          onUpdate={onUpdateUniformat2}
          onUpdateError={onUpdateError}
          data-test-id="dropdownLevel2"
        />
        <UniformatDropdown
          value={dropdownLevel3Value}
          projectId={projectId}
          label="Niveau 3"
          uniformatLevel="level3"
          uniformatParent={dropdownLevel2Value}
          dropdownData={dropdownLevel3Data}
          onSuccess={onSuccessFetchingLevel3}
          onUpdate={onUpdateUniformat3}
          onUpdateError={onUpdateError}
          data-test-id="dropdownLevel3"
        />
        <UniformatDropdown
          value={dropdownLevel4Value}
          projectId={projectId}
          label="Niveau 4"
          uniformatLevel="level4"
          uniformatParent={dropdownLevel3Value}
          dropdownData={dropdownLevel4Data}
          onSuccess={onSuccessFetchingLevel4}
          onUpdate={onUpdateUniformat4}
          onUpdateError={onUpdateError}
          data-test-id="dropdownLevel4"
        />
      </div>
    </div>
  );
};

export default createStyledComponent(UniformatSection, uniformatSectionStyle);
