import { DatePicker } from "antd";
import { Asset } from "app/domain/asset";
import { useI18n } from "app/locales";
import { getAssetOptions } from "app/store/asset/selectors";
import { State } from "app/store/state";
import { AssetFields } from "app/view/asset/form/useAsset";
import { ComponentProps } from "common/style/createStyledComponent";
import moment from "moment";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { Form as SemanticForm } from "semantic-ui-react";

type InstallationYear = number | moment.Moment | undefined;

interface AssetInformationProps extends ComponentProps {
  asset: Asset | undefined;
  onUpdate: (fields: AssetFields) => void;
}

const AssetInformation: FC<AssetInformationProps> = ({ asset, onUpdate }: AssetInformationProps) => {
  const { getAttributeLabel } = useI18n();

  const [isAssetAreaMessageDisplayed, setIsAssetAreaMessageDisplayed] = useState(false);
  const assetOptions = useSelector(getAssetOptions);

  const sites = useSelector((state: State): any[] => (state.minimalInfo.data ? state.minimalInfo.data.sites : []));

  const setLists = (): any[] => {
    const siteOptions = [];

    if (sites) {
      const data = sites;

      for (let index = 0; index < data.length; index += 1) {
        siteOptions.push({
          key: index,
          text: data[index].name,
          value: data[index].id
        });
      }
    }

    return siteOptions;
  };

  const handleSelectChange = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({ [data.name]: data.value });
  };

  const handleChange = (event: any): void => {
    event.preventDefault();
    onUpdate({
      [event.target.name]: event.target.value
    });
  };

  const notifyAssetAreaHasChanged = (): void => {
    setIsAssetAreaMessageDisplayed(true);
  };

  const getYear = (key: "year_renovated" | "year_constructed"): InstallationYear => {
    if (!asset || (moment.isMoment(asset[key]) && asset[key]?.get("year") === 0)) return undefined;
    if (typeof asset[key] === "string") {
      return moment(asset[key]);
    }
    return asset[key];
  };

  const onDateChange = (e: number, key: string): void => {
    onUpdate({
      // Temporary fix. moment().set("year", 0) sets year to 0 so it can save in BDD (bigint)
      [key]: e || moment().set("year", 0) // null
    });
  };

  return (
    <>
      <SemanticForm.Group unstackable widths={4}>
        <SemanticForm.Dropdown
          disabled
          icon="search"
          required
          label="Site"
          clearable
          placeholder="Site"
          name="id_site"
          value={asset && asset.site_id}
          fluid
          onChange={handleSelectChange}
          search
          selection
          options={setLists()}
        />
      </SemanticForm.Group>
      <SemanticForm.Group unstackable widths={4}>
        {asset && asset.id ? <SemanticForm.Input disabled label="ID de l'actif" value={asset.id} /> : null}
        <SemanticForm.Input
          disabled
          label={getAttributeLabel("customer_reference")}
          placeholder={getAttributeLabel("customer_reference")}
          name="customerReference"
          value={asset && asset.customerReference ? asset.customerReference : ""}
          onChange={handleChange}
        />
        <SemanticForm.Input
          disabled
          label="Numéro de l'actif"
          placeholder="Numéro de l'actif"
          name="asset_number"
          value={asset && asset.asset_number ? asset.asset_number : ""}
          onChange={handleChange}
        />
        <SemanticForm.Input
          disabled
          label={getAttributeLabel("name")}
          placeholder={getAttributeLabel("name")}
          name="name"
          value={asset && asset.name ? asset.name : ""}
          onChange={handleChange}
        />
        <SemanticForm.Input disabled label="Patrimonial" name="heritage" value={asset && asset.heritage ? "Oui" : "Non"} />
        <SemanticForm.Dropdown
          required
          label="Vocation principale"
          multiple
          clearable
          placeholder="Vocation principale"
          name="asset_use"
          value={asset && asset.asset_use}
          fluid
          onChange={handleSelectChange}
          selection
          options={assetOptions ? assetOptions.list_asset_use_options : []}
        />
      </SemanticForm.Group>
      <SemanticForm.Group unstackable widths={3}>
        <SemanticForm.Field
          required
          name="year_constructed"
          label={getAttributeLabel("construction_year")}
          control={DatePicker.YearPicker}
          value={getYear("year_constructed")}
          onChange={(e: number): void => onDateChange(e, "year_constructed")}
          placeholder={getAttributeLabel("construction_year")}
        />
        <SemanticForm.Field
          name="year_renovated"
          label="Dernière année de rénovation majeure"
          control={DatePicker.YearPicker}
          value={getYear("year_renovated")}
          onChange={(e: number): void => onDateChange(e, "year_renovated")}
          placeholder="Année de rénovation"
        />
        <SemanticForm.Input
          label={`Superficie de l'actif en m\u00B2`}
          placeholder="Superficie de l'actif"
          name="area"
          type="number"
          value={asset && asset.area}
          onChange={handleChange}
          onBlur={notifyAssetAreaHasChanged}
        />
        {isAssetAreaMessageDisplayed ? (
          <p className="warning">Les systèmes dont la quantité est liée à la superficie de l&apos; actif seront mis à jour lors de l&apos;enregistrement.</p>
        ) : null}
      </SemanticForm.Group>
      <SemanticForm.Group unstackable widths={3}>
        <SemanticForm.Input
          required
          label="Nombre d'étages sous-sol"
          placeholder="Nombre d'étage sous-sol"
          name="nb_floors_basement"
          value={asset && asset.nb_floors_basement ? asset.nb_floors_basement : ""}
          fluid
          onChange={handleChange}
        />
        <SemanticForm.Input
          required
          label="Nombre d'étages hors sol"
          placeholder="Nombre d'étage hors sol"
          name="nb_floors_total"
          value={asset && asset.nb_floors_total ? asset.nb_floors_total : ""}
          fluid
          onChange={handleChange}
        />
        <SemanticForm.Dropdown
          required
          label="Stationnement souterrain"
          clearable
          placeholder="Stationnement souterrain"
          name="has_underground_parking"
          value={asset && asset.has_underground_parking}
          fluid
          onChange={handleSelectChange}
          selection
          options={assetOptions ? assetOptions.list_has_underground_parking_options : []}
        />
      </SemanticForm.Group>
    </>
  );
};

export default AssetInformation;
