interface UserCommentProps {
  commentId: number;
  parentCategory: string;
  projectId: string;
  siteId: string;
  assetId: string;
  parentId: string;
  siteName: string;
  assetNumber: string;
  parentName: string;
  message: string;
  author: string;
  isResolved: boolean;
  isRead: boolean;
  createdAt: string;
}

class UserComment {
  commentId: number;
  parentCategory: string;
  projectId: string;
  siteId: string;
  assetId: string;
  parentId: string;
  siteName: string;
  assetNumber: string;
  parentName: string;
  message: string;
  author: string;
  isResolved: boolean;
  isRead: boolean;
  createdAt: string;

  constructor(props: UserCommentProps) {
    this.siteName = props.siteName;
    this.commentId = props.commentId;
    this.parentCategory = props.parentCategory;
    this.projectId = props.projectId;
    this.siteId = props.siteId;
    this.assetId = props.assetId;
    this.parentId = props.parentId;
    this.siteName = props.siteName;
    this.assetNumber = props.assetNumber;
    this.parentName = props.parentName;
    this.message = props.message;
    this.author = props.author;
    this.isResolved = props.isResolved;
    this.isRead = props.isRead;
    this.createdAt = props.createdAt;
  }
}

export default UserComment;
