/* eslint-disable react-hooks/exhaustive-deps */

import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useEffect } from "react";
import FormControl, { BaseFormControlProps } from "ui/form/formControl";
import { useFormContext } from "ui/form/useForm";

import TextField from "./textField";
import useTextField from "./useTextField";

interface BaseTextFieldProps extends ComponentProps {
  text: string;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  number?: boolean;
  isDisabled?: boolean;
  isNotBold?: boolean;
  hasBorder?: boolean;
}

interface ControlledTextFieldProps extends BaseTextFieldProps {
  onInput: (newText: string) => void;
}

interface UncontrolledTextFieldProps extends BaseTextFieldProps, BaseFormControlProps {}

type TextFieldProps = ControlledTextFieldProps | UncontrolledTextFieldProps;

function isControlled(props: TextFieldProps): props is ControlledTextFieldProps {
  return "onInput" in props;
}

const TextFieldControl: FC<TextFieldProps> = (props: TextFieldProps) => {
  const { text, label, placeholder, readOnly, number, className, isDisabled, isNotBold, hasBorder } = props;

  const formContext = useFormContext();

  const { text: currentText, onInput } = useTextField(text);

  useEffect(() => {
    if (!isControlled(props)) {
      const { name } = props;

      if (formContext) {
        formContext.setValue(name, text);
      }
    }
  }, [props]);

  const handleInput = (newText: string): void => {
    onInput(newText);

    if (formContext) {
      formContext.setValue((props as UncontrolledTextFieldProps).name, newText);
    }
  };

  return isControlled(props) ? (
    <TextField
      isDisabled={isDisabled}
      isNotBold={isNotBold}
      hasBorder={hasBorder}
      label={label}
      placeholder={placeholder}
      readOnly={readOnly}
      number={number}
      text={text}
      onInput={(props as ControlledTextFieldProps).onInput}
      data-test-id="textField"
    />
  ) : (
    <FormControl name={(props as UncontrolledTextFieldProps).name} rules={(props as UncontrolledTextFieldProps).rules} className={className}>
      <TextField label={label} placeholder={placeholder} readOnly={readOnly} number={number} text={currentText} onInput={handleInput} />
    </FormControl>
  );
};

export default TextFieldControl;
