/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Comment, SiteComment, UserComment } from "app/domain/comment";
import { EntityCategory } from "app/domain/entityCategory";
import CommentMapper from "app/service/comment/commentMapper";
import { CommentRequest } from "app/service/comment/commentRequest";
import SiteCommentMapper, { SiteCommentDTO } from "app/service/comment/site/siteCommentMapper";
import UserCommentMapper, { UserCommentDTO } from "app/service/comment/user/userCommentMapper";
import api, { NEW_API_VERSION } from "app/service/common/api";

const getQueryParams = (parentType: EntityCategory, parentId: string): any => {
  if (parentType === EntityCategory.ASSET) {
    return { asset_id: parentId };
  }
  if (parentType === EntityCategory.SYSTEM) {
    return { system_id: parentId };
  }
  if (parentType === EntityCategory.REQUIREMENT) {
    return { requirement_id: parentId };
  }
  return { user_id: "" };
};

class CommentService {
  static getComments = async (parentType: EntityCategory, parentId: string): Promise<Comment[]> => {
    const queryParams = getQueryParams(parentType, parentId);
    const commentDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/comments`,
        queryParams
      })
    ).data as CommentRequest[];

    return CommentMapper.fromDTO(commentDTOs, parentType, parentId);
  };

  static getSiteComments = async (siteId: string): Promise<SiteComment[]> => {
    const siteCommentDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/comments`,
        queryParams: { site_id: siteId }
      })
    ).data as SiteCommentDTO[];

    return SiteCommentMapper.fromDTO(siteCommentDTOs);
  };

  static getUserComments = async (): Promise<UserComment[]> => {
    const userCommentDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/comments`
      })
    ).data as UserCommentDTO[];

    return UserCommentMapper.fromDTO(userCommentDTOs);
  };

  static createComment = async (comment: Comment): Promise<Comment> => {
    const commentResponse = CommentMapper.toDTO(comment);

    const createdCommentDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/comments`,
        body: commentResponse
      })
    ).data as CommentRequest;

    return CommentMapper.fromDTO(createdCommentDTO, comment.parentType, comment.parentId);
  };

  static updateComment = async (comment: Comment): Promise<Comment> => {
    const commentResponse = CommentMapper.toDTO(comment);

    const updatedComment = (
      await api.put({
        id: commentResponse.id,
        path: `${NEW_API_VERSION}/comments`,
        body: commentResponse
      })
    ).data as CommentRequest;

    return CommentMapper.fromDTO(updatedComment, comment.parentType, comment.parentId);
  };
}

export default CommentService;
