import { PhotoStatus } from "./photoStatus";

class Photo {
  id: string;
  name: string;
  src: string;
  status: PhotoStatus;
  timestamp: Date;
  file?: File;

  constructor(id: string, name: string, src: string, status: PhotoStatus, timestamp: Date, file?: File) {
    this.id = id;
    this.name = name;
    this.src = src;
    this.status = status;
    this.timestamp = timestamp;
    this.file = file;
  }
}

export default Photo;
