import Site from "./site";

export interface ProjectProps {
  id: string;
  description: string;
  sites: {
    id: string;
    name: string;
  }[];
}

class Project {
  value: string;
  label: string;
  children: Site[];

  constructor(projectProps: ProjectProps) {
    this.value = projectProps.id;
    this.label = projectProps.description;
    this.children = projectProps.sites.map((site) => new Site({ id: site.id, name: site.name }));
  }
}

export default Project;
