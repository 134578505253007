/* eslint-disable no-nested-ternary */

import { Grid } from "@mui/material";
import { System } from "app/domain/system/index";
import { useI18n } from "app/locales/index";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import InputLabel from "ui/inputLabel/inputLabel";

interface SystemModelLabelsProps extends ComponentProps {
  system: System | undefined;
  selected: string[];
}

const StyledSystemModelLabels = styled.div`
  width: 100%;
`;

const SystemModelLabels: FC<SystemModelLabelsProps> = ({ system, selected }: SystemModelLabelsProps) => {
  const { getAttributeLabel } = useI18n();

  const displayUniformat = (): string => {
    return selected[0]
      ? `${selected[0].split("/")[0]}`
      : system && system.uniformat && system.uniformat.level4
      ? system.uniformat.level4.toString()
      : system && system.uniformat && system.uniformat.level3
      ? system.uniformat.level3
      : "";
  };

  return (
    <StyledSystemModelLabels>
      <Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InputLabel label="Uniformat" text={displayUniformat()} />
          </Grid>
          <Grid item xs={4}>
            <InputLabel label="Nom du système modèle" text={system && system.name ? system.name : ""} />
          </Grid>

          <Grid item xs={4}>
            <InputLabel label={getAttributeLabel("action_name")} text={system && system.actionType ? system.actionType : ""} />
          </Grid>
        </Grid>
      </Grid>
    </StyledSystemModelLabels>
  );
};

export default SystemModelLabels;
