import { SystemChildren } from "app/domain/systemChildren";

export type SystemChildrenDTO = {
  id: string;
  child_type: string;
  child_text_value?: string;
  children?: [];
  text_value_options: string[];
};

class SystemChildrenMapper {
  static fromDTO(systemChildrenDTO: SystemChildrenDTO): SystemChildren;
  static fromDTO(systemChildrenDTOs: SystemChildrenDTO[]): SystemChildren[];
  static fromDTO(systemChildrenDTOs: SystemChildrenDTO | SystemChildrenDTO[]): any {
    if (!Array.isArray(systemChildrenDTOs)) {
      return new SystemChildren({
        id: systemChildrenDTOs.id,
        childType: systemChildrenDTOs.child_type,
        childTextValue: systemChildrenDTOs.child_text_value,
        children: systemChildrenDTOs.children,
        textValueOptions: systemChildrenDTOs.text_value_options
      });
    }
    return systemChildrenDTOs.map((systemChildrenDTO: SystemChildrenDTO): SystemChildren => this.fromDTO(systemChildrenDTO));
  }

  static toDTO(systemChildren: SystemChildren): SystemChildrenDTO {
    return {
      id: systemChildren.id,
      child_type: systemChildren.childType,
      child_text_value: systemChildren.childTextValue,
      children: systemChildren.children,
      text_value_options: systemChildren.textValueOptions
    };
  }
}

export default SystemChildrenMapper;
