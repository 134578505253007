interface CostItemProps {
  unitCost: number;
  description: string;
  unitOfMeasure: string;
  id?: number;
  code?: string;
  className?: string;
}

class CostItem {
  unitCost: number;
  description: string;
  unitOfMeasure: string;
  id?: number;
  code?: string;
  className?: string;

  constructor(costItemProps: CostItemProps) {
    this.unitCost = costItemProps.unitCost;
    this.description = costItemProps.description;
    this.unitOfMeasure = costItemProps.unitOfMeasure;
    this.id = costItemProps.id;
    this.code = costItemProps.code;
    this.className = costItemProps.className;
  }
}

export default CostItem;
