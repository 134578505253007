import { Tab as MaterialTab, Tabs as MaterialTabs } from "@mui/material";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import styled, { css } from "styled-components";

import useTabs from "./useTabs";

const StyledTabs = styled(MaterialTabs)`
  padding-left: 40px;
  .MuiButtonBase-root {
    padding: 6px 12px;
  }
  .MuiTab-textColorPrimary {
    opacity: 0.7;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    text-transform: initial;
    font-size: 1rem;
    &.Mui-selected {
      opacity: 1;
      color: rgba(0, 0, 0, 0.95);
    }
  }
`;

export const tabsStyle = css`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: fit-content;

  .MuiTabs-root {
    position: relative;

    .MuiTabs-scroller {
      .MuiTab-root {
        min-width: fit-content;

        .MuiTab-wrapper {
          font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
          text-transform: initial;
          font-size: 1rem;
        }
      }

      .Mui-selected {
        font-weight: 700;

        .MuiTab-wrapper {
          color: rgba(0, 0, 0, 0.95);
        }
      }

      .MuiTabs-indicator {
        background-color: rgba(0, 0, 0, 0.95);
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
`;

// TODO: test that children are of type Tab or Tab[]
interface TabsProps extends ComponentProps {
  children: JSX.Element | Array<JSX.Element | boolean>;
  activeTabIndex?: number;
  onTabChange?: (newTabIndex: number) => void;
}

const createTabs = (tabs: JSX.Element | Array<JSX.Element | boolean>): JSX.Element[] | JSX.Element => {
  if (Array.isArray(tabs)) {
    const filteredTabs = tabs.filter((action) => typeof action !== "boolean") as JSX.Element[];

    return filteredTabs.map((tab) => <MaterialTab label={tab.props.label} key={tab.props.label} />);
  }
  return <MaterialTab label={tabs.props.label} />;
};

const getActiveTabPane = (tabs: JSX.Element | Array<JSX.Element | boolean>, activeTabIndex: number): JSX.Element | Array<JSX.Element | boolean> => {
  if (Array.isArray(tabs)) {
    const filteredTabs = tabs.filter((action) => typeof action !== "boolean") as JSX.Element[];

    return filteredTabs.filter((tab, index) => activeTabIndex === index);
  }
  return tabs;
};

// TODO: test whole component
const Tabs: FC<TabsProps> = ({ children, activeTabIndex, onTabChange, className }: TabsProps) => {
  const { activeTabIndex: internalActiveTabIndex, onTabChange: internalOnTabChange } = useTabs();

  const tabs = createTabs(children);
  const activeTabPane = getActiveTabPane(children, activeTabIndex || internalActiveTabIndex);

  const handleTabChange = (event: React.ChangeEvent<unknown>, newTabIndex: number): void => {
    if (onTabChange) {
      onTabChange(newTabIndex);
    } else {
      internalOnTabChange(newTabIndex);
    }
  };

  return (
    <div className={className}>
      <StyledTabs value={activeTabIndex || internalActiveTabIndex} onChange={handleTabChange} variant="scrollable" data-test-id="tabsContainer">
        {tabs}
      </StyledTabs>
      {activeTabPane}
    </div>
  );
};

export default createStyledComponent(Tabs, tabsStyle);
