import AuditProgress from "app/domain/asset/auditProgress";
import { useCallback, useEffect, useMemo, useState } from "react";

type AuditProgressHook = {
  currentAuditProgress: any;
  onUpdate: (fieldName: string, fieldValue: any) => void;
};

const useAuditProgress = (initialAuditProgress: AuditProgress): AuditProgressHook => {
  const [currentAuditProgress, setCurrentAuditProgress] = useState({
    ...initialAuditProgress
  });

  const onUpdate = useCallback((fieldName: string, fieldValue: any) => {
    setCurrentAuditProgress((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue
    }));
  }, []);

  useEffect(() => {
    if (initialAuditProgress) {
      Object.entries(initialAuditProgress).forEach(([key, value]) => {
        onUpdate(key, value);
      });
    }
  }, [initialAuditProgress, onUpdate]);

  return useMemo(
    () => ({
      currentAuditProgress,
      onUpdate
    }),
    [currentAuditProgress, onUpdate]
  );
};

export default useAuditProgress;
