import React, { useEffect, useRef, useState } from "react";

type UseOpenNewTab = {
  newTab: boolean;
};

const useOpenNewTab = (): UseOpenNewTab => {
  const [newTab, setNewTab] = useState(false);

  const handleKeyDown: any = (ev: any) => {
    if (ev.key === "Control" || ev.key === "Meta") {
      setNewTab(true);
    }
  };
  const handleKeyUp: any = (ev: any) => {
    if (ev.key === "Control" || ev.key === "Meta") {
      setNewTab(false);
    }
  };

  useEffect(() => {
    const listenerDown: any = document.addEventListener("keydown", handleKeyDown);
    const listenerUp: any = document.addEventListener("keyup", handleKeyUp);
    return (): any => {
      document.removeEventListener("keydown", listenerDown);
      document.removeEventListener("keyup", listenerUp);
    };
  }, []);

  return { newTab };
};

export default useOpenNewTab;
