import Option from "app/domain/common/option";

interface RequirementProblematicOptionProps extends Option {
  parent: string;
}

class RequirementProblematicOption extends Option {
  parent: string;

  constructor(requirementProblematicOptionProps: RequirementProblematicOptionProps) {
    super({
      id: requirementProblematicOptionProps.id,
      text: requirementProblematicOptionProps.text,
      value: requirementProblematicOptionProps.value
    });
    this.parent = requirementProblematicOptionProps.parent;
  }
}

export default RequirementProblematicOption;
