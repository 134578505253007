import SearchItem from "app/domain/search/searchItem";

export type SearchItemDTO = {
  text: string;
  location: string;
};

class SearchMapper {
  static fromDTO(searchItemDTOs: SearchItemDTO[]): SearchItem[] {
    return searchItemDTOs.map((searchItemDTO: SearchItemDTO): SearchItem => new SearchItem(searchItemDTO.text, searchItemDTO.location));
  }

  static toDTO(searchItem: SearchItem): SearchItemDTO;
  static toDTO(searchItems: SearchItem[]): SearchItemDTO[];
  static toDTO(searchItems: SearchItem | SearchItem[]): any {
    if (!Array.isArray(searchItems)) {
      return { text: searchItems.text };
    }
    return searchItems.map((searchItem: SearchItem): SearchItemDTO => this.toDTO(searchItem));
  }
}

export default SearchMapper;
