import { createAction } from "@reduxjs/toolkit";
import { Project } from "app/domain/project";

import { ProjectActionTypes } from "./types";

export const getProjectsAction = createAction(ProjectActionTypes.GET_PROJECTS);

export const getProjectsRequestAction = createAction(ProjectActionTypes.GET_PROJECTS_REQUEST);

export const getProjectsSuccessAction = createAction(ProjectActionTypes.GET_PROJECTS_SUCCESS, (projects: Project[]) => ({
  payload: {
    projects
  }
}));

export const getProjectsErrorAction = createAction(ProjectActionTypes.GET_PROJECTS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const setCurrentProjectAction = createAction(ProjectActionTypes.SET_CURRENT_PROJECT, (projectId: string) => ({
  payload: {
    projectId
  }
}));

export const resetAction = createAction(ProjectActionTypes.RESET_PROJECT);
