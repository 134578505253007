import Option from "app/domain/common/option";
import { Requirement } from "app/domain/requirement";
import { useI18n } from "app/locales";
import { getRequirementOptions } from "app/store/requirement/selectors";
import { moneyFormatter } from "app/utils/formatter";
import { FormFieldGroup } from "app/view/common/form";
import InfoBubble from "app/view/system/form/systemForm/formSections/InfoBubble";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { forwardRef, RefObject, useMemo } from "react";
import { useSelector } from "react-redux";
import { Form as SemanticForm } from "semantic-ui-react";

interface MonetaryInformationProps extends ComponentProps {
  requirement: Requirement | undefined;
  projectId?: string | undefined;
}

const calculateIndirectCosts = (projectId: string | undefined): number => (projectId === "PL-01347" ? 1.0 : 1.45);

const MonetaryInformation = forwardRef(({ requirement, projectId }: MonetaryInformationProps, ref): JSX.Element => {
  const { getAttributeLabel } = useI18n();

  const requirementOptions = useSelector(getRequirementOptions);

  const removalValue = useMemo(() => {
    return requirementOptions && requirementOptions.removalOptions && requirement && requirement.removal
      ? parseFloat((requirementOptions.removalOptions.find((option: Option) => option.id === (requirement as Requirement).removal) as Option).value)
      : 0;
  }, [requirementOptions, requirement]);

  const adjustmentFactor = useMemo(() => {
    const complexity = requirement && requirement.complexity ? requirement.complexity : 0;
    const height = requirement && requirement.height ? requirement.height : 0;
    const patrimonyFactor = requirement && requirement.patrimony_factor ? requirement.patrimony_factor : 0;

    return calculateIndirectCosts(projectId) * (complexity + height + 1) * (removalValue + patrimonyFactor + 1);
  }, [requirement, removalValue, projectId]);

  const totalCostWithFees = useMemo(() => {
    const totalCost = requirement ? requirement.totalCost : 0;

    return totalCost * adjustmentFactor;
  }, [requirement, adjustmentFactor]);

  return (
    <div>
      <h3 ref={ref as RefObject<any>}>
        <span>4.</span> Informations monétaires
      </h3>
      <FormFieldGroup>
        <SemanticForm.Input
          label={<InfoBubble label="Frais indirects" infoText="Varient selon le projet" />}
          placeholder="Frais indirects"
          value={calculateIndirectCosts(projectId)}
          readOnly
          data-test-id="indirectCostsField"
        />
        <SemanticForm.Input
          label={<InfoBubble label="Facteur total d'ajustement" infoText="(Complexité + Hauteur + 1) x (Enlèvement + Facteur patrimonial + 1) x Frais indirects" />}
          placeholder="Facteur d'ajustement total"
          name="adjustment_factor"
          value={adjustmentFactor.toFixed(2)}
          readOnly
          data-test-id="adjustmentFactorField"
        />
        <SemanticForm.Input
          transparent
          label={getAttributeLabel("total_cost")}
          placeholder={getAttributeLabel("total_cost")}
          value={requirement ? moneyFormatter.format(requirement.totalCost) : ""}
          readOnly
          data-test-id="totalCostField"
        />
        <SemanticForm.Input
          label={<InfoBubble label="Coût total avec frais" infoText="Coût total x Facteur d'ajustement total" />}
          value={moneyFormatter.format(totalCostWithFees)}
          readOnly
          data-test-id="totalCostWithFeesField"
        />
      </FormFieldGroup>
    </div>
  );
});

MonetaryInformation.displayName = "MonetaryInformation";

export default MonetaryInformation;
