import { State } from "app/domain/common";
import { colors } from "ui/colors";

export const getStateColor = (state: State): string => {
  if (state === State.IN_PROGRESS) {
    return "orange";
  }
  if (state === State.COMPLETED) {
    return colors.ACCENT_BLUE_DARK;
  }
  if (state === State.VERIFIED) {
    return "green";
  }
  return "";
};
