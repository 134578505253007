import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";

import { cardGridStyle } from "./style";

interface CardGridProps extends ComponentProps {
  children: JSX.Element[];
}

const CardGrid: FC<CardGridProps> = ({ children, className }: CardGridProps) => <div className={className}>{children}</div>;

export default createStyledComponent(CardGrid, cardGridStyle);
