import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

const dropdownItem = css``;

export interface DropdownItemProps extends ComponentProps {
  value: string;
  label: string;
}

const DropdownItem: FC<DropdownItemProps> = ({ value, label, className }: DropdownItemProps) => (
  <li className={className} key={value}>
    {label}
  </li>
);

export default createStyledComponent(DropdownItem, dropdownItem);
