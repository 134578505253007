import { RequiredParamsError } from "app/domain/common";

export interface RequiredParamsErrorDTO {
  params: string[];
}

class RequiredParamsErrorMapper {
  static fromDTO(requiredParamsErrorDTOs: RequiredParamsErrorDTO[]): RequiredParamsError[] {
    return requiredParamsErrorDTOs.map(
      (requiredParamsErrorDTO: RequiredParamsErrorDTO) =>
        new RequiredParamsError({
          params: requiredParamsErrorDTO.params
        })
    );
  }
}

export default RequiredParamsErrorMapper;
