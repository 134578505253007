import Option from "app/domain/common/option";

import RequirementItemOption from "./requirementItemOption";
import RequirementProblematicOption from "./requirementProblematicOption";

interface RequirementOptionsProps {
  activityOptions: Option[];
  categoryOptions: Option[];
  complexityOptions: Option[];
  heightOptions: Option[];
  localisationOptions: Option[];
  patrimonyFactorOptions: Option[];
  priorityOptions: Option[];
  problematicOptions: RequirementProblematicOption[];
  removalOptions: Option[];
  requirementItemOptions: RequirementItemOption[];
  auditorOptions: Option[];
}

class RequirementOptions {
  activityOptions: Option[];
  categoryOptions: Option[];
  complexityOptions: Option[];
  heightOptions: Option[];
  localisationOptions: Option[];
  patrimonyFactorOptions: Option[];
  priorityOptions: Option[];
  problematicOptions: RequirementProblematicOption[];
  removalOptions: Option[];
  requirementItemOptions: RequirementItemOption[];
  auditorOptions: Option[];

  constructor(requirementOptionsProps: RequirementOptionsProps) {
    this.activityOptions = requirementOptionsProps.activityOptions;
    this.categoryOptions = requirementOptionsProps.categoryOptions;
    this.complexityOptions = requirementOptionsProps.complexityOptions;
    this.heightOptions = requirementOptionsProps.heightOptions;
    this.localisationOptions = requirementOptionsProps.localisationOptions;
    this.patrimonyFactorOptions = requirementOptionsProps.patrimonyFactorOptions;
    this.priorityOptions = requirementOptionsProps.priorityOptions;
    this.problematicOptions = requirementOptionsProps.problematicOptions;
    this.removalOptions = requirementOptionsProps.removalOptions;
    this.requirementItemOptions = requirementOptionsProps.requirementItemOptions;
    this.auditorOptions = requirementOptionsProps.auditorOptions;
  }
}

export default RequirementOptions;
