/* eslint-disable react-hooks/exhaustive-deps */

import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect } from "react";
import { css } from "styled-components";

import useValuesSearchbar from "./useValuesSearchbar";
import ValuesSearchbar from "./valuesSearchbar";
import ValuesSelector from "./valuesSelector";

const filterPanelModalContentStyle = css`
  padding: 20px;
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  cursor: default;
  outline: none;
`;

interface FilterPanelModalContentProps extends ComponentProps {
  values: string[];
  selectedValues: string[];
  onSelect: (value: string) => void;
  isChecked: boolean;
  isIndeterminate: boolean;
  onFilter?: (values: string[]) => void;
  onSelectAll?: () => void;
}

const FilterPanelModalContent: FC<FilterPanelModalContentProps> = ({
  values,
  selectedValues,
  isChecked,
  isIndeterminate,
  onSelect,
  onSelectAll,
  onFilter,
  className
}: FilterPanelModalContentProps) => {
  const { onSearch, searchedValue, results } = useValuesSearchbar(values);

  useEffect(() => {
    if (onFilter) {
      onFilter(results);
    }
  }, [results]);

  return (
    <div className={className}>
      {onFilter && <ValuesSearchbar searchedValue={searchedValue} onSearch={onSearch} data-test-id="valuesSearchbar" />}
      <ValuesSelector
        values={values}
        selectedValues={selectedValues}
        searchedValue={searchedValue}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        data-test-id="valuesSelector"
        isChecked={isChecked}
        isIndeterminate={isIndeterminate}
      />
    </div>
  );
};

export default createStyledComponent(memo(FilterPanelModalContent), filterPanelModalContentStyle);
