import { Local } from "app/domain/local/index";
import { Params, useRouter } from "app/routing/routerProvider";
import { getSelectedValues } from "app/store/filter/selectedValue/selectors";
import { getLocalsAction } from "app/store/local/actions";
import { getLocals } from "app/store/local/selectors";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { Toolbar } from "app/view/common/toolbar";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { AddButton } from "ui/button/actions";
import { useTable } from "ui/table/index";
import { RowData } from "ui/table/types";

import { createRoomRowData } from "./roomData";
import RoomTable from "./roomTable";

const roomListingStyle = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ADD_SYSTEM = "Ajouter un système";
const ADD_REQUIREMENT = "Ajouter une exigence";
const ASSET_COMPONENT_URL = "/newcomponent";
const ASSET_REQUIREMENT_URL = "/newrequirement";

interface RoomListingProps extends ComponentProps {
  assetId: string | undefined;
}

const RoomListing: FC<RoomListingProps> = ({ assetId, className }: RoomListingProps) => {
  const { getParam, goTo } = useRouter();
  const selectedValues = useSelector(getSelectedValues);
  const rooms = useSelector(getLocals);
  const projectId = getParam(Params.PROJECT);
  const siteId = getParam(Params.SITE);

  const { onDismiss } = useErrorMessage();

  const dispatch = useDispatch();

  const data = useMemo(() => rooms.map((room: Local) => createRoomRowData(room)), [rooms]) as RowData[];

  const { onChange: onDataChange, rowData: currentData, rowSelectionProps } = useTable(data, selectedValues);

  useEffect(() => {
    if (assetId) {
      dispatch(getLocalsAction(assetId));
    }

    return (): void => {
      onDismiss();
    };
  }, [dispatch, onDismiss, assetId]);

  const redirectToSystemCreationPage = (): void => {
    goTo(ASSET_COMPONENT_URL, {
      projectId,
      siteId,
      assetId,
      selectedRoomsId: rowSelectionProps.selectedRowKeys,
      tabIndex: 6
    });
  };

  const redirectToRequirementCreationPage = (): void => {
    goTo(ASSET_REQUIREMENT_URL, {
      projectId,
      siteId,
      assetId,
      selectedRoomsId: rowSelectionProps.selectedRowKeys
    });
  };

  const actions = [
    <AddButton onClick={redirectToSystemCreationPage} label={ADD_SYSTEM} key={0} data-test-id="createSystemButton" />,
    <AddButton onClick={redirectToRequirementCreationPage} label={ADD_REQUIREMENT} key={1} data-test-id="createRequirementButton" />
  ];

  return (
    <div className={className}>
      <Toolbar actions={actions} data-test-id="toolbar" />
      <RoomTable selectedValues={selectedValues} onDataChange={onDataChange} currentData={currentData} data={data} rowSelectionProps={rowSelectionProps} data-test-id="roomTable" />
    </div>
  );
};

export default createStyledComponent(memo(RoomListing), roomListingStyle);
