import { resetAction as resetAssetAction } from "app/store/asset/actions";
import { getErrorMessage as getAssetErrorMessageSelector } from "app/store/asset/selectors";
import { resetAction as resetAuditProgressAction } from "app/store/auditProgress/actions";
import { getErrorMessage as getAuditProgressErrorMessageSelector } from "app/store/auditProgress/selectors";
import { resetAction as resetCostItemAction } from "app/store/costItem/actions";
import { getErrorMessage as getCostItemErrorMessageSelector } from "app/store/costItem/selectors";
import { resetAction as resetLocalAction } from "app/store/local/actions";
import { getErrorMessage as getLocalErrorMessageSelector } from "app/store/local/selectors";
import { resetAction as resetPhotoAction } from "app/store/photo/actions";
import { getErrorMessage as getPhotoErrorMessageSelector } from "app/store/photo/selectors";
import { resetAction as resetProjectAction } from "app/store/project/actions";
import { getErrorMessage as getProjectErrorMessageSelector } from "app/store/project/selectors";
import { resetAction as resetRequirementAction } from "app/store/requirement/actions";
import { getErrorMessage as getRequirementErrorMessageSelector } from "app/store/requirement/selectors";
import { resetAction as resetSiteAction } from "app/store/site/actions";
import { getErrorMessage as getSiteErrorMessageSelector } from "app/store/site/selectors";
import { resetAction as resetSystemAction } from "app/store/system/actions";
import { getErrorMessage as getSystemErrorMessageSelector } from "app/store/system/selectors";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface ErrorMessageProviderState {
  errorMessage: string;
  onUpdate: (errorMessage: string) => void;
  onDismiss: () => void;
}

const ErrorMessageContext = createContext({
  errorMessage: "",
  onUpdate: (errorMessage: string) => {},
  onDismiss: () => {}
});

export const useErrorMessage = (): ErrorMessageProviderState => useContext(ErrorMessageContext);

const ErrorMessageProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const projectErrorMessage = useSelector(getProjectErrorMessageSelector);
  const siteErrorMessage = useSelector(getSiteErrorMessageSelector);
  const assetErrorMessage = useSelector(getAssetErrorMessageSelector);
  const auditProgressErrorMessage = useSelector(getAuditProgressErrorMessageSelector);
  const systemErrorMessage = useSelector(getSystemErrorMessageSelector);
  const requirementErrorMessage = useSelector(getRequirementErrorMessageSelector);
  const costItemErrorMessage = useSelector(getCostItemErrorMessageSelector);
  const photoErrorMessage = useSelector(getPhotoErrorMessageSelector);
  const localErrorMessage = useSelector(getLocalErrorMessageSelector);

  const [currentErrorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  const getErrorMessage = useCallback((errorMessage?: string | string[]) => {
    if (errorMessage) {
      if (Array.isArray(errorMessage)) {
        for (let index = 0; index < errorMessage.length; index += 1) {
          if (errorMessage[index] !== "") {
            return errorMessage[index];
          }
        }
        return "";
      }
      return errorMessage as string;
    }
    return "";
  }, []);

  const errorMessage = useMemo(
    () =>
      getErrorMessage([
        currentErrorMessage,
        projectErrorMessage,
        siteErrorMessage,
        assetErrorMessage,
        auditProgressErrorMessage,
        systemErrorMessage,
        requirementErrorMessage,
        costItemErrorMessage,
        photoErrorMessage,
        localErrorMessage
      ]),
    [
      getErrorMessage,
      currentErrorMessage,
      projectErrorMessage,
      siteErrorMessage,
      assetErrorMessage,
      auditProgressErrorMessage,
      systemErrorMessage,
      requirementErrorMessage,
      costItemErrorMessage,
      photoErrorMessage,
      localErrorMessage
    ]
  );

  const onDismiss = useCallback(() => {
    setErrorMessage("");
    dispatch(resetProjectAction());
    dispatch(resetSiteAction());
    dispatch(resetAssetAction());
    dispatch(resetAuditProgressAction());
    dispatch(resetSystemAction());
    dispatch(resetRequirementAction());
    dispatch(resetCostItemAction());
    dispatch(resetPhotoAction());
    dispatch(resetLocalAction());
  }, [dispatch]);

  const onUpdate = useCallback((message: string) => {
    setErrorMessage(message);
  }, []);

  return <ErrorMessageContext.Provider value={{ errorMessage, onDismiss, onUpdate }}>{children}</ErrorMessageContext.Provider>;
};

export default ErrorMessageProvider;
