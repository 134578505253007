import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

const inputModelStyle = css`
  .ui.input > input {
    min-width: 800px;
  }
`;

interface InputModelProps extends ComponentProps {
  children: any;
}

const InputModel: FC<InputModelProps> = ({ children, className }: InputModelProps) => <div className={className}>{children}</div>;

export default createStyledComponent(InputModel, inputModelStyle);
