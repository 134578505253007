import SearchItem from "app/domain/search/searchItem";
import { State } from "app/store/state";
import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { css } from "styled-components";
import { colors } from "ui/colors";

import SearchResult from "./searchResult";

const searchResultContainerStyle = css`
  padding: 0;
  margin: 0;
  cursor: pointer;
  background-color: ${colors.SECONDARY_BLACK};
  border-left: 2px solid ${colors.ACCENT_BLUE};
  transition: opacity 0.3s ease;
  position: absolute;
  width: 177px;
  left: 0;
`;

interface SearchResultContainerProps extends ComponentProps {
  isVisible: boolean;
  onSelect: () => void;
  setIsVisible: (isVisible: boolean) => void;
}

const useOutsideAlerter = (ref: any, callBack: (isVisible: boolean) => void): void => {
  useEffect(() => {
    const handleClickOutside = (event: any): void => {
      if (ref.current && !ref.current.contains(event.target)) {
        callBack(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

const SearchResultContainer: FC<SearchResultContainerProps> = ({ onSelect, className, isVisible, setIsVisible }: SearchResultContainerProps) => {
  const searchResults = useSelector((state: State): SearchItem[] => state.search.searchResults);
  const wrapperRef = useRef(null);
  const handleSearchResultClick = useCallback(() => {
    onSelect();
  }, [onSelect]);
  useOutsideAlerter(wrapperRef, setIsVisible);
  return isVisible ? (
    <ul ref={wrapperRef} className={className} data-test-id="searchResultContainer">
      {searchResults
        ? searchResults.map((searchResult) => <SearchResult item={searchResult} onClick={handleSearchResultClick} key={searchResult.location} data-test-id="searchResult" />)
        : null}
    </ul>
  ) : null;
};

export default createStyledComponent(SearchResultContainer, searchResultContainerStyle);
