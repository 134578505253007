import { RequirementProblematicOption, RequirementsOptions } from "app/domain/requirement/options";
import OptionMapper, { OptionDTO } from "app/service/common/optionMapper";

import RequirementItemOptionMapper, { RequirementItemOptionDTO } from "./requirementItemOptionMapper";
import RequirementProblematicOptionMapper from "./requirementProblematicOptionMapper";

export type RequirementOptionsDTO = {
  list_activity_options: OptionDTO[];
  list_category_options: OptionDTO[];
  list_complexity_options: OptionDTO[];
  list_height_options: OptionDTO[];
  list_localisation_options: OptionDTO[];
  list_patrimony_factor_options: OptionDTO[];
  list_priority_options: OptionDTO[];
  list_problematic_options: RequirementProblematicOption[];
  list_removal_options: OptionDTO[];
  list_requirement_item_options: RequirementItemOptionDTO[];
  list_auditor_options: OptionDTO[];
};

class RequirementOptionsMapper {
  static fromDTO(requirementOptionsDTO: RequirementOptionsDTO): RequirementsOptions {
    return new RequirementsOptions({
      activityOptions: OptionMapper.fromDTO(requirementOptionsDTO.list_activity_options),
      categoryOptions: OptionMapper.fromDTO(requirementOptionsDTO.list_category_options),
      complexityOptions: OptionMapper.fromDTO(requirementOptionsDTO.list_complexity_options),
      heightOptions: OptionMapper.fromDTO(requirementOptionsDTO.list_height_options),
      localisationOptions: OptionMapper.fromDTO(requirementOptionsDTO.list_localisation_options),
      patrimonyFactorOptions: OptionMapper.fromDTO(requirementOptionsDTO.list_patrimony_factor_options),
      priorityOptions: OptionMapper.fromDTO(requirementOptionsDTO.list_priority_options),
      problematicOptions: RequirementProblematicOptionMapper.fromDTO(requirementOptionsDTO.list_problematic_options),
      removalOptions: OptionMapper.fromDTO(requirementOptionsDTO.list_removal_options),
      requirementItemOptions: RequirementItemOptionMapper.fromDTO(requirementOptionsDTO.list_requirement_item_options),
      auditorOptions: OptionMapper.fromDTO(requirementOptionsDTO.list_auditor_options)
    });
  }
}

export default RequirementOptionsMapper;
