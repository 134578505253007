/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";
import { System } from "app/domain/system";

import {
  addSystemAction,
  addSystemErrorAction,
  addSystemRequestAction,
  addSystemSuccessAction,
  deleteSystemAction,
  deleteSystemErrorAction,
  deleteSystemRequestAction,
  deleteSystemSuccessAction,
  generateSystemCopiesAction,
  generateSystemCopiesErrorAction,
  generateSystemCopiesRequestAction,
  generateSystemCopiesSuccessAction,
  generateSystemCopiesToDestinationsErrorAction,
  generateSystemCopiesToDestinationsRequestAction,
  generateSystemCopiesToDestinationsSuccessAction,
  generateSystemLabelsAction,
  generateSystemLabelsErrorAction,
  generateSystemLabelsRequestAction,
  generateSystemLabelsSuccessAction,
  getSystemAction,
  getSystemErrorAction,
  getSystemOptionsAction,
  getSystemOptionsErrorAction,
  getSystemOptionsRequestAction,
  getSystemOptionsSuccessAction,
  getSystemRequestAction,
  getSystemsAction,
  getSystemsErrorAction,
  getSystemsRequestAction,
  getSystemsSuccessAction,
  getSystemSuccessAction,
  resetAction,
  setFilteredSystemsAction,
  setShouldRedirectToSystemsPageAction,
  updateSystemAction,
  updateSystemErrorAction,
  updateSystemRequestAction,
  updateSystemSuccessAction
} from "./actions";
import { initialSystemState, SystemState } from "./state";
import {
  AddSystemErrorAction,
  AddSystemSuccessAction,
  DeleteSystemErrorAction,
  DeleteSystemSuccessAction,
  GenerateSystemCopiesErrorAction,
  GenerateSystemCopiesSuccessAction,
  GenerateSystemLabelsErrorAction,
  GenerateSystemLabelsSuccessAction,
  GetSystemErrorAction,
  GetSystemOptionsErrorAction,
  GetSystemOptionsSuccessAction,
  GetSystemsErrorAction,
  GetSystemsSuccessAction,
  GetSystemSuccessAction,
  SetFilteredSystemsAction,
  SetRedirectToSystemsPageAction,
  UpdateSystemErrorAction,
  UpdateSystemSuccessAction
} from "./types";

const replaceIn = (systems: System[], newSystem: System): System[] => {
  const indexOfSystemToReplace = systems.findIndex((system: System) => system.id === newSystem.id);
  systems[indexOfSystemToReplace] = newSystem;
  return systems;
};

const getSystem = (state: SystemState): void => {
  state.errorMessage = "";
  state.newSystemId = "";
  state.shouldRedirectToModifySystemPage = false;
};

const getSystemRequest = (state: SystemState): void => {
  state.isLoading = true;
};

const getSystemSuccess = (state: SystemState, action: GetSystemSuccessAction): void => {
  state.isLoading = false;
  state.currentSystem = action.payload.system;
};

const getSystemError = (state: SystemState, action: GetSystemErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const getSystems = (state: SystemState): void => {
  state.errorMessage = "";
  state.newSystemId = "";
  state.shouldRedirectToModifySystemPage = false;
};

const getSystemsRequest = (state: SystemState): void => {
  state.isLoading = true;
};

const getSystemsSuccess = (state: SystemState, action: GetSystemsSuccessAction): void => {
  state.isLoading = false;
  state.systems = action.payload.systems;
  state.shouldRedirectToComponentsPage = false;
};

const getSystemsError = (state: SystemState, action: GetSystemsErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const getSystemOptions = (state: SystemState): void => {
  state.errorMessage = "";
};

const getSystemOptionsRequest = (state: SystemState): void => {
  state.isFetchingOptions = true;
};

const getSystemOptionsSuccess = (state: SystemState, action: GetSystemOptionsSuccessAction): void => {
  state.isFetchingOptions = false;
  state.optionsLists = action.payload.options;
};

const getSystemOptionsError = (state: SystemState, action: GetSystemOptionsErrorAction): void => {
  state.isFetchingOptions = false;
  state.errorMessage = action.payload.errorMessage;
};

const addSystem = (state: SystemState): void => {
  state.errorMessage = "";
  state.newSystemId = "";
};

const addSystemRequest = (state: SystemState): void => {
  state.isLoading = true;
};

const addSystemSuccess = (state: SystemState, action: AddSystemSuccessAction): void => {
  state.isLoading = false;
  state.systems = [...state.systems, action.payload.system];
  state.newSystemId = action.payload.system.id;
  state.shouldRedirectToComponentsPage = action.payload.isClosing;
  state.shouldRedirectToModifySystemPage = !action.payload.isClosing;
};

const addSystemError = (state: SystemState, action: AddSystemErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const updateSystem = (state: SystemState): void => {
  state.errorMessage = "";
};

const updateSystemRequest = (state: SystemState): void => {
  state.isLoading = true;
};

const updateSystemSuccess = (state: SystemState, action: UpdateSystemSuccessAction): void => {
  state.isLoading = false;
  state.currentSystem = action.payload.system;
  state.systems = replaceIn(state.systems, action.payload.system);
  state.shouldRedirectToComponentsPage = action.payload.isClosing;
};

const updateSystemError = (state: SystemState, action: UpdateSystemErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const deleteSystem = (state: SystemState): void => {
  state.errorMessage = "";
};

const deleteSystemRequest = (state: SystemState): void => {
  state.isLoading = true;
};

const deleteSystemSuccess = (state: SystemState, action: DeleteSystemSuccessAction): void => {
  state.isLoading = false;
  state.currentSystem = undefined;
  state.systems = state.systems.filter((system: System) => system.id !== action.payload.systemId);
  state.shouldRedirectToComponentsPage = true;
};

const deleteSystemError = (state: SystemState, action: DeleteSystemErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const generateSystemLabels = (state: SystemState): void => {
  state.errorMessage = "";
};

const generateSystemLabelsRequest = (state: SystemState): void => {
  state.isLoading = true;
};

const generateSystemLabelsSuccess = (state: SystemState, action: GenerateSystemLabelsSuccessAction): void => {
  state.isLoading = false;
  state.systems = replaceIn(state.systems, action.payload.system);
  state.currentSystem = action.payload.system;
};

const generateSystemLabelsError = (state: SystemState, action: GenerateSystemLabelsErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const generateSystemCopies = (state: SystemState): void => {
  state.errorMessage = "";
};

const generateSystemCopiesRequest = (state: SystemState): void => {
  state.isLoading = true;
};

const generateSystemCopiesSuccess = (state: SystemState, action: GenerateSystemCopiesSuccessAction): void => {
  const systemsToAdd = action.payload.systemCopies.filter((systemCopy: System) => systemCopy.assetId === action.payload.parentId);

  state.isLoading = false;
  state.systems = [...state.systems, ...systemsToAdd];
  state.shouldRedirectToComponentsPage = true;
};

const generateSystemCopiesError = (state: SystemState, action: GenerateSystemCopiesErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const setFilteredSystems = (state: SystemState, action: SetFilteredSystemsAction): void => {
  const systemIds = [...action.payload.systemIds];
  const filteredSystems = [];

  for (let index = 0; index < systemIds.length; index += 1) {
    const filteredSystem = state.systems.filter((system: System) => system.id === systemIds[index])[0];

    filteredSystems.push(filteredSystem);
  }

  state.filteredSystems = [...filteredSystems];
};

const setShouldRedirectToSystemsPage = (state: SystemState, action: SetRedirectToSystemsPageAction): void => {
  state.shouldRedirectToComponentsPage = action.payload.value;
};

const reset = (state: SystemState): void => {
  state.errorMessage = "";
};

const generateSystemCopiesToDestinationRequest = (state: SystemState): void => {
  state.isLoading = true;
};

const generateSystemCopiesToDestinationSuccess = (state: SystemState): void => {
  state.isLoading = false;
};

const generateSystemCopiesToDestinationError = (state: SystemState): void => {
  state.isLoading = false;
};

const systemReducer = createReducer(initialSystemState, {
  [getSystemAction.type]: (state) => getSystem(state),
  [getSystemRequestAction.type]: (state) => getSystemRequest(state),
  [getSystemSuccessAction.type]: (state, action) => getSystemSuccess(state, action),
  [getSystemErrorAction.type]: (state, action) => getSystemError(state, action),
  [getSystemsAction.type]: (state) => getSystems(state),
  [getSystemsRequestAction.type]: (state) => getSystemsRequest(state),
  [getSystemsSuccessAction.type]: (state, action) => getSystemsSuccess(state, action),
  [getSystemsErrorAction.type]: (state, action) => getSystemsError(state, action),
  [getSystemOptionsAction.type]: (state) => getSystemOptions(state),
  [getSystemOptionsRequestAction.type]: (state) => getSystemOptionsRequest(state),
  [getSystemOptionsSuccessAction.type]: (state, action) => getSystemOptionsSuccess(state, action),
  [getSystemOptionsErrorAction.type]: (state, action) => getSystemOptionsError(state, action),
  [addSystemAction.type]: (state) => addSystem(state),
  [addSystemRequestAction.type]: (state) => addSystemRequest(state),
  [addSystemSuccessAction.type]: (state, action) => addSystemSuccess(state, action),
  [addSystemErrorAction.type]: (state, action) => addSystemError(state, action),
  [updateSystemAction.type]: (state) => updateSystem(state),
  [updateSystemRequestAction.type]: (state) => updateSystemRequest(state),
  [updateSystemSuccessAction.type]: (state, action) => updateSystemSuccess(state, action),
  [updateSystemErrorAction.type]: (state, action) => updateSystemError(state, action),
  [deleteSystemAction.type]: (state) => deleteSystem(state),
  [deleteSystemRequestAction.type]: (state) => deleteSystemRequest(state),
  [deleteSystemSuccessAction.type]: (state, action) => deleteSystemSuccess(state, action),
  [deleteSystemErrorAction.type]: (state, action) => deleteSystemError(state, action),
  [generateSystemLabelsAction.type]: (state) => generateSystemLabels(state),
  [generateSystemLabelsRequestAction.type]: (state) => generateSystemLabelsRequest(state),
  [generateSystemLabelsSuccessAction.type]: (state, action) => generateSystemLabelsSuccess(state, action),
  [generateSystemLabelsErrorAction.type]: (state, action) => generateSystemLabelsError(state, action),
  [generateSystemCopiesAction.type]: (state) => generateSystemCopies(state),
  [generateSystemCopiesRequestAction.type]: (state) => generateSystemCopiesRequest(state),
  [generateSystemCopiesSuccessAction.type]: (state, action) => generateSystemCopiesSuccess(state, action),
  [generateSystemCopiesErrorAction.type]: (state, action) => generateSystemCopiesError(state, action),
  [generateSystemCopiesToDestinationsRequestAction.type]: (state) => generateSystemCopiesToDestinationRequest(state),
  [generateSystemCopiesToDestinationsSuccessAction.type]: (state) => generateSystemCopiesToDestinationSuccess(state),
  [generateSystemCopiesToDestinationsErrorAction.type]: (state) => generateSystemCopiesToDestinationError(state),
  [setFilteredSystemsAction.type]: (state, action) => setFilteredSystems(state, action),
  [setShouldRedirectToSystemsPageAction.type]: (state, action) => setShouldRedirectToSystemsPage(state, action),
  [resetAction.type]: (state) => reset(state)
});

export default systemReducer;
