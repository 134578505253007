/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useEffect, useRef } from "react";

const usePrevious = (value: any): any => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
