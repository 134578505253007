import React, { FC } from "react";

import Banner from "./banner";

interface StagingBannerProps {
  isVisible: boolean;
}

const StagingBanner: FC<StagingBannerProps> = ({ isVisible }: StagingBannerProps) => <Banner isVisible={isVisible} text="STAGING" color="red" />;

export default StagingBanner;
