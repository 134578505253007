import { SiteComment } from "app/domain/comment/index";

export type SiteCommentDTO = {
  comment_id: number;
  parent_category: string;
  project_id: string;
  site_id: string;
  asset_id: string;
  parent_id: string;
  asset_number: string;
  parent_name: string;
  message: string;
  author: string;
  is_resolved: boolean;
  created_at: string;
};

class SiteCommentMapper {
  static fromDTO(siteCommentDTO: SiteCommentDTO): SiteComment;
  static fromDTO(siteCommentDTOs: SiteCommentDTO[]): SiteComment[];
  static fromDTO(siteCommentDTOs: SiteCommentDTO | SiteCommentDTO[]): any {
    if (!Array.isArray(siteCommentDTOs)) {
      return new SiteComment({
        commentId: siteCommentDTOs.comment_id,
        parentCategory: siteCommentDTOs.parent_category,
        projectId: siteCommentDTOs.project_id,
        siteId: siteCommentDTOs.site_id,
        assetId: siteCommentDTOs.asset_id,
        parentId: siteCommentDTOs.parent_id,
        assetNumber: siteCommentDTOs.asset_number,
        parentName: siteCommentDTOs.parent_name,
        message: siteCommentDTOs.message,
        author: siteCommentDTOs.author,
        isResolved: siteCommentDTOs.is_resolved,
        createdAt: siteCommentDTOs.created_at
      });
    }
    return siteCommentDTOs.map((siteCommentDTO: SiteCommentDTO): SiteComment => this.fromDTO(siteCommentDTO));
  }
}

export default SiteCommentMapper;
