import { System } from "app/domain/system";
import { getUserName } from "app/store/login/selectors";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

export type SystemFields = {
  [key: string]: any;
};

type SystemHook = {
  currentSystem: any;
  onUpdate: (fields: SystemFields) => void;
};

const useSystem = (initialSystem: System | undefined, auditDate: string | undefined, installationYear: string | undefined): SystemHook => {
  const userName = useSelector(getUserName);

  const [currentSystem, setCurrentSystem] = useState({
    ...initialSystem,
    unitCost: initialSystem && initialSystem.unitCost ? initialSystem.unitCost : 0,
    quantity: initialSystem && initialSystem.quantity ? initialSystem.quantity : 0,
    auditDate: initialSystem && initialSystem.auditDate ? initialSystem.auditDate : auditDate,
    installationYear: initialSystem && initialSystem.installationYear ? initialSystem.installationYear : installationYear,
    auditor: initialSystem && initialSystem.auditor ? initialSystem.auditor : userName,
    isIdentificationInName: initialSystem && initialSystem.isIdentificationInName ? initialSystem.isIdentificationInName : true,
    isLocalNumberInName: initialSystem && initialSystem.isLocalNumberInName ? initialSystem.isLocalNumberInName : true,
    isLocalisationInName: initialSystem && initialSystem.isLocalisationInName ? initialSystem.isLocalisationInName : true,
    isInstallationYearInName: initialSystem && initialSystem.isInstallationYearInName ? initialSystem.isInstallationYearInName : false,
    errors: initialSystem ? initialSystem.errors : [],
    calculations: initialSystem && initialSystem.calculations ? initialSystem.calculations : []
  });

  const onUpdate = useCallback((fields: SystemFields) => {
    setCurrentSystem((prevState) => ({
      ...prevState,
      ...fields
    }));
  }, []);

  useEffect(() => {
    if (initialSystem) {
      onUpdate(initialSystem);
    }

    if (auditDate) {
      onUpdate({ auditDate });
    }

    if (installationYear) {
      onUpdate({ installationYear });
    }
  }, [initialSystem, auditDate, installationYear, onUpdate]);

  return useMemo(
    () => ({
      currentSystem,
      onUpdate
    }),
    [currentSystem, onUpdate]
  );
};

export default useSystem;
