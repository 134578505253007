import { EntityCategory } from "app/domain/entityCategory";
import Photo, { PhotoStatus } from "app/domain/photo";

export type PhotoDTO = {
  id: string;
  name: string;
  src: string;
  timestamp: string;
};

const getEntity = (entityId: string, entityCategory: EntityCategory): any => {
  if (entityCategory === EntityCategory.ASSET) {
    return {
      asset_id: entityId
    };
  }
  if (entityCategory === EntityCategory.SYSTEM) {
    return {
      system_id: entityId
    };
  }
  if (entityCategory === EntityCategory.REQUIREMENT) {
    return {
      requirement_id: entityId
    };
  }
  return { site_id: entityId };
};

// TODO: review mapper. The uploaded status should not be encapsulated here
class PhotoMapper {
  static fromDTO(photoDTO: PhotoDTO): Photo;
  static fromDTO(photoDTOs: PhotoDTO[]): Photo[];
  static fromDTO(photoDTOs: PhotoDTO | PhotoDTO[]): any {
    if (!Array.isArray(photoDTOs)) {
      return new Photo(photoDTOs.id, photoDTOs.name, photoDTOs.src, PhotoStatus.UPLOADED, new Date(photoDTOs.timestamp));
    }
    return photoDTOs.map((photoDTO: PhotoDTO): Photo => this.fromDTO(photoDTO));
  }

  static toDTO(photo: Photo, entityId: string, entityCategory: EntityCategory): PhotoDTO;
  static toDTO(photos: Photo[], entityId: string, entityCategory: EntityCategory): PhotoDTO[];
  static toDTO(photos: Photo | Photo[], entityId: string, entityCategory: EntityCategory): any {
    if (!Array.isArray(photos)) {
      return { ...getEntity(entityId, entityCategory), id: photos.id, name: photos.name, src: photos.src };
    }
    return photos.map((photo: Photo): PhotoDTO => this.toDTO(photo, entityId, entityCategory));
  }
}

export default PhotoMapper;
