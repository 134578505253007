/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";
import AgGridCustomFilterState from "app/view/gridTemplates/agGridCustomFilterState";

import { deleteAgGridCustomFilterStateAction, resetAgGridCustomFilterStateAction, setAgGridCustomFilterStateAction, setFiltersAction, setFiltersActionV2 } from "./actions";
import { FilterState, initialFilterState } from "./state";
import { DeleteAgGridCustomFilterStateAction, SetAgGridCustomFilterStateAction, SetFiltersAction, SetFiltersActionV2 } from "./types";

const initializeFilterEntry = (state: FilterState, table: string): void => {
  if (!state[table]) {
    state[table] = {
      filters: [],
      columnState: [],
      model: null
    };
  }
};

const setFilters = (state: FilterState, action: SetFiltersAction): void => {
  const { table, filters } = action.payload;
  initializeFilterEntry(state, table);

  state[table].filters = filters;
};

const setFiltersV2 = (state: FilterState, action: SetFiltersActionV2): void => {
  const { table, columnState } = action.payload;
  initializeFilterEntry(state, table);

  state[table].columnState = columnState;
};

const setAgGridCustomFilterState = (state: FilterState, action: SetAgGridCustomFilterStateAction): void => {
  const { table, model } = action.payload;
  initializeFilterEntry(state, table);

  state[table].model = model;
};

const resetAgGridCustomFilterState = (state: FilterState, action: SetAgGridCustomFilterStateAction): void => {
  const { table } = action.payload;

  state[table].model = {};
};

const deleteAgGridCustomFilterState = (state: FilterState, action: DeleteAgGridCustomFilterStateAction): void => {
  const { table, deletedColumn } = action.payload;

  state[table].model.filterModal[deletedColumn] = {};
};

const filterReducer = createReducer(initialFilterState, {
  [setFiltersAction.type]: (state, action) => setFilters(state, action),
  [setFiltersActionV2.type]: (state, action) => setFiltersV2(state, action),
  [setAgGridCustomFilterStateAction.type]: (state, action) => setAgGridCustomFilterState(state, action),
  [resetAgGridCustomFilterStateAction.type]: (state, action) => resetAgGridCustomFilterState(state, action),
  [deleteAgGridCustomFilterStateAction.type]: (state, action) => deleteAgGridCustomFilterState(state, action)
});

export default filterReducer;
