import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, RefObject } from "react";
import { css } from "styled-components";
import { colors } from "ui/colors";

import FormSectionNavigationLink from "./formSectionNavigationLink";

const formOverviewNavStyle = css`
  width: 80%;
  height: 40%;
  overflow: auto;
  margin-left: 10%;

  ul {
    margin: 0;
    padding: 0;
    border-left: 1px solid ${colors.ACCENT_BLUE};
  }
`;

export interface NavigationLink {
  reference: RefObject<any>;
  title: string;
}

interface FormOverviewNavProps extends ComponentProps {
  formSectionNavigationLinks: NavigationLink[];
}

const FormOverviewNav: FC<FormOverviewNavProps> = ({ formSectionNavigationLinks, className }: FormOverviewNavProps) => {
  return (
    <div className={className}>
      <ul data-test-id="formSectionNavigationLinks">
        {formSectionNavigationLinks.map((formSectionNavigationLink: NavigationLink) => (
          <FormSectionNavigationLink title={formSectionNavigationLink.title} reference={formSectionNavigationLink.reference} key={formSectionNavigationLink.title} />
        ))}
      </ul>
    </div>
  );
};

export default createStyledComponent(FormOverviewNav, formOverviewNavStyle);
