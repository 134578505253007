import { Grid, TextField } from "@mui/material";
import { System } from "app/domain/system";
import { useI18n } from "app/locales";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import Label from "ui/label/label";

import { SystemFields } from "../../useSystem";

interface DescriptionFormProps extends ComponentProps {
  system: System | undefined;
  systemWithoutModel?: boolean;
  onUpdate: (fields: SystemFields) => void;
  onNavigationBlock: () => void;
}

const DescriptionForm: FC<DescriptionFormProps> = ({ system, onUpdate, onNavigationBlock, systemWithoutModel }: DescriptionFormProps) => {
  const { getAttributeLabel } = useI18n();
  const handleChange = (event: any): void => {
    event.preventDefault();

    onUpdate({ [event.target.name]: event.target.value.replace("≤", "<=") });
    onNavigationBlock();
  };

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={2}>
        <Grid item xs={2} alignContent="end" alignSelf="center">
          <Label label={getAttributeLabel("description")} />
        </Grid>
        <Grid item xs={8}>
          <TextField
            key="description"
            name="description"
            multiline
            variant="outlined"
            fullWidth
            value={system && system.description ? system.description : ""}
            onChange={handleChange}
            rows={3}
          />
        </Grid>
      </Grid>

      <Grid key={system?.model_number} container item spacing={3} style={systemWithoutModel || system?.model_number === "Custom" ? { display: "none" } : { display: "auto" }}>
        <Grid item xs={2} alignContent="end" alignSelf="center">
          <Label label="Instructions d'utilisation et notes sur le modèle" />
        </Grid>
        <Grid item xs={8} alignContent="end" alignSelf="center">
          <TextField
            disabled
            rows={10}
            key="comment"
            name="comment"
            multiline
            variant="outlined"
            fullWidth
            value={system && system.comment ? system.comment : ""}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container item spacing={3}>
        <Grid item xs={2} alignContent="end" alignSelf="center">
          <Label label="Notes pour le client" />
        </Grid>
        <Grid item xs={8} alignContent="end" alignSelf="center">
          <TextField
            rows={3}
            key="note"
            name="public_notes"
            multiline
            variant="outlined"
            fullWidth
            value={system && system.public_notes ? system.public_notes : ""}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={2} alignContent="end" alignSelf="center">
          <Label label="Notes internes" />
        </Grid>
        <Grid item xs={8} alignContent="end" alignSelf="center">
          <TextField rows={3} key="note" name="note" multiline variant="outlined" fullWidth value={system && system.note ? system.note : ""} onChange={handleChange} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DescriptionForm;
