import { EntityCategory } from "app/domain/entityCategory";

const ERROR_MESSAGE = "An entity ID is mandatory!";

export interface CommentEntity {
  parentId: string;
  parentType: EntityCategory;
}

export interface CommentEntityResponse {
  asset_id?: string;
  system_id?: string;
  requirement_id?: string;
}

export default class CommentEntityMapper {
  static toDTO(entityId: CommentEntity): CommentEntityResponse {
    switch (entityId.parentType) {
      case EntityCategory.SYSTEM: {
        return { system_id: entityId.parentId };
      }
      case EntityCategory.REQUIREMENT: {
        return { requirement_id: entityId.parentId };
      }
      case EntityCategory.ASSET: {
        return { asset_id: entityId.parentId };
      }
      default: {
        throw new Error(ERROR_MESSAGE);
      }
    }
  }
}
