import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import { transparentize } from "polished";
import React, { FC } from "react";
import { Progress as SemanticProgress } from "semantic-ui-react";
import { css, SimpleInterpolation } from "styled-components";
import { Button } from "ui/button";
import * as constants from "ui/button/constants";

const progressBarStyle = css`
  border-radius: ${constants.DEFAULT_BUTTON_BORDER_RADIUS};
  position: relative;
  overflow: hidden;

  &&&& ${Button as unknown as SimpleInterpolation} {
    height: 100%;
    margin: auto;
    background-color: ${transparentize(0.5, constants.DEFAULT_BUTTON_COLOR)};
  }

  && .progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;

    .bar {
      height: 100%;
      background-color: ${constants.DEFAULT_BUTTON_COLOR};
    }
  }

  && .progress::after {
    content: ${(props: StyleProps): string => `"${props.title}"`};
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    padding: 0.78571429em 1.5em 0.78571429em;
    line-height: ${constants.DEFAULT_BUTTON_LINE_HEIGHT};
    font-size: 1rem;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 700;
  }
`;

export interface ProgressBarProps extends ComponentProps {
  title: string;
  progress: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ title, progress, className }: ProgressBarProps) => (
  <div className={className}>
    <Button disabled title={title} data-test-id="progressBarContainer" />
    <SemanticProgress percent={progress} size="tiny" attached="bottom" active data-test-id="progressBar" />
  </div>
);

export default createStyledComponent(ProgressBar, progressBarStyle);
