import { EntityCategory } from "app/domain/entityCategory";
import { addPhotosAction } from "app/store/photo";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { useDispatch } from "react-redux";
import { Button } from "ui/button";
import { ImageUploader, useImageUploader } from "ui/imageUploader";
import { Modal } from "ui/modal";

const SUBMIT = "Soumettre";

interface AddPhotosModalProps extends ComponentProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  siteId: string;
  assetId: string;
  entityId: string;
  entityCategory: EntityCategory;
}

const AddPhotosModal: FC<AddPhotosModalProps> = ({ isOpen, onClose, projectId, siteId, assetId, entityId, entityCategory }: AddPhotosModalProps) => {
  const { onChange, onReset, uploadedImages } = useImageUploader();

  const dispatch = useDispatch();

  const handleClose = (): void => {
    onReset();
    onClose();
  };

  const submitPhotos = (): void => {
    handleClose();

    dispatch(addPhotosAction(uploadedImages, entityId, entityCategory));
  };

  return (
    <Modal
      isOpen={isOpen}
      content={<ImageUploader onChange={onChange} data-test-id="imageUploader" />}
      actions={[<Button disabled={uploadedImages.length === 0} onClick={submitPhotos} title={SUBMIT} key={0} data-test-id="submitPhotosActionButton" />]}
      onClose={handleClose}
      data-test-id="modal"
    />
  );
};

export default memo(AddPhotosModal);
