import { Dictionary } from "../types";
import Attributes from "./attributes";
import Errors from "./errors";
import RequirementType from "./requirementType";
import State from "./state";

const EN: Dictionary<any> = {
  state: State,
  requirementType: RequirementType,
  errors: Errors,
  attributes: Attributes
};

export default EN;
