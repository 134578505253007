class SystemChildren {
  id: string;
  childType: string;
  childTextValue?: string;
  children?: [];
  textValueOptions: string[];

  constructor(systemChildren: SystemChildren) {
    this.id = systemChildren.id;
    this.childType = systemChildren.childType;
    this.childTextValue = systemChildren.childTextValue;
    this.children = systemChildren.children;
    this.textValueOptions = systemChildren.textValueOptions;
  }
}

export default SystemChildren;
