import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import React, { FC, memo, useRef } from "react";
import { css } from "styled-components";
import { useContainerVisibleDimensions } from "ui/hook";

import Loader from "./loader";

const loaderContainerStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${(props: StyleProps): string => (props.isActive ? "1" : "-1")};
`;

interface LoaderContainerProps extends ComponentProps {
  isActive: boolean;
}

const LoaderContainer: FC<LoaderContainerProps> = ({ isActive, className }: LoaderContainerProps) => {
  const loaderContainerReference = useRef(null);
  const loaderContainerVisibleDimensions = useContainerVisibleDimensions(loaderContainerReference);

  return (
    <div className={className} ref={loaderContainerReference}>
      <Loader isActive={isActive} height={loaderContainerVisibleDimensions.height} data-test-id="loader" />
    </div>
  );
};

export default createStyledComponent(memo(LoaderContainer), loaderContainerStyle);
