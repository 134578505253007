import { PayloadAction } from "../../types";

export enum SelectedValueActionTypes {
  SET_SELECTED_VALUES = "SET_SELECTED_VALUES"
}

export type SetSelectedValueAction = PayloadAction<
  SelectedValueActionTypes.SET_SELECTED_VALUES,
  {
    selectedValues: string[];
  }
>;
