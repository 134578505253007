import { Filter, FilterType } from "app/domain/filter";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useMemo } from "react";
import compare from "react-fast-compare";
import { css, SimpleInterpolation } from "styled-components";
import { Icon } from "ui/icon";
import { FilterPanel } from "ui/table/filterPanel";

import FilterPanelButton from "../filterPanel/filterPanelButton";

const quickFilterPanelStyle = css`
  &&&&& {
    ${Icon as unknown as SimpleInterpolation} {
      height: 0.7em;
      width: 0.7em;

      i {
        font-size: 1em;
      }
    }

    ${FilterPanelButton as unknown as SimpleInterpolation} {
      margin-right: 8px;
    }
  }
`;

interface QuickFilterPanelProps extends ComponentProps {
  quickFilters: Filter[];
  selectedQuickFilters: Filter[];
  onQuickFilterSelect: (filter: Filter) => void;
}

const isEqual = (prevProps: QuickFilterPanelProps, nextProps: QuickFilterPanelProps): boolean => {
  const areQuickFiltersEqual = compare(prevProps.quickFilters, nextProps.quickFilters);
  const areSelectedQuickFiltersEqual = compare(prevProps.selectedQuickFilters, nextProps.selectedQuickFilters);

  return areQuickFiltersEqual && areSelectedQuickFiltersEqual;
};

const QuickFilterPanel: FC<QuickFilterPanelProps> = ({ quickFilters, selectedQuickFilters, onQuickFilterSelect, className }: QuickFilterPanelProps) => {
  const values = useMemo(() => quickFilters.map((quickFilter: Filter) => quickFilter.label), [quickFilters]);

  const filteredValues = useMemo(() => selectedQuickFilters.map((quickFilter: Filter) => quickFilter.label), [selectedQuickFilters]);

  const handleFilter = (filterLabels: string[]): void => {
    const filter = quickFilters.filter((quickFilter: Filter) => quickFilter.label === filterLabels[0])[0];

    onQuickFilterSelect(filter);
  };

  return (
    <div className={className}>
      <FilterPanel values={values} filteredValues={filteredValues} onFilter={handleFilter} filterType={FilterType.QUICK_FILTER} data-test-id="filterPanel" />
    </div>
  );
};

export default createStyledComponent(memo(QuickFilterPanel, isEqual), quickFilterPanelStyle);
