import { Requirement } from "app/domain/requirement";
import { RequirementsOptions } from "app/domain/requirement/options";
import { createSelector } from "reselect";

import { State } from "../state";

export const getRequirements = (state: State): Requirement[] => state.requirements.requirements;

const flterBySystemId = (_: State, systemId: string | undefined): string | undefined => systemId;

export const getCurrentRequirements = createSelector([getRequirements, flterBySystemId], (requirements: Requirement[], systemId: string | undefined) => {
  if (systemId) {
    return requirements.filter((requirement: Requirement) => requirement.component_id === systemId);
  }
  return requirements;
});

export const getCurrentRequirement = createSelector(
  [(state: State): Requirement | undefined => state.requirements.currentRequirement],
  (currentRequirement: Requirement | undefined) => currentRequirement
);

export const getErrorMessage = createSelector([(state: State): string => state.requirements.errorMessage], (errorMessage) => errorMessage);

export const isLoading = createSelector([(state: State): boolean => state.requirements.isLoading], (isRequirementLoading: boolean) => isRequirementLoading);

export const isLoadingOptions = createSelector([(state: State): boolean => state.requirements.isFetchingOptions], (isFetchingOptions: boolean) => isFetchingOptions);

export const shouldRedirectToRequirements = createSelector(
  [(state: State): boolean => state.requirements.shouldRedirectToRequirementsPage],
  (shouldRedirect: boolean) => shouldRedirect
);

export const getRequirementOptions = createSelector(
  [(state: State): RequirementsOptions => state.requirements.options],
  (requirementOptions: RequirementsOptions) => requirementOptions
);

export const getFilteredRequirements = createSelector(
  [(state: State): Requirement[] => state.requirements.filteredRequirements],
  (filteredRequirements: Requirement[]) => filteredRequirements
);
