import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import React from "react";

const EditButtonCell: React.FC = ({ displayDrawer, data }: any): JSX.Element => {
  const handleClick = (): void => {
    displayDrawer(data.id as string);
  };

  return (
    <Button type="button">
      <EditIcon onClick={handleClick} />
    </Button>
  );
};

export default EditButtonCell;
