import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Button } from "ui/button";
import { IconType } from "ui/icon";

export interface SaveButtonProps extends ComponentProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

const SaveButton: FC<SaveButtonProps> = ({ label, onClick, disabled }: SaveButtonProps) => (
  <Button title={label} iconType={IconType.SAVE} onClick={onClick} disabled={disabled} data-test-id="button" />
);

export default SaveButton;
