import { useCallback, useMemo, useState } from "react";

type ToggleHook = {
  isToggleOn: boolean;
  toggle: () => void;
};

const useToggle = (initialToggleState: boolean): ToggleHook => {
  const [isToggleOn, setToggle] = useState(initialToggleState);

  const toggle = useCallback(() => {
    setToggle(!isToggleOn);
  }, [isToggleOn]);

  return useMemo(
    () => ({
      isToggleOn,
      toggle
    }),
    [isToggleOn, toggle]
  );
};

export default useToggle;
