import Typography from "@mui/material/Typography";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import styled from "styled-components";

export const StyledTypography = styled(Typography)`
  &.MuiTypography-body1 {
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: normal;
  }
`;

interface LabelProps extends ComponentProps {
  label: string;
}

const Label: FC<LabelProps> = ({ label }: LabelProps) => {
  return (
    <div>
      <div>
        <StyledTypography gutterBottom>{label}</StyledTypography>
      </div>
    </div>
  );
};

export default Label;
