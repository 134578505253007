import { SystemModel } from "app/domain/system";
import { Action, PayloadAction } from "app/store/types";

export enum SystemModelActionTypes {
  GET_SYSTEM_MODELS = "GET_SYSTEM_MODELS",
  GET_SYSTEM_MODELS_REQUEST = "GET_SYSTEM_MODELS_REQUEST",
  GET_SYSTEM_MODELS_ERROR = "GET_SYSTEM_MODELS_ERROR",
  GET_SYSTEM_MODELS_SUCCESS = "GET_SYSTEM_MODELS_SUCCESS",
  RESET_SYSTEM_MODELS = "RESET_SYSTEM_MODELS"
}

export type GetSystemModelsAction = Action<SystemModelActionTypes.GET_SYSTEM_MODELS>;

export type GetSystemModelsRequestAction = Action<SystemModelActionTypes.GET_SYSTEM_MODELS_REQUEST>;

export type GetSystemModelsSuccessAction = PayloadAction<
  SystemModelActionTypes.GET_SYSTEM_MODELS_SUCCESS,
  {
    systemModels: SystemModel[];
  }
>;

export type GetSystemModelsErrorAction = PayloadAction<SystemModelActionTypes.GET_SYSTEM_MODELS_ERROR, { errorMessage: string }>;

export type ResetAction = Action<SystemModelActionTypes.RESET_SYSTEM_MODELS>;
