/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";
import Photo, { PhotosFolder } from "app/domain/photo";

import {
  addPhotosAction,
  addPhotosErrorAction,
  addPhotosRequestAction,
  addPhotosSuccessAction,
  deletePhotosAction,
  deletePhotosErrorAction,
  deletePhotosRequestAction,
  deletePhotoSuccessAction,
  getPhotosAction,
  getPhotosErrorAction,
  getPhotosFolderErrorAction,
  getPhotosFolderRequestAction,
  getPhotosRequestAction,
  getPhotosSuccessAction,
  resetAction,
  setPhotosFolderAction,
  startDownloadingPhotosFolderAction,
  stopDownloadingPhotosFolderAction
} from "./actions";
import { EntityPhotosStatus, initialPhotoState, PhotosFolderPollingStatus, PhotoState } from "./state";
import {
  AddPhotosAction,
  AddPhotosErrorAction,
  AddPhotosRequestAction,
  AddPhotosSuccessAction,
  DeletePhotosAction,
  DeletePhotosErrorAction,
  DeletePhotosRequestAction,
  DeletePhotoSuccessAction,
  GetPhotosAction,
  GetPhotosErrorAction,
  GetPhotosFolderErrorAction,
  GetPhotosFolderRequestAction,
  GetPhotosRequestAction,
  GetPhotosSuccessAction,
  SetPhotosFolderAction,
  StartDownloadingPhotosFolderAction,
  StopDownloadingPhotosFolderAction
} from "./types";

const setEntityPhotosStatus = (state: PhotoState, entityId: string, status: EntityPhotosStatus): void => {
  const entityPhotos = state.photos[entityId];

  if (entityPhotos) {
    entityPhotos.status = status;
  }
};

const setErrorMessage = (state: PhotoState, entityId: string, errorMessage: string): void => {
  const entityPhotos = state.photos[entityId];

  if (entityPhotos) {
    entityPhotos.status = undefined;
    state.errorMessage = errorMessage;
  }
};

const initializeEntityPhotosEntry = (state: PhotoState, entityId: string): void => {
  const entityPhotos = state.photos[entityId];

  if (!entityPhotos) {
    state.photos[entityId] = {
      photos: [],
      status: undefined
    };
  }
};

const initializeEntityPhotosFolderEntry = (state: PhotoState, entityId: string): void => {
  const entityPhotosFolder = state.photosFolders[entityId];

  if (!entityPhotosFolder) {
    state.photosFolders[entityId] = {
      photosFolder: new PhotosFolder(),
      pollingStatus: PhotosFolderPollingStatus.STOPPED
    };
  }
};

const addPhotos = (state: PhotoState, action: AddPhotosAction): void => {
  initializeEntityPhotosEntry(state, action.payload.entityId);

  state.errorMessage = "";
};

const addPhotosRequest = (state: PhotoState, action: AddPhotosRequestAction): void => {
  setEntityPhotosStatus(state, action.payload.entityId, EntityPhotosStatus.UPLOADING);
};

const addPhotosSuccess = (state: PhotoState, action: AddPhotosSuccessAction): void => {
  const entityPhotos = state.photos[action.payload.entityId];
  const payloadPhotos = action.payload.photos;

  payloadPhotos.forEach((payloadPhoto: Photo) => {
    entityPhotos.photos = entityPhotos.photos.filter((entityPhoto) => entityPhoto.id !== payloadPhoto.id);
    entityPhotos.photos.push(payloadPhoto);
  });

  entityPhotos.status = undefined;
};

const addPhotosError = (state: PhotoState, action: AddPhotosErrorAction): void => {
  setErrorMessage(state, action.payload.entityId, action.payload.errorMessage);
};

const deletePhotos = (state: PhotoState, action: DeletePhotosAction): void => {
  const entityPhotos = state.photos[action.payload.entityId];

  if (entityPhotos) {
    state.errorMessage = "";
  }
};

const deletePhotosRequest = (state: PhotoState, action: DeletePhotosRequestAction): void => {
  setEntityPhotosStatus(state, action.payload.entityId, EntityPhotosStatus.DELETING);
};

const deletePhotoSuccess = (state: PhotoState, action: DeletePhotoSuccessAction): void => {
  const entityPhotos = state.photos[action.payload.entityId];

  if (entityPhotos) {
    entityPhotos.photos = entityPhotos.photos.filter((entityPhoto: Photo) => entityPhoto.id !== action.payload.photoId);

    entityPhotos.status = undefined;
  }
};

const deletePhotosError = (state: PhotoState, action: DeletePhotosErrorAction): void => {
  setErrorMessage(state, action.payload.entityId, action.payload.errorMessage);
};

const getPhotos = (state: PhotoState, action: GetPhotosAction): void => {
  initializeEntityPhotosEntry(state, action.payload.entityId);

  state.errorMessage = "";
};

const getPhotosRequest = (state: PhotoState, action: GetPhotosRequestAction): void => {
  const entityPhotos = state.photos[action.payload.entityId];

  entityPhotos.status = EntityPhotosStatus.FETCHING;
};

const getPhotosSuccess = (state: PhotoState, action: GetPhotosSuccessAction): void => {
  const entityPhotos = state.photos[action.payload.entityId];

  entityPhotos.photos = action.payload.photos;
  entityPhotos.status = undefined;
};

const getPhotosError = (state: PhotoState, action: GetPhotosErrorAction): void => {
  setErrorMessage(state, action.payload.entityId, action.payload.errorMessage);
};

const getPhotosFolder = (state: PhotoState, action: StartDownloadingPhotosFolderAction): void => {
  initializeEntityPhotosFolderEntry(state, action.payload.entityId);

  state.errorMessage = "";
};

const getPhotosFolderRequest = (state: PhotoState, action: GetPhotosFolderRequestAction): void => {
  const { entityId } = action.payload;

  const entityPhotosFolder = state.photosFolders[entityId];

  entityPhotosFolder.pollingStatus = PhotosFolderPollingStatus.STARTED;
};

const setPhotosFolder = (state: PhotoState, action: SetPhotosFolderAction): void => {
  const { entityId } = action.payload;

  const entityPhotosFolder = state.photosFolders[entityId];

  entityPhotosFolder.photosFolder = action.payload.photosFolder;
};

const getPhotosFolderError = (state: PhotoState, action: GetPhotosFolderErrorAction): void => {
  state.errorMessage = action.payload.errorMessage;

  const entityPhotosFolder = state.photosFolders[action.payload.entityId];
  entityPhotosFolder.pollingStatus = PhotosFolderPollingStatus.STOPPED;
};

const setPhotosFolderPollingStatusToStopped = (state: PhotoState, action: StopDownloadingPhotosFolderAction): void => {
  const entityPhotosFolder = state.photosFolders[action.payload.entityId];

  entityPhotosFolder.pollingStatus = PhotosFolderPollingStatus.STOPPED;
};

const reset = (state: PhotoState): void => {
  state.errorMessage = "";
};

const photoReducer = createReducer(initialPhotoState, {
  [addPhotosAction.type]: (state, action) => addPhotos(state, action),
  [addPhotosRequestAction.type]: (state, action) => addPhotosRequest(state, action),
  [addPhotosSuccessAction.type]: (state, action) => addPhotosSuccess(state, action),
  [addPhotosErrorAction.type]: (state, action) => addPhotosError(state, action),
  [deletePhotosAction.type]: (state, action) => deletePhotos(state, action),
  [deletePhotosRequestAction.type]: (state, action) => deletePhotosRequest(state, action),
  [deletePhotoSuccessAction.type]: (state, action) => deletePhotoSuccess(state, action),
  [deletePhotosErrorAction.type]: (state, action) => deletePhotosError(state, action),
  [getPhotosAction.type]: (state, action) => getPhotos(state, action),
  [getPhotosRequestAction.type]: (state, action) => getPhotosRequest(state, action),
  [getPhotosSuccessAction.type]: (state, action) => getPhotosSuccess(state, action),
  [getPhotosErrorAction.type]: (state, action) => getPhotosError(state, action),
  [startDownloadingPhotosFolderAction.type]: (state, action) => getPhotosFolder(state, action),
  [getPhotosFolderRequestAction.type]: (state, action) => getPhotosFolderRequest(state, action),
  [setPhotosFolderAction.type]: (state, action) => setPhotosFolder(state, action),
  [getPhotosFolderErrorAction.type]: (state, action) => getPhotosFolderError(state, action),
  [stopDownloadingPhotosFolderAction.type]: (state, action) => setPhotosFolderPollingStatusToStopped(state, action),
  [resetAction.type]: (state) => reset(state)
});

export default photoReducer;
