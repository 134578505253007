interface ProjectProps {
  id: string;
  description: string;
}

class Project {
  id: string;
  description: string;

  constructor(projectProps: ProjectProps) {
    this.id = projectProps.id;
    this.description = projectProps.description;
  }
}

export default Project;
