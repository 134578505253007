import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect, useState } from "react";
import { Message as SemanticMessage } from "semantic-ui-react";
import { css } from "styled-components";

const errorMessageStyle = css`
  && {
    min-height: fit-content;
    margin: 0;

    p {
      white-space: pre-wrap;
    }
  }
`;

interface ErrorMessageProps extends ComponentProps {
  message: string;
  onDismiss: () => void;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message, onDismiss, className }: ErrorMessageProps) => {
  const [isVisible, setVisibility] = useState(message !== "");

  useEffect(() => {
    setVisibility(message !== "");
  }, [message, setVisibility]);

  const handleDismiss = (): void => {
    setVisibility(false);
    onDismiss();
  };
  return <>{isVisible && <SemanticMessage header="Erreur" negative onDismiss={handleDismiss} content={message} className={className} data-test-id="message" />}</>;
};

export default createStyledComponent(memo(ErrorMessage), errorMessageStyle);
