import AuditProgress from "app/domain/asset/auditProgress";
import { Params, useRouter } from "app/routing/routerProvider";
import { getAuditProgressAction, updateAuditProgressAction } from "app/store/auditProgress/actions";
import { getAuditProgress, isLoading as isLoadingSelector } from "app/store/auditProgress/selectors";
import { Toolbar } from "app/view/common/toolbar";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form as SemanticForm } from "semantic-ui-react";
import { css } from "styled-components";
import { SaveButton } from "ui/button/actions";
import { Loader } from "ui/loader";

import ArchitecturalMonitoring from "./formSections/architecturalMonitoring";
import ElectromechanicalMonitoring from "./formSections/electromechanicalMonitoring";
import GlobalQualityControl from "./formSections/globalQualityControl";
import useAuditProgress from "./useAuditProgress";

const SAVE = "Enregistrer";

const assetInputInformationStyle = css`
  > *:not(:last-child) {
    margin-bottom: 1em;
  }
`;

type AssetInputInformationFormProps = ComponentProps;

const AssetInputInformationForm: FC<AssetInputInformationFormProps> = ({ className }: AssetInputInformationFormProps) => {
  const auditProgress = useSelector(getAuditProgress);
  const isLoading = useSelector(isLoadingSelector);

  const { currentAuditProgress, onUpdate } = useAuditProgress(auditProgress as AuditProgress);

  const { getParam } = useRouter();
  const assetId = getParam(Params.ASSET);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuditProgressAction(assetId));
  }, [dispatch, assetId]);

  const saveAuditProgress = (): void => {
    dispatch(updateAuditProgressAction(currentAuditProgress as AuditProgress));
  };

  const actions = [<SaveButton label={SAVE} onClick={saveAuditProgress} key={0} />];

  return (
    <SemanticForm className={className}>
      <Toolbar actions={actions} />
      <Loader isActive={isLoading} />
      <GlobalQualityControl onUpdate={onUpdate} auditProgress={currentAuditProgress} />
      <ElectromechanicalMonitoring onUpdate={onUpdate} auditProgress={currentAuditProgress} />
      <ArchitecturalMonitoring onUpdate={onUpdate} auditProgress={currentAuditProgress} />
    </SemanticForm>
  );
};

export default createStyledComponent(AssetInputInformationForm, assetInputInformationStyle);
