import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useRef } from "react";

import Button from "./button";

export interface FileUploadButtonProps extends ComponentProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
  color?: string;
}

const FileUploadButton: FC<FileUploadButtonProps> = ({ onChange, title, color, modifiers }: FileUploadButtonProps) => {
  const fileInputReference = useRef(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event);

    const input = event.target as HTMLInputElement;
    input.value = "";
  };

  return (
    <>
      <Button
        color={color}
        title={title}
        modifiers={modifiers}
        onClick={(): void => {
          (fileInputReference.current as unknown as HTMLInputElement).click();
        }}
        data-test-id="fileUploadButton"
      />
      <input multiple ref={fileInputReference} type="file" hidden onChange={handleChange} data-test-id="fileInput" />
    </>
  );
};

export default FileUploadButton;
