import { Form, Button } from "semantic-ui-react";
import * as React from "react";
import { withRouter } from "react-router";

class AccountFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      password: "",
      confirmPassword: "",
    };
  }

  handleChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  changePassword = () => {
    const { password, confirmPassword } = this.state;

    if (password.trim() === confirmPassword.trim()) {
      this.props.changePassword(password.trim());
      this.setState({ error: false });
    } else {
      this.setState({ error: "Les mots de passe ne correspondent pas." });
    }
  };

  render() {
    const { email, passwordIsChanged } = this.props;
    const { password, confirmPassword, error, changeAttempt } = this.state;

    return (
      <div>
        <div>
          <h2>Changer mon mot de passe</h2>
          <Form>
            <Form.Group widths={3}>
              <Form.Input value={email} label="Courriel" />
            </Form.Group>
            <Form.Group widths={3}>
              <Form.Input
                type="password"
                name="password"
                value={password}
                label="Nouveau mot de passe"
                placeholder="Nouveau mot de passe"
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group widths={3}>
              <Form.Input
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                label="Confirmer le nouveau mot de passe"
                placeholder="Confirmer le nouveau mot de passe"
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>

          {error != null && changeAttempt === true ? (
            <p className="error-message">{error}</p>
          ) : null}
          {passwordIsChanged === true && changeAttempt === true ? (
            <p className="success">Le mot de passe à bien été changé.</p>
          ) : null}
          {passwordIsChanged === false && changeAttempt === true ? (
            <p className="error-message">Une erreur est survenue.</p>
          ) : null}
        </div>
        <div className="crudBtns">
          <Button
            onClick={() => {
              this.props.changePassword(password);
              this.setState({ changeAttempt: true });
            }}
            className="btnSave"
            content="Enregistrer"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(AccountFormComponent);
