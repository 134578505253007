import { useCallback, useMemo, useState } from "react";

type SelectableCardGridHook = {
  onSelect: (cardKey: string) => void;
  onReset: () => void;
  selectedCardKeys: string[];
};

const useSelectableCardGrid = (): SelectableCardGridHook => {
  const [selectedCardKeys, setSelectedCardKeys] = useState([] as string[]);

  const onSelect = useCallback(
    (cardKey: string) => {
      if (selectedCardKeys.includes(cardKey)) {
        setSelectedCardKeys(selectedCardKeys.filter((selectedCardKey) => selectedCardKey !== cardKey));
      } else {
        setSelectedCardKeys([...selectedCardKeys, cardKey]);
      }
    },
    [selectedCardKeys]
  );

  const onReset = useCallback(() => {
    setSelectedCardKeys([]);
  }, []);

  return useMemo(
    () => ({
      onSelect,
      onReset,
      selectedCardKeys
    }),
    [onSelect, onReset, selectedCardKeys]
  );
};

export default useSelectableCardGrid;
