import { getAssetAction } from "app/store/asset/actions";
import { getRoomAction } from "app/store/local/actions";
import { setCurrentProjectAction } from "app/store/project/actions";
import { getRequirementAction } from "app/store/requirement/actions";
import { getSiteAction } from "app/store/site/actions";
import { getSystemAction } from "app/store/system/actions";
import usePrevious from "app/view/common/hook/usePrevious";
import React, { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import compare from "react-fast-compare";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";

export enum Params {
  PROJECT = "projects",
  SITE = "sites",
  ASSET = "assets",
  SYSTEM = "components",
  REQUIREMENT = "requirements",
  ROOM = "rooms"
}

interface LocationState {
  [key: string]: any;
}

interface RouterProviderState {
  goTo: (path: string, locationState?: LocationState, newTab?: boolean) => void;
  getParam: (key: string) => any | undefined;
  getStateParam: (key: string) => any | undefined;
  location: string;
}

const RouterContext = createContext({
  goTo: (path: string, locationState?: LocationState) => {},
  getParam: (key: string) => undefined,
  getStateParam: (key: string) => undefined,
  location: "/"
});

export const useRouter = (): RouterProviderState => useContext(RouterContext);

const RouterProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const dispatch = useDispatch();

  const goTo = useCallback(
    (path: string, locationState?: LocationState, newTab?: boolean): void => {
      if (newTab) {
        window.open(path);
        return;
      }
      history.push(path, locationState);
    },
    [history]
  );

  // TODO Refactor routes to make it higher in the hierarchy.
  // TODO Retrieve params from react-router.
  // We should be retrieving the params via useParams() provided by react-router but for the moment we need a custom getParam function
  // The reason: The Routes component is lower than RouterProvider in the components hierarchy, so we don't have access to the params
  // via useParams (it returns undefined). Once we refactor Routes, we'll make it higher in the hierarchy.
  const getParam = useCallback(
    (key: string): any | undefined => {
      if (location && location.pathname.indexOf(key) !== -1) {
        const urlAfterParamName = location.pathname.split(`${key}/`).slice(1).join(".");
        return urlAfterParamName.substring(0, urlAfterParamName.indexOf("/") !== -1 ? urlAfterParamName.indexOf("/") : urlAfterParamName.length);
      }
      return undefined;
    },
    [location]
  );

  const getStateParam = useCallback(
    (key: string): any | undefined => {
      return location.state ? location.state[key] : undefined;
    },
    [location.state]
  );

  const currentParams = useMemo(
    () =>
      ({
        siteId: getParam(Params.SITE),
        assetId: getParam(Params.ASSET),
        systemId: getParam(Params.SYSTEM),
        requirementId: getParam(Params.REQUIREMENT),
        roomId: getParam(Params.ROOM)
      } as any),
    [getParam]
  );
  const previousParams = usePrevious(currentParams);

  const hasParamChanged = useCallback(
    (param: string): boolean => {
      return currentParams && currentParams[param] && !compare(previousParams && previousParams[param], currentParams[param]);
    },
    [currentParams, previousParams]
  );

  useEffect(() => {
    if (hasParamChanged("projectId")) {
      dispatch(setCurrentProjectAction(currentParams.projectId));
    }

    if (hasParamChanged("siteId")) {
      dispatch(getSiteAction(currentParams.siteId));
    }

    if (hasParamChanged("assetId")) {
      dispatch(getAssetAction(currentParams.assetId));
    }

    if (hasParamChanged("systemId")) {
      dispatch(getSystemAction(currentParams.systemId));
    }

    if (hasParamChanged("requirementId")) {
      dispatch(getRequirementAction(currentParams.requirementId));
    }
    if (hasParamChanged("roomId")) {
      dispatch(getRoomAction(currentParams.roomId));
    }
  }, [currentParams, hasParamChanged, dispatch]);

  return <RouterContext.Provider value={{ goTo, getParam, getStateParam, location: location.pathname }}>{children}</RouterContext.Provider>;
};

export default RouterProvider;
