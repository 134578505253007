import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { IconButton } from "ui/button";
import { Icon, IconType } from "ui/icon";

interface WebcamActionButtonProps extends ComponentProps {
  onClick: () => void;
  iconType: IconType;
}

const WebcamActionButton: FC<WebcamActionButtonProps> = ({ onClick, iconType, className }: WebcamActionButtonProps) => (
  <IconButton color="white" modifiers="outlined" circular onClick={onClick} className={className}>
    <Icon type={iconType} />
  </IconButton>
);

export default memo(WebcamActionButton);
