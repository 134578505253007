import { CostLine } from "app/domain/costItem";
import { moneyFormatter } from "app/utils/formatter";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Button as SemanticButton, Form as SemanticForm, Icon as SemanticIcon, Table as SemanticTable } from "semantic-ui-react";

interface CostLineTableRowProps extends ComponentProps {
  costLine: CostLine;
  index: number;
  onDeleteCostLine: (index: number) => void;
  onUpdateCostLine: (index: number, costLineQuantity: number) => void;
}

const CostLinesTableRow: FC<CostLineTableRowProps> = ({ costLine, index, onDeleteCostLine, onUpdateCostLine }: CostLineTableRowProps) => {
  const [quantity, setQuantity] = useState(costLine.quantity || "");

  useEffect(() => {
    setQuantity(costLine.quantity || "");
  }, [costLine.quantity]);

  const getQuantity = useCallback(() => (quantity === "" ? 0 : parseFloat(quantity as string)), [quantity]);

  const totalCost = useMemo(() => moneyFormatter.format(Math.round(getQuantity() * (costLine.unitCost as number) * 100) / 100), [getQuantity, costLine.unitCost]);

  const handleCostLineDelete = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();

    onDeleteCostLine(index);
  };

  const handleQuantityInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();

    setQuantity(event.target.value);
  };

  const handleQuantityInputBlur = (): void => {
    onUpdateCostLine(index, getQuantity());
  };

  return (
    <SemanticTable.Row>
      <SemanticTable.Cell>
        <SemanticButton size="small" color="red" circular onClick={handleCostLineDelete} icon data-test-id="deleteCostLineButton">
          <SemanticIcon name="delete" />
        </SemanticButton>
      </SemanticTable.Cell>
      <SemanticTable.Cell data-test-id="classNameCell">{costLine.className}</SemanticTable.Cell>
      <SemanticTable.Cell data-test-id="codeCell">{costLine.code}</SemanticTable.Cell>
      <SemanticTable.Cell data-test-id="descriptionCell">{costLine.description}</SemanticTable.Cell>
      <SemanticTable.Cell>
        <SemanticForm.Input type="number" value={quantity} placeholder={0} onChange={handleQuantityInputChange} onBlur={handleQuantityInputBlur} data-test-id="quantityInput" />
      </SemanticTable.Cell>
      <SemanticTable.Cell style={{ textAlign: "center" }} data-test-id="unitCell">
        {costLine.unitOfMeasure}
      </SemanticTable.Cell>
      <SemanticTable.Cell style={{ textAlign: "right" }} data-test-id="unitCostCell">
        {moneyFormatter.format(costLine.unitCost as number)}
      </SemanticTable.Cell>
      <SemanticTable.Cell style={{ textAlign: "right" }} data-test-id="totalCostCell">
        {totalCost}
      </SemanticTable.Cell>
    </SemanticTable.Row>
  );
};

export default CostLinesTableRow;
