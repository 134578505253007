import { CostItem } from "app/domain/costItem";
import { CostItemService } from "app/service/costItem";
import { call, put, takeLatest } from "redux-saga/effects";

import { getCostItemsErrorAction, getCostItemsRequestAction, getCostItemsSuccessAction } from "./actions";
import { CostItemActionTypes } from "./types";

export const GET_COST_ITEMS_ERROR = "Une erreur s'est produite lors de la récupération des éléments de coût. Veuillez réessayer.";

export function* getCostItems(): Generator {
  yield put(getCostItemsRequestAction());
}

export function* getCostItemsRequest(): Generator {
  try {
    const costItems = yield call(CostItemService.getCostItems);

    yield put(getCostItemsSuccessAction(costItems as CostItem[]));
  } catch (error) {
    yield put(getCostItemsErrorAction(GET_COST_ITEMS_ERROR));
  }
}

function* costItemSaga(): Generator {
  yield takeLatest(CostItemActionTypes.GET_COST_ITEMS, getCostItems);
  yield takeLatest(CostItemActionTypes.GET_COST_ITEMS_REQUEST, getCostItemsRequest);
}

export default costItemSaga;
