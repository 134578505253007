/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";

import {
  createAssetAction,
  createAssetErrorAction,
  createAssetRequestAction,
  createAssetSuccessAction,
  getAssetAction,
  getAssetErrorAction,
  getAssetOptionsAction,
  getAssetOptionsErrorAction,
  getAssetOptionsRequestAction,
  getAssetOptionsSuccessAction,
  getAssetRequestAction,
  getAssetsAction,
  getAssetsErrorAction,
  getAssetsMinimalInfoAction,
  getAssetsMinimalInfoErrorAction,
  getAssetsMinimalInfoRequestAction,
  getAssetsMinimalInfoSuccessAction,
  getAssetsRequestAction,
  getAssetsSuccessAction,
  getAssetSuccessAction,
  resetAction,
  updateAssetAction,
  updateAssetErrorAction,
  updateAssetRequestAction,
  updateAssetSuccessAction
} from "./actions";
import { AssetState, initialAssetState } from "./state";
import {
  CreateAssetErrorAction,
  CreateAssetSuccessAction,
  GetAssetErrorAction,
  GetAssetOptionsErrorAction,
  GetAssetOptionsSuccessAction,
  GetAssetsErrorAction,
  GetAssetsMinimalInfoErrorAction,
  GetAssetsMinimalInfoSuccessAction,
  GetAssetsSuccessAction,
  GetAssetSuccessAction,
  UpdateAssetErrorAction,
  UpdateAssetSuccessAction
} from "./types";

const getAsset = (state: AssetState): void => {
  state.errorMessage = "";
};

const getAssetRequest = (state: AssetState): void => {
  state.isFetching = true;
};

const getAssetSuccess = (state: AssetState, action: GetAssetSuccessAction): void => {
  state.isFetching = false;
  state.currentAsset = action.payload.asset;
};

const getAssetError = (state: AssetState, action: GetAssetErrorAction): void => {
  state.isFetching = false;
  state.errorMessage = action.payload.errorMessage;
};

const getAssets = (state: AssetState): void => {
  state.errorMessage = "";
};

const getAssetsRequest = (state: AssetState): void => {
  state.isFetching = true;
};

const getAssetsSuccess = (state: AssetState, action: GetAssetsSuccessAction): void => {
  state.isFetching = false;
  state.assets = action.payload.assets;
};

const getAssetsError = (state: AssetState, action: GetAssetsErrorAction): void => {
  state.isFetching = false;
  state.errorMessage = action.payload.errorMessage;
};

const getAssetOptions = (state: AssetState): void => {
  state.errorMessage = "";
};

const getAssetOptionsRequest = (state: AssetState): void => {
  state.isFetchingOptions = true;
};

const getAssetOptionsSuccess = (state: AssetState, action: GetAssetOptionsSuccessAction): void => {
  state.isFetchingOptions = false;
  state.optionsLists = action.payload.options;
};

const getAssetOptionsError = (state: AssetState, action: GetAssetOptionsErrorAction): void => {
  state.isFetchingOptions = false;
  state.errorMessage = action.payload.errorMessage;
};

const createAsset = (state: AssetState): void => {
  state.errorMessage = "";
};

const createAssetRequest = (state: AssetState): void => {
  state.isUpdating = true;
};

const createAssetSuccess = (state: AssetState, action: CreateAssetSuccessAction): void => {
  state.isUpdating = false;
  state.isFetching = false;
  state.currentAsset = action.payload.asset;
  state.assets = action.payload.assets;
};

const createAssetError = (state: AssetState, action: CreateAssetErrorAction): void => {
  state.isUpdating = false;
  state.errorMessage = action.payload.errorMessage;
};

const updateAsset = (state: AssetState): void => {
  state.errorMessage = "";
};

const updateAssetRequest = (state: AssetState): void => {
  state.isUpdating = true;
};

const updateAssetSuccess = (state: AssetState, action: UpdateAssetSuccessAction): void => {
  state.isUpdating = false;
  state.currentAsset = action.payload.asset;
  state.assets = action.payload.assets;
};

const updateAssetError = (state: AssetState, action: UpdateAssetErrorAction): void => {
  state.isUpdating = false;
  state.errorMessage = action.payload.errorMessage;
};

const getAssetsMinimalInfo = (state: AssetState): void => {
  state.errorMessage = "";
};

const getAssetsMinimalInfoRequest = (state: AssetState): void => {
  state.isFetching = true;
};

const getAssetsMinimalInfoSuccess = (state: AssetState, action: GetAssetsMinimalInfoSuccessAction): void => {
  state.isFetching = false;
  state.assetsMinimalInfo = action.payload.assetsMinimalInfo;
};

const getAssetsMinimalInfoError = (state: AssetState, action: GetAssetsMinimalInfoErrorAction): void => {
  state.isFetching = false;
  state.errorMessage = action.payload.errorMessage;
};

const reset = (state: AssetState): void => {
  state.errorMessage = "";
};

const assetReducer = createReducer(initialAssetState, {
  [getAssetAction.type]: (state: any) => getAsset(state),
  [getAssetRequestAction.type]: (state: any) => getAssetRequest(state),
  [getAssetSuccessAction.type]: (state: any, action) => getAssetSuccess(state, action),
  [getAssetErrorAction.type]: (state: any, action) => getAssetError(state, action),
  [getAssetsAction.type]: (state: any) => getAssets(state),
  [getAssetsRequestAction.type]: (state: any) => getAssetsRequest(state),
  [getAssetsSuccessAction.type]: (state: any, action) => getAssetsSuccess(state, action),
  [getAssetsErrorAction.type]: (state: any, action) => getAssetsError(state, action),
  [getAssetOptionsAction.type]: (state: any) => getAssetOptions(state),
  [getAssetOptionsRequestAction.type]: (state: any) => getAssetOptionsRequest(state),
  [getAssetOptionsSuccessAction.type]: (state: any, action) => getAssetOptionsSuccess(state, action),
  [getAssetOptionsErrorAction.type]: (state: any, action) => getAssetOptionsError(state, action),
  [createAssetAction.type]: (state: any) => createAsset(state),
  [createAssetRequestAction.type]: (state: any) => createAssetRequest(state),
  [createAssetSuccessAction.type]: (state: any, action) => createAssetSuccess(state, action),
  [createAssetErrorAction.type]: (state: any, action) => createAssetError(state, action),
  [updateAssetAction.type]: (state: any) => updateAsset(state),
  [updateAssetRequestAction.type]: (state: any) => updateAssetRequest(state),
  [updateAssetSuccessAction.type]: (state: any, action) => updateAssetSuccess(state, action),
  [updateAssetErrorAction.type]: (state: any, action) => updateAssetError(state, action),
  [getAssetsMinimalInfoAction.type]: (state: any) => getAssetsMinimalInfo(state),
  [getAssetsMinimalInfoRequestAction.type]: (state: any) => getAssetsMinimalInfoRequest(state),
  [getAssetsMinimalInfoSuccessAction.type]: (state: any, action) => getAssetsMinimalInfoSuccess(state, action),
  [getAssetsMinimalInfoErrorAction.type]: (state: any, action) => getAssetsMinimalInfoError(state, action),
  [resetAction.type]: (state: any) => reset(state)
});

export default assetReducer;
