import Asset from "./asset";
import AssetForm from "./assetForm";

export default class AssetFormMapper {
  static toAsset(assetForm: AssetForm, siteId: string): Asset {
    const toNumber = (value: string | undefined): number | undefined => {
      const number = Number(value);
      return Number.isNaN(number) ? undefined : number;
    };

    return {
      name: assetForm.name,
      asset_number: assetForm.asset_number,
      site_id: siteId,
      customerReference: assetForm.customerReference,
      area: toNumber(assetForm.area),
      year_constructed: assetForm.year_constructed,
      year_renovated: assetForm.year_renovated,
      replacement_value: toNumber(assetForm.replacement_value),
      heritage: assetForm.heritage,
      pastIVP: assetForm.pastIVP,
      civic_number: assetForm.civic_number,
      street_name: assetForm.street_name,
      city: assetForm.city,
      postal_code: assetForm.postal_code,
      region: assetForm.region,
      province: assetForm.province,
      country: assetForm.country
    };
  }
}
