import { System } from "app/domain/system";

// TODO: review type for optionsLists (see app/domain/system/systemOptions)
export type SystemState = {
  isLoading: boolean;
  isFetchingOptions: boolean;
  systems: System[];
  filteredSystems: System[];
  currentSystem: System | undefined;
  optionsLists: any;
  errorMessage: string;
  newSystemId: string;
  shouldRedirectToComponentsPage: boolean;
  shouldRedirectToModifySystemPage: boolean;
};

export const initialSystemState: SystemState = {
  isLoading: false,
  isFetchingOptions: false,
  systems: [],
  filteredSystems: [],
  currentSystem: undefined,
  optionsLists: [],
  errorMessage: "",
  newSystemId: "",
  shouldRedirectToComponentsPage: false,
  shouldRedirectToModifySystemPage: false
};
