import { createSelector } from "@reduxjs/toolkit";
import { ColumnState } from "ag-grid-community";
import { Filter } from "app/domain/filter";
import AgGridCustomFilterState from "app/view/gridTemplates/agGridCustomFilterState";

import { State } from "../state";

const getTableFilters = (state: State, table: string): any => state.filter[table];

export const getFilters = createSelector([getTableFilters], (tableFilters: any): Filter[] => (tableFilters ? tableFilters.filters : []));
export const getFiltersV2 = createSelector([getTableFilters], (state: any): ColumnState[] => (state ? state.columnState : []));
export const getAgGridCustomFilterState = createSelector([getTableFilters], (state: any): any => (state ? state.model : null));
