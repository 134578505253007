import CalculationLine from "app/domain/calculation/calculationLine";

interface CalculationsProps {
  id?: number;
  linkedTo: string;
  type: string;
  data: CalculationLine[];
}

class Calculation {
  id?: number;
  linkedTo: string;
  type: string;
  data: CalculationLine[];

  constructor(calculationsProps: CalculationsProps) {
    this.id = calculationsProps.id;
    this.linkedTo = calculationsProps.linkedTo;
    this.type = calculationsProps.type;
    this.data = calculationsProps.data;
  }
}

export default Calculation;
