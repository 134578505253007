import { createReducer } from "typesafe-actions";

import { ConnectionState, initialConnectionState } from "./state";
import { ConnectionActionTypes, UpdateConnectionStatusAction } from "./types";

const connectionReducer = createReducer(initialConnectionState).handleType(ConnectionActionTypes.UPDATE_STATUS, (state: ConnectionState, action: UpdateConnectionStatusAction) => ({
  ...state,
  status: action.payload
}));

export default connectionReducer;
