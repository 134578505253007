import AuditProgress from "app/domain/asset/auditProgress";
import AuditProgressService from "app/service/asset/AuditProgressAPI";
import { notify } from "app/view/common/toast/Toast";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  getAllAuditProgressesErrorAction,
  getAllAuditProgressesRequestAction,
  getAllAuditProgressesSuccessAction,
  getAuditProgressErrorAction,
  getAuditProgressRequestAction,
  getAuditProgressSuccessAction,
  updateAuditProgressErrorAction,
  updateAuditProgressRequestAction,
  updateAuditProgressSuccessAction
} from "./actions";
import { AuditProgressActionTypes, GetAuditProgressAction, GetAuditProgressRequestAction, UpdateAuditProgressAction, UpdateAuditProgressRequestAction } from "./types";

export const GET_AUDIT_PROGRESS_ERROR = "Une erreur s'est produite lors de la récupération des informations de saisie de l'audit. Veuillez réessayer.";
export const GET_ALL_AUDIT_PROGRESSES_ERROR = "Une erreur s'est produite lors de la récupération des informations de saisie des audits. Veuillez réessayer.";
export const UPDATE_AUDIT_PROGRESS_ERROR = "Une erreur s'est produite lors de la mise à jour des informations de saisie de l'audit. Veuillez réessayer.";

export function* getAuditProgress(action: GetAuditProgressAction): Generator {
  yield put(getAuditProgressRequestAction(action.payload.assetId));
}

export function* getAuditProgressRequest(action: GetAuditProgressRequestAction): Generator {
  try {
    const auditProgress = yield call(AuditProgressService.getAuditProgress, action.payload.assetId);
    yield put(getAuditProgressSuccessAction(auditProgress as AuditProgress));
  } catch (error) {
    yield put(getAuditProgressErrorAction(GET_AUDIT_PROGRESS_ERROR));
  }
}

export function* getAllAuditProgresses(): Generator {
  yield put(getAllAuditProgressesRequestAction);
}

export function* getAllAuditProgressesRequest(): Generator {
  try {
    const auditProgresses = yield call(AuditProgressService.getAllAuditProgresses);
    yield put(getAllAuditProgressesSuccessAction(auditProgresses as AuditProgress[]));
  } catch (error) {
    yield put(getAllAuditProgressesErrorAction(GET_ALL_AUDIT_PROGRESSES_ERROR));
  }
}

export function* updateAuditProgress(action: UpdateAuditProgressAction): Generator {
  yield put(updateAuditProgressRequestAction(action.payload.auditProgress));
}

export function* updateAuditProgressRequest(action: UpdateAuditProgressRequestAction): Generator {
  try {
    const updatedAuditProgress = yield call(AuditProgressService.updateAuditProgress, action.payload.auditProgress);

    yield put(updateAuditProgressSuccessAction(updatedAuditProgress as AuditProgress));
    notify("Les informations d'avancement d'audit ont été modifiées avec succès");
  } catch (error) {
    yield put(updateAuditProgressErrorAction(UPDATE_AUDIT_PROGRESS_ERROR));
  }
}

function* auditProgressSaga(): Generator {
  yield takeLatest(AuditProgressActionTypes.GET_AUDIT_PROGRESS, getAuditProgress);
  yield takeLatest(AuditProgressActionTypes.GET_AUDIT_PROGRESS_REQUEST, getAuditProgressRequest);
  yield takeLatest(AuditProgressActionTypes.GET_ALL_AUDIT_PROGRESSES, getAllAuditProgresses);
  yield takeLatest(AuditProgressActionTypes.GET_ALL_AUDIT_PROGRESSES_REQUEST, getAllAuditProgressesRequest);
  yield takeLatest(AuditProgressActionTypes.UPDATE_AUDIT_PROGRESS, updateAuditProgress);
  yield takeLatest(AuditProgressActionTypes.UPDATE_AUDIT_PROGRESS_REQUEST, updateAuditProgressRequest);
}

export default auditProgressSaga;
