import Option from "app/domain/common/option";

interface RequirementItemOptionProps extends Option {
  parentLevel3: string;
  parentLevel4: string;
}

class RequirementItemOption extends Option {
  parentLevel3: string;
  parentLevel4: string;

  constructor(requirementItemOptionProps: RequirementItemOptionProps) {
    super({
      id: requirementItemOptionProps.id,
      text: requirementItemOptionProps.text,
      value: requirementItemOptionProps.value
    });
    this.parentLevel3 = requirementItemOptionProps.parentLevel3;
    this.parentLevel4 = requirementItemOptionProps.parentLevel4;
  }
}

export default RequirementItemOption;
