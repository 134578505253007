import { Comment } from "app/domain/comment";
import { createSelector } from "reselect";

import { State } from "../state";

export const getComments = createSelector([(state: State): Comment[] => state.comments.comments], (comments: Comment[]) => comments);

export const isFetching = createSelector([(state: State): boolean => state.comments.isFetching || state.comments.isFetching], (_isFetching: boolean) => _isFetching);

export const getErrorMessage = createSelector([(state: State): string => state.comments.errorMessage], (errorMessage) => errorMessage);
