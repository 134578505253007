import Asset from "app/domain/asset/asset";
import ErrorsMapper, { ErrorsDTO } from "app/service/common/errorsMapper";
import moment from "moment";

export type AssetDTO = {
  area?: number;
  area_to_inspect?: number;
  asset_number?: string;
  assessment_date?: string;
  calculated_condition_grading?: string;
  id?: string;
  site_id?: string;
  is_audit_done?: boolean;
  ivp?: number;
  mechanical_progress?: number;
  name?: string;
  past_ivp?: string;
  past_ri?: number;
  sum_requirements_prices?: number;
  site_name?: string;
  customer_reference?: string;
  asset_use?: string[];
  year_constructed?: number;
  year_renovated?: number;
  year_of_data?: number;
  nb_floors_basement?: number;
  nb_floors_total?: number;
  has_underground_parking?: boolean;
  audit_date?: string;
  auditor_building_envelope?: string;
  auditor_interior?: string;
  auditor_mechanical?: string;
  auditor_electrical?: string;
  building_cost_category?: string;
  replacement_value?: number;
  civic_number?: string;
  street_name?: string;
  city?: string;
  postal_code?: string;
  region?: string;
  province?: string;
  country?: string;
  heritage: boolean;
  description?: string;
  estimated_condition_grading?: string;
  architectural_progress?: number;
  client_comments?: string;
  errors?: ErrorsDTO;
  comments_count?: number;
};

class AssetMapper {
  static fromDTO(assetDTO: AssetDTO): Asset;
  static fromDTO(assetDTOs: AssetDTO[]): Asset[];
  static fromDTO(assetDTOs: AssetDTO | AssetDTO[]): Asset | Asset[] {
    if (!Array.isArray(assetDTOs)) {
      return new Asset({
        id: assetDTOs.id,
        errors: assetDTOs.errors ? ErrorsMapper.fromDTO(assetDTOs.errors) : [],
        site_id: assetDTOs.site_id,
        customerReference: assetDTOs.customer_reference,
        asset_number: assetDTOs.asset_number,
        name: assetDTOs.name,
        asset_use: assetDTOs.asset_use,
        year_constructed: assetDTOs.year_constructed ? moment().set("year", assetDTOs.year_constructed) : undefined,
        year_renovated: assetDTOs.year_renovated ? moment().set("year", assetDTOs.year_renovated) : undefined,
        area: assetDTOs.area,
        nb_floors_basement: assetDTOs.nb_floors_basement,
        nb_floors_total: assetDTOs.nb_floors_total,
        has_underground_parking: assetDTOs.has_underground_parking,
        auditDate: assetDTOs.audit_date,
        auditorBuildingEnvelope: assetDTOs.auditor_building_envelope,
        auditorInterior: assetDTOs.auditor_interior,
        auditorMechanical: assetDTOs.auditor_mechanical,
        auditorElectrical: assetDTOs.auditor_electrical,
        building_cost_category: assetDTOs.building_cost_category,
        replacement_value: assetDTOs.replacement_value,
        is_audit_done: assetDTOs.is_audit_done,
        civic_number: assetDTOs.civic_number,
        street_name: assetDTOs.street_name,
        city: assetDTOs.city,
        postal_code: assetDTOs.postal_code,
        region: assetDTOs.region,
        province: assetDTOs.province,
        country: assetDTOs.country,
        heritage: assetDTOs.heritage,
        description: assetDTOs.description,
        areaToInspect: assetDTOs.area_to_inspect,
        estimated_condition_grading: assetDTOs.estimated_condition_grading,
        calculatedConditionGrading: assetDTOs.calculated_condition_grading,
        architectural_progress: assetDTOs.architectural_progress,
        mechanical_progress: assetDTOs.mechanical_progress,
        ivp: assetDTOs.ivp,
        sum_requirements_prices: assetDTOs.sum_requirements_prices,
        pastIVP: assetDTOs.past_ivp,
        pastRI: assetDTOs.past_ri,
        client_comments: assetDTOs.client_comments,
        siteName: assetDTOs.site_name,
        commentsCount: assetDTOs.comments_count ? assetDTOs.comments_count : 0
      });
    }
    return assetDTOs.map((assetDTO: AssetDTO): Asset => this.fromDTO(assetDTO));
  }

  static toDTO(asset: Asset): AssetDTO;
  static toDTO(assets: Asset[]): AssetDTO[];
  static toDTO(assets: Asset | Asset[]): AssetDTO | AssetDTO[] {
    if (!Array.isArray(assets)) {
      return {
        id: assets.id,
        site_id: assets.site_id,
        asset_number: assets.asset_number,
        name: assets.name,
        asset_use: assets.asset_use,
        customer_reference: assets.customerReference,
        year_constructed: assets.year_constructed?.year(),
        year_renovated: assets.year_renovated?.year(),
        area: assets.area,
        nb_floors_basement: assets.nb_floors_basement,
        nb_floors_total: assets.nb_floors_total,
        has_underground_parking: assets.has_underground_parking,
        assessment_date: assets.assessment_date,
        year_of_data: assets.year_of_data,
        audit_date: assets.auditDate,
        auditor_building_envelope: assets.auditorBuildingEnvelope,
        auditor_interior: assets.auditorInterior,
        auditor_mechanical: assets.auditorMechanical,
        auditor_electrical: assets.auditorElectrical,
        building_cost_category: assets.building_cost_category,
        replacement_value: assets.replacement_value,
        is_audit_done: assets.is_audit_done,
        civic_number: assets.civic_number,
        street_name: assets.street_name,
        city: assets.city,
        postal_code: assets.postal_code,
        region: assets.region,
        province: assets.province,
        country: assets.country,
        heritage: assets.heritage,
        description: assets.description,
        area_to_inspect: assets.areaToInspect,
        estimated_condition_grading: assets.estimated_condition_grading,
        calculated_condition_grading: assets.calculatedConditionGrading,
        sum_requirements_prices: assets.sum_requirements_prices,
        past_ivp: assets.pastIVP,
        past_ri: assets.pastRI,
        client_comments: assets.client_comments
      };
    }
    return assets.map((asset: Asset): AssetDTO => this.toDTO(asset));
  }
}

export default AssetMapper;
