/* eslint-disable array-callback-return, consistent-return, no-plusplus */

import { EntityCategory } from "app/domain/entityCategory";
import { Filter } from "app/domain/filter";
import { QaError } from "app/domain/qaError";
import { useI18n } from "app/locales";
import { setFiltersAction } from "app/store/filter/actions";
import { getFilters } from "app/store/filter/selectors";
import { State } from "app/store/state";
import usePrevious from "app/view/common/hook/usePrevious";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import isEqual from "react-fast-compare";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { ColumnAlignment, Table } from "ui/table";
import useFilters from "ui/table/quickFilterPanel/useFilters";
import { Column, RowData } from "ui/table/types";
import useTable from "ui/table/useTable";

const userUserQaErrorTableStyle = css`
  && {
    @media screen and (max-width: 1200px) {
      font-size: 0.9em;
    }
  }
`;

interface UserQaErrorTableProps extends ComponentProps {
  qaErrors: QaError[];
  setSelectedRowKeys: (list: string[]) => void;
  getQaErrors: () => void;
}

const UserQaErrorTable: FC<UserQaErrorTableProps> = ({ qaErrors, setSelectedRowKeys, getQaErrors, className }: UserQaErrorTableProps) => {
  const filters = useSelector((state: State): Filter[] => getFilters(state, "userQaError"));
  const previousFilters = usePrevious(filters);

  const { onChange: onChangeFilter, onToggle: onToggleFilter, onDelete: onDeleteFilter, onReset: onResetFilters, filters: currentFilters } = useFilters(filters);

  const { onChange: onDataChange, rowSelectionProps } = useTable();

  const dispatch = useDispatch();

  const { dictionaryCode } = useI18n();

  useEffect(() => {
    if (!isEqual(previousFilters, currentFilters)) {
      dispatch(setFiltersAction("userQaError", currentFilters));
    }
  }, [dispatch, previousFilters, currentFilters]);

  useEffect(() => {
    setSelectedRowKeys(rowSelectionProps.selectedRowKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelectionProps.selectedRowKeys]);

  useEffect(() => {
    getQaErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = useCallback(
    (clickedRowData: any): void => {
      const baseURL = `/projects/${clickedRowData.projectId}/sites/${clickedRowData.siteId}/assets/${clickedRowData.assetId}`;
      if (clickedRowData.parentCategory.value === EntityCategory.SYSTEM) {
        window.open(`${baseURL}/components/${clickedRowData.systemId}`);
      } else if (clickedRowData.parentCategory.value === EntityCategory.REQUIREMENT) {
        window.open(`${baseURL}/requirements/${clickedRowData.systemId}`);
      } else {
        window.open(baseURL);
      }
    },

    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Projet",
        Footer: "",
        field: "projectName",
        sort: true,
        select: true,
        style: {
          width: 0.5
        }
      },
      {
        Header: "Site",
        Footer: "",
        field: "siteName",
        sort: true,
        select: true,
        style: {
          width: 0.5
        }
      },
      {
        Header: "Catégorie",
        Footer: "",
        field: "parentCategory",
        sort: true,
        select: true,
        style: {
          width: 0.5
        }
      },
      {
        Header: "No actif",
        Footer: "",
        field: "assetNumber",
        sort: true,
        select: true,
        style: {
          alignment: ColumnAlignment.CENTER,
          width: 0.5
        }
      },
      {
        Header: "Nom de l'actif",
        Footer: "",
        field: "assetName",
        sort: true,
        select: true
      },
      {
        Header: "Nom de l'élément",
        Footer: "",
        field: "parentName",
        onClick: handleRowClick,
        sort: true,
        select: true,
        style: {
          width: 1.5
        }
      },
      {
        Header: "Auditeur",
        Footer: "",
        field: "createdBy",
        sort: true,
        select: true,
        style: {
          width: 0.7
        }
      },
      {
        Header: "Nom de l'erreur",
        Footer: "",
        field: "ruleName",
        sort: true,
        select: true,
        style: {
          width: 1.5
        }
      }
    ],
    [handleRowClick]
  ) as Column[];

  const data = useMemo(
    () =>
      qaErrors.map((qaError: QaError) => ({
        id: String(qaError.errorId),
        systemId: qaError.parentId,
        projectId: qaError.projectId,
        siteId: qaError.siteId,
        assetId: qaError.assetId,
        parentCategory: {
          accessor: qaError.parentCategory,
          value: qaError.parentCategory
        },
        projectName: {
          accessor: qaError.projectName,
          value: qaError.projectName
        },
        siteName: {
          accessor: qaError.siteName,
          value: qaError.siteName
        },
        assetNumber: {
          accessor: qaError.assetNumber,
          value: qaError.assetNumber
        },
        assetName: {
          accessor: qaError.assetName,
          value: qaError.assetName
        },
        parentId: {
          accessor: qaError.parentId,
          value: qaError.parentReference
        },
        parentName: {
          accessor: qaError.parentName,
          value: qaError.parentName
        },
        createdBy: {
          accessor: qaError.createdBy,
          value: qaError.createdBy
        },
        ruleName: {
          accessor: qaError.errorMessage.format(dictionaryCode),
          value: qaError.errorMessage.format(dictionaryCode)
        }
      })),
    [qaErrors, dictionaryCode]
  ) as RowData[];

  return (
    <Table
      className={className}
      data={data}
      onDataChange={onDataChange}
      columns={columns}
      filters={currentFilters}
      onFilter={onChangeFilter}
      onToggleFilter={onToggleFilter}
      onDeleteFilter={onDeleteFilter}
      onResetFilters={onResetFilters}
      rowSelectionProps={rowSelectionProps}
    />
  );
};

export default createStyledComponent(memo(UserQaErrorTable), userUserQaErrorTableStyle);
