/* eslint-disable jsx-a11y/no-static-element-interactions */

import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Cell as ReactTableCell } from "react-table";
import { css } from "styled-components";
import { ColumnAlignment, RowData } from "ui/table/types";

import * as constants from "../constants";

const tableBodyRowCellStyle = css`
  &&& {
    text-align: ${(props: StyleProps): string => props.alignment || "left"};
    color: ${(props: StyleProps): string => (props.onClick ? "#1e70bf" : constants.DEFAULT_ROW_COLOR)};
    text-decoration: ${(props: StyleProps): string => (props.onClick ? "underline" : "none")};

    &:hover {
      cursor: ${(props: StyleProps): string => (props.onClick ? "pointer" : "default")};
    }
  }
`;

interface TableRowCellProps extends ComponentProps {
  cell: ReactTableCell;
  rowData: RowData;
  onClick?: (rowData: RowData) => void;
  alignment?: ColumnAlignment;
}

const TableBodyRowCell: FC<TableRowCellProps> = ({ cell, rowData, onClick, className }: TableRowCellProps) => {
  const handleClick = (): void => {
    if (onClick) {
      onClick(rowData);
    }
  };

  return (
    <div {...cell.getCellProps()} key={cell.getCellProps().key} onClick={handleClick} className={`${className} cell`}>
      {cell.render("Cell")}
    </div>
  );
};

export default createStyledComponent(TableBodyRowCell, tableBodyRowCellStyle);
