import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { forwardRef, memo } from "react";
import { FormProvider as ReactHookFormProvider } from "react-hook-form";
import { Form as SemanticForm } from "semantic-ui-react";
import { css } from "styled-components";

import FormContext from "./formContext";
import forwardRefAs from "./forwardRefAs";

const formStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;

  && .field {
    margin: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const ForwardableForm = forwardRefAs("form");

interface FormProps extends ComponentProps {
  children: any;
  context: FormContext;
  onSubmit?: (data: any, event: React.FormEvent<HTMLFormElement>) => void;
}

const Form = forwardRef(({ children, context, onSubmit, className }: FormProps, ref): JSX.Element => {
  const { getContextValues } = context;

  return (
    <ReactHookFormProvider {...getContextValues()} data-test-id="formContext">
      <SemanticForm onSubmit={getContextValues().handleSubmit(onSubmit)} className={className} noValidate as={ForwardableForm} forwardedRef={ref}>
        {children}
      </SemanticForm>
    </ReactHookFormProvider>
  );
});

Form.displayName = "Form";

export default createStyledComponent(memo(Form), formStyle);
