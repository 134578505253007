/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";
import { Comment } from "app/domain/comment";

import {
  addCommentAction,
  addCommentErrorAction,
  addCommentRequestAction,
  addCommentSuccessAction,
  getCommentsAction,
  getCommentsErrorAction,
  getCommentsRequestAction,
  getCommentsSuccessAction,
  resetAction,
  updateCommentAction,
  updateCommentErrorAction,
  updateCommentRequestAction,
  updateCommentSuccessAction
} from "./commentActions";
import { CommentState, initialCommentState } from "./commentState";
import {
  AddCommentErrorAction,
  AddCommentSuccessAction,
  GetCommentsErrorAction,
  GetCommentsSuccessAction,
  UpdateCommentErrorAction,
  UpdateCommentSuccessAction
} from "./commentTypes";

const replaceIn = (comments: Comment[], newComment: Comment): Comment[] => {
  const indexOfCommentToReplace = comments.findIndex((comment: Comment) => comment.id === newComment.id);
  comments[indexOfCommentToReplace] = newComment;
  return comments;
};

const getComments = (state: CommentState): void => {
  state.errorMessage = "";
};

const getCommentsRequest = (state: CommentState): void => {
  state.isFetching = true;
};

const getCommentsSuccess = (state: CommentState, action: GetCommentsSuccessAction): void => {
  state.isFetching = false;
  state.comments = action.payload.comments;
};

const getCommentsError = (state: CommentState, action: GetCommentsErrorAction): void => {
  state.isFetching = false;
  state.errorMessage = action.payload.errorMessage;
};

const addComment = (state: CommentState): void => {
  state.errorMessage = "";
};

const addCommentRequest = (state: CommentState): void => {
  state.isFetching = true;
};

const addCommentSuccess = (state: CommentState, action: AddCommentSuccessAction): void => {
  state.isFetching = false;
  state.comments = [...state.comments, action.payload.comment];
};

const addCommentError = (state: CommentState, action: AddCommentErrorAction): void => {
  state.isFetching = false;
  state.errorMessage = action.payload.errorMessage;
};

const updateComment = (state: CommentState): void => {
  state.errorMessage = "";
};

const updateCommentRequest = (state: CommentState): void => {
  state.isFetching = true;
};

const updateCommentSuccess = (state: CommentState, action: UpdateCommentSuccessAction): void => {
  state.isFetching = false;
  state.comments = replaceIn(state.comments, action.payload.comment);
};

const updateCommentError = (state: CommentState, action: UpdateCommentErrorAction): void => {
  state.isFetching = false;
  state.errorMessage = action.payload.errorMessage;
};

const reset = (state: CommentState): void => {
  state.errorMessage = "";
};

const commentReducer = createReducer(initialCommentState, {
  [getCommentsAction.type]: (state) => getComments(state),
  [getCommentsRequestAction.type]: (state) => getCommentsRequest(state),
  [getCommentsSuccessAction.type]: (state, action) => getCommentsSuccess(state, action),
  [getCommentsErrorAction.type]: (state, action) => getCommentsError(state, action),
  [addCommentAction.type]: (state) => addComment(state),
  [addCommentRequestAction.type]: (state) => addCommentRequest(state),
  [addCommentSuccessAction.type]: (state, action) => addCommentSuccess(state, action),
  [addCommentErrorAction.type]: (state, action) => addCommentError(state, action),
  [updateCommentAction.type]: (state) => updateComment(state),
  [updateCommentRequestAction.type]: (state) => updateCommentRequest(state),
  [updateCommentSuccessAction.type]: (state, action) => updateCommentSuccess(state, action),
  [updateCommentErrorAction.type]: (state, action) => updateCommentError(state, action),
  [resetAction.type]: (state) => reset(state)
});

export default commentReducer;
