class User {
  constructor({ email, password, nickname, name }) {
    this.email = email;
    this.password = password;
    this.nickname = nickname;
    this.name = name;
  }

  toJSON() {
    return {
      ...this,
    };
  }

  static parse({ email, password, name, nickname }) {
    return new User({
      email,
      password,
      nickname,
      name,
    });
  }
}

export default User;
