import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Button as SemanticButton } from "semantic-ui-react";
import { Icon, IconType } from "ui/icon";

import { style, styleModifiers } from "./style";

interface ButtonProps extends ComponentProps {
  title: string;
  onClick?: () => void;
  submit?: boolean;
  iconType?: IconType;
  color?: string;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({ title, iconType, onClick, submit, disabled, className }: ButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  return (
    <SemanticButton onClick={handleClick} disabled={disabled} type={submit ? "submit" : "button"} className={className} data-test-id="button">
      {iconType && <Icon type={iconType} />}
      <p className="title">{title}</p>
    </SemanticButton>
  );
};

export default createStyledComponent(Button, style, styleModifiers);
