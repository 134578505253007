import Local from "app/domain/local/local";
import api, { NEW_API_VERSION } from "app/service/common/api";

import LocalMapper, { LocalDTO } from "./localMapper";

class LocalService {
  static getLocals = async (assetId: string): Promise<Local[]> => {
    const localsDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/assets/${assetId}/rooms`
      })
    ).data as LocalDTO[];

    return LocalMapper.fromDTO(localsDTO);
  };

  static getRoom = async (roomId: string): Promise<Local> => {
    const localDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/rooms/${roomId}`
      })
    ).data as LocalDTO;

    return LocalMapper.fromDTO(localDTO);
  };
}

export default LocalService;
