import { Grid, TextField } from "@mui/material";
import Calculation from "app/domain/calculation/calculation";
import CalculationLine from "app/domain/calculation/calculationLine";
import CalculatorTable from "app/view/system/form/systemForm/formSections/calculator/calculatorTable";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { forwardRef, memo, useEffect } from "react";
import { Button } from "ui/button";
import Label from "ui/label/label";
import { Modal, useModal } from "ui/modal";

interface CalculatorProps extends ComponentProps {
  calculations: Calculation[];
  onUpdate: (calculations: Calculation[]) => void;
}

const getFirstOrNewCalculation = (calculations: Calculation[]): Calculation => {
  const hasCalculations = calculations && calculations.length > 0;
  return hasCalculations
    ? calculations[calculations.length - 1]
    : new Calculation({
        linkedTo: "quantity",
        type: "area",
        data: []
      });
};

const calculateTotal = (lines: CalculationLine[]): number => {
  let result = 0;
  lines.forEach((line: CalculationLine) => {
    if (line.operation) {
      switch (line.operation) {
        case "div":
          result += line.size / line.quantity;
          break;
        case "mul":
          result += line.size * line.quantity;
          break;
        default:
          result += line.size * line.quantity;
          break;
      }
    } else {
      result += line.size * line.quantity;
    }
  });
  return result;
};

const Calculator = forwardRef(({ calculations, onUpdate }: CalculatorProps): JSX.Element => {
  const { isModalOpen, openModal, closeModal } = useModal();
  const [calculation, setCalculation] = React.useState(getFirstOrNewCalculation(calculations));
  const [total, setTotal] = React.useState(calculateTotal(calculation.data));
  const [initialLines, setInitialLines] = React.useState(calculation.data);

  const setLines = (lines: CalculationLine[]): void => {
    setCalculation({ ...calculation, data: lines });
  };

  useEffect(() => {
    setCalculation(getFirstOrNewCalculation(calculations));
    setInitialLines(calculation.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculations]);

  useEffect(() => {
    setTotal(calculateTotal(calculation.data));
  }, [calculation.data]);

  const updateAndClose = (): void => {
    onUpdate([calculation]);
    closeModal();
  };

  const revertAndClose = (): void => {
    setLines(initialLines);
    closeModal();
  };

  return (
    <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
      <Grid container item spacing={2} alignContent="center">
        <Grid item xs={2} alignSelf="center">
          <Label label="Calcul Total" />
        </Grid>
        <Grid item xs={6} alignSelf="end">
          <TextField key="total" size="small" name="total" placeholder="0" value={total} />
        </Grid>
        <Grid item xs={4}>
          <Button title="Modifier Calcul" onClick={openModal} />
        </Grid>
      </Grid>
      <Modal
        isOpen={isModalOpen}
        content={<CalculatorTable lines={calculation.data} setLines={setLines} calculations={calculations} />}
        actions={[<Button onClick={updateAndClose} title="Accepter" key={0} />]}
        onClose={revertAndClose}
      />
    </Grid>
  );
});

Calculator.displayName = "Calcul";

export default memo(Calculator);
