import { Local } from "app/domain/local/index";
import React from "react";
import { RowData } from "ui/table/types";

export const createRoomRowData = (room: Local): RowData => {
  return {
    id: room.id,
    number: {
      accessor: room.number,
      value: room.number
    },
    area: {
      accessor: room.area,
      value: room.area
    },
    floorNumber: {
      accessor: room.floorNumber,
      value: room.floorNumber
    },
    description: {
      accessor: room.description,
      value: room.description
    }
  };
};
