import { CostLine } from "app/domain/costItem/index";

export type CostLineDTO = {
  cost_item_id: number;
  code: string;
  unit_cost: number;
  description: string;
  class_name: string;
  unit_of_measure: string;
  id?: number;
  quantity?: number;
  system_model_action_id?: string;
  uniformat_value?: string;
  uniformat_level_3?: string;
};

class CostLineMapper {
  static fromDTO(costLineDTO: CostLineDTO): CostLine;
  static fromDTO(costLineDTOs: CostLineDTO[]): CostLine[];
  static fromDTO(costLineDTOs: CostLineDTO | CostLineDTO[]): any {
    if (!Array.isArray(costLineDTOs)) {
      return new CostLine({
        costItemId: costLineDTOs.cost_item_id,
        code: costLineDTOs.code,
        unitCost: costLineDTOs.unit_cost,
        description: costLineDTOs.description,
        className: costLineDTOs.class_name,
        unitOfMeasure: costLineDTOs.unit_of_measure,
        id: costLineDTOs.id,
        quantity: costLineDTOs.quantity,
        systemModelActionId: costLineDTOs.system_model_action_id,
        uniformatLevel3: costLineDTOs.uniformat_value
      });
    }
    return costLineDTOs.map((costLineDTO: CostLineDTO): CostLine => this.fromDTO(costLineDTO));
  }

  static toDTO(costLine: CostLine): CostLineDTO;
  static toDTO(costLines: CostLine[]): CostLineDTO[];
  static toDTO(costLines: CostLine | CostLine[]): any {
    if (!Array.isArray(costLines)) {
      return {
        cost_item_id: costLines.costItemId,
        id: costLines.id,
        quantity: costLines.quantity
      };
    }
    return costLines.map((costLine: CostLine): CostLineDTO => this.toDTO(costLine));
  }
}

export default CostLineMapper;
