/* eslint-disable */
import "antd/dist/antd.css";

import { Tree } from "antd";
import { DataNode } from "antd/lib/tree";
import React, { FC, useEffect, useState } from "react";
import { useAsync } from "react-async";

// getData and defaultData MUST return a Promise<DataNode[]>.
// The handling of multiple layer must be done in the parent component in the getData function. Can add separator in the key.
const LazyTreeView: FC<{ checked: string[]; setChecked: Function; getData: Function; defaultData: Function; setAreDataLoading: Function; isSingleCheck?: boolean }> = (props) => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [nodes, setNodes] = useState<DataNode[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [oldSelectedValue, setOldSelectedValue] = useState<string>("");

  const handleSuccess = (fetchedData: DataNode[]): void => {
    setNodes(fetchedData);
    props.setAreDataLoading(false);
  };

  const loadDefaultData = async (): Promise<DataNode[]> => {
    props.setAreDataLoading(true);
    return await props.defaultData();
  };

  const { run } = useAsync({
    deferFn: loadDefaultData,
    onResolve: handleSuccess
  });

  useEffect(() => {
    run();
  }, []);

  const setAreChildrenCheckable = (selectedElementKey: string, currentTree: DataNode[], oldChecked?: string): DataNode[] => {
    return currentTree.map((node) => {
      if (node.children !== undefined) {
        if (node.key !== selectedElementKey && node.key !== oldChecked) {
          return {
            ...node,
            children: setAreChildrenCheckable(selectedElementKey, node.children, oldChecked)
          };
        }

        return {
          ...node,
          children: node.children.map((element) => {
            return {
              key: element.key,
              title: element.title,
              checkable: !(node.key === selectedElementKey),
              isLeaf: true
            };
          })
        };
      }

      return node;
    });
  };

  const onCheck = (checkedKeysValue: React.Key[] | any): void => {
    if (props.isSingleCheck) {
      if (checkedKeysValue.length !== 0) {
        if (oldSelectedValue) {
          checkedKeysValue.shift();
        }
        while (checkedKeysValue.length > 1) {
          checkedKeysValue.pop();
        }
      }

      setNodes(setAreChildrenCheckable(checkedKeysValue[0] as string, nodes, oldSelectedValue));
      setOldSelectedValue(checkedKeysValue[0] as string);
    }
    props.setChecked(checkedKeysValue);
  };

  const onExpand = (expandedKeysValue: React.Key[]): void => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const addNewElement = (key: string, children: DataNode[], currentTree: DataNode[]): DataNode[] => {
    return currentTree.map((node) => {
      if (node.children !== undefined) {
        return {
          ...node,
          children: addNewElement(key, children, node.children)
        };
      }

      if (node.key === key) {
        return {
          ...node,
          children: children.map((childNode) => {
            return {
              ...childNode,
              checkable: props.isSingleCheck && props.checked[0] === node.key ? false : childNode.checkable
            };
          })
        };
      }

      return node;
    });
  };

  const loadData = ({ key, children }: any): Promise<void> => {
    return new Promise<void>(async (resolve, reject) => {
      if (children) {
        return resolve();
      }

      setNodes(addNewElement(key, await props.getData(key), nodes));
      return resolve();
    });
  };

  return (
    <Tree
      checkable
      loadData={loadData}
      selectable={false}
      treeData={nodes}
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      onCheck={onCheck}
      checkedKeys={props.checked}
    />
  );
};

export default LazyTreeView;
