import Reply from "app/domain/comment/reply";
import { EntityCategory } from "app/domain/entityCategory";

interface CommentProps {
  id?: number;
  parentId: string;
  parentType: EntityCategory;
  avatar: string;
  fieldName: string;
  text: string;
  replies: Reply[];
  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  resolvedBy?: string;
  canEdit: boolean;
  isRead: boolean;
}

class Comment {
  id?: number;
  parentId: string;
  parentType: EntityCategory;
  avatar: string;
  fieldName: string;
  text: string;
  replies: Reply[];
  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  resolvedBy?: string;
  canEdit: boolean;
  isRead: boolean;

  constructor(props: CommentProps) {
    this.id = props.id;
    this.parentId = props.parentId;
    this.parentType = props.parentType;
    this.avatar = props.avatar;
    this.fieldName = props.fieldName;
    this.text = props.text;
    this.replies = props.replies;
    this.createdBy = props.createdBy;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
    this.resolvedBy = props.resolvedBy;
    this.canEdit = props.canEdit;
    this.isRead = props.isRead;
  }
}

export default Comment;
