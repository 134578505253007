import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import compare from "react-fast-compare";

import SelectableCard from "../card/selectableCard";
import { cardGridStyle } from "./style";

interface SelectableCardGridProps extends ComponentProps {
  children: JSX.Element[];
  onOpenImage: (imageIndex: number) => void;
  onSelect: (cardKey: string) => void;
}

const isEqual = (prevProps: SelectableCardGridProps, nextProps: SelectableCardGridProps): boolean => compare(prevProps, nextProps);

const SelectableCardGrid: FC<SelectableCardGridProps> = ({ children, onOpenImage, onSelect, className }: SelectableCardGridProps) => (
  <div className={className}>
    {children.map((card, index) => (
      <SelectableCard key={card.key as string} imageIndex={index} onOpenImage={onOpenImage} onClick={(): void => onSelect(card.key as string)}>
        {card}
      </SelectableCard>
    ))}
  </div>
);

export default createStyledComponent(memo(SelectableCardGrid, isEqual), cardGridStyle);
