import { Asset } from "app/domain/asset";
import { FieldNames } from "app/service/comment/field/fieldNames";

export default class AssetFieldValueFetcher {
  getFieldValue(asset: Asset, fieldName: string): string {
    switch (fieldName) {
      case FieldNames.YEAR_CONSTRUCTED: {
        return String(asset.year_constructed);
      }
      case FieldNames.AREA: {
        return String(asset.area);
      }
      case FieldNames.AUDIT_DATE: {
        return String(asset.auditDate);
      }
      case FieldNames.AUDITOR_BUILDING_ENVELOPE: {
        return String(asset.auditorBuildingEnvelope);
      }
      case FieldNames.AUDITOR_INTERIOR: {
        return String(asset.auditorInterior);
      }
      case FieldNames.AUDITOR_MECHANICAL: {
        return String(asset.auditorMechanical);
      }
      case FieldNames.AUDITOR_ELECTRICAL: {
        return String(asset.auditorElectrical);
      }
      case FieldNames.REPLACEMENT_VALUE: {
        return String(asset.replacement_value);
      }
      case FieldNames.DESCRIPTION: {
        return String(asset.description);
      }
      case FieldNames.ESTIMATED_CONDITION_GRADING: {
        return String(asset.estimated_condition_grading);
      }
      default: {
        return "";
      }
    }
  }
}
