import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import usePrevious from "../../app/view/common/hook/usePrevious";

type ErrorMessageHook = {
  errorMessage: string;
  onUpdate: (errorMessage: string) => void;
  onDismiss: () => void;
};

const useErrorMessage = (initialErrorMessage?: string | string[]): ErrorMessageHook => {
  const getErrorMessage = (errorMessage?: string | string[]): string => {
    if (errorMessage) {
      if (Array.isArray(errorMessage)) {
        for (let index = 0; index < errorMessage.length; index += 1) {
          if (errorMessage[index] !== "") {
            return errorMessage[index];
          }
        }
        return "";
      }
      return errorMessage as string;
    }
    return "";
  };

  const [currentErrorMessage, setCurrentErrorMessage] = useState(getErrorMessage(initialErrorMessage));
  const previousInitialErrorMessage = usePrevious(initialErrorMessage);

  useEffect(() => {
    if (!isEqual(previousInitialErrorMessage, initialErrorMessage)) {
      setCurrentErrorMessage(getErrorMessage(initialErrorMessage));
    }
  }, [previousInitialErrorMessage, initialErrorMessage]);

  const onUpdate = useCallback((errorMessage: string) => {
    setCurrentErrorMessage(errorMessage);
  }, []);

  const onDismiss = useCallback(() => {
    onUpdate("");
  }, [onUpdate]);

  return useMemo(
    () => ({
      errorMessage: currentErrorMessage,
      onUpdate,
      onDismiss
    }),
    [currentErrorMessage, onUpdate, onDismiss]
  );
};

export default useErrorMessage;
