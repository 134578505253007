import { Dictionary, Form, Gender } from "../types";
import FrenchWord from "./frenchWord";

const Attributes: Dictionary<FrenchWord> = {
  action_description: new FrenchWord("description de l'action", Gender.F, Form.S),
  action_name: new FrenchWord("nom de l'action", Gender.M, Form.S),
  action_name_suffix: new FrenchWord("détails de l'action", Gender.M, Form.S),
  action_type: new FrenchWord("type d'action", Gender.M, Form.S),
  activity: new FrenchWord("activité/action", Gender.F, Form.S),
  age_range: new FrenchWord("catégorie d'âge", Gender.F, Form.S),
  asset_id: new FrenchWord("actif parent", Gender.M, Form.S),
  audit_date: new FrenchWord("date de l'audit", Gender.F, Form.S),
  auditor: new FrenchWord("auditeur", Gender.M, Form.S),
  auditor_building_envelope: new FrenchWord("auditeur enveloppe extérieure", Gender.M, Form.S),
  auditor_electrical: new FrenchWord("auditeur électricité", Gender.M, Form.S),
  auditor_interior: new FrenchWord("auditeur intérieur", Gender.M, Form.S),
  auditor_mechanical: new FrenchWord("auditeur mécanique", Gender.M, Form.S),
  category: new FrenchWord("catégorie", Gender.F, Form.S),
  condition_grading: new FrenchWord("cote d'état (avant réparations)", Gender.F, Form.S),
  condition_grading_after_repair: new FrenchWord("cote d'état (après réparations)", Gender.F, Form.S),
  construction_year: new FrenchWord("année de construction", Gender.F, Form.S),
  cost_lines: new FrenchWord("lignes de coût", Gender.F, Form.P),
  custom_name: new FrenchWord("nom modifié", Gender.M, Form.S),
  customer_reference: new FrenchWord("référence client", Gender.F, Form.S),
  description: new FrenchWord("description", Gender.F, Form.S),
  estimated_lifetime: new FrenchWord("durée de vie résiduelle (évaluée)", Gender.F, Form.S),
  identification: new FrenchWord("identification", Gender.F, Form.S),
  installation_year: new FrenchWord("année d'installation", Gender.F, Form.S),
  lifetime: new FrenchWord("durée de vie résiduelle (calculée)", Gender.F, Form.S),
  localisation: new FrenchWord("localisation", Gender.F, Form.S),
  name: new FrenchWord("nom", Gender.M, Form.S),
  number_of_photos: new FrenchWord("nombre de photos", Gender.M, Form.S),
  priority: new FrenchWord("priorité", Gender.F, Form.S),
  quantity: new FrenchWord("quantité", Gender.F, Form.S),
  reference: new FrenchWord("référence", Gender.F, Form.S),
  renewal_percentage: new FrenchWord("pourcentage de renouvellement", Gender.M, Form.S),
  requirement_category: new FrenchWord("type", Gender.M, Form.S),
  requirement_items: new FrenchWord("éléments concernés", Gender.M, Form.P),
  requirement_locals: new FrenchWord("local(aux)", Gender.M, Form.P),
  requirement_problematics: new FrenchWord("problématiques", Gender.F, Form.P),
  systems_locals: new FrenchWord("local(aux)", Gender.M, Form.P),
  total_cost: new FrenchWord("coût total", Gender.M, Form.S),
  uniformat1: new FrenchWord("uniformat niveau 1", Gender.M, Form.S),
  uniformat2: new FrenchWord("uniformat niveau 2", Gender.M, Form.S),
  uniformat3: new FrenchWord("uniformat niveau 3", Gender.M, Form.S),
  uniformat4: new FrenchWord("uniformat niveau 4", Gender.M, Form.S),
  unit_cost: new FrenchWord("coût unitaire", Gender.M, Form.S),
  unit_of_measure: new FrenchWord("unité de mesure", Gender.F, Form.S),
  unit_of_measure_factor: new FrenchWord("ajustement unité de mesure", Gender.M, Form.S),
  useful_life: new FrenchWord("durée de vie utile", Gender.F, Form.S),
  year_planned: new FrenchWord("année prévue", Gender.F, Form.S)
};

export default Attributes;
