/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Container, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Cable } from "actioncable";
import ReportService from "app/service/report/reportService";
import { downloadLink } from "app/utils/downloadLink";
import FileDownloader from "app/utils/fileDownloader";
import { getUserId } from "app/utils/getUserId";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import ProjectsSitesTreeView from "app/view/project/projectsSitesTreeView";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useAsync } from "react-async";
import styled from "styled-components";
import { Button, FileUploadButton } from "ui/button";
import { ErrorMessage } from "ui/errorMessage";
import { Loader } from "ui/loader";

interface ImportCustomerReferenceProps extends ComponentProps {
  cable: Cable;
}

const StyledContainer = styled.div`
  div > * {
    margin: 5px 0;
  }

  .section {
    padding: 10px 0;
  }
`;

const ImportCustomerReference: FC<ImportCustomerReferenceProps> = ({ cable }: ImportCustomerReferenceProps) => {
  const UPLOAD_FILE = "Téléverser un fichier";

  const availableTables = [
    { id: "assets", name: "Actifs" },
    { id: "systems", name: "Systèmes" },
    { id: "requirements", name: "Exigences" },
    { id: "actions", name: "Actions" },
    { id: "system_lines", name: "Lignes de coût des systèmes" },
    { id: "action_lines", name: "Lignes de coût des exigences (actions)" }
  ];

  const [selectedTable, setSelectedTable] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(true);
  const { errorMessage, onDismiss, onUpdate: onUpdateErrorMessage } = useErrorMessage();
  const [uploadCustomerReferenceMessage, setUploadCustomerReferenceMessage] = useState("");

  const uploadCustomerReferencesFile = ([file]: any): Promise<any> => {
    setUploadCustomerReferenceMessage("Mise à jour en cours ...");
    return ReportService.uploadCustomerReferences(file);
  };

  const handleUploadCustomerReferenceSuccess = (): void => {
    setIsLoading(false);
    setUploadCustomerReferenceMessage("Le fichier a été téléversé.");
  };

  const handleExportToVFASuccess = (result: any): void => {
    FileDownloader.download(result.url as string, result.fileName as string);
    setIsLoading(false);
  };

  const handleUploadCustomerReferenceError = (error: any): void => {
    if (error.code !== "ERR_NETWORK") {
      onUpdateErrorMessage("Une erreur s'est produite lors de la mise à jour des références client. Veuillez réessayer.");
      setUploadCustomerReferenceMessage("");
    }
  };

  const handleExportToVFAError = (): void => {
    setIsLoading(false);
    onUpdateErrorMessage("Une erreur s'est produite lors de l'exportation des données. Veuillez réessayer.");
  };

  const { run } = useAsync({
    deferFn: uploadCustomerReferencesFile,
    onResolve: handleUploadCustomerReferenceSuccess,
    onReject: handleUploadCustomerReferenceError
  });

  const exportDataToVFA = ([siteIds, tableName]: any): Promise<any> => {
    return ReportService.exportDataForVFA(siteIds, tableName);
  };

  const { run: runExportToVFA } = useAsync({
    deferFn: exportDataToVFA,
    onResolve: handleExportToVFASuccess,
    onReject: handleExportToVFAError
  });

  const handleFileUpload = (event: any): void => {
    run(event.target.files[0]);
  };

  const handleTableChange = (event: any): void => {
    setSelectedTable(event.target.value);
  };

  const exportData = (): void => {
    setIsLoading(true);
    runExportToVFA(checked, selectedTable);
  };

  useEffect(() => {
    if (checked && checked.length > 0 && selectedTable) {
      setIsExportButtonDisabled(false);
    }
  }, [selectedTable, checked]);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    cable.subscriptions.create(
      { channel: "UpdateCustomerReferencesChannel", user_id: getUserId() },
      {
        received: (data) => {
          if (data.status === "DONE") {
            const blob = new Blob([atob(data.file)], { type: "data:application/octet-stream;base64" });
            const url = window.URL.createObjectURL(blob);
            downloadLink(data.filename, url);
          }
        }
      }
    );
  }, []);

  return (
    <StyledContainer>
      <div>
        <ErrorMessage message={errorMessage} onDismiss={onDismiss} />
        <Loader isActive={isLoading} />
        <Container fixed>
          <div className="section">
            <Typography variant="h5">Exportation des données Unifika</Typography>
            <ProjectsSitesTreeView checked={checked} setChecked={setChecked} />
            <Box sx={{ maxWidth: 500 }}>
              <FormControl fullWidth>
                <InputLabel id="tableOptions">Sélectionner la table</InputLabel>
                <Select labelId="tableOptions" autoWidth value={selectedTable} onChange={handleTableChange}>
                  {availableTables.map((table) => (
                    <MenuItem key={table.id} value={table.id}>
                      {table.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Button onClick={exportData} title="Exporter" disabled={isExportButtonDisabled} />
          </div>
          <Divider />
          <div className="section">
            <Typography variant="h5">Importation des identifiants dans Unifika</Typography>
            <FileUploadButton onChange={handleFileUpload} title={UPLOAD_FILE} />
            <div>{uploadCustomerReferenceMessage}</div>
          </div>
        </Container>
      </div>
    </StyledContainer>
  );
};

export default ImportCustomerReference;
