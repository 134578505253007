import { Params, useRouter } from "app/routing/routerProvider";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { useLoader } from "app/view/common/loader/loaderProvider";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";
import { Loader } from "ui/loader";

import RoomForm from "./form/roomForm";

const modifyRoomContainerStyle = css`
  position: relative;
  flex: 1;
`;

type ModifyRoomContainerProps = ComponentProps;

const ModifyRoomContainer: FC<ModifyRoomContainerProps> = ({ className }: ModifyRoomContainerProps) => {
  const { isActive } = useLoader();
  const { getParam } = useRouter();

  const projectId = getParam(Params.PROJECT);
  const siteId = getParam(Params.SITE);
  const assetId = getParam(Params.ASSET);
  const { errorMessage, onDismiss, onUpdate } = useErrorMessage();

  return (
    <div className={className}>
      <Loader isActive={isActive} />
      <RoomForm projectId={projectId} siteId={siteId} assetId={assetId} errorMessage={errorMessage} onReset={onDismiss} onUpdateError={onUpdate} />
    </div>
  );
};

export default createStyledComponent(ModifyRoomContainer, modifyRoomContainerStyle);
