import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";

import Actions from "./actions";

export const toolbarStyle = css`
  display: flex;
`;

interface ToolbarProps extends ComponentProps {
  actions?: JSX.Element | Array<JSX.Element | boolean>;
}

const Toolbar: FC<ToolbarProps> = ({ actions, className }: ToolbarProps) => <div className={className}>{actions && <Actions>{actions}</Actions>}</div>;

export default createStyledComponent(Toolbar, toolbarStyle);
