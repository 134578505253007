import { Comment, Reply } from "app/domain/comment";
import { EntityCategory } from "app/domain/entityCategory";
import { CommentRequest } from "app/service/comment/commentRequest";
import { CommentResponse } from "app/service/comment/commentResponse";
import CommentEntityMapper from "app/service/comment/entity/commentEntityMapper";
import { ReplyRequest } from "app/service/comment/reply/replyRequest";
import { ReplyResponse } from "app/service/comment/reply/replyResponse";
import Avatars from "app/view/comment/avatars";

const formatDate = (value: string | undefined): Date | undefined => {
  return value ? new Date(value) : undefined;
};

const mapToReplies = (replyRequests: ReplyRequest[]): Reply[] => {
  return replyRequests.map(
    (replyRequest: ReplyRequest) =>
      new Reply({
        id: replyRequest.id,
        avatar: Avatars.Default,
        text: replyRequest.description,
        createdBy: replyRequest.created_by,
        createdAt: formatDate(replyRequest.created_at),
        updatedAt: formatDate(replyRequest.updated_at),
        canEdit: replyRequest.can_edit,
        isRead: replyRequest.is_read
      })
  );
};

const mapToReplyRequests = (replies: Reply[]): ReplyResponse[] => {
  return replies.map((reply: Reply) => {
    return {
      id: reply.id,
      description: reply.text
    };
  });
};

class CommentMapper {
  static fromDTO(commentRequest: CommentRequest, parentType: EntityCategory, parentId: string): Comment;
  static fromDTO(commentRequests: CommentRequest[], parentType: EntityCategory, parentId: string): Comment[];
  static fromDTO(commentRequests: CommentRequest | CommentRequest[], parentType: EntityCategory, parentId: string): any {
    if (!Array.isArray(commentRequests)) {
      return new Comment({
        id: commentRequests.id,
        parentType,
        parentId,
        avatar: Avatars.Default,
        fieldName: commentRequests.field_name,
        text: commentRequests.description,
        replies: mapToReplies(commentRequests.replies),
        createdBy: commentRequests.created_by,
        createdAt: formatDate(commentRequests.created_at),
        updatedAt: formatDate(commentRequests.updated_at),
        resolvedBy: commentRequests.resolved_by,
        canEdit: commentRequests.can_edit,
        isRead: commentRequests.is_read
      });
    }

    return commentRequests.map((commentRequest: CommentRequest): Comment => this.fromDTO(commentRequest, parentType, parentId));
  }

  static toDTO(comment: Comment): CommentResponse;
  static toDTO(comments: Comment[]): CommentResponse[];
  static toDTO(comments: Comment | Comment[]): any {
    if (!Array.isArray(comments)) {
      return {
        id: comments.id,
        ...CommentEntityMapper.toDTO(comments),
        field_name: comments.fieldName,
        description: comments.text,
        replies_attributes: mapToReplyRequests(comments.replies),
        resolved_by: comments.resolvedBy
      };
    }

    return comments.map((comment: Comment): CommentResponse => this.toDTO(comment));
  }
}

export default CommentMapper;
