/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control, react-hooks/exhaustive-deps */

import { Autocomplete, Grid, TextField } from "@mui/material";
import { AssetMinimalInfo } from "app/domain/asset";
import Calculation from "app/domain/calculation/calculation";
import { CostLine } from "app/domain/costItem";
import { Local } from "app/domain/local";
import { System } from "app/domain/system";
import { useI18n } from "app/locales";
import { useRouter } from "app/routing/routerProvider";
import { getLocalsAction } from "app/store/local/actions";
import { State } from "app/store/state";
import { DropdownContainer, FormFieldGroup, Separator } from "app/view/common/form";
import Calculator from "app/view/system/form/systemForm/formSections/calculator/calculator";
import ManufacturerSection from "app/view/system/form/systemForm/formSections/systemInformation/manufacturerSection";
import MonetaryInformation from "app/view/system/form/systemForm/formSections/systemInformation/monetaryInformation";
import SystemNameSection from "app/view/system/form/systemForm/formSections/systemInformation/systemNameSection";
import { SystemFields } from "app/view/system/form/systemForm/useSystem";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { forwardRef, RefObject, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form as SemanticForm, Segment as SemanticSegment } from "semantic-ui-react";
import { Checkbox, CheckboxGroup } from "ui/form/controls/checkbox";
import InputLabel from "ui/inputLabel/inputLabel";
import Label from "ui/label/label";

import CostInformation from "./costInformation";
import DescriptionForm from "./descriptionForm";

interface SystemInformationProps extends ComponentProps {
  system: System | undefined;
  assetsMinimalInfo: AssetMinimalInfo[];
  onUpdate: (fields: SystemFields) => void;
  onSystemReset: () => void;
  onActionReset: () => void;
  isElementInCreationMode: boolean;
  onNavigationBlock: () => void;
  projectId: string;
  siteId: string;
  assetId: string;
  onCostLinesChange: (costLine: CostLine[]) => void;
  calculations: Calculation[];
  systemWithoutModel?: boolean;
  withModel: () => void;
}

const SystemInformation = forwardRef(
  (
    {
      system,
      assetsMinimalInfo,
      onUpdate,
      isElementInCreationMode,
      onNavigationBlock,
      projectId,
      siteId,
      onCostLinesChange,
      calculations,
      systemWithoutModel,
      withModel
    }: SystemInformationProps,
    ref
  ): JSX.Element => {
    const { getAttributeLabel } = useI18n();
    const { getStateParam } = useRouter();

    const localisationOptions = [
      { text: "Ensemble de la phase" },
      { text: "Façade" },
      { text: "Toit" },
      { text: "Toiture" },
      { text: "Whole Phase" },
      { text: "Facade" },
      { text: "Roof" }
    ];

    const dispatch = useDispatch();
    const [listedLocals, setListedLocals] = useState([] as Local[]);
    const [localArea, setLocalArea] = useState<string>();

    const selectedRoomsId = getStateParam("selectedRoomsId");

    const locals = useSelector((state: State): Local[] => state.locals.locals);

    const findLocal = (localId: string): Local | undefined => locals.find((local: Local) => local.id === localId);

    const fromLocalsToLocalIds = (systemLocals: Local[]): string[] => systemLocals.map((local: Local) => local.id as string);

    const fromLocalIdsToLocals = (localIds: string[]): Local[] => {
      const systemLocals = [];
      for (let i = 0; i < localIds.length; i += 1) {
        const associatedLocal = findLocal(localIds[i]);
        if (associatedLocal) {
          systemLocals.push(
            new Local({
              id: localIds[i],
              number: associatedLocal.number,
              area: associatedLocal.area
            })
          );
        }
      }
      return systemLocals;
    };

    useEffect(() => {
      const systemLocals = system?.locals;
      if (systemLocals && systemLocals.length > 0) {
        setLocalArea(
          systemLocals
            .map((local: Local): number => {
              return local.area || 0;
            })
            .reduce((previous, current): number => {
              return current + previous;
            })
            .toFixed(2)
        );
      } else {
        setLocalArea("");
      }
    }, [system]);

    useEffect(() => {
      if (selectedRoomsId) {
        onUpdate({ locals: fromLocalIdsToLocals(selectedRoomsId) });
      }
    }, [selectedRoomsId]);

    useEffect(() => {
      if (locals) {
        setListedLocals(locals);
      }
    }, [locals]);

    const findAssetArea = (associatedAssetId: string): number => {
      const associatedAsset = assetsMinimalInfo.find((assetMinimalInfo: AssetMinimalInfo) => assetMinimalInfo.id === associatedAssetId);
      return associatedAsset ? associatedAsset.area : 0;
    };

    const handleParentAssetChange = (event: any, data: any): void => {
      event.preventDefault();
      dispatch(getLocalsAction(data.value));
      if (system && system.isLinkedToArea) {
        onUpdate({
          [data.name]: data.value,
          quantity: findAssetArea(data.value)
        });
      } else {
        onUpdate({ [data.name]: data.value, locals: [] });
      }
      onNavigationBlock();
    };

    const handleChange = (event: any): void => {
      event.preventDefault();

      onUpdate({ [event.target.name]: event.target.value });
      onNavigationBlock();
    };

    const handleLocalisationInputChange = (event: any): void => {
      onUpdate({ localisation: event.target.value });
      onNavigationBlock();
    };

    const handleLocalisationAutoComplete = (event: any, newInputValue: any): void => {
      if (newInputValue) {
        onUpdate({ localisation: newInputValue.text });
      }
    };

    const handleLocalsChange = (event: any, data: any): void => {
      event.preventDefault();

      onUpdate({ [data.name]: fromLocalIdsToLocals(data.value) });
      onNavigationBlock();
    };

    const handleCheckChange = (event: any): void => {
      onUpdate({ [event.currentTarget.name]: event.currentTarget.checked });
      onNavigationBlock();
    };

    const mapToLocalOptions = (assetLocals: Local[]): any =>
      assetLocals.map((local: Local) => ({
        key: local.id,
        text: local.number,
        value: local.id
      }));

    const updateCalculations = (updatedCalculations: Calculation[]): void => {
      onUpdate({ calculations: updatedCalculations });
    };

    const hasEnabledManufacturerSection = (site: string): boolean => {
      const authorizedSites = ["979000-2", "979000-3", "979000-4", "979000-5", "923000"];
      return authorizedSites.includes(site);
    };

    return (
      <div>
        <h3 ref={ref as RefObject<any>}>
          <span>1.</span> Informations du système
        </h3>
        {!isElementInCreationMode ? (
          <FormFieldGroup>
            <SemanticForm.Dropdown
              selection
              search
              label={getAttributeLabel("asset_id")}
              onChange={handleParentAssetChange}
              name="assetId"
              value={system && system.assetId}
              options={
                assetsMinimalInfo
                  ? assetsMinimalInfo.map((assetMinimalInfo: AssetMinimalInfo) => ({
                      key: `asset-${assetMinimalInfo.id}`,
                      text: `${assetMinimalInfo.id} - ${assetMinimalInfo.name}`,
                      value: assetMinimalInfo.id
                    }))
                  : []
              }
            />
          </FormFieldGroup>
        ) : null}
        <SystemNameSection
          projectId={projectId}
          isElementInCreationMode={isElementInCreationMode}
          system={system}
          onUpdate={onUpdate}
          onCostLinesChange={onCostLinesChange}
          systemWithoutModel={systemWithoutModel}
          withModel={withModel}
        />
        <Separator />
        <FormFieldGroup row>
          <CheckboxGroup title="Ajouter au nom:">
            <Checkbox
              label={getAttributeLabel("identification")}
              name="isIdentificationInName"
              isChecked={system && system.isIdentificationInName ? system.isIdentificationInName : false}
              onClick={handleCheckChange}
            />
            <Checkbox
              label="Le numéro du local"
              name="isLocalNumberInName"
              isChecked={system && system.isLocalNumberInName ? system.isLocalNumberInName : false}
              onClick={handleCheckChange}
            />
            <Checkbox
              label={getAttributeLabel("localisation")}
              name="isLocalisationInName"
              isChecked={system && system.isLocalisationInName ? system.isLocalisationInName : false}
              onClick={handleCheckChange}
            />
            <Checkbox
              label={getAttributeLabel("installation_year")}
              name="isInstallationYearInName"
              isChecked={system && system.isInstallationYearInName ? system.isInstallationYearInName : false}
              onClick={handleCheckChange}
            />
          </CheckboxGroup>
        </FormFieldGroup>
        <Separator />
        <Grid container spacing={3}>
          <Grid container item spacing={2}>
            <Grid item xs={2} alignContent="end">
              <Label label={getAttributeLabel("identification")} />
            </Grid>

            <Grid item xs={3}>
              <TextField key="identification" size="small" name="identification" value={system && system.identification ? system.identification : ""} onChange={handleChange} />
            </Grid>

            <Grid item xs={2} alignContent="end">
              <Label label="ID Cégep" />
            </Grid>
            <Grid item xs={3}>
              {siteId && siteId === "904001" ? (
                <TextField key="serialNumber" size="small" name="serialNumber" value={system && system.serialNumber ? system.serialNumber : ""} onChange={handleChange} />
              ) : null}
            </Grid>
          </Grid>

          {hasEnabledManufacturerSection(siteId) ? <ManufacturerSection system={system} onUpdate={onUpdate} /> : null}
          <Grid container item spacing={3}>
            <Grid item xs={2} alignContent="end" alignSelf="center">
              <Label label="Numéro de modèle" />
            </Grid>
            <Grid item xs={3} alignContent="end" alignSelf="center">
              <InputLabel key="model_number" label="" text={system && system.model_number ? system.model_number : ""} />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={1} alignContent="end" alignSelf="center">
              <Label label="Local(aux)" />
            </Grid>
            <Grid item xs={5} alignContent="end" alignSelf="center">
              <DropdownContainer>
                <SemanticForm.Dropdown
                  required
                  search
                  multiple
                  name="locals"
                  value={system && system.locals ? fromLocalsToLocalIds(system.locals) : []}
                  fluid
                  onChange={handleLocalsChange}
                  selection
                  options={mapToLocalOptions(listedLocals)}
                />
              </DropdownContainer>
            </Grid>
          </Grid>

          <Grid container item spacing={3}>
            <Grid item xs={1} alignContent="end" alignSelf="center">
              <Label label="Superficie local(aux)" />
            </Grid>
            <Grid item xs={5} alignContent="end" alignSelf="center">
              <DropdownContainer>
                <SemanticForm.Input placeholder="Superficie" name="localsArea" type="number" readonly disabled value={localArea} />
              </DropdownContainer>
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={2} alignContent="end" alignSelf="center">
              <Label label={getAttributeLabel("localisation")} />
            </Grid>

            <Grid item xs={2} alignContent="end" alignSelf="center">
              <Autocomplete
                freeSolo
                id="autoCompleteLocalisation"
                options={localisationOptions}
                disableClearable
                getOptionLabel={(option: any): string => option.text}
                style={{ width: 300 }}
                inputValue={system && system.localisation ? system.localisation : ""}
                onChange={handleLocalisationAutoComplete}
                renderInput={(params): JSX.Element => <TextField {...params} onChange={handleLocalisationInputChange} variant="outlined" size="small" />}
              />
            </Grid>
          </Grid>
        </Grid>

        <Separator />
        <DescriptionForm system={system} onUpdate={onUpdate} onNavigationBlock={onNavigationBlock} systemWithoutModel={systemWithoutModel} />
        <Separator />
        <Calculator className="systemModelSection" calculations={calculations} onUpdate={updateCalculations} />
        <Separator />
        <CostInformation
          system={system}
          onUpdate={onUpdate}
          onNavigationBlock={onNavigationBlock}
          assetsMinimalInfo={assetsMinimalInfo}
          isElementInCreationMode={isElementInCreationMode}
          systemWithoutModel={systemWithoutModel}
        />
        <Separator />

        <MonetaryInformation system={system} projectId={projectId} onUpdate={onUpdate} onNavigationBlock={onNavigationBlock} systemWithoutModel={systemWithoutModel} />
      </div>
    );
  }
);

SystemInformation.displayName = "SystemInformation";

export default SystemInformation;
