import { State as RequirementState } from "app/domain/common";
import { Requirement, RequirementType } from "app/domain/requirement";
import { DictionaryCode } from "app/locales";
import { moneyFormatter } from "app/utils/formatter";
import { getStateColor } from "app/view/common/form/formColors";
import moment from "moment";
import React from "react";
import { TableBadge } from "ui/table";
import { RowData } from "ui/table/types";

const getRequirementTypeColor = (requirementType: RequirementType): string => {
  if (requirementType === RequirementType.EXCLUDED) {
    return "grey";
  }
  if (requirementType === RequirementType.ACTIVE) {
    return "green";
  }
  if (requirementType === RequirementType.DONE) {
    return "blue";
  }
  if (requirementType === RequirementType.DELETED) {
    return "red";
  }
  if (requirementType === RequirementType.REPLACED_BY_SYSTEM) {
    return "purple";
  }
  if (requirementType === RequirementType.LIBRARY) {
    return "orange";
  }
  return "";
};

export const createRequirementRowData = (requirement: Requirement, dictionaryCode: DictionaryCode): RowData => {
  return {
    id: requirement.id,
    toVerify: {
      accessor: requirement.toVerify ? "1" : "0",
      value: requirement.toVerify ? "1" : "0"
    },
    reference: {
      accessor: requirement.reference === "" ? null : requirement.reference,
      value: requirement.reference
    },
    customerReference: {
      accessor: requirement.customerReference === "" ? null : requirement.customerReference,
      value: requirement.customerReference
    },
    state: {
      accessor: RequirementState.format(dictionaryCode, requirement.state),
      value: <TableBadge label={RequirementState.format(dictionaryCode, requirement.state)} color={getStateColor(requirement.state)} />
    },
    type: {
      accessor: RequirementType.format(dictionaryCode, requirement.type),
      value: <TableBadge label={RequirementType.format(dictionaryCode, requirement.type)} color={getRequirementTypeColor(requirement.type)} />
    },
    uniformat: {
      accessor: requirement.uniformat.level4 ? requirement.uniformat.level4 : requirement.uniformat.level3,
      value: requirement.uniformat.level4 ? requirement.uniformat.level4 : requirement.uniformat.level3
    },
    action: {
      accessor: requirement.actionName,
      value: requirement.actionName
    },
    totalCost: {
      accessor: requirement.totalCost,
      value: moneyFormatter.format(requirement.totalCost)
    },
    date: {
      accessor: requirement.created_hour ? `${new Date(requirement.created_hour).toISOString()}` : "",
      value: requirement.created_hour ? `${moment(requirement.created_hour).fromNow()}` : ""
    }
  };
};
