/* stylelint-disable value-keyword-case */

import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useCallback } from "react";
import { Form as SemanticForm } from "semantic-ui-react";
import { css } from "styled-components";
import * as constants from "ui/form/controls/constants";
import { formControlStyle } from "ui/form/controls/style";

const dropdownStyle = css`
  ${formControlStyle};

  .ui.selection.active.dropdown {
    border-color: ${constants.DEFAULT_FORM_CONTROL_FOCUS_COLOR};
    box-shadow: none;

    .menu {
      border-color: ${constants.DEFAULT_FORM_CONTROL_FOCUS_COLOR};
      box-shadow: none;
    }
  }
`;

export type DropdownItem = {
  value: string;
  text: string;
};

const createDropdownItems = (items: JSX.Element[] | JSX.Element): DropdownItem[] => {
  if (Array.isArray(items)) {
    return items.map((item) => ({
      value: item.props.value,
      text: item.props.label
    }));
  }
  return [{ value: items.props.value, text: items.props.label }];
};

interface DropdownProps extends ComponentProps {
  children: JSX.Element[] | JSX.Element;
  onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownItem) => void;
  value?: string;
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const Dropdown: FC<DropdownProps> = ({ children, onChange, value, label, placeholder, isLoading, isDisabled, className }: DropdownProps) => {
  const dropdownItems = createDropdownItems(children);

  const handleChange = useCallback(
    (event: React.SyntheticEvent<HTMLElement>, data: any) => {
      event.preventDefault();

      onChange(event, data);
    },
    [onChange]
  );

  return (
    <SemanticForm.Dropdown
      label={label}
      placeholder={placeholder}
      loading={isLoading || false}
      disabled={isDisabled || false}
      fluid
      selection
      search
      clearable
      onChange={handleChange}
      value={value}
      options={dropdownItems}
      className={className}
      data-test-id="dropdown"
    />
  );
};

export default createStyledComponent(Dropdown, dropdownStyle);
