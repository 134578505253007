import { colors } from "../colors";

export const DEFAULT_CARD_WIDTH = "350px";
export const DEFAULT_CARD_HEIGHT = "250px";
export const DEFAULT_CARD_BORDER_COLOR = "#d6d6d6";
export const DEFAULT_CARD_BORDER_RADIUS = ".1em";
export const DEFAULT_CARD_BOX_SHADOW = `0 0 0 ${DEFAULT_CARD_BORDER_RADIUS} ${DEFAULT_CARD_BORDER_COLOR}`;

export const SELECTED_CARD_BORDER_COLOR = colors.ACCENT_BLUE;
export const SELECTED_CARD_BORDER_RADIUS = ".4em";
export const SELECTED_CARD_BOX_SHADOW = `0 0 0 ${SELECTED_CARD_BORDER_RADIUS} ${SELECTED_CARD_BORDER_COLOR}`;

export const HOVERED_CARD_BORDER_COLOR = DEFAULT_CARD_BORDER_COLOR;
export const HOVERED_CARD_BORDER_RADIUS = SELECTED_CARD_BORDER_RADIUS;
export const HOVERED_CARD_BOX_SHADOW = `0 0 0 ${HOVERED_CARD_BORDER_RADIUS} ${HOVERED_CARD_BORDER_COLOR}`;
