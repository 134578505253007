interface SiteProps {
  id: string;
  project_id?: string;
  id_organism?: string;
  site_number?: string;
  name?: string;
  city?: string;
  province?: string;
  country?: string;
  replacement_value?: number;
  requirements_value?: number;
  postal_code?: string;
  description?: string;
  is_audit_done?: boolean;
  facility_condition_index?: string;
}

class Site {
  id: string;
  project_id?: string;
  id_organism?: string;
  site_number?: string;
  name?: string;
  city?: string;
  province?: string;
  country?: string;
  replacement_value?: number;
  requirements_value?: number;
  postal_code?: string;
  description?: string;
  is_audit_done?: boolean;
  facility_condition_index?: string;

  constructor(siteProps: SiteProps) {
    this.id = siteProps.id;
    this.project_id = siteProps.project_id;
    this.id_organism = siteProps.id_organism;
    this.site_number = siteProps.site_number;
    this.name = siteProps.name;
    this.city = siteProps.city;
    this.province = siteProps.province;
    this.country = siteProps.country;
    this.replacement_value = siteProps.replacement_value;
    this.requirements_value = siteProps.requirements_value;
    this.postal_code = siteProps.postal_code;
    this.description = siteProps.description;
    this.is_audit_done = siteProps.is_audit_done;
    this.facility_condition_index = siteProps.facility_condition_index;
  }
}

export default Site;
