import { ComponentProps, createStyledComponent, StyleModifiers, StyleProps } from "common/style/createStyledComponent";
import { transparentize } from "polished";
import React, { FC, memo } from "react";
import compare from "react-fast-compare";
import { Icon as SemanticIcon } from "semantic-ui-react";
import { css } from "styled-components";
import { colors } from "ui/colors";

import { IconType } from "./type";

const defaultColor = colors.ACCENT_BLUE_DARK;

export const iconStyle = css`
  display: flex;
  height: 28px;
  width: 28px;

  .icon {
    position: relative;
    height: inherit;
    width: inherit;
    margin: 0;
    font-size: 2em;
    flex: 1;
    color: ${(props: StyleProps): string => props.color || defaultColor};
  }

  .icon::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`;

export const iconStyleModifiers: StyleModifiers = {
  circular: (props: StyleProps) => css`
    background-color: ${props.color || defaultColor};
    border-radius: 50% !important;

    .icon {
      font-size: 1.3em;
      color: white;
    }
  `,

  outlined: (props: StyleProps) => css`
    background-color: white;
    border: 1px solid ${transparentize(0.4, props.color || defaultColor)};

    .icon {
      color: ${props.color || defaultColor};
    }
  `
};

interface IconProps extends ComponentProps {
  type: IconType;
  color?: string;
}

const isEqual = (prevProps: IconProps, nextProps: IconProps): boolean => compare(prevProps, nextProps);

const Icon: FC<IconProps> = ({ type, className }: IconProps) => (
  <div className={className}>
    <SemanticIcon name={type} />
  </div>
);

export default createStyledComponent(memo(Icon, isEqual), iconStyle, iconStyleModifiers);
