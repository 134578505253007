import { Project } from "app/domain/project";

import { Action, PayloadAction } from "../types";

export enum ProjectActionTypes {
  GET_PROJECTS = "GET_PROJECTS",
  GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST",
  GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS",
  GET_PROJECTS_ERROR = "GET_PROJECTS_ERROR",
  SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT",
  RESET_PROJECT = "RESET_PROJECT"
}

export type GetProjectsAction = Action<ProjectActionTypes.GET_PROJECTS>;

export type GetProjectsRequestAction = Action<ProjectActionTypes.GET_PROJECTS_REQUEST>;

export type GetProjectsSuccessAction = PayloadAction<
  ProjectActionTypes.GET_PROJECTS_SUCCESS,
  {
    projects: Project[];
  }
>;

export type GetProjectsErrorAction = PayloadAction<ProjectActionTypes.GET_PROJECTS_ERROR, { errorMessage: string }>;

export type SetCurrentProjectAction = PayloadAction<
  ProjectActionTypes.SET_CURRENT_PROJECT,
  {
    projectId: string;
  }
>;

export type ResetAction = Action<ProjectActionTypes.RESET_PROJECT>;
