import { Requirement } from "app/domain/requirement";
import { RequirementItemOption, RequirementsOptions } from "app/domain/requirement/options";
import RequirementProblematicOption from "app/domain/requirement/options/requirementProblematicOption";

import { Action, PayloadAction } from "../types";

export enum RequirementActionTypes {
  GET_REQUIREMENT = "GET_REQUIREMENT",
  GET_REQUIREMENT_REQUEST = "GET_REQUIREMENT_REQUEST",
  GET_REQUIREMENT_SUCCESS = "GET_REQUIREMENT_SUCCESS",
  GET_REQUIREMENT_ERROR = "GET_REQUIREMENT_ERROR",
  GET_REQUIREMENTS = "GET_REQUIREMENTS",
  GET_REQUIREMENTS_REQUEST = "GET_REQUIREMENTS_REQUEST",
  GET_REQUIREMENTS_SUCCESS = "GET_REQUIREMENTS_SUCCESS",
  GET_REQUIREMENTS_ERROR = "GET_REQUIREMENTS_ERROR",
  GET_REQUIREMENT_OPTIONS = "GET_REQUIREMENT_OPTIONS",
  GET_REQUIREMENT_OPTIONS_REQUEST = "GET_REQUIREMENT_OPTIONS_REQUEST",
  GET_REQUIREMENT_OPTIONS_SUCCESS = "GET_REQUIREMENT_OPTIONS_SUCCESS",
  GET_REQUIREMENT_OPTIONS_ERROR = "GET_REQUIREMENT_OPTIONS_ERROR",
  ADD_REQUIREMENT = "ADD_REQUIREMENT",
  ADD_REQUIREMENT_REQUEST = "ADD_REQUIREMENT_REQUEST",
  ADD_REQUIREMENT_SUCCESS = "ADD_REQUIREMENT_SUCCESS",
  ADD_REQUIREMENT_ERROR = "ADD_REQUIREMENT_ERROR",
  UPDATE_REQUIREMENT = "UPDATE_REQUIREMENT",
  UPDATE_REQUIREMENT_REQUEST = "UPDATE_REQUIREMENT_REQUEST",
  UPDATE_REQUIREMENT_SUCCESS = "UPDATE_REQUIREMENT_SUCCESS",
  UPDATE_REQUIREMENT_ERROR = "UPDATE_REQUIREMENT_ERROR",
  DELETE_REQUIREMENT = "DELETE_REQUIREMENT",
  DELETE_REQUIREMENT_REQUEST = "DELETE_REQUIREMENT_REQUEST",
  DELETE_REQUIREMENT_SUCCESS = "DELETE_REQUIREMENT_SUCCESS",
  DELETE_REQUIREMENT_ERROR = "DELETE_REQUIREMENT_ERROR",
  GENERATE_REQUIREMENT_COPIES = "GENERATE_REQUIREMENT_COPIES",
  GENERATE_REQUIREMENT_COPIES_REQUEST = "GENERATE_REQUIREMENT_COPIES_REQUEST",
  GENERATE_REQUIREMENT_COPIES_SUCCESS = "GENERATE_REQUIREMENT_COPIES_SUCCESS",
  GENERATE_REQUIREMENT_COPIES_ERROR = "GENERATE_REQUIREMENT_COPIES_ERROR",
  ADD_REQUIREMENT_OPTION = "ADD_REQUIREMENT_OPTION",
  ADD_REQUIREMENT_OPTION_REQUEST = "ADD_REQUIREMENT_OPTION_REQUEST",
  ADD_REQUIREMENT_OPTION_SUCCESS = "ADD_REQUIREMENT_OPTION_SUCCESS",
  ADD_REQUIREMENT_OPTION_ERROR = "ADD_REQUIREMENT_OPTION_ERROR",
  SET_FILTERED_REQUIREMENTS = "SET_FILTERED_REQUIREMENTS",
  RESET_REQUIREMENT = "RESET_REQUIREMENT"
}

export type GetRequirementAction = PayloadAction<
  RequirementActionTypes.GET_REQUIREMENT,
  {
    requirementId: string;
  }
>;

export type GetRequirementRequestAction = PayloadAction<
  RequirementActionTypes.GET_REQUIREMENT_REQUEST,
  {
    requirementId: string;
  }
>;

export type GetRequirementSuccessAction = PayloadAction<
  RequirementActionTypes.GET_REQUIREMENT_SUCCESS,
  {
    requirement: Requirement;
  }
>;

export type GetRequirementErrorAction = PayloadAction<RequirementActionTypes.GET_REQUIREMENT_ERROR, { errorMessage: string }>;

export type GetRequirementsAction = PayloadAction<
  RequirementActionTypes.GET_REQUIREMENTS,
  {
    assetId: string;
  }
>;

export type GetRequirementsRequestAction = PayloadAction<
  RequirementActionTypes.GET_REQUIREMENTS_REQUEST,
  {
    assetId: string;
  }
>;

export type GetRequirementsSuccessAction = PayloadAction<
  RequirementActionTypes.GET_REQUIREMENTS_SUCCESS,
  {
    requirements: Requirement[];
  }
>;

export type GetRequirementsErrorAction = PayloadAction<RequirementActionTypes.GET_REQUIREMENTS_ERROR, { errorMessage: string }>;

export type GetRequirementOptionsAction = Action<RequirementActionTypes.GET_REQUIREMENT_OPTIONS>;

export type GetRequirementOptionsRequestAction = Action<RequirementActionTypes.GET_REQUIREMENT_OPTIONS_REQUEST>;

export type GetRequirementOptionsSuccessAction = PayloadAction<
  RequirementActionTypes.GET_REQUIREMENT_OPTIONS_SUCCESS,
  {
    options: RequirementsOptions;
  }
>;

export type GetRequirementOptionsErrorAction = PayloadAction<RequirementActionTypes.GET_REQUIREMENT_OPTIONS_ERROR, { errorMessage: string }>;

export type AddRequirementAction = PayloadAction<
  RequirementActionTypes.ADD_REQUIREMENT,
  {
    requirement: Requirement;
  }
>;

export type AddRequirementRequestAction = PayloadAction<
  RequirementActionTypes.ADD_REQUIREMENT_REQUEST,
  {
    requirement: Requirement;
  }
>;

export type AddRequirementSuccessAction = PayloadAction<
  RequirementActionTypes.ADD_REQUIREMENT_SUCCESS,
  {
    requirement: Requirement;
  }
>;

export type AddRequirementErrorAction = PayloadAction<RequirementActionTypes.ADD_REQUIREMENT_ERROR, { errorMessage: string }>;

export type UpdateRequirementAction = PayloadAction<
  RequirementActionTypes.UPDATE_REQUIREMENT,
  {
    requirement: Requirement;
  }
>;

export type UpdateRequirementRequestAction = PayloadAction<
  RequirementActionTypes.UPDATE_REQUIREMENT_REQUEST,
  {
    requirement: Requirement;
  }
>;

export type UpdateRequirementSuccessAction = PayloadAction<
  RequirementActionTypes.UPDATE_REQUIREMENT_SUCCESS,
  {
    requirement: Requirement;
  }
>;

export type UpdateRequirementErrorAction = PayloadAction<RequirementActionTypes.UPDATE_REQUIREMENT_ERROR, { errorMessage: string }>;

export type DeleteRequirementAction = PayloadAction<
  RequirementActionTypes.DELETE_REQUIREMENT,
  {
    requirementId: string;
  }
>;

export type DeleteRequirementRequestAction = PayloadAction<
  RequirementActionTypes.DELETE_REQUIREMENT_REQUEST,
  {
    requirementId: string;
  }
>;

export type DeleteRequirementSuccessAction = PayloadAction<
  RequirementActionTypes.DELETE_REQUIREMENT_SUCCESS,
  {
    requirementId: string;
  }
>;

export type DeleteRequirementErrorAction = PayloadAction<RequirementActionTypes.DELETE_REQUIREMENT_ERROR, { errorMessage: string }>;

export type GenerateRequirementCopiesAction = PayloadAction<
  RequirementActionTypes.GENERATE_REQUIREMENT_COPIES,
  {
    requirementId: string;
    requirementCopies: Requirement[];
    parentId: string;
  }
>;

export type GenerateRequirementCopiesRequestAction = PayloadAction<
  RequirementActionTypes.GENERATE_REQUIREMENT_COPIES_REQUEST,
  {
    requirementId: string;
    requirementCopies: Requirement[];
    parentId: string;
  }
>;

export type GenerateRequirementCopiesSuccessAction = PayloadAction<
  RequirementActionTypes.GENERATE_REQUIREMENT_COPIES_SUCCESS,
  { requirementCopies: Requirement[]; parentId: string }
>;

export type GenerateRequirementCopiesErrorAction = PayloadAction<RequirementActionTypes.GENERATE_REQUIREMENT_COPIES_ERROR, { errorMessage: string }>;

export type AddRequirementOptionAction = PayloadAction<
  RequirementActionTypes.ADD_REQUIREMENT_OPTION,
  {
    option: RequirementItemOption | RequirementProblematicOption;
  }
>;

export type AddRequirementOptionRequestAction = PayloadAction<
  RequirementActionTypes.ADD_REQUIREMENT_OPTION_REQUEST,
  {
    option: RequirementItemOption | RequirementProblematicOption;
  }
>;

export type AddRequirementOptionSuccessAction = PayloadAction<
  RequirementActionTypes.ADD_REQUIREMENT_OPTION_SUCCESS,
  {
    option: RequirementItemOption | RequirementProblematicOption;
  }
>;

export type AddRequirementOptionErrorAction = PayloadAction<RequirementActionTypes.ADD_REQUIREMENT_OPTION_ERROR, { errorMessage: string }>;

export type SetFilteredRequirementsAction = PayloadAction<RequirementActionTypes.SET_FILTERED_REQUIREMENTS, { requirementIds: string[] }>;

export type ResetAction = Action<RequirementActionTypes.RESET_REQUIREMENT>;
