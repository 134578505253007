import Calculation from "app/domain/calculation/calculation";

export interface CalculationDTO {
  id: number;
  linked_to: string;
  calculation_type: string;
  calculation_data: string;
}

class CalculationMapper {
  static fromDTO(calculationDTO: CalculationDTO): Calculation;
  static fromDTO(calculationDTOs: CalculationDTO[]): Calculation[];
  static fromDTO(calculationDTOs: CalculationDTO | CalculationDTO[]): any {
    if (!Array.isArray(calculationDTOs)) {
      return new Calculation({
        id: calculationDTOs.id,
        linkedTo: calculationDTOs.linked_to,
        type: calculationDTOs.calculation_type,
        data: calculationDTOs.calculation_data ? JSON.parse(calculationDTOs.calculation_data) : []
      });
    }
    return calculationDTOs.map((calculationDTO: CalculationDTO): Calculation => this.fromDTO(calculationDTO));
  }

  static toDTO(calculation: Calculation): CalculationDTO;
  static toDTO(calculation: Calculation[]): CalculationDTO[];
  static toDTO(calculation: Calculation | Calculation[]): any {
    if (!Array.isArray(calculation)) {
      return {
        id: calculation.id,
        linked_to: calculation.linkedTo,
        calculation_type: calculation.type,
        calculation_data: calculation.data.length > 0 ? JSON.stringify(calculation.data) : undefined
      };
    }
    return calculation.map((c: Calculation): CalculationDTO => this.toDTO(c));
  }
}

export default CalculationMapper;
