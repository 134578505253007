interface ReplyProps {
  id?: number;
  avatar: string;
  text: string;
  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  canEdit: boolean;
  isRead: boolean;
}

class Reply {
  id?: number;
  avatar: string;
  text: string;
  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  canEdit: boolean;
  isRead: boolean;

  constructor(props: ReplyProps) {
    this.id = props.id;
    this.avatar = props.avatar;
    this.text = props.text;
    this.createdBy = props.createdBy;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
    this.canEdit = props.canEdit;
    this.isRead = props.isRead;
  }
}

export default Reply;
