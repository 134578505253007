import SearchItem from "app/domain/search/searchItem";
import SearchService from "app/service/search/searchService";
import { call, put, takeLatest } from "redux-saga/effects";

import { getSearchResultsErrorAction, getSearchResultsRequestAction, getSearchResultsSuccessAction } from "./actions";
import { GetSearchResultsAction, GetSearchResultsRequestAction, SearchActionTypes } from "./types";

export const GET_SEARCH_RESULTS_ERROR = "Une erreur s'est produite lors de la récupération des résultats. Veuillez réessayer.";

export function* getRsMeansCustomQuantities(action: GetSearchResultsAction): Generator {
  yield put(getSearchResultsRequestAction(action.payload.searchItem));
}

export function* getRsMeansCustomQuantitiesRequest(action: GetSearchResultsRequestAction): Generator {
  try {
    const searchResults = yield call(SearchService.search, action.payload.searchItem);
    yield put(getSearchResultsSuccessAction(searchResults as SearchItem[]));
  } catch (error) {
    yield put(getSearchResultsErrorAction(GET_SEARCH_RESULTS_ERROR));
  }
}

function* searchSaga(): Generator {
  yield takeLatest(SearchActionTypes.GET_SEARCH_RESULTS, getRsMeansCustomQuantities);
  yield takeLatest(SearchActionTypes.GET_SEARCH_RESULTS_REQUEST, getRsMeansCustomQuantitiesRequest);
}

export default searchSaga;
