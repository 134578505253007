import { Local } from "app/domain/local";

export type LocalState = {
  isFetching: boolean;
  errorMessage: string;
  locals: Local[];
  currentRoom: Local | undefined;
};

export const initialLocalState: LocalState = {
  isFetching: false,
  errorMessage: "",
  locals: [],
  currentRoom: undefined
};
