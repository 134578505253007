interface ManufacturerProps {
  text: string;
  isValidated: boolean;
  id?: number;
}

class Manufacturer {
  text: string;
  isValidated: boolean;
  id?: number;

  constructor(manufacturerProps: ManufacturerProps) {
    this.id = manufacturerProps.id;
    this.text = manufacturerProps.text;
    this.isValidated = manufacturerProps.isValidated;
  }
}

export default Manufacturer;
