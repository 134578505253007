import Option from "app/domain/common/option";
import { Requirement } from "app/domain/requirement";
import { getRequirementOptions } from "app/store/requirement/selectors";
import { FormFieldGroup } from "app/view/common/form";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { forwardRef, RefObject } from "react";
import { useSelector } from "react-redux";
import { Form as SemanticForm } from "semantic-ui-react";

import { RequirementFields } from "../useRequirement";

interface AdjustmentFactorProps extends ComponentProps {
  requirement: Requirement | undefined;
  onUpdate: (fields: RequirementFields) => void;
  onNavigationBlock: () => void;
}

const AdjustmentFactor = forwardRef(({ requirement, onUpdate, onNavigationBlock }: AdjustmentFactorProps, ref): JSX.Element => {
  const requirementOptions = useSelector(getRequirementOptions);

  const handleSelectChange = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({ [data.name]: data.value === "" ? null : data.value });
    onNavigationBlock();
  };

  return (
    <div>
      <h3 ref={ref as RefObject<any>}>
        <span>3.</span> Facteurs d&apos;ajustement
      </h3>
      <FormFieldGroup>
        <SemanticForm.Dropdown
          label="Complexité"
          clearable
          placeholder="Complexité"
          name="complexity"
          value={requirement && requirement.complexity}
          onChange={handleSelectChange}
          selection
          options={requirementOptions ? requirementOptions.complexityOptions : []}
        />
        <SemanticForm.Dropdown
          label="Hauteur"
          clearable
          placeholder="Hauteur"
          name="height"
          value={requirement && requirement.height}
          onChange={handleSelectChange}
          selection
          options={requirementOptions ? requirementOptions.heightOptions : []}
        />
        <SemanticForm.Dropdown
          label="Enlèvement"
          clearable
          placeholder="Enlèvement"
          name="removal"
          value={requirement && requirement.removal}
          onChange={handleSelectChange}
          selection
          options={
            requirementOptions
              ? requirementOptions.removalOptions.map((option: Option) => ({
                  value: option.id,
                  text: option.text
                }))
              : []
          }
        />
        <SemanticForm.Dropdown
          label="Facteur patrimonial"
          clearable
          placeholder="Facteur patrimonial"
          name="patrimony_factor"
          value={requirement && requirement.patrimony_factor}
          onChange={handleSelectChange}
          selection
          options={requirementOptions ? requirementOptions.patrimonyFactorOptions : []}
        />
      </FormFieldGroup>
    </div>
  );
});

AdjustmentFactor.displayName = "AdjustmentFactor";

export default AdjustmentFactor;
