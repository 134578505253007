import { Requirement } from "app/domain/requirement";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Checkbox, CheckboxGroup } from "ui/form/controls/checkbox";

import { RequirementFields } from "./useRequirement";

interface RequirementFormStateProps extends ComponentProps {
  requirement: Requirement | undefined;
  onUpdate: (fields: RequirementFields) => void;
  onNavigationBlock: () => void;
}

const RequirementFormState: FC<RequirementFormStateProps> = ({ requirement, onUpdate, onNavigationBlock }: RequirementFormStateProps) => {
  const handleCheckChange = (event: any): void => {
    onUpdate({ [event.currentTarget.name]: event.currentTarget.checked });
    onNavigationBlock();
  };

  return (
    <CheckboxGroup>
      <Checkbox label="À vérifier" name="toVerify" isChecked={(requirement && requirement.toVerify) || false} onClick={handleCheckChange} />
      <Checkbox label="Contrôle qualité fait" name="isVerified" isChecked={(requirement && requirement.isVerified) || false} onClick={handleCheckChange} />
    </CheckboxGroup>
  );
};

export default RequirementFormState;
