/* eslint-disable react-hooks/exhaustive-deps */

import { Comment, Reply } from "app/domain/comment";
import { updateCommentAction } from "app/store/comment/commentActions";
import { getUserName } from "app/store/login/selectors";
import CommentActionButton from "app/view/comment/commentActionButton";
import CommentReplyCreator from "app/view/comment/commentReplyCreator";
import CommentThreadReply from "app/view/comment/commentThreadReply";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { ChangeEvent, FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Comment as UIComment, Form, SemanticCOLORS } from "semantic-ui-react";
import { TextAreaProps } from "semantic-ui-react/dist/commonjs/addons/TextArea/TextArea";
import { colors } from "ui/colors";

interface CommentThreadProps extends ComponentProps {
  comment: Comment;
  onResolve: () => void;
  setIsEditing: (val: boolean) => void;
  isEditing: boolean;
  fieldName: string;
}

const CommentThread: FC<CommentThreadProps> = ({ comment, onResolve, isEditing, setIsEditing, className, fieldName }: CommentThreadProps): JSX.Element => {
  const dispatch = useDispatch();

  const findButtonText = (): string => {
    return comment.resolvedBy ? "Réouvrir" : "Résoudre";
  };

  const findButtonColor = (): SemanticCOLORS => {
    return comment.resolvedBy ? "red" : "green";
  };

  const [resolveButtonText, setResolveButtonText] = React.useState(findButtonText());
  const [resolveButtonColor, setResolveButtonColor] = React.useState(findButtonColor());
  const [isReplying, setIsReplying] = React.useState(false);
  const [editComment, setEditComment] = React.useState("");
  const [commentDate, setCommentDate] = React.useState("");

  const currentUser = useSelector(getUserName);

  useEffect(() => {
    setResolveButtonText(findButtonText());
    setResolveButtonColor(findButtonColor());
  }, [comment.resolvedBy]);

  useEffect(() => {
    let date = "";
    if (comment.updatedAt) {
      date = `${comment.updatedAt.toDateString()} (edited)`;
    } else if (comment.createdAt) {
      date = comment.createdAt.toDateString();
    }
    setCommentDate(date);
  }, [comment.createdAt, comment.updatedAt]);

  const updateComment = (updatedComment: Comment): void => {
    dispatch(updateCommentAction(updatedComment));
  };

  const updateCommentReplies = (replies: Reply[]): void => {
    const updatedComment = { ...comment, replies };
    updateComment(updatedComment);
  };

  const updateReply = (reply: Reply): void => {
    const updatedReplies = [...comment.replies];
    const replyToUpdate = comment.replies.findIndex((r) => r.id === reply.id);
    updatedReplies[replyToUpdate] = { ...reply };
    updateCommentReplies(updatedReplies);
  };

  const addReply = (reply: Reply): void => {
    updateCommentReplies([...comment.replies, reply]);
    setIsReplying(false);
  };

  const cancelReply = (): void => {
    setIsReplying(false);
  };

  const toggleIsResolved = (): void => {
    const resolvedBy = comment.resolvedBy ? undefined : "The backend will replace this non-empty content with the current user.";
    if (resolvedBy) {
      onResolve();
    }
    updateComment({ ...comment, resolvedBy });
  };

  const showNewReply = (): void => {
    setIsReplying(true);
  };

  const showEditComment = (): void => {
    setEditComment(comment.text);
    setIsEditing(true);
  };

  const saveEditComment = (): void => {
    updateComment({ ...comment, text: editComment, fieldName });
    setIsEditing(false);
  };

  const cancelEditComment = (): void => {
    setIsEditing(false);
  };

  const modifyText = (event: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps): void => {
    setEditComment(String(data.value));
  };

  return (
    <div>
      <UIComment className={className}>
        <UIComment.Content style={{ "white-space": "break-spaces", padding: "0em 0em 0em 1.25em", ...(!comment.isRead ? { backgroundColor: colors.COLOR_WHEN_READ } : {}) }}>
          <UIComment.Avatar src={comment.avatar} />
          <UIComment.Author content={comment.createdBy} style={{ padding: "0em 0em 0em 3em" }} />
          <UIComment.Metadata content={commentDate} />
          <UIComment.Text>
            {isEditing && (
              <Form>
                <Form.TextArea value={editComment} onChange={modifyText} />
              </Form>
            )}
            {!isEditing && comment.text}
          </UIComment.Text>

          {!isReplying && (
            <UIComment.Actions>
              {comment.createdBy === currentUser && (
                <CommentActionButton basic content={resolveButtonText} color={resolveButtonColor} onClick={toggleIsResolved} isVisible={!isEditing} />
              )}
              {!comment.resolvedBy && <CommentActionButton content="Répondre" onClick={showNewReply} isVisible={!isEditing} />}
              <CommentActionButton content="Modifier" onClick={showEditComment} isVisible={!isEditing && comment.canEdit} />
              <CommentActionButton content="Sauvegarder" onClick={saveEditComment} isVisible={isEditing} />
              <CommentActionButton content="Annuler" onClick={cancelEditComment} isVisible={isEditing} />
            </UIComment.Actions>
          )}
        </UIComment.Content>
        <UIComment.Group>
          {comment.replies.map((reply: Reply) => (
            <CommentThreadReply key={reply.id} reply={reply} updateReply={updateReply} />
          ))}
        </UIComment.Group>
      </UIComment>
      {!isEditing && isReplying && <CommentReplyCreator onCreate={addReply} onCancel={cancelReply} />}
    </div>
  );
};

export default CommentThread;
