import { Reply } from "app/domain/comment";
import CommentActionButton from "app/view/comment/commentActionButton";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { ChangeEvent, FC, useEffect } from "react";
import { Comment as UIComment, Form } from "semantic-ui-react";
import { TextAreaProps } from "semantic-ui-react/dist/commonjs/addons/TextArea/TextArea";
import { colors } from "ui/colors";

interface CommentProps extends ComponentProps {
  reply: Reply;
  updateReply: (reply: Reply) => void;
}

const CommentThreadReply: FC<CommentProps> = ({ reply, updateReply, className }: CommentProps) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editReply, setEditReply] = React.useState("");
  const [metadata, setMetadata] = React.useState("");

  useEffect(() => {
    let date = "";
    if (reply.updatedAt) {
      date = `${reply.updatedAt.toDateString()} (edited)`;
    } else if (reply.createdAt) {
      date = reply.createdAt.toDateString();
    }
    setMetadata(date);
  }, [reply.createdAt, reply.updatedAt]);

  const showEditReply = (): void => {
    setEditReply(reply.text);
    setIsEditing(true);
  };

  const saveEditReply = (): void => {
    updateReply({ ...reply, text: editReply });
    setIsEditing(false);
  };

  const cancelEditReply = (): void => {
    setIsEditing(false);
  };

  const modifyText = (event: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps): void => {
    setEditReply(String(data.value));
  };

  return (
    <UIComment className={className}>
      <UIComment.Content style={{ padding: "0.25em 0em 0em 1.25em", ...(!reply.isRead ? { backgroundColor: colors.COLOR_WHEN_READ } : {}) }}>
        <UIComment.Avatar src={reply.avatar} />
        <UIComment.Author content={reply.createdBy} style={{ padding: "0em 0em 0em 3em" }} />
        <UIComment.Metadata content={metadata} />
        <UIComment.Text>
          {isEditing && (
            <Form>
              <Form.TextArea value={editReply} onChange={modifyText} />
            </Form>
          )}
          {!isEditing && reply.text}
        </UIComment.Text>
        <UIComment.Actions>
          <CommentActionButton content="Modifier" onClick={showEditReply} isVisible={!isEditing && reply.canEdit} />
          <CommentActionButton content="Sauvegarder" onClick={saveEditReply} isVisible={isEditing} />
          <CommentActionButton content="Annuler" onClick={cancelEditReply} isVisible={isEditing} />
        </UIComment.Actions>
      </UIComment.Content>
    </UIComment>
  );
};

export default CommentThreadReply;
