export enum NotifiableType {
  comment = "comment",
  reply = "reply"
}

export interface INotification {
  id: number;
  isRead: boolean;
  notifiableType: NotifiableType;
  comment: string;
  siteId: string;
  assetId: string;
  parentId: string;
  createdBy: string;
  projectId: string;
  parentType: string;
  createdAt: Date;
  message?: string;
  reply?: string;
}

export class Notification {
  id: number;
  isRead: boolean;
  notifiableType: NotifiableType;
  comment: string;
  siteId: string;
  assetId: string;
  parentId: string;
  createdBy: string;
  projectId: string;
  parentType: string;
  createdAt: Date;
  message: string;
  reply?: string;

  constructor(notification: INotification) {
    this.id = notification.id;
    this.isRead = notification.isRead;
    this.notifiableType = notification.notifiableType;
    this.comment = notification.comment;
    this.reply = notification.reply;
    this.siteId = notification.siteId;
    this.assetId = notification.assetId;
    this.parentId = notification.parentId;
    this.createdBy = notification.createdBy;
    this.projectId = notification.projectId;
    this.parentType = notification.parentType;
    this.createdAt = notification.createdAt;
    this.message =
      notification.notifiableType === NotifiableType.comment ? `${notification.createdBy} a commenté votre système` : `${notification.createdBy} a répondu à votre commentaire`;
  }
}

export default Notification;
