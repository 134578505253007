import { createAction } from "@reduxjs/toolkit";
import { EntityCategory } from "app/domain/entityCategory";
import { System } from "app/domain/system";

import { SystemActionTypes } from "./types";

export const getSystemAction = createAction(SystemActionTypes.GET_SYSTEM, (systemId: string) => ({
  payload: {
    systemId
  }
}));

export const getSystemRequestAction = createAction(SystemActionTypes.GET_SYSTEM_REQUEST, (systemId: string) => ({
  payload: {
    systemId
  }
}));

export const getSystemSuccessAction = createAction(SystemActionTypes.GET_SYSTEM_SUCCESS, (system: System) => ({
  payload: {
    system
  }
}));

export const getSystemErrorAction = createAction(SystemActionTypes.GET_SYSTEM_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const getSystemsAction = createAction(SystemActionTypes.GET_SYSTEMS, (parentType: EntityCategory, parentId: string) => ({
  payload: {
    parentType,
    parentId
  }
}));

export const getSystemsRequestAction = createAction(SystemActionTypes.GET_SYSTEMS_REQUEST, (parentType: EntityCategory, parentId: string) => ({
  payload: {
    parentType,
    parentId
  }
}));

export const getSystemsSuccessAction = createAction(SystemActionTypes.GET_SYSTEMS_SUCCESS, (systems: System[]) => ({
  payload: {
    systems
  }
}));

export const getSystemsErrorAction = createAction(SystemActionTypes.GET_SYSTEMS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const getSystemOptionsAction = createAction(SystemActionTypes.GET_SYSTEM_OPTIONS);

export const getSystemOptionsRequestAction = createAction(SystemActionTypes.GET_SYSTEM_OPTIONS_REQUEST);

// TODO: determine options type
export const getSystemOptionsSuccessAction = createAction(SystemActionTypes.GET_SYSTEM_OPTIONS_SUCCESS, (options: any) => ({
  payload: {
    options
  }
}));

export const getSystemOptionsErrorAction = createAction(SystemActionTypes.GET_SYSTEM_OPTIONS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const addSystemAction = createAction(SystemActionTypes.ADD_SYSTEM, (system: System, isClosing: boolean) => ({
  payload: {
    system,
    isClosing
  }
}));

export const addSystemRequestAction = createAction(SystemActionTypes.ADD_SYSTEM_REQUEST, (system: System, isClosing: boolean) => ({
  payload: {
    system,
    isClosing
  }
}));

export const addSystemSuccessAction = createAction(SystemActionTypes.ADD_SYSTEM_SUCCESS, (system: System, isClosing: boolean) => ({
  payload: {
    system,
    isClosing
  }
}));

export const addSystemErrorAction = createAction(SystemActionTypes.ADD_SYSTEM_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const updateSystemAction = createAction(SystemActionTypes.UPDATE_SYSTEM, (system: System, isClosing: boolean) => ({
  payload: {
    system,
    isClosing
  }
}));

export const updateSystemRequestAction = createAction(SystemActionTypes.UPDATE_SYSTEM_REQUEST, (system: System, isClosing: boolean) => ({
  payload: {
    system,
    isClosing
  }
}));

export const updateSystemSuccessAction = createAction(SystemActionTypes.UPDATE_SYSTEM_SUCCESS, (system: System, isClosing: boolean) => ({
  payload: {
    system,
    isClosing
  }
}));

export const updateSystemErrorAction = createAction(SystemActionTypes.UPDATE_SYSTEM_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const deleteSystemAction = createAction(SystemActionTypes.DELETE_SYSTEM, (systemId: string) => ({
  payload: {
    systemId
  }
}));

export const deleteSystemRequestAction = createAction(SystemActionTypes.DELETE_SYSTEM_REQUEST, (systemId: string) => ({
  payload: {
    systemId
  }
}));

export const deleteSystemSuccessAction = createAction(SystemActionTypes.DELETE_SYSTEM_SUCCESS, (systemId: string) => ({
  payload: {
    systemId
  }
}));

export const deleteSystemErrorAction = createAction(SystemActionTypes.DELETE_SYSTEM_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const generateSystemLabelsAction = createAction(SystemActionTypes.GENERATE_SYSTEM_LABELS, (systemIds) => ({
  payload: { systemIds }
}));

export const generateSystemLabelsRequestAction = createAction(SystemActionTypes.GENERATE_SYSTEM_LABELS_REQUEST, (systemIds) => ({
  payload: { systemIds }
}));

export const generateSystemLabelsSuccessAction = createAction(SystemActionTypes.GENERATE_SYSTEM_LABELS_SUCCESS, (system) => ({
  payload: {
    system
  }
}));

export const generateSystemLabelsErrorAction = createAction(SystemActionTypes.GENERATE_SYSTEM_LABELS_ERROR, (errorMessage) => ({
  payload: {
    errorMessage
  }
}));

export const generateSystemCopiesToDestinationsRequestAction = createAction(
  SystemActionTypes.GENERATE_SYSTEM_COPIES_TO_DESTINATIONS_REQUEST,
  (isCopyingIdentification: boolean, isCopyingQuantity: boolean, systemToCopyIds: string[], destinationAssetIds: string[]) => ({
    payload: {
      isCopyingIdentification,
      isCopyingQuantity,
      systemToCopyIds,
      destinationAssetIds
    }
  })
);

export const generateSystemCopiesToDestinationsSuccessAction = createAction(SystemActionTypes.GENERATE_SYSTEM_COPIES_TO_DESTINATIONS_SUCCESS);

export const generateSystemCopiesToDestinationsErrorAction = createAction(SystemActionTypes.GENERATE_SYSTEM_COPIES_TO_DESTINATIONS_ERROR);

export const generateSystemCopiesAction = createAction(SystemActionTypes.GENERATE_SYSTEM_COPIES, (systemId: string, systemCopies: System[], parentId: string) => ({
  payload: {
    systemId,
    systemCopies,
    parentId
  }
}));

export const generateSystemCopiesRequestAction = createAction(SystemActionTypes.GENERATE_SYSTEM_COPIES_REQUEST, (systemId: string, systemCopies: System[], parentId: string) => ({
  payload: {
    systemId,
    systemCopies,
    parentId
  }
}));

export const generateSystemCopiesSuccessAction = createAction(SystemActionTypes.GENERATE_SYSTEM_COPIES_SUCCESS, (systemCopies: System[], parentId: string) => ({
  payload: {
    systemCopies,
    parentId
  }
}));

export const generateSystemCopiesErrorAction = createAction(SystemActionTypes.GENERATE_SYSTEM_COPIES_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const setFilteredSystemsAction = createAction(SystemActionTypes.SET_FILTERED_SYSTEMS, (systemIds: string[]) => ({
  payload: {
    systemIds
  }
}));

export const setShouldRedirectToSystemsPageAction = createAction(SystemActionTypes.SET_REDIRECT_TO_SYSTEMS_PAGE, (value: boolean) => ({
  payload: {
    value
  }
}));

export const resetAction = createAction(SystemActionTypes.RESET_SYSTEM);
