/* eslint-disable no-nested-ternary, react-hooks/exhaustive-deps */

import { CostLine } from "app/domain/costItem/index";
import { System, SystemModel, SystemModelAction } from "app/domain/system";
import SystemService from "app/service/system/systemService";
import { SystemFields } from "app/view/system/form/systemForm/useSystem";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect, useState } from "react";
import { useAsync } from "react-async";
import { css } from "styled-components";
import { ErrorMessage, useErrorMessage } from "ui/errorMessage/index";

import ActionOptionsSection from "./actionOptionsSection";
import SearchSystemModelControl from "./searchSystemModelControl";
import SystemModelResultsTable from "./systemModelResultsTable";

const systemModelModalStyle = css`
  .modal-container {
    height: 650px;
    width: 85vw;
    max-width: 85vw;
    min-width: 85vw;
  }
`;

interface SystemModelSectionProps extends ComponentProps {
  projectId: string;
  system: System | undefined;
  currentModel: SystemModel;
  currentAction: SystemModelAction;
  isModalOpen: boolean;
  onUpdate: (fields: SystemFields) => void;
  onUpdateModel: (model: SystemModel) => void;
  onUpdateAction: (action: SystemModelAction) => void;
  onCostLinesChange: (costLine: CostLine[]) => void;
  onReset: () => void;
}

const SystemModelSection: FC<SystemModelSectionProps> = ({
  projectId,
  system,
  currentModel,
  currentAction,
  isModalOpen,
  onUpdate,
  onUpdateModel,
  onUpdateAction,
  onCostLinesChange,
  onReset,
  className
}: SystemModelSectionProps) => {
  const [systemModels, setSystemModels] = useState([] as SystemModel[]);
  const [actionOptions, setActionOptions] = useState([] as SystemModelAction[]);
  const [isLoadingSystemModels, setIsLoadingSystemModels] = useState(false);

  const onUpdateModels = (updatedModels: SystemModel[]): void => {
    setSystemModels(updatedModels);
  };

  const onUpdateActions = (updatedActions: SystemModelAction[]): void => {
    setActionOptions(updatedActions);
  };

  const onUpdateIsLoadingSystemModels = (isLoading: boolean): void => {
    setIsLoadingSystemModels(isLoading);
  };

  const { errorMessage: currentErrorMessage, onUpdate: onErrorMessageUpdate, onDismiss } = useErrorMessage();

  const fetchActions = ([systemModelId]: string[]): Promise<SystemModelAction[]> => SystemService.getSystemModelActions(systemModelId);

  const handleFetchActionsError = (): void => {
    onErrorMessageUpdate(`Une erreur s'est produite lors de la récupération des informations reliées au système. Veuillez réessayer`);
  };

  const handleSuccessFetchActions = (actionsFetched: SystemModelAction[]): void => {
    setActionOptions(actionsFetched);
    if (actionsFetched.length === 1) {
      onUpdateAction(actionsFetched[0]);
    }
  };

  const { run: runActions } = useAsync({
    deferFn: fetchActions,
    onResolve: handleSuccessFetchActions,
    onReject: handleFetchActionsError
  });

  const onRowClick = (systemModelId: string): void => {
    runActions(systemModelId);
    const selectedModel = systemModels.find((model: SystemModel) => model.id === systemModelId);
    onReset();
    if (selectedModel) {
      onUpdateModel(selectedModel);
    }
  };

  useEffect(() => {
    if (isModalOpen && system && system.systemModelId) {
      runActions(system.systemModelId);
    }
  }, [isModalOpen]);

  return (
    <div>
      <div className={className}>
        <ErrorMessage message={currentErrorMessage} onDismiss={onDismiss} data-test-id="errorMessage" />
        <SearchSystemModelControl
          projectId={projectId}
          system={system}
          isModalOpen={isModalOpen}
          onUpdateModels={onUpdateModels}
          onUpdateActions={onUpdateActions}
          onUpdateIsLoadingSystemModels={onUpdateIsLoadingSystemModels}
          onReset={onReset}
          onErrorMessageUpdate={onErrorMessageUpdate}
          data-test-id="searchSystemModelControl"
        />
        <SystemModelResultsTable
          selectedSystemModelId={system && system.systemModelId}
          systemModels={systemModels}
          isLoadingSystemModels={isLoadingSystemModels}
          onRowClick={onRowClick}
          data-test-id="systemResultsTable"
        />
        <ActionOptionsSection currentAction={currentAction} actionOptions={actionOptions} onUpdateAction={onUpdateAction} data-test-id="actionOptionsSection" />
      </div>
    </div>
  );
};

export default createStyledComponent(memo(SystemModelSection), systemModelModalStyle);
