/* eslint-disable global-require, @typescript-eslint/no-var-requires, no-return-assign, no-param-reassign */

import "react-dropzone-uploader/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";
import "./index.css";

import env from "@beam-australia/react-env";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import React from "react";
import ReactDOM from "react-dom";

import { App } from "./app";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

if (process.env.NODE_ENV === "production" && !env("API_HOST").includes("staging")) {
  LogRocket.init("qcvr0g/unifika");
  setupLogRocketReact(LogRocket);
}

if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  const reactVirtualizedComponents = require("react-virtualized");
  const semanticUIComponents = require("semantic-ui-react");

  Object.values(reactVirtualizedComponents).forEach((component: any) => (component.whyDidYouRender = false));

  Object.values(semanticUIComponents).forEach((component: any) => (component.whyDidYouRender = false));

  whyDidYouRender(React, {
    trackAllPureComponents: process.env.REACT_APP_TRACK_RENDER === "true",
    trackHooks: process.env.REACT_APP_TRACK_RENDER === "true",
    trackExtraHooks:
      process.env.REACT_APP_TRACK_RENDER === "true"
        ? [
            [require("react-redux/lib"), "useSelector"],
            [require("react-redux/lib"), "useDispatch"]
          ]
        : []
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
