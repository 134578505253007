import { Comment } from "app/domain/comment";
import { EntityCategory } from "app/domain/entityCategory";

import { Action, PayloadAction } from "../types";

export enum CommentActionTypes {
  GET_COMMENTS = "GET_COMMENTS",
  GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST",
  GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS",
  GET_COMMENTS_ERROR = "GET_COMMENTS_ERROR",
  ADD_COMMENT = "ADD_COMMENT",
  ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST",
  ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS",
  ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR",
  UPDATE_COMMENT = "UPDATE_COMMENT",
  UPDATE_COMMENT_REQUEST = "UPDATE_COMMENT_REQUEST",
  UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS",
  UPDATE_COMMENT_ERROR = "UPDATE_COMMENT_ERROR",
  RESET_COMMENT = "RESET_COMMENT"
}

export type GetCommentsAction = PayloadAction<
  CommentActionTypes.GET_COMMENTS,
  {
    parentType: EntityCategory;
    parentId: string;
  }
>;

export type GetCommentsRequestAction = PayloadAction<
  CommentActionTypes.GET_COMMENTS_REQUEST,
  {
    parentType: EntityCategory;
    parentId: string;
  }
>;

export type GetCommentsSuccessAction = PayloadAction<
  CommentActionTypes.GET_COMMENTS_SUCCESS,
  {
    comments: Comment[];
  }
>;

export type GetCommentsErrorAction = PayloadAction<CommentActionTypes.GET_COMMENTS_ERROR, { errorMessage: string }>;

export type AddCommentAction = PayloadAction<
  CommentActionTypes.ADD_COMMENT,
  {
    comment: Comment;
    isClosing: boolean;
  }
>;

export type AddCommentRequestAction = PayloadAction<
  CommentActionTypes.ADD_COMMENT_REQUEST,
  {
    comment: Comment;
    isClosing: boolean;
  }
>;

export type AddCommentSuccessAction = PayloadAction<
  CommentActionTypes.ADD_COMMENT_SUCCESS,
  {
    comment: Comment;
    isClosing: boolean;
  }
>;

export type AddCommentErrorAction = PayloadAction<CommentActionTypes.ADD_COMMENT_ERROR, { errorMessage: string }>;

export type UpdateCommentAction = PayloadAction<
  CommentActionTypes.UPDATE_COMMENT,
  {
    comment: Comment;
    isClosing: boolean;
  }
>;

export type UpdateCommentRequestAction = PayloadAction<
  CommentActionTypes.UPDATE_COMMENT_REQUEST,
  {
    comment: Comment;
    isClosing: boolean;
  }
>;

export type UpdateCommentSuccessAction = PayloadAction<
  CommentActionTypes.UPDATE_COMMENT_SUCCESS,
  {
    comment: Comment;
    isClosing: boolean;
  }
>;

export type UpdateCommentErrorAction = PayloadAction<CommentActionTypes.UPDATE_COMMENT_ERROR, { errorMessage: string }>;

export type ResetAction = Action<CommentActionTypes.RESET_COMMENT>;
