import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";
import { colors } from "ui/colors";

const separatorStyle = css`
  width: 90%;
  margin-left: 5%;
  height: 2px;
  background-color: ${colors.ACCENT_GREY};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Separator: FC<ComponentProps> = ({ className }: ComponentProps) => <div className={className} />;

export default createStyledComponent(Separator, separatorStyle);
