import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";

interface CirclePercentageProps extends ComponentProps {
  diameter: number;
  percentage: number;
}

const CirclePercentage: FC<CirclePercentageProps> = ({ diameter, percentage }: CirclePercentageProps) => (
  <svg viewBox={`0 0 ${diameter} ${diameter}`}>
    <path
      d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
      fill="none"
      stroke="white"
      strokeWidth="2.8"
    />
    <path
      className="circle"
      d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
      fill="none"
      stroke="#00a2cc"
      strokeDasharray={`${percentage}, 100`}
    />
    <text
      x="18"
      y="20.35"
      className="percentage"
      style={{
        fill: "#00a2cc",
        fontFamily: "sans-serif",
        fontSize: "0.5em",
        textAnchor: "middle"
      }}
    >
      {Math.round(percentage).toString()} %
    </text>
  </svg>
);

export default CirclePercentage;
