import IdGenerator from "app/utils/idGenerator";

import Photo from "./photo";
import { PhotoStatus } from "./photoStatus";

// TODO: find a way to incorporate te body of this function inside the class (see PhotoMapper)
// TODO: we only need to extract this method here because the class instance is lost when offline
const createPhoto = async (photoFile: File, photoStatus: PhotoStatus): Promise<Photo> => {
  const photoId = IdGenerator.generate();
  return new Photo(photoId, "", window.URL.createObjectURL(photoFile), photoStatus, new Date(), photoStatus === PhotoStatus.PENDING ? photoFile : undefined);
};

class PhotoFactory {
  static async create(photoFile: File, photoStatus: PhotoStatus): Promise<Photo>;
  static async create(photoFiles: File[], photoStatus: PhotoStatus): Promise<Photo[]>;
  static create(photoFiles: File | File[], photoStatus: PhotoStatus): Promise<any> {
    if (!Array.isArray(photoFiles)) {
      return createPhoto(photoFiles, photoStatus);
    }
    return Promise.all(photoFiles.map(async (photoFile: File): Promise<Photo> => createPhoto(photoFile, photoStatus)));
  }
}

export default PhotoFactory;
