import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Tab as SemanticTab } from "semantic-ui-react";
import { css } from "styled-components";

export const tabStyle = css`
  &&& {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: none;
  }
`;

export interface TabProps extends ComponentProps {
  label: string | JSX.Element;
  children: JSX.Element | JSX.Element[];
}

const Tab: FC<TabProps> = ({ children, className }: TabProps) => <SemanticTab.Pane className={className}>{children}</SemanticTab.Pane>;

export default createStyledComponent(Tab, tabStyle);
