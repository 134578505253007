import { Asset } from "app/domain/asset";
import { Toolbar } from "app/view/common/toolbar";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useCallback } from "react";
import { Accordion as SemanticAccordion, Form as SemanticForm } from "semantic-ui-react";
import { css } from "styled-components";
import { Button } from "ui/button";
import { SaveButton } from "ui/button/actions";
import { IconType } from "ui/icon";

import useAsset from "../../useAsset";
import { AdditionalInformation, AssetEvaluation, AssetInformation, AuditInformation, FinancialInformation, LocalisationInformation, SurfaceInformation } from "./formSections";

const assetBasicInformationFormStyle = css`
  > *:not(:last-child) {
    margin-bottom: 1em;
  }
`;

interface AssetBasicInformationFormProps extends ComponentProps {
  onSave: (asset: Asset) => void;
  asset: Asset | undefined;
}

const AssetBasicInformationForm: FC<AssetBasicInformationFormProps> = ({ onSave, asset, className }: AssetBasicInformationFormProps) => {
  const { currentAsset, onUpdate } = useAsset(asset as Asset);

  const confirmSave = useCallback(() => {
    onSave(currentAsset);
  }, [currentAsset, onSave]);

  const handleIsAuditDone = (): void => {
    if (!currentAsset) return;
    onSave({ ...currentAsset, is_audit_done: !currentAsset.is_audit_done });
  };

  const actions = [
    <SaveButton label="Enregistrer" onClick={confirmSave} key={0} />,
    <Button
      key={1}
      title={currentAsset.is_audit_done ? "Audit terminée" : "Audit en cours"}
      iconType={currentAsset.is_audit_done ? IconType.CHECK : IconType.CLOCK}
      onClick={handleIsAuditDone}
      data-test-id="button"
    />
  ];

  const panels = [
    {
      title: "Informations actif",
      content: {
        content: <AssetInformation asset={currentAsset} onUpdate={onUpdate} />
      },
      key: 0
    },
    {
      title: "Informations audit - Chef d'équipe",
      content: {
        content: <AuditInformation asset={currentAsset} onUpdate={onUpdate} />
      },
      key: 1
    },
    {
      title: "Évaluation actif - Chef d'équipe",
      content: {
        content: <AssetEvaluation asset={currentAsset} onUpdate={onUpdate} />
      },
      key: 2
    },
    {
      title: "Informations superficie",
      content: {
        content: <SurfaceInformation asset={currentAsset} onUpdate={onUpdate} />
      },
      key: 3
    },
    {
      title: "Informations monétaires",
      content: {
        content: <FinancialInformation asset={currentAsset} />
      },
      key: 4
    },
    {
      title: "Localisation -  Chef d'équipe",
      content: {
        content: <LocalisationInformation asset={currentAsset} onUpdate={onUpdate} />
      },
      key: 5
    },
    {
      title: "Informations supplémentaires",
      content: {
        content: <AdditionalInformation asset={currentAsset} onUpdate={onUpdate} />
      },
      key: 6
    }
  ];

  return (
    <SemanticForm className={className}>
      <Toolbar actions={actions} />
      <SemanticAccordion exclusive={false} styled fluid defaultActiveIndex={[0, 1, 2, 3, 4]} panels={panels} />
    </SemanticForm>
  );
};

export default createStyledComponent(AssetBasicInformationForm, assetBasicInformationFormStyle);
