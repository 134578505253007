import { CostItem, CostLine } from "app/domain/costItem";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo, useState } from "react";
import { Button as SemanticButton } from "semantic-ui-react";

import CustomCostItemTable from "./customCostItemTable";

interface CustomCostItemProps extends ComponentProps {
  addCustomCostLineToParent: (costLine: CostLine) => void;
}

const CustomCostItem: FC<CustomCostItemProps> = ({ addCustomCostLineToParent }: CustomCostItemProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const displayCreateCustomCostItemSection = (): void => {
    setIsVisible(true);
  };

  const closeCustomCostItemSection = (): void => {
    setIsVisible(false);
  };

  const addCustomCostItem = (costItem: CostItem): void => {
    const newCostLine = new CostLine({
      ...costItem,
      id: undefined,
      quantity: 0,
      costItemId: costItem.id as number,
      code: costItem.code as string,
      className: costItem.className as string
    });

    addCustomCostLineToParent(newCostLine);
  };

  return (
    <div>
      {!isVisible && <SemanticButton onClick={displayCreateCustomCostItemSection} content="Créer un élément de coût" />}
      {isVisible && <CustomCostItemTable onAdd={addCustomCostItem} onClose={closeCustomCostItemSection} />}
    </div>
  );
};

export default memo(CustomCostItem);
