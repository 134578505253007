/* stylelint-disable no-duplicate-selectors */

import { ComponentProps, createStyledComponent, StyleModifiers } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { css } from "styled-components";
import { IconType } from "ui/icon";

import WebcamActionButton from "./actionButton";

export const webcamActionsStyle = css`
  background-color: #000000ab;
  position: fixed;
  padding: 20px;
  display: flex;

  .webcamActionButtonsContainer {
    margin: auto;
    display: flex;

    .close {
      position: absolute;
      margin: auto;
    }

    .capture {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    > * {
      margin: 5px;
    }
  }
`;

const webcamActionsStyleModifiers: StyleModifiers = {
  vertical: () => css`
     {
      min-width: 50px;
      width: 10%;
      max-width: 100px;
      top: 0;
      bottom: 0;
      right: 0;

      .webcamActionButtonsContainer {
        flex-direction: column;

        .close {
          left: 0;
          right: 0;
        }

        .retry {
          margin: auto auto 20px auto;
        }

        .submit {
          margin: 20px auto auto auto;
        }
      }
    }
  `,

  horizontal: () => css`
     {
      min-height: 50px;
      height: 10%;
      max-height: 100px;
      left: 0;
      right: 0;
      bottom: 0;

      .webcamActionButtonsContainer {
        flex-direction: row;

        .close {
          top: 0;
          bottom: 0;
        }

        .retry {
          margin: auto 20px auto auto;
        }

        .submit {
          margin: auto auto auto 20px;
        }
      }
    }
  `
};

interface WebcamActionsProps extends ComponentProps {
  isStreaming: boolean;
  onCapture: () => void;
  onRetry: () => void;
  onSubmit: () => void;
  onClose: () => void;
}

const WebcamActions: FC<WebcamActionsProps> = ({ isStreaming, onCapture, onRetry, onSubmit, onClose, className }: WebcamActionsProps) => (
  <div className={className}>
    <div className="webcamActionButtonsContainer">
      <WebcamActionButton onClick={onClose} iconType={IconType.CANCEL} data-test-id="closeWebcamButton" className="close" />
      {isStreaming ? (
        <WebcamActionButton onClick={onCapture} iconType={IconType.CAMERA} data-test-id="captureImageButton" className="capture" />
      ) : (
        <>
          <WebcamActionButton onClick={onRetry} iconType={IconType.UNDO} data-test-id="retryButton" className="retry" />
          <WebcamActionButton onClick={onSubmit} iconType={IconType.CHECK} data-test-id="submitCapturedImageButton" className="submit" />
        </>
      )}
    </div>
  </div>
);

export default createStyledComponent(memo(WebcamActions), webcamActionsStyle, webcamActionsStyleModifiers);
