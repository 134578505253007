/* eslint-disable react-hooks/exhaustive-deps */

import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { TextField } from "ui/form/controls/textField";

const FILTER_PANEL_SEARCHBAR_PLACEHOLDER = "Rechercher...";

interface ValuesSearchbarProps extends ComponentProps {
  searchedValue: string;
  onSearch: (value: string) => void;
}

const ValuesSearchbar: FC<ValuesSearchbarProps> = ({ searchedValue, onSearch }: ValuesSearchbarProps) => {
  return <TextField text={searchedValue} onInput={onSearch} placeholder={FILTER_PANEL_SEARCHBAR_PLACEHOLDER} data-test-id="textField" />;
};

export default memo(ValuesSearchbar);
