import { Asset } from "app/domain/asset";
import { getAssetOptions } from "app/store/asset/selectors";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Form as SemanticForm } from "semantic-ui-react";

import { AssetFields } from "../../../useAsset";

interface LocalisationInformationProps extends ComponentProps {
  asset: Asset | undefined;
  onUpdate: (fields: AssetFields) => void;
}

const LocalisationInformation: FC<LocalisationInformationProps> = ({ asset, onUpdate }: LocalisationInformationProps) => {
  const assetOptions = useSelector(getAssetOptions);

  const handleSelectChange = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({ [data.name]: data.value });
  };

  const handleChange = (event: any): void => {
    event.preventDefault();

    onUpdate({ [event.target.name]: event.target.value });
  };

  return (
    <>
      <SemanticForm.Group unstackable widths={4}>
        <SemanticForm.Input
          label="Numéro civique"
          placeholder="Numéro civique"
          name="civic_number"
          value={asset && asset.civic_number ? asset.civic_number : ""}
          onChange={handleChange}
        />
        <SemanticForm.Input label="Rue" placeholder="Rue" name="street_name" value={asset && asset.street_name ? asset.street_name : ""} onChange={handleChange} />
      </SemanticForm.Group>
      <SemanticForm.Group unstackable widths={4}>
        <SemanticForm.Input label="Ville" placeholder="Ville" name="city" value={asset && asset.city} onChange={handleChange} />
        <SemanticForm.Input label="Code postal" placeholder="Code postal" name="postal_code" value={asset && asset.postal_code} onChange={handleChange} />
      </SemanticForm.Group>
      <SemanticForm.Group unstackable widths={4}>
        <SemanticForm.Dropdown
          label="Région"
          clearable
          placeholder="Région"
          name="region"
          value={asset && asset.region}
          onChange={handleSelectChange}
          selection
          options={assetOptions && assetOptions.list_region_options ? assetOptions.list_region_options : []}
        />
        <SemanticForm.Input label="Province" placeholder="Province" name="province" value={asset && asset.province} onChange={handleChange} />
      </SemanticForm.Group>
      <SemanticForm.Group unstackable widths={4}>
        <SemanticForm.Input label="Pays" placeholder="Pays" name="country" value={asset && asset.country} onChange={handleChange} />
      </SemanticForm.Group>
    </>
  );
};

export default LocalisationInformation;
