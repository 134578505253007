import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import compare from "react-fast-compare";
import { Cell as ReactTableCell } from "react-table";
import { css, CSSProperties } from "styled-components";
import { Row, RowData } from "ui/table/types";

import { TableRowSelectionProps } from "../../useTable";
import RowSelectionButton from "../rowSelectionButton";
import TableBodyRowCell from "./tableBodyRowCell";

const tableBodyRowStyle = css`
  &:first-child > * {
    border-top: none;
  }

  &:last-child > * {
    border-bottom: none;
  }
`;

interface TableBodyRowProps extends ComponentProps {
  row: Row;
  prepareRow: (row: Row) => void;
  rowSelectionProps?: TableRowSelectionProps;
  style?: CSSProperties;
}

const isEqual = (prevProps: TableBodyRowProps, nextProps: TableBodyRowProps): boolean => {
  const isRowEqual = compare(prevProps.row, nextProps.row);
  const isPrepareRowEqual = compare(prevProps.prepareRow, nextProps.prepareRow);
  const isRowSelectionPropsEqual = compare(prevProps.rowSelectionProps, nextProps.rowSelectionProps);
  const isStyleEqual = compare(prevProps.style, nextProps.style);
  const isClassNameEqual = compare(prevProps.className, nextProps.className);

  return isRowEqual && isPrepareRowEqual && isRowSelectionPropsEqual && isStyleEqual && isClassNameEqual;
};

const TableBodyRow: FC<TableBodyRowProps> = ({ row, prepareRow, rowSelectionProps, style, className }: TableBodyRowProps) => {
  prepareRow(row);

  return (
    <div className={`${className} row`} data-test-id="row" style={style}>
      {rowSelectionProps && (
        <div className={`${className} cell`}>
          <RowSelectionButton
            isRowSelected={rowSelectionProps.selectedRowKeys.includes((row.original as RowData).id)}
            onRowSelect={(): void => rowSelectionProps.onSelect((row.original as RowData).id)}
          />
        </div>
      )}
      {row.cells.map((cell: ReactTableCell, index: number) => (
        <TableBodyRowCell cell={cell} rowData={row.original as RowData} onClick={row.onClick[index]} alignment={row.alignments[index]} key={cell.getCellProps().key} />
      ))}
    </div>
  );
};

export default createStyledComponent(memo(TableBodyRow), tableBodyRowStyle);
