import { Asset, AssetMinimalInfo } from "app/domain/asset";
import { createSelector } from "reselect";

import { State } from "../state";

export const getAssets = createSelector([(state: State): Asset[] => state.assets.assets], (assets: Asset[]) => assets);

export const getAssetOptions = createSelector([(state: State): any => state.assets.optionsLists], (optionsLists: any) => optionsLists);

export const getCurrentAsset = createSelector([(state: State): Asset | undefined => state.assets.currentAsset], (currentAsset: Asset | undefined) => currentAsset);

export const isFetching = createSelector([(state: State): boolean => state.assets.isFetching], (isFetchingAsset: boolean) => isFetchingAsset);

export const isUpdating = createSelector([(state: State): boolean => state.assets.isUpdating], (isAssetUpdating: boolean) => isAssetUpdating);

export const isLoading = createSelector([(state: State): boolean => state.assets.isUpdating || state.assets.isFetching], (isAssetLoading: boolean) => isAssetLoading);

export const isLoadingOptions = createSelector([(state: State): boolean => state.assets.isFetchingOptions], (isFetchingOptions: boolean) => isFetchingOptions);

export const getErrorMessage = createSelector([(state: State): string => state.assets.errorMessage], (errorMessage) => errorMessage);

export const getAssetsMinimalInfo = createSelector(
  [(state: State): AssetMinimalInfo[] => state.assets.assetsMinimalInfo],
  (assetsMinimalInfo: AssetMinimalInfo[]) => assetsMinimalInfo
);
