/* eslint-disable react-hooks/exhaustive-deps  */
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Cable } from "actioncable";
import QaError from "app/domain/qaError/qaError";
import QaErrorService from "app/service/qaError/qaErrorService";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import React, { FC, memo, useEffect, useState } from "react";
import { useAsync } from "react-async";
import styled, { css } from "styled-components";
import { Button } from "ui/button";
import Loader from "ui/loader/loader";
import { Modal } from "ui/modal";
import { Tab, Tabs } from "ui/tab";

import ProjectsSitesTreeView from "../project/projectsSitesTreeView";
import LinearProgressWithLabel from "./qa_loader";
import UserQaErrorIgnoredTable from "./userQaErrorIgnoredTable";
import UserQaErrorTable from "./userQaErrorTable";

const userQaErrorPageStyle = css`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  .loaderContainer {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
  }
`;
const StyledFormControl = styled(FormControl)`
  margin: 20px 0 !important;
`;

const StartQa = styled.div`
  display: flex;
  .MuiBox-root {
    width: 800px;
    margin-left: 50px;
  }
  span {
    height: 25px;
    border-radius: 10px;
  }
`;

const uniformat1s = {
  all: ["A", "B", "C", "D", "E", "F"],
  elec: ["D"],
  arch: ["A", "B", "C", "E", "F"]
};

type UserQaErrorPageProps = ComponentProps & { cable: Cable };

const UserQaErrorPage: FC<UserQaErrorPageProps> = ({ className, cable }: UserQaErrorPageProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [qaErrors, setQaErrors] = useState([] as QaError[]);
  const { onUpdate: onUpdateError } = useErrorMessage();
  const [checked, setChecked] = useState<string[]>([]);
  const [siteTarget, setSiteTarget] = useState("all");
  const [status, setStatus] = useState(-1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [isOpenQaErrors, setIsOpenQaErrors] = useState(false);
  const [comment, setComment] = useState("");

  const fetchQaErrors = (bypassed: boolean): Promise<QaError[]> => {
    setIsLoading(true);
    setQaErrors([]);
    return QaErrorService.getQaErrors({ bypassed });
  };

  const runSitesQarules = (): Promise<unknown> => {
    setIsOpen(false);
    setStatus(0);
    const uniformat1 = uniformat1s[siteTarget as "all" | "elec" | "arch"] as string[];
    return QaErrorService.runSitesQaRules(checked, uniformat1);
  };

  const handleSuccess = (fetchedQaErrors: QaError[]): void => {
    setIsLoading(false);
    setQaErrors(fetchedQaErrors);
  };

  const handleError = (): void => {
    setIsLoading(false);
    onUpdateError(`Une erreur s'est produite lors de la récupération des erreurs du contrôle qualité. Veuillez réessayer`);
  };

  const { run: getQaErrors } = useAsync({
    deferFn: fetchQaErrors as () => any,
    onResolve: handleSuccess,
    onReject: handleError
  });

  const { run: sendSitesIds } = useAsync({
    deferFn: runSitesQarules
  });

  const fetchBypassQaErrors = (): Promise<QaError[]> => {
    setIsLoading(true);
    return QaErrorService.bypassQaErrors(selectedRowKeys, comment);
  };

  const fetchKeepQaErrors = (): Promise<QaError[]> => {
    setIsLoading(true);
    return QaErrorService.keepQaErrors(selectedRowKeys);
  };

  const fetchBypassQaErrorsEnd = (): void => {
    setIsLoading(false);
    setIsOpenQaErrors(false);
    setQaErrors([]);
    setComment("");
    getQaErrors();
  };

  const fetchkeepQaErrorsEnd = (): void => {
    setIsLoading(false);
    setIsOpenQaErrors(false);
    setQaErrors([]);
    setComment("");
    getQaErrors(true);
  };

  const { run: sendBypassQaErrors } = useAsync({
    deferFn: fetchBypassQaErrors,
    onResolve: fetchBypassQaErrorsEnd
  });

  const { run: keepQaErrors } = useAsync({
    deferFn: fetchKeepQaErrors,
    onResolve: fetchkeepQaErrorsEnd
  });

  const token = localStorage.getItem("token");
  const decoded = jwt_decode(token as string);
  const id = _.get(decoded, "id");

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    getQaErrors();
    cable.subscriptions.create(
      { channel: "QaRuleChannel", user_id: id },
      {
        received: (data) => {
          setStatus(data);
          if (data === 100) {
            getQaErrors();
          }
        }
      }
    );

    return (): void => {
      // cable.disconnect();
    };
  }, []);

  const handlerOpen = (): void => {
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleChange = (event: SelectChangeEvent): void => {
    setSiteTarget(event.target.value as string);
  };

  const displayProgressBar = status >= 0 && status < 100;

  return (
    <div className={className}>
      <div className="loaderContainer">
        <Loader className="loader" isActive={isLoading} height={100} data-test-id="loader" />
      </div>
      <h1>Contrôle qualité - Erreurs</h1>
      <Tabs>
        <Tab label="Erreurs">
          <StartQa>
            <Button modifiers="outlined" title="Contrôle qualité" onClick={handlerOpen} disabled={displayProgressBar} />
            <Button modifiers="outlined" title="Ignorer erreur" onClick={(): void => setIsOpenQaErrors(true)} disabled={_.isEmpty(selectedRowKeys)} />
            {displayProgressBar && <LinearProgressWithLabel value={status} />}
          </StartQa>
          <UserQaErrorTable qaErrors={qaErrors} setSelectedRowKeys={setSelectedRowKeys} getQaErrors={getQaErrors} />
        </Tab>
        <Tab label="Erreurs ignorées">
          <Button modifiers="outlined" title="Ramener les erreurs" onClick={keepQaErrors} disabled={_.isEmpty(selectedRowKeys)} />
          <UserQaErrorIgnoredTable setSelectedRowKeys={setSelectedRowKeys} qaErrors={qaErrors} getIgnoredQaErrors={getQaErrors} />
        </Tab>
      </Tabs>

      <Modal
        isOpen={isOpen}
        content={
          <div style={{ padding: "20px 0" }}>
            <ProjectsSitesTreeView checked={checked} setChecked={setChecked} />
            <StyledFormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Sélectionner discipline</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" value={siteTarget} label="Sélectionner discipline" onChange={handleChange}>
                <MenuItem value="all">Tous</MenuItem>
                <MenuItem value="elec">Électrique/Mécanique</MenuItem>
                <MenuItem value="arch">Architecture</MenuItem>
              </Select>
            </StyledFormControl>
          </div>
        }
        title="Sélectionner le Projet / Site"
        actions={[<Button title="Appliquer" key={0} data-test-id="applySelection" onClick={sendSitesIds} />]}
        onClose={handleClose}
        data-test-id="modal"
      />

      <Modal
        isOpen={isOpenQaErrors}
        content={
          <div style={{ padding: "20px 0" }}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="commentaire"
              style={{ width: 400 }}
              value={comment}
              onChange={(event): void => setComment(event.target.value)}
            />
          </div>
        }
        title="Justification"
        actions={[<Button title="Appliquer" key={0} data-test-id="applySelection" onClick={sendBypassQaErrors} />]}
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        onClose={() => setIsOpenQaErrors(false)}
        data-test-id="modal"
      />
    </div>
  );
};

export default createStyledComponent(memo(UserQaErrorPage), userQaErrorPageStyle);
