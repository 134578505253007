/* eslint-disable react-hooks/exhaustive-deps */

import UniformatOption from "app/domain/uniformat/options/uniformatOption";
import { UniformatService } from "app/service/uniformat";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect } from "react";
import { useAsync } from "react-async";
import { Dropdown, DropdownItem } from "ui/form/controls/dropdown";
import { DropdownItem as DropdownItemInterface } from "ui/form/controls/dropdown/dropdown";

interface DropdownData {
  uniformatOptions: UniformatOption[];
  isLoading: boolean;
  isDisabled: boolean;
}

interface UniformatDropdownProps extends ComponentProps {
  value: string;
  projectId: string;
  label: string;
  uniformatLevel: string;
  uniformatParent: string;
  dropdownData: DropdownData;
  onUpdate: (data: string) => void;
  onSuccess: (uniformatOptions: UniformatOption[]) => void;
  onUpdateError: (errorMessage: string) => void;
}

const UniformatDropdown: FC<UniformatDropdownProps> = ({
  value,
  projectId,
  label,
  uniformatLevel,
  uniformatParent,
  dropdownData,
  onUpdate,
  onSuccess,
  onUpdateError
}: UniformatDropdownProps) => {
  const fetchUniformats = ([searchedUniformatParent]: string[]): Promise<UniformatOption[]> => UniformatService.getUniformatOptions(projectId, searchedUniformatParent);

  const handleSuccessFetchUniformat = (uniformatsFetched: UniformatOption[]): void => {
    onSuccess(uniformatsFetched);
  };

  const handleFetchUniformatsError = (): void => {
    onUpdateError(`Une erreur s'est produite lors de la récupération des uniformats. Veuillez réessayer`);
  };

  const { run: runUniformatOptions } = useAsync({
    deferFn: fetchUniformats,
    onResolve: handleSuccessFetchUniformat,
    onReject: handleFetchUniformatsError
  });

  const handleChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownItemInterface): void => {
    event.preventDefault();
    onUpdate(data.value);
  };

  useEffect(() => {
    if (uniformatParent || uniformatLevel === "level1") {
      runUniformatOptions(uniformatParent);
    }
  }, [uniformatParent, uniformatLevel]);

  return (
    <Dropdown label={label} value={value} isLoading={dropdownData.isLoading} onChange={handleChange} isDisabled={dropdownData.isDisabled} data-test-id="uniformatDropdown">
      {dropdownData.uniformatOptions.map((option: UniformatOption) => (
        <DropdownItem value={option.value} label={option.text} key={option.id} />
      ))}
    </Dropdown>
  );
};

export default memo(UniformatDropdown);
