import { useCallback, useEffect, useMemo, useState } from "react";

type TextFieldHook = {
  text: string;
  onInput: (newText: string) => void;
  onSet: (initialValue: string) => void;
};

const useTextField = (text: string): TextFieldHook => {
  const [currentText, setCurrentText] = useState(text);

  useEffect(() => {
    setCurrentText(text);
  }, [text]);

  const handleInput = useCallback((newText: string) => {
    setCurrentText(newText);
  }, []);

  const set = useCallback((value: string) => {
    setCurrentText(value);
  }, []);

  return useMemo(
    () => ({
      text: currentText,
      onInput: handleInput,
      onSet: set
    }),
    [currentText, handleInput, set]
  );
};

export default useTextField;
