import { categoryOptions, reasonOptions, updateOptions } from "app/data/formOptions";
import { System } from "app/domain/system";
import { FormFieldGroup } from "app/view/common/form";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, RefObject } from "react";
import { Form as SemanticForm, TextArea } from "semantic-ui-react";

import { SystemFields } from "../useSystem";

interface RenewalInformationProps extends ComponentProps {
  system: System | undefined;
  onUpdate: (fields: SystemFields) => void;
  onNavigationBlock: () => void;
}

const RenewalInformation: FC<RenewalInformationProps> = ({ system, onUpdate, onNavigationBlock }: RenewalInformationProps) => {
  const handleChange = (event: any): void => {
    event.preventDefault();

    onUpdate({ [event.target.name]: event.target.value });
    onNavigationBlock();
  };

  const handleSelectChange = (event: any, data: any): void => {
    event.preventDefault();

    onUpdate({
      [data.name]: data.value
    });
    onNavigationBlock();
  };

  return (
    <div>
      <h3>
        <span>2.1</span>Renouvellement
      </h3>
      <FormFieldGroup>
        <SemanticForm.Dropdown selection search clearable label="Cause" onChange={handleSelectChange} name="reason" value={system && system.reason} options={reasonOptions} />
        <SemanticForm.Dropdown
          selection
          search
          clearable
          label="Catégorie"
          onChange={handleSelectChange}
          name="category"
          value={system && system.category}
          options={categoryOptions}
        />
        <SemanticForm.Dropdown selection search clearable label="Mise à jour" onChange={handleSelectChange} name="update" value={system && system.update} options={updateOptions} />
        <div className="field">
          <TextArea
            id="renewalDescription"
            required
            size="large"
            placeholder="Description du renouvellement"
            name="renewalDescription"
            value={system && system.renewalDescription ? system.renewalDescription : ""}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <TextArea
            id="actionDescription"
            required
            size="large"
            placeholder="Description de l'action"
            name="actionDescription"
            value={system && system.actionDescription ? system.actionDescription : ""}
            onChange={handleChange}
          />
        </div>
      </FormFieldGroup>
    </div>
  );
};

export default RenewalInformation;
