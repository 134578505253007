/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Asset, AssetMinimalInfo } from "app/domain/asset";
import { AuditStatus } from "app/domain/audit/auditStatus";
import { EntityCategory } from "app/domain/entityCategory";

import { Action, PayloadAction } from "../types";

export enum AssetActionTypes {
  GET_ASSET = "GET_ASSET",
  GET_ASSET_REQUEST = "GET_ASSET_REQUEST",
  GET_ASSET_SUCCESS = "GET_ASSET_SUCCESS",
  GET_ASSET_ERROR = "GET_ASSET_ERROR",
  GET_ASSETS = "GET_ASSETS",
  GET_ASSETS_REQUEST = "GET_ASSETS_REQUEST",
  GET_ASSETS_SUCCESS = "GET_ASSETS_SUCCESS",
  GET_ASSETS_ERROR = "GET_ASSETS_ERROR",
  GET_ASSET_OPTIONS = "GET_ASSET_OPTIONS",
  GET_ASSET_OPTIONS_REQUEST = "GET_ASSET_OPTIONS_REQUEST",
  GET_ASSET_OPTIONS_SUCCESS = "GET_ASSET_OPTIONS_SUCCESS",
  GET_ASSET_OPTIONS_ERROR = "GET_ASSET_OPTIONS_ERROR",
  CREATE_ASSET = "CREATE_ASSET",
  CREATE_ASSET_REQUEST = "CREATE_ASSET_REQUEST",
  CREATE_ASSET_SUCCESS = "CREATE_ASSET_SUCCESS",
  CREATE_ASSET_ERROR = "CREATE_ASSET_ERROR",
  UPDATE_ASSET = "UPDATE_ASSET",
  UPDATE_ASSET_REQUEST = "UPDATE_ASSET_REQUEST",
  UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS",
  UPDATE_ASSET_ERROR = "UPDATE_ASSET_ERROR",
  GET_ASSETS_MINIMAL_INFO = "GET_ASSETS_MINIMAL_INFO",
  GET_ASSETS_MINIMAL_INFO_REQUEST = "GET_ASSETS_MINIMAL_INFO_REQUEST",
  GET_ASSETS_MINIMAL_INFO_SUCCESS = "GET_ASSETS_MINIMAL_INFO_SUCCESS",
  GET_ASSETS_MINIMAL_INFO_ERROR = "GET_ASSETS_MINIMAL_INFO_ERROR",
  RESET_ASSET = "RESET_ASSET"
}

export type GetAssetAction = PayloadAction<
  AssetActionTypes.GET_ASSET,
  {
    assetId: string;
  }
>;

export type GetAssetRequestAction = PayloadAction<
  AssetActionTypes.GET_ASSET_REQUEST,
  {
    assetId: string;
  }
>;

export type GetAssetSuccessAction = PayloadAction<
  AssetActionTypes.GET_ASSET_SUCCESS,
  {
    asset: Asset;
  }
>;

export type GetAssetErrorAction = PayloadAction<AssetActionTypes.GET_ASSET_ERROR, { errorMessage: string }>;

export type GetAssetsAction = PayloadAction<
  AssetActionTypes.GET_ASSETS,
  {
    parentCategory?: EntityCategory;
    parentId?: string;
    auditStatus?: AuditStatus;
  }
>;

export type GetAssetsRequestAction = PayloadAction<
  AssetActionTypes.GET_ASSETS_REQUEST,
  {
    parentCategory?: EntityCategory;
    parentId?: string;
    auditStatus?: AuditStatus;
  }
>;

export type GetAssetsSuccessAction = PayloadAction<
  AssetActionTypes.GET_ASSETS_SUCCESS,
  {
    assets: Asset[];
  }
>;

export type GetAssetsErrorAction = PayloadAction<AssetActionTypes.GET_ASSETS_ERROR, { errorMessage: string }>;

export type GetAssetOptionsAction = Action<AssetActionTypes.GET_ASSET_OPTIONS>;

export type GetAssetOptionsRequestAction = Action<AssetActionTypes.GET_ASSET_OPTIONS_REQUEST>;

// TODO: determine options type
export type GetAssetOptionsSuccessAction = PayloadAction<
  AssetActionTypes.GET_ASSET_OPTIONS_SUCCESS,
  {
    options: any;
  }
>;

export type GetAssetOptionsErrorAction = PayloadAction<AssetActionTypes.GET_ASSET_OPTIONS_ERROR, { errorMessage: string }>;

export type CreateAssetAction = PayloadAction<
  AssetActionTypes.CREATE_ASSET,
  {
    asset: Asset;
    siteId: string;
  }
>;

export type CreateAssetRequestAction = PayloadAction<
  AssetActionTypes.CREATE_ASSET_REQUEST,
  {
    asset: Asset;
    siteId: string;
  }
>;

export type CreateAssetSuccessAction = PayloadAction<
  AssetActionTypes.CREATE_ASSET_SUCCESS,
  {
    asset: Asset;
    assets: Asset[];
  }
>;

export type CreateAssetErrorAction = PayloadAction<AssetActionTypes.CREATE_ASSET_ERROR, { errorMessage: string }>;

export type UpdateAssetAction = PayloadAction<
  AssetActionTypes.UPDATE_ASSET,
  {
    asset: Asset;
    entityType: EntityCategory;
    entityId: string;
    auditStatus?: AuditStatus;
  }
>;

export type UpdateAssetRequestAction = PayloadAction<
  AssetActionTypes.UPDATE_ASSET_REQUEST,
  {
    asset: Asset;
    entityType: EntityCategory;
    entityId: string;
    auditStatus?: AuditStatus;
  }
>;

export type UpdateAssetSuccessAction = PayloadAction<
  AssetActionTypes.UPDATE_ASSET_SUCCESS,
  {
    asset: Asset;
    assets: Asset[];
  }
>;

export type UpdateAssetErrorAction = PayloadAction<AssetActionTypes.UPDATE_ASSET_ERROR, { errorMessage: string }>;

export type GetAssetsMinimalInfoAction = PayloadAction<
  AssetActionTypes.GET_ASSETS_MINIMAL_INFO,
  {
    siteId: string;
  }
>;

export type GetAssetsMinimalInfoRequestAction = PayloadAction<
  AssetActionTypes.GET_ASSETS_MINIMAL_INFO_REQUEST,
  {
    siteId: string;
  }
>;

export type GetAssetsMinimalInfoSuccessAction = PayloadAction<
  AssetActionTypes.GET_ASSETS_MINIMAL_INFO_SUCCESS,
  {
    assetsMinimalInfo: AssetMinimalInfo[];
  }
>;

export type GetAssetsMinimalInfoErrorAction = PayloadAction<AssetActionTypes.GET_ASSETS_MINIMAL_INFO_ERROR, { errorMessage: string }>;

export type ResetAction = Action<AssetActionTypes.RESET_ASSET>;
