/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Requirement } from "app/domain/requirement";
import { RequirementItemOption, RequirementProblematicOption, RequirementsOptions } from "app/domain/requirement/options";
import api, { NEW_API_VERSION } from "app/service/common/api";

import { RequirementItemOptionMapper, RequirementOptionsMapper, RequirementProblematicOptionMapper } from "./options";
import { RequirementItemOptionDTO } from "./options/requirementItemOptionMapper";
import { RequirementOptionsDTO } from "./options/requirementOptionsMapper";
import { RequirementProblematicOptionDTO } from "./options/requirementProblematicOptionMapper";
import RequirementMapper, { RequirementDTO } from "./requirementMapper";

class RequirementService {
  static getRequirement = async (requirementId: string): Promise<Requirement> => {
    const requirementDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/requirements/${requirementId}`
      })
    ).data as RequirementDTO;

    return RequirementMapper.fromDTO(requirementDTO);
  };

  static getRequirements = async (assetId: string): Promise<Requirement[]> => {
    const requirementDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/assets/${assetId}/requirements`
      })
    ).data as RequirementDTO[];

    return RequirementMapper.fromDTO(requirementDTOs);
  };

  static createRequirement = async (requirement: Requirement): Promise<Requirement> => {
    const requirementDTO = RequirementMapper.toDTO(requirement);

    const createdRequirementDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/requirements`,
        body: requirementDTO
      })
    ).data as RequirementDTO;

    return RequirementMapper.fromDTO(createdRequirementDTO);
  };

  static createRequirementCopies = async (requirementId: string, requirementCopies: Requirement[]): Promise<Requirement[]> => {
    const requirementCopyDTOs = RequirementMapper.toDTO(requirementCopies);
    const createdRequirementCopyDTOs = (
      await api.post({
        path: `${NEW_API_VERSION}/requirements/${requirementId}/copies`,
        body: requirementCopyDTOs
      })
    ).data as RequirementDTO[];

    return RequirementMapper.fromDTO(createdRequirementCopyDTOs);
  };

  static updateRequirement = async (requirement: Requirement): Promise<Requirement> => {
    const requirementDTO = RequirementMapper.toDTO(requirement);

    const updatedRequirementDTO = (
      await api.patch({
        id: requirementDTO.id,
        path: `${NEW_API_VERSION}/requirements`,
        body: requirementDTO
      })
    ).data as RequirementDTO;

    return RequirementMapper.fromDTO(updatedRequirementDTO);
  };

  static deleteRequirement = async (requirementId: string): Promise<void> => {
    await api.delete({
      path: `${NEW_API_VERSION}/requirements`,
      id: requirementId
    });
  };

  static getRequirementOptions = async (): Promise<RequirementsOptions> => {
    const requirementOptionsDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/requirements/options`
      })
    ).data as RequirementOptionsDTO;

    return RequirementOptionsMapper.fromDTO(requirementOptionsDTO);
  };

  static addRequirementProblematicOption = async (requirementProblematicOption: RequirementProblematicOption): Promise<RequirementProblematicOption> => {
    const requirementProblematicOptionDTO = RequirementProblematicOptionMapper.toDTO(requirementProblematicOption);
    const newRequirementProblematicOptionDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/requirement_problematic_options`,
        body: requirementProblematicOptionDTO
      })
    ).data as RequirementProblematicOptionDTO;

    return RequirementProblematicOptionMapper.fromDTO(newRequirementProblematicOptionDTO);
  };

  static addRequirementItemOption = async (requirementItemOption: RequirementItemOption): Promise<RequirementItemOption> => {
    const requirementItemOptionDTO = RequirementItemOptionMapper.toDTO(requirementItemOption);
    const newRequirementItemOptionDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/requirement_item_options`,
        body: requirementItemOptionDTO
      })
    ).data as RequirementItemOptionDTO;

    return RequirementItemOptionMapper.fromDTO(newRequirementItemOptionDTO);
  };
}

export default RequirementService;
