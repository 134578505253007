import Option from "app/domain/common/option";

export type OptionDTO = {
  text: string;
  value: string;
  id?: number;
};

class OptionMapper {
  static fromDTO(optionDTOs: OptionDTO[]): Option[] {
    return optionDTOs.map(
      (optionDTO: OptionDTO): Option =>
        new Option({
          text: optionDTO.text,
          value: optionDTO.value,
          id: optionDTO.id
        })
    );
  }
}

export default OptionMapper;
