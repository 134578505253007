import { createSelector } from "@reduxjs/toolkit";
import { CostItem } from "app/domain/costItem";

import { State } from "../state";

export const getCostItems = createSelector([(state: State): CostItem[] => state.costItems.costItems], (costItems: CostItem[]) => costItems);

export const isLoading = createSelector([(state: State): boolean => state.costItems.isLoading], (isLoadingCostIem: boolean) => isLoadingCostIem);

export const getErrorMessage = createSelector([(state: State): string => state.costItems.errorMessage], (errorMessage: string) => errorMessage);
