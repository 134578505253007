/* eslint-disable react-hooks/exhaustive-deps */

import { FilterType } from "app/domain/filter";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo, useCallback } from "react";
import compare from "react-fast-compare";
import { Button } from "ui/button";
import { Modal } from "ui/modal";

import FilterPanelModalContent from "./filterPanelModalContent";
import useValuesSelector from "./useValuesSelector";

const APPLY = "Appliquer";

interface FilterPanelModalProps extends ComponentProps {
  values: string[];
  filteredValues: string[];
  onFilter: (values: string[]) => void;
  filterType: FilterType;
  isOpen: boolean;
  onClose: () => void;
}

const isEqual = (prevProps: FilterPanelModalProps, nextProps: FilterPanelModalProps): boolean => {
  const areValuesEqual = compare(prevProps.values, nextProps.values);
  const areFilteredValuesEqual = compare(prevProps.filteredValues, nextProps.filteredValues);
  const isOpenEqual = compare(prevProps.isOpen, nextProps.isOpen);

  return areValuesEqual && areFilteredValuesEqual && isOpenEqual;
};

const FilterPanelModal: FC<FilterPanelModalProps> = ({ values, filteredValues, onFilter, filterType, isOpen, onClose }: FilterPanelModalProps) => {
  const {
    onSelect,
    onSelectAll,
    onReset,
    onFilter: onValuesFilter,
    selectedValues,
    values: currentValues,
    isChecked,
    isIndeterminate
  } = useValuesSelector(values, filteredValues, filterType === FilterType.COLUMN_FILTER);

  const handleApply = useCallback(() => {
    onFilter(selectedValues);
    onClose();
  }, [onFilter, selectedValues, onClose]);

  const handleClose = (): void => {
    if (filterType === FilterType.COLUMN_FILTER) {
      onReset();
    }
    onClose();
  };

  const handleSelect = (value: string): void => {
    onSelect(value);

    if (filterType === FilterType.QUICK_FILTER) {
      onFilter([value]);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      content={
        <FilterPanelModalContent
          values={currentValues}
          selectedValues={selectedValues}
          onSelect={handleSelect}
          onSelectAll={filterType === FilterType.COLUMN_FILTER ? onSelectAll : undefined}
          onFilter={filterType === FilterType.COLUMN_FILTER ? onValuesFilter : undefined}
          isChecked={isChecked}
          isIndeterminate={isIndeterminate}
          data-test-id="filterPanelModalContent"
        />
      }
      actions={
        filterType === FilterType.COLUMN_FILTER
          ? [<Button title={APPLY} onClick={handleApply} disabled={selectedValues.length === 0} key={0} data-test-id="applyFiltersActionButton" />]
          : []
      }
      onClose={handleClose}
      data-test-id="modal"
    />
  );
};

export default memo(FilterPanelModal, isEqual);
