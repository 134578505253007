/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import Manufacturer from "app/domain/manufacturer/manufacturer";
import api, { NEW_API_VERSION } from "app/service/common/api";

import ManufacturerMapper, { ManufacturerDTO } from "./manufacturerMapper";

class ManufacturerService {
  static getManufacturers = async (): Promise<Manufacturer[]> => {
    const manufacturerDTOs = (
      await api.get({
        path: `${NEW_API_VERSION}/manufacturers`
      })
    ).data as ManufacturerDTO[];

    return ManufacturerMapper.fromDTO(manufacturerDTOs);
  };

  static createManufacturer = async (option: Manufacturer): Promise<Manufacturer> => {
    const manufacturerDTO = ManufacturerMapper.toDTO(option);
    const returnedManufacturerDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/manufacturers`,
        body: manufacturerDTO
      })
    ).data as ManufacturerDTO;

    return ManufacturerMapper.fromDTO(returnedManufacturerDTO);
  };
}

export default ManufacturerService;
