interface LocalProps {
  id: string;
  number?: string;
  description?: string;
  area?: number;
  floorNumber?: string;
  assetId?: string;
}

class Local {
  id: string;
  number?: string;
  description?: string;
  area?: number;
  floorNumber?: string;
  assetId?: string;

  constructor(localProps: LocalProps) {
    this.id = localProps.id;
    this.number = localProps.number;
    this.description = localProps.description;
    this.area = localProps.area;
    this.floorNumber = localProps.floorNumber;
    this.assetId = localProps.assetId;
  }
}

export default Local;
