interface SiteProps {
  id: string;
  name: string;
}

class Site {
  value: string;
  label: string;
  constructor(siteProps: SiteProps) {
    this.value = siteProps.id;
    this.label = siteProps.name;
  }
}

export default Site;
