import { useCallback, useMemo, useState } from "react";

import { ImageStatus } from "./imageStatus";

type ImageUploaderHook = {
  onChange: (imageFile: File, imageStatus: ImageStatus) => void;
  onReset: () => void;
  uploadedImages: File[];
};

const useImageUploader = (): ImageUploaderHook => {
  const [uploadedImages, setUploadedImages] = useState([] as File[]);

  const onChange = useCallback(
    (imageFile: File, imageStatus: ImageStatus) => {
      if (imageStatus === ImageStatus.REMOVED) {
        setUploadedImages(uploadedImages.filter((uploadedImage) => uploadedImage !== imageFile));
      } else if (imageStatus === ImageStatus.UPLOADED) {
        setUploadedImages([...uploadedImages, imageFile]);
      }
    },
    [uploadedImages]
  );

  const onReset = useCallback(() => {
    setUploadedImages([]);
  }, []);

  return useMemo(
    () => ({
      onChange,
      onReset,
      uploadedImages
    }),
    [onChange, onReset, uploadedImages]
  );
};

export default useImageUploader;
