import SearchItem from "app/domain/search/searchItem";
import { getSearchResultsAction } from "app/store/search/actions";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { css } from "styled-components";

import SearchInput from "./searchInput";
import SearchResultContainer from "./searchResultContainer";

const searchBarStyle = css`
  position: relative;
  margin-right: 5px;
`;

type SearchBarProps = ComponentProps;

const SearchBar: FC<SearchBarProps> = ({ className }: SearchBarProps) => {
  const [isSearchResultContainerVisible, setSearchResultContainerVisibility] = useState(false);
  const [searchedItem, setSearchedItem] = useState("");

  const dispatch = useDispatch();

  const handleChange = useCallback((newValue: string) => {
    setSearchedItem(newValue);
  }, []);

  const handleSearch = useCallback(() => {
    const searchItem = new SearchItem(searchedItem, "");

    dispatch(getSearchResultsAction(searchItem));
    setSearchResultContainerVisibility(true);
  }, [searchedItem, dispatch]);

  const handleSearchResultSelection = useCallback(() => {
    setSearchedItem("");
    setSearchResultContainerVisibility(false);
  }, []);

  return (
    <div className={className}>
      <SearchInput onSearch={handleSearch} onChange={handleChange} value={searchedItem} data-test-id="searchInput" />
      <SearchResultContainer
        setIsVisible={setSearchResultContainerVisibility}
        isVisible={isSearchResultContainerVisible}
        onSelect={handleSearchResultSelection}
        data-test-id="searchResultContainer"
      />
    </div>
  );
};

export default createStyledComponent(SearchBar, searchBarStyle);
