import { LabelImportantRounded } from "@mui/icons-material";
import { ListItemButton, ListItemText } from "@mui/material";
import { useRouter } from "app/routing/routerProvider";
import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";
import { colors } from "ui/colors";

const menuListItemStyle = css`
  &&& {
    background-color: ${(props: StyleProps): string => (props.isSelected ? colors.ACCENT_BLUE : colors.SECONDARY_BLACK)};
  }
`;

export interface MenuItem {
  id: string;
  label: string;
  url: string;
}

interface MenuListItemProps extends ComponentProps {
  item: MenuItem;
  isSelected: boolean;
  onClick: () => void;
}

const MenuListItem: FC<MenuListItemProps> = ({ item, isSelected, onClick, className }: MenuListItemProps) => {
  const { goTo } = useRouter();

  const handleClick = (): void => {
    onClick();
    goTo(item.url);
  };

  return (
    <ListItemButton className={className} onClick={handleClick} selected={isSelected}>
      <LabelImportantRounded />
      <ListItemText style={{ paddingLeft: "5px" }} primary={item.label} />
    </ListItemButton>
  );
};

export default createStyledComponent(MenuListItem, menuListItemStyle);
