/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AssetMinimalInfo } from "app/domain/asset";
import Asset from "app/domain/asset/asset";
import { AuditStatus } from "app/domain/audit/auditStatus";
import { EntityCategory } from "app/domain/entityCategory";
import api, { GetRequest, NEW_API_VERSION, QueryParams } from "app/service/common/api";

import AssetForm from "../../domain/asset/assetForm";
import AssetFormMapper from "../../domain/asset/assetFormMapper";
import AssetMapper, { AssetDTO } from "./assetMapper";
import AssetMinimalInfoMapper, { AssetMinimalInfoDTO } from "./assetMinimalInfoMapper";

const getQueryKey = (parentCategory: EntityCategory): string => (parentCategory === EntityCategory.PROJECT ? "project_id" : "site_id");

const getQueryParam = (parentCategory: EntityCategory, parentId: string, auditStatus?: AuditStatus): QueryParams | undefined => {
  if (auditStatus) {
    return {
      [getQueryKey(parentCategory)]: parentId,
      audit_status: auditStatus
    };
  }
  return { [getQueryKey(parentCategory)]: parentId };
};

const getRequestParams = (parentCategory?: EntityCategory, parentId?: string, auditStatus?: AuditStatus): GetRequest => {
  const pathParam = { path: `${NEW_API_VERSION}/assets` };
  if (parentCategory && parentId && !auditStatus) {
    return {
      ...pathParam,
      queryParams: getQueryParam(parentCategory, parentId)
    };
  }
  if (parentCategory && parentId && auditStatus) {
    return {
      ...pathParam,
      queryParams: getQueryParam(parentCategory, parentId, auditStatus)
    };
  }
  return pathParam;
};

class AssetService {
  static getAssets = async (parentCategory?: EntityCategory, parentId?: string, auditStatus?: AuditStatus): Promise<Asset[]> => {
    const assetsDTO = (await api.get(getRequestParams(parentCategory, parentId, auditStatus))).data as AssetDTO[];

    return AssetMapper.fromDTO(assetsDTO);
  };

  static getAsset = async (assetId: string): Promise<Asset> => {
    const assetDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/assets/${assetId}`
      })
    ).data as AssetDTO;

    return AssetMapper.fromDTO(assetDTO);
  };

  static createAsset = async (asset: Asset): Promise<Asset> => {
    const assetDTO = AssetMapper.toDTO(asset);
    const createAssetDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/assets`,
        body: assetDTO
      })
    ).data as AssetDTO;

    return AssetMapper.fromDTO(createAssetDTO);
  };

  static updateAsset = async (asset: Asset): Promise<Asset> => {
    const assetDTO = AssetMapper.toDTO(asset);

    const updatedAssetDTO = (
      await api.put({
        id: assetDTO.id,
        path: `${NEW_API_VERSION}/assets`,
        body: assetDTO
      })
    ).data as AssetDTO;

    return AssetMapper.fromDTO(updatedAssetDTO);
  };

  static getAssetsMinimalInfo = async (siteId: string): Promise<AssetMinimalInfo[]> => {
    const assetsMinimalInfoDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/assets`,
        queryParams: { site_id: siteId, options: "minimal" }
      })
    ).data as AssetMinimalInfoDTO[];

    return AssetMinimalInfoMapper.fromDTO(assetsMinimalInfoDTO);
  };

  static postAsset = async (newAsset: AssetForm, siteId: string): Promise<Asset> => {
    const newAssetDTO = AssetFormMapper.toAsset(newAsset, siteId);

    const assetsDTO = (
      await api.post({
        path: `${NEW_API_VERSION}/assets`,
        body: newAssetDTO
      })
    ).data as AssetDTO;

    return AssetMapper.fromDTO(assetsDTO);
  };
}

export default AssetService;
