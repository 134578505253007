import { createAction } from "@reduxjs/toolkit";
import { CostItem } from "app/domain/costItem";

import { CostItemActionTypes } from "./types";

export const getCostItemsAction = createAction(CostItemActionTypes.GET_COST_ITEMS);

export const getCostItemsRequestAction = createAction(CostItemActionTypes.GET_COST_ITEMS_REQUEST);

export const getCostItemsSuccessAction = createAction(CostItemActionTypes.GET_COST_ITEMS_SUCCESS, (costItems: CostItem[]) => ({
  payload: {
    costItems
  }
}));

export const getCostItemsErrorAction = createAction(CostItemActionTypes.GET_COST_ITEMS_ERROR, (errorMessage: string) => ({
  payload: {
    errorMessage
  }
}));

export const resetAction = createAction(CostItemActionTypes.RESET_COST_ITEM);
