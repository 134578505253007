/* eslint-disable no-nested-ternary */

import { Uniformat } from "app/domain/uniformat";
import { useCallback, useEffect, useMemo, useState } from "react";

type UniformatHook = {
  uniformat: any;
  lastUniformat: string;
  onUpdate: (uniformat: Uniformat) => void;
};

const useUniformat = (initialUniformat: Uniformat | undefined): UniformatHook => {
  const [currentUniformat, setCurrentUniformat] = useState(initialUniformat || ({ level2: "", level3: "", level4: "" } as Uniformat));

  const onUpdate = useCallback((uniformat: Uniformat) => {
    setCurrentUniformat(uniformat);
  }, []);

  const lastUniformat = currentUniformat.level4
    ? currentUniformat.level4
    : currentUniformat.level3
    ? currentUniformat.level3
    : currentUniformat.level2
    ? currentUniformat.level2
    : currentUniformat.level1
    ? currentUniformat.level1
    : "";

  useEffect(() => {
    if (initialUniformat) {
      onUpdate(initialUniformat);
    }
  }, [initialUniformat, onUpdate]);

  return useMemo(
    () => ({
      uniformat: currentUniformat,
      lastUniformat,
      onUpdate
    }),
    [currentUniformat, onUpdate, lastUniformat]
  );
};

export default useUniformat;
