import Local from "app/domain/local/local";
import { createSelector } from "reselect";

import { State } from "../state";

export const getLocals = createSelector([(state: State): Local[] => state.locals.locals], (locals: Local[]) => locals);

export const getCurrentRoom = createSelector([(state: State): Local | undefined => state.locals.currentRoom], (room: Local | undefined) => room);

export const isFetching = createSelector([(state: State): boolean => state.locals.isFetching || state.locals.isFetching], (_isFetching: boolean) => _isFetching);

export const getErrorMessage = createSelector([(state: State): string => state.locals.errorMessage], (errorMessage) => errorMessage);
