import MaterialCheckbox from "@mui/material/Checkbox";
import MaterialFormControlLabel from "@mui/material/FormControlLabel";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { forwardRef, memo } from "react";
import { css } from "styled-components";
import * as constants from "ui/form/controls/constants";

const checkboxStyle = css`
  && {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0.8em;
    user-select: none;

    .MuiTouchRipple-root {
      display: none;
    }

    .MuiCheckbox-colorSecondary {
      padding: 0;
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: ${constants.DEFAULT_FORM_CONTROL_FOCUS_COLOR};
    }

    .MuiCheckbox-colorSecondary.MuiCheckbox-indeterminate {
      color: ${constants.DEFAULT_FORM_CONTROL_FOCUS_COLOR};
    }

    .MuiIconButton-colorSecondary:hover {
      background-color: transparent;
    }

    .MuiTypography-body1 {
      font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
      color: rgba(0, 0, 0, 0.87);
      font-size: 1em;
      padding-left: 0.3em;
    }
  }
`;

interface CheckboxProps extends ComponentProps {
  onClick: (event: React.FormEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  indeterminate?: boolean;
  label?: string;
  name?: string;
}

const Checkbox = forwardRef(({ isChecked, indeterminate, onClick, label, name, className }: CheckboxProps, ref): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLLabelElement>): void => {
    event.stopPropagation();
  };

  return (
    <MaterialFormControlLabel
      control={
        <MaterialCheckbox checked={isChecked || false} indeterminate={indeterminate || false} onChange={onClick} onClick={handleClick} name={name} data-test-id="checkbox" />
      }
      label={label}
      className={className}
      onClick={handleClick}
      ref={ref}
    />
  );
});

Checkbox.displayName = "Checkbox";

export default createStyledComponent(memo(Checkbox), checkboxStyle);
