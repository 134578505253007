import { CostItem } from "app/domain/costItem";
import { useI18n } from "app/locales";
import CostItemService from "app/service/costItem/costItemService";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { useLoader } from "app/view/common/loader/loaderProvider";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useCallback } from "react";
import { useAsync } from "react-async";
import { Table as SemanticTable } from "semantic-ui-react";
import { css } from "styled-components";
import { Button } from "ui/button";
import { TextField, useTextField } from "ui/form/controls/textField";

const customCostItemTableStyle = css`
  && {
    .buttons {
      > * {
        float: right;
      }

      > *:not(:first-child) {
        margin-right: 10px;
      }
    }
  }
`;

interface CustomCostItemTableProps extends ComponentProps {
  onAdd: (costItem: CostItem) => void;
  onClose: () => void;
}

const CustomCostItemTable: FC<CustomCostItemTableProps> = ({ onAdd, onClose, className }: CustomCostItemTableProps) => {
  const { getAttributeLabel } = useI18n();
  const { setIsActive } = useLoader();
  const { onUpdate } = useErrorMessage();
  const { dictionaryCode } = useI18n();

  const { text: description, onInput: onDescriptionInput } = useTextField("");
  const { text: unitOfMeasure, onInput: onUnitOfMeasureInput } = useTextField("");
  const { text: unitCost, onInput: onUnitCostInput } = useTextField("");

  const addCostItem = ([costItem]: CostItem[]): Promise<CostItem> => {
    setIsActive(true);
    return CostItemService.addCostItem(costItem);
  };

  const handleSuccess = (costItem: CostItem): void => {
    setIsActive(false);
    onAdd(costItem);
    onClose();
  };

  const handleError = (): void => {
    setIsActive(false);
    onUpdate("Une erreur s'est produite lors de la création de l'élément de coût. Veuillez réessayer.");
  };

  const { run } = useAsync({
    deferFn: addCostItem,
    onResolve: handleSuccess,
    onReject: handleError
  });

  const addCustomCostItem = useCallback(() => {
    const costItem = new CostItem({
      unitCost: unitCost === "" ? 0 : parseFloat(unitCost),
      description,
      unitOfMeasure
    });

    run(costItem);
  }, [unitCost, description, unitOfMeasure, run]);

  return (
    <div className={className}>
      <SemanticTable>
        <SemanticTable.Header>
          <SemanticTable.Row>
            <SemanticTable.HeaderCell width={7}>{getAttributeLabel("description")}</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>{getAttributeLabel("unit_of_measure")}</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>{getAttributeLabel("unit_cost")}</SemanticTable.HeaderCell>
          </SemanticTable.Row>
        </SemanticTable.Header>
        <SemanticTable.Body>
          <SemanticTable.Row>
            <SemanticTable.Cell>
              <TextField text={description} placeholder={getAttributeLabel("description")} onInput={onDescriptionInput} data-test-id="descriptionTextField" />
            </SemanticTable.Cell>
            <SemanticTable.Cell>
              <TextField text={unitOfMeasure} placeholder={getAttributeLabel("unit_of_measure")} onInput={onUnitOfMeasureInput} data-test-id="unitTextField" />
            </SemanticTable.Cell>
            <SemanticTable.Cell>
              <TextField text={unitCost} placeholder={getAttributeLabel("unit_cost")} onInput={onUnitCostInput} number data-test-id="unitCostTextField" />
            </SemanticTable.Cell>
          </SemanticTable.Row>
        </SemanticTable.Body>
      </SemanticTable>
      <div className="buttons">
        <Button onClick={addCustomCostItem} title="Ajouter" data-test-id="addButton" />
        <Button onClick={onClose} title="Annuler" data-test-id="cancelButton" modifiers="outlined" color="#d80000" />
      </div>
    </div>
  );
};

export default createStyledComponent(memo(CustomCostItemTable), customCostItemTableStyle);
