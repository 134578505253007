import { EntityCategory } from "app/domain/entityCategory";
import { System } from "app/domain/system";

import { Action, PayloadAction } from "../types";

export enum SystemActionTypes {
  GET_SYSTEM = "GET_SYSTEM",
  GET_SYSTEM_REQUEST = "GET_SYSTEM_REQUEST",
  GET_SYSTEM_SUCCESS = "GET_SYSTEM_SUCCESS",
  GET_SYSTEM_ERROR = "GET_SYSTEM_ERROR",
  GET_SYSTEMS = "GET_SYSTEMS",
  GET_SYSTEMS_REQUEST = "GET_SYSTEMS_REQUEST",
  GET_SYSTEMS_SUCCESS = "GET_SYSTEMS_SUCCESS",
  GET_SYSTEMS_ERROR = "GET_SYSTEMS_ERROR",
  GET_SYSTEM_OPTIONS = "GET_SYSTEM_OPTIONS",
  GET_SYSTEM_OPTIONS_REQUEST = "GET_SYSTEM_OPTIONS_REQUEST",
  GET_SYSTEM_OPTIONS_SUCCESS = "GET_SYSTEM_OPTIONS_SUCCESS",
  GET_SYSTEM_OPTIONS_ERROR = "GET_SYSTEM_OPTIONS_ERROR",
  ADD_SYSTEM = "ADD_SYSTEM",
  ADD_SYSTEM_REQUEST = "ADD_SYSTEM_REQUEST",
  ADD_SYSTEM_SUCCESS = "ADD_SYSTEM_SUCCESS",
  ADD_SYSTEM_ERROR = "ADD_SYSTEM_ERROR",
  UPDATE_SYSTEM = "UPDATE_SYSTEM",
  UPDATE_SYSTEM_REQUEST = "UPDATE_SYSTEM_REQUEST",
  UPDATE_SYSTEM_SUCCESS = "UPDATE_SYSTEM_SUCCESS",
  UPDATE_SYSTEM_ERROR = "UPDATE_SYSTEM_ERROR",
  DELETE_SYSTEM = "DELETE_SYSTEM",
  DELETE_SYSTEM_REQUEST = "DELETE_SYSTEM_REQUEST",
  DELETE_SYSTEM_SUCCESS = "DELETE_SYSTEM_SUCCESS",
  DELETE_SYSTEM_ERROR = "DELETE_SYSTEM_ERROR",
  GENERATE_SYSTEM_LABELS = "GENERATE_SYSTEM_LABELS",
  GENERATE_SYSTEM_LABELS_REQUEST = "GENERATE_SYSTEM_LABELS_REQUEST",
  GENERATE_SYSTEM_LABELS_SUCCESS = "GENERATE_SYSTEM_LABELS_SUCCESS",
  GENERATE_SYSTEM_LABELS_ERROR = "GENERATE_SYSTEM_LABELS_ERROR",
  GENERATE_SYSTEM_COPIES_TO_DESTINATIONS_SUCCESS = "GENERATE_SYSTEM_COPIES_TO_DESTINATIONS_SUCCESS",
  GENERATE_SYSTEM_COPIES_TO_DESTINATIONS_ERROR = "GENERATE_SYSTEM_COPIES_TO_DESTINATIONS_ERROR",
  GENERATE_SYSTEM_COPIES_TO_DESTINATIONS_REQUEST = "GENERATE_SYSTEM_COPIES_TO_DESTINATIONS_REQUEST",
  GENERATE_SYSTEM_COPIES = "GENERATE_SYSTEM_COPIES",
  GENERATE_SYSTEM_COPIES_REQUEST = "GENERATE_SYSTEM_COPIES_REQUEST",
  GENERATE_SYSTEM_COPIES_SUCCESS = "GENERATE_SYSTEM_COPIES_SUCCESS",
  GENERATE_SYSTEM_COPIES_ERROR = "GENERATE_SYSTEM_COPIES_ERROR",
  SET_FILTERED_SYSTEMS = "SET_FILTERED_SYSTEMS",
  SET_REDIRECT_TO_SYSTEMS_PAGE = "SET_REDIRECT_TO_SYSTEMS_PAGE",
  RESET_SYSTEM = "RESET_SYSTEM"
}

export type GetSystemAction = PayloadAction<
  SystemActionTypes.GET_SYSTEM,
  {
    systemId: string;
  }
>;

export type GetSystemRequestAction = PayloadAction<
  SystemActionTypes.GET_SYSTEM_REQUEST,
  {
    systemId: string;
  }
>;

export type GetSystemSuccessAction = PayloadAction<
  SystemActionTypes.GET_SYSTEM_SUCCESS,
  {
    system: System;
  }
>;

export type GetSystemErrorAction = PayloadAction<SystemActionTypes.GET_SYSTEM_ERROR, { errorMessage: string }>;

export type GetSystemsAction = PayloadAction<
  SystemActionTypes.GET_SYSTEMS,
  {
    parentType: EntityCategory;
    parentId: string;
  }
>;

export type GetSystemsRequestAction = PayloadAction<
  SystemActionTypes.GET_SYSTEMS_REQUEST,
  {
    parentType: EntityCategory;
    parentId: string;
  }
>;

export type GetSystemsSuccessAction = PayloadAction<
  SystemActionTypes.GET_SYSTEMS_SUCCESS,
  {
    systems: System[];
  }
>;

export type GetSystemsErrorAction = PayloadAction<SystemActionTypes.GET_SYSTEMS_ERROR, { errorMessage: string }>;

export type GetSystemOptionsAction = Action<SystemActionTypes.GET_SYSTEM_OPTIONS>;

export type GetSystemOptionsRequestAction = Action<SystemActionTypes.GET_SYSTEM_OPTIONS_REQUEST>;

// TODO: determine options type
export type GetSystemOptionsSuccessAction = PayloadAction<
  SystemActionTypes.GET_SYSTEM_OPTIONS_SUCCESS,
  {
    options: any;
  }
>;

export type GetSystemOptionsErrorAction = PayloadAction<SystemActionTypes.GET_SYSTEM_OPTIONS_ERROR, { errorMessage: string }>;

export type AddSystemAction = PayloadAction<
  SystemActionTypes.ADD_SYSTEM,
  {
    system: System;
    isClosing: boolean;
  }
>;

export type AddSystemRequestAction = PayloadAction<
  SystemActionTypes.ADD_SYSTEM_REQUEST,
  {
    system: System;
    isClosing: boolean;
  }
>;

export type AddSystemSuccessAction = PayloadAction<
  SystemActionTypes.ADD_SYSTEM_SUCCESS,
  {
    system: System;
    isClosing: boolean;
  }
>;

export type AddSystemErrorAction = PayloadAction<SystemActionTypes.ADD_SYSTEM_ERROR, { errorMessage: string }>;

export type UpdateSystemAction = PayloadAction<
  SystemActionTypes.UPDATE_SYSTEM,
  {
    system: System;
    isClosing: boolean;
  }
>;

export type UpdateSystemRequestAction = PayloadAction<
  SystemActionTypes.UPDATE_SYSTEM_REQUEST,
  {
    system: System;
    isClosing: boolean;
  }
>;

export type UpdateSystemSuccessAction = PayloadAction<
  SystemActionTypes.UPDATE_SYSTEM_SUCCESS,
  {
    system: System;
    isClosing: boolean;
  }
>;

export type UpdateSystemErrorAction = PayloadAction<SystemActionTypes.UPDATE_SYSTEM_ERROR, { errorMessage: string }>;

export type DeleteSystemAction = PayloadAction<
  SystemActionTypes.DELETE_SYSTEM,
  {
    systemId: string;
  }
>;

export type DeleteSystemRequestAction = PayloadAction<
  SystemActionTypes.DELETE_SYSTEM_REQUEST,
  {
    systemId: string;
  }
>;

export type DeleteSystemSuccessAction = PayloadAction<
  SystemActionTypes.DELETE_SYSTEM_SUCCESS,
  {
    systemId: string;
  }
>;

export type DeleteSystemErrorAction = PayloadAction<SystemActionTypes.DELETE_SYSTEM_ERROR, { errorMessage: string }>;

export type GenerateSystemLabelsAction = PayloadAction<
  SystemActionTypes.GENERATE_SYSTEM_LABELS,
  {
    systemIds: string[];
  }
>;

export type GenerateSystemLabelsRequestAction = PayloadAction<
  SystemActionTypes.GENERATE_SYSTEM_LABELS_REQUEST,
  {
    systemIds: string[];
  }
>;

export type GenerateSystemLabelsSuccessAction = PayloadAction<SystemActionTypes.GENERATE_SYSTEM_LABELS_SUCCESS, { system: System }>;

export type GenerateSystemLabelsErrorAction = PayloadAction<SystemActionTypes.GENERATE_SYSTEM_LABELS_ERROR, { errorMessage: string }>;

export type GenerateSystemCopiesAction = PayloadAction<
  SystemActionTypes.GENERATE_SYSTEM_COPIES,
  {
    systemId: string;
    systemCopies: System[];
    parentId: string;
  }
>;

export type GenerateSystemCopiesToDestinationRequestAction = PayloadAction<
  SystemActionTypes.GENERATE_SYSTEM_COPIES_TO_DESTINATIONS_REQUEST,
  {
    isCopyingIdentification: boolean;
    isCopyingQuantity: boolean;
    systemToCopyIds: string[];
    destinationAssetIds: string[];
  }
>;

export type GenerateSystemCopiesRequestAction = PayloadAction<
  SystemActionTypes.GENERATE_SYSTEM_COPIES_REQUEST,
  {
    systemId: string;
    systemCopies: System[];
    parentId: string;
  }
>;

export type GenerateSystemCopiesSuccessAction = PayloadAction<SystemActionTypes.GENERATE_SYSTEM_COPIES_SUCCESS, { systemCopies: System[]; parentId: string }>;

export type GenerateSystemCopiesErrorAction = PayloadAction<SystemActionTypes.GENERATE_SYSTEM_COPIES_ERROR, { errorMessage: string }>;

export type SetFilteredSystemsAction = PayloadAction<SystemActionTypes.SET_FILTERED_SYSTEMS, { systemIds: string[] }>;

export type SetRedirectToSystemsPageAction = PayloadAction<SystemActionTypes.SET_REDIRECT_TO_SYSTEMS_PAGE, { value: boolean }>;

export type ResetAction = Action<SystemActionTypes.RESET_SYSTEM>;
