import Calculation from "app/domain/calculation/calculation";
import CalculationLine from "app/domain/calculation/calculationLine";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useEffect, useState } from "react";
import { Dropdown, Input, Table } from "semantic-ui-react";
import { DeleteButton } from "ui/button/actions";

interface CalculatorTableRowsProps extends ComponentProps {
  line: CalculationLine;
  onUpdate: (line: CalculationLine) => void;
  onRemove: (line: CalculationLine) => void;
  calculations: Calculation[];
}

type TOperation = {
  key?: number;
  value?: string;
  text?: string;
};

const operationOptions: TOperation[] = [
  { value: "mul", text: "X", key: 1 },
  { value: "div", text: "/", key: 2 }
];

const CalculatorTableRow: FC<CalculatorTableRowsProps> = ({ line, onUpdate, onRemove, calculations }: CalculatorTableRowsProps) => {
  const [description, setDescription] = React.useState(line.description);
  const [size, setSize] = React.useState(line.size.toString());
  const [quantity, setQuantity] = React.useState(line.quantity.toString());
  const [operation, setOperation] = useState<TOperation>(operationOptions[0]);

  const updateDescription = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setDescription(e.target.value);
  };

  const updateSize = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSize(e.target.value);
  };

  const updateQuantity = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setQuantity(e.target.value);
  };

  const updateLine = (): void => {
    const newLine = new CalculationLine({
      id: line.id,
      description,
      size: Number(size),
      quantity: Number(quantity),
      operation: operation?.value ? operation.value : ""
    });
    onUpdate(newLine);
  };

  const onClickRemove = (): void => {
    onRemove(line);
  };

  const handleOperationChange = (event: any, value: any): void => {
    if (!value) return;
    setOperation(value);
  };

  const getTotal = (): number => {
    switch (operation?.value) {
      case "mul":
        return line.size * line.quantity;
      case "div":
        return line.size / line.quantity;
      default:
        return line.size * line.quantity;
    }
  };

  useEffect(() => {
    const opInit = operationOptions.find((op: TOperation): any => op.value === line.operation);
    if (!opInit) return;
    setOperation(opInit);
  }, [line]);

  return (
    <Table.Row key={line.id}>
      <Table.Cell>
        <Input type="text" value={description} onChange={updateDescription} onBlur={updateLine} />
      </Table.Cell>
      <Table.Cell>
        <Input type="number" value={size} onChange={updateSize} onBlur={updateLine} />
      </Table.Cell>
      <Table.Cell>
        <Dropdown name="operation" style={{ width: 100 }} fluid value={operation && operation.value} onChange={handleOperationChange} selection options={operationOptions} />
      </Table.Cell>
      <Table.Cell>
        <Input type="number" value={quantity} onChange={updateQuantity} onBlur={updateLine} />
      </Table.Cell>
      <Table.Cell> = </Table.Cell>
      <Table.Cell>{getTotal()}</Table.Cell>
      <Table.Cell>
        <DeleteButton label="" onClick={onClickRemove} />
      </Table.Cell>
    </Table.Row>
  );
};

export default CalculatorTableRow;
