import Photo, { PhotoStatus } from "app/domain/photo";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { Badge } from "ui/badge";
import { ImageCard } from "ui/card";

export const PENDING_PHOTO_STATUS_MESSAGE = "En attente";

interface PhotoCardProps extends ComponentProps {
  photo: Photo;
}

const PhotoCard: FC<PhotoCardProps> = ({ photo, className }: PhotoCardProps) => {
  const getPhotoDescription = (): string | JSX.Element => {
    if (photo.status === PhotoStatus.PENDING) {
      return <Badge label={PENDING_PHOTO_STATUS_MESSAGE} data-test-id="pendingBadge" />;
    }
    return photo.name;
  };

  return <ImageCard src={photo.src} description={getPhotoDescription()} className={className} />;
};

export default memo(PhotoCard);
