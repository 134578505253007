class SideMenu {
  constructor({ sites }) {
    this.sites = sites;
  }

  static parse({ sites }) {
    return new SideMenu({
      sites,
    });
  }

  static replaceIn(list, object) {
    const replacedIn = JSON.parse(JSON.stringify(list));
    const index = replacedIn.findIndex((elem) => elem.id === object.id);
    replacedIn[index] = object;
    return replacedIn;
  }
}

export default SideMenu;
