import { SystemMinimalInfo } from "app/domain/system";

export type SystemMinimalInfoDTO = {
  id: string;
  reference: string;
  custom_name_concat: string;
};

class SystemMinimalInfoMapper {
  static fromDTO(systemMinimalInfoDTO: SystemMinimalInfoDTO): SystemMinimalInfo;
  static fromDTO(systemMinimalInfoDTOs: SystemMinimalInfoDTO[]): SystemMinimalInfo[];
  static fromDTO(systemMinimalInfoDTOs: SystemMinimalInfoDTO | SystemMinimalInfoDTO[]): any {
    if (!Array.isArray(systemMinimalInfoDTOs)) {
      return new SystemMinimalInfo({
        id: systemMinimalInfoDTOs.id,
        reference: systemMinimalInfoDTOs.reference,
        customNameConcat: systemMinimalInfoDTOs.custom_name_concat
      });
    }
    return systemMinimalInfoDTOs.map((systemMinimalInfoDTO: SystemMinimalInfoDTO): SystemMinimalInfo => this.fromDTO(systemMinimalInfoDTO));
  }
}

export default SystemMinimalInfoMapper;
