import { EntityCategory } from "app/domain/entityCategory";
import Photo, { PhotosFolder } from "app/domain/photo";
import { Action, PayloadAction } from "app/store/types";

export enum PhotoActionTypes {
  ADD_PHOTOS = "ADD_PHOTOS",
  ADD_PHOTOS_REQUEST = "ADD_PHOTOS_REQUEST",
  ADD_PHOTOS_SUCCESS = "ADD_PHOTOS_SUCCESS",
  ADD_PHOTOS_ERROR = "ADD_PHOTOS_ERROR",
  DELETE_PHOTOS = "DELETE_PHOTOS",
  DELETE_PHOTOS_REQUEST = "DELETE_PHOTOS_REQUEST",
  DELETE_PHOTO_SUCCESS = "DELETE_PHOTO_SUCCESS",
  DELETE_PHOTOS_ERROR = "DELETE_PHOTOS_ERROR",
  GET_PHOTOS = "GET_PHOTOS",
  GET_PHOTOS_REQUEST = "GET_PHOTOS_REQUEST",
  GET_PHOTOS_SUCCESS = "GET_PHOTOS_SUCCESS",
  GET_PHOTOS_ERROR = "GET_PHOTOS_ERROR",
  START_DOWNLOADING_PHOTOS_FOLDER_ACTION = "START_DOWNLOADING_PHOTOS_FOLDER_ACTION",
  GET_PHOTOS_FOLDER_REQUEST = "GET_PHOTOS_FOLDER_REQUEST",
  SET_PHOTOS_FOLDER_ACTION = "SET_PHOTOS_FOLDER_ACTION",
  GET_PHOTOS_FOLDER_ERROR = "GET_PHOTOS_FOLDER_ERROR",
  STOP_DOWNLOADING_PHOTOS_FOLDER_ACTION = "STOP_DOWNLOADING_PHOTOS_FOLDER_ACTION",
  RESET_PHOTO = "RESET_PHOTO"
}

export type AddPhotosAction = PayloadAction<
  PhotoActionTypes.ADD_PHOTOS,
  {
    photoFiles: File[];
    entityId: string;
    entityCategory: EntityCategory;
  }
>;

export type AddPhotosRequestAction = PayloadAction<
  PhotoActionTypes.ADD_PHOTOS_REQUEST,
  {
    photoFiles: File[];
    entityId: string;
    entityCategory: EntityCategory;
  }
>;

export type AddPhotosSuccessAction = PayloadAction<
  PhotoActionTypes.ADD_PHOTOS_SUCCESS,
  {
    photos: Photo[];
    entityId: string;
  }
>;

export type AddPhotosErrorAction = PayloadAction<PhotoActionTypes.ADD_PHOTOS_ERROR, { errorMessage: string; entityId: string }>;

export type DeletePhotosAction = PayloadAction<
  PhotoActionTypes.DELETE_PHOTOS,
  {
    photoIds: string[];
    entityId: string;
  }
>;

export type DeletePhotosRequestAction = PayloadAction<
  PhotoActionTypes.DELETE_PHOTOS_REQUEST,
  {
    photoIds: string[];
    entityId: string;
  }
>;

export type DeletePhotoSuccessAction = PayloadAction<
  PhotoActionTypes.DELETE_PHOTO_SUCCESS,
  {
    photoId: string;
    entityId: string;
  }
>;

export type DeletePhotosErrorAction = PayloadAction<PhotoActionTypes.DELETE_PHOTOS_ERROR, { errorMessage: string; entityId: string }>;

export type GetPhotosAction = PayloadAction<
  PhotoActionTypes.GET_PHOTOS,
  {
    entityId: string;
    entityCategory: EntityCategory;
  }
>;

export type GetPhotosRequestAction = PayloadAction<
  PhotoActionTypes.GET_PHOTOS_REQUEST,
  {
    entityId: string;
    entityCategory: EntityCategory;
  }
>;

export type GetPhotosSuccessAction = PayloadAction<
  PhotoActionTypes.GET_PHOTOS_SUCCESS,
  {
    photos: Photo[];
    entityId: string;
  }
>;

export type GetPhotosErrorAction = PayloadAction<PhotoActionTypes.GET_PHOTOS_ERROR, { errorMessage: string; entityId: string }>;

export type StartDownloadingPhotosFolderAction = PayloadAction<
  PhotoActionTypes.START_DOWNLOADING_PHOTOS_FOLDER_ACTION,
  {
    entityId: string;
    entityCategory: EntityCategory;
  }
>;

export type GetPhotosFolderRequestAction = PayloadAction<
  PhotoActionTypes.GET_PHOTOS_FOLDER_REQUEST,
  {
    entityId: string;
    entityCategory: EntityCategory;
  }
>;

export type SetPhotosFolderAction = PayloadAction<
  PhotoActionTypes.SET_PHOTOS_FOLDER_ACTION,
  {
    photosFolder: PhotosFolder;
    entityId: string;
    entityCategory: EntityCategory;
  }
>;

export type GetPhotosFolderErrorAction = PayloadAction<
  PhotoActionTypes.GET_PHOTOS_FOLDER_ERROR,
  {
    errorMessage: string;
    entityId: string;
  }
>;

export type StopDownloadingPhotosFolderAction = PayloadAction<
  PhotoActionTypes.STOP_DOWNLOADING_PHOTOS_FOLDER_ACTION,
  {
    entityId: string;
  }
>;

export type ResetAction = Action<PhotoActionTypes.RESET_PHOTO>;
