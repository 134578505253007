import Local from "app/domain/local/local";
import System from "app/domain/system/system";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { Form as SemanticForm } from "semantic-ui-react";
import { css } from "styled-components";

import { SystemCopyFields } from "../useSystemCopyTable";

const localsDropdownStyle = css`
  height: fit-content;

  label {
    display: block;
    color: #000000de;
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
  }

  .ui.selection.dropdown {
    min-width: 140px;
    width: 100%;
  }
`;

interface LocalsDropdownProps extends ComponentProps {
  system: System;
  locals: Local[];
  index: number;
  onUpdate: (systemCopyFields: SystemCopyFields, index: number) => void;
}

const LocalsDropdown: FC<LocalsDropdownProps> = ({ system, locals, index, onUpdate, className }: LocalsDropdownProps) => {
  const findLocal = (localId: string): Local | undefined => locals.find((local: Local) => local.id === localId);

  const fromLocalIdsToLocals = (localIds: string[]): Local[] => {
    const systemLocals = [];

    for (let i = 0; i < localIds.length; i += 1) {
      const associatedLocal = findLocal(localIds[i]);

      if (associatedLocal) {
        systemLocals.push(associatedLocal);
      }
    }

    return systemLocals;
  };

  const mapToLocalOptions = (assetLocals: Local[]): any =>
    assetLocals.map((local: Local) => ({
      key: local.id,
      text: local.number,
      value: local.id
    }));

  const fromLocalsToLocalIds = (systemLocals: Local[]): string[] => systemLocals.map((local: Local) => local.id);

  const handleLocalsChange = (event: React.SyntheticEvent<HTMLElement>, data: any): void => {
    event.preventDefault();

    const result = fromLocalIdsToLocals(data.value);
    const systemCopyFields = {
      [data.name]: result
    };

    onUpdate(systemCopyFields, index);
  };

  return (
    <SemanticForm.Dropdown
      label="Local(aux)"
      className={className}
      multiple
      selection
      search
      placeholder="Local(aux)"
      onChange={handleLocalsChange}
      name="locals"
      value={system && system.locals ? fromLocalsToLocalIds(system.locals) : []}
      options={mapToLocalOptions(locals)}
      clearable
      data-test-id="dropdown"
    />
  );
};

export default createStyledComponent(memo(LocalsDropdown), localsDropdownStyle);
