import { Filter } from "app/domain/filter";
import { ComponentProps, createStyledComponent, StyleProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { css } from "styled-components";
import { RowData, TableHeaderColumn } from "ui/table/types";

import ColumnFilterPanel from "./filters/columnFilterPanel";
import SortFilter from "./filters/sortFilter";
import Title from "./title";

const tableHeaderRowCellStyle = css`
  &&&&& {
    position: relative;
    background: #f9fafb;
    font-weight: 700;
    overflow: visible;

    .content {
      display: flex;
      width: ${(props: StyleProps): string => (props.column.getHeaderProps().key === "header_selection" ? "fit-content" : "auto")};
      overflow: hidden;

      .filters {
        display: flex;
        flex: 1;
      }
    }
  }
`;

interface TableHeaderCellProps extends ComponentProps {
  column: TableHeaderColumn;
  rows: RowData[];
  filters?: Filter[];
  onFilter?: (filter: Filter) => void;
}

const TableHeaderRowCell: FC<TableHeaderCellProps> = ({ column, rows, filters, onFilter, className }: TableHeaderCellProps) => (
  <div {...column.getHeaderProps(column.sort ? column.getSortByToggleProps({ title: undefined }) : undefined)} className={`${className} cell`}>
    <div className="content">
      <Title sort={column.sort} filter={column.select} column={column} />
      <div className="filters">
        <SortFilter sort={column.sort} isSortedDesc={column.isSortedDesc} data-test-id="sortIcon" />
        {column.select && onFilter && <ColumnFilterPanel column={column} rows={rows} onFilter={onFilter} filters={filters} />}
      </div>
    </div>
  </div>
);

export default createStyledComponent(TableHeaderRowCell, tableHeaderRowCellStyle);
