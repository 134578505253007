import { Requirement } from "app/domain/requirement";
import { Params, useRouter } from "app/routing/routerProvider";
import { getAssetsMinimalInfoAction } from "app/store/asset/actions";
import { getLocalsAction } from "app/store/local/actions";
import { getUserName } from "app/store/login/selectors";
import { deleteRequirementAction, getRequirementOptionsAction, updateRequirementAction } from "app/store/requirement/actions";
import { getCurrentRequirement, shouldRedirectToRequirements as shouldRedirectToRequirementsSelector } from "app/store/requirement/selectors";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { useLoader } from "app/view/common/loader/loaderProvider";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";
import { Loader } from "ui/loader";

import { RequirementForm } from "./requirementForm";

const modifyRequirementContainerStyle = css`
  position: relative;
  flex: 1;
`;

type ModifyRequirementContainerProps = ComponentProps;

const ModifyRequirementContainer: FC<ModifyRequirementContainerProps> = ({ className }: ModifyRequirementContainerProps) => {
  const [modifyAttempt, setModifyAttempt] = useState(false);
  const { getParam } = useRouter();
  const { isActive } = useLoader();
  const { errorMessage, onDismiss, onUpdate } = useErrorMessage();

  const currentRequirement = useSelector(getCurrentRequirement);
  const currentUser = useSelector(getUserName);

  const shouldRedirectToRequirements = useSelector(shouldRedirectToRequirementsSelector);

  const dispatch = useDispatch();

  const projectId = getParam(Params.PROJECT);
  const siteId = getParam(Params.SITE);
  const assetId = getParam(Params.ASSET);
  const requirementId = getParam(Params.REQUIREMENT);

  useEffect(() => {
    dispatch(getRequirementOptionsAction());
    dispatch(getAssetsMinimalInfoAction(siteId));
    dispatch(getLocalsAction(assetId));

    return (): void => {
      onDismiss();
    };
  }, [dispatch, onDismiss, siteId, assetId]);

  const saveRequirement = (requirement: Requirement): void => {
    const updatedRequirement = {
      ...requirement,
      updated_by: currentUser
    };

    dispatch(updateRequirementAction(updatedRequirement));
    setModifyAttempt(true);
  };

  const deleteRequirement = (): void => {
    dispatch(deleteRequirementAction(requirementId));
  };

  const verifyModifyAttempt = (): void => {
    if (errorMessage === "" && modifyAttempt) {
      setModifyAttempt(false);
    }
  };

  return (
    <div className={className}>
      {verifyModifyAttempt()}
      <Loader isActive={isActive} />
      <RequirementForm
        onConfirm={saveRequirement}
        projectId={projectId}
        siteId={siteId}
        assetId={assetId}
        requirementId={requirementId}
        isElementInCreationMode={false}
        onReset={onDismiss}
        onUpdate={onUpdate}
        errorMessage={errorMessage}
        shouldRedirectToRequirementsPage={shouldRedirectToRequirements}
        onDelete={deleteRequirement}
        requirement={currentRequirement}
      />
    </div>
  );
};

export default createStyledComponent(ModifyRequirementContainer, modifyRequirementContainerStyle);
