export type SystemCopiesDTO = {
  is_copying_identification: boolean;
  is_copying_quantity: boolean;
  system_to_copy_ids: string[];
  destination_asset_ids: string[];
};

class SystemCopiesMapper {
  static toDTO(isCopyingIdentification: boolean, isCopyingQuantity: boolean, systemToCopyIds: string[], destinationAssetIds: string[]): SystemCopiesDTO {
    return {
      is_copying_identification: isCopyingIdentification,
      is_copying_quantity: isCopyingQuantity,
      system_to_copy_ids: systemToCopyIds,
      destination_asset_ids: destinationAssetIds
    };
  }
}

export default SystemCopiesMapper;
