/* eslint-disable consistent-return */

import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect, useState } from "react";
import { Dimmer, Loader as SemanticLoader } from "semantic-ui-react";
import { css } from "styled-components";

const loaderStyle = css`
  && {
    position: sticky;
  }
`;

interface LoaderProps extends ComponentProps {
  isActive: boolean;
  height: number;
}

const Loader: FC<LoaderProps> = ({ isActive, height, className }: LoaderProps) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      setActive(true);
    } else {
      const timeoutId = setTimeout(() => {
        setActive(false);
      }, 500);

      return (): void => {
        clearTimeout(timeoutId);
      };
    }
  }, [isActive]);

  return (
    <Dimmer
      inverted
      active={active}
      className={className}
      data-test-id="loaderDimmer"
      style={{
        height: `${height}px`,
        zIndex: 9999
      }}
    >
      <SemanticLoader inverted inline="centered" size="large" />
    </Dimmer>
  );
};

export default createStyledComponent(memo(Loader), loaderStyle);
