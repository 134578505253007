import { RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Site } from "app/domain/site";
import { useRouter } from "app/routing/routerProvider";
import { getSitesAction } from "app/store/site/actions";
import { getSites } from "app/store/site/selectors";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "styled-components";

const siteTableStyle = css`
  padding: 1em;
`;

interface SiteTableProps extends ComponentProps {
  projectId: string;
}

const SiteTable: FC<SiteTableProps> = ({ projectId }: SiteTableProps) => {
  const dispatch = useDispatch();
  const gridRef = useRef<any>(null); // For accessing Grid's API
  const sites = useSelector(getSites);
  const { location, goTo } = useRouter();

  const columnDefs = [
    {
      headerName: "Site",
      headerTooltip: "Site",
      field: "siteName",
      filter: true,
      sortable: true,
      resizable: false,
      flex: 1,
      minWidth: 100
    }
  ];

  const rowData = sites.map((site) => {
    return { siteName: site.name, ...site };
  });

  const handleRowClicked = (rowClickedEvent: RowClickedEvent): void => {
    goTo(`${location}/sites/${(rowClickedEvent.data as Site).id}`);
  };

  useEffect(() => {
    dispatch(getSitesAction(projectId));
  }, [dispatch, projectId]);

  return (
    <div className="ag-theme-alpine" style={{ height: "100%" }}>
      <AgGridReact
        onRowClicked={handleRowClicked}
        rowData={rowData}
        columnDefs={columnDefs}
        animateRows
        pagination
        suppressRowClickSelection
        suppressDragLeaveHidesColumns
        ref={gridRef}
      />
    </div>
  );
};

export default createStyledComponent(memo(SiteTable), siteTableStyle);
