import { fork } from "redux-saga/effects";

import assetSaga from "./asset/sagas";
import auditProgressSaga from "./auditProgress/sagas";
import commentSaga from "./comment/commentSagas";
import connectionSaga from "./connection/sagas";
import costItemSaga from "./costItem/sagas";
import localSaga from "./local/sagas";
import loginSaga from "./login/sagas";
import photoSaga from "./photo/sagas";
import projectSaga from "./project/sagas";
import reportSagas from "./report/reportSagas";
import requirementSaga from "./requirement/sagas";
import searchSaga from "./search/sagas";
import siteSaga from "./site/sagas";
import systemSaga from "./system/sagas";
import systemModelSaga from "./system/systemModel/sagas";

export default function* sagas(): Generator {
  yield fork(photoSaga);
  yield fork(siteSaga);
  yield fork(systemSaga);
  yield fork(assetSaga);
  yield fork(connectionSaga);
  yield fork(searchSaga);
  yield fork(auditProgressSaga);
  yield fork(systemModelSaga);
  yield fork(requirementSaga);
  yield fork(costItemSaga);
  yield fork(loginSaga);
  yield fork(localSaga);
  yield fork(projectSaga);
  yield fork(commentSaga);
  yield fork(reportSagas);
}
