import { Asset } from "app/domain/asset";
import { useCallback, useEffect, useMemo, useState } from "react";

export type AssetFields = {
  [key: string]: any;
};

type AssetHook = {
  currentAsset: any;
  onUpdate: (fields: AssetFields) => void;
};

const useAsset = (initialAsset: Asset | undefined): AssetHook => {
  const [currentAsset, setCurrentAsset] = useState({
    ...initialAsset
  });

  const onUpdate = useCallback((fields: AssetFields) => {
    setCurrentAsset((prevState) => ({
      ...prevState,
      ...fields
    }));
  }, []);

  useEffect(() => {
    if (initialAsset) {
      onUpdate(initialAsset);
    }
  }, [initialAsset, onUpdate]);

  return useMemo(
    () => ({
      currentAsset,
      onUpdate
    }),
    [currentAsset, onUpdate]
  );
};

export default useAsset;
