import SearchItem from "app/domain/search/searchItem";
import { action } from "typesafe-actions";

import { GetSearchResultsAction, GetSearchResultsErrorAction, GetSearchResultsRequestAction, GetSearchResultsSuccessAction, ResetAction, SearchActionTypes } from "./types";

export const getSearchResultsRequestAction = (searchItem: SearchItem): GetSearchResultsRequestAction =>
  action(SearchActionTypes.GET_SEARCH_RESULTS_REQUEST, {
    searchItem
  });

export const getSearchResultsAction = (searchItem: SearchItem): GetSearchResultsAction =>
  action(SearchActionTypes.GET_SEARCH_RESULTS, {
    searchItem
  });

export const getSearchResultsSuccessAction = (searchResults: SearchItem[]): GetSearchResultsSuccessAction =>
  action(SearchActionTypes.GET_SEARCH_RESULTS_SUCCESS, {
    searchResults
  });

export const getSearchResultsErrorAction = (error: string): GetSearchResultsErrorAction => action(SearchActionTypes.GET_SEARCH_RESULTS_ERROR, error);

export const resetAction = (): ResetAction => action(SearchActionTypes.RESET_SEARCH);
