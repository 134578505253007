import { Project } from "app/domain/project";

export type ProjectDTO = {
  id: string;
  description: string;
};

class ProjectMapper {
  static fromDTO(projectDTOs: ProjectDTO[]): Project[] {
    return projectDTOs.map((projectDTO: ProjectDTO): Project => {
      return new Project({
        id: projectDTO.id,
        description: projectDTO.description
      });
    });
  }
}

export default ProjectMapper;
