import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useCallback } from "react";
import { IFileWithMeta, IPreviewProps } from "react-dropzone-uploader";
import { css } from "styled-components";
import { IconButton } from "ui/button";
import { Icon, IconType } from "ui/icon";

export const imagePreviewStyle = css`
  padding: 5px;
  width: 100%;
  min-height: fit-content;

  .preview {
    width: 140px;
    max-width: 140px;
    height: 100px;
    max-height: 100px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0;
  }

  .previewStatusContainer {
    margin: 20px;

    .dzu-previewButton {
      margin: auto;
    }
  }
`;

interface ImagePreviewProps extends ComponentProps, IPreviewProps {
  fileWithMeta: IFileWithMeta;
}

const ImagePreview: FC<ImagePreviewProps> = ({ fileWithMeta, className }: ImagePreviewProps) => {
  const handleClick = useCallback(() => {
    fileWithMeta.remove();
  }, [fileWithMeta]);

  return (
    <div className={className}>
      <div
        className="preview"
        data-test-id="imagePreview"
        style={{
          backgroundImage: `url(${fileWithMeta.meta.previewUrl})`
        }}
      />
      <div className="previewStatusContainer">
        <IconButton onClick={handleClick} data-test-id="removeImageButton">
          <Icon type={IconType.CANCEL} />
        </IconButton>
      </div>
    </div>
  );
};

export default createStyledComponent(ImagePreview, imagePreviewStyle);
