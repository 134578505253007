import { RequirementItemOption } from "app/domain/requirement/options";

export type RequirementItemOptionDTO = {
  parent_level3: string;
  parent_level4: string;
  text: string;
  value: string;
  id?: number;
};

class RequirementItemOptionMapper {
  static fromDTO(requirementItemOptionDTO: RequirementItemOptionDTO): RequirementItemOption;
  static fromDTO(requirementItemOptionDTOs: RequirementItemOptionDTO[]): RequirementItemOption[];
  static fromDTO(requirementItemOptionDTOs: RequirementItemOptionDTO | RequirementItemOptionDTO[]): any {
    if (!Array.isArray(requirementItemOptionDTOs)) {
      return new RequirementItemOption({
        parentLevel3: requirementItemOptionDTOs.parent_level3,
        parentLevel4: requirementItemOptionDTOs.parent_level4,
        text: requirementItemOptionDTOs.text,
        value: requirementItemOptionDTOs.value,
        id: requirementItemOptionDTOs.id
      });
    }
    return requirementItemOptionDTOs.map((requirementItemOptionDTO: RequirementItemOptionDTO): RequirementItemOption => this.fromDTO(requirementItemOptionDTO));
  }

  static toDTO(requirementItemOption: RequirementItemOption): RequirementItemOptionDTO {
    return {
      parent_level3: requirementItemOption.parentLevel3,
      parent_level4: requirementItemOption.parentLevel4,
      text: requirementItemOption.text,
      value: requirementItemOption.value,
      id: requirementItemOption.id
    };
  }
}

export default RequirementItemOptionMapper;
