/* eslint-disable @typescript-eslint/no-namespace, import/export */

import { Dictionaries, DictionaryCode } from "app/locales";

export enum RequirementType {
  EXCLUDED = "excluded",
  ACTIVE = "active",
  DELETED = "deleted",
  DONE = "done",
  REPLACED_BY_SYSTEM = "replacedBySystem",
  LIBRARY = "library"
}

export namespace RequirementType {
  export const values = [
    RequirementType.EXCLUDED,
    RequirementType.ACTIVE,
    RequirementType.DELETED,
    RequirementType.DONE,
    RequirementType.REPLACED_BY_SYSTEM,
    RequirementType.LIBRARY
  ] as RequirementType[];

  export const format = (dictionaryCode: DictionaryCode, requirementType: RequirementType): string => {
    return Dictionaries[dictionaryCode].requirementType[requirementType];
  };

  export const toString = (requirementType: RequirementType): string => {
    if (requirementType === RequirementType.EXCLUDED) {
      return "excluded";
    }
    if (requirementType === RequirementType.ACTIVE) {
      return "active";
    }
    if (requirementType === RequirementType.DELETED) {
      return "deleted";
    }
    if (requirementType === RequirementType.DONE) {
      return "done";
    }
    if (requirementType === RequirementType.REPLACED_BY_SYSTEM) {
      return "replaced_by_component";
    }
    if (requirementType === RequirementType.LIBRARY) {
      return "library";
    }
    return "";
  };

  export const fromString = (requirementType: string): RequirementType => {
    if (requirementType === "excluded") {
      return RequirementType.EXCLUDED;
    }
    if (requirementType === "active") {
      return RequirementType.ACTIVE;
    }
    if (requirementType === "deleted") {
      return RequirementType.DELETED;
    }
    if (requirementType === "done") {
      return RequirementType.DONE;
    }
    if (requirementType === "replaced_by_component") {
      return RequirementType.REPLACED_BY_SYSTEM;
    }
    if (requirementType === "library") {
      return RequirementType.LIBRARY;
    }

    throw new RangeError(
      `Illegal argument passed to fromString(): ${requirementType} does not 
      correspond to any instance of the enum RequirementType`
    );
  };
}
