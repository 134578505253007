import { Dictionary } from "../types";

const Attributes: Dictionary<string> = {
  action_description: "action description",
  action_name: "action name",
  action_name_suffix: "action name suffix",
  action_type: "action type",
  activity: "activity/action",
  age_range: "age category",
  asset_id: "parent asset",
  audit_date: "audit date",
  auditor: "auditor",
  auditor_building_envelope: "building envelope auditor",
  auditor_electrical: "electricity auditor",
  auditor_interior: "interior auditor",
  auditor_mechanical: "mechanical auditor",
  category: "category",
  condition_grading: "condition grading (before repairs)",
  condition_grading_after_repair: "condition grading (after repairs)",
  construction_year: "construction year",
  cost_lines: "cost lines",
  custom_name: "modified name",
  customer_reference: "customer reference",
  description: "description",
  estimated_lifetime: "residual life (estimated)",
  identification: "identification",
  installation_year: "installation year",
  lifetime: "residual life (calculated)",
  localisation: "localisation",
  name: "name",
  number_of_photos: "number of photos",
  priority: "priority",
  quantity: "quantity",
  reference: "reference",
  renewal_percentage: "renewal percentage",
  requirement_category: "type",
  requirement_items: "concerned elements",
  requirement_locals: "local(s)",
  requirement_problematics: "problematics",
  systems_locals: "local(s)",
  total_cost: "total cost",
  uniformat1: "uniformat level 1",
  uniformat2: "uniformat level 2",
  uniformat3: "uniformat level 3",
  uniformat4: "uniformat level 4",
  unit_cost: "unit cost",
  unit_of_measure: "unit of measure",
  unit_of_measure_factor: "unit of measure adjustment",
  useful_life: "useful life",
  year_planned: "planned year"
};

export default Attributes;
