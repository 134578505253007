import { Site } from "app/domain/site";

import api, { GetRequest, NEW_API_VERSION } from "../common/api";
import SiteMapper, { SiteDTO } from "./siteMapper";

const getRequestParams = (projectId?: string): GetRequest => {
  const pathParam = { path: `${NEW_API_VERSION}/sites` };
  if (projectId) {
    return {
      ...pathParam,
      queryParams: { project_id: projectId }
    };
  }
  return pathParam;
};

const extractFilename = (contentDisposition: string): string => {
  let filename = "";

  if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  return filename;
};

export type Report = {
  url: string;
  filename: string;
};

class SiteService {
  static getSite = async (siteId: string): Promise<Site> => {
    const siteDTO = (
      await api.get({
        path: `${NEW_API_VERSION}/sites/${siteId}`
      })
    ).data as SiteDTO;

    return SiteMapper.fromDTO(siteDTO);
  };

  static getSites = async (projectId?: string): Promise<Site[]> => {
    const siteDTOs = (await api.get(getRequestParams(projectId))).data as SiteDTO[];
    return SiteMapper.fromDTO(siteDTOs);
  };

  static updateSite = async (site: Site): Promise<Site> => {
    const siteDTO = SiteMapper.toDTO(site);
    const updatedSiteDTO = (
      await api.put({
        id: siteDTO.id,
        path: `${NEW_API_VERSION}/sites`,
        body: siteDTO
      })
    ).data as SiteDTO;

    return SiteMapper.fromDTO(updatedSiteDTO);
  };

  static generateReport = async (siteId: string): Promise<Report> => {
    const report = await api.get({
      path: `${NEW_API_VERSION}/sites/${siteId}/report`,
      responseType: "blob"
    });

    const filename = extractFilename(report.headers["content-disposition"]);
    const blob = new Blob([report.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    return { url: window.URL.createObjectURL(blob), filename };
  };
}

export default SiteService;
