import { Asset } from "app/domain/asset";
import { formatNumber, moneyFormatter } from "app/utils/formatter";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Form as SemanticForm } from "semantic-ui-react";

interface FinancialInformationProps extends ComponentProps {
  asset: Asset | undefined;
}

const FinancialInformation: FC<FinancialInformationProps> = ({ asset }: FinancialInformationProps) => (
  <SemanticForm.Group unstackable widths={3}>
    <SemanticForm.Input
      transparent
      label="Valeur de remplacement"
      placecholder="Valeur de remplacement"
      name="replacement_value"
      value={asset && asset.replacement_value ? moneyFormatter.format(asset.replacement_value) : ""}
    />
    <SemanticForm.Input disabled label="IVP" placeholder="IVP" name="facility_condition_index" value={asset && asset.ivp ? formatNumber(asset.ivp, 3) : ""} />
    <SemanticForm.Input
      disabled
      label="Cote d'état"
      placeholder="Cote d'état calculée"
      name="calculatedConditionGrading"
      value={asset && asset.calculatedConditionGrading ? asset.calculatedConditionGrading : ""}
    />
  </SemanticForm.Group>
);

export default FinancialInformation;
