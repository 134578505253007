import Calculation from "app/domain/calculation/calculation";
import CalculationLine from "app/domain/calculation/calculationLine";
import CalculatorTableRow from "app/view/system/form/systemForm/formSections/calculator/calculatorTableRow";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { Container, Table } from "semantic-ui-react";
import { css } from "styled-components";
import { Button } from "ui/button";

const calculatorTableStyle = css`
  margin: 10px 0 0 0;
  height: 280px;
  position: relative;
  overflow-y: scroll;

  .loaderContainer {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
  }
`;

interface CalculatorTableProps extends ComponentProps {
  lines: CalculationLine[];
  setLines: (lines: CalculationLine[]) => void;
  calculations: Calculation[];
}

const CalculatorTable: FC<CalculatorTableProps> = ({ lines, setLines, calculations }: CalculatorTableProps) => {
  const getNextAvailableId = (): number => {
    return Math.max(...lines.map((line: CalculationLine) => line.id)) + 1;
  };

  const addCalculatorLine = (): void => {
    const calculatorLine = new CalculationLine({
      id: lines.length > 0 ? getNextAvailableId() : 1,
      description: "",
      size: 1,
      quantity: 1,
      operation: "mul"
    });
    setLines(lines.concat(calculatorLine));
  };

  const removeLine = (line: CalculationLine): void => {
    setLines(lines.filter((l: CalculationLine) => l !== line));
  };

  const updateLine = (line: CalculationLine): void => {
    setLines(
      lines.map((l: CalculationLine) => {
        return l.id === line.id ? line : l;
      })
    );
  };

  return (
    <Container>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Taille</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>Quantité</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>Total</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lines.map((line: CalculationLine) => (
            <CalculatorTableRow key={line.id} line={line} onUpdate={updateLine} onRemove={removeLine} calculations={calculations} />
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
              <div className="buttons">
                <Button onClick={addCalculatorLine} title="Ajouter" />
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  );
};

export default createStyledComponent(memo(CalculatorTable), calculatorTableStyle);
