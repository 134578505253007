import { Action, PayloadAction } from "../types";

export enum ReportActionTypes {
  GENERATE_TEMPLATE_REPORT = "GENERATE_TEMPLATE_REPORT",
  GENERATE_TEMPLATE_REPORT_REQUEST = "GENERATE_TEMPLATE_REPORT_REQUEST",
  GENERATE_TEMPLATE_REPORT_SUCCESS = "GENERATE_TEMPLATE_REPORT_SUCCESS",
  GENERATE_TEMPLATE_REPORT_ERROR = "GENERATE_TEMPLATE_REPORT_ERROR",

  IMPORT_DATA = "IMPORT_DATA",
  IMPORT_DATA_REQUEST = "IMPORT_DATA_REQUEST",
  IMPORT_DATA_SUCCESS = "IMPORT_DATA_SUCCESS",
  IMPORT_DATA_ERROR = "IMPORT_DATA_ERROR"
}

export type GenerateTemplateReportAction = Action<ReportActionTypes.GENERATE_TEMPLATE_REPORT>;

export type GenerateTemplateReportRequestAction = Action<ReportActionTypes.GENERATE_TEMPLATE_REPORT_REQUEST>;

export type GenerateTemplateReportSuccessAction = Action<ReportActionTypes.GENERATE_TEMPLATE_REPORT_SUCCESS>;

export type GenerateTemplateReportErrorAction = PayloadAction<ReportActionTypes.GENERATE_TEMPLATE_REPORT_ERROR, { errorMessage: string }>;

export type ImportDataAction = PayloadAction<ReportActionTypes.IMPORT_DATA, { report: File }>;

export type ImportDataRequestAction = PayloadAction<ReportActionTypes.IMPORT_DATA_REQUEST, { report: File }>;

export type ImportDataSuccessAction = Action<ReportActionTypes.IMPORT_DATA_SUCCESS>;

export type ImportDataErrorAction = PayloadAction<ReportActionTypes.IMPORT_DATA_ERROR, { errorMessage: string }>;
