/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";
import { Requirement } from "app/domain/requirement";
import { RequirementProblematicOption } from "app/domain/requirement/options";

import {
  addRequirementAction,
  addRequirementErrorAction,
  addRequirementOptionAction,
  addRequirementOptionErrorAction,
  addRequirementOptionRequestAction,
  addRequirementOptionSuccessAction,
  addRequirementRequestAction,
  addRequirementSuccessAction,
  deleteRequirementAction,
  deleteRequirementErrorAction,
  deleteRequirementRequestAction,
  deleteRequirementSuccessAction,
  generateRequirementCopiesAction,
  generateRequirementCopiesErrorAction,
  generateRequirementCopiesRequestAction,
  generateRequirementCopiesSuccessAction,
  getRequirementAction,
  getRequirementErrorAction,
  getRequirementOptionsAction,
  getRequirementOptionsErrorAction,
  getRequirementOptionsRequestAction,
  getRequirementOptionsSuccessAction,
  getRequirementRequestAction,
  getRequirementsAction,
  getRequirementsErrorAction,
  getRequirementsRequestAction,
  getRequirementsSuccessAction,
  getRequirementSuccessAction,
  resetAction,
  setFilteredRequirementsAction,
  updateRequirementAction,
  updateRequirementErrorAction,
  updateRequirementRequestAction,
  updateRequirementSuccessAction
} from "./actions";
import { initialRequirementState, RequirementState } from "./state";
import {
  AddRequirementErrorAction,
  AddRequirementOptionErrorAction,
  AddRequirementOptionSuccessAction,
  AddRequirementSuccessAction,
  DeleteRequirementErrorAction,
  DeleteRequirementSuccessAction,
  GenerateRequirementCopiesErrorAction,
  GenerateRequirementCopiesSuccessAction,
  GetRequirementErrorAction,
  GetRequirementOptionsErrorAction,
  GetRequirementOptionsSuccessAction,
  GetRequirementsErrorAction,
  GetRequirementsSuccessAction,
  GetRequirementSuccessAction,
  SetFilteredRequirementsAction,
  UpdateRequirementErrorAction,
  UpdateRequirementSuccessAction
} from "./types";

const replaceIn = (requirements: Requirement[], newRequirement: Requirement): Requirement[] => {
  const indexOfRequirementToReplace = requirements.findIndex((requirement: Requirement) => requirement.id === newRequirement.id);
  requirements[indexOfRequirementToReplace] = newRequirement;
  return requirements;
};

const getRequirement = (state: RequirementState): void => {
  state.errorMessage = "";
};

const getRequirementRequest = (state: RequirementState): void => {
  state.isLoading = true;
};

const getRequirementSuccess = (state: RequirementState, action: GetRequirementSuccessAction): void => {
  state.isLoading = false;
  state.currentRequirement = action.payload.requirement;
};

const getRequirementError = (state: RequirementState, action: GetRequirementErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const getRequirements = (state: RequirementState): void => {
  state.errorMessage = "";
};

const getRequirementsRequest = (state: RequirementState): void => {
  state.isLoading = true;
};

const getRequirementsSuccess = (state: RequirementState, action: GetRequirementsSuccessAction): void => {
  state.isLoading = false;
  state.requirements = action.payload.requirements;
  state.shouldRedirectToRequirementsPage = false;
};

const getRequirementsError = (state: RequirementState, action: GetRequirementsErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const getRequirementOptions = (state: RequirementState): void => {
  state.errorMessage = "";
};

const getRequirementOptionsRequest = (state: RequirementState): void => {
  state.isFetchingOptions = true;
};

const getRequirementOptionsSuccess = (state: RequirementState, action: GetRequirementOptionsSuccessAction): void => {
  state.isFetchingOptions = false;
  state.options = action.payload.options;
};

const getRequirementOptionsError = (state: RequirementState, action: GetRequirementOptionsErrorAction): void => {
  state.isFetchingOptions = false;
  state.errorMessage = action.payload.errorMessage;
};

const addRequirement = (state: RequirementState): void => {
  state.errorMessage = "";
};

const addRequirementRequest = (state: RequirementState): void => {
  state.isLoading = true;
};

const addRequirementSuccess = (state: RequirementState, action: AddRequirementSuccessAction): void => {
  state.isLoading = false;
  state.requirements = [...state.requirements, action.payload.requirement];
  state.shouldRedirectToRequirementsPage = true;
};

const addRequirementError = (state: RequirementState, action: AddRequirementErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const updateRequirement = (state: RequirementState): void => {
  state.errorMessage = "";
};

const updateRequirementRequest = (state: RequirementState): void => {
  state.isLoading = true;
};

const updateRequirementSuccess = (state: RequirementState, action: UpdateRequirementSuccessAction): void => {
  state.isLoading = false;
  state.currentRequirement = action.payload.requirement;
  state.requirements = replaceIn(state.requirements, action.payload.requirement);
};

const updateRequirementError = (state: RequirementState, action: UpdateRequirementErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const deleteRequirement = (state: RequirementState): void => {
  state.errorMessage = "";
};

const deleteRequirementRequest = (state: RequirementState): void => {
  state.isLoading = true;
};

const deleteRequirementSuccess = (state: RequirementState, action: DeleteRequirementSuccessAction): void => {
  state.isLoading = false;
  state.currentRequirement = undefined;
  state.shouldRedirectToRequirementsPage = true;

  state.requirements = state.requirements.filter((requirement: Requirement) => requirement.id !== action.payload.requirementId);
};

const deleteRequirementError = (state: RequirementState, action: DeleteRequirementErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const generateRequirementCopies = (state: RequirementState): void => {
  state.errorMessage = "";
};

const generateRequirementCopiesRequest = (state: RequirementState): void => {
  state.isLoading = true;
};

const generateRequirementCopiesSuccess = (state: RequirementState, action: GenerateRequirementCopiesSuccessAction): void => {
  const requirementsToAdd = action.payload.requirementCopies.filter((requirementCopy: Requirement) => requirementCopy.assetId === action.payload.parentId);
  state.isLoading = false;
  state.requirements = [...state.requirements, ...requirementsToAdd];
};

const generateRequirementCopiesError = (state: RequirementState, action: GenerateRequirementCopiesErrorAction): void => {
  state.isLoading = false;
  state.errorMessage = action.payload.errorMessage;
};

const addRequirementOption = (state: RequirementState): void => {
  state.errorMessage = "";
};

const addRequirementOptionRequest = (state: RequirementState): void => {
  state.isLoading = true;
};

const addRequirementOptionSuccess = (state: RequirementState, action: AddRequirementOptionSuccessAction): void => {
  state.isLoading = false;
  if (action.payload.option instanceof RequirementProblematicOption) {
    state.options.problematicOptions = [...state.options.problematicOptions, action.payload.option];
  } else {
    state.options.requirementItemOptions = [...state.options.requirementItemOptions, action.payload.option];
  }
};

const addRequirementOptionError = (state: RequirementState, action: AddRequirementOptionErrorAction): void => {
  state.errorMessage = action.payload.errorMessage;
};

const setFilteredRequirements = (state: RequirementState, action: SetFilteredRequirementsAction): void => {
  const requirementIds = [...action.payload.requirementIds];
  const filteredRequirements = [];

  for (let index = 0; index < requirementIds.length; index += 1) {
    const system = state.requirements.filter((requirement: Requirement) => requirement.id === requirementIds[index])[0];

    filteredRequirements.push(system);
  }

  state.filteredRequirements = [...filteredRequirements];
};

const reset = (state: RequirementState): void => {
  state.errorMessage = "";
};

const requirementReducer = createReducer(initialRequirementState, {
  [getRequirementAction.type]: (state) => getRequirement(state),
  [getRequirementRequestAction.type]: (state) => getRequirementRequest(state),
  [getRequirementSuccessAction.type]: (state, action) => getRequirementSuccess(state, action),
  [getRequirementErrorAction.type]: (state, action) => getRequirementError(state, action),
  [getRequirementsAction.type]: (state) => getRequirements(state),
  [getRequirementsRequestAction.type]: (state) => getRequirementsRequest(state),
  [getRequirementsSuccessAction.type]: (state, action) => getRequirementsSuccess(state, action),
  [getRequirementsErrorAction.type]: (state, action) => getRequirementsError(state, action),
  [getRequirementOptionsAction.type]: (state) => getRequirementOptions(state),
  [getRequirementOptionsRequestAction.type]: (state) => getRequirementOptionsRequest(state),
  [getRequirementOptionsSuccessAction.type]: (state, action) => getRequirementOptionsSuccess(state, action),
  [getRequirementOptionsErrorAction.type]: (state, action) => getRequirementOptionsError(state, action),
  [addRequirementAction.type]: (state) => addRequirement(state),
  [addRequirementRequestAction.type]: (state) => addRequirementRequest(state),
  [addRequirementSuccessAction.type]: (state, action) => addRequirementSuccess(state, action),
  [addRequirementErrorAction.type]: (state, action) => addRequirementError(state, action),
  [updateRequirementAction.type]: (state) => updateRequirement(state),
  [updateRequirementRequestAction.type]: (state) => updateRequirementRequest(state),
  [updateRequirementSuccessAction.type]: (state, action) => updateRequirementSuccess(state, action),
  [updateRequirementErrorAction.type]: (state, action) => updateRequirementError(state, action),
  [deleteRequirementAction.type]: (state) => deleteRequirement(state),
  [deleteRequirementRequestAction.type]: (state) => deleteRequirementRequest(state),
  [deleteRequirementSuccessAction.type]: (state, action) => deleteRequirementSuccess(state, action),
  [deleteRequirementErrorAction.type]: (state, action) => deleteRequirementError(state, action),
  [generateRequirementCopiesAction.type]: (state) => generateRequirementCopies(state),
  [generateRequirementCopiesRequestAction.type]: (state) => generateRequirementCopiesRequest(state),
  [generateRequirementCopiesSuccessAction.type]: (state, action) => generateRequirementCopiesSuccess(state, action),
  [generateRequirementCopiesErrorAction.type]: (state, action) => generateRequirementCopiesError(state, action),
  [addRequirementOptionAction.type]: (state) => addRequirementOption(state),
  [addRequirementOptionRequestAction.type]: (state) => addRequirementOptionRequest(state),
  [addRequirementOptionSuccessAction.type]: (state, action) => addRequirementOptionSuccess(state, action),
  [addRequirementOptionErrorAction.type]: (state, action) => addRequirementOptionError(state, action),
  [setFilteredRequirementsAction.type]: (state, action) => setFilteredRequirements(state, action),
  [resetAction.type]: (state) => reset(state)
});

export default requirementReducer;
