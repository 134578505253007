/* eslint-disable react-hooks/exhaustive-deps */
/* stylelint-disable value-keyword-case */

import { Error } from "app/domain/common";
import { CostLine } from "app/domain/costItem";
import { EntityCategory } from "app/domain/entityCategory";
import { Requirement } from "app/domain/requirement";
import { System } from "app/domain/system";
import { useI18n } from "app/locales";
import { useRouter } from "app/routing/routerProvider";
import RequirementFieldValueFetcher from "app/service/comment/field/requirementFieldValueFetcher";
import { CostItemService } from "app/service/costItem";
import { getAssetsMinimalInfo } from "app/store/asset/selectors";
import { getFilteredRequirements } from "app/store/requirement/selectors";
import { State } from "app/store/state";
import CommentsListing from "app/view/comment/listing/commentsListing";
import { useErrorMessage } from "app/view/common/errorMessage/errorMessageProvider";
import { FormOverview, formStyle } from "app/view/common/form";
import usePrevious from "app/view/common/hook/usePrevious";
import { useLoader } from "app/view/common/loader/loaderProvider";
import DeleteModal from "app/view/common/modal/deleteModal";
import { Page } from "app/view/common/page";
import { Toolbar } from "app/view/common/toolbar";
import { INDEX_REQUIREMENTS_TAB, MODAL_TYPE_REQUIREMENT } from "app/view/constant";
import useCostLineTable from "app/view/costItem/costLinesTable/useCostLineTable";
import PhotoListing from "app/view/photo/listing/photoListing";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import { isEqual } from "lodash";
import React, { createRef, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-async";
import { useSelector } from "react-redux";
import { Prompt, Redirect } from "react-router";
import { Button as SemanticButton, Label } from "semantic-ui-react";
import { css } from "styled-components";
import { Button } from "ui/button";
import { SaveButton } from "ui/button/actions";
import { ErrorMessage } from "ui/errorMessage";
import { IconType } from "ui/icon";
import { Tab, Tabs } from "ui/tab";

import CopyRequirementForm from "../copyRequirementForm";
import { AdjustmentFactor, CostLinesInformation, ManagementInformation, MonetaryInformation, RequirementInformation, TimelineInformation } from "./formSections";
import RequirementFormState from "./requirementFormState";
import useRequirement from "./useRequirement";

const INDIRECT_COST_ADJUSTMENT = 145;

const requirementFormStyle = css`
  ${formStyle};
  width: 80%;
  margin-top: 14px;

  .requirementError {
    margin: 1em;
  }
`;

interface RequirementFormProps extends ComponentProps {
  onConfirm: (requirement: Requirement) => void;
  projectId: string;
  siteId: string;
  assetId: string;
  isElementInCreationMode: boolean;
  onReset: () => void;
  onUpdate: (errorMessage: string) => void;
  errorMessage: string;
  shouldRedirectToRequirementsPage: boolean;
  requirementId?: string;
  onDelete?: (data: any) => void;
  requirement?: Requirement;
  auditDate?: string;
}

const RequirementForm: FC<RequirementFormProps> = ({
  requirement,
  onConfirm,
  projectId,
  siteId,
  assetId,
  requirementId,
  isElementInCreationMode,
  onReset,
  onUpdate,
  errorMessage,
  shouldRedirectToRequirementsPage,
  onDelete,
  auditDate,
  className
}: RequirementFormProps) => {
  const { dictionaryCode } = useI18n();
  const { goTo } = useRouter();

  const assets = useSelector(getAssetsMinimalInfo);
  const systems = useSelector((state: State): System[] => state.systems.systems);

  const filteredRequirements = useSelector(getFilteredRequirements);

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [isFormCopyRequirementOpen, setIsFormCopyRequirementOpen] = useState(false);

  const { currentRequirement, onUpdate: onRequirementUpdate } = useRequirement(requirement as Requirement, auditDate);

  const {
    onAdd: onAddCostLine,
    onUpdate: onUpdateCostLine,
    onDelete: onDeleteCostLine,
    onReset: onResetCostLineTable,
    selectedCostLines,
    costLinesTotalCost
  } = useCostLineTable(isElementInCreationMode || !requirement || !requirement.costLines ? [] : (requirement.costLines as CostLine[]));

  const previousRequirementCostLines = usePrevious(selectedCostLines);
  const { onUpdate: onUpdateErrorMessage } = useErrorMessage();
  const { setIsActive } = useLoader();

  const refReqInfo = createRef();
  const refTempInfo = createRef();
  const refMoneyInfo = createRef();
  const refAdjustFact = createRef();
  const refManInfo = createRef();
  const refCostLines = createRef();

  const requirementName = useMemo((): string => {
    if (currentRequirement) {
      const problematics = currentRequirement.problematics ? currentRequirement.problematics : [];
      const joinedProblematics = problematics.join(" - ");

      const items = currentRequirement.items ? currentRequirement.items : [];
      const joinedItems = items.join(" - ");

      return items.length === 0 ? joinedProblematics : `${joinedItems} - ${joinedProblematics}`;
    }
    return "";
  }, [currentRequirement]);

  const requirementActionNameSuffix = useMemo((): string => {
    return currentRequirement?.actionNameSuffix ? currentRequirement.actionNameSuffix : "";
  }, [currentRequirement]);

  const requirementActionName = useMemo((): string => {
    const SPLIT_CHAR = " - ";
    if (currentRequirement) {
      const activity = currentRequirement.activity ? currentRequirement.activity : "";
      const items = currentRequirement.items ? currentRequirement.items : [];
      const joinedItems = items.join(SPLIT_CHAR);
      const joinedString = `${activity} - ${joinedItems}`;
      const string = activity.length === 0 ? joinedItems : joinedString;
      return currentRequirement?.actionNameSuffix ? `${string} - ${currentRequirement?.actionNameSuffix}` : string;
    }
    return "";
  }, [currentRequirement]);

  const requirementAdjustmentFactor = useMemo((): number => {
    if (currentRequirement) {
      const complexity = currentRequirement.complexity ? currentRequirement.complexity : 0;
      const height = currentRequirement.height ? currentRequirement.height : 0;
      const removal = currentRequirement.removal ? currentRequirement.removal : 0;
      const patrimony_factor = currentRequirement.patrimony_factor ? currentRequirement.patrimony_factor : 0;

      return complexity + height + removal + patrimony_factor + INDIRECT_COST_ADJUSTMENT;
    }
    return 0;
  }, [currentRequirement]);

  useEffect((): void => {
    onReset();
    if (!isElementInCreationMode) {
      if (currentRequirement && currentRequirement.errors && currentRequirement.errors.length > 0) {
        let formattedError = "";

        currentRequirement.errors.forEach((error: Error) => {
          if (typeof error.format === "function") {
            formattedError = `${formattedError}${error.format(dictionaryCode)}\n`;
          }
        });

        onUpdate(formattedError);
      }
    }

    onRequirementUpdate({
      adjustment_factor: requirementAdjustmentFactor,
      name: requirementName,
      actionName: requirementActionName,
      actionNameSuffix: requirementActionNameSuffix,
      totalCost: costLinesTotalCost
    });

    if (!isEqual(previousRequirementCostLines, selectedCostLines)) {
      onResetCostLineTable(selectedCostLines);
      onRequirementUpdate({
        costLines: selectedCostLines
      });
    }
  }, [
    onRequirementUpdate,
    requirementAdjustmentFactor,
    requirementName,
    requirementActionName,
    requirementActionNameSuffix,
    previousRequirementCostLines,
    selectedCostLines,
    costLinesTotalCost,
    onResetCostLineTable,
    isElementInCreationMode,
    onUpdate,
    dictionaryCode,
    currentRequirement.errors,
    onReset
  ]);

  const [commentTabLabel, setCommentTabLabel] = useState(<div>Commentaires</div>);
  useEffect((): void => {
    const commentCount =
      currentRequirement && currentRequirement.commentsCount > 0 ? (
        <Label circular color="red" size="tiny">
          {currentRequirement.commentsCount}
        </Label>
      ) : null;
    setCommentTabLabel(<div>Commentaires {commentCount}</div>);
  }, [currentRequirement, currentRequirement?.commentsCount]);

  const getPercentageDone = (): number => {
    const fieldsToWrite = [
      "uniformat",
      "category",
      "items",
      "problematics",
      "name",
      "cause",
      "activity",
      "actionName",
      "actionNameSuffix",
      "actionDescription",
      "priority",
      "auditDate",
      "auditor",
      "description",
      "yearPlanned",
      "costLines"
    ];

    let fieldsSum = 0;

    if (currentRequirement) {
      fieldsToWrite.forEach((field: string) => {
        if (currentRequirement[field] !== null && currentRequirement[field] !== "" && currentRequirement[field] !== undefined) {
          fieldsSum += 1;
        }
      });
    }

    return (fieldsSum / fieldsToWrite.length) * 100;
  };

  const confirmSave = useCallback(() => {
    setShouldBlockNavigation(false);
    onConfirm(currentRequirement);
  }, [currentRequirement, onConfirm]);

  const handleDelete = (): void => {
    if (onDelete) {
      onDelete(requirementId);
    }
  };

  const showFormCopyRequirement = (): void => {
    setIsFormCopyRequirementOpen(true);
  };

  const hideFormCopyRequirement = (): void => {
    setIsFormCopyRequirementOpen(false);
  };

  const handleNavigationBlock = (): void => {
    setShouldBlockNavigation(true);
  };

  const handleFetchReplacementCostLinesSuccess = (costLines: CostLine[]): void => {
    const newRequirementCostLines = costLines.map(
      (costLine: CostLine) =>
        new CostLine({
          costItemId: costLine.costItemId,
          quantity: 0,
          code: costLine.code,
          unitCost: costLine.unitCost,
          description: costLine.description,
          className: costLine.className,
          unitOfMeasure: costLine.unitOfMeasure
        })
    );
    newRequirementCostLines.forEach((costLine: CostLine) => onAddCostLine(costLine));
    setIsActive(false);
  };

  const handleFetchReplacementCostLinesError = (): void => {
    setIsActive(false);
    onUpdateErrorMessage("Une erreur s'est produite lors de l'ajout des lignes de coût. Veuillez réessayer.");
  };

  const fetchReplacementCostLines = ([uniformat3]: string[]): Promise<CostLine[]> => {
    return CostItemService.getRequirementReplacementCostLines(uniformat3);
  };

  const { run } = useAsync({
    deferFn: fetchReplacementCostLines,
    onResolve: handleFetchReplacementCostLinesSuccess,
    onReject: handleFetchReplacementCostLinesError
  });

  const handleSelectRequirementReplacement = (uniformat3: string): void => {
    setIsActive(true);
    run(uniformat3);
  };

  const redirectToRequirementsPage = (): void => {
    goTo(`/projects/${projectId}/sites/${siteId}/assets/${assetId}`, {
      tabIndex: INDEX_REQUIREMENTS_TAB
    });
  };

  const requirementFieldValueFetcher = new RequirementFieldValueFetcher();
  const fetchRequirementValue = (fieldName: string): string => {
    return requirementFieldValueFetcher.getFieldValue(currentRequirement, fieldName);
  };

  const actions = [
    <SaveButton onClick={confirmSave} label="Enregistrer" key={0} />,
    <Button onClick={redirectToRequirementsPage} title="Fermer" iconType={IconType.CANCEL} key={1} />,
    !isElementInCreationMode && <DeleteModal itemType={MODAL_TYPE_REQUIREMENT} onDelete={handleDelete} key={2} />
  ];

  return shouldRedirectToRequirementsPage ? (
    <Redirect
      to={{
        pathname: `/projects/${projectId}/sites/${siteId}/assets/${assetId}`,
        state: { tabIndex: INDEX_REQUIREMENTS_TAB }
      }}
    />
  ) : (
    <Page
      showBreadcrumb={!isElementInCreationMode}
      title={
        isElementInCreationMode ? (
          <>
            <span>Créer</span> une nouvelle exigence
          </>
        ) : (
          <>
            <span>Exigence:</span> {requirement && requirement.reference ? requirement.reference : ""}
          </>
        )
      }
    >
      <Prompt message="Vous avez des changements non-enregistrés. Quitter quand même ?" when={shouldBlockNavigation} />
      <CopyRequirementForm
        show={isFormCopyRequirementOpen}
        handleClose={hideFormCopyRequirement}
        fields={{ ...requirement }}
        requirementToBeCopiedId={requirementId}
        redirectToRequirementsPage={redirectToRequirementsPage}
        listSiteAssets={assets}
        components={systems}
        assetId={assetId}
      />
      <div className={className}>
        <div className="formOptions">{isElementInCreationMode ? null : <SemanticButton onClick={showFormCopyRequirement} content="Copier cette exigence" />}</div>
        <ErrorMessage message={errorMessage} onDismiss={onReset} className="requirementError" />
        <Tabs>
          <Tab label="Informations" className="informations">
            <Toolbar actions={actions} />
            <RequirementFormState requirement={currentRequirement} onUpdate={onRequirementUpdate} onNavigationBlock={handleNavigationBlock} />
            <RequirementInformation
              requirement={currentRequirement}
              assets={assets}
              onUpdate={onRequirementUpdate}
              isElementInCreationMode={isElementInCreationMode}
              onNavigationBlock={handleNavigationBlock}
              projectId={projectId}
              assetId={assetId}
              onSelectUniformat3={handleSelectRequirementReplacement}
              onUpdateErrorMessage={onUpdateErrorMessage}
              ref={refReqInfo}
            />
            <TimelineInformation requirement={currentRequirement} onUpdate={onRequirementUpdate} onNavigationBlock={handleNavigationBlock} ref={refTempInfo} />
            <AdjustmentFactor requirement={currentRequirement} onUpdate={onRequirementUpdate} onNavigationBlock={handleNavigationBlock} ref={refAdjustFact} />
            <MonetaryInformation requirement={currentRequirement} projectId={projectId} ref={refMoneyInfo} />
            <ManagementInformation requirement={currentRequirement} onUpdate={onRequirementUpdate} onNavigationBlock={handleNavigationBlock} ref={refManInfo} />
            <CostLinesInformation
              onAdd={onAddCostLine}
              onUpdate={onUpdateCostLine}
              onDelete={onDeleteCostLine}
              selectedCostLines={selectedCostLines}
              ref={refCostLines}
              systemId={assetId as string}
            />
          </Tab>
          {!isElementInCreationMode && (
            <Tab label="Photos">
              <PhotoListing entityId={requirementId || ""} entityCategory={EntityCategory.REQUIREMENT} />
            </Tab>
          )}
          {!isElementInCreationMode && !!currentRequirement && (
            <Tab label={commentTabLabel}>
              <CommentsListing parentId={currentRequirement.id} parentType={EntityCategory.REQUIREMENT} fetchFieldValue={fetchRequirementValue} />
            </Tab>
          )}
        </Tabs>
        <FormOverview
          itemId={requirementId}
          items={filteredRequirements}
          percentage={getPercentageDone()}
          formSectionNavigationLinks={[
            { reference: refReqInfo, title: "1. Informations sur l'exigence" },
            { reference: refTempInfo, title: "2. Informations temporelles" },
            { reference: refAdjustFact, title: "3. Facteurs d'ajustement" },
            { reference: refMoneyInfo, title: "4. Informations monétaires" },
            { reference: refManInfo, title: "5. Informations de gestion" },
            {
              reference: refCostLines,
              title: "6. Informations lignes de coût"
            }
          ]}
        />
      </div>
    </Page>
  );
};

export default createStyledComponent(RequirementForm, requirementFormStyle);
