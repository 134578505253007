import enTranslationMessages from "../translations/en.json";
import frTranslationMessages from "../translations/fr.json";
import { DEFAULT_LOCALE } from "./constants";

export const formatTranslationMessages = (locale: string, messages: Messages): Messages => {
  const defaultFormattedMessages: Messages = locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, frTranslationMessages) : {};

  return Object.keys(messages).reduce((formattedMessages: Messages, key: string) => {
    let message: string = messages[key];
    if (!message && locale !== DEFAULT_LOCALE) {
      message = defaultFormattedMessages[key];
    }
    return Object.assign(formattedMessages, { [key]: message });
  }, {});
};

export const translationMessages = {
  fr: formatTranslationMessages("fr", frTranslationMessages),
  en: formatTranslationMessages("en", enTranslationMessages)
};
