/* eslint-disable  @typescript-eslint/explicit-module-boundary-types, no-continue */

import { Row } from "./types";

const toString = (value: any): string[] => {
  const splitAlphaNumericRegex = /([0-9]+)/gm;

  let valueString = "";

  if (typeof value === "number") {
    if (Number.isNaN(value) || value === Infinity || value === -Infinity) {
      valueString = "";
    }
    valueString = String(value);
  }
  if (typeof value === "string") {
    valueString = value;
  }
  return valueString.split(splitAlphaNumericRegex).filter(Boolean);
};

export const sortAlphanumeric = (firstValue: any, secondValue: any): number => {
  const firstValueString = toString(firstValue);
  const secondValueString = toString(secondValue);

  while (firstValueString.length && secondValueString.length) {
    const firstValueStringShifted = firstValueString.shift() as string;
    const secondValueStringShifted = secondValueString.shift() as string;

    const firstValueNumber = parseInt(firstValueStringShifted, 10);
    const secondValueNumber = parseInt(secondValueStringShifted, 10);

    const combo = [firstValueNumber, secondValueNumber].sort();

    // Both are string
    if (Number.isNaN(combo[0])) {
      if (firstValueStringShifted > secondValueStringShifted) {
        return 1;
      }
      if (secondValueStringShifted > firstValueStringShifted) {
        return -1;
      }
      continue;
    }

    // One is a string, one is a number
    if (Number.isNaN(combo[1])) {
      return Number.isNaN(firstValueNumber) ? -1 : 1;
    }

    // Both are numbers
    if (firstValueNumber > secondValueNumber) {
      return 1;
    }
    if (secondValueNumber > firstValueNumber) {
      return -1;
    }
  }

  return firstValueString.length - secondValueString.length;
};

export const sortRows = (firstRow: Row, secondRow: Row, fieldAccessor: string): number => sortAlphanumeric(firstRow.values[fieldAccessor], secondRow.values[fieldAccessor]);

export const selectRows = (rows: Row[], fieldAccessor: string, selectedRowValues: string[] | undefined): Row[] => {
  if (selectedRowValues) {
    return rows.filter((row: Row) => {
      const rowValue = row.values[fieldAccessor];
      return selectedRowValues.includes(String(rowValue));
    });
  }
  return rows;
};
