import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC } from "react";
import { Button } from "ui/button";
import { IconType } from "ui/icon";

export interface AddButtonProps extends ComponentProps {
  label: string;
  onClick: () => void;
}

const AddButton: FC<AddButtonProps> = ({ label, onClick }: AddButtonProps) => <Button title={label} iconType={IconType.PLUS} onClick={onClick} data-test-id="button" />;

export default AddButton;
