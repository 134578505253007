import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, memo } from "react";
import { Segment as SemanticSegment } from "semantic-ui-react";
import { css } from "styled-components";

const checkboxGroupStyle = css`
  && {
    display: inline-flex;
    flex-direction: column;
    margin: 0;
    width: 100%;

    && > * > * {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

interface CheckboxGroupProps extends ComponentProps {
  children: JSX.Element | JSX.Element[];
  title?: string;
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({ children, title, className }: CheckboxGroupProps) => (
  <SemanticSegment color="blue" className={className}>
    {title}
    <div>{children}</div>
  </SemanticSegment>
);

export default createStyledComponent(memo(CheckboxGroup), checkboxGroupStyle);
