/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Cable } from "actioncable";
import { useRouter } from "app/routing/routerProvider";
import { setupTimers, startIdleService } from "app/service/SessionService";
import { logoutUserAction } from "app/store/login/actions";
import { isLoggedIn as isLoggedInSelector } from "app/store/login/selectors";
import NotificationBar from "app/view/common/header/notificationBar";
import { ComponentProps } from "common/style/createStyledComponent";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { colors } from "ui/colors";

import { HEADER_PADDING } from "./constants";
import Logo from "./logo";
import MenuButton from "./menuButton";
import { SearchBar } from "./searchBar";
import UserProfile from "./userProfile";

export const HeaderStyle = styled(AppBar)`
  height: 50px !important;
  background-color: ${colors.SECONDARY_BLACK} !important;
  color: ${colors.PRIMARY_WHITE} !important;

  .logo-container {
    display: flex;
  }

  .logo {
    height: auto;
    width: 200px;
    cursor: pointer;
    margin-right: 5px;

    svg {
      width: auto;
    }
  }

  .options {
    margin-left: auto;
    align-items: center;
    display: flex;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  height: 50px !important;
  display: flex !important;
  background-color: ${colors.SECONDARY_BLACK} !important;
  color: ${colors.PRIMARY_WHITE} !important;
  padding: ${HEADER_PADDING};
`;

export const Grow = styled.div`
  flex-grow: 1;
`;

interface HeaderProps extends ComponentProps {
  onOpen: () => void;
  cable: Cable;
}

const Header: FC<HeaderProps> = ({ onOpen, cable }: HeaderProps) => {
  const [isIdleServiceRunning, setIsIdleServiceRunning] = useState(false);

  const { goTo } = useRouter();
  const isLoggedIn = useSelector(isLoggedInSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && !isIdleServiceRunning) {
      setupTimers();
      startIdleService(() => {
        dispatch(logoutUserAction());
      });

      setIsIdleServiceRunning(true);
    }
  }, [isLoggedIn, isIdleServiceRunning, dispatch]);

  const handleClick = useCallback(() => {
    goTo("/");
  }, [goTo]);

  return isLoggedIn ? (
    <HeaderStyle position="relative">
      <StyledToolbar>
        <div className="logo" onClick={handleClick} data-test-id="logo">
          <Logo />
        </div>
        <MenuButton onClick={onOpen} data-test-id="menuButton" />
        <Grow />
        <NotificationBar cable={cable} />
        <SearchBar data-test-id="searchBar" />
        <UserProfile data-test-id="userProfile" />
      </StyledToolbar>
    </HeaderStyle>
  ) : null;
};

export default Header;
