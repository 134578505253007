import { RefObject, useCallback, useEffect, useState } from "react";

interface ContentOverflowHook {
  isOverflowing: boolean;
}

const useContentOverflow = (reference: RefObject<Element>): ContentOverflowHook => {
  const [isOverflowing, setOverflowStatus] = useState(false);

  const isContentOverflowing = useCallback(() => {
    const element = reference.current as unknown as HTMLElement;

    return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
  }, [reference]);

  useEffect(() => {
    const handleResize = (): void => {
      setOverflowStatus(isContentOverflowing());
    };

    if (reference.current) {
      setOverflowStatus(isContentOverflowing());
    }

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, [reference, isContentOverflowing]);

  return { isOverflowing };
};

export default useContentOverflow;
