/* eslint-disable no-param-reassign */

import { createReducer } from "@reduxjs/toolkit";

import {
  getAllAuditProgressesAction,
  getAllAuditProgressesErrorAction,
  getAllAuditProgressesRequestAction,
  getAllAuditProgressesSuccessAction,
  getAuditProgressAction,
  getAuditProgressErrorAction,
  getAuditProgressRequestAction,
  getAuditProgressSuccessAction,
  resetAction,
  updateAuditProgressAction,
  updateAuditProgressErrorAction,
  updateAuditProgressRequestAction,
  updateAuditProgressSuccessAction
} from "./actions";
import { AuditProgressState, initialAuditProgressState } from "./state";
import {
  GetAllAuditProgressesErrorAction,
  GetAllAuditProgressesSuccessAction,
  GetAuditProgressErrorAction,
  GetAuditProgressSuccessAction,
  UpdateAuditProgressErrorAction,
  UpdateAuditProgressSuccessAction
} from "./types";

const getAuditProgress = (state: AuditProgressState): void => {
  state.errorMessage = "";
};

const getAuditProgressRequest = (state: AuditProgressState): void => {
  state.isFetching = true;
};

const getAuditProgressSuccess = (state: AuditProgressState, action: GetAuditProgressSuccessAction): void => {
  state.isFetching = false;
  state.auditProgress = action.payload.auditProgress;
};

const getAuditProgressError = (state: AuditProgressState, action: GetAuditProgressErrorAction): void => {
  state.isFetching = false;
  state.errorMessage = action.payload.errorMessage;
};

const getAllAuditProgresses = (state: AuditProgressState): void => {
  state.errorMessage = "";
};

const getAllAuditProgressesRequest = (state: AuditProgressState): void => {
  state.isFetching = true;
};

const getAllAuditProgressesSuccess = (state: AuditProgressState, action: GetAllAuditProgressesSuccessAction): void => {
  state.isFetching = false;
  state.allAuditProgresses = action.payload.auditProgresses;
};

const getAllAuditProgressesError = (state: AuditProgressState, action: GetAllAuditProgressesErrorAction): void => {
  state.isFetching = false;
  state.errorMessage = action.payload.errorMessage;
};

const updateAuditProgress = (state: AuditProgressState): void => {
  state.errorMessage = "";
};

const updateAuditProgressRequest = (state: AuditProgressState): void => {
  state.isUpdating = true;
};

const updateAuditProgressSuccess = (state: AuditProgressState, action: UpdateAuditProgressSuccessAction): void => {
  state.isUpdating = false;
  state.auditProgress = action.payload.auditProgress;
};

const updateAuditProgressError = (state: AuditProgressState, action: UpdateAuditProgressErrorAction): void => {
  state.isUpdating = false;
  state.errorMessage = action.payload.errorMessage;
};

const reset = (state: AuditProgressState): void => {
  state.errorMessage = "";
};

const auditProgressReducer = createReducer(initialAuditProgressState, {
  [getAuditProgressAction.type]: (state) => getAuditProgress(state),
  [getAuditProgressRequestAction.type]: (state) => getAuditProgressRequest(state),
  [getAuditProgressSuccessAction.type]: (state, action) => getAuditProgressSuccess(state, action),
  [getAuditProgressErrorAction.type]: (state, action) => getAuditProgressError(state, action),
  [getAllAuditProgressesAction.type]: (state) => getAllAuditProgresses(state),
  [getAllAuditProgressesRequestAction.type]: (state) => getAllAuditProgressesRequest(state),
  [getAllAuditProgressesSuccessAction.type]: (state, action) => getAllAuditProgressesSuccess(state, action),
  [getAllAuditProgressesErrorAction.type]: (state, action) => getAllAuditProgressesError(state, action),
  [updateAuditProgressAction.type]: (state) => updateAuditProgress(state),
  [updateAuditProgressRequestAction.type]: (state) => updateAuditProgressRequest(state),
  [updateAuditProgressSuccessAction.type]: (state, action) => updateAuditProgressSuccess(state, action),
  [updateAuditProgressErrorAction.type]: (state, action) => updateAuditProgressError(state, action),
  [resetAction.type]: (state) => reset(state)
});

export default auditProgressReducer;
