import { CostItem } from "app/domain/costItem";

export type CostItemState = {
  isLoading: boolean;
  errorMessage: string;
  costItems: CostItem[];
};

export const initialCostItemState: CostItemState = {
  isLoading: false,
  errorMessage: "",
  costItems: []
};
