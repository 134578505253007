/* eslint-disable react-hooks/exhaustive-deps */
import { Cable } from "actioncable";
import { EntityCategory } from "app/domain/entityCategory";
import { Site } from "app/domain/site";
import { Params, useRouter } from "app/routing/routerProvider";
import { getPhotosFolderDownloadProgress, getPhotosFolderPollingStatus, startDownloadingPhotosFolderAction, stopDownloadingPhotosFolderAction } from "app/store/photo";
import { PhotosFolderPollingStatus } from "app/store/photo/state";
import { generateTemplateReportAction, importDataAction, importDataSuccessAction } from "app/store/report/reportActions";
import { generateSiteReportAction, updateSiteAction } from "app/store/site/actions";
import { State } from "app/store/state";
import { downloadLink } from "app/utils/downloadLink";
import { getUserId } from "app/utils/getUserId";
import { Toolbar } from "app/view/common/toolbar";
import { ComponentProps, createStyledComponent } from "common/style/createStyledComponent";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion as SemanticAccordion, Form as SemanticForm } from "semantic-ui-react";
import { css } from "styled-components";
import { Button, FileUploadButton } from "ui/button";
import { DownloadButton, SaveButton } from "ui/button/actions";
import { IconType } from "ui/icon";

import useSite from "../useSite";
import { GeneralInformation, SiteInformation } from "./formSections";

const SITE_INFORMATION = "Informations site";
const GENERAL_INFORMATION = "Informations gérérales";
const SAVE = "Enregistrer";
const GENERATE_EXCEL_REPORT = "Générer rapport Excel";
const DOWNLOAD = "Télécharger photos";
const DOWNLOAD_IN_PROGRESS = "Préparation du zip en cours...";
const GENERATE_TEMPLATE_REPORT = "Générer un template";
const siteBasicInformationFormStyle = css`
  > *:not(:last-child) {
    margin-bottom: 1em;
  }
`;

interface SiteBasicInformationFormProps extends ComponentProps {
  site: Site | undefined;
  cable: Cable;
}

const SiteBasicInformationForm: FC<SiteBasicInformationFormProps> = ({ site, cable, className }: SiteBasicInformationFormProps) => {
  const { getParam } = useRouter();
  const { currentSite, onUpdate } = useSite(site as Site);

  const dispatch = useDispatch();

  const siteId = getParam(Params.SITE);

  const photosFolderDownloadProgress = useSelector((state: State): number => getPhotosFolderDownloadProgress(state, siteId));

  const photosFolderPollingStatus = useSelector((state: State): PhotosFolderPollingStatus => getPhotosFolderPollingStatus(state, siteId));

  const handleSave = (): void => {
    dispatch(updateSiteAction(currentSite));
  };

  const handleReportGeneration = (): void => {
    dispatch(generateSiteReportAction(siteId));
  };

  const handleDownloadStart = (): void => {
    dispatch(startDownloadingPhotosFolderAction(siteId, EntityCategory.SITE));
  };

  const handleDownloadCancel = (): void => {
    dispatch(stopDownloadingPhotosFolderAction(siteId));
  };
  const handleTemplateGeneration = (): void => {
    dispatch(generateTemplateReportAction());
  };

  const handleFileUpload = (event: any): void => {
    dispatch(importDataAction(event.target.files[0]));
  };

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    cable.subscriptions.create(
      { channel: "ImportDataChannel", user_id: getUserId() },
      {
        received: (data) => {
          if (data.status === "DONE") {
            dispatch(importDataSuccessAction());
            downloadLink(data.filename, data.file);
          }
        }
      }
    );
  }, []);

  const actions = [
    <SaveButton label={SAVE} onClick={handleSave} key={0} />,
    <Button title={GENERATE_EXCEL_REPORT} iconType={IconType.EXCEL} onClick={handleReportGeneration} key={1} />,
    <Button title={GENERATE_TEMPLATE_REPORT} iconType={IconType.EXCEL} onClick={handleTemplateGeneration} key={2} />,
    <FileUploadButton onChange={handleFileUpload} title="Importer des données" key={3} />,
    <DownloadButton
      label={DOWNLOAD}
      downloadLabel={DOWNLOAD_IN_PROGRESS}
      onDownloadStart={handleDownloadStart}
      onDownloadStop={handleDownloadCancel}
      progress={photosFolderDownloadProgress}
      isDownloading={photosFolderPollingStatus === PhotosFolderPollingStatus.STARTED}
      key={4}
    />
  ];

  const panels = [
    {
      title: SITE_INFORMATION,
      content: {
        content: <SiteInformation site={currentSite} onUpdate={onUpdate} />
      },
      key: 0
    },
    {
      title: GENERAL_INFORMATION,
      content: {
        content: <GeneralInformation site={currentSite} onUpdate={onUpdate} />
      },
      key: 1
    }
  ];

  return (
    <SemanticForm className={className}>
      <Toolbar actions={actions} />
      <SemanticAccordion exclusive={false} styled fluid defaultActiveIndex={[0, 1]} panels={panels} />
    </SemanticForm>
  );
};

export default createStyledComponent(SiteBasicInformationForm, siteBasicInformationFormStyle);
