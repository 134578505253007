import { css } from "styled-components";

import * as constants from "./constants";

export const formControlStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  label {
    display: block;
    color: ${constants.DEFAULT_FORM_CONTROL_TEXT_COLOR};
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
  }
`;
