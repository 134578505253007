import React, { FC } from "react";

const ClickableCell: FC = (props: any): JSX.Element => {
  return (
    <div>
      <a href={`/projects/${props?.data?.projectId}/sites/${props?.data?.siteId}/assets/${props?.data?.id}`}>{props?.data?.number}</a>
    </div>
  );
};

export default ClickableCell;
