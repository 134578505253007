interface CalculationLineProps {
  id: number;
  description: string;
  size: number;
  quantity: number;
  operation: string;
}

class CalculationLine {
  id: number;
  description: string;
  size: number;
  quantity: number;
  operation: string;

  constructor(calculationLineProps: CalculationLineProps) {
    this.id = calculationLineProps.id;
    this.description = calculationLineProps.description;
    this.size = calculationLineProps.size;
    this.quantity = calculationLineProps.quantity;
    this.operation = calculationLineProps.operation;
  }
}

export default CalculationLine;
